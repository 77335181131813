import Service, { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';
import { task, TaskGenerator, timeout } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';

import ModalsService from 'qonto-switching-bank-js/services/modal';
import NetworkService from 'qonto-switching-bank-js/services/network';
import {
  PROVIDER_ITEM_REFRESH_STATUS,
  ProviderItemRefreshInfo,
} from 'qonto-switching-bank-js/types/credentials';

import ToastFlashMessagesService from './toast-flash-messages';

interface PollProviderItemRefreshInfoGeneratorParams {
  statusesToAwait: PROVIDER_ITEM_REFRESH_STATUS[];
  maxFetchCount?: number;
}

export default class FunnelService extends Service {
  @service declare modals: typeof ModalsService;
  @service declare network: NetworkService;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service declare intl: IntlService;

  getProviderItemRefreshInfo = async () => {
    let response = await this.network.get('/provider/item/refresh_status');

    return response.data as ProviderItemRefreshInfo;
  };

  startFunnel = (countryCode: string) => {
    if (countryCode && countryCode === 'FR') {
      this.modals.open('consent-modal', {
        isFullScreenModal: true,
      });
    } else {
      this.toastFlashMessages.toastError(this.intl.t('toast.not_available_for_country'));
    }
  };

  @task *pollProviderItemRefreshInfoTask({
    statusesToAwait = [],
    maxFetchCount = 20,
  }: PollProviderItemRefreshInfoGeneratorParams): TaskGenerator<ProviderItemRefreshInfo> {
    let providerItemRefreshInfo: ProviderItemRefreshInfo = yield this.getProviderItemRefreshInfo();
    let fetchCount = 1;
    let triggerStatuses: PROVIDER_ITEM_REFRESH_STATUS[] = [
      PROVIDER_ITEM_REFRESH_STATUS.FINISHED,
      PROVIDER_ITEM_REFRESH_STATUS.FINISHED_ERROR,
      PROVIDER_ITEM_REFRESH_STATUS.INVALID_CREDS,
      ...statusesToAwait,
    ];

    while (
      !triggerStatuses.includes(providerItemRefreshInfo.status) &&
      fetchCount <= maxFetchCount
    ) {
      yield timeout(4000);
      providerItemRefreshInfo = yield this.getProviderItemRefreshInfo();
      fetchCount++;
    }
    if (fetchCount > maxFetchCount) {
      this.toastFlashMessages.defaultToastError();
      Sentry.captureMessage(
        `Polling has timeout with the status ${providerItemRefreshInfo.status}`
      );
    }

    return providerItemRefreshInfo;
  }
}
