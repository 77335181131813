import { ProviderConnectionStatusDetails } from 'qonto-switching-bank-js/types/migration';

export interface ChildBankFormInput {
  label: string;
  type: 'USER' | 'PWD' | 'PWD2';
  isNum: '0' | '1';
  maxLength?: number;
}

export interface MultiFactorAuth {
  type?: 'SMS' | 'APP_TO_APP';
  label?: string;
  description?: string;
  redirect_url?: string;
  is_numeric?: boolean;
}

export enum PROVIDER_ITEM_REFRESH_STATUS {
  AUTHENTICATING = 'authenticating',
  RETRIEVING_DATA = 'retrieving_data',
  INFO_REQUIRED = 'info_required',
  FINISHED = 'finished',
  INVALID_CREDS = 'invalid_creds',
  FINISHED_ERROR = 'finished_error',
}

export interface ProviderItemRefreshInfo {
  status: PROVIDER_ITEM_REFRESH_STATUS;
  mfa?: MultiFactorAuth;
  status_details?: ProviderConnectionStatusDetails;
}

export interface CredentialsBody {
  USER: string;
  PWD?: string;
  PWD2?: string;
}
export interface ProviderItemRequestBody {
  bank_id: number;
  credentials: CredentialsBody;
}

export type LabelTranslationKey =
  | 'onboarding_funnel.credentials.clientnumber_title'
  | 'onboarding_funnel.credentials.password_title'
  | 'onboarding_funnel.credentials.code_title'
  | 'onboarding_funnel.credentials.confidentialcode_title'
  | 'onboarding_funnel.credentials.email_title'
  | 'onboarding_funnel.credentials.companynumber_title'
  | 'onboarding_funnel.credentials.id_title'
  | 'onboarding_funnel.credentials.accesscode_title'
  | 'onboarding_funnel.credentials.usernumber_title'
  | 'onboarding_funnel.credentials.birthdate_title'
  | 'onboarding_funnel.credentials.answer_title'
  | 'onboarding_funnel.credentials.subscriptionnumber_title'
  | 'onboarding_funnel.credentials.accountnumber_title'
  | 'onboarding_funnel.credentials.phonenumber_title'
  | 'onboarding_funnel.credentials.servercode_title'
  | 'onboarding_funnel.credentials.4digitcode_title';

export type PlaceholderTranslationKey =
  | 'onboarding_funnel.credentials.clientnumber_placeholder'
  | 'onboarding_funnel.credentials.password_placeholder'
  | 'onboarding_funnel.credentials.code_placeholder'
  | 'onboarding_funnel.credentials.email_placeholder'
  | 'onboarding_funnel.credentials.companynumber_placeholder'
  | 'onboarding_funnel.credentials.id_placeholder'
  | 'onboarding_funnel.credentials.usernumber_placeholder'
  | 'onboarding_funnel.credentials.birthdate_placeholder'
  | 'onboarding_funnel.credentials.answer_placeholder'
  | 'onboarding_funnel.credentials.subscriptionnumber_placeholder'
  | 'onboarding_funnel.credentials.accountnumber_placeholder'
  | 'onboarding_funnel.credentials.phonenumber_placeholder';

export type BridgeLabel =
  | 'ID' // Simulator only
  | 'PWD' // Simulator only
  | 'Numéro client'
  | 'Mot de passe (6 chiffres)'
  | 'Code utilisateur'
  | 'Code confidentiel'
  | 'Mot de passe'
  | 'E-mail'
  | "Numéro d'entreprise"
  | "Email d'identification"
  | 'Email'
  | 'Password'
  | 'Identifiant / Numéro de client'
  | "Code d'accès"
  | 'Identifiant client'
  | "Numéro d'usager"
  | 'Identifiant personnel'
  | 'Identifiant'
  | 'Code personnel'
  | 'Date de naissance (JJMMAAAA)'
  | 'Code secret'
  | 'Réponse à la question mémorable'
  | "Votre code d'accès"
  | 'Votre code secret'
  | 'Réponse mémorable'
  | "N° d'abonné"
  | 'N° de Client'
  | 'N° de Compte'
  | "Numéro d'abonné"
  | 'Code confidentiel | Pas encore de code ? Entrez 6 chiffres'
  | 'Numéro de téléphone avec indicatif (+33...)'
  | 'Code serveur'
  | 'Code à 4 chiffres'
  | 'Votre code secret à 4 chiffres'
  | 'Votre adresse mail'
  | 'Votre mot de passe'
  | 'Votre code client'
  | 'Votre identifiant web'
  | 'Votre identifiant';

export interface CredentialsInputLabels {
  bridgeLabel: BridgeLabel;
  label: LabelTranslationKey;
  placeholder: PlaceholderTranslationKey | '';
  isPasswordType: boolean;
}

export type CredentialsInput = ChildBankFormInput & CredentialsInputLabels;

export const CredentialsInputLabelsUSERDefault: CredentialsInputLabels = {
  bridgeLabel: 'ID',
  label: 'onboarding_funnel.credentials.id_title',
  placeholder: 'onboarding_funnel.credentials.id_placeholder',
  isPasswordType: false,
};
export const CredentialsInputLabelsPWDDefault: CredentialsInputLabels = {
  bridgeLabel: 'PWD',
  label: 'onboarding_funnel.credentials.password_title',
  placeholder: 'onboarding_funnel.credentials.password_placeholder',
  isPasswordType: true,
};

export const CredentialsInputLabelsList: CredentialsInputLabels[] = [
  CredentialsInputLabelsUSERDefault, // For simulator bank only
  CredentialsInputLabelsPWDDefault, // For simulator bank only
  {
    bridgeLabel: 'Numéro client',
    label: 'onboarding_funnel.credentials.clientnumber_title',
    placeholder: 'onboarding_funnel.credentials.clientnumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Mot de passe (6 chiffres)',
    label: 'onboarding_funnel.credentials.password_title',
    placeholder: 'onboarding_funnel.credentials.password_placeholder',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Code utilisateur',
    label: 'onboarding_funnel.credentials.usernumber_title',
    placeholder: 'onboarding_funnel.credentials.usernumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Code confidentiel',
    label: 'onboarding_funnel.credentials.confidentialcode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Mot de passe',
    label: 'onboarding_funnel.credentials.password_title',
    placeholder: 'onboarding_funnel.credentials.password_placeholder',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'E-mail',
    label: 'onboarding_funnel.credentials.email_title',
    placeholder: 'onboarding_funnel.credentials.email_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: "Numéro d'entreprise",
    label: 'onboarding_funnel.credentials.companynumber_title',
    placeholder: 'onboarding_funnel.credentials.companynumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: "Email d'identification",
    label: 'onboarding_funnel.credentials.email_title',
    placeholder: 'onboarding_funnel.credentials.email_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Email',
    label: 'onboarding_funnel.credentials.email_title',
    placeholder: 'onboarding_funnel.credentials.email_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Password',
    label: 'onboarding_funnel.credentials.password_title',
    placeholder: 'onboarding_funnel.credentials.password_placeholder',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Identifiant / Numéro de client',
    label: 'onboarding_funnel.credentials.id_title',
    placeholder: 'onboarding_funnel.credentials.id_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: "Code d'accès",
    label: 'onboarding_funnel.credentials.accesscode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Identifiant client',
    label: 'onboarding_funnel.credentials.id_title',
    placeholder: 'onboarding_funnel.credentials.id_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: "Numéro d'usager",
    label: 'onboarding_funnel.credentials.usernumber_title',
    placeholder: 'onboarding_funnel.credentials.usernumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Identifiant personnel',
    label: 'onboarding_funnel.credentials.id_title',
    placeholder: 'onboarding_funnel.credentials.id_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Identifiant',
    label: 'onboarding_funnel.credentials.id_title',
    placeholder: 'onboarding_funnel.credentials.id_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Code personnel',
    label: 'onboarding_funnel.credentials.confidentialcode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Date de naissance (JJMMAAAA)',
    label: 'onboarding_funnel.credentials.birthdate_title',
    placeholder: 'onboarding_funnel.credentials.birthdate_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Code secret',
    label: 'onboarding_funnel.credentials.confidentialcode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Réponse à la question mémorable',
    label: 'onboarding_funnel.credentials.answer_title',
    placeholder: 'onboarding_funnel.credentials.answer_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: "Votre code d'accès",
    label: 'onboarding_funnel.credentials.accesscode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Votre code secret',
    label: 'onboarding_funnel.credentials.confidentialcode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Réponse mémorable',
    label: 'onboarding_funnel.credentials.answer_title',
    placeholder: 'onboarding_funnel.credentials.answer_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: "N° d'abonné",
    label: 'onboarding_funnel.credentials.subscriptionnumber_title',
    placeholder: 'onboarding_funnel.credentials.subscriptionnumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'N° de Client',
    label: 'onboarding_funnel.credentials.clientnumber_title',
    placeholder: 'onboarding_funnel.credentials.clientnumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'N° de Compte',
    label: 'onboarding_funnel.credentials.accountnumber_title',
    placeholder: 'onboarding_funnel.credentials.accountnumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: "Numéro d'abonné",
    label: 'onboarding_funnel.credentials.subscriptionnumber_title',
    placeholder: 'onboarding_funnel.credentials.subscriptionnumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Code confidentiel | Pas encore de code ? Entrez 6 chiffres',
    label: 'onboarding_funnel.credentials.confidentialcode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Numéro de téléphone avec indicatif (+33...)',
    label: 'onboarding_funnel.credentials.phonenumber_title',
    placeholder: 'onboarding_funnel.credentials.phonenumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Code serveur',
    label: 'onboarding_funnel.credentials.servercode_title',
    placeholder: '',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Code à 4 chiffres',
    label: 'onboarding_funnel.credentials.4digitcode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Votre code secret à 4 chiffres',
    label: 'onboarding_funnel.credentials.4digitcode_title',
    placeholder: '',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Votre adresse mail',
    label: 'onboarding_funnel.credentials.email_title',
    placeholder: 'onboarding_funnel.credentials.email_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Votre mot de passe',
    label: 'onboarding_funnel.credentials.password_title',
    placeholder: 'onboarding_funnel.credentials.password_placeholder',
    isPasswordType: true,
  },
  {
    bridgeLabel: 'Votre code client',
    label: 'onboarding_funnel.credentials.clientnumber_title',
    placeholder: 'onboarding_funnel.credentials.clientnumber_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Votre identifiant web',
    label: 'onboarding_funnel.credentials.id_title',
    placeholder: 'onboarding_funnel.credentials.id_placeholder',
    isPasswordType: false,
  },
  {
    bridgeLabel: 'Votre identifiant',
    label: 'onboarding_funnel.credentials.id_title',
    placeholder: 'onboarding_funnel.credentials.id_placeholder',
    isPasswordType: false,
  },
];
