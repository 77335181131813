import Component from '@glimmer/component';

import { task, TaskForTaskFunction } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface ConfirmationModalArgs {
  data: { confirmTask?: TaskForTaskFunction<(...args: unknown[]) => Generator<unknown>> };
  close: (...args: unknown[]) => void;
}

export default class ConfirmationModalComponent extends Component<ConfirmationModalArgs> {
  @task confirmTask = taskFor(function* (this: ConfirmationModalComponent) {
    let { confirmTask } = this.args.data;

    if (confirmTask) {
      yield confirmTask.linked().perform(this.args.close);
    } else {
      this.args.close('confirm');
    }
  });
}
