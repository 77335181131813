function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Component from '@glimmer/component';
export default class QStepper extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "steps", []);
  }
  get _currentStep() {
    return this.args.currentStep || 0;
  }
  registerChild(child) {
    this.steps.push(child);

    // We are pushing onto the steps array and as such don't support dynamically adding steps.
    // This means we only need to update the index for the current child.
    child.orderedIndex = this.steps.length - 1;
  }
  unregisterChild(child) {
    let index = this.steps.indexOf(child);
    if (index >= 0) {
      this.steps.splice(index, 1);
    }
    this._updateLabels();
  }
  _updateLabels() {
    // update labels based on the sorted index
    this.steps.forEach((c, i) => {
      if (c.orderedIndex !== i) {
        c.orderedIndex = i;
      }
    });
  }
}