function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
export default class CheckboxField extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "ariaLabeledBy", `${guidFor(this)}-checkbox-field-label`);
    _defineProperty(this, "fieldId", `${guidFor(this)}-checkbox-field`);
  }
  /*
    Adding a getter was necessary because the local-class doesn't seem to be added correctly when
    having it on multiple lines.
  */
  get localClass() {
    let {
      disabled,
      errorMessage,
      hasError
    } = this.args;
    let wrapperClass = 'wrapper';
    if (disabled) {
      wrapperClass += ' disabled';
    } else if (!errorMessage && hasError) {
      wrapperClass += ' error';
    }
    return wrapperClass;
  }
}