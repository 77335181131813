import dayjs from 'dayjs';
const DATE_STRING_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; // YYYY-MM-DD

export const COMPACT_DATE_FIELD_FORMATS = {
  en: 'DD-MM-YYYY',
  fr: 'DD-MM-YYYY',
  it: 'DD/MM/YYYY',
  de: 'DD.MM.YYYY',
  es: 'DD-MM-YYYY'
};
export const COMPACT_DATE_SEPARATORS = {
  en: '-',
  fr: '-',
  it: '/',
  de: '.',
  es: '-'
};

/**
 *
 * @param value string date in the format YYYY-MM-DD or GMT
 * @returns string date in the corresponding compact format DD[/-.]MM[/-.]YYYY
 *
 * But it returns the same input value in case the input is not valid
 */
export function formatDateFormField(value, locale) {
  let date = null;
  let separator = COMPACT_DATE_SEPARATORS[locale] ?? COMPACT_DATE_SEPARATORS['en'];
  if (typeof value === 'string' && DATE_STRING_REGEX.test(value)) {
    let splitDate = value.split('-');
    date = `${splitDate[2]}${separator}${splitDate[1]}${separator}${splitDate[0]}`;
  }
  return date ?? value;
}

/**
 *
 * @param value string date in the format YYYY-MM-DD
 * @returns Date object
 *
 * But it returns the same input value in case the input is not valid
 */
export function convertDateStringToObject(value) {
  let date = null;
  if (typeof value === 'string' && DATE_STRING_REGEX.test(value)) {
    date = dayjs(value).toDate();
  }
  return date ?? value;
}