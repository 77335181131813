import Model, { attr } from '@ember-data/model';

import { ChildBankFormInput } from 'qonto-switching-bank-js/types/credentials';

export default class ChildBankModel extends Model {
  @attr declare name: string;
  @attr declare type: 'business' | 'retail' | 'both' | 'neither';
  @attr declare logo_url: string;
  @attr declare parent_bank_id: number;
  @attr declare provider_bank_id: number;
  @attr declare authentication_type: string;
  @attr declare url: string;
  @attr declare form: ChildBankFormInput[];

  get isPro() {
    return this.type === 'business' || this.type === 'both';
  }

  get isIndividual() {
    return this.type === 'retail' || this.type === 'both';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'child-bank': ChildBankModel;
  }
}
