import { helper } from '@ember/component/helper';

export function oppositeSign([number]) {
  if (number === 0) {
    return number;
  }

  return number * -1;
}

export default helper(oppositeSign);
