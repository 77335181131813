export default {
  "disclaimer-block": "_disclaimer-block_u12kuh",
  "disclaimer-block-level-error": "_disclaimer-block-level-error_u12kuh",
  "disclaimer-block-level-info": "_disclaimer-block-level-info_u12kuh",
  "disclaimer-block-level-warning": "_disclaimer-block-level-warning_u12kuh",
  "disclaimer-block__icon": "_disclaimer-block__icon_u12kuh",
  "error": "_error_u12kuh",
  "info": "_info_u12kuh",
  "warning": "_warning_u12kuh",
  "disclaimer-block__message": "_disclaimer-block__message_u12kuh"
};