import Store from '@ember-data/store';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import OrganizationModel from 'qonto-switching-bank-js/models/organization';
import FixturesService from 'qonto-switching-bank-js/services/fixtures';
import FunnelService from 'qonto-switching-bank-js/services/funnel';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import Segment from 'qonto-switching-bank-js/services/segment';

interface OrganizationArgs {
  organization: OrganizationModel;
}

export default class ProductTourBannerComponent extends Component<OrganizationArgs> {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare segment: Segment;
  @service declare fixtures: FixturesService;
  @service declare funnel: FunnelService;
  @service declare modals: typeof ModalsService;

  // Hard URL transition to force Ember to reload all model() hooks.
  @action resetProductTour() {
    this.segment.track('bankswitch_demo_replay');

    localStorage.removeItem('hasSeenProductTour');

    let url = this.router.urlFor('dashboard');
    window.location.assign(url);
  }

  @action connectMyBank() {
    localStorage.setItem('hasSeenProductTour', 'true');
    this.funnel.startFunnel(this.args.organization.legal_country);
  }
}
