var _class;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import Component from '@glimmer/component';
import { action } from '@ember/object';
const isTouchDevice = !!window && 'ontouchstart' in window;
if (typeof FastBoot === 'undefined') {
  (function (ElementProto) {
    if (typeof ElementProto.matches !== 'function') {
      ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector;
    }
    if (typeof ElementProto.closest !== 'function') {
      ElementProto.closest = function closest(selector) {
        let element = this;
        while (element !== null && element.nodeType === 1) {
          if (element.matches(selector)) {
            return element;
          }
          element = element.parentNode;
        }
        return null;
      };
    }
  })(window.Element.prototype);
}
let Options = (_class = class Options extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "isTouchDevice", this.args.extra?._isTouchDevice || isTouchDevice);
    _defineProperty(this, "touchMoveEvent", void 0);
    _defineProperty(this, "mouseOverHandler", _ => {});
    _defineProperty(this, "mouseUpHandler", _ => {});
    _defineProperty(this, "touchEndHandler", _ => {});
    _defineProperty(this, "touchMoveHandler", _ => {});
    _defineProperty(this, "touchStartHandler", _ => {});
  }
  addHandlers(element) {
    let role = element.getAttribute('role');
    if (role === 'group') {
      return;
    }
    let findOptionAndPerform = (action, e) => {
      if (e.target === null) return;
      let optionItem = e.target.closest('[data-option-index]');
      if (!optionItem) {
        return;
      }
      if (optionItem.closest('[aria-disabled=true]')) {
        return; // Abort if the item or an ancestor is disabled
      }
      let optionIndex = optionItem.getAttribute('data-option-index');
      if (optionIndex === null) return;
      action(this._optionFromIndex(optionIndex), e);
    };
    this.mouseUpHandler = e => findOptionAndPerform(this.args.select.actions.choose, e);
    element.addEventListener('mouseup', this.mouseUpHandler);
    if (this.args.highlightOnHover) {
      this.mouseOverHandler = e => findOptionAndPerform(this.args.select.actions.highlight, e);
      element.addEventListener('mouseover', this.mouseOverHandler);
    }
    if (this.isTouchDevice) {
      this.touchMoveHandler = e => {
        this.touchMoveEvent = e;
        if (element) {
          element.removeEventListener('touchmove', this.touchMoveHandler);
        }
      };
      // Add touch event handlers to detect taps
      this.touchStartHandler = _ => {
        element.addEventListener('touchmove', this.touchMoveHandler);
      };
      this.touchEndHandler = e => {
        if (e.target === null) return;
        let optionItem = e.target.closest('[data-option-index]');
        if (optionItem === null) return;
        e.preventDefault();
        if (this._hasMoved(e)) {
          this.touchMoveEvent = undefined;
          return;
        }
        if (optionItem.closest('[aria-disabled=true]')) {
          return; // Abort if the item or an ancestor is disabled
        }
        let optionIndex = optionItem.getAttribute('data-option-index');
        if (optionIndex === null) return;
        this.args.select.actions.choose(this._optionFromIndex(optionIndex), e);
      };
      element.addEventListener('touchstart', this.touchStartHandler);
      element.addEventListener('touchend', this.touchEndHandler);
    }
    if (role !== 'group') {
      this.args.select.actions.scrollTo(this.args.select.highlighted);
    }
  }
  removeHandlers(element) {
    element.removeEventListener('mouseup', this.mouseUpHandler);
    element.removeEventListener('mouseover', this.mouseOverHandler);
    element.removeEventListener('touchstart', this.touchStartHandler);
    element.removeEventListener('touchmove', this.touchMoveHandler);
    element.removeEventListener('touchend', this.touchEndHandler);
  }
  _optionFromIndex(index) {
    let parts = index.split('.');
    let option = this.args.options[parseInt(parts[0], 10)];
    for (let i = 1; i < parts.length; i++) {
      option = option.options[parseInt(parts[i], 10)];
    }
    return option;
  }
  _hasMoved(endEvent) {
    let moveEvent = this.touchMoveEvent;
    if (!moveEvent) {
      return false;
    }
    let changedTouch = moveEvent.changedTouches[0];
    if (!endEvent.changedTouches?.[0] || changedTouch.touchType !== 'stylus') {
      return true;
    }

    // Distinguish stylus scroll and tap: if touch "distance" < 5px, we consider it a tap
    let horizontalDistance = Math.abs(changedTouch.pageX - endEvent.changedTouches[0].pageX);
    let verticalDistance = Math.abs(changedTouch.pageY - endEvent.changedTouches[0].pageY);
    return horizontalDistance >= 5 || verticalDistance >= 5;
  }
}, (_applyDecoratedDescriptor(_class.prototype, "addHandlers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "addHandlers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeHandlers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "removeHandlers"), _class.prototype)), _class);
export { Options as default };