import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import SegmentService from 'qonto-switching-bank-js/services/segment';

export default class SynchronizationSuccessRoute extends Route {
  @service declare segment: SegmentService;
  @service declare store: Store;

  model() {
    this.segment.track('bankswitch_synchro_success_seen');
    return this.store.queryRecord('migration', {});
  }
}
