import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';

import NetworkService from 'qonto-switching-bank-js/services/network';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class SelectAccountsRoute extends Route {
  @service declare network: NetworkService;
  @service declare router: RouterService;
  @service declare store: Store;

  async model() {
    let accounts = (await this.store.findAll('account')).slice();
    let migration = await this.store.queryRecord('migration', {});

    return { accounts, migration };
  }

  async afterModel(model: ModelFrom<SelectAccountsRoute>) {
    if (model.accounts.length === 0) {
      Sentry.captureMessage(`Migration ${model.migration.id} don't have any accounts available`);
      this.router.transitionTo('errors.maintenance');
    } else if (model.accounts.length === 1) {
      await this.network.put('/accounts', { account: { ids: [model.accounts.firstObject?.id] } });
      this.router.transitionTo('onboarding.choose-dashboard-access');
    }
  }
}
