import Service, { inject as service } from '@ember/service';

import { dropTask, race, rawTimeout, restartableTask, waitForEvent } from 'ember-concurrency';

const USER_ACTIVITY_TIMEOUT = 25 * 60 * 1000; // 25 min
const MODAL_TIMEOUT = 5 * 60 * 1000; // 5 min

export default class RefreshSessionManager extends Service {
  @service intl;
  @service modals;
  @service session;
  @service('ember-user-activity@user-activity') userActivity;

  startActivityTracking() {
    this.activityTrackingTask.perform();
  }

  cancelActivityTracking() {
    this.activityTrackingTask.cancelAll();
  }

  @restartableTask
  *activityTrackingTask() {
    while (true) {
      this.scheduleModalTask.perform(USER_ACTIVITY_TIMEOUT);
      yield waitForEvent(this.userActivity, 'userActive');
    }
  }

  @restartableTask
  *scheduleModalTask(timeUntilModal) {
    yield rawTimeout(timeUntilModal);
    this.showModalTask.perform();
  }

  @dropTask
  *showModalTask() {
    if (!this.session.isAuthenticated) {
      return;
    }

    let modal = this.modals.open('confirmation-modal', {
      title: this.intl.t('logout.modal.title'),
      body: this.intl.t('logout.modal.content', { htmlSafe: true }),
      cancel: this.intl.t('logout.modal.close_btn'),
      confirm: this.intl.t('logout.modal.accept_btn'),
    });

    let timeout = rawTimeout(MODAL_TIMEOUT).then(() => 'timeout');

    let result = yield race([modal, timeout]);

    if (result === 'cancel' || result === 'timeout') {
      modal.close();

      this.session.invalidate();
    }
  }
}
