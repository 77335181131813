import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/browser';

import ChooseDashboardAccessRoute from 'qonto-switching-bank-js/routes/onboarding/choose-dashboard-access/route';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class ChooseDashboardAccessController extends Controller {
  @service declare network: NetworkService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;

  declare model: ModelFrom<ChooseDashboardAccessRoute>;

  @tracked isLoadingAutomaticCta = false;

  get isDisabled() {
    return this.isLoadingAutomaticCta;
  }

  get appointmentCard() {
    let translationKey = 'optional_cs.select_method.main_page.customer_service_bloc';

    return {
      illustration: 'customer-service',
      titles: [`${translationKey}.title.1`, `${translationKey}.title.2`],
      description: `${translationKey}.description`,
      reasons: [
        `${translationKey}.argument1`,
        `${translationKey}.argument2`,
        `${translationKey}.argument3`,
      ],
      button: {
        label: `${translationKey}.primary_cta`,
        action: this.selectAppointment,
        isDisabled: this.isDisabled,
      },
    };
  }

  get automaticCard() {
    let translationKey = 'optional_cs.select_method.main_page.algorithm_bloc';

    return {
      badge: `${translationKey}.tag_recommended`,
      illustration: 'rocket-launch',
      titles: [`${translationKey}.title.1`, `${translationKey}.title.2`],
      description: `${translationKey}.description`,
      reasons: [
        `${translationKey}.argument1`,
        `${translationKey}.argument2`,
        `${translationKey}.argument3`,
      ],
      button: {
        label: `${translationKey}.primary_cta`,
        action: this.selectAutomatic,
        isLoading: this.isLoadingAutomaticCta,
        isDisabled: this.isDisabled,
      },
    };
  }

  @action selectAppointment() {
    this.segment.track('bankswitch_choice_call');
    this.router.transitionTo('onboarding.appointment', {
      queryParams: { category: 'classic' },
    });
  }

  @action selectAutomatic() {
    let { migration } = this.model;
    this.isLoadingAutomaticCta = true;

    migration.status = 'dashboard_generation';

    migration
      .save()
      .then(() => {
        this.segment.track('bankswitch_choice_automated');
        this.router.transitionTo('onboarding.waiting-dashboard');
      })
      .catch((error: { message?: string }) => {
        Sentry.captureMessage(
          `Migration update after choosing automatic dashboard failed | ${error.message}`
        );
        this.router.transitionTo('errors.maintenance');
      })
      .finally(() => (this.isLoadingAutomaticCta = false));
  }
}
