import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import SegmentService from 'qonto-switching-bank-js/services/segment';

export default class MigrationStatusRoute extends Route {
  @service declare segment: SegmentService;

  model() {
    return { migrationStatus: 2 };
  }

  async afterModel() {
    this.segment.track('bankswitch_stepper_viewed');
  }
}
