var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _descriptor, _descriptor2;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import templateLayout from '../templates/components/power-calendar';
import { assert } from '@ember/debug';
import { add, normalizeDate, normalizeCalendarValue } from 'ember-power-calendar-utils';
import PowerCalendarNavComponent from './power-calendar/nav';
import PowerCalendarDaysComponent from './power-calendar/days';
let _class = (_dec = service('power-calendar'), _dec2 = computed('onSelect', 'onCenterChange'), _dec3 = computed('center'), _dec4 = computed('_publicAPI'), _dec5 = computed('selected', 'currentCenter', 'locale', 'powerCalendarService.locale', 'changeCenterTask.isRunning', 'publicActions'), _dec6 = computed('tag'), _dec7 = task(function* (newCenter, calendar, e) {
  assert('You attempted to move the center of a calendar that doesn\'t receive an `@onCenterChange` action.', typeof this.onCenterChange === 'function');
  let value = normalizeCalendarValue({
    date: newCenter
  });
  yield this.onCenterChange(value, calendar, e);
}), (_class2 = class _class2 extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "powerCalendarService", _descriptor, this);
    _defineProperty(this, "navComponent", PowerCalendarNavComponent);
    _defineProperty(this, "daysComponent", PowerCalendarDaysComponent);
    _defineProperty(this, "center", null);
    _defineProperty(this, "_calendarType", 'single');
    _defineProperty(this, "layout", templateLayout);
    _defineProperty(this, "tagName", '');
    // Tasks
    _initializerDefineProperty(this, "changeCenterTask", _descriptor2, this);
  }
  // Lifecycle hooks
  init() {
    super.init(...arguments);
    this.registerCalendar();
    if (this.onInit) {
      this.onInit(this.publicAPI);
    }
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.unregisterCalendar();
  }

  // CPs
  get publicActions() {
    let actions = {};
    if (this.onSelect) {
      actions.select = (...args) => this.select(...args);
    }
    if (this.onCenterChange) {
      let changeCenter = (newCenter, calendar, e) => {
        return this.changeCenterTask.perform(newCenter, calendar, e);
      };
      actions.changeCenter = changeCenter;
      actions.moveCenter = (step, unit, calendar, e) => {
        let newCenter = add(this.currentCenter, step, unit);
        return changeCenter(newCenter, calendar, e);
      };
    }
    return actions;
  }
  get selected() {
    return undefined;
  }
  set selected(v) {
    return normalizeDate(v);
  }
  get currentCenter() {
    let center = this.center;
    if (!center) {
      center = this.selected || this.powerCalendarService.getDate();
    }
    return normalizeDate(center);
  }
  get publicAPI() {
    return this._publicAPI;
  }
  get _publicAPI() {
    return {
      uniqueId: guidFor(this),
      type: this._calendarType,
      selected: this.selected,
      loading: this.changeCenterTask.isRunning,
      center: this.currentCenter,
      locale: this.locale || this.powerCalendarService.locale,
      actions: this.publicActions
    };
  }
  get tagWithDefault() {
    if (this.tag === undefined || this.tag === null) {
      return 'div';
    }
    return this.tag;
  }

  // Actions
  select(day, calendar, e) {
    if (this.onSelect) {
      this.onSelect(day, calendar, e);
    }
  }
  // Methods
  registerCalendar() {
    if (window) {
      window.__powerCalendars = window.__powerCalendars || {}; // TODO: weakmap??
      window.__powerCalendars[this.publicAPI.uniqueId] = this;
    }
  }
  unregisterCalendar() {
    if (window) {
      delete window.__powerCalendars[guidFor(this)];
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "powerCalendarService", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "publicActions", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "publicActions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selected", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentCenter", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "currentCenter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "publicAPI", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "publicAPI"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_publicAPI", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_publicAPI"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "tagWithDefault", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "tagWithDefault"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "changeCenterTask", [_dec7], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class2));
export { _class as default };