var _class;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import { getOwner } from '@ember/application';
import { schedule } from '@ember/runloop';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';
import Tether from 'tether';
import { action } from '@ember/object';
let EmberTetherComponent = (_class = class EmberTetherComponent extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "_tether", void 0);
    _defineProperty(this, "element", void 0);
  }
  get classPrefix() {
    return this.args.classPrefix || 'ember-tether';
  }
  get emberTetherConfig() {
    return (getOwner(this).resolveRegistration('config:environment') || {})['ember-tether'];
  }
  get bodyElement() {
    let config = this.emberTetherConfig;
    if (config && config.bodyElementId) {
      return document.getElementById(config.bodyElementId);
    }
    return undefined;
  }
  willDestroy() {
    super.willDestroy(...arguments);
    if (!this._tether) return;
    let {
      _tether,
      element
    } = this;
    schedule('render', () => {
      this.removeElement(element);
      this.removeTether(_tether);
    });
  }
  updateTether() {
    this.removeTether(this._tether);
    this.addTether();
  }
  positionTether() {
    this._tether?.position();
  }
  addTether(el = null) {
    // when called from did-insert modifier, el will be passed
    if (el) {
      this.element = el;
    }
    if (this._tetherTarget) {
      this._tether = new Tether(this._tetherOptions);
      this.positionTether();
    }
  }
  removeTether(tether) {
    tether?.destroy();
  }
  removeElement(element) {
    element.parentNode?.removeChild(element);
  }
  get _tetherTarget() {
    let t = this.args.target;
    if (t && t.element) {
      t = t.element;
    }
    return t;
  }
  get _tetherOptions() {
    let options = {
      element: this.element,
      target: this._tetherTarget,
      classPrefix: this.classPrefix
    };
    if (this.bodyElement) {
      options.bodyElement = this.bodyElement;
    }
    ['attachment', 'targetAttachment', 'offset', 'targetOffset', 'targetModifier', 'constraints', 'optimizations'].forEach(k => {
      let v = this.args[k];
      if (!isNone(v)) {
        options[k] = v;
      }
    });
    return options;
  }
}, (_applyDecoratedDescriptor(_class.prototype, "updateTether", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateTether"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "positionTether", [action], Object.getOwnPropertyDescriptor(_class.prototype, "positionTether"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTether", [action], Object.getOwnPropertyDescriptor(_class.prototype, "addTether"), _class.prototype)), _class);
export { EmberTetherComponent as default };