export default {
  "wrapper": "_wrapper_11x2nr",
  "selector": "_selector_11x2nr",
  "with-subtitle": "_with-subtitle_11x2nr",
  "icon": "_icon_11x2nr _icon_1ju4e7",
  "header": "_header_11x2nr _header_1ju4e7",
  "title": "_title_11x2nr _title_1ju4e7",
  "subtitle": "_subtitle_11x2nr _subtitle_1ju4e7",
  "selector__content": "_selector__content_11x2nr",
  "selector__extra": "_selector__extra_11x2nr"
};