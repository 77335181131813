var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable ember/no-array-prototype-extensions, ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/no-runloop */

import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { and, bool, empty, not, or } from '@ember/object/computed';
import { schedule } from '@ember/runloop';
const ICON_IS_VALID = 'icon_checkmark_rounded_filled';
const ICON_IS_INVALID = 'icon_cross_rounded_filled';
export const SMALL_INPUT_SIZE = 19;
export const MEDIUM_INPUT_SIZE = 40;
export const LARGE_INPUT_SIZE = 93;

/**
  Wraps a group of form elements.

  Basic example:

  ```hbs
  {{#x-form-group
    label=label
  }}
    {{input}}
  {{/x-form-group}}
  ```

  Example with error:

  ```hbs
  {{#x-form-group
    label=label
    error="Wrong format"
  }}
    {{input}}
  {{/x-form-group}}
  ```

  @deprecated This component should not be used directly anymore. Use `<FormElements>` ones instead.
  @class XFormGroupComponent
  @public
 */
let XFormGroupComponent = (_dec = bool('errors'), _dec2 = bool('icon'), _dec3 = bool('symbol'), _dec4 = and('clearable', 'isNotEmpty'), _dec5 = empty('value'), _dec6 = and('isHtmlValid', 'isNotEmpty'), _dec7 = computed('clearable', 'hasDecoration'), _dec8 = not('isEmpty'), _dec9 = or('hasIcon', 'hasSymbol'), _dec10 = computed('errorMessage', 'errorsTrunc'), _dec11 = computed('errorsTrunc'), _dec12 = computed('errors', 'errors.[]'), _dec13 = computed('autovalidate', 'isValid'), _dec14 = computed('customIcon', 'bvalidityIcon'), (_class = class XFormGroupComponent extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "tagName", '');
    _initializerDefineProperty(this, "hasError", _descriptor, this);
    _initializerDefineProperty(this, "hasIcon", _descriptor2, this);
    _initializerDefineProperty(this, "hasSymbol", _descriptor3, this);
    _initializerDefineProperty(this, "isClearable", _descriptor4, this);
    _initializerDefineProperty(this, "isEmpty", _descriptor5, this);
    _initializerDefineProperty(this, "isValid", _descriptor6, this);
    _initializerDefineProperty(this, "isNotEmpty", _descriptor7, this);
    _initializerDefineProperty(this, "hasDecoration", _descriptor8, this);
  }
  get clearableOnHover() {
    if (this.hasDecoration) {
      return true;
    }
    return this.clearable === 'hover';
  }
  get shouldTruncErrors() {
    let errorsLength = this.errorMessage?.length;
    let {
      errorsTrunc
    } = this;
    if (errorsTrunc && errorsLength) {
      if (errorsTrunc === 'small' && errorsLength > SMALL_INPUT_SIZE) {
        return true;
      } else if (errorsTrunc === 'medium' && errorsLength > MEDIUM_INPUT_SIZE) {
        return true;
      } else if (errorsTrunc === 'large' && errorsLength > LARGE_INPUT_SIZE) {
        return true;
      }
    }
    return false;
  }
  get errorsTruncSize() {
    let {
      errorsTrunc
    } = this;
    if (errorsTrunc === 'small') {
      return SMALL_INPUT_SIZE;
    } else if (errorsTrunc === 'large') {
      return LARGE_INPUT_SIZE;
    } else {
      return MEDIUM_INPUT_SIZE;
    }
  }
  get errorMessage() {
    return this.errors?.firstObject?.message || this.errors;
  }
  get bvalidityIcon() {
    if (this.autovalidate && this.isNotEmpty) {
      return this.isValid ? ICON_IS_VALID : ICON_IS_INVALID;
    }
    return '';
  }
  get icon() {
    let {
      customIcon,
      bvalidityIcon
    } = this;
    return customIcon || bvalidityIcon;
  }
  setFocus(val) {
    // this is a bit of a complicated one to explain...
    //
    // if we don't do this, then this `set('focused')` will cause the
    // "Acceptance | Requests | Multi Accounts" test suite in qonto-js to
    // fail after updating to Ember v3.22 or above, because of an
    // update-while-rendering assertion.
    //
    // after a lengthy debugging session with another Ember.js Core Team member
    // (@rwjblue) we concluded that this is most likely related to
    // https://github.com/emberjs/ember.js/issues/19344 and that this is going
    // to be hard to fix before Ember.js has a few internal implementation
    // details cleaned up correctly.
    //
    // therefore we are using a workaround here, that manually schedules the
    // `set('focused')` call on the `actions` runloop (see
    // https://guides.emberjs.com/release/applications/run-loop/), to avoid the
    // update-while-rendering error caused by the test suite mentioned above.
    schedule('actions', this, function () {
      this.set('focused', val);
    });
  }

  /**
   * This method, which is being called by `did-insert`, is necessary because
   * the `XAutoFocus` component can create ordering problems. The focus will
   * be moved into the `XFormGroup` component before the `focusin` event
   * listener is installed.
   *
   * To mitigate the issue, we check at `did-insert` time whether the
   * `activeElement` is inside of the `XFormGroup` component and then call
   * `setFocus(true)`, if necessary.
   */
  updateFocus(rootElement) {
    let element = document.activeElement;
    while (element) {
      if (element === rootElement) {
        this.setFocus(true);
        return;
      }
      element = element.parentElement;
    }
  }
  handleClearInput() {
    let emptyValue = this.value instanceof Date ? null : '';
    this.clearInput?.(emptyValue);
    this.setFocus(false);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasError", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasIcon", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasSymbol", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isClearable", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "clearableOnHover", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "clearableOnHover"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isNotEmpty", [_dec8], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasDecoration", [_dec9], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "shouldTruncErrors", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "shouldTruncErrors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "errorsTruncSize", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "errorsTruncSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "errorMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bvalidityIcon", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "bvalidityIcon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "icon", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFocus", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFocus", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClearInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClearInput"), _class.prototype)), _class));
export { XFormGroupComponent as default };