var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { warn } from '@ember/debug';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import dayjs from 'dayjs';
const DATE_FORMAT = 'DD/MM/YYYY';
const DEFAULT_LOCALE = 'EN';
const DRAFT_STATUS = 'draft';
const INVOICE_MODAL = 'invoice-modal';
const INVOICE_FORM = 'invoice-form';
const SETTINGS_FORM = 'settings-form';
const AUTOMATIC_MODE = 'automatic';
let PdfPreviewDe24 = (_class = class PdfPreviewDe24 extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
  }
  get isExampleContent() {
    return this.args.origin === SETTINGS_FORM || this.args.isExampleContent;
  }
  get document() {
    let document = this.args.invoice;
    let settings = this.args.settings;

    // display example content used for the settings modal pdf preview
    if (this.isExampleContent && settings) {
      let exampleDocument = {
        organization: {
          locale: document.organization.locale,
          legalName: document.organization.legalName,
          legalNumber: document.organization.legalNumber,
          shortLegalForm: document.organization.shortLegalForm,
          legalCountry: document.organization.legalCountry,
          address: document.organization.address,
          contactEmail: settings.contactEmail,
          vatNumber: settings.vatNumber,
          taxNumber: settings.taxNumber,
          companyLeadership: settings.companyLeadership,
          districtCourt: settings.districtCourt,
          commercialRegisterNumber: settings.commercialRegisterNumber
        },
        locale: document.organization.locale,
        number: settings.numberingMode === AUTOMATIC_MODE ? settings.invoiceNextNumberFormatted : this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-number'),
        issueDate: this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-issue-date'),
        dueDate: this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-due-date'),
        performanceDate: this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-performance-date'),
        pageCount: this.intl.t('ui-kit.pdf-preview.invoice-example.page-count'),
        contactEmail: settings.contactEmail,
        bic: document.bic,
        iban: document.iban,
        reference: document.reference,
        beneficiaryName: document.beneficiaryName,
        header: settings.invoiceHeader,
        footer: settings.invoiceFooter,
        totalVat: this.intl.t('ui-kit.pdf-preview.invoice-example.item-vat-total'),
        totalExcludingVat: this.intl.t('ui-kit.pdf-preview.invoice-example.item-total-without-vat'),
        totalAmount: this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-total'),
        currency: 'EUR',
        items: [{
          title: this.intl.t('ui-kit.pdf-preview.invoice-example.item-title'),
          description: this.intl.t('ui-kit.pdf-preview.invoice-example.item-description'),
          quantity: this.intl.t('ui-kit.pdf-preview.invoice-example.item-quantity'),
          unitPrice: this.intl.t('ui-kit.pdf-preview.invoice-example.item-price'),
          vatRate: this.intl.t('ui-kit.pdf-preview.invoice-example.item-vat-amount'),
          totalVat: this.intl.t('ui-kit.pdf-preview.invoice-example.item-vat-total'),
          totalExcludingVat: this.intl.t('ui-kit.pdf-preview.invoice-example.item-total-without-vat'),
          subTotal: this.intl.t('ui-kit.pdf-preview.invoice-example.item-total'),
          totalAmount: this.intl.t('ui-kit.pdf-preview.invoice-example.item-total')
        }],
        customer: {
          address: this.intl.t('ui-kit.pdf-preview.invoice-example.client-address-line1'),
          city: this.intl.t('ui-kit.pdf-preview.invoice-example.client-city'),
          zipCode: this.intl.t('ui-kit.pdf-preview.invoice-example.client-zipcode'),
          countryCode: this.intl.t('ui-kit.pdf-preview.invoice-example.client-countrycode'),
          name: this.intl.t('ui-kit.pdf-preview.invoice-example.client-name'),
          vatNumber: this.intl.t('ui-kit.pdf-preview.invoice-example.client-vat-number'),
          locale: document.organization.locale
        }
      };
      if (this.args.isQuote) {
        delete exampleDocument.performanceDate;
        delete exampleDocument.dueDate;
        exampleDocument.number = settings.numberingMode === AUTOMATIC_MODE ? settings.quoteNextNumberFormatted : this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-number');
        exampleDocument.header = settings.quoteHeader;
        exampleDocument.footer = settings.quoteFooter;
        exampleDocument.expiryDate = this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-due-date');
      }
      return exampleDocument;
    }
    return document;
  }
  get displayThreeColumns() {
    return this.document.organization?.companyLeadership || this.document.organization?.taxNumber || this.document.organization?.vatNumber;
  }
  get isForeignCurrency() {
    return this.document.currency && this.document.currency !== 'EUR';
  }
  get title() {
    if (this.args.isQuote) {
      return this.intl.t('ui-kit.pdf-preview.quote-title', {
        language: this.displayLanguage
      });
    } else if (this.isDraftTitleDisplayed) {
      return this.intl.t('ui-kit.pdf-preview.draft.title', {
        language: this.displayLanguage
      });
    } else {
      return this.intl.t('ui-kit.pdf-preview.title', {
        language: this.displayLanguage
      });
    }
  }
  get isDraftTitleDisplayed() {
    let {
      status
    } = this.document;
    let {
      origin
    } = this.args;
    return status === DRAFT_STATUS && !(origin === INVOICE_MODAL || origin === INVOICE_FORM);
  }
  get displayedNumber() {
    let {
      numberingMode,
      origin,
      settings
    } = this.args;
    let {
      number,
      status
    } = this.document;
    if (origin === INVOICE_FORM && numberingMode === AUTOMATIC_MODE) {
      return this.args.isQuote ? settings?.quoteNextNumberFormatted : settings?.invoiceNextNumberFormatted;
    }
    if (status === DRAFT_STATUS && origin === INVOICE_MODAL && numberingMode === AUTOMATIC_MODE) {
      return this.intl.t('ui-kit.pdf-preview.draft.invoice-number-draft-value');
    }
    return number;
  }
  get organizationAddress() {
    let address = this.document.organization?.address;
    return address = address?.firstLine.concat(address?.secondLine ? ` ${address?.secondLine}` : '').concat(address?.thirdLine ? ` ${address?.thirdLine}` : '');
  }
  get customerCountryName() {
    if (!this.args.customer && !this.isExampleContent) {
      return '';
    }
    let code = this.args.customer?.countryCode || this.document.customer?.countryCode;
    if (!code) {
      return '';
    }
    return this.intl.t(`ui-kit.countries.${code}`);
  }
  get customerBillingAddressCountryName() {
    if (!this.args.customer && !this.isExampleContent) {
      return '';
    }
    let code = this.args.customer?.billingAddress?.countryCode || this.document.customer?.billingAddress?.countryCode || this.args.customer?.get('billingAddress')?.countryCode || this.document.customer?.get('billingAddress')?.countryCode;
    return code ? this.intl.t(`ui-kit.countries.${code}`) : '';
  }
  get customerDeliveryAddressCountryName() {
    if (!this.args.customer && !this.isExampleContent) {
      return '';
    }
    let code = this.args.customer?.deliveryAddress?.countryCode || this.document.customer?.deliveryAddress?.countryCode || this.args.customer?.get('deliveryAddress')?.countryCode || this.document.customer?.get('deliveryAddress')?.countryCode;
    return code ? this.intl.t(`ui-kit.countries.${code}`) : '';
  }
  get customer() {
    let {
      customer,
      customerSnapshot
    } = this.args;

    // DEPRECATED: remove once consumers are updated
    if (!customer && !customerSnapshot) {
      warn('PdfPreview: providing the customer through the @invoice record is deprecated. Please use @customerSnapshot or @customer!', {
        id: 'ui-kit.pdf-preview-customer'
      });
      return this.document.customerSnapshot && !this.args.useCustomerRecord ? this.document.customerSnapshot : this.document.customer;
    }
    let isUsingCustomerRecord = Boolean(customer);
    return this.args.customerSnapshot && !isUsingCustomerRecord ? this.args.customerSnapshot : this.args.customer;
  }
  get issueDate() {
    let {
      issueDate
    } = this.document;
    return this._formatDate(issueDate);
  }
  get performanceDate() {
    let {
      performanceDate
    } = this.document;
    return this._formatDate(performanceDate);
  }
  get dueOrExpiryDate() {
    if (this.args.isQuote) {
      let {
        expiryDate
      } = this.document;
      return this._formatDate(expiryDate);
    }
    let {
      dueDate
    } = this.document;
    return this._formatDate(dueDate);
  }
  get differenceIssueEndDate() {
    let {
      expiryDate,
      issueDate,
      dueDate
    } = this.document;
    if (this.args.isQuote) {
      return Math.ceil(Math.abs(new Date(expiryDate) - new Date(issueDate)) / (1000 * 60 * 60 * 24));
    }
    return Math.ceil(Math.abs(new Date(dueDate) - new Date(issueDate)) / (1000 * 60 * 60 * 24));
  }
  get dueOrExpiryDateSentence() {
    if (this.args.isQuote) {
      if (this.isExampleContent) {
        return this.intl.t('ui-kit.pdf-preview.invoice-example.quote-validated-time');
      }
      return this.intl.t('ui-kit.pdf-preview.quote-validated-time', {
        number: this.differenceIssueEndDate,
        language: this.displayLanguage,
        expiry_date: this.dueOrExpiryDate,
        days_amount: this.differenceIssueEndDate
      });
    }
    if (this.isExampleContent) {
      return this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-payment-time');
    }
    return this.intl.t('ui-kit.pdf-preview.invoice-payment-time', {
      number: this.differenceIssueEndDate,
      language: this.displayLanguage,
      dueDate: this.dueOrExpiryDate,
      days_amount: this.differenceIssueEndDate
    });
  }
  get uppercaseLocale() {
    return this.document.organization?.legalCountry?.toUpperCase() ?? DEFAULT_LOCALE;
  }
  get isDraftInvoice() {
    return this.document.status === DRAFT_STATUS;
  }
  get shouldDisplayDraftDisclaimer() {
    return this.document.organization?.legalCountry?.toUpperCase() === 'IT' && this.isDraftTitleDisplayed;
  }
  get totalsTableMarginBottomClass() {
    if (this.shouldDisplayStampDutyDisclaimer) return 'mb-8';
    if (this.shouldDisplayDraftDisclaimer) return 'mb-40';
    return 'mb-16';
  }
  get displayLanguage() {
    if (this.isExampleContent) {
      return this.args.userLocale || this.document.locale;
    }

    // DEPRECATED: remove once consumers are updated
    if (!this.args.customer && !this.args.customerSnapshot) {
      let customerLocale = this.document.locale && !this.args.useCustomerRecord ? this.document.locale : this.document.customer?.get('locale');
      return customerLocale ?? this.intl?.primaryLocale;
    }
    let isUsingCustomerRecord = Boolean(this.args.customer);
    let customerLocale = this.document.locale && !isUsingCustomerRecord ? this.document.locale : this.customer.locale;
    return customerLocale ?? this.intl?.primaryLocale;
  }
  get relatedQuoteNumber() {
    return this.document.quote?.get?.('number') || this.document.quote?.number;
  }
  get vatExemptionReasons() {
    let reasons = [];
    let items = this.document?.items || [];
    if (items.length > 0) {
      for (let element of items) {
        // save all the reasons
        if (element?.vatExemptionCode) {
          let reason = this.intl.t(`ui-kit.pdf-preview.exemption.DE.${element?.vatExemptionCode}`, {
            language: this.displayLanguage
          });

          // but not duplicated reason
          if (!reasons.includes(reason)) {
            reasons.push(reason);
          }
        }
      }
    }
    return reasons;
  }
  _formatDate(value) {
    if (this.isExampleContent) {
      return value;
    }
    let date = dayjs(value);
    if (dayjs(date).isValid()) {
      return date.format(DATE_FORMAT);
    }
    return '';
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { PdfPreviewDe24 as default };