export default {
  "container": "_container_16dakb",
  "disabled": "_disabled_16dakb",
  "note": "_note_16dakb",
  "ignored-icon": "_ignored-icon_16dakb",
  "title": "_title_16dakb",
  "operation": "_operation_16dakb",
  "menu": "_menu_16dakb",
  "trigger": "_trigger_16dakb",
  "content": "_content_16dakb",
  "is-ignored": "_is-ignored_16dakb",
  "is-done": "_is-done_16dakb"
};
