export default {
  "container": "_container_12fc5e",
  "outlet-container": "_outlet-container_12fc5e",
  "side-container": "_side-container_12fc5e",
  "logo": "_logo_12fc5e",
  "project-name": "_project-name_12fc5e",
  "content-container": "_content-container_12fc5e",
  "link": "_link_12fc5e",
  "dashboard-settings-menu": "_dashboard-settings-menu_12fc5e"
};
