import Store from '@ember-data/store';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import ToastFlashMessagesService from 'app/services/toast-flash-messages';

import FixturesService from 'qonto-switching-bank-js/services/fixtures';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';

interface ConsentModalContentArgs {
  close: () => void;
}

export default class ConsentModalContentComponent extends Component<ConsentModalContentArgs> {
  @service('migration') declare migrationService: MigrationService;
  @service declare store: Store;
  @service declare fixtures: FixturesService;
  @service declare segment: SegmentService;
  @service declare router: RouterService;
  @service declare network: NetworkService;
  @service declare toastFlashMessages: ToastFlashMessagesService;

  @tracked checked = false;

  @action toggleCheckbox() {
    this.checked = !this.checked;
  }

  @action close() {
    this.checked = false;
    this.args.close();
  }

  @action confirm() {
    this.segment.track('bankswitch_consent_validated');

    this.network
      .post('/provider/users')
      .then(() => {
        this.fixtures.resetFixturesStore();
        this.router.transitionTo('onboarding.select-bank.index');
      })
      .catch(() => {
        this.toastFlashMessages.defaultToastError();
      })
      .finally(() => this.close());
  }
}
