import Model, { attr } from '@ember-data/model';

import {
  Category,
  LastTransaction,
  Status,
  TransactionMethod,
  TransactionType,
} from 'qonto-switching-bank-js/types/action';

export default class ActionModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare migration_id: string;
  @attr('string') declare status: Status;
  @attr('string') declare transaction_method: TransactionMethod;
  @attr('string') declare transaction_type: TransactionType;
  @attr('date') declare created_at: Date;
  @attr('date') declare updated_at: Date;
  @attr('string') declare category?: Category;
  @attr('number') declare transactions_count: number;
  @attr('string') declare note: string;
  @attr('boolean') declare is_important: boolean;
  @attr({ defaultValue: () => ({ date: null, amount: null }) })
  declare last_transaction: LastTransaction;

  get iconName() {
    return this.category || 'other_expense';
  }

  get methodName() {
    switch (this.transaction_method) {
      case 'core':
      case 'b2b':
        return 'mandates';
      case 'transfer':
        return 'transfers';
      case 'card':
        return 'cards';
      default:
        return 'others';
    }
  }

  get noteLines() {
    if (this.note) {
      return this.note.replace('\r\n', '\n').replace('\r', '\n').split('\n');
    } else {
      return [];
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    action: ActionModel;
  }
}
