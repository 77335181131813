import Service from '@ember/service';

export default class OrganizationService extends Service {
  init() {
    super.init(...arguments);
  }

  setSelectedOrganization(selectedOrganizationId) {
    localStorage.setItem('organizationId', selectedOrganizationId);
  }
}
