export default {
  "step": "_step_1vq4rl",
  "title-line": "_title-line_1vq4rl",
  "bubble": "_bubble_1vq4rl",
  "active": "_active_1vq4rl",
  "approved": "_approved_1vq4rl",
  "submitted": "_submitted_1vq4rl",
  "rejected": "_rejected_1vq4rl",
  "title": "_title_1vq4rl body-1",
  "checkmark": "_checkmark_1vq4rl",
  "right": "_right_1vq4rl",
  "content": "_content_1vq4rl",
  "description": "_description_1vq4rl body-2",
  "selector": "_selector_1vq4rl",
  "selector-title": "_selector-title_1vq4rl"
};