import { assert } from '@ember/debug';
import Component from '@glimmer/component';
export const ALLOWED_VARIANTS = ['default', 'accent'];
export default class ToggleButtonComponent extends Component {
  get checkedIndex() {
    return this.args.items.findIndex(item => item.value === this.args.value);
  }
  get variant() {
    let variant = this.args.variant ?? 'default';
    assert(`<ToggleButton /> can only have these variants: ${ALLOWED_VARIANTS.join(', ')}.`, ALLOWED_VARIANTS.includes(variant));
    return variant;
  }
  get items() {
    assert(`<ToggleButton /> can only have 2 items.`, this.args.items.length === 2);
    return this.args.items;
  }
}