import RESTAdapter from '@ember-data/adapter/rest';
import { inject as service } from '@ember/service';

import { apiBaseURL } from 'qonto-switching-bank-js/constants/hosts';

export default class ParentBankAdapter extends RESTAdapter {
  @service network;

  host = apiBaseURL;
  namespace = 'banks';

  findAll() {
    return this.network.get(`/banks/parents`).then(response => {
      return { 'parent-banks': response.data };
    });
  }
}
