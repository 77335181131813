import Controller from '@ember/controller';

import { AppointmentCategory } from './types';

/**
 * queryParam 'category' can have one of these values :
 * 'classic' (by default) : Classic migration appointment that occur in the onboarding flow
 * 'information' : Appointment that can be choose by the user before he start the onboarding
 * 'help' : When the user is blocked, the user can set an helping appointment
 */
export default class AppointmentController extends Controller {
  queryParams = ['category'];
  category: AppointmentCategory = 'classic';

  get showBackButton() {
    return this.category === 'classic';
  }

  get wordings() {
    let base = this.category === 'information' ? 'information_appointment' : 'appointment';

    return { title: `${base}.calendar_title`, subtitle: `${base}.calendar_subtitle` };
  }
}
