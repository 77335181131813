import Component from '@glimmer/component';
export default class FormField extends Component {
  get hasError() {
    let {
      message,
      messageLevel
    } = this.args;
    let hasMessage = false;
    // @message can potentially be a SafeString object when using the `htmlSafe=true` option in `intl.t()`
    // eg: `@message={{t "my.key" htmlSafe=true}}`

    if (message) {
      hasMessage = typeof message === 'string' ? message.length > 0 : message.toString().length > 0;
    }
    return messageLevel === 'error' && hasMessage;
  }
}