import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ChooseDashboardAccessRoute extends Route {
  @service declare store: Store;

  async model() {
    return { migration: await this.store.queryRecord('migration', {}) };
  }
}
