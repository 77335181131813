import Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';
import IntlService from 'ember-intl/services/intl';

import { authorizedRoutes } from 'qonto-switching-bank-js/constants/migration';
import MigrationModel from 'qonto-switching-bank-js/models/migration';
import Router from 'qonto-switching-bank-js/router';
import FixturesService from 'qonto-switching-bank-js/services/fixtures';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { PROVIDER_ITEM_REFRESH_STATUS } from 'qonto-switching-bank-js/types/credentials';
import {
  MigrationStatus,
  ProviderConnectionStatusDetails,
} from 'qonto-switching-bank-js/types/migration';

import ToastFlashMessagesService from './toast-flash-messages';
export default class EntryPointRouteService extends Service {
  @service declare store: Store;
  @service declare modals: typeof ModalsService;
  @service declare network: NetworkService;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare segment: SegmentService;
  @service declare fixtures: FixturesService;

  async getNewFunnelUrl(bank_id: string) {
    try {
      let response = await this.network.post('/connect', null, { params: { bank_id } });

      return response.data.redirect_url;
    } catch (error) {
      if (error?.response?.data?.message === 'Country not supported') {
        this.toastFlashMessages.toastError(this.intl.t('toast.not_available_for_country'));
      } else {
        this.toastFlashMessages.defaultToastError();
      }
    }
  }

  // TODO : Remove '?' in parameter migration_id type when 'migration-succeeded' behaviour is changed
  async getCurrentFunnelUrl(migration_id?: string) {
    try {
      let response = await this.network.post('/connect', null, { params: { migration_id } });

      return response.data.redirect_url;
    } catch (error) {
      if (error?.response?.data?.message === 'Country not supported') {
        this.toastFlashMessages.toastError(this.intl.t('toast.not_available_for_country'));
      } else {
        this.toastFlashMessages.defaultToastError();
      }
    }
  }

  shouldShowErrorPage(migration: MigrationModel, targetRoute?: string) {
    return (
      targetRoute !== 'onboarding.appointment' &&
      migration.hasError &&
      (migration.status === 'created' || migration.status === 'synchronizing') &&
      migration.provider_connection_status_details !== 'login_failed' &&
      migration.provider_connection_status_details !== 'needs_manual_refresh'
    );
  }

  statusErrorHaveDedicatedPage(errorStatus: ProviderConnectionStatusDetails) {
    return [
      'needs_human_action',
      'could_not_refresh',
      'not_supported',
      'pro_account_locked',
    ].includes(errorStatus);
  }

  getMigrationErrorRoute(error: ProviderConnectionStatusDetails) {
    switch (error) {
      case 'needs_human_action':
        return 'errors.needs-human-action';
      case 'could_not_refresh':
        return 'errors.could-not-refresh';
      case 'not_supported':
        return 'errors.not-supported';
      default:
        return 'errors.maintenance';
    }
  }

  async getEntryPoint(migration?: MigrationModel, targetRoute?: string): Promise<string> {
    let entryPointRoute = '';

    if (migration && this.shouldShowErrorPage(migration, targetRoute)) {
      let statusErrorHaveDedicatedPage = this.statusErrorHaveDedicatedPage(
        migration.provider_connection_status_details
      );

      if (statusErrorHaveDedicatedPage) {
        return this.getMigrationErrorRoute(migration.provider_connection_status_details);
      } else {
        Sentry.captureMessage(
          `A migration have an unhandled provider connection status error : ${migration.provider_connection_status_details} | ${migration.extended_provider_connection_status}`
        );
      }
    }

    if (targetRoute && authorizedRoutes(migration?.status).includes(targetRoute)) {
      entryPointRoute = targetRoute;
    } else {
      entryPointRoute = await this.defaultRoute(migration?.status);
    }

    return entryPointRoute;
  }

  async find_synchronizing_route(): Promise<string> {
    try {
      let refreshStatusResponse = await this.network.get('/provider/item/refresh_status');
      let providerItemRefreshInfo = refreshStatusResponse.data;

      switch (providerItemRefreshInfo.status) {
        case PROVIDER_ITEM_REFRESH_STATUS.INFO_REQUIRED:
        case PROVIDER_ITEM_REFRESH_STATUS.INVALID_CREDS:
          return 'onboarding.select-bank.child.credentials';
        case PROVIDER_ITEM_REFRESH_STATUS.FINISHED:
          if (providerItemRefreshInfo.status_details === 'pro_account_locked') {
            return 'onboarding.synchronization-in-progress';
          }
          return 'onboarding.synchronization-success';
        case PROVIDER_ITEM_REFRESH_STATUS.RETRIEVING_DATA:
          return 'onboarding.synchronization-in-progress';
        case PROVIDER_ITEM_REFRESH_STATUS.FINISHED_ERROR:
          return 'onboarding.select-bank.child.credentials';
        default:
          Sentry.captureMessage(
            `A migration have an unhandled provider connection status error: ${providerItemRefreshInfo.status}`
          );
          return 'errors.maintenance';
      }
    } catch {
      return 'onboarding.select-bank.child.credentials';
    }
  }

  async transitionToCredentialsWithCurrentMigration(currentMigration: MigrationModel) {
    let response = await this.network.get(
      `/banks/children_by_provider_bank_id/${currentMigration?.bank_id}`
    );
    let children = response.data;
    this.router.transitionTo(
      'onboarding.select-bank.child.credentials',
      children.parent_bank_id,
      children.id
    );
  }

  async defaultRoute(status: MigrationStatus | undefined): Promise<string> {
    if (status) {
      switch (status) {
        case 'synchronizing':
          return await this.find_synchronizing_route();
        case 'created':
          return 'onboarding.select-accounts';
        case 'synchronized':
          return 'onboarding.choose-dashboard-access';
        case 'call_planned':
          return 'onboarding.migration-status';
        case 'dashboard_generation':
          return 'onboarding.waiting-dashboard';
        case 'empty_dashboard':
          return 'onboarding.dashboard-empty';
        case 'in_progress':
          return 'dashboard';
        case 'finished':
          return 'onboarding.migration-succeeded';
      }
    } else {
      return 'dashboard';
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    entryPointRoute: EntryPointRouteService;
  }
}
