import { buildTask as _buildTask } from "ember-concurrency/-private/async-arrow-runtime";
var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable @qonto/requires-task-function-syntax, ember-concurrency/require-task-name-suffix */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Ember from 'ember';
import { restartableTask, timeout } from 'ember-concurrency';
const MINIMUM_CHARACTERS_FOR_SEARCH = 5;
const DEBOUNCE_TIMEOUT = 200;

/**
 * A text field. When the user fills it out, it will perform a search using
 * Geocode earth Search, showing a dropdown with matching addresses. The user can
 * select one of these in order to complete the field.
 *
 * ```hbs
 * <FormElements::AddressSearchInput
 *   @placeholder="Enter an address"
 *   @loadingMessage="Searching..."
 *   @inputId="customId"
 *   @availableCountries={{this.availableCountries}}
 *   @select={{this.onResultSelected}}
 *   @update={{this.onValueChanged}}
 *   @value={{this.street}}
 *   @required={{true}}
 * />
 * ```
 *
 * @class FormElements::AddressSearchInput
 * @public
 **/
let FormElementsAddressSearchInput = (_class = class FormElementsAddressSearchInput extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "geocodeEarth", _descriptor, this);
    /**
     * Text showing on the input field when empty.
     * Styled to convey that it's not a real value.
     *
     * @property placeholder
     * @type string
     */
    _defineProperty(this, "placeholder", this.args.placeholder ?? null);
    /**
     * Whether to set the `required` attribute on the input field.
     *
     * @property required
     * @type boolean
     */
    _defineProperty(this, "required", this.args.required ?? false);
    /**
     * Whether to set the `disabled` attribute on the input field.
     *
     * @property disabled
     * @type boolean
     */
    _defineProperty(this, "disabled", this.args.disabled ?? false);
    /**
     * Whether to set the `readonly` attribute on the input field.
     *
     * @property readonly
     * @type boolean
     */
    _defineProperty(this, "readonly", this.args.readonly ?? false);
    /**
     * Message Message to show while waiting for results.
     *
     * @property loadingMessage
     * @type string
     */
    _defineProperty(this, "loadingMessage", this.args.loadingMessage ?? '');
    /**
     * Id of the input element. This is useful to associate it to a label.
     *
     * @property inputId
     * @type ?string
     */
    _defineProperty(this, "inputId", this.args.inputId ?? null);
    /**
     * A list of countries that will be allowed as search results. If null, any
     * country is allowed.
     *
     * @property availableCountries
     * @type [{code: string}]
     */
    _defineProperty(this, "availableCountries", this.args.availableCountries ?? null);
    /**
     * Called when the user selects a search result.
     *
     * @property select
     * @required
     * @type function
     * @param choice {object} The selected search result.
     */
    _defineProperty(this, "select", this.args.select ?? null);
    /**
     * Results of the latest Geocode Earth search, shown in the dropdown.
     *
     * @private
     * @property _places
     * @type [result]
     */
    _initializerDefineProperty(this, "_places", _descriptor2, this);
    _defineProperty(this, "handleInputTask", _buildTask(() => ({
      context: this,
      generator: function* (value) {
        this.args.update?.(value);
        yield this.handleSearch.perform(value);
      }
    }), null, "handleInputTask", "restartable"));
    assert('<FormElements::AddressSearchInput> requires a `@select` callback', typeof this.select === 'function');
  }
  get maybeCountryCodes() {
    return this.availableCountries ? this.availableCountries.map(option => option.code.toLowerCase()) : null;
  }
  get noMatchesMessage() {
    if (this.handleSearch.isRunning) {
      return this.loadingMessage;
    }
    return '';
  }
  get customDropdownClass() {
    let hasPlaces = this._places.length > 0;
    let isShowingMessage = Boolean(this.noMatchesMessage);
    return !hasPlaces && !isShowingMessage ? 'address-search-input-wormhole address-search-input--hide-dropdown' : 'address-search-input-wormhole';
  }
  optionMatcher() {
    // We don't want Ember Power Select's matcher working here.
    return false;
  }

  /**
   * Perform a search on Geocode Earth. After completed, set the
   * results on the property `_places`. If no results, set this to
   * an empty array.
   * Debounced to 500ms.
   *
   * @method
   * @param value {string} The search query, expected to be the same as the
   *   current value of the field.
   */
  *handleSearch(value) {
    if (value.length < MINIMUM_CHARACTERS_FOR_SEARCH) {
      this._places = [];
      return;
    }
    if (!Ember.testing) {
      yield timeout(DEBOUNCE_TIMEOUT);
    }
    let options = {
      query: value
    };
    if (this.maybeCountryCodes) {
      options.countries = this.maybeCountryCodes;
    }
    let places;
    try {
      places = yield this.geocodeEarth.search(options);
    } catch (error) {
      // Ideally we should tell client code, but we don't have a well-defined
      // way to do so. Setting _places to empty and pretending that nothing
      // happened is ok. This will continue working as a normal input field.
      this._places = [];
      if (!this.handleSearch.lastErrored && error.cause?.status === 429) {
        throw new Error('geocode-earth: too many requests');
      }
      return;
    }
    places.forEach(place => {
      place.description = formatDescription(place);
    });
    this._places = places;
  }
  onBlurCustom(dropdown) {
    dropdown.actions.close();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "geocodeEarth", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_places", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _applyDecoratedDescriptor(_class.prototype, "handleSearch", [restartableTask, waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBlurCustom", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlurCustom"), _class.prototype)), _class);
export { FormElementsAddressSearchInput as default };
function formatDescription({
  street,
  postalCode,
  city,
  administrative,
  country
}) {
  return [street, postalCode, city, administrative, country].filter(Boolean).join(', ');
}