import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DashboardTypeActionController extends Controller {
  @service router;
  @service store;

  get backRoute() {
    return { route: 'dashboard.type', model: this.model.action.transaction_type };
  }

  @action
  changePage(targetedPage) {
    this.router.transitionTo({
      queryParams: { page: targetedPage },
    });
  }
}
