import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { LottiePlayer } from 'design-system-kit';

import { appURL } from 'qonto-switching-bank-js/constants/hosts';
import FunnelService from 'qonto-switching-bank-js/services/funnel';
import GuideBookDownloadService from 'qonto-switching-bank-js/services/guide-book-download';
import SegmentService from 'qonto-switching-bank-js/services/segment';

export default class MigrationSucceededController extends Controller {
  lottiePlayer = LottiePlayer;
  @service declare segment: SegmentService;
  @service('guide-book-download') declare guideBook: GuideBookDownloadService;
  @service declare funnel: FunnelService;

  appLink = appURL;

  @action
  connectAnotherBank() {
    this.funnel.startFunnel('FR');
    this.segment.track('bankswitch_new_migration_started');
  }

  @action async downloadFile() {
    this.guideBook.downloadFile();
  }
}
