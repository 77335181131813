import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import ToastFlashMessagesService from 'app/services/toast-flash-messages';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import ActionModel from 'qonto-switching-bank-js/models/action';
import ActionService from 'qonto-switching-bank-js/services/action';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { Category, TransactionMethod } from 'qonto-switching-bank-js/types/action';

import { actionCategories, actionTypes, CategoryOption, formLabels, TypeOption } from './wordings';

interface ActionFormArgs {
  action: ActionModel;
  isCreate: boolean;
  shouldDisplayDemoData: boolean;
  close: () => void;
}

interface InitialActionData {
  name: string;
  category?: Category;
  transaction_method: TransactionMethod;
  note: string;
}

export default class ActionFormComponent extends Component<ActionFormArgs> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service('action') declare actionService: ActionService;
  @service declare segment: SegmentService;

  @tracked action;
  declare initialData: InitialActionData;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(...arguments);
    this.action = this.args.action;

    if (this.args.shouldDisplayDemoData) {
      this.initialData = {
        name: this.action.name,
        category: this.action.category,
        transaction_method: this.action.transaction_method,
        note: this.action.note,
      };
    }
  }

  get isSubmittable() {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.action.hasDirtyAttributes &&
      this.action.name &&
      this.action.category &&
      this.action.transaction_method
    );
  }

  get labels() {
    return formLabels(this.args.isCreate);
  }

  get categories() {
    return actionCategories(this.args.isCreate);
  }
  get selectedCategory() {
    return this.categories.find(category => category.value === this.action.category);
  }
  @action selectCategory(category: CategoryOption) {
    this.action.category = category.value;
  }

  get types() {
    return actionTypes(this.action.transaction_type);
  }
  get selectedType() {
    return this.types.find(type => type.value === this.action.transaction_method);
  }
  @action selectType(type: TypeOption) {
    this.action.transaction_method = type.value;
  }

  @action closeAndRollback() {
    if (this.args.shouldDisplayDemoData) {
      this.action.setProperties(this.initialData);
    } else if (this.args.isCreate) {
      this.action.deleteRecord();
    } else {
      this.action.rollbackAttributes();
    }

    this.args.close();
  }

  @task handleActionFormSubmitTask = taskFor(function* (this: ActionFormComponent) {
    if (this.args.shouldDisplayDemoData) {
      this.args.close();
      this.toastFlashMessages.toastInfo(this.intl.t('dashboard.edit_action.toaster'));
    } else {
      try {
        let isNewRecord = this.args.isCreate;

        yield this.action.save();
        this.segment.track(isNewRecord ? 'bankswitch_action_created' : 'bankswitch_action_edited');
        this.args.close();

        this.toastFlashMessages.toastInfo(
          this.intl.t(`dashboard.${isNewRecord ? 'create' : 'edit'}_action.toaster`)
        );
      } catch {
        this.action.rollbackAttributes();
        this.toastFlashMessages.defaultToastError();
      }
    }
  });
}
