import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import ENV from 'qonto-switching-bank-js/config/environment';

function feature(key: string) {
  try {
    return window.localStorage.getItem(key) === 'true';
  } catch {
    return false;
  }
}

/**
  The frontend-features service is intended to be used to setup **temporary** feature flags on frontend only.

  Such flags should be used during development of features covered by multiple MRs to avoid long running feature branch.
  For feature flags defined on backend, you must use qonto/services/features-manager.
 */
export default class FrontendFeaturesService extends Service {
  @tracked funnelInHouseEnabled = feature('FUNNEL_IN_HOUSE');

  get isFunnelInHouseEnabled() {
    return this.funnelInHouseEnabled && ENV.deployTarget !== 'production';
  }
}
