import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';

import FlashMessages from 'ember-cli-flash/services/flash-messages';

export default class ToastFlashMessagesService extends FlashMessages {
  @service intl;

  /**
   * Triggers a toast info
   *
   * @public
   * @method toastInfo
   *
   * @param  {String} message Message to display
   * @param  {String} [iconName='checkmark-icon'] Icon to display
   * @returns void
   */
  toastInfo(message, iconName = 'icon_checkmark_rounded_filled') {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'toast',
      componentName: 'toast',
      type: 'info',
      priority: 101,
      iconName,
      message,
    }).getFlashObject();
  }

  /**
   * Triggers a success toast
   *
   * @public
   * @method toastSuccess
   *
   * @param  {String} message Message to display
   * @param  {String} [iconName='checkmark-icon'] Icon to display
   * @returns void
   */
  toastSuccess(message, iconName = 'icon_checkmark_rounded_filled') {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'toast',
      componentName: 'toast',
      type: 'success',
      priority: 100,
      iconName,
      message,
    }).getFlashObject();
  }

  /**
   * Triggers a toast error
   *
   * @public
   * @method toastError
   *
   * @param  {String} message Message to display
   * @param  {String} [iconName='circle_error'] Icon to display
   * @returns void
   */
  toastError(message, iconName = 'icon_error_filled') {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'toast',
      componentName: 'toast',
      type: 'error',
      priority: 102,
      iconName,
      message,
    }).getFlashObject();
  }

  defaultToastError() {
    this.toastError(this.intl.t('toast.default_error_message'));
  }

  clearToastMessages() {
    super.clearMessages();
  }
}
