var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { inject as service } from '@ember/service';
import { waitForPromise } from '@ember/test-waiters';
import BaseValidator from 'ember-cp-validations/validators/base';
function translationKeyLookup(input) {
  switch (input) {
    case 'Straight rows of keys are easy to guess':
    case 'Short keyboard patterns are easy to guess':
      return 'ui-kit.zxcvbn.password_spatial';
    case 'Repeats like "aaa" are easy to guess':
    case 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
      return 'ui-kit.zxcvbn.password_repeat';
    case 'Sequences like abc or 6543 are easy to guess':
      return 'ui-kit.zxcvbn.password_sequence';
    case 'Recent years are easy to guess':
    case 'Dates are often easy to guess':
      return 'ui-kit.zxcvbn.password_date';
    case 'This is a top-10 common password':
    case 'This is a top-100 common password':
      return 'ui-kit.zxcvbn.password_common';
    case 'This is a very common password':
    case 'This is similar to a commonly used password':
    case 'A word by itself is easy to guess':
    case 'Names and surnames by themselves are easy to guess':
    case 'Common names and surnames are easy to guess':
      return 'ui-kit.zxcvbn.password_dictionary';
  }
}
let zxcvbnValidator = (_class = class zxcvbnValidator extends BaseValidator {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
    _defineProperty(this, "_zxcvbn", null);
  }
  async validate(value) {
    if (!this._zxcvbn) {
      let lib = await waitForPromise(import('zxcvbn'));
      this._zxcvbn = lib.default;
    }
    let predicate = this._zxcvbn(value);
    if (predicate.score < 3 && predicate.feedback.warning) {
      let key = translationKeyLookup(predicate.feedback.warning);
      return this.intl.t(key);
    } else {
      return true;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { zxcvbnValidator as default };