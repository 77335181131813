import Component from '@glimmer/component';
export default class PowerSelectCustomsMultipleOption extends Component {
  get checked() {
    let {
      key,
      select
    } = this.args;
    let {
      selected = []
    } = select;
    return selected.some(option => key === option.key);
  }
}