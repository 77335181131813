var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
let DropdownComponent = (_class = class DropdownComponent extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "elementId", guidFor(this));
    _defineProperty(this, "buttonId", this.args.buttonId ?? `${this.elementId}-dropdown-trigger`);
    _defineProperty(this, "menuId", `${this.args.buttonId ?? this.elementId}-dropdown-menu`);
    _defineProperty(this, "ariaRole", this.args.ariaRole ?? 'menu');
    _initializerDefineProperty(this, "showMenu", _descriptor, this);
    _defineProperty(this, "targetAttachment", this.args.targetAttachment ?? 'bottom right');
    _defineProperty(this, "attachment", this.args.attachment ?? 'top right');
    _defineProperty(this, "constraints", [{
      to: 'scrollParent',
      attachment: this.args.constraintsAttachment ?? 'together'
    }]);
    _defineProperty(this, "targetSelector", `#${this.buttonId}`);
    _defineProperty(this, "exceptSelector", `${this.args.exceptSelector ? `${this.args.exceptSelector}, ` : ''}${this.targetSelector}`);
  }
  get localStyles() {
    let {
      quickActions,
      triggerMargin,
      zIndex,
      flexShrink
    } = this.args;
    let result = [];
    if (zIndex) result.push('z-index');
    if (flexShrink) result.push('flex-shrink');
    if (triggerMargin) result.push('ml-12');
    if (quickActions) result.push('quick-actions-trigger');
    if (quickActions && this.showMenu) result.push('active');
    return result.join(' ');
  }
  get api() {
    return {
      toggle: this.toggle,
      isOpen: this.showMenu,
      buttonId: this.buttonId,
      menuId: this.menuId
    };
  }
  toggle() {
    this.showMenu = !this.showMenu;
    this.args.toggle?.(this.showMenu);
  }
  handleEscape(e) {
    if (this.args.handleEscape && e.key === 'Escape') {
      this.toggle();
    }
  }
  onClickOutside() {
    this.showMenu = !this.showMenu;
    this.args.onClickOutside?.(this.showMenu);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showMenu", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "toggle", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleEscape", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleEscape"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickOutside", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickOutside"), _class.prototype)), _class);
export { DropdownComponent as default };