import { helper } from '@ember/component/helper';

export function formatAsPercent([value]) {
  // the percentage is in decimals, so 0.1111 needs to be converted in 11.11%
  let rate = value * 100;
  let preciseRate = Math.round(rate * 100) / 100;
  return preciseRate;
}

export default helper(formatAsPercent);
