// This util allow you to split a line of text in 3 subsequent one
// the splitter is the group characted `< >`.
// it will returns an array with the 3 elements --> 1 < 2 > 3 == [1,2,3]
// giving you the possibility to use the `2` element to put in a component
export default function splitText(text) {
  let match = text.match(/(.*)<([^>]+)>(.*)/);
  if (!match) {
    return [text];
  }
  return match.slice(1);
}