var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import calculatePosition from '../utils/calculate-position';
import { schedule } from '@ember/runloop';
import { macroCondition, isTesting, importSync } from '@embroider/macros';
import config from 'ember-get-config';
const UNINITIALIZED = {};
const IGNORED_STYLES = ['top', 'left', 'right', 'width', 'height'];
let BasicDropdown = (_class = class BasicDropdown extends Component {
  get horizontalPosition() {
    return this.args.horizontalPosition || 'auto'; // auto-right | right | center | left
  }
  get verticalPosition() {
    return this.args.verticalPosition || 'auto'; // above | below
  }
  get destination() {
    return this.args.destination || this._getDestinationId();
  }
  get disabled() {
    let newVal = this.args.disabled || false;
    if (this._previousDisabled !== UNINITIALIZED && this._previousDisabled !== newVal) {
      schedule('actions', () => {
        if (newVal && this.publicAPI.isOpen) {
          this.isOpen = false;
        }
        this.args.registerAPI && this.args.registerAPI(this.publicAPI);
      });
    }
    this._previousDisabled = newVal;
    return newVal;
  }
  get publicAPI() {
    return {
      uniqueId: this._uid,
      isOpen: this.isOpen,
      disabled: this.disabled,
      actions: this._actions
    };
  }

  // Lifecycle hooks
  constructor(owner, args) {
    super(owner, args);
    _initializerDefineProperty(this, "hPosition", _descriptor, this);
    _initializerDefineProperty(this, "vPosition", _descriptor2, this);
    _initializerDefineProperty(this, "top", _descriptor3, this);
    _initializerDefineProperty(this, "left", _descriptor4, this);
    _initializerDefineProperty(this, "right", _descriptor5, this);
    _initializerDefineProperty(this, "width", _descriptor6, this);
    _initializerDefineProperty(this, "height", _descriptor7, this);
    _initializerDefineProperty(this, "otherStyles", _descriptor8, this);
    _initializerDefineProperty(this, "isOpen", _descriptor9, this);
    _initializerDefineProperty(this, "renderInPlace", _descriptor10, this);
    _defineProperty(this, "previousVerticalPosition", void 0);
    _defineProperty(this, "previousHorizontalPosition", void 0);
    _defineProperty(this, "destinationElement", void 0);
    _defineProperty(this, "_uid", guidFor(this));
    _defineProperty(this, "_dropdownId", this.args.dropdownId || `ember-basic-dropdown-content-${this._uid}`);
    _defineProperty(this, "_previousDisabled", UNINITIALIZED);
    _defineProperty(this, "_actions", {
      open: this.open,
      close: this.close,
      toggle: this.toggle,
      reposition: this.reposition
    });
    if (this.args.onInit) {
      this.args.onInit(this.publicAPI);
    }
    this.args.registerAPI && this.args.registerAPI(this.publicAPI);
  }
  willDestroy() {
    super.willDestroy();
    if (this.args.registerAPI) {
      this.args.registerAPI(null);
    }
  }

  // Actions
  open(e) {
    if (this.isDestroyed) {
      return;
    }
    if (this.publicAPI.disabled || this.publicAPI.isOpen) {
      return;
    }
    if (this.args.onOpen && this.args.onOpen(this.publicAPI, e) === false) {
      return;
    }
    this.isOpen = true;
    this.args.registerAPI && this.args.registerAPI(this.publicAPI);
    let trigger = document.querySelector(`[data-ebd-id=${this.publicAPI.uniqueId}-trigger]`);
    if (trigger) {
      let parent = trigger.parentElement;
      if (parent) {
        parent.setAttribute("aria-owns", this._dropdownId);
      }
    }
  }
  close(e, skipFocus) {
    if (this.isDestroyed) {
      return;
    }
    if (this.publicAPI.disabled || !this.publicAPI.isOpen) {
      return;
    }
    if (this.args.onClose && this.args.onClose(this.publicAPI, e) === false) {
      return;
    }
    if (this.isDestroyed) {
      return; // To check that the `onClose` didn't destroy the dropdown
    }
    this.hPosition = this.vPosition = null;
    this.top = this.left = this.right = this.width = this.height = undefined;
    this.previousVerticalPosition = this.previousHorizontalPosition = undefined;
    this.isOpen = false;
    this.args.registerAPI && this.args.registerAPI(this.publicAPI);
    let trigger = document.querySelector(`[data-ebd-id=${this.publicAPI.uniqueId}-trigger]`);
    if (!trigger) {
      return;
    }
    let parent = trigger.parentElement;
    if (parent) {
      parent.removeAttribute("aria-owns");
    }
    if (skipFocus) {
      return;
    }
    if (trigger.tabIndex > -1) {
      trigger.focus();
    }
  }
  toggle(e) {
    if (this.publicAPI.isOpen) {
      this.close(e);
    } else {
      this.open(e);
    }
  }
  reposition() {
    if (!this.publicAPI.isOpen) {
      return;
    }
    let dropdownElement = document.getElementById(this._dropdownId);
    let triggerElement = document.querySelector(`[data-ebd-id=${this.publicAPI.uniqueId}-trigger]`);
    if (!dropdownElement || !triggerElement) {
      return;
    }
    this.destinationElement = this.destinationElement || document.getElementById(this.destination);
    let {
      horizontalPosition,
      verticalPosition,
      previousHorizontalPosition,
      previousVerticalPosition
    } = this;
    let {
      renderInPlace = false,
      matchTriggerWidth = false
    } = this.args;
    let calculatePositionFn = this.args.calculatePosition || calculatePosition;
    let positionData = calculatePositionFn(triggerElement, dropdownElement, this.destinationElement, {
      horizontalPosition,
      verticalPosition,
      previousHorizontalPosition,
      previousVerticalPosition,
      renderInPlace,
      matchTriggerWidth,
      dropdown: this
    });
    return this.applyReposition(triggerElement, dropdownElement, positionData);
  }
  applyReposition(_trigger, dropdown, positions) {
    let changes = {
      hPosition: positions.horizontalPosition,
      vPosition: positions.verticalPosition,
      otherStyles: Object.assign({}, this.otherStyles)
    };
    if (positions.style) {
      if (positions.style.top !== undefined) {
        changes.top = `${positions.style.top}px`;
      }
      // The component can be aligned from the right or from the left, but not from both.
      if (positions.style.left !== undefined) {
        changes.left = `${positions.style.left}px`;
        changes.right = undefined;
        // Since we set the first run manually we may need to unset the `right` property.
        if (positions.style.right !== undefined) {
          positions.style.right = undefined;
        }
      } else if (positions.style.right !== undefined) {
        changes.right = `${positions.style.right}px`;
        changes.left = undefined;
      }
      if (positions.style.width !== undefined) {
        changes.width = `${positions.style.width}px`;
      }
      if (positions.style.height !== undefined) {
        changes.height = `${positions.style.height}px`;
      }
      if (this.top === undefined) {
        // Bypass Ember on the first reposition only to avoid flickering.
        for (let prop in positions.style) {
          if (positions.style[prop] !== undefined) {
            if (typeof positions.style[prop] === 'number') {
              dropdown.style.setProperty(prop, `${positions.style[prop]}px`);
            } else {
              dropdown.style.setProperty(prop, `${positions.style[prop]}`);
            }
          }
        }
      }
    }
    for (let prop in positions.style) {
      if (!IGNORED_STYLES.includes(prop)) {
        changes.otherStyles;
        changes.otherStyles[prop] = positions.style[prop];
      }
    }
    this.hPosition = changes.hPosition;
    this.vPosition = changes.vPosition;
    this.top = changes.top;
    this.left = changes.left;
    this.right = changes.right;
    this.width = changes.width;
    this.height = changes.height;
    this.otherStyles = changes.otherStyles;
    this.previousHorizontalPosition = positions.horizontalPosition;
    this.previousVerticalPosition = positions.verticalPosition;
    return changes;
  }
  _getDestinationId() {
    // This takes care of stripping this code out if not running tests
    if (macroCondition(isTesting())) {
      if (typeof FastBoot === 'undefined') {
        try {
          let {
            getRootElement
          } = importSync('@ember/test-helpers');
          return getRootElement().id;
        } catch (error) {
          // use default below
        }
      }
      let rootView = document.querySelector('#ember-testing > .ember-view');
      if (rootView) {
        return rootView.id;
      }
      return '';
    }
    const _config = config;
    return _config['ember-basic-dropdown'] && _config['ember-basic-dropdown'].destination || 'ember-basic-dropdown-wormhole';
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hPosition", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "vPosition", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "top", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "left", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "right", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "width", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "height", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "otherStyles", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.initiallyOpened || false;
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "renderInPlace", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.renderInPlace !== undefined ? this.args.renderInPlace : false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "open", [action], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reposition", [action], Object.getOwnPropertyDescriptor(_class.prototype, "reposition"), _class.prototype)), _class);
export { BasicDropdown as default };