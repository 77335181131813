import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

import { appURL } from 'qonto-switching-bank-js/constants/hosts';

export default class MobileDisplayComponent extends Component {
  appLink = appURL;
  lottiePlayer = LottiePlayer;
}
