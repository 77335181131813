import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/browser';
import window from 'ember-window-mock';

import ENV from 'qonto-switching-bank-js/config/environment';
import {
  appWithDeeplink,
  authBaseURL,
  authUserNamespace,
} from 'qonto-switching-bank-js/constants/hosts';

export default class IndexRoute extends Route {
  @service session;
  @service network;
  @service router;
  @service entryPointRoute;
  @service('organization') organizationService;
  @service refreshSessionManager;
  @service localeManager;
  @service store;
  @service zendeskWidget;
  @service zendeskLocalization;

  @tracked isAuthenticated = false;

  queryParams = {
    organizationId: {
      refreshModel: true,
    },
  };

  requireAuthentication(transition) {
    this.session.requireAuthentication(transition, () => {
      transition.abort();

      let deeplink = appWithDeeplink;

      // We don't need to do this in production, because the `appWithDeeplink` will point to the
      // right place, but it isn't the case for review apps.
      if (ENV.deployTarget !== 'production') {
        deeplink = addReturnURLToDeepLink(appWithDeeplink, window.location.href);
      }

      window.location.replace(deeplink);
    });
  }

  async beforeModel(transition) {
    await this.session.setup();

    setSentryTransaction(transition);
    this.router.on('routeWillChange', transition => setSentryTransaction(transition));
    this.router.on('routeDidChange', transition => setSentryTransaction(transition));

    try {
      let auth = await this.network.put(`${authBaseURL}/${authUserNamespace}/users/sessions`);

      if (auth.status === 200) {
        this.isAuthenticated = true;
      }
    } catch {
      if (appWithDeeplink) {
        this.requireAuthentication(transition);
      }
    }
  }

  async model(params, transition) {
    let user = await this.store.queryRecord('user', {});
    let targetRoute = transition?.to?.name;
    let organizationId = params?.organizationId;
    let migration = undefined;

    if (organizationId) {
      this.organizationService.setSelectedOrganization(organizationId);
      localStorage.setItem('organizationId', organizationId); // Ensure it's stored for later use
    } else {
      organizationId = localStorage.getItem('organizationId');
    }

    if (!organizationId) {
      if (targetRoute !== 'onboarding.choose-organization') {
        this.router.transitionTo('onboarding.choose-organization');
      }

      return { migration, user };
    }

    if (targetRoute !== 'errors.maintenance' && targetRoute !== 'errors.unauthorized') {
      migration = await this.store.queryRecord('migration', {});
      let entryPointRoute = await this.entryPointRoute.getEntryPoint(migration, targetRoute);

      // Check if we have to make a transition to a route different of the current
      if (targetRoute !== entryPointRoute) {
        if (entryPointRoute === 'onboarding.select-bank.child.credentials') {
          await this.entryPointRoute.transitionToCredentialsWithCurrentMigration(migration);
        } else {
          this.router.transitionTo(entryPointRoute);
        }
      }
    }

    return { migration, user, organizationId };
  }

  async afterModel(model) {
    let urlParams = new URLSearchParams(window.location.search);
    let success = urlParams.get('success');
    let step = urlParams.get('step');

    await this.localeManager.setLocale(model.user.locale);

    if (success === 'false') {
      Sentry.captureMessage(
        `Redirection issue: The user land on redirect with a success status : ${success} and step : ${step}`
      );
    }

    if (model.organizationId) {
      let organization = await this.store.findRecord('organization', model.organizationId);

      this.zendeskLocalization.organizationCountry = organization?.legal_country;

      await this.zendeskWidget.selectCountry(organization?.legal_country);
    } else {
      // console.error('Organization ID is undefined');
    }
  }

  @action error(error, transition) {
    if (error.response?.status === 401) {
      let errorMessage = error.response?.data?.message || error.response?.data?.errors;
      let targetRoute = transition?.to?.name;
      let organizationId = localStorage.getItem('organizationId');

      switch (errorMessage) {
        case 'Authenticable::InvalidRoleError':
          if (targetRoute !== 'errors.unauthorized') {
            this.router.transitionTo('errors.unauthorized');
          }
          break;
        case 'Authenticable::InvalidOrganizationError':
          localStorage.removeItem('organizationId');
          if (targetRoute !== 'onboarding.choose-organization') {
            Sentry.captureMessage(`Invalid organization ID "${organizationId}"`);
            this.router.transitionTo('onboarding.choose-organization');
          }
          break;
        default:
          window.location.replace(appWithDeeplink);
          break;
      }

      return false;
    }

    return true;
  }

  @action willTransition(transition) {
    if (!this.isAuthenticated) {
      this.requireAuthentication(transition);
    }
  }

  activate() {
    super.activate(...arguments);
    this.refreshSessionManager.startActivityTracking();
  }

  deactivate() {
    super.deactivate(...arguments);
    this.refreshSessionManager.cancelActivityTracking();
  }
}

function setSentryTransaction(transition) {
  let name = transition.to?.name;
  if (name) {
    Sentry.configureScope(scope => scope.setTransactionName(name));
  }
}

function addReturnURLToDeepLink(deeplink, returnURL) {
  let url = new URL(deeplink);
  url.searchParams.append('return_url', returnURL);

  return url.toString();
}
