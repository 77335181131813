var _class;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { INPUT_TYPES } from '../../constants/form-fields/text-field';
let TextField = (_class = class TextField extends Component {
  constructor() {
    super(...arguments);
    assert('<TextField/> can only have a "text", "password" or "email" type', INPUT_TYPES.includes(this.type));
  }
  get type() {
    return this.args.type || 'text';
  }
  get autocomplete() {
    return this.args.autocomplete ?? 'off';
  }
  get hasError() {
    let {
      message,
      messageLevel
    } = this.args;
    let hasMessage = false;
    // @message can potentially be a SafeString object when using the `htmlSafe=true` option in `intl.t()`
    // eg: `@message={{t "my.key" htmlSafe=true}}`

    if (message) {
      hasMessage = typeof message === 'string' ? message.length > 0 : message.toString().length > 0;
    }
    return messageLevel === 'error' && hasMessage;
  }
  handleInput(event) {
    let {
      value
    } = event.target;
    this.args.update(value);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "handleInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class);
export { TextField as default };