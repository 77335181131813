export default {
  "flag": "_flag_1rhbmw",
  "be": "_be_1rhbmw",
  "ch": "_ch_1rhbmw",
  "mc": "_mc_1rhbmw",
  "ne": "_ne_1rhbmw",
  "np": "_np_1rhbmw",
  "va": "_va_1rhbmw",
  "ac": "_ac_1rhbmw",
  "ad": "_ad_1rhbmw",
  "ae": "_ae_1rhbmw",
  "af": "_af_1rhbmw",
  "ag": "_ag_1rhbmw",
  "ai": "_ai_1rhbmw",
  "al": "_al_1rhbmw",
  "am": "_am_1rhbmw",
  "ao": "_ao_1rhbmw",
  "aq": "_aq_1rhbmw",
  "ar": "_ar_1rhbmw",
  "as": "_as_1rhbmw",
  "at": "_at_1rhbmw",
  "au": "_au_1rhbmw",
  "aw": "_aw_1rhbmw",
  "ax": "_ax_1rhbmw",
  "az": "_az_1rhbmw",
  "ba": "_ba_1rhbmw",
  "bb": "_bb_1rhbmw",
  "bd": "_bd_1rhbmw",
  "bf": "_bf_1rhbmw",
  "bg": "_bg_1rhbmw",
  "bh": "_bh_1rhbmw",
  "bi": "_bi_1rhbmw",
  "bj": "_bj_1rhbmw",
  "bl": "_bl_1rhbmw",
  "bm": "_bm_1rhbmw",
  "bn": "_bn_1rhbmw",
  "bo": "_bo_1rhbmw",
  "bq": "_bq_1rhbmw",
  "br": "_br_1rhbmw",
  "bs": "_bs_1rhbmw",
  "bt": "_bt_1rhbmw",
  "bv": "_bv_1rhbmw",
  "bw": "_bw_1rhbmw",
  "by": "_by_1rhbmw",
  "bz": "_bz_1rhbmw",
  "ca": "_ca_1rhbmw",
  "cc": "_cc_1rhbmw",
  "cd": "_cd_1rhbmw",
  "cf": "_cf_1rhbmw",
  "cg": "_cg_1rhbmw",
  "ci": "_ci_1rhbmw",
  "ck": "_ck_1rhbmw",
  "cl": "_cl_1rhbmw",
  "cm": "_cm_1rhbmw",
  "cn": "_cn_1rhbmw",
  "co": "_co_1rhbmw",
  "cp": "_cp_1rhbmw",
  "cq": "_cq_1rhbmw",
  "cr": "_cr_1rhbmw",
  "cu": "_cu_1rhbmw",
  "cv": "_cv_1rhbmw",
  "cw": "_cw_1rhbmw",
  "cx": "_cx_1rhbmw",
  "cy": "_cy_1rhbmw",
  "cz": "_cz_1rhbmw",
  "de": "_de_1rhbmw",
  "dg": "_dg_1rhbmw",
  "dj": "_dj_1rhbmw",
  "dk": "_dk_1rhbmw",
  "dm": "_dm_1rhbmw",
  "do": "_do_1rhbmw",
  "dz": "_dz_1rhbmw",
  "ea": "_ea_1rhbmw",
  "ec": "_ec_1rhbmw",
  "ee": "_ee_1rhbmw",
  "eg": "_eg_1rhbmw",
  "eh": "_eh_1rhbmw",
  "er": "_er_1rhbmw",
  "es": "_es_1rhbmw",
  "et": "_et_1rhbmw",
  "eu": "_eu_1rhbmw",
  "ez": "_ez_1rhbmw",
  "fi": "_fi_1rhbmw",
  "fj": "_fj_1rhbmw",
  "fk": "_fk_1rhbmw",
  "fm": "_fm_1rhbmw",
  "fo": "_fo_1rhbmw",
  "fr": "_fr_1rhbmw",
  "fx": "_fx_1rhbmw",
  "ga": "_ga_1rhbmw",
  "gb": "_gb_1rhbmw",
  "gd": "_gd_1rhbmw",
  "ge": "_ge_1rhbmw",
  "gf": "_gf_1rhbmw",
  "gg": "_gg_1rhbmw",
  "gh": "_gh_1rhbmw",
  "gi": "_gi_1rhbmw",
  "gl": "_gl_1rhbmw",
  "gm": "_gm_1rhbmw",
  "gn": "_gn_1rhbmw",
  "gp": "_gp_1rhbmw",
  "gq": "_gq_1rhbmw",
  "gr": "_gr_1rhbmw",
  "gs": "_gs_1rhbmw",
  "gt": "_gt_1rhbmw",
  "gu": "_gu_1rhbmw",
  "gw": "_gw_1rhbmw",
  "gy": "_gy_1rhbmw",
  "hk": "_hk_1rhbmw",
  "hm": "_hm_1rhbmw",
  "hn": "_hn_1rhbmw",
  "hr": "_hr_1rhbmw",
  "ht": "_ht_1rhbmw",
  "hu": "_hu_1rhbmw",
  "ic": "_ic_1rhbmw",
  "id": "_id_1rhbmw",
  "ie": "_ie_1rhbmw",
  "il": "_il_1rhbmw",
  "im": "_im_1rhbmw",
  "in": "_in_1rhbmw",
  "io": "_io_1rhbmw",
  "iq": "_iq_1rhbmw",
  "ir": "_ir_1rhbmw",
  "is": "_is_1rhbmw",
  "it": "_it_1rhbmw",
  "je": "_je_1rhbmw",
  "jm": "_jm_1rhbmw",
  "jo": "_jo_1rhbmw",
  "jp": "_jp_1rhbmw",
  "ke": "_ke_1rhbmw",
  "kg": "_kg_1rhbmw",
  "kh": "_kh_1rhbmw",
  "ki": "_ki_1rhbmw",
  "km": "_km_1rhbmw",
  "kn": "_kn_1rhbmw",
  "kp": "_kp_1rhbmw",
  "kr": "_kr_1rhbmw",
  "kw": "_kw_1rhbmw",
  "ky": "_ky_1rhbmw",
  "kz": "_kz_1rhbmw",
  "la": "_la_1rhbmw",
  "lb": "_lb_1rhbmw",
  "lc": "_lc_1rhbmw",
  "li": "_li_1rhbmw",
  "lk": "_lk_1rhbmw",
  "lr": "_lr_1rhbmw",
  "ls": "_ls_1rhbmw",
  "lt": "_lt_1rhbmw",
  "lu": "_lu_1rhbmw",
  "lv": "_lv_1rhbmw",
  "ly": "_ly_1rhbmw",
  "ma": "_ma_1rhbmw",
  "md": "_md_1rhbmw",
  "me": "_me_1rhbmw",
  "mf": "_mf_1rhbmw",
  "mg": "_mg_1rhbmw",
  "mh": "_mh_1rhbmw",
  "mk": "_mk_1rhbmw",
  "ml": "_ml_1rhbmw",
  "mm": "_mm_1rhbmw",
  "mn": "_mn_1rhbmw",
  "mo": "_mo_1rhbmw",
  "mp": "_mp_1rhbmw",
  "mq": "_mq_1rhbmw",
  "mr": "_mr_1rhbmw",
  "ms": "_ms_1rhbmw",
  "mt": "_mt_1rhbmw",
  "mu": "_mu_1rhbmw",
  "mv": "_mv_1rhbmw",
  "mw": "_mw_1rhbmw",
  "mx": "_mx_1rhbmw",
  "my": "_my_1rhbmw",
  "mz": "_mz_1rhbmw",
  "na": "_na_1rhbmw",
  "nc": "_nc_1rhbmw",
  "nf": "_nf_1rhbmw",
  "ng": "_ng_1rhbmw",
  "ni": "_ni_1rhbmw",
  "nl": "_nl_1rhbmw",
  "no": "_no_1rhbmw",
  "nr": "_nr_1rhbmw",
  "nu": "_nu_1rhbmw",
  "nz": "_nz_1rhbmw",
  "om": "_om_1rhbmw",
  "pa": "_pa_1rhbmw",
  "pe": "_pe_1rhbmw",
  "pf": "_pf_1rhbmw",
  "pg": "_pg_1rhbmw",
  "ph": "_ph_1rhbmw",
  "pk": "_pk_1rhbmw",
  "pl": "_pl_1rhbmw",
  "pm": "_pm_1rhbmw",
  "pn": "_pn_1rhbmw",
  "pr": "_pr_1rhbmw",
  "ps": "_ps_1rhbmw",
  "pt": "_pt_1rhbmw",
  "pw": "_pw_1rhbmw",
  "py": "_py_1rhbmw",
  "qa": "_qa_1rhbmw",
  "re": "_re_1rhbmw",
  "ro": "_ro_1rhbmw",
  "rs": "_rs_1rhbmw",
  "ru": "_ru_1rhbmw",
  "rw": "_rw_1rhbmw",
  "sa": "_sa_1rhbmw",
  "sb": "_sb_1rhbmw",
  "sc": "_sc_1rhbmw",
  "sd": "_sd_1rhbmw",
  "se": "_se_1rhbmw",
  "sg": "_sg_1rhbmw",
  "sh": "_sh_1rhbmw",
  "si": "_si_1rhbmw",
  "sj": "_sj_1rhbmw",
  "sk": "_sk_1rhbmw",
  "sl": "_sl_1rhbmw",
  "sm": "_sm_1rhbmw",
  "sn": "_sn_1rhbmw",
  "so": "_so_1rhbmw",
  "sr": "_sr_1rhbmw",
  "ss": "_ss_1rhbmw",
  "st": "_st_1rhbmw",
  "su": "_su_1rhbmw",
  "sv": "_sv_1rhbmw",
  "sx": "_sx_1rhbmw",
  "sy": "_sy_1rhbmw",
  "sz": "_sz_1rhbmw",
  "ta": "_ta_1rhbmw",
  "tc": "_tc_1rhbmw",
  "td": "_td_1rhbmw",
  "tf": "_tf_1rhbmw",
  "tg": "_tg_1rhbmw",
  "th": "_th_1rhbmw",
  "tj": "_tj_1rhbmw",
  "tk": "_tk_1rhbmw",
  "tl": "_tl_1rhbmw",
  "tm": "_tm_1rhbmw",
  "tn": "_tn_1rhbmw",
  "to": "_to_1rhbmw",
  "tr": "_tr_1rhbmw",
  "tt": "_tt_1rhbmw",
  "tv": "_tv_1rhbmw",
  "tw": "_tw_1rhbmw",
  "tz": "_tz_1rhbmw",
  "ua": "_ua_1rhbmw",
  "ug": "_ug_1rhbmw",
  "uk": "_uk_1rhbmw",
  "um": "_um_1rhbmw",
  "un": "_un_1rhbmw",
  "us": "_us_1rhbmw",
  "uy": "_uy_1rhbmw",
  "uz": "_uz_1rhbmw",
  "vc": "_vc_1rhbmw",
  "ve": "_ve_1rhbmw",
  "vg": "_vg_1rhbmw",
  "vi": "_vi_1rhbmw",
  "vn": "_vn_1rhbmw",
  "vu": "_vu_1rhbmw",
  "wf": "_wf_1rhbmw",
  "ws": "_ws_1rhbmw",
  "xk": "_xk_1rhbmw",
  "ye": "_ye_1rhbmw",
  "yt": "_yt_1rhbmw",
  "za": "_za_1rhbmw",
  "zm": "_zm_1rhbmw",
  "zw": "_zw_1rhbmw"
};