var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class2, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';
import templateLayout from '../../templates/components/power-calendar/days';
import { add, endOf, endOfWeek, formatDate, getWeekdays, getWeekdaysMin, getWeekdaysShort, isAfter, isBefore, isSame, localeStartOfWeek, normalizeCalendarDay, normalizeDate, startOf, startOfWeek, withLocale } from 'ember-power-calendar-utils';
const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
let _class = (_dec = service('power-calendar'), _dec2 = computed('calendar.locale'), _dec3 = computed('calendar.locale'), _dec4 = computed('calendar.locale'), _dec5 = computed('weekdaysShort', 'startOfWeek'), _dec6 = computed('localeStartOfWeek', 'weekdayFormat', 'calendar.locale'), _dec7 = computed('calendar', 'focusedId', 'localeStartOfWeek', 'minDate', 'maxDate', 'disabledDates.[]', 'maxLength', 'currentCenter'), _dec8 = computed('showDaysAround', 'days'), _dec9 = computed('center', 'calendar.center'), (_class2 = class _class2 extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "layout", templateLayout);
    _defineProperty(this, "tagName", '');
    _defineProperty(this, "focusedId", undefined);
    _defineProperty(this, "showDaysAround", true);
    _defineProperty(this, "weekdayFormat", 'short');
    // "min" | "short" | "long"
    _initializerDefineProperty(this, "powerCalendarService", _descriptor, this);
  }
  // CPs
  get weekdaysMin() {
    return withLocale(this.calendar.locale, getWeekdaysMin);
  }
  get weekdaysShort() {
    return withLocale(this.calendar.locale, getWeekdaysShort);
  }
  get weekdays() {
    return withLocale(this.calendar.locale, getWeekdays);
  }
  get localeStartOfWeek() {
    let forcedStartOfWeek = this.startOfWeek;
    if (forcedStartOfWeek) {
      return parseInt(forcedStartOfWeek, 10);
    }
    return localeStartOfWeek(this.calendar.locale);
  }
  get weekdaysNames() {
    let {
      localeStartOfWeek,
      weekdayFormat
    } = this;
    let format = `weekdays${weekdayFormat === 'long' ? '' : weekdayFormat === 'min' ? 'Min' : 'Short'}`;
    let weekdaysNames = this[format];
    return weekdaysNames.slice(localeStartOfWeek).concat(weekdaysNames.slice(0, localeStartOfWeek));
  }
  get days() {
    let today = this.powerCalendarService.getDate();
    let lastDay = this.lastDay();
    let day = this.firstDay();
    let days = [];
    while (isBefore(day, lastDay)) {
      days.push(this.buildDay(day, today, this.calendar));
      day = add(day, 1, "day");
    }
    return days;
  }
  get weeks() {
    let {
      showDaysAround,
      days
    } = this;
    let weeks = [];
    let i = 0;
    while (days[i]) {
      let daysOfWeek = days.slice(i, i + 7);
      if (!showDaysAround) {
        daysOfWeek = daysOfWeek.filter(d => d.isCurrentMonth);
      }
      weeks.push({
        id: `week-of-${daysOfWeek[0].id}`,
        days: daysOfWeek,
        missingDays: 7 - daysOfWeek.length
      });
      i += 7;
    }
    return weeks;
  }
  get currentCenter() {
    let center = this.center;
    if (!center) {
      center = this.selected || this.calendar.center;
    }
    return normalizeDate(center);
  }

  // Actions
  handleDayFocus(e) {
    scheduleOnce('actions', this, this._updateFocused, e.target.dataset.date);
  }
  handleDayBlur() {
    scheduleOnce('actions', this, this._updateFocused, null);
  }
  handleKeyDown(e) {
    let {
      focusedId
    } = this;
    if (focusedId) {
      let days = this.days;
      let day, index;
      for (let i = 0; i < days.length; i++) {
        if (days[i].id === focusedId) {
          index = i;
          break;
        }
      }
      if (e.keyCode === 38) {
        e.preventDefault();
        let newIndex = Math.max(index - 7, 0);
        day = days[newIndex];
        if (day.isDisabled) {
          for (let i = newIndex + 1; i <= index; i++) {
            day = days[i];
            if (!day.isDisabled) {
              break;
            }
          }
        }
      } else if (e.keyCode === 40) {
        e.preventDefault();
        let newIndex = Math.min(index + 7, days.length - 1);
        day = days[newIndex];
        if (day.isDisabled) {
          for (let i = newIndex - 1; i >= index; i--) {
            day = days[i];
            if (!day.isDisabled) {
              break;
            }
          }
        }
      } else if (e.keyCode === 37) {
        day = days[Math.max(index - 1, 0)];
        if (day.isDisabled) {
          return;
        }
      } else if (e.keyCode === 39) {
        day = days[Math.min(index + 1, days.length - 1)];
        if (day.isDisabled) {
          return;
        }
      } else {
        return;
      }
      this.set('focusedId', day.id);
      scheduleOnce('afterRender', this, '_focusDate', day.id);
    }
  }

  // Methods
  buildDay(date, today, calendar) {
    let id = formatDate(date, 'YYYY-MM-DD');
    return normalizeCalendarDay({
      id,
      number: date.getDate(),
      date: new Date(date),
      isDisabled: this.dayIsDisabled(date),
      isFocused: this.focusedId === id,
      isCurrentMonth: date.getMonth() === this.currentCenter.getMonth(),
      isToday: isSame(date, today, 'day'),
      isSelected: this.dayIsSelected(date, calendar)
    });
  }
  buildonSelectValue(day) {
    return day;
  }
  dayIsSelected(date, calendar = this.calendar) {
    return calendar.selected ? isSame(date, calendar.selected, 'day') : false;
  }
  dayIsDisabled(date) {
    let isDisabled = !this.calendar.actions.select;
    if (isDisabled) {
      return true;
    }
    if (this.minDate && isBefore(date, startOf(this.minDate, 'day'))) {
      return true;
    }
    if (this.maxDate && isAfter(date, endOf(this.maxDate, 'day'))) {
      return true;
    }
    if (this.disabledDates) {
      let disabledInRange = this.disabledDates.some(d => {
        let isSameDay = isSame(date, d, 'day');
        let isWeekDayIncludes = WEEK_DAYS.indexOf(d) !== -1 && formatDate(date, 'ddd') === d;
        return isSameDay || isWeekDayIncludes;
      });
      if (disabledInRange) {
        return true;
      }
    }
    return false;
  }
  firstDay() {
    let firstDay = startOf(this.currentCenter, 'month');
    return startOfWeek(firstDay, this.localeStartOfWeek);
  }
  lastDay() {
    let localeStartOfWeek = this.localeStartOfWeek;
    assert("The center of the calendar is an invalid date.", !isNaN(this.currentCenter.getTime()));
    let lastDay = endOf(this.currentCenter, 'month');
    return endOfWeek(lastDay, localeStartOfWeek);
  }
  _updateFocused(id) {
    this.set('focusedId', id);
  }
  _focusDate(id) {
    let dayElement = document.querySelector(`[data-power-calendar-id="${this.calendar.uniqueId}"] [data-date="${id}"]`);
    if (dayElement) {
      dayElement.focus();
    }
  }
  handleClick(e) {
    let dayEl = e.target.closest('[data-date]');
    if (dayEl) {
      let dateStr = dayEl.dataset.date;
      let day = this.days.find(d => d.id === dateStr);
      if (day) {
        if (this.calendar.actions.select) {
          this.calendar.actions.select(day, this.calendar, e);
        }
      }
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "powerCalendarService", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "weekdaysMin", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "weekdaysMin"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "weekdaysShort", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "weekdaysShort"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "weekdays", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "weekdays"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "localeStartOfWeek", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "localeStartOfWeek"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "weekdaysNames", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "weekdaysNames"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "days", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "days"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "weeks", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "weeks"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentCenter", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "currentCenter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDayFocus", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDayFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDayBlur", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDayBlur"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2));
export { _class as default };