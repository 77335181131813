var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable @qonto/requires-task-function-syntax */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Ember from 'ember';
import { restartableTask, timeout } from 'ember-concurrency';
const POSITION_MAP = {
  'top left': 'bottom left',
  'top center': 'bottom center',
  'top right': 'bottom right',
  'bottom left': 'top left',
  'bottom center': 'top center',
  'bottom right': 'top right',
  'middle left': 'middle right',
  'middle right': 'middle left'
};
let TooltipComponent = (_class = class TooltipComponent extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "showTooltip", _descriptor, this);
    _defineProperty(this, "targetId", guidFor(this));
    _defineProperty(this, "tooltipId", `${this.targetId}-tooltip`);
  }
  get position() {
    return this.args.position ?? 'bottom center';
  }
  get attachment() {
    return POSITION_MAP[this.position];
  }
  *showTooltipTask(value) {
    let delay = Ember.testing ? 0 : 120;
    yield timeout(delay);
    this.showTooltip = value;
  }
  closeTooltipOnClick() {
    if (this.args.closeOnClick && !this.args.disabled) {
      this.showTooltip = false;
    }
  }
  handleEscape(e) {
    if (e.key === 'Escape') {
      this.showTooltip = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showTooltip", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "showTooltipTask", [restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "showTooltipTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeTooltipOnClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "closeTooltipOnClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleEscape", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleEscape"), _class.prototype)), _class);
export { TooltipComponent as default };