/* eslint-disable ember/no-side-effects */
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import { getActions, getMigration, getQontoAccount } from 'qonto-switching-bank-js/utils/fixtures';

export default class FixturesService extends Service {
  @service store;
  @service intl;

  @tracked _migration;
  @tracked _qonto_account;
  @tracked _actions;

  computeActionStats = actions => {
    let ignoredCount = {
      debit: actions.debit.filter(action => action.status === 'ignored').length,
      credit: actions.credit.filter(action => action.status === 'ignored').length,
    };
    let finishedCount = {
      debit: actions.debit.filter(action => action.status === 'finished').length,
      credit: actions.credit.filter(action => action.status === 'finished').length,
    };

    return {
      count: actions.debit.length + actions.credit.length,
      created_credit_count: actions.credit.length - finishedCount.credit - ignoredCount.credit,
      created_debit_count: actions.debit.length - finishedCount.debit - ignoredCount.debit,
      finished_count: finishedCount.debit + finishedCount.credit,
      relevant_credit_count: actions.credit.length - ignoredCount.credit,
      relevant_debit_count: actions.debit.length - ignoredCount.debit,
    };
  };

  get migration() {
    if (isEmpty(this._migration)) {
      this._migration = this.store.createRecord('migration', getMigration(this.intl));
    }
    if (!isEmpty(this.actions)) {
      this._migration.action_stats = this.computeActionStats(this.actions);
    }

    return this._migration;
  }

  get qonto_account() {
    if (isEmpty(this._qonto_account)) {
      this._qonto_account = getQontoAccount(this.intl);
    }

    return this._qonto_account;
  }

  get actions() {
    if (isEmpty(this._actions)) {
      let { debit, credit } = getActions(this.intl);

      debit = debit.map(a => this.store.createRecord('action', a));
      credit = credit.map(a => this.store.createRecord('action', a));
      debit.forEach(a => {
        a.transactions = a.transactions.map(t => this.store.createRecord('transaction', t));
      });
      credit.forEach(a => {
        a.transactions = a.transactions.map(t => this.store.createRecord('transaction', t));
      });

      this._actions = { debit, credit };
    }

    return this._actions;
  }

  deleteAction = actionToDelete => {
    this._actions = {
      debit: this._actions.debit.filter(action => action.id !== actionToDelete.id),
      credit: this._actions.credit.filter(action => action.id !== actionToDelete.id),
    };

    actionToDelete.transactions.forEach(transaction => transaction.deleteRecord());
    actionToDelete.deleteRecord();
  };

  resetFixturesStore = () => {
    this.store.unloadAll('migration');
    this.store.unloadAll('transaction');
    this.store.unloadAll('action');

    this._migration = undefined;
    this._actions = undefined;
  };
}
