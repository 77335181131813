import Model, { attr } from '@ember-data/model';

export default class UserModel extends Model {
  @attr avatar;
  @attr avatar_thumb;
  @attr birth_city;
  @attr birth_country;
  @attr birth_zipcode;
  @attr birthdate;
  @attr('boolean') can_enroll_trusted_device_using_sms_otp;
  @attr default_avatar;
  @attr default_avatar_thumb;
  @attr email;
  @attr first_name;
  @attr full_name;
  @attr futurae_user_id;
  @attr gender;
  @attr last_name;
  @attr locale;
  @attr('boolean') mfa_enabled;
  @attr mfa_method;
  @attr('boolean') onboarded;
  @attr('boolean') otp_enabled;
  @attr otp_method;
  @attr pending_email;
  @attr pending_phone_number;
  @attr phone_number;
  @attr('boolean') phone_number_verified;
  @attr('boolean') sca_enabled;
  @attr('boolean') sca_enrollment_available;
  @attr segment_id;
  @attr timezone;
  @attr zendesk;
}
