import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/browser';
import ToastFlashMessagesService from 'app/services/toast-flash-messages';
import { LottiePlayer } from 'design-system-kit';

import Router from 'qonto-switching-bank-js/router';
import SynchronizationSuccessRoute from 'qonto-switching-bank-js/routes/onboarding/synchronization-success/route';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class SynchronizationSuccessController extends Controller {
  lottiePlayer = LottiePlayer;
  @service declare router: Router;
  @service('migration') declare migrationService: MigrationService;
  @service declare network: NetworkService;
  @service declare segment: SegmentService;
  @service declare toastFlashMessages: ToastFlashMessagesService;

  declare model: ModelFrom<SynchronizationSuccessRoute>;

  @tracked isLoading = false;

  @action updateMigrationStatus() {
    let migration = this.model;

    if (migration.status === 'created') {
      this.router.transitionTo('onboarding.select-accounts');
    } else {
      this.isLoading = true;

      migration.status = 'created';
      migration
        .save()
        .then(() => {
          this.segment.track('bankswitch_synchro_success_completed');
          this.router.transitionTo('onboarding.select-accounts');
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          Sentry.captureMessage(
            `Migration update after synchronization success failed | ${error?.message}`
          );
          this.toastFlashMessages.defaultToastError();
        })
        .finally(() => (this.isLoading = false));
    }
  }
}
