import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DashboardTypeIndexController extends Controller {
  @service('action') actionService;
  @service modals;
  @service store;

  queryParams = ['tab'];

  @tracked tab = 'all';

  get parsedActions() {
    return this.actionService.parseByMethod(
      this.store
        .peekAll('action')
        .filter(action => action.transaction_type === this.model.transaction_type)
    );
  }

  @action updateTab(tab) {
    this.tab = tab;
  }

  @action showCreateActionForm() {
    let transaction_type = this.model.transaction_type;
    let migration_id = this.model.migration.id;
    let action = this.store.createRecord('action', { transaction_type, migration_id });

    this.modals.open('action-form-modal', {
      isFullScreenModal: true,
      action,
      isCreate: true,
      shouldDisplayDemoData: this.model.shouldDisplayDemoData,
    });
  }

  @action showEditActionForm(action) {
    this.modals.open('action-form-modal', {
      isFullScreenModal: true,
      action,
      isCreate: false,
      shouldDisplayDemoData: this.model.shouldDisplayDemoData,
    });
  }
}
