import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import ENV from 'qonto-switching-bank-js/config/environment';

export function init() {
  // Comment this condition if you need to test in dev
  if (ENV.environment !== 'production') {
    return;
  }
  Sentry.init({
    dsn: 'https://fb5c8e63adcb4fccb1d4e9b08a433b48@o74188.ingest.sentry.io/5673408',
    integrations: [new Integrations.BrowserTracing()],
    environment: ENV.deployTarget ? ENV.deployTarget : 'dev',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
