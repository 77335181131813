import Service from '@ember/service';

import {
  calendlyHelpUrl,
  calendlyInformationUrl,
  calendlyMigrationUrl,
} from 'qonto-switching-bank-js/constants/calendly';
import { CalendlyEvent, CountryCode } from 'qonto-switching-bank-js/types/calendly';

export default class CalendlyService extends Service {
  getCalendlyInformationAppointmentUrl(legalCountry: CountryCode) {
    return calendlyInformationUrl[legalCountry];
  }

  getCalendlyMigrationAppointmentUrl(legalCountry: CountryCode) {
    return calendlyMigrationUrl[legalCountry];
  }

  getCalendlyHelpAppointmentUrl(legalCountry: CountryCode) {
    return calendlyHelpUrl[legalCountry];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCalendlyEvent(e: Event & { data?: any }): e is CalendlyEvent {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }
}
