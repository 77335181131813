var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
let AccordionItemComponent = (_class = class AccordionItemComponent extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "id", `accordion-item-${guidFor(this)}`);
    _initializerDefineProperty(this, "isExpanded", _descriptor, this);
    _initializerDefineProperty(this, "hasNoShadow", _descriptor2, this);
  }
  setElement(element) {
    this.element = element;
  }
  *transition({
    insertedSprites,
    removedSprites
  }) {
    let fadesIn = insertedSprites.map(sprite => {
      return fadeIn(sprite);
    });
    removedSprites.forEach(sprite => {
      fadeOut(sprite);
    });
    if (this.isExpanded && this.args.onExpanded) {
      yield Promise.all(fadesIn);
      this.args.onExpanded({
        element: this.element
      });
    }
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.initiallyExpanded || false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasNoShadow", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.hasNoShadow || false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "setElement", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transition", [action], Object.getOwnPropertyDescriptor(_class.prototype, "transition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleExpand", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleExpand"), _class.prototype)), _class);
export { AccordionItemComponent as default };