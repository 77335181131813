import ENV from 'qonto-switching-bank-js/config/environment';

const calendly_base_url = 'https://calendly.com/';
const environmentSuffix = ENV.deployTarget === 'production' ? '' : '-staging';
const primary_color = 'primary_color=1d1d1b'; // --primary-black
const background_color = 'background_color=f9f9f7'; // --gray50
const calendly_params = `?hide_gdpr_banner=1&${background_color}&${primary_color}`;

export const calendlyInformationUrl = {
  DE: `${calendly_base_url}migrationsberater/informationen-zu-ihrem-kontowechsel${environmentSuffix}${calendly_params}`,
  ES: `${calendly_base_url}su-asesor-de-migracion/informacion-sobre-el-cambio-de-su-cuenta${environmentSuffix}${calendly_params}`,
  FR: `${calendly_base_url}customer-support-bank-switch/information-call${environmentSuffix}${calendly_params}`,
};

export const calendlyMigrationUrl = {
  DE: `${calendly_base_url}migrationsberater/umstellung-ihres-geschaftskontos-auf-qonto${environmentSuffix}${calendly_params}`,
  ES: `${calendly_base_url}su-asesor-de-migracion/migracion-cuentas-profesionales-a-qonto${environmentSuffix}${calendly_params}`,
  FR: `${calendly_base_url}customer-support-bank-switch/switch${environmentSuffix}${calendly_params}`,
};

export const calendlyHelpUrl = {
  DE: `${calendly_base_url}migrationsberater/bankmigration-unterstutzung${environmentSuffix}${calendly_params}`,
  ES: `${calendly_base_url}su-asesor-de-migracion/migracion-de-cuentas-ayuda${environmentSuffix}${calendly_params}`,
  FR: `${calendly_base_url}customer-support-bank-switch/migration-bancaire-aide${environmentSuffix}${calendly_params}`,
};
