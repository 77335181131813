import RESTAdapter from '@ember-data/adapter/rest';
import { inject as service } from '@ember/service';

import { apiBaseURL } from 'qonto-switching-bank-js/constants/hosts';

export default class TransactionAdapter extends RESTAdapter {
  @service network;
  @service store;

  host = apiBaseURL;

  async query(store, type, options) {
    let currentMigration = await this.store.queryRecord('migration', {});
    let { action_id, page } = options.filter;

    return this.network
      .get(`/migrations/${currentMigration.id}/actions/${action_id}/${type.modelName}s`, {
        params: { page },
      })
      .then(response => {
        return response.data;
      });
  }
}
