export default {
  "wrapper": "_wrapper_a3ngw4",
  "wrapper--readonly": "_wrapper--readonly_a3ngw4",
  "selector": "_selector_a3ngw4",
  "with-subtitle": "_with-subtitle_a3ngw4",
  "wrapper--disabled": "_wrapper--disabled_a3ngw4",
  "icon": "_icon_a3ngw4 _icon_1ju4e7",
  "header": "_header_a3ngw4 _header_1ju4e7",
  "title": "_title_a3ngw4 _title_1ju4e7",
  "subtitle": "_subtitle_a3ngw4 _subtitle_1ju4e7",
  "selector__content": "_selector__content_a3ngw4",
  "selector__animated-container": "_selector__animated-container_a3ngw4",
  "selector__extra": "_selector__extra_a3ngw4"
};