var _class;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import Component from '@glimmer/component';
import { action } from '@ember/object';
let BasicDropdownTrigger = (_class = class BasicDropdownTrigger extends Component {
  // Actions
  /**
   * Allows similair behaviour to `ember-composable-helpers`' `optional` helper.
   * Avoids adding extra dependencies.
   * Can be removed when the template `V1` compatability event handlers are removed.
   *
   * @see https://github.com/cibernox/ember-basic-dropdown/issues/498
   * @memberof BasicDropdownContent
   */
  noop() {}
  disableDocumentTextSelect(disable) {
    if (disable) {
      document.body.classList.add('ember-basic-dropdown-text-select-disabled');
    } else {
      document.body.classList.remove('ember-basic-dropdown-text-select-disabled');
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "disableDocumentTextSelect", [action], Object.getOwnPropertyDescriptor(_class.prototype, "disableDocumentTextSelect"), _class.prototype)), _class);
export { BasicDropdownTrigger as default };