import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import IntlService from 'ember-intl/services/intl';

import MigrationModel from 'qonto-switching-bank-js/models/migration';
import OrganizationModel from 'qonto-switching-bank-js/models/organization';
import IndexRoute from 'qonto-switching-bank-js/routes/application/route';
import FixturesService from 'qonto-switching-bank-js/services/fixtures';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class DashboardRoute extends Route {
  @service declare intl: IntlService;
  @service declare fixtures: FixturesService;
  @service declare store: Store;

  async model() {
    let { migration: fetchedMigration } = this.modelFor('application') as ModelFrom<IndexRoute>;
    let migration: MigrationModel;
    let organization: OrganizationModel;
    let organizationId = localStorage.getItem('organizationId') ?? '';

    let shouldDisplayProductTourBanner = !fetchedMigration;
    let shouldDisplayDemoData = !fetchedMigration || fetchedMigration.kind === 'cs_file_manual';

    if (fetchedMigration?.kind === 'cs_file_manual') {
      this.store.unloadAll('migration');
    }

    organization = await this.store.findRecord('organization', organizationId);
    migration = shouldDisplayDemoData ? this.fixtures.migration : fetchedMigration;

    return { migration, organization, shouldDisplayDemoData, shouldDisplayProductTourBanner };
  }
}
