var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
let PdfPreviewTermsAndConditions = (_class = class PdfPreviewTermsAndConditions extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
  }
  get termsAndConditionsWithLegalName() {
    let result,
      orgaNameForm = '';
    if (this.args.document) {
      let {
        termsAndConditions,
        organization
      } = this.args.document;
      if (organization) {
        let {
          legalName,
          shortLegalForm
        } = organization;
        orgaNameForm = legalName;
        if (shortLegalForm && new RegExp(` ${shortLegalForm.trim()}$`, 'i').test(orgaNameForm.trim()) === false) {
          orgaNameForm = `${orgaNameForm}, ${shortLegalForm}`;
        }

        // FR has 2 specific field to display: rsc number and capital share if available
        if (organization?.legalCountry === 'FR' && this.args.settings) {
          let {
            legalCapitalShare,
            commercialRegisterNumber
          } = this.args.settings;
          if (legalCapitalShare) {
            // currency will always be EUR as it's the default organization's legal country currency
            let formattedCapitalShare = this.intl.formatNumber(legalCapitalShare, {
              locale: this.args.displayLanguage,
              currency: 'EUR',
              style: 'currency'
            });
            orgaNameForm = this.intl.t('ui-kit.pdf-preview.footer-organization', {
              orgaName: orgaNameForm,
              language: this.args.displayLanguage,
              amount: formattedCapitalShare
            });
          }
          if (commercialRegisterNumber) {
            orgaNameForm = `${orgaNameForm} · ${commercialRegisterNumber}`;
          }
        }
      }
      result = orgaNameForm;
      if (termsAndConditions) {
        result = `${result} · ${termsAndConditions}`;
      }
    }
    return result;
  }
  get termsAndConditionsClass() {
    if (this.termsAndConditionsWithLegalName?.length <= 375) return '';
    if (this.termsAndConditionsWithLegalName?.length <= 425) return 'terms-conditions-size-9';
    return 'terms-conditions-size-8';
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { PdfPreviewTermsAndConditions as default };