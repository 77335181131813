var _dec, _class2;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import CalendarComponent from './power-calendar';
import { computed, action } from '@ember/object';
import { normalizeDate, isSame, normalizeMultipleActionValue } from 'ember-power-calendar-utils';
import { assert } from '@ember/debug';
import { isArray } from '@ember/array';
import PowerCalendarMultipleDaysComponent from './power-calendar-multiple/days';
let _class = (_dec = computed('center'), (_class2 = class _class2 extends CalendarComponent {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "daysComponent", PowerCalendarMultipleDaysComponent);
    _defineProperty(this, "_calendarType", 'multiple');
  }
  // CPs
  get selected() {
    return undefined;
  }
  set selected(v) {
    return isArray(v) ? v.map(normalizeDate) : v;
  }
  get currentCenter() {
    let center = this.center;
    if (!center) {
      center = (this.selected || [])[0] || this.powerCalendarService.getDate();
    }
    return normalizeDate(center);
  }

  // Actions
  select(dayOrDays, calendar, e) {
    assert(`The select action expects an array of date objects, or a date object. ${typeof dayOrDays} was recieved instead.`, isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date);
    let days;
    if (isArray(dayOrDays)) {
      days = dayOrDays;
    } else if (dayOrDays instanceof Object && dayOrDays.date instanceof Date) {
      days = [dayOrDays];
    }
    if (this.onSelect) {
      this.onSelect(this._buildCollection(days), calendar, e);
    }
  }

  // Methods
  _buildCollection(days) {
    let selected = this.publicAPI.selected || [];
    for (let day of days) {
      let index = selected.findIndex(selectedDate => isSame(day.date, selectedDate, "day"));
      if (index === -1) {
        selected = [...selected, day.date];
      } else {
        selected = selected.slice(0, index).concat(selected.slice(index + 1));
      }
    }
    return normalizeMultipleActionValue({
      date: selected
    });
  }
}, (_applyDecoratedDescriptor(_class2.prototype, "selected", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentCenter", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "currentCenter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype)), _class2));
export { _class as default };