var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import dayjs from 'dayjs';

// Defensive function to ensure the date type
const sanitizeAndConvertToObjectDate = date => date && dayjs(date).isValid() && dayjs(date).toDate() || null;
let DatePickerField = (_class = class DatePickerField extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
    // Ember-basic-dropdown properties
    _defineProperty(this, "renderInPlace", this.args.renderInPlace ?? true);
    // The date that acts as the center for the displayed date information
    _initializerDefineProperty(this, "center", _descriptor2, this);
    // to track if a new date has been selected, to then pass down the info to the date-field component
    _initializerDefineProperty(this, "hasPickedADate", _descriptor3, this);
    // to track if the clear button has been clicked, to then pass down the info to the date-field component and clear the input value
    _initializerDefineProperty(this, "shouldClearValue", _descriptor4, this);
  }
  // Limits for the date selection
  get minDate() {
    return sanitizeAndConvertToObjectDate(this.args.minDate);
  }
  get maxDate() {
    return sanitizeAndConvertToObjectDate(this.args.maxDate);
  }

  // The current selected date
  get value() {
    return sanitizeAndConvertToObjectDate(this.args.value);
  }
  get stringValue() {
    return this.args.value;
  }

  //Error level
  get messageLevel() {
    return this.args.messageLevel ?? 'error';
  }

  // Compute years options for the power-select in nav bar
  get yearsOptions() {
    // Defaults: The min and max date years or the year qonto was founded and +5 years from today
    let start = this.minDate || new Date(2016, 0);
    let end = this.maxDate || dayjs().startOf('day').add(10, 'year');
    let eachYearOfInterval = [];
    let currentYear = dayjs(start).startOf('year');
    while (currentYear.isBefore(end) || currentYear.isSame(end, 'year')) {
      eachYearOfInterval.push(currentYear.get('year'));
      currentYear = currentYear.add(1, 'year');
    }
    return eachYearOfInterval;
  }
  get verticalPosition() {
    return this.args.verticalPosition ?? 'below';
  }

  // The position of the calendar horizontally relative to the trigger
  // possible values: auto|auto-right|right|center|left
  get horizontalPosition() {
    return this.args.horizontalPosition ?? 'auto';
  }
  get disabledDates() {
    let {
      disabledDates
    } = this.args;
    return disabledDates?.map(date => sanitizeAndConvertToObjectDate(date));
  }

  // Check whether the value is within the limits, and return the limit if it is not
  _getLimitedDate(date) {
    if (this.minDate && dayjs(date).isBefore(dayjs(this.minDate))) {
      return this.args.minDate;
    } else if (this.maxDate && dayjs(date).isAfter(dayjs(this.maxDate))) {
      return this.args.maxDate;
    }
    return date;
  }

  // Call the parent update event and close the date-picker-field
  _update(date, hasInvalidDate) {
    let limitedDate = this._getLimitedDate(date);
    this.args?.onUpdate(limitedDate, hasInvalidDate);
    this.hasPickedADate = false;
    this.shouldClearValue = false;
    this.center = sanitizeAndConvertToObjectDate(limitedDate); // Put the calendar to the right month
  }

  // Year change through power-select in date-picker-field nav-bar
  selectYear(calendar, year) {
    let newCenter = dayjs(calendar.center).set('year', year).toDate();
    calendar.actions.changeCenter(newCenter);
  }

  // Manual edition from the date-field input send at date-field focus-out
  // So it's also trigger when the user click on date-picker-field
  onUpdateField(date, hasInvalidDate) {
    if (!dayjs(date).isSame(dayjs(this.value), 'day')) {
      this._update(date, hasInvalidDate);
    }
  }

  // Date selection with the date-picker-field
  onSelect(calendar, {
    date
  }) {
    this._update(dayjs(date).format('YYYY-MM-DD'));
    this.hasPickedADate = true;
    calendar.actions.close();
  }

  // Center the calendar on dropdown open
  initCenter() {
    this.center = this.value;
  }

  // Clear the field from any value and close the calendar
  handleClearDate(calendar) {
    this._update(null, false);
    this.hasPickedADate = true;
    this.shouldClearValue = true;
    calendar.actions.close();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "center", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.value || dayjs().startOf('day').toDate();
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasPickedADate", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "shouldClearValue", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "selectYear", [action], Object.getOwnPropertyDescriptor(_class.prototype, "selectYear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateField", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initCenter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "initCenter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClearDate", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClearDate"), _class.prototype)), _class);
export { DatePickerField as default };