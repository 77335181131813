import { helper } from '@ember/component/helper';
import { htmlSafe as htmlSafeTemplate } from '@ember/template';

export function htmlSafe(text: string) {
  return htmlSafeTemplate(text);
}

export default helper(([text]: string[]) => {
  return htmlSafe(text);
});
