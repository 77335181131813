import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ChooseOrganizationRoute extends Route {
  @service network;
  @service organizationUtils;
  @service router;
  @service('organization') organizationService;
  @service('migration') migrationService;
  @service entryPointRoute;
  @service store;

  async model() {
    let organizations = await this.store.findAll('organization');

    return organizations.slice().sort(this.organizationUtils.compareOrganizations);
  }

  async afterModel(organizations) {
    if (organizations.length === 1) {
      this.organizationService.setSelectedOrganization(organizations[0].id);

      let migration = await this.store.queryRecord('migration', {});
      let entryPointRoute = await this.entryPointRoute.getEntryPoint(migration);
      if (entryPointRoute === 'onboarding.select-bank.child.credentials') {
        await this.entryPointRoute.transitionToCredentialsWithCurrentMigration(migration);
      } else {
        this.router.transitionTo(entryPointRoute);
      }
    }
  }
}
