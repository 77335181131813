/* eslint-disable ember/no-classic-components */
import EmberComponent from '@ember/component';
import Component from '@glimmer/component';
export default class StorybookComponent extends Component {
  constructor(owner, args) {
    super(owner, args);

    // eslint-disable-next-line ember/no-classic-classes
    this.story = EmberComponent.extend({
      tagName: '',
      layout: args.story.template,
      ...args.story.context
    });

    // eslint-disable-next-line ember/no-classic-classes
    this.storyDark = EmberComponent.extend({
      tagName: '',
      layout: args.story.template,
      ...args.story.context
    });
  }
}