function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Component from '@glimmer/component';
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
export default class PricingCardComponent extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "badgeTypes", BADGE_TYPE);
    _defineProperty(this, "createEqhKey", key => {
      return `${this.args.equalizeHeightKey}--${key}`;
    });
  }
  get features() {
    // Handle deprecated features format array
    return this.args.features.map(elem => {
      if (elem.title) {
        return {
          description: elem.title,
          badge: elem.label
        };
      }
      return elem;
    });
  }
}