import { assert } from '@ember/debug';
import Component from '@glimmer/component';
import dayjs from 'dayjs';
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
const BADGE_STYLE = {
  [BADGE_TYPE.ACTIVATE]: {
    icon: 'icon_emptystate_activate',
    colorClass: 'purple'
  },
  [BADGE_TYPE.TRY_FOR_FREE]: {
    icon: 'icon_emptystate_try',
    colorClass: 'mint'
  },
  [BADGE_TYPE.UPGRADE]: {
    icon: 'icon_emptystate_upgrade',
    colorClass: 'peach'
  },
  [BADGE_TYPE.SAVE]: {
    icon: 'icon_emptystate_save',
    colorClass: 'peach'
  },
  [BADGE_TYPE.NOT_AVAILABLE]: {
    icon: 'icon_emptystate_not-eligible',
    colorClass: 'gray'
  },
  [BADGE_TYPE.NEW_FEATURE]: {
    icon: 'icon_sparkles',
    colorClass: 'yellow'
  }
};
export default class BadgeComponent extends Component {
  constructor() {
    super(...arguments);
    assert(`<Badge /> can only have following types: ${Object.values(BADGE_TYPE).join(', ')}`, Object.values(BADGE_TYPE).includes(this.args.type));
  }
  get iconOnly() {
    return !this.args.text;
  }
  get icon() {
    return BADGE_STYLE[this.args.type].icon;
  }
  get class() {
    return BADGE_STYLE[this.args.type].colorClass;
  }
  get isActive() {
    if (!this.args.expirationDate) {
      return true;
    }
    let expirationDate = dayjs(this.args.expirationDate);
    if (expirationDate.isValid() && dayjs().isBefore(expirationDate)) {
      return true;
    }
    return false;
  }
}