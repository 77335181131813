import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';
import { rawTimeout, task } from 'ember-concurrency';
import Base from 'ember-simple-auth/authenticators/base';

import { authBaseURL, authUserNamespace } from 'qonto-switching-bank-js/constants/hosts';

export const SESSION_URL = `${authBaseURL}/${authUserNamespace}/users/sessions`;

const FIVE_MINUTES = 5 * 60 * 1000;

// We are overriding one of the 3 methods of the Base Authenticator brick of ember
export default class CustomAuthenticator extends Base {
  @service network;

  // The method restore is called when a element of the app change so the app checks the validity of the current session
  async restore({ expires, websocket_token }) {
    if (!expires) {
      throw new Error('No session data');
    }

    let timeLeft = Date.parse(expires) - Date.now();
    if (timeLeft <= 0) {
      throw new Error('Session expired');
    }

    // this prevents `setTimeout()` from being called with a negative timeout
    // if the session expires within the next 5 minutes.
    let timeUntilRefresh = Math.max(0, timeLeft - FIVE_MINUTES);
    this.scheduleRefresh(timeUntilRefresh);

    return { expires, websocket_token };
  }

  async invalidate() {
    this.scheduleRefreshTask.cancelAll();

    try {
      await this.network.delete(SESSION_URL);
    } catch (error) {
      // we ignore all errors because throwing an error here will lead to
      // an infinite session invalidation loop

      // 401 is an expected failure after a session expiry timeout so we ignore
      // that without reporting it to Sentry, all other issues are reported to
      // Sentry.
      if (error.response && error.response.status !== 401) {
        Sentry.captureMessage(`Session invalidation failed with status ${error.response.status}`);
      }
    }
  }

  scheduleRefresh(timeUntilRefresh) {
    this.scheduleRefreshTask.perform(timeUntilRefresh);
  }

  @(task(function* (timeUntilRefresh) {
    yield rawTimeout(timeUntilRefresh);
    yield this.refreshTask.perform();
  }).drop())
  scheduleRefreshTask;

  /**
   * Use the existing authentication cookie to get a fresh one with a new
   * expiration date.
   */
  @task(function* () {
    let {
      data: { expires, websocket_token },
    } = yield this.network.put(SESSION_URL);
    this.trigger('sessionDataUpdated', { expires, websocket_token });

    let timeLeft = Date.parse(expires) - Date.now();
    this.scheduleRefresh(timeLeft - FIVE_MINUTES);

    return { expires, websocket_token };
  })
  refreshTask;

  // The method authenticate is called to authenticate a user. Not used here because we have no login
  // authenticate(options) {} Needed if we setup a login

  // The method invalidate is called to logout a user. Not used here because we have no login
  // invalidate(data) {} Needed if we setup a logout button
}
