import { assert } from '@ember/debug';
import Component from '@glimmer/component';
export default class ReadonlyComponent extends Component {
  constructor() {
    super(...arguments);
    assert('<PowerSelect::Readonly/> must have a @selected', this.args.selected);
  }
  get value() {
    let {
      selected
    } = this.args;
    if (Array.isArray(selected)) {
      return selected?.map(v => v).join(', ') || '';
    }
    return selected;
  }
}