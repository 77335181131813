var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable ember/no-runloop */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
let XDropdownButton = (_class = class XDropdownButton extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "total", 0);
    _initializerDefineProperty(this, "selectedIndex", _descriptor, this);
    _defineProperty(this, "id", guidFor(this));
    _defineProperty(this, "triggerClass", this.args.triggerClass || 'x-dropdown-button__trigger');
    _defineProperty(this, "contentClass", this.args.contentClass || 'x-dropdown-button__content');
    _defineProperty(this, "renderInPlace", this.args.renderInPlace || 'true');
  }
  assign() {
    return Object.assign({}, ...arguments);
  }
  onKeyDown(e) {
    if (['ArrowUp', 'Up', 'ArrowDown', 'Down'].includes(e.key)) {
      this.handleKeyUpDown(e);
    } else if (['Enter', ' '].includes(e.key)) {
      this.handleKeyEnterSpace(e);
    }
  }
  handleKeyEnterSpace(e) {
    let option = document.querySelector(`#${this.id} [data-option-index="${this.selectedIndex}"]`);
    if (option) {
      e.preventDefault();
      e.stopPropagation();
      option.click();
    }
  }
  handleKeyUpDown(e) {
    e.preventDefault();
    e.stopPropagation();
    let {
      selectedIndex,
      total
    } = this;
    if (['ArrowUp', 'Up'].includes(e.key)) {
      selectedIndex--;
    } else if (['ArrowDown', 'Down'].includes(e.key)) {
      selectedIndex++;
    }
    let lastIndex = total - 1;
    if (selectedIndex > lastIndex) {
      selectedIndex = lastIndex;
    } else if (selectedIndex < 0) {
      selectedIndex = 0;
    }
    this.selectedIndex = selectedIndex;
  }
  selectItem() {
    next(() => this.dropDownState.actions.close());
  }
  onInitDropDown(dropDownState) {
    this.dropDownState = dropDownState;
  }
  getIndex() {
    if (this.args.getIndex) {
      this.total = this.args.getIndex() + 1;
      return this.total - 1;
    } else {
      return this.total++;
    }
  }
  onOpen() {
    this.selectedIndex = this.args.selectedIndex ?? 0;
    this.total = 0;
    return this.args.onOpen?.(...arguments);
  }
  onClose(dropdown, e) {
    if (e?.defaultPrevented) {
      return false;
    }
    return this.args.onClose?.(...arguments);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedIndex", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.selectedIndex ?? 0;
  }
}), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectItem", [action], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInitDropDown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onInitDropDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getIndex", [action], Object.getOwnPropertyDescriptor(_class.prototype, "getIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpen", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype)), _class);
export { XDropdownButton as default };