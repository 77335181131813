import RESTAdapter from '@ember-data/adapter/rest';
import { inject as service } from '@ember/service';

import { membershipNamespace, qontoApiBaseURL } from 'qonto-switching-bank-js/constants/hosts';

export default class OrganizationAdapter extends RESTAdapter {
  @service network;
  @service organizationUtils;
  baseUrl = qontoApiBaseURL + '/' + membershipNamespace;

  findAll(store, type) {
    return this.network
      .get('/organizations', {
        baseURL: this.baseUrl,
        params: {
          per_page: '100',
          with: ['label_lists', 'labels'],
        },
      })
      .then(response => {
        let data = {};
        data[`${type.modelName}`] = response.data.organizations.filter(
          this.organizationUtils.isActive
        );
        return data;
      });
  }

  findRecord(store, type, id) {
    return this.network
      .get(`/${type.modelName}s/${id}`, {
        baseURL: this.baseUrl,
      })
      .then(response => {
        let data = {};
        data[`${type.modelName}`] = response.data.organization;
        return data;
      });
  }
}
