import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardTypeIndexRoute extends Route {
  @service segment;

  model() {
    return this.modelFor('dashboard.type');
  }

  async afterModel(model) {
    if (!model.shouldDisplayDemoData) {
      this.segment.track(`bankswitch_${model.transaction_type}_viewed`);
    }
  }
}
