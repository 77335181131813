import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import IntlService from 'ember-intl/services/intl';

import { appURL } from 'qonto-switching-bank-js/constants/hosts';
import Router from 'qonto-switching-bank-js/router';
import DashboardIndexRoute from 'qonto-switching-bank-js/routes/dashboard/index/route';
import EntryPointRouteService from 'qonto-switching-bank-js/services/entry-point-route';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class DashboardIndexController extends Controller {
  @service declare intl: IntlService;
  @service declare entryPointRoute: EntryPointRouteService;
  @service declare router: Router;

  declare model: ModelFrom<DashboardIndexRoute>;

  appLink = appURL;

  get warning() {
    let migration = this.model.migration;
    let bank_name = migration.bank_name;

    switch (migration.provider_connection_status_details) {
      case 'needs_human_action':
        return {
          label: this.intl.t(migration.extendedProviderConnectionStatusWordings.toaster, {
            bank_name,
          }),
        };
      case 'needs_manual_refresh':
        return {
          label: this.intl.t('error_1010.disclaimer.body'),
          button: {
            label: this.intl.t('error_1010.disclaimer.link'),
            action: this.connectMigration,
          },
        };
      case 'needs_password_rotation':
        return { label: this.intl.t('error_430.disclaimer.body', { bank_name }) };
      default:
        return undefined;
    }
  }

  @action connectMigration() {
    this.entryPointRoute
      .getCurrentFunnelUrl(this.model.migration.id)
      .then(url => window.location.assign(url));
  }
}
