import { action } from '@ember/object';
import Component from '@glimmer/component';

type PageSelectorComponentArgs = {
  currentPage: number;
  totalPages: number;
  perPage: number;
  totalCount: number;
  paginationLabel: string;
  changePage: (page: number) => void;
};

export default class PageSelectorComponent extends Component<PageSelectorComponentArgs> {
  get previousAvailable() {
    return this.args.currentPage > 1;
  }

  get nextAvailable() {
    return this.args.currentPage < this.args.totalPages;
  }

  get summary() {
    let currentPage = this.args.currentPage;
    let perPage = this.args.perPage;
    let totalCount = this.args.totalCount;
    let offset = currentPage * perPage;
    let end = Math.min(offset, totalCount);
    let start = totalCount && offset - perPage + 1;

    return `${start}-${end} ${this.args.paginationLabel ?? 'of'} ${totalCount}`;
  }

  @action
  handlePrevious() {
    if (this.previousAvailable) {
      this.args.changePage(this.args.currentPage - 1);
    }
  }

  @action
  handleNext() {
    if (this.nextAvailable) {
      this.args.changePage(this.args.currentPage + 1);
    }
  }
}
