import Service from '@ember/service';

import OrganizationModel from 'qonto-switching-bank-js/models/organization';
export default class OrganizationUtilsService extends Service {
  compareOrganizations(
    firstOrganization: OrganizationModel,
    secondOrganization: OrganizationModel
  ) {
    if (firstOrganization.name < secondOrganization.name) {
      return -1;
    }
    if (firstOrganization.name > secondOrganization.name) {
      return 1;
    }
    return 0;
  }

  isActive(organization: OrganizationModel) {
    return !organization.access_disabled;
  }
}
