var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { getOwner } from '@ember/application';
import Service, { inject as service } from '@ember/service';
import { OVERSEAS_FRANCE_COUNTRY_CODES } from '@qonto/ui-kit/constants/countries';

// Geocode Earth endpoint
const BASE_URL = 'https://api.geocode.earth';
const API_VERSION = 'v1';

// Layers that cover any place with a physical address
const LAYERS = ['address', 'street', 'venue'].join();

// Maximum number of results returned by the API
const SIZE = 10;

// Paris coordinates to get nearby results in Europe
const FOCUS_POINT = {
  lat: '48.864716',
  lon: '2.349014'
};
let GeocodeEarth = (_class = class GeocodeEarth extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "intl", _descriptor, this);
    let ENV = getOwner(this).resolveRegistration('config:environment');
    let {
      apiKey
    } = ENV['@qonto/ui-kit']['geocode-earth'] || {
      apiKey: null
    };
    this.API_KEY = apiKey;
  }
  async search({
    query,
    countries = []
  }) {
    let url = `${BASE_URL}/${API_VERSION}/autocomplete?api_key=${this.API_KEY}&text=${query}&layers=${LAYERS}&size=${SIZE}&lang=${this.intl.locale}&focus.point.lat=${FOCUS_POINT.lat}&focus.point.lon=${FOCUS_POINT.lon}`;

    // Restricting results to a list of one or more countries
    let boundaryCountries = countries.map(country => country.toUpperCase()).join();
    if (boundaryCountries) {
      url = url + `&boundary.country=${boundaryCountries}`;
    }
    let response = await this.fetch(url);
    if (!response.ok) {
      throw new Error('geocode-earth: failed to fetch api', {
        cause: response
      });
    }
    let {
      features
    } = await response.json();
    return this.parseFeatures(features);
  }
  fetch(url) {
    return fetch(url);
  }
  parseFeatures(features) {
    return features.map(feature => this.parseFeature(feature));
  }
  parseFeature({
    properties
  }) {
    let {
      locality,
      name,
      postalcode,
      borough,
      country_code,
      country: geoCountry,
      region,
      region_a
    } = properties;

    // For Overseas France countries, Geocode always return FR as country code
    // And the actual territory code as region_a
    let isOverseasFrance = country_code === 'FR' && OVERSEAS_FRANCE_COUNTRY_CODES.includes(region_a);
    let city = locality || null;
    let street = name || null;
    let postalCode = postalcode || null;
    let administrative = borough || null;
    let countryCode = (isOverseasFrance ? region_a : country_code) || null;
    let country = isOverseasFrance ? region : geoCountry;
    return {
      city,
      street,
      postalCode,
      administrative,
      countryCode,
      country
    };
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { GeocodeEarth as default };