import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/browser';

export default class ZendeskLocalizationService extends Service {
  @service store;
  @service localeManager;
  @service session;

  ZENDESK_DEFAULTS = {
    UNAUTH_FALLBACK_COUNTRY: 'fr',
    UNAUTH_FALLBACK_LOCALE: 'en',
    AUTH_FALLBACK_LOCALE: 'en',
  };

  ZENDESK_FAQ_IDS = {
    5020434: {
      fr: 23947677617809,
      de: undefined,
      it: undefined,
      es: 23950991413905,
    },
    5145573: {
      fr: 23947652550417,
      de: undefined,
      it: undefined,
      es: 23950975026449,
    },
  };

  @tracked _organizationCountry = this.ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY;

  constructor() {
    super(...arguments);
    this.loadOrganizationCountry();
  }

  @action
  async loadOrganizationCountry() {
    try {
      let organizationId = localStorage.getItem('organizationId') || '';
      let organization = await this.store.findRecord('organization', organizationId);
      this._organizationCountry =
        organization.legalCountry || this.ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY;
    } catch (error) {
      Sentry.captureMessage('Failed to load organization data', error);
    }
  }

  get organizationCountry() {
    return this._organizationCountry?.toLowerCase();
  }

  set organizationCountry(value) {
    this._organizationCountry = value;
  }

  calcZendeskLocale(locale = this.ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE) {
    let orgCountry = this.organizationCountry;
    let userLocale = locale.toLowerCase();

    let zendeskLocale = userLocale;

    if (orgCountry !== userLocale) {
      zendeskLocale = this.ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE;
    }
    return zendeskLocale;
  }

  @tracked _userLocale = this.calcZendeskLocale(
    this.localeManager?.locale || this.ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE
  );

  get userLocale() {
    return this._userLocale;
  }

  get localization() {
    let orgCountry = this.organizationCountry;
    let userLocale = this.userLocale;

    return {
      orgCountry,
      userLocale,
    };
  }

  get composeHCZendeskUrl() {
    return `https://support-${this.organizationCountry}.qonto.com/hc/${this.userLocale}`;
  }

  get composeSSOZendeskUrl() {
    let ZENDESK_SSO_BASE_URL =
      'https://qonto9015.zendesk.com/auth/v2/login/continue_with_sso_tracking';

    let paramsByCountry = {
      fr: {
        authOrigin: '17320677699345,true,true',
        brandId: '17320677699345',
      },
      it: {
        authOrigin: '23132128531089,true,true',
        brandId: '23132128531089',
      },
      es: {
        authOrigin: '23132160601361,true,true',
        brandId: '23132160601361',
      },
      de: {
        authOrigin: '23132188747153,true,true',
        brandId: '23132188747153',
      },
    };

    let { authOrigin, brandId } = paramsByCountry[this.organizationCountry];

    let authOriginParam = `auth_origin=${authOrigin}`;
    let brandIdParam = `brand_id=${brandId}`;
    let localeParam = `locale=${this.userLocale}`;
    let returnToParam = `return_to=${this.composeHCZendeskUrl}`;
    let queryString = `${authOriginParam}&${brandIdParam}&${localeParam}&${returnToParam}`;

    return `${ZENDESK_SSO_BASE_URL}?${queryString}`;
  }

  get getLocalizedHelpCenter() {
    return this.session.isAuthenticated ? this.composeSSOZendeskUrl : this.composeHCZendeskUrl;
  }

  getLocalizedArticle(articleId) {
    let orgCountry = this.organizationCountry;

    if (this.ZENDESK_FAQ_IDS[articleId]?.[orgCountry]) {
      return `${this.getLocalizedHelpCenter}/articles/${this.ZENDESK_FAQ_IDS[articleId]?.[orgCountry]}`;
    } else {
      return this.getLocalizedHelpCenter;
    }
  }
}
