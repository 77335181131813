import Component from '@glimmer/component';

import OrganizationModel from 'qonto-switching-bank-js/models/organization';

interface SettingsMenuOrganizationArgs {
  organization: OrganizationModel;
}

export default class SettingsMenuOrganizationComponent extends Component<SettingsMenuOrganizationArgs> {
  get profilePictureUrl() {
    let { avatar_thumb, default_avatar_thumb } = this.args.organization;

    if (avatar_thumb) {
      return avatar_thumb.file_url;
    } else {
      return default_avatar_thumb.file_url;
    }
  }
  get redirectionLink() {
    return `/?organizationId=${this.args.organization.id}`;
  }
}
