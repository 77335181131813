import { helper } from '@ember/component/helper';
export const truncateText = (params, hash) => {
  let value = Array.isArray(params[0]) ? params[0][0] : params[0];
  let len = hash.limit;
  let out = '';
  if (value) {
    out = value.substr(0, len);
    if (value.length > len) {
      out += '...';
    }
  } else {
    out = '';
  }
  return out;
};
export default helper(truncateText);