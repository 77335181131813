function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

import { assert } from '@ember/debug';
import memoize from 'fast-memoize';
import { FormatError } from 'intl-messageformat';
import { MISSING_INTL_API } from '../error-types';
import Formatter from './-base';

// `Intl.RelativeTimeFormat` will be added in TypeScript 4.0
// @see https://github.com/microsoft/TypeScript/pull/36084#issuecomment-649080072

/**
 * Unit to use in the relative time internationalized message.
 *
 * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/format#Parameters).
 *
 * [Specification](https://tc39.es/ecma402/#sec-singularrelativetimeunit).
 */

/**
 * The format of output message.
 *
 * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
 *
 * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
 */

/**
 * The length of the internationalized message.
 *
 * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
 *
 * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
 */

/**
 * An object with some or all of properties of `options` parameter
 * of `Intl.RelativeTimeFormat` constructor.
 *
 * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
 *
 * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
 */

const RELATIVE_TIME_OPTIONS = ['numeric', 'style', 'unit'];

/**
 * @private
 * @hide
 */
export default class FormatRelative extends Formatter {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "createNativeFormatter", memoize((locales, options) => {
      if (!Intl || !Intl.RelativeTimeFormat) {
        const error = new FormatError(`Intl.RelativeTimeFormat is not available in this environment. Try polyfilling it using "@formatjs/intl-relativetimeformat"`, MISSING_INTL_API);
        this.config.onError({
          kind: MISSING_INTL_API,
          error
        });
        throw error;
      }
      return new Intl.RelativeTimeFormat(locales, options);
    }));
  }
  get options() {
    return RELATIVE_TIME_OPTIONS;
  }
  format(locale, value, formatOptions) {
    const formatterOptions = this.readOptions(formatOptions);
    this.validateFormatterOptions(locale, formatterOptions);
    const unit = formatOptions?.unit ?? formatterOptions.unit;
    assert(`[ember-intl] FormatRelative: 'formatOptions' are missing a 'unit'.`, unit);
    const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
    return formatterInstance.format(typeof value === 'number' ? value : new Date(value).getTime(), unit);
  }
}
_defineProperty(FormatRelative, "type", 'relative');