import { buildTask as _buildTask } from "ember-concurrency/-private/async-arrow-runtime";
var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable @qonto/no-waitfor-decorators-tasks */
import { htmlSafe } from '@ember/template';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { restartableTask, timeout } from 'ember-concurrency';

/**
 *
 * @class ProgressBar
 * @extends Component
 */
let ProgressBar = (_class = class ProgressBar extends Component {
  constructor() {
    super(...arguments);
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    _initializerDefineProperty(this, "delayAnimationTask", _descriptor, this);
    this.delayAnimationTask.perform();
  }

  /**
   *
   * @property progress
   * @default 0
   * @type {Number}
   */
  get progress() {
    return this.args.progress ?? 0;
  }

  /**
   *
   * @property min
   * @default 0
   * @type {Number}
   */
  get min() {
    return this.args.min ?? 0;
  }

  /**
   *
   * @property max
   * @default 100
   * @type {Number}
   */
  get max() {
    return this.args.max ?? 100;
  }
  get width() {
    let percent = this.progress * 100 / (this.max - this.min);
    let width = this.delayAnimationTask.isRunning ? 0 : percent;
    return htmlSafe(`width: ${width}%;`);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "delayAnimationTask", [waitFor], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return _buildTask(() => ({
      context: this,
      generator: function* () {
        yield timeout(300);
      }
    }), null, "delayAnimationTask", "restartable");
  }
})), _class);
export { ProgressBar as default };