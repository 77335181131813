import EmberRouter from '@ember/routing/router';

import config from 'qonto-switching-bank-js/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

// eslint-disable-next-line array-callback-return
Router.map(function () {
  this.route('404', { path: '/*path' });

  this.route('onboarding', { path: '/' }, function () {
    this.route('choose-organization');
    this.route('select-accounts');

    // Migration flow
    this.route('select-bank', function () {
      this.route('child', { path: '/:parent_bank_id' }, function () {
        this.route('credentials', { path: '/credentials/:child_bank_id' });
      });
    });
    this.route('synchronization-in-progress', { path: '/synchronization' });
    this.route('synchronization-success');
    this.route('choose-dashboard-access');
    this.route('appointment');
    this.route('waiting-dashboard');
    this.route('migration-status');
    this.route('dashboard-empty');
    this.route('migration-succeeded');
  });

  this.route('dashboard', function () {
    this.route('type', { path: '/:transaction_type' }, function () {
      this.route('action', { path: '/action/:action_id' });
    });
  });

  this.route('errors', { path: '/error' }, function () {
    this.route('could-not-refresh');
    this.route('maintenance');
    this.route('needs-human-action');
    this.route('not-supported');
    this.route('unauthorized');
  });
});
