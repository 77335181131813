import { assert } from '@ember/debug';
export function dateToken({
  date,
  locale,
  token = COMPACT
}) {
  assert(`{{date-token}} can only manage those tokens: ${VALID_TOKENS.toString()}`, VALID_TOKENS.includes(token));
  let options = FORMAT_OPTIONS[token];
  let customFormat = LOCALE_CUSTOM_FORMATS[locale]?.[token];
  let dateTimeFormat = new Intl.DateTimeFormat(locale, options);
  try {
    return customFormat?.(dateTimeFormat.formatToParts(new Date(date))) ?? dateTimeFormat.format(new Date(date));
  } catch {
    return INVALID_DATE;
  }
}
const INVALID_DATE = '';
const COMPACT = 'compact';
const DATE_S = 'date-s';
const MONTH_YEAR_L = 'month-year-l';
const MONTH_YEAR_S = 'month-year-s';
const DATE_YEAR_S = 'date-year-s';
const DATE_YEAR_L = 'date-year-l';
const WEEK_DATE_L = 'week-date-l';
const DATE_TIME_L = 'date-time-l';
const DATE_TIME_S = 'date-time-s';
const FORMAT_OPTIONS = Object.freeze({
  [COMPACT]: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  },
  [DATE_S]: {
    month: 'short',
    day: '2-digit'
  },
  [MONTH_YEAR_L]: {
    month: 'long',
    year: 'numeric'
  },
  [MONTH_YEAR_S]: {
    month: 'short',
    year: 'numeric'
  },
  [DATE_YEAR_S]: {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  },
  [DATE_YEAR_L]: {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  },
  [WEEK_DATE_L]: {
    month: 'long',
    weekday: 'long',
    day: '2-digit'
  },
  [DATE_TIME_L]: {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  },
  [DATE_TIME_S]: {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }
});
const LOCALE_CUSTOM_FORMATS = {
  en: {
    [COMPACT]: ([month,, day,, year]) => `${day.value}-${month.value}-${year.value}`,
    [DATE_TIME_L]: parts => parts.map(({
      value
    }, i) => i === 5 ? ', ' : value).join(''),
    [DATE_TIME_S]: parts => parts.map(({
      value
    }, i) => i === 5 ? ', ' : value).join('')
  },
  fr: {
    [DATE_TIME_L]: parts => parts.map(({
      value
    }, i) => i === 5 ? ', ' : value).join(''),
    [DATE_TIME_S]: parts => parts.map(({
      value
    }, i) => i === 5 ? ', ' : value).join('')
  },
  de: {
    [DATE_TIME_L]: parts => parts.map(({
      value
    }, i) => [3, 5].includes(i) ? ', ' : value).join(''),
    [DATE_TIME_S]: parts => parts.map(({
      value
    }, i) => i === 5 ? ', ' : value).join('')
  },
  it: {
    [DATE_TIME_L]: parts => parts.map(({
      value
    }, i) => i === 5 ? ', ' : value).join(''),
    [DATE_TIME_S]: parts => parts.map(({
      value
    }, i) => i === 5 ? ', ' : value).join('')
  },
  es: {
    [COMPACT]: parts => parts.map(({
      type,
      value
    }) => type === 'literal' ? '-' : value).join(''),
    [WEEK_DATE_L]: parts => parts.map(({
      value
    }, i) => i === 1 ? ' ' : value).join('')
  },
  pt: {
    [DATE_S]: parts => parts.map(({
      value,
      type
    }) => {
      if (type === 'literal') {
        return ' ';
      } else if (type === 'month' && value.endsWith('.')) {
        return value.slice(0, -1);
      } else {
        return value;
      }
    }).join(''),
    [MONTH_YEAR_S]: parts => parts.map(({
      value,
      type
    }) => {
      if (type === 'literal') {
        return ' ';
      } else if (type === 'month' && value.endsWith('.')) {
        return value.slice(0, -1);
      } else {
        return value;
      }
    }).join(''),
    [DATE_YEAR_S]: parts => parts.map(({
      value,
      type
    }) => {
      if (type === 'literal') {
        return ' ';
      } else if (type === 'month' && value.endsWith('.')) {
        return value.slice(0, -1);
      } else {
        return value;
      }
    }).join(''),
    [DATE_TIME_L]: parts => parts.map(({
      value,
      type
    }) => type === 'literal' && value === ' às ' ? ', ' : value).join(''),
    [DATE_TIME_S]: parts => parts.map(({
      value,
      type
    }) => {
      if (type === 'literal' && value === ' às ') {
        return ', ';
      } else if (type === 'literal' && value === ' de ') {
        return ' ';
      } else if (type === 'month' && value.endsWith('.')) {
        return value.slice(0, -1);
      } else {
        return value;
      }
    }).join('')
  }
};
export const DATE_FORMAT_TOKENS = {
  COMPACT,
  DATE_S,
  MONTH_YEAR_L,
  MONTH_YEAR_S,
  DATE_YEAR_S,
  DATE_YEAR_L,
  WEEK_DATE_L,
  DATE_TIME_L,
  DATE_TIME_S
};
const VALID_TOKENS = Object.values(DATE_FORMAT_TOKENS);