var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
let TabsComponent = (_class = class TabsComponent extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "navObjects", _descriptor, this);
    _initializerDefineProperty(this, "_activeId", _descriptor2, this);
  }
  handleActiveTab() {
    this._activeId = this.navObjects[0].id;
  }
  get activeId() {
    if ('value' in this.args) {
      return this.navObjects.find(({
        value
      }) => value === this.args.value)?.id;
    } else {
      return this._activeId;
    }
  }
  changeTab(activeId) {
    let {
      value
    } = this.navObjects.find(({
      id
    }) => id === activeId);
    this._activeId = activeId;
    this.args.onChangeTab?.(value);
  }
  registerNav(elementId, title, value, icon) {
    this.unregisterNav(elementId);
    this.navObjects.push({
      id: elementId,
      title,
      value,
      icon
    });
    this.navObjects = [...this.navObjects];
  }
  unregisterNav(elementId) {
    let index = this.navObjects.findIndex(({
      id
    }) => id === elementId);
    if (index > -1) {
      this.navObjects.splice(index, 1);
      this.navObjects = [...this.navObjects];
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "navObjects", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_activeId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "handleActiveTab", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleActiveTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeTab", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerNav", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregisterNav", [action], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterNav"), _class.prototype)), _class);
export { TabsComponent as default };