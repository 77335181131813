import Service, { service } from '@ember/service';

export default class CurrentUserService extends Service {
  @service session;
  @service segment;
  @service store;

  user;

  async load() {
    if (this.session.isAuthenticated) {
      this.user = await this.store.queryRecord('user', {});
      let { email, phoneNumber, lastName, firstName } = this.user;
      this.segment.identify({ email, phoneNumber, lastName, firstName });
    }
  }
}
