var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { equal, sort, mapBy } from '@ember/object/computed';
import Service from '@ember/service';
import { typeOf, isNone } from '@ember/utils';
import { warn, assert } from '@ember/debug';
import { set, get, setProperties, computed } from '@ember/object';
import { classify } from '@ember/string';
import { A as emberArray } from '@ember/array';
import FlashMessage from 'ember-cli-flash/flash/object';
import objectWithout from 'ember-cli-flash/utils/object-without';
import { getOwner } from '@ember/application';
import flashMessageOptions from 'ember-cli-flash/utils/flash-message-options';
import getWithDefault from 'ember-cli-flash/utils/get-with-default';
let FlashMessagesService = (_dec = equal('queue.length', 0).readOnly(), _dec2 = mapBy('queue', '_guid').readOnly(), _dec3 = sort('queue', function (a, b) {
  if (a.priority < b.priority) {
    return 1;
  } else if (a.priority > b.priority) {
    return -1;
  }
  return 0;
}).readOnly(), (_class = class FlashMessagesService extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "isEmpty", _descriptor, this);
    _initializerDefineProperty(this, "_guids", _descriptor2, this);
    _initializerDefineProperty(this, "arrangedQueue", _descriptor3, this);
    this._setDefaults();
    this.queue = emberArray();
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.clearMessages();
  }
  add(options = {}) {
    this._enqueue(this._newFlashMessage(options));
    return this;
  }
  clearMessages() {
    const flashes = this.queue;
    if (isNone(flashes)) {
      return;
    }
    flashes.forEach(flash => flash.destroyMessage());
    flashes.clear();
    return this;
  }
  registerTypes(types = emberArray()) {
    types.forEach(type => this._registerType(type));
    return this;
  }
  peekFirst() {
    return this.queue.firstObject;
  }
  peekLast() {
    return this.queue.lastObject;
  }
  getFlashObject() {
    const errorText = 'A flash message must be added before it can be returned';
    assert(errorText, this.queue.length);
    return this.peekLast();
  }
  _newFlashMessage(options = {}) {
    assert('The flash message cannot be empty when preventDuplicates is enabled.', this.defaultPreventDuplicates ? options.message : true);
    assert('The flash message cannot be empty when preventDuplicates is enabled.', options.preventDuplicates ? options.message : true);
    const flashService = this;
    const allDefaults = getWithDefault(this, 'flashMessageDefaults', {});
    const defaults = objectWithout(allDefaults, ['types', 'preventDuplicates']);
    const flashMessageOptions = Object.assign({}, defaults, {
      flashService
    });
    for (let key in options) {
      const value = get(options, key);
      const option = this._getOptionOrDefault(key, value);
      set(flashMessageOptions, key, option);
    }
    return FlashMessage.create(flashMessageOptions);
  }
  _getOptionOrDefault(key, value) {
    const defaults = getWithDefault(this, 'flashMessageDefaults', {});
    const defaultOption = get(defaults, key);
    if (typeOf(value) === 'undefined') {
      return defaultOption;
    }
    return value;
  }
  get flashMessageDefaults() {
    const config = getOwner(this).resolveRegistration('config:environment');
    const overrides = getWithDefault(config, 'flashMessageDefaults', {});
    return flashMessageOptions(overrides);
  }
  _setDefaults() {
    const defaults = getWithDefault(this, 'flashMessageDefaults', {});
    for (let key in defaults) {
      const classifiedKey = classify(key);
      const defaultKey = `default${classifiedKey}`;
      set(this, defaultKey, defaults[key]);
    }
    this.registerTypes(getWithDefault(this, 'defaultTypes', emberArray()));
  }
  _registerType(type) {
    assert('The flash type cannot be undefined', type);
    this[type] = (message, options = {}) => {
      const flashMessageOptions = Object.assign({}, options);
      setProperties(flashMessageOptions, {
        message,
        type
      });
      return this.add(flashMessageOptions);
    };
  }
  _hasDuplicate(guid) {
    return this._guids.includes(guid);
  }
  _enqueue(flashInstance) {
    const instancePreventDuplicates = flashInstance.preventDuplicates;
    const preventDuplicates = typeof instancePreventDuplicates === 'boolean' ?
    // always prefer instance option over global option
    instancePreventDuplicates : this.defaultPreventDuplicates;
    if (preventDuplicates) {
      const guid = flashInstance._guid;
      if (this._hasDuplicate(guid)) {
        warn('Attempting to add a duplicate message to the Flash Messages Service', false, {
          id: 'ember-cli-flash.duplicate-message'
        });
        return;
      }
    }
    return this.queue.pushObject(flashInstance);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_guids", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "arrangedQueue", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "flashMessageDefaults", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "flashMessageDefaults"), _class.prototype)), _class));
export { FlashMessagesService as default };