var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import ModalServices from 'ember-promise-modals/services/modals';
let Modals = (_class = class Modals extends ModalServices {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "bodyClass", _descriptor, this);
  }
  open(component, data, options) {
    let className = data?.isFullScreenModal ? 'epm-from-bottom' : 'epm-fade';
    let clickOutsideDeactivates = data?.isFullScreenModal ? false : true;
    let focusTrapOptions = {
      clickOutsideDeactivates,
      ...options?.focusTrapOptions
    };
    if (['popup/destructive', 'popup/confirmation', 'popup/information'].includes(component)) {
      className += ' epm-popup-modal';
    }
    if (options && options.className) {
      className += ` ${options.className}`;
    }
    let modal = super.open(component, data, {
      ...options,
      className,
      focusTrapOptions,
      onAnimationModalOutEnd: () => this.bodyClass.deregister(modal.guid)
    });
    modal.guid = guidFor(modal);
    this.bodyClass.register(modal.guid, data?.isFullScreenModal ? ['has-fullscreen-modal'] : ['has-popup-modal']);
    return modal;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bodyClass", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { Modals as default };