var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 *  @class FilePreviewHeader
 *  @extends Component
 */
let FilePreviewHeader = (_class = class FilePreviewHeader extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "strings", _descriptor, this);
    _initializerDefineProperty(this, "showDeleteConfirmationForm", _descriptor2, this);
  }
  get formatedFileSize() {
    let {
      selectedFile
    } = this.args;
    if (selectedFile) {
      return this.strings.formatFileSize(selectedFile.fileSize);
    }
  }
  cancelDelete() {
    this.showDeleteConfirmationForm = false;
  }
  confirmDelete(selectedFile) {
    this.args.remove(selectedFile);
    this.showDeleteConfirmationForm = false;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "strings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showDeleteConfirmationForm", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "cancelDelete", [action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmDelete", [action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmDelete"), _class.prototype)), _class);
export { FilePreviewHeader as default };