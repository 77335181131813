import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { bankAccountsNamespace, qontoApiBaseURL } from 'qonto-switching-bank-js/constants/hosts';
import MigrationModel from 'qonto-switching-bank-js/models/migration';
import IndexRoute from 'qonto-switching-bank-js/routes/application/route';
import DashboardRoute from 'qonto-switching-bank-js/routes/dashboard/route';
import FixturesService from 'qonto-switching-bank-js/services/fixtures';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import NetworkService from 'qonto-switching-bank-js/services/network';
import RefinerService from 'qonto-switching-bank-js/services/refiner';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class DashboardIndexRoute extends Route {
  @service declare network: NetworkService;
  @service('migration') declare migrationService: MigrationService;
  @service declare segment: SegmentService;
  @service declare refiner: RefinerService;
  @service declare fixtures: FixturesService;
  @service declare store: Store;

  async fetchQontoAccount(organizationId: string) {
    let baseURL = qontoApiBaseURL + '/' + bankAccountsNamespace;
    let params = { organization_id: organizationId };
    let response = await this.network.get('/bank_accounts', { baseURL, params });

    return response.data.bank_accounts.find((account: { main: boolean }) => account.main);
  }

  async model() {
    let dashboardModel = this.modelFor('dashboard') as ModelFrom<DashboardRoute>;
    let { user } = this.modelFor('application') as ModelFrom<IndexRoute>;
    let { organization, shouldDisplayDemoData } = dashboardModel;
    let qontoAccount;
    let migration;

    if (shouldDisplayDemoData) {
      qontoAccount = this.fixtures.qonto_account;
      migration = this.fixtures.migration as MigrationModel;
    } else {
      [qontoAccount, migration] = await Promise.all([
        this.fetchQontoAccount(organization.id),
        this.store.queryRecord('migration', {}),
      ]);
    }

    return { migration, organization, user, qontoAccount, shouldDisplayDemoData };
  }

  afterModel(model: ModelFrom<DashboardIndexRoute>) {
    if (!model.shouldDisplayDemoData) {
      this.segment.track('bankswitch_overview_viewed');

      if (
        model.migration.areNoActionsLeft &&
        localStorage.getItem('autoEndMigrationModalSeen') !== 'true'
      ) {
        localStorage.setItem('autoEndMigrationModalSeen', 'true');
        this.migrationService.endMigration(model.migration);
      }

      this.refiner.load(model.migration, model.organization, model.user);
    }
  }
}
