import Model, { attr } from '@ember-data/model';

export default class OrganizationModel extends Model {
  @attr('boolean') access_disabled;
  @attr('number') active_memberships_count;
  @attr addresscontact_name;
  @attr avatar;
  @attr avatar_thumb;
  @attr bylaw_uploaded_at;
  @attr('number') cards_count;
  @attr check_settings;
  @attr combined_kdeposit_signature;
  @attr('string') contact_emai;
  @attr('string') contract_signed_at;
  @attr('string') contract_status;
  @attr('string') core_banking_system;
  @attr('string') created_at;
  @attr default_avatar;
  @attr default_avatar_thumb;
  @attr deposit_capital_status;
  @attr draft;
  @attr features;
  @attr('string') fx_statusfx_kyc_waiting;
  @attr hq_addressid;
  @attr('string') kyb_status;
  @attr label_lists;
  @attr labels;
  @attr('string') legal_country;
  @attr('string') legal_form;
  @attr('string') legal_name;
  @attr('string') legal_number;
  @attr('string') legal_registration_date;
  @attr('string') legal_sector;
  @attr('number') legal_share_capital;
  @attr('number') legal_share_capital_cents;
  @attr legal_tva_number;
  @attr membership_ids;
  @attr('number') memberships_count;
  @attr('string') name;
  @attr('string') original_price_plan_code;
  @attr original_voucher_code;
  @attr poa_uploaded_at;
  @attr('string') referral_code;
  @attr('number') referral_reward_amount_cents;
  @attr('string') referral_reward_amount_currency;
  @attr('string') referral_url;
  @attr('number') requests_count;
  @attr('string') siren;
  @attr('string') slug;
  @attr('boolean') under_registration;
  @attr('boolean') unlimited;
  @attr('boolean') unreferenced;
  @attr vat_rates_categories;
  @attr('boolean') with_debit_card;
}
