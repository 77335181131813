var _dec, _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import Evented from '@ember/object/evented';
import EmberObject, { set } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import { guidFor } from 'ember-cli-flash/utils/computed';

// Note:
// To avoid https://github.com/adopted-ember-addons/ember-cli-flash/issues/341 from happening, this class can't simply be called Object
let FlashObject = (_dec = guidFor('message').readOnly(), (_class = class FlashObject extends EmberObject.extend(Evented) {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "exitTimer", null);
    _defineProperty(this, "exiting", false);
    _defineProperty(this, "isExitable", true);
    _defineProperty(this, "initializedTime", null);
    _initializerDefineProperty(this, "_guid", _descriptor, this);
  }
  init() {
    super.init(...arguments);
    if (this.sticky) {
      return;
    }
    this.timerTask();
    this._setInitializedTime();
  }
  destroyMessage() {
    this._cancelTimer();
    if (this.exitTaskInstance) {
      cancel(this.exitTaskInstance);
      this._teardown();
    } else {
      this.exitTimerTask();
    }
  }
  exitMessage() {
    if (!this.isExitable) {
      return;
    }
    this.exitTimerTask();
    this.trigger('didExitMessage');
  }
  willDestroy() {
    if (this.onDestroy) {
      this.onDestroy();
    }
    this._cancelTimer();
    this._cancelTimer('exitTaskInstance');
    super.willDestroy(...arguments);
  }
  preventExit() {
    set(this, 'isExitable', false);
  }
  allowExit() {
    set(this, 'isExitable', true);
    this._checkIfShouldExit();
  }
  timerTask() {
    if (!this.timeout) {
      return;
    }
    const timerTaskInstance = later(() => {
      this.exitMessage();
    }, this.timeout);
    set(this, 'timerTaskInstance', timerTaskInstance);
  }
  exitTimerTask() {
    if (this.isDestroyed) {
      return;
    }
    set(this, 'exiting', true);
    if (this.extendedTimeout) {
      let exitTaskInstance = later(() => {
        this._teardown();
      }, this.extendedTimeout);
      set(this, 'exitTaskInstance', exitTaskInstance);
    } else {
      this._teardown();
    }
  }
  _setInitializedTime() {
    let currentTime = new Date().getTime();
    set(this, 'initializedTime', currentTime);
    return this.initializedTime;
  }
  _getElapsedTime() {
    let currentTime = new Date().getTime();
    return currentTime - this.initializedTime;
  }
  _cancelTimer(taskName = 'timerTaskInstance') {
    if (this[taskName]) {
      cancel(this[taskName]);
    }
  }
  _checkIfShouldExit() {
    if (this._getElapsedTime() >= this.timeout && !this.sticky) {
      this._cancelTimer();
      this.exitMessage();
    }
  }
  _teardown() {
    const queue = this.flashService?.queue;
    if (queue) {
      queue.removeObject(this);
    }
    this.destroy();
    this.trigger('didDestroyMessage');
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_guid", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { FlashObject as default };