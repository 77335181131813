import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import Router from 'qonto-switching-bank-js/router';
import SelectBankChildRoute from 'qonto-switching-bank-js/routes/onboarding/select-bank/child/route';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import ParentBankService from 'qonto-switching-bank-js/services/parent-bank';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class SelectBankChildController extends Controller {
  @service('parentBank') declare parentBankService: ParentBankService;
  @service declare modals: typeof ModalsService;
  @service declare router: Router;

  declare model: ModelFrom<SelectBankChildRoute>;

  @tracked searchQuery = '';

  get proBanks() {
    return this.model.childBanks.filter(bank => bank.isPro);
  }

  get individualBanks() {
    return this.model.childBanks.filter(bank => bank.isIndividual);
  }

  get filteredBanks() {
    return this.parentBankService.search(this.model.childBanks, this.searchQuery);
  }

  get showBanksByType(): boolean {
    return this.model.childBanks.every(bank => bank.type !== 'neither') && !this.searchQuery;
  }

  @action searchBanks(search: string) {
    this.set('searchQuery', search);
  }

  @action handleSelection(childBankId: string) {
    this.router.transitionTo(
      'onboarding.select-bank.child.credentials',
      this.model.parentBank.id,
      childBankId
    );
  }
}
