function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import EmberObject from '@ember/object';
export const localFilesKeys = ['downloadUrl', 'fileContentType', 'fileName', 'fileSize', 'fileUrl', 'originalFile', 'status'];
export default class LocalFile extends EmberObject {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "downloadUrl", null);
    _defineProperty(this, "fileContentType", null);
    _defineProperty(this, "fileName", null);
    _defineProperty(this, "fileSize", null);
    _defineProperty(this, "fileUrl", null);
    _defineProperty(this, "originalFile", null);
    _defineProperty(this, "status", null);
  }
  static async read(file) {
    let result = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onerror = error => reject(error);
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
    return LocalFile.create({
      downloadUrl: file.downloadUrl,
      fileContentType: file.type,
      fileName: file.name,
      fileSize: file.size,
      fileUrl: result,
      originalFile: file,
      status: file.status
    });
  }
}