import * as dayjs from 'dayjs';

export const getMigration = intl => ({
  bank_name: intl.t('product_tour.overview.card1.title'),
  bank_logo_url: '/images/icon-default-bank.svg',
  fetched_at: null,
  status: 'in_progress',
  selected_accounts: [
    {
      iban: intl.t('product_tour.overview.card1.IBAN'),
      balance: 1000000,
    },
  ],
  isFixture: true,
});

export const getQontoAccount = intl => ({
  name: intl.t('product_tour.overview.card2.title'),
  iban: intl.t('product_tour.overview.card2.IBAN'),
  balance: 1000000,
});

export const getActions = intl => {
  // Generate 'length' number of transactions with a certain difference between date
  let generateTransactions = ({ length, frequency, amount, baseDate, label }) => {
    return Array.from({ length }, () => {
      let transaction = { amount, label, date: baseDate.format('YYYY-MM-DD') };
      baseDate = baseDate.subtract(frequency, 'day');

      return transaction;
    });
  };

  return {
    debit: [
      {
        id: 1,
        name: intl.t('product_tour.suppliers.action1.title'),
        category: 'marketing',
        status: 'created',
        transaction_type: 'debit',
        transaction_method: 'transfer',
        last_transaction: { date: dayjs().subtract(5, 'day').format('YYYY-MM-DD') },
        transactions_count: 10,
        transactions: generateTransactions({
          length: 10,
          frequency: 30,
          amount: -2000,
          baseDate: dayjs().subtract(5, 'day'),
          label: intl.t('product_tour.suppliers.action1.transactions'),
        }),
      },
      {
        id: 2,
        name: intl.t('product_tour.suppliers.action2.title'),
        category: 'restaurant_and_bar',
        status: 'created',
        transaction_type: 'debit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(2, 'day').format('YYYY-MM-DD') },
        transactions_count: 20,
        transactions: generateTransactions({
          length: 20,
          frequency: 3,
          amount: -10,
          baseDate: dayjs().subtract(2, 'day'),
          label: intl.t('product_tour.suppliers.action2.transactions'),
        }),
      },
      {
        id: 3,
        name: intl.t('product_tour.suppliers.action3.title'),
        category: 'transport',
        status: 'created',
        transaction_type: 'debit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(20, 'day').format('YYYY-MM-DD') },
        transactions_count: 10,
        transactions: generateTransactions({
          length: 10,
          frequency: 60,
          amount: -1000,
          baseDate: dayjs().subtract(20, 'day'),
          label: intl.t('product_tour.suppliers.action3.transactions'),
        }),
      },
      {
        id: 4,
        name: intl.t('product_tour.suppliers.action4.title'),
        category: 'insurance',
        status: 'created',
        transaction_type: 'debit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(10, 'day').format('YYYY-MM-DD') },
        transactions_count: 15,
        transactions: generateTransactions({
          length: 15,
          frequency: 30,
          amount: -50,
          baseDate: dayjs().subtract(10, 'day'),
          label: intl.t('product_tour.suppliers.action4.transactions'),
        }),
      },
      {
        id: 5,
        name: intl.t('product_tour.suppliers.action5.title'),
        category: 'office_supply',
        status: 'finished',
        transaction_type: 'debit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(3, 'day').format('YYYY-MM-DD') },
        transactions_count: 20,
        transactions: generateTransactions({
          length: 20,
          frequency: 5,
          amount: -150,
          baseDate: dayjs().subtract(3, 'day'),
          label: intl.t('product_tour.suppliers.action5.transactions'),
        }),
      },
      {
        id: 6,
        name: intl.t('product_tour.suppliers.action6.title'),
        category: 'office_supply',
        status: 'finished',
        transaction_type: 'debit',
        transaction_method: 'transfer',
        last_transaction: { date: dayjs().subtract(20, 'day').format('YYYY-MM-DD') },
        transactions_count: 20,
        transactions: generateTransactions({
          length: 20,
          frequency: 30,
          amount: -300,
          baseDate: dayjs().subtract(20, 'day'),
          label: intl.t('product_tour.suppliers.action6.transactions'),
        }),
      },
      {
        id: 7,
        name: intl.t('product_tour.suppliers.action7.title'),
        category: 'utility',
        status: 'ignored',
        transaction_type: 'debit',
        transaction_method: 'b2b',
        last_transaction: { date: dayjs().subtract(10, 'day').format('YYYY-MM-DD') },
        transactions_count: 15,
        transactions: generateTransactions({
          length: 15,
          frequency: 30,
          amount: -500,
          baseDate: dayjs().subtract(10, 'day'),
          label: intl.t('product_tour.suppliers.action7.transactions'),
        }),
      },
      {
        id: 8,
        name: intl.t('product_tour.suppliers.action8.title'),
        category: 'utility',
        status: 'ignored',
        transaction_type: 'debit',
        transaction_method: 'b2b',
        last_transaction: { date: dayjs().subtract(15, 'day').format('YYYY-MM-DD') },
        transactions_count: 15,
        transactions: generateTransactions({
          length: 15,
          frequency: 30,
          amount: -200,
          baseDate: dayjs().subtract(15, 'day'),
          label: intl.t('product_tour.suppliers.action8.transactions'),
        }),
      },
    ],
    credit: [
      {
        id: 9,
        name: intl.t('product_tour.clients.action1.title'),
        category: 'marketing',
        status: 'finished',
        transaction_type: 'credit',
        transaction_method: 'transfer',
        last_transaction: { date: dayjs().subtract(5, 'day').format('YYYY-MM-DD') },
        transactions_count: 10,
        transactions: generateTransactions({
          length: 10,
          frequency: 30,
          amount: 2000,
          baseDate: dayjs().subtract(5, 'day'),
          label: intl.t('product_tour.clients.action1.transactions'),
        }),
      },
      {
        id: 10,
        name: intl.t('product_tour.clients.action2.title'),
        category: 'restaurant_and_bar',
        status: 'finished',
        transaction_type: 'credit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(2, 'day').format('YYYY-MM-DD') },
        transactions_count: 20,
        transactions: generateTransactions({
          length: 20,
          frequency: 3,
          amount: 10,
          baseDate: dayjs().subtract(2, 'day'),
          label: intl.t('product_tour.clients.action2.transactions'),
        }),
      },
      {
        id: 11,
        name: intl.t('product_tour.clients.action3.title'),
        category: 'transport',
        status: 'finished',
        transaction_type: 'credit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(20, 'day').format('YYYY-MM-DD') },
        transactions_count: 10,
        transactions: generateTransactions({
          length: 10,
          frequency: 60,
          amount: 1000,
          baseDate: dayjs().subtract(20, 'day'),
          label: intl.t('product_tour.clients.action3.transactions'),
        }),
      },
      {
        id: 12,
        name: intl.t('product_tour.clients.action4.title'),
        category: 'insurance',
        status: 'ignored',
        transaction_type: 'credit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(10, 'day').format('YYYY-MM-DD') },
        transactions_count: 15,
        transactions: generateTransactions({
          length: 15,
          frequency: 30,
          amount: 50,
          baseDate: dayjs().subtract(10, 'day'),
          label: intl.t('product_tour.clients.action4.transactions'),
        }),
      },
      {
        id: 13,
        name: intl.t('product_tour.clients.action5.title'),
        category: 'office_supply',
        status: 'finished',
        transaction_type: 'credit',
        transaction_method: 'card',
        last_transaction: { date: dayjs().subtract(3, 'day').format('YYYY-MM-DD') },
        transactions_count: 20,
        transactions: generateTransactions({
          length: 20,
          frequency: 5,
          amount: 150,
          baseDate: dayjs().subtract(3, 'day'),
          label: intl.t('product_tour.clients.action5.transactions'),
        }),
      },
      {
        id: 14,
        name: intl.t('product_tour.clients.action6.title'),
        category: 'office_supply',
        status: 'finished',
        transaction_type: 'credit',
        transaction_method: 'transfer',
        last_transaction: { date: dayjs().subtract(20, 'day').format('YYYY-MM-DD') },
        transactions_count: 20,
        transactions: generateTransactions({
          length: 20,
          frequency: 30,
          amount: 300,
          baseDate: dayjs().subtract(20, 'day'),
          label: intl.t('product_tour.clients.action6.transactions'),
        }),
      },
      {
        id: 15,
        name: intl.t('product_tour.clients.action7.title'),
        category: 'utility',
        status: 'ignored',
        transaction_type: 'credit',
        transaction_method: '',
        last_transaction: { date: dayjs().subtract(10, 'day').format('YYYY-MM-DD') },
        transactions_count: 15,
        transactions: generateTransactions({
          length: 15,
          frequency: 30,
          amount: 500,
          baseDate: dayjs().subtract(10, 'day'),
          label: intl.t('product_tour.clients.action7.transactions'),
        }),
      },
      {
        id: 16,
        name: intl.t('product_tour.clients.action8.title'),
        category: 'utility',
        status: 'ignored',
        transaction_type: 'credit',
        transaction_method: '',
        last_transaction: { date: dayjs().subtract(15, 'day').format('YYYY-MM-DD') },
        transactions_count: 15,
        transactions: generateTransactions({
          length: 15,
          frequency: 30,
          amount: 200,
          baseDate: dayjs().subtract(15, 'day'),
          label: intl.t('product_tour.clients.action8.transactions'),
        }),
      },
    ],
  };
};
