import PowerSelectTrigger from 'ember-power-select/components/power-select/trigger';
export default class PowerSelectCustomsTrigger extends PowerSelectTrigger {
  get options() {
    let {
      selected
    } = this.args;
    if (Array.isArray(selected)) {
      return selected?.map(option => option.value).join(', ') || '';
    }
    return selected;
  }
}