import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import ToastFlashMessagesService from 'app/services/toast-flash-messages';
import * as dayjs from 'dayjs';
import IntlService from 'ember-intl/services/intl';

import ClipboardService from 'qonto-switching-bank-js/services/clipboard';
import { Account } from 'qonto-switching-bank-js/types/migration';

type BankDetailsOption = {
  value: string;
  label: string;
  balance: string;
};

type BankDetailsArgs = {
  accounts: Account[];
};

export default class BankDetailsComponent extends Component<BankDetailsArgs> {
  @service declare clipboard: ClipboardService;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service declare intl: IntlService;

  @tracked pickedAccount = 'all';

  formatBalance(balance?: string | number) {
    if (!balance) {
      return '';
    } else {
      return new Intl.NumberFormat(this.intl.primaryLocale, {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
      }).format(typeof balance === 'string' ? Number.parseFloat(balance) : balance);
    }
  }

  hiddenIbanFormatter(iban: string) {
    return `${iban.slice(0, 4)} •••• ${iban.slice(iban.length - 3)}`;
  }

  get allAccountsBalance() {
    return this.args.accounts.reduce((total, account) => {
      if (!account.balance) {
        return total;
      } else if (typeof account.balance === 'string') {
        return total + Number.parseFloat(account.balance);
      } else {
        return total + account.balance;
      }
    }, 0);
  }

  get options(): BankDetailsOption[] {
    return [
      {
        value: 'all',
        balance: this.formatBalance(this.allAccountsBalance),
        label: this.intl.t('dashboard.overview.cards.dropdown.title'),
      },
      ...this.args.accounts.map(account => ({
        value: account.id,
        balance: this.formatBalance(account.balance),
        label: account.iban
          ? `${account.name} | ${this.hiddenIbanFormatter(account.iban)}`
          : account.name,
      })),
    ];
  }

  get selectedOption() {
    return this.options.find(option => option.value === this.pickedAccount);
  }

  get formattedBalance() {
    let accounts = this.args.accounts;

    if (accounts.length === 1) {
      return this.formatBalance(accounts[0].balance);
    }

    if (this.pickedAccount === 'all') {
      return this.formatBalance(this.allAccountsBalance);
    } else {
      return this.formatBalance(
        accounts.find(account => account.id === this.pickedAccount)?.balance
      );
    }
  }

  get daysSinceLastUpdate() {
    let balance_last_updated_at;

    if (this.pickedAccount === 'all') {
      balance_last_updated_at = this.args.accounts
        .map(account => account.balance_last_updated_at)
        .reduce(
          (picked, balance) => (dayjs(balance).isAfter(picked) ? balance : picked),
          undefined
        );
    } else {
      balance_last_updated_at = this.args.accounts.find(
        account => account.id === this.pickedAccount
      )?.balance_last_updated_at;
    }

    return dayjs().diff(balance_last_updated_at, 'day');
  }

  @action
  selectOption(option: BankDetailsOption) {
    this.pickedAccount = option.value;
  }

  @action
  copyIban(iban: string) {
    this.clipboard.copy(iban.toString());
    this.toastFlashMessages.toastInfo(
      this.intl.t('dashboard.account_details.notifications.iban_copy')
    );
  }
}
