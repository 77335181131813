import { getUrlsTable } from '@qonto-web/shared-config';

import ENV from 'qonto-switching-bank-js/config/environment';

let { backendUrlsTable, frontendUrlsTable } = getUrlsTable(ENV);

export const {
  switchingBankApiBaseURL: apiBaseURL,
  authBaseURL,
  apiBaseURL: qontoApiBaseURL,
} = backendUrlsTable;

export const {
  switchingBankJsURL: jsURL,
  registerJsURL: registerURL,
  appJsURL: appURL,
} = frontendUrlsTable;

export const appWithDeeplink = appURL + '/deeplinks?action=switching_bank';
export const signInURL = appURL + '/signin';

// in Qonto APIs, namespaces are applied to resources
export const apiNamespace = 'v1';
export const authNamespace = 'v1';
export const billerNamespace = 'v1';
export const registerNamespace = 'v1';
export const authUserNamespace = 'v2';

export const transactionNamespace = 'v3';
export const organizationNamespace = 'v3';
export const attachmentNamespace = 'v3';
export const membershipNamespace = 'v3';
export const migrationTasksNamespace = 'v3';
export const transferNamespace = 'v3';
export const cardNamespace = 'v3';
export const checkNamespace = 'v3';
export const conciergeRequestNamespace = 'v3';
export const directDebitNamespace = 'v3';
export const incomeNamespace = 'v3';
export const labelListNamespace = 'v3';
export const labelNamespace = 'v3';
export const walletToWalletNamespace = 'v3';
export const beneficiaryNamespace = 'v3';
export const multiTransfersNamespace = 'v3';
export const hubNamespace = 'v1';
export const notifierNamespace = 'v1';
export const bankAccountsNamespace = 'v3';
