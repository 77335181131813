import { action } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import { EmberRunTimer } from '@ember/runloop/types';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface AnimatedCheckboxArgs {
  checked: boolean;
  toggleChecked: () => Promise<void>;
}

export default class AnimatedCheckboxComponent extends Component<AnimatedCheckboxArgs> {
  @tracked checked = this.args.checked; // Local 'checked' property
  scheduledToggle?: EmberRunTimer;

  // Local 'toggleChecked' action
  @action toggleChecked(event: Event) {
    event.stopPropagation();
    this.checked = !this.checked;

    let target = event.target as HTMLElement;
    let button = target.querySelector('button');

    // Needed for the animation to work correctly
    if (target.tagName === 'DIV' && button) {
      button.focus();
    }

    // In case of another click before 500ms, cancel the scheduled action.
    if (this.scheduledToggle) {
      cancel(this.scheduledToggle);
    }

    // In case of a double-click, we don't need to call the parent 'toggleChecked'
    if (this.checked !== this.args.checked) {
      this.scheduledToggle = later(this.args.toggleChecked, 500);
    }
  }
}
