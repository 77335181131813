export default {
  "container": "_container_1rknm4",
  "position": "_position_1rknm4",
  "title-container": "_title-container_1rknm4",
  "subtitle": "_subtitle_1rknm4",
  "content": "_content_1rknm4",
  "progress-container": "_progress-container_1rknm4",
  "progress-bar": "_progress-bar_1rknm4",
  "progress-bar-green": "_progress-bar-green_1rknm4",
  "link": "_link_1rknm4",
  "card": "_card_1rknm4",
  "progress-info": "_progress-info_1rknm4"
};
