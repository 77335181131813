var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
let PasswordInput = (_class = class PasswordInput extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
    _initializerDefineProperty(this, "capsLockOn", _descriptor2, this);
    _initializerDefineProperty(this, "type", _descriptor3, this);
    _initializerDefineProperty(this, "_value", _descriptor4, this);
    _defineProperty(this, "_id", guidFor(this));
  }
  get errorClass() {
    return this.args.hasErrors ? 'is-error' : '';
  }
  get id() {
    return this._id;
  }
  get hasValue() {
    return this._value.length > 0;
  }
  get placeholderTranslation() {
    return this.args.placeholder ?? this.intl.t('ui-kit.password_creator.placeholder');
  }
  get labelTranslation() {
    return this.args.label ?? this.intl.t('ui-kit.password_creator.label');
  }
  checkCapslock(event) {
    this.capsLockOn = event?.getModifierState?.('CapsLock');
  }
  handleToggleType(type) {
    this.type = type === 'password' ? 'text' : 'password';
  }
  handleChange(event) {
    let value = event.target.value;
    this._value = value;
    this.args.onChange(value);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "capsLockOn", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'password';
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_value", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return '';
  }
}), _applyDecoratedDescriptor(_class.prototype, "checkCapslock", [action], Object.getOwnPropertyDescriptor(_class.prototype, "checkCapslock"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleToggleType", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleToggleType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
export { PasswordInput as default };