import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';

import ApplicationAdapter from 'qonto-switching-bank-js/adapters/application';

export default class MigrationAdapter extends ApplicationAdapter {
  @service network;

  async queryRecord() {
    try {
      let response = await this.network.get('/migrations/current');

      return { migration: response.data };
    } catch (error) {
      if (error.response?.status === 404) {
        return { migration: null };
      } else {
        Sentry.captureException(error);
        throw error;
      }
    }
  }

  async updateRecord(_store, _type, snapshot) {
    let response = await this.network.put(`/migrations/${snapshot.id}`, {
      migration: this.getDirtyAttributes(snapshot.record),
    });

    return { migration: response.data };
  }
}
