import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { task, timeout } from 'ember-concurrency';

export default class WaitingDashboardRoute extends Route {
  @service store;

  @(task({
    migrationService: service('migration'),
    router: service(),
    store: service(),
    migration: null,

    async updateMigration() {
      let migration = await this.store.queryRecord('migration', {});
      this.set('migration', migration);
      return migration;
    },

    async redirect() {
      if (this.migration.status === 'in_progress') {
        this.router.transitionTo('dashboard');
      } else if (this.migration.status === 'empty_dashboard') {
        this.router.transitionTo('onboarding.dashboard-empty');
      } else {
        this.router.transitionTo('errors.maintenance');
      }
    },

    *perform() {
      let migration = yield this.updateMigration();
      while (migration.status === 'dashboard_generation') {
        yield timeout(10000);
        migration = yield this.updateMigration();
      }
      this.redirect();
    },
  }).cancelOn('deactivate'))
  fetchMigration;

  model() {
    return { task: this.fetchMigration.perform() };
  }

  @action
  willTransition() {
    this.fetchMigration.cancelAll();
  }
}
