import { assert } from '@ember/debug';
import Component from '@glimmer/component';
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
export default class PromotionalCard extends Component {
  constructor() {
    super(...arguments);
    assert('<PromotionalCard /> must have a @subtitle', this.args.subtitle);
    assert('<PromotionalCard /> must have a @badgeText', this.args.badgeText);
    assert('<PromotionalCard /> must have a @ctaText', this.args.ctaText);
    assert('<PromotionalCard /> must have a @onCtaClick', this.args.onCtaClick);
    assert(`<PromotionalCard /> can only have following badgeTypes: ${Object.values(BADGE_TYPE).join(', ')}`, Object.values(BADGE_TYPE).includes(this.args.badgeType));
  }
}