export default {
  "badge": "_badge_102aql",
  "icon-only": "_icon-only_102aql",
  "purple": "_purple_102aql",
  "mint": "_mint_102aql",
  "peach": "_peach_102aql",
  "gray": "_gray_102aql",
  "yellow": "_yellow_102aql",
  "icon": "_icon_102aql",
  "no-margin": "_no-margin_102aql"
};