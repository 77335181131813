function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

import memoize from 'fast-memoize';
import Formatter from './-base';
const NUMBER_OPTIONS = ['localeMatcher', 'style', 'currency', 'currencyDisplay', 'unit', 'unitDisplay', 'useGrouping', 'minimumIntegerDigits', 'minimumFractionDigits', 'maximumFractionDigits', 'minimumSignificantDigits', 'maximumSignificantDigits',
// Unified NumberFormat (Stage 3 as of 10/22/19)
'compactDisplay', 'currencyDisplay', 'currencySign', 'notation', 'signDisplay', 'unitDisplay', 'unit'];

/**
 * @private
 * @hide
 */
export default class FormatNumber extends Formatter {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "createNativeFormatter", memoize((locales, options) => {
      return new Intl.NumberFormat(locales, options);
    }));
  }
  get options() {
    return NUMBER_OPTIONS;
  }
  format(locale, value, formatOptions) {
    const formatterOptions = this.readOptions(formatOptions);
    this.validateFormatterOptions(locale, formatterOptions);
    const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
    return formatterInstance.format(value);
  }
}
_defineProperty(FormatNumber, "type", 'number');