import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { appURL } from 'qonto-switching-bank-js/constants/hosts';

export default class SettingsMenuComponent extends Component {
  @service store;
  @service organizationUtils;
  @service('migration') migrationService;

  appLink = appURL;

  @tracked isMainPanelOpen = false;
  @tracked isOrganizationPanelOpen = false;
  @tracked organizations = [];

  @action openMainPanel() {
    this.isMainPanelOpen = true;
  }
  @action closeMainPanel() {
    this.isOrganizationPanelOpen = false;
    this.isMainPanelOpen = false;
  }

  @action openOrganizationPanel() {
    this.isOrganizationPanelOpen = true;

    if (this.organizations.length === 0) {
      this.store.findAll('organization', { reload: true }).then(organizations => {
        this.organizations = organizations
          .slice()
          .sort(this.organizationUtils.compareOrganizations);
      });
    }
  }
  @action closeOrganizationPanel() {
    this.isOrganizationPanelOpen = false;
  }

  @action endMigration() {
    this.migrationService.endMigration(this.args.migration);
  }
}
