import { helper } from '@ember/component/helper';

/**
 * This helper transforms a number into an array whose length equals the provided number.
 * Each array element is an empty slot.
 *
 * It is used to repeat a loop X times.
 * e.g. {{#each (repeat 3)}}
 *
 * @param {int} times - Array length
 * @return {Array}
 */
export default helper(([times]) => new Array(times));