function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable ember/no-runloop */
import { scheduleOnce } from '@ember/runloop';
export class Aligner {
  constructor() {
    /**
     * Stores parent elements by the keys they belong to
     *
     * @type {Map<string, Element[]>}
     */
    _defineProperty(this, "parentElementTracker", new Map());
    /**
     * Stores the key for each children, so that we know for which key we should align parent elements
     *
     * @type {WeakMap<Element, string>}
     */
    _defineProperty(this, "childrenKeyMeta", new WeakMap());
    _defineProperty(this, "resizeObserver", new ResizeObserver(entries => {
      let elements = entries.reduce((elements, entry) => {
        elements.push(entry.target);
        return elements;
      }, []);
      let keys = this.keysForChildren(elements);
      scheduleOnce('afterRender', this, this.run, keys);
    }));
  }
  track(key, element) {
    let elementsForKey = this.parentElementTracker.get(key) || [];
    if (!elementsForKey.includes(element)) {
      elementsForKey.push(element);
      let observableChildren = Array.from(element.childNodes).filter(this.isObservable);
      for (let child of observableChildren) {
        this.childrenKeyMeta.set(child, key);

        // Calling observe on an element will trigger the event, and, as a consequence, a new run
        this.resizeObserver.observe(child, {
          box: 'border-box'
        });
      }
    }
    this.parentElementTracker.set(key, elementsForKey);
  }
  untrack(key, element) {
    if (!this.isTracking(key, element)) {
      return;
    }
    element.style.minHeight = '';
    for (let child of element.childNodes) {
      if (this.isObservable(child)) {
        this.resizeObserver.unobserve(child);
        this.childrenKeyMeta.delete(child);
      }
    }
    let remainingElements = this.parentElementTracker.get(key).filter(e => e !== element);

    // If there are no elements left, just remove the key and the job is finished
    if (remainingElements.length) {
      this.parentElementTracker.set(key, remainingElements);
    } else {
      this.parentElementTracker.delete(key);
    }
  }
  isTracking(key, element) {
    return Boolean(this.parentElementTracker.get(key)?.includes(element));
  }
  hasElementsForKey(key) {
    return Boolean(this.parentElementTracker.get(key));
  }
  run(keys) {
    // If no specific keys provided, run on all keys
    if (!keys) {
      keys = this.parentElementTracker.keys();
    }
    for (let key of keys) {
      let elements = this.parentElementTracker.get(key);
      if (elements) {
        this.alignElements(elements);
      }
    }
  }
  keysForChildren(elements) {
    return elements.reduce((keys, element) => {
      let keyForElement = this.childrenKeyMeta.get(element);
      if (!keys.includes(keyForElement)) {
        keys.push(keyForElement);
      }
      return keys;
    }, []);
  }
  alignElements(elements) {
    // Reset `min-height` before obtaining element heights
    for (let element of elements) {
      element.style.minHeight = '';
    }
    let maxHeight = elements.reduce((maxHeight, element) => {
      element.style.minHeight = '';
      return Math.max(maxHeight, element.offsetHeight);
    }, 0);
    for (let element of elements) {
      element.style.minHeight = maxHeight + 'px';
    }
  }
  isObservable(element) {
    return element instanceof Element;
  }
}