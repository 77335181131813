import {
  MigrationStatus,
  ProviderConnectionStatusDetails,
} from 'qonto-switching-bank-js/types/migration';

export const authorizedRoutes = (status: MigrationStatus | undefined) => {
  if (status) {
    switch (status) {
      case 'synchronizing':
        return [
          'onboarding.appointment',
          'onboarding.select-bank',
          'onboarding.select-bank.index',
          'onboarding.select-bank.child',
          'onboarding.select-bank.child.index',
          'onboarding.select-bank.child.credentials',
          'onboarding.synchronization-in-progress',
          'onboarding.synchronization-success',
        ];
      case 'created':
        return ['onboarding.appointment', 'onboarding.select-accounts'];
      case 'synchronized':
        return [
          'onboarding.select-accounts',
          'onboarding.choose-dashboard-access',
          'onboarding.appointment',
        ];
      case 'call_planned':
        return ['onboarding.select-accounts', 'onboarding.migration-status'];
      case 'dashboard_generation':
        return ['onboarding.waiting-dashboard'];
      case 'empty_dashboard':
        return ['onboarding.appointment', 'onboarding.dashboard-empty'];
      case 'in_progress':
        return [
          'onboarding.select-accounts',
          'dashboard.type',
          'dashboard.type.index',
          'dashboard.type.action',
          'dashboard.index',
          'dashboard',
        ];
      case 'finished':
        return [
          'onboarding.migration-succeeded',
          'onboarding.select-bank',
          'onboarding.select-bank.index',
          'onboarding.select-bank.child',
          'onboarding.select-bank.child.index',
          'onboarding.select-bank.child.credentials',
        ];
    }
  } else {
    return [
      'onboarding.select-bank',
      'onboarding.select-bank.index',
      'onboarding.select-bank.child',
      'onboarding.select-bank.child.index',
      'onboarding.select-bank.child.credentials',
      'dashboard.type',
      'dashboard.type.index',
      'dashboard.type.action',
      'dashboard.index',
      'dashboard',
    ];
  }
};

export const extendedProviderConnectionStatusBody = (
  errorStatus: ProviderConnectionStatusDetails,
  extendedStatus: string
) => {
  switch (errorStatus) {
    case 'needs_human_action':
      return {
        TOS_TO_VALIDATE: 'synchronization.error_page_429.body_action1',
        CONTRACT_TO_SIGN: 'synchronization.error_page_429.body_action2',
        INACTIVE_CREDS: 'synchronization.error_page_429.body_action3',
        ACCOUNT_TO_ACTIVATE: 'synchronization.error_page_429.body_action4',
        PHONE_NB_TO_CONFIRM: 'synchronization.error_page_429.body_action5',
        INFORMATION_TO_REVIEW: 'synchronization.error_page_429.body_action6',
        SMTH_TO_VALIDATE: 'synchronization.error_page_429.body_action6',
        OTP_REGISTRATION_TO_DO: 'synchronization.error_page_429.body_action10',
        KYC_TO_FILL: 'synchronization.error_page_429.body_action7',
        AMLCFT_DATA_TO_CONFIRM: 'synchronization.error_page_429.body_action6',
        PHONE_NB_TO_SET: 'synchronization.error_page_429.body_action5',
        PERSO_DATA_TO_CONFIRM: 'synchronization.error_page_429.body_action7',
        FORM_TO_FILL: 'synchronization.error_page_429.body_action6',
        READONLY_ACCESS_TO_CREATE: 'synchronization.error_page_429.body_action11',
        CANNOT_ACCESS_SERVICES: 'synchronization.error_page_429.body_action11',
        NO_ONLINE_ACCESS: 'synchronization.error_page_429.body_action11',
        CREDS_UPDATE_MESSAGE: 'synchronization.error_page_429.body_action13',
        UNLOCK_OTP_IN_APP: 'synchronization.error_page_429.body_action10',
        UNLOCK_OTP_IN_WEB: 'synchronization.error_page_429.body_action10',
        CONTACT_BANK: 'synchronization.error_page_429.body_action12',
        EXPIRED_ID_CONTACT_BANK: 'synchronization.error_page_429.body_action12',
        NO_ACCESS_CONTACT_BANK: 'synchronization.error_page_429.body_action12',
        INACTIVE_CREDS_CONTACT_BANK: 'synchronization.error_page_429.body_action13',
        PWD_RENEWAL: 'synchronization.error_page_429.body_action14',
        PWD_RENEWAL_FIRST_CO: 'synchronization.error_page_429.body_action15',
        PWD_RENEWAL_READONLY_ACCESS: 'synchronization.error_page_429.body_action14',
      }[extendedStatus];
    default:
      return undefined;
  }
};

export const extendedProviderConnectionStatusToaster = (
  errorStatus: ProviderConnectionStatusDetails,
  extendedStatus: string
) => {
  switch (errorStatus) {
    case 'needs_human_action':
      return {
        TOS_TO_VALIDATE: 'error_429.disclaimer.body_action1',
        CONTRACT_TO_SIGN: 'error_429.disclaimer.body_action2',
        INACTIVE_CREDS: 'error_429.disclaimer.body_action3',
        ACCOUNT_TO_ACTIVATE: 'error_429.disclaimer.body_action4',
        PHONE_NB_TO_CONFIRM: 'error_429.disclaimer.body_action5',
        INFORMATION_TO_REVIEW: 'error_429.disclaimer.body_action6',
        SMTH_TO_VALIDATE: 'error_429.disclaimer.body_action6',
        OTP_REGISTRATION_TO_DO: 'error_429.disclaimer.body_action10',
        KYC_TO_FILL: 'error_429.disclaimer.body_action7',
        AMLCFT_DATA_TO_CONFIRM: 'error_429.disclaimer.body_action6',
        PHONE_NB_TO_SET: 'error_429.disclaimer.body_action5',
        PERSO_DATA_TO_CONFIRM: 'error_429.disclaimer.body_action7',
        FORM_TO_FILL: 'error_429.disclaimer.body_action6',
        READONLY_ACCESS_TO_CREATE: 'error_429.disclaimer.body_action11',
        CANNOT_ACCESS_SERVICES: 'error_429.disclaimer.body_action11',
        NO_ONLINE_ACCESS: 'error_429.disclaimer.body_action11',
        CREDS_UPDATE_MESSAGE: 'error_429.disclaimer.body_action13',
        UNLOCK_OTP_IN_APP: 'error_429.disclaimer.body_action10',
        UNLOCK_OTP_IN_WEB: 'error_429.disclaimer.body_action10',
        CONTACT_BANK: 'error_429.disclaimer.body_action12',
        EXPIRED_ID_CONTACT_BANK: 'error_429.disclaimer.body_action12',
        NO_ACCESS_CONTACT_BANK: 'error_429.disclaimer.body_action12',
        INACTIVE_CREDS_CONTACT_BANK: 'error_429.disclaimer.body_action13',
        PWD_RENEWAL: 'error_429.disclaimer.body_action14',
        PWD_RENEWAL_FIRST_CO: 'error_429.disclaimer.body_action15',
        PWD_RENEWAL_READONLY_ACCESS: 'error_429.disclaimer.body_action14',
      }[extendedStatus];
    default:
      return undefined;
  }
};
