import RESTAdapter from '@ember-data/adapter/rest';
import { inject as service } from '@ember/service';

import { authBaseURL, authUserNamespace } from 'qonto-switching-bank-js/constants/hosts';

export default class UserAdapter extends RESTAdapter {
  @service network;
  baseUrl = `${authBaseURL}/${authUserNamespace}`;

  queryRecord() {
    return this.network.get(`${authBaseURL}/${authUserNamespace}/users/me`).then(response => {
      return response.data;
    });
  }
}
