import Model, { attr } from '@ember-data/model';

export default class ParentBankModel extends Model {
  @attr declare name: string;
  @attr declare logo_url: string;
  @attr declare display_order: number;
  @attr declare child_bank_ids: number[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'parent-bank': ParentBankModel;
  }
}
