import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';
import { taskFor } from 'ember-concurrency-ts';

import ChildBankModel from 'qonto-switching-bank-js/models/child-bank';
import IndexRoute from 'qonto-switching-bank-js/routes/application/route';
import FunnelService from 'qonto-switching-bank-js/services/funnel';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { PROVIDER_ITEM_REFRESH_STATUS } from 'qonto-switching-bank-js/types/credentials';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class SynchronizationRoute extends Route {
  @service declare funnel: FunnelService;
  @service declare router: RouterService;
  @service declare network: NetworkService;
  @service declare store: Store;
  @service declare segment: SegmentService;

  async model() {
    let { user } = this.modelFor('application') as ModelFrom<IndexRoute>;
    let migration = await this.store.queryRecord('migration', {});
    let response = await this.network.get(
      `/banks/children_by_provider_bank_id/${migration?.bank_id}`
    );
    let children: ChildBankModel = response.data;

    this.segment.track('bankswitch_synchro_in_progress_seen');

    return { children, user };
  }

  afterModel(model: ModelFrom<SynchronizationRoute>) {
    let providerItemRefreshInfoPoller = taskFor(this.funnel.pollProviderItemRefreshInfoTask);

    providerItemRefreshInfoPoller
      .perform({
        statusesToAwait: [PROVIDER_ITEM_REFRESH_STATUS.FINISHED],
        maxFetchCount: 100,
      })
      .then(async providerItemRefreshInfo => {
        if (providerItemRefreshInfo.status === PROVIDER_ITEM_REFRESH_STATUS.FINISHED) {
          if (providerItemRefreshInfo.status_details === 'pro_account_locked') {
            let organizationId = localStorage.getItem('organizationId');

            if (organizationId) {
              let organization = await this.store.findRecord('organization', organizationId);

              await this.network.post('/provider/item/unlock', {
                holder_name: model.user.full_name,
                corporate_name: organization.legal_name,
              });

              this.router.transitionTo('onboarding.synchronization-success');
            } else {
              Sentry.captureMessage(
                "Can't find organizationid in localstorage, can't unlock account"
              );
            }
          } else {
            this.router.transitionTo('onboarding.synchronization-success');
          }
        } else if (providerItemRefreshInfo.status === PROVIDER_ITEM_REFRESH_STATUS.FINISHED_ERROR) {
          this.router.transitionTo('onboarding.select-bank.child.credentials');
        } else {
          Sentry.captureMessage(
            `Unexpected provider item refresh info status after synchronization in progress polling: ${providerItemRefreshInfo.status}, ${providerItemRefreshInfo.status_details}`
          );

          this.router.transitionTo('errors.maintenance');
        }
      });
  }
}
