import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/browser';
import { LottiePlayer } from 'design-system-kit';
import { taskFor } from 'ember-concurrency-ts';

import SelectBankChildCredentialsRoute from 'qonto-switching-bank-js/routes/onboarding/select-bank/child/credentials/route';
import FunnelService from 'qonto-switching-bank-js/services/funnel';
import NetworkService from 'qonto-switching-bank-js/services/network';
import {
  PROVIDER_ITEM_REFRESH_STATUS,
  ProviderItemRequestBody,
} from 'qonto-switching-bank-js/types/credentials';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

interface ConfirmationModalArgs {
  data: { migrationId: string; providerItemRequestBody: ProviderItemRequestBody };
  close: (...args: unknown[]) => void;
}
export default class CredentialsModalOtpComponent extends Component<ConfirmationModalArgs> {
  lottiePlayer = LottiePlayer;
  @service declare network: NetworkService;
  @service declare router: RouterService;
  @service declare funnel: FunnelService;
  declare model: ModelFrom<SelectBankChildCredentialsRoute>;
  @tracked code = '';
  @tracked isLoading = false;
  @tracked isInvalidOtp = false;

  get isDisabled() {
    return !this.code || this.isLoading;
  }

  get providerItemRefreshInfoPoller() {
    return taskFor(this.funnel.pollProviderItemRefreshInfoTask);
  }

  @action async sendCode() {
    this.isLoading = true;
    this.isInvalidOtp = false;
    try {
      await this.network.post('/provider/item/mfa', {
        otp: this.code,
      });
      let providerItemRefreshInfo = await this.providerItemRefreshInfoPoller.perform({
        statusesToAwait: [PROVIDER_ITEM_REFRESH_STATUS.RETRIEVING_DATA],
      });
      if (
        providerItemRefreshInfo.status === PROVIDER_ITEM_REFRESH_STATUS.RETRIEVING_DATA ||
        providerItemRefreshInfo.status === PROVIDER_ITEM_REFRESH_STATUS.FINISHED
      ) {
        this.router.transitionTo('onboarding.synchronization-in-progress');
        this.args.close();
      } else if (providerItemRefreshInfo.status === PROVIDER_ITEM_REFRESH_STATUS.FINISHED_ERROR) {
        await this.network.post('/provider/items', this.args.data.providerItemRequestBody);
        await this.providerItemRefreshInfoPoller.perform({
          statusesToAwait: [PROVIDER_ITEM_REFRESH_STATUS.INFO_REQUIRED],
        });
      } else {
        Sentry.captureMessage(
          `Unexpected provider item refresh info status after modal otp polling: ${providerItemRefreshInfo.status}, ${providerItemRefreshInfo.status_details}`
        );
      }
      this.isInvalidOtp = true;
      this.isLoading = false;
    } catch (error) {
      this.router.transitionTo('errors.maintenance');
      Sentry.captureMessage(
        `OTP validation could not be complete: ${error?.response?.data?.message}`
      );
    }
  }
}
