var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import window from 'ember-window-mock';
const ERRORS = {
  FAILED_AUTOPLAY: 'autoplay failed',
  UNKNOWN_WRAPPER: 'unknown wrapper HTML element',
  FAILED_COLOR_PARSE: 'failed to parse a video color'
};
const CANVAS_SIDE = 4;
const MAX_RETRY = 10;

/**
 *
 * Display video based animations on all the browsers.
 *
 * This components solves three issues:
 * - Corrects unmatching backgrounds between video bg and container bg Removes the need of alpha channel on WebM video (that doesn't work with Safari)
 * - Allows us to export After Effect complex animations in WebM instead of lottie (better for performances and size)
 *
 *   ⚠️  Note: The component will update the wrapper background color. In order to avoid evident color shifting
 *   the wrapper HTML element background color should be as close as possible as the video bg.
 *
 *   @argument containerSelector String - The query selector for the wrapping container
 *   @argument width Number
 *   @argument height Number
 *   @argument isBlackBackground Boolean
 *   @argument onError Function
 *
 */
let VideoAnimationComponent = (_class = class VideoAnimationComponent extends Component {
  constructor() {
    super(...arguments);
    /** @type {HTMLCanvasElement} */
    _defineProperty(this, "canvasDOMElement", void 0);
    /** @type {HTMLVideoElement} */
    _defineProperty(this, "videoDOMElement", void 0);
    _defineProperty(this, "animationFrameId", void 0);
    _initializerDefineProperty(this, "showFallback", _descriptor, this);
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      this.showFallback = true;
    }
  }
  onError() {
    this.showFallback = true;
  }
  setupDOMElements(element) {
    if (element instanceof HTMLCanvasElement) {
      this.canvasDOMElement = element;
      return;
    }
    this.videoDOMElement = element;
  }
  drawLoop() {
    this.animationFrameId = requestAnimationFrame(this.drawLoop);
    this.canvasDOMElement.getContext('2d').drawImage(this.videoDOMElement, 0, 0, this.videoDOMElement.videoWidth, this.videoDOMElement.videoHeight, 0, 0, this.args.width, this.args.height);
  }
  loadVideoData() {
    // We override these attributes due to an issue in Chrome
    this.videoDOMElement.muted = true;
    this.videoDOMElement.autoplay = true;
    this.videoDOMElement.play().catch(() => {
      this.args.onError?.(ERRORS.FAILED_AUTOPLAY);
      this.showFallback = true;
    });
    if (!this.showFallback) {
      this.overrideContainerBackgroundColor();
    }
  }
  loadVideoMetadata() {
    // Avoid sudden video show up after loading.
    // Transition managed in the css module
    this.canvasDOMElement.style.opacity = '1';
    if (!this.showFallback) {
      this.drawLoop();
    }
  }
  cleanAnimationFrame() {
    cancelAnimationFrame(this.animationFrameId);
  }

  /**
   * This function creates a canvas HTML element 4px x 4px (that is never attached to the DOM)
   * and draws on it the first 4px x 4px frame of the video.
   *
   * Then the `getImageData` function takes the color informations about that square
   * and then updates the wrapper elements accordingly.
   *
   * This is necessary since many GPUs use in some specific situations a limited range of byte size
   * to represent RGB colors (instead 0-255 they use 16-235).
   * In order to prevent a slight difference between video background and the wrapper background colors
   * we force the background to take the same as the limited video.
   *
   * [Nvidia example](https://www.nvidia.com/content/Control-Panel-Help/vLatest/en-us/mergedProjects/nvdsp/To_change_the_RGB_range.htm).
   */
  overrideContainerBackgroundColor(count = 0) {
    let canvas = new OffscreenCanvas(CANVAS_SIDE, CANVAS_SIDE);
    let ctx = canvas.getContext('2d');
    ctx.drawImage(this.videoDOMElement, 0, 0, CANVAS_SIDE, CANVAS_SIDE);
    let {
      data
    } = ctx.getImageData(0, 0, CANVAS_SIDE, CANVAS_SIDE);
    let container = document.querySelectorAll(this.args.containerSelector);
    assert('The container should be a valid HTML element', container.length > 0);
    if (container.length === 0) {
      this.args.onError?.(ERRORS.UNKNOWN_WRAPPER);
      this.showFallback = true;
      return;
    }

    // Take the first pixel rgb info
    let colors = [data[0], data[1], data[2]];
    let isFrameLoaded = !colors.every(c => c < 2);
    let videoBGisBlack = this.args.isBlackBackground && count === MAX_RETRY;
    if (isFrameLoaded || videoBGisBlack) {
      if (count === MAX_RETRY) {
        this.args.onError?.(ERRORS.FAILED_COLOR_PARSE);
      }
      container.forEach(el => {
        el.style.backgroundColor = `rgb(${colors[0]}, ${colors[1]}, ${colors[2]})`;
      });
    } else {
      setTimeout(() => {
        this.overrideContainerBackgroundColor(count++);
      }, 10);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showFallback", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "onError", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupDOMElements", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setupDOMElements"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drawLoop", [action], Object.getOwnPropertyDescriptor(_class.prototype, "drawLoop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadVideoData", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadVideoData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadVideoMetadata", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadVideoMetadata"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cleanAnimationFrame", [action], Object.getOwnPropertyDescriptor(_class.prototype, "cleanAnimationFrame"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "overrideContainerBackgroundColor", [action], Object.getOwnPropertyDescriptor(_class.prototype, "overrideContainerBackgroundColor"), _class.prototype)), _class);
export { VideoAnimationComponent as default };