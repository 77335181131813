var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import dayjs from 'dayjs';
const DATE_FORMAT = 'DD/MM/YYYY';
let CreditNotePreview = (_class = class CreditNotePreview extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
  }
  get customer() {
    let {
      customer,
      customerSnapshot
    } = this.args.receivableInvoice;
    return customerSnapshot ?? customer;
  }
  get creditNoteIssueDate() {
    let {
      issueDate
    } = this.args.creditNote;
    return this._formatDate(issueDate);
  }
  get invoiceIssueDate() {
    let {
      issueDate
    } = this.args.receivableInvoice;
    return this._formatDate(issueDate);
  }
  get displayLanguage() {
    let {
      receivableInvoice,
      locale
    } = this.args.creditNote;
    let customerLocale = locale ?? receivableInvoice?.get?.('customer.locale');
    return customerLocale ?? this.intl?.primaryLocale;
  }
  _formatDate(value) {
    if (value && dayjs(value).isValid()) {
      return dayjs(value).format(DATE_FORMAT);
    }
    return '';
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { CreditNotePreview as default };