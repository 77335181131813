/* eslint-disable @typescript-eslint/no-unused-vars */
import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from 'design-system-kit';
import { task } from 'ember-concurrency';

export default class ChooseOrganizationController extends Controller {
  lottiePlayer = LottiePlayer;

  @service network;
  @service router;
  @service('organization') organizationService;
  @service entryPointRoute;
  @service store;

  @tracked selectedOrganization = null;

  get isDisabled() {
    return isEmpty(this.selectedOrganization) || this.updateSelectedOrganizationTask.isRunning;
  }

  @action
  selectOrganization(id) {
    this.selectedOrganization = id;
  }

  @(task(function* () {
    this.organizationService.setSelectedOrganization(this.selectedOrganization);

    try {
      let migration = yield this.store.queryRecord('migration', {});
      let entryPointRoute = yield this.entryPointRoute.getEntryPoint(migration);
      if (entryPointRoute === 'onboarding.select-bank.child.credentials') {
        yield this.entryPointRoute.transitionToCredentialsWithCurrentMigration(migration);
      } else {
        this.router.transitionTo(entryPointRoute);
      }
    } catch (error) {
      let errorMessage = error?.response?.data?.message || error?.response?.data?.errors;

      if (errorMessage === 'Authenticable::InvalidRoleError') {
        this.router.transitionTo('errors.unauthorized');
      }
    }
  }).drop())
  updateSelectedOrganizationTask;
}
