var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable ember/no-array-prototype-extensions, ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/no-computed-properties-in-native-classes, ember/require-tagless-components */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { alias, bool, equal, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { attribute, className, classNames } from '@ember-decorators/component';
const DEFAULT_MAX_SIZE = 30 * 1e6;
const DEFAULT_MIN_SIZE = 0;
const DEFAULT_EXTENSIONS = 'pdf,jpg,jpeg,png,gif';

/**
  A dropzone component, to upload files. Usage:

  ```hbs
    {{x-dropzone-multipart
      label="Drop your png files here"
      successLabel="It worked !"
      bigLabel=true
      disabled=false
      required=true
      extensions="png"
      filesLimit=2
      localFiles=this.localFiles
      addLocalFile=(action "handleAddFile")
      deleteLocalFile=(action "handleDeleteFile")
    }}
  ```

  @class XDropzoneMultipart
  @public
*/
let XDropzoneMultipart = (_dec = classNames('x-dropzone', 'is-file-dropzone'), _dec2 = className('disabled'), _dec3 = className('required'), _dec4 = className('colors'), _dec5 = not('multiple'), _dec6 = className('multiple'), _dec7 = className('big-label'), _dec8 = attribute('data-has-error'), _dec9 = className('has-errors'), _dec10 = bool('errorMessage'), _dec11 = className('has-file'), _dec12 = bool('localFiles.firstObject'), _dec13 = className('has-status'), _dec14 = bool('statusIcon'), _dec15 = alias('localFiles.firstObject'), _dec16 = equal('file.state', 'uploading'), _dec17 = equal('file.state', 'uploaded'), _dec18 = computed('file.progress'), _dec19 = computed('disabled', 'isUploading'), _dec20 = computed('readFileError', 'errors'), _dec21 = computed('label', 'successLabel', 'hasErrors', 'hasFile', 'multiple'), _dec22 = computed('hasErrors', 'hasFile', 'colors'), _dec23 = computed('fileType'), _dec24 = computed('file', 'file.fileContentType'), _dec25 = computed('multiple', 'file'), _dec26 = computed('maxSize', 'text', 'showDefaultText'), _dec(_class = (_class2 = class XDropzoneMultipart extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "strings", _descriptor, this);
    _initializerDefineProperty(this, "disabled", _descriptor2, this);
    _initializerDefineProperty(this, "required", _descriptor3, this);
    _initializerDefineProperty(this, "single", _descriptor4, this);
    _initializerDefineProperty(this, "colors", _descriptor5, this);
    _initializerDefineProperty(this, "bigLabel", _descriptor6, this);
    _initializerDefineProperty(this, "hasErrors", _descriptor7, this);
    _initializerDefineProperty(this, "hasFile", _descriptor8, this);
    _initializerDefineProperty(this, "hasStatus", _descriptor9, this);
    _initializerDefineProperty(this, "file", _descriptor10, this);
    _initializerDefineProperty(this, "isUploading", _descriptor11, this);
    _initializerDefineProperty(this, "isUploaded", _descriptor12, this);
  }
  get progress() {
    return this.get('file.progress') / 100;
  }
  get showTrashIcon() {
    if (this.disabled) {
      return false;
    } else if (!this.isUploading) {
      return true;
    }
    return false;
  }
  get errorMessage() {
    let {
      errors,
      readFileError
    } = this;
    if (readFileError) {
      return readFileError;
    }
    if (typeof errors === 'string') {
      return errors;
    }
    if (errors) {
      let hasManyErrorMessage = errors.get('firstObject.message.firstObject');
      let singleErrorMessage = errors.get('firstObject.message');
      return hasManyErrorMessage || singleErrorMessage;
    }
    return '';
  }
  get dropzoneLabel() {
    if (!this.get('multiple')) {
      if (this.get('hasErrors')) {
        return this.get('errorMessage');
      }
      if (this.get('hasFile')) {
        return this.get('successLabel');
      }
    }
    return this.get('label');
  }
  get statusIcon() {
    if (this.get('colors') || this.get('bigLabel')) {
      return this.get('hasErrors') ? 'icon_cross_rounded_filled' : this.get('hasFile') ? 'icon_checkmark_rounded_filled' : null;
    }
    return null;
  }
  get fileIcon() {
    return this.get('fileType');
  }
  get fileType() {
    let contentType = this.get('file.fileContentType');
    if (contentType && contentType.includes('image')) {
      return 'icon_picture';
    }
    return 'icon_invoices';
  }
  get dropzoneIcon() {
    if (!this.get('multiple') && this.get('file')) {
      return this.get('fileIcon');
    }
    return 'icon_upload_outlined';
  }
  get subLabelText() {
    if (this.get('text')) {
      return this.get('text');
    } else if (this.get('showDefaultText')) {
      let maxSize = this.strings.formatFileSize(this.get('maxSize'));
      let fallback = `We accept PDF, JPG, PNG & GIF files, up to ${maxSize}`;
      return this.strings.t('labels.upload_max_size', {
        params: {
          maxSize
        },
        fallback
      });
    } else {
      return null;
    }
  }
  get hasMultipleError() {
    return this.multiple && this.hasErrors;
  }
  init() {
    super.init(...arguments);
    this.set('readFileError', null);

    /**
      The extension supported string
      @argument extensions
      @type String?
      @default 'pdf,jpg,jpeg,png,gif'
    */
    this.extensions = this.extensions || DEFAULT_EXTENSIONS;

    /**
      The label text displayed in UI
      @argument label
      @type String?
      @default null
    */
    this.label = this.label || null;

    /**
      The file to upload size limit
      @argument maxSize
      @type Integer?
      @default 30 * 1e6
    */
    this.maxSize = this.maxSize || DEFAULT_MAX_SIZE;

    /**
      The minimum size limit for the file to upload 
      @argument minSize
      @type Integer?
      @default 0
    */
    this.minSize = this.minSize || DEFAULT_MIN_SIZE;

    /**
      The max limit of files to upload
      @argument filesLimit
      @type Integer?
      @default 0
    */
    this.filesLimit = this.filesLimit || 0;

    /**
     The label displayed on upload asuccess
     @argument successLabel
     @type String?
     */
    this.successLabel = this.successLabel || '';

    /**
      The text displayed in dropzone
      @argument text
      @type String?
      @default null
    */
    this.text = this.text || null;
    this._dropping = this._dropping || false;

    /**
      Enable the label big sixe display
      @argument bigLabel
      @type Boolean?
      @default false
    */
    this.bigLabel = this.bigLabel || false;

    /**
      Enable the 'colors' class
      @argument colors
      @type Boolean?
      @default true
    */
    this.colors = this.colors || true;

    /**
      Define if the dropzone is enabled or not
      @argument disabled
      @type Boolean?
      @default false
    */
    this.disabled = this.disabled || false;

    /**
      Define if a file must be uploaded or not. it shows a required chips on it
      @argument required
      @type Boolean?
      @default false
    */
    this.required = this.required || false;

    /**
      The Define is the dropzone default text is shown or not
      @argument showDefaultText
      @type Boolean?
      @default false
    */
    this.showDefaultText = this.showDefaultText || false;

    /**
      Errors array or string to display
      @argument errors
      @type Array? || String?
      @default null
    */
    this.errors = this.errors || null;

    /**
      Uploaded local files
      @argument localFiles
      @type Array?
      @default null
    */
    this.localFiles = this.localFiles || null;

    /**
      Uploaded errored local files
      @argument readFileError
      @type Array?
      @default null
    */
    this.readFileError = this.readFileError || null;

    /**
      Method triggered on file upload
      @argument addLocalFile
      @type Function?
      @default (() => {})
    */
    this.addLocalFile = this.addLocalFile || (() => {});

    /**
      Method triggered on file delete
      @argument deleteLocalFile
      @type Function?
      @default (() => {})
    */
    this.deleteLocalFile = this.deletelLocalFile || (() => {});
  }
  didUpdateAttrs() {
    super.didUpdateAttrs(...arguments);
    this.set('readFileError', null);
  }
  handleDelete(file) {
    this.get('deleteLocalFile')(file);
  }
  handleAddFile(file) {
    this.get('addLocalFile')(file);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "strings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "required", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "single", [_dec4, _dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "colors", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "bigLabel", [_dec7], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "hasErrors", [_dec8, _dec9, _dec10], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "hasFile", [_dec11, _dec12], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "hasStatus", [_dec13, _dec14], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "file", [_dec15], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "isUploading", [_dec16], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "isUploaded", [_dec17], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "progress", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "progress"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showTrashIcon", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "showTrashIcon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "errorMessage", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "errorMessage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dropzoneLabel", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "dropzoneLabel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "statusIcon", [_dec22], Object.getOwnPropertyDescriptor(_class2.prototype, "statusIcon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fileIcon", [_dec23], Object.getOwnPropertyDescriptor(_class2.prototype, "fileIcon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fileType", [_dec24], Object.getOwnPropertyDescriptor(_class2.prototype, "fileType"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dropzoneIcon", [_dec25], Object.getOwnPropertyDescriptor(_class2.prototype, "dropzoneIcon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "subLabelText", [_dec26], Object.getOwnPropertyDescriptor(_class2.prototype, "subLabelText"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDelete", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDelete"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleAddFile", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAddFile"), _class2.prototype)), _class2)) || _class);
export { XDropzoneMultipart as default };