var _class, _descriptor, _descriptor2, _descriptor3;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { COMPACT_DATE_FIELD_FORMATS, COMPACT_DATE_SEPARATORS, formatDateFormField } from '../../utils/format-date-form-field';
const DELETE_BACKWARD_INPUT_TYPE = 'deleteContentBackward';
const DATE_FORMAT_REGEXP = /(\d{0,2})(\d{0,2})(\d{0,4})/;
const DATE_MAX_LENGTH = 8;
const DEFAULT_LOCALE = 'en';
let DateField = (_class = class DateField extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "intl", _descriptor, this);
    _initializerDefineProperty(this, "_date", _descriptor2, this);
    _initializerDefineProperty(this, "canShowValidationError", _descriptor3, this);
    dayjs.extend(customParseFormat);
  }
  get locale() {
    return this.intl.primaryLocale;
  }
  get dateFormat() {
    return COMPACT_DATE_FIELD_FORMATS[this.locale] ?? COMPACT_DATE_FIELD_FORMATS[DEFAULT_LOCALE];
  }
  get dateSeparator() {
    return COMPACT_DATE_SEPARATORS[this.locale] ?? COMPACT_DATE_SEPARATORS[DEFAULT_LOCALE];
  }
  get date() {
    let {
      value,
      shouldClearValue
    } = this.args;
    if (shouldClearValue) {
      return null;
    }
    return formatDateFormField(value, this.locale) || this._date;
  }
  set date(val) {
    this._date = val;
  }
  get translations() {
    return {
      placeholder: this.intl.t('ui-kit.form-fields.date-field.placeholder'),
      errors: {
        invalidDate: this.intl.t('ui-kit.form-fields.date-field.error.invalid')
      },
      ...this.args.translations
    };
  }
  get hasMessage() {
    let {
      message
    } = this.args;
    return message?.length > 0;
  }
  get hasError() {
    let {
      message,
      messageLevel,
      ignoreInputError
    } = this.args;
    let hasFormError = messageLevel === 'error' && message?.length > 0;
    let hasInputError = !ignoreInputError && Boolean(this.invalidDateMsg);
    return hasInputError || hasFormError;
  }
  get messageLevel() {
    return this.hasError ? 'error' : this.args.messageLevel;
  }
  get isMessageDisplayed() {
    let {
      hideMessage,
      message
    } = this.args;
    let hasMessage = message || this.hasMessage || this.hasError;
    return !hideMessage && hasMessage && this.canShowValidationError;
  }
  get messageToDisplay() {
    if (this.isMessageDisplayed) {
      return this.invalidDateMsg || this.args.message;
    }
  }
  get invalidDateMsg() {
    let {
      date,
      dateFormat,
      translations
    } = this;
    if (!date?.length) {
      return;
    }
    let parsedDate = date?.length === dateFormat.length ? dayjs(date, dateFormat, true).toDate() : NaN;
    if (isNaN(parsedDate)) {
      return translations.errors.invalidDate;
    }
  }
  handleInput(event) {
    let {
      target: {
        value
      },
      inputType
    } = event;
    if (!value) {
      this._validateAndUpdate(null);
      return;
    }
    let cleanedValue = value.replace(/\D/g, '').substring(0, DATE_MAX_LENGTH);
    let formattedValue = this._getFormattedValue(cleanedValue);
    if (inputType === DELETE_BACKWARD_INPUT_TYPE && formattedValue !== value && formattedValue.endsWith(this.dateSeparator)) {
      formattedValue = value.slice(0, -1);
    }
    this._setCaretPosition(event.target, value, formattedValue);
    this._validateAndUpdate(formattedValue.slice(0, this.dateFormat.length));
    this.canShowValidationError = formattedValue?.length === this.dateFormat.length;
  }
  handleFocusout(event) {
    this.canShowValidationError = true;

    // readonly inputs are focusable, so their value can be updated just by focusin and focusout the input
    // but they shouldn't
    if (!event.target.hasAttribute('readonly')) {
      let {
        value
      } = event.target;
      this._validateAndUpdate(value);
    }
  }
  _validateAndUpdate(value) {
    this.date = value;
    if (!value) {
      // in case of empty value, call update with null to erase the value from ember data
      this.args.onUpdate(null);
      return;
    }
    let date = value?.length === this.dateFormat.length ? dayjs(value, this.dateFormat, true).toDate() : NaN;
    if (isNaN(date)) {
      // invalid date:
      // value = null; hasInvalidDate = true
      this.args.onUpdate(null, true);
    } else {
      let splitDate = value.split(this.dateSeparator);
      let stringDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
      this.args.onUpdate(stringDate);
    }
  }
  _getFormattedValue(rawValue) {
    let {
      dateSeparator
    } = this;
    return rawValue?.replace(DATE_FORMAT_REGEXP, function () {
      let capturedGroups = [...arguments].slice(1, arguments.length - 2);
      // we don't simply do:
      // return [...capturedGroups].filter(o => o).join(separator); where separator: '/' | '-' | '.';
      // because this needs the day and the month to be typed to insert the separator in between
      // but we need the separator to be added right after the day is typed and before the month is started to be typed
      return [...capturedGroups].filter(o => o).map((inputGroup, idx) => {
        if (idx < 2) {
          return inputGroup.length === 2 ? `${inputGroup}${dateSeparator}` : inputGroup;
        }
        return inputGroup;
      }).join('');
    });
  }
  _setCaretPosition(element, oldValue, newValue) {
    let {
      selectionEnd
    } = element;
    let prevPos = selectionEnd;
    let newPos = prevPos;
    if (oldValue.length === prevPos) {
      newPos = newValue.length;
    }

    // to avoid caret jumping when editing value not at the end
    element.value = newValue;

    // set caret position
    element.setSelectionRange(newPos, newPos);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_date", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return '';
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canShowValidationError", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _applyDecoratedDescriptor(_class.prototype, "handleInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFocusout", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocusout"), _class.prototype)), _class);
export { DateField as default };