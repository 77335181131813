function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

import flatten from 'ember-intl/-private/utils/flatten';
import parse from 'ember-intl/-private/utils/parse';

/**
 * @remarks
 * `tests/unit/helpers/t-test.ts` has a test that asserts that numbers are
 * acceptable as translations and converted to strings.
 */

class Translation {
  get localeName() {
    return this._localeName;
  }
  constructor(localeName) {
    _defineProperty(this, "translations", new Map());
    _defineProperty(this, "asts", new Map());
    _defineProperty(this, "_localeName", void 0);
    this._localeName = localeName;
  }
  addTranslations(translations) {
    const flatTranslations = flatten(translations);
    for (const key in flatTranslations) {
      let translation = flatTranslations[key];

      // If it's not a string, coerce it to one.
      if (typeof translation !== 'string') {
        translation = `${translation}`;
      }
      this.translations.set(key, translation);
      this.asts.set(key, parse(translation));
    }
  }
  find(key) {
    if (this.has(key)) {
      return {
        ast: this.asts.get(key),
        original: this.translations.get(key)
      };
    }
  }
  has(key) {
    return this.translations.has(key);
  }
}
export default Translation;