import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
export default class BlockPlaceholder extends Component {
  get style() {
    let {
      borderRadius,
      width = '32px',
      height = '32px'
    } = this.args;
    return htmlSafe([`width: ${width}`, `min-width: ${width}`, `height: ${height}`, `min-height: ${height}`, borderRadius !== undefined ? `border-radius: ${borderRadius}` : null].filter(val => val).join(';'));
  }
}