import Component from '@glimmer/component';

import { appURL } from 'qonto-switching-bank-js/constants/hosts';

export default class BackToQontoButtonComponent extends Component<{ isTertiary?: boolean }> {
  appLink = appURL;

  get buttonStyle(): string {
    return this.args.isTertiary ? 'btn--tertiary' : 'btn--primary btn--stretch';
  }
}
