import { helper } from '@ember/component/helper';

export function formatIban(iban: string) {
  let parts = iban.replace(/\s/g, '').match(/.{2,4}/g);

  if (!parts) {
    return iban;
  }
  return parts.join(' ');
}

export default helper(([iban]: string[]) => {
  return formatIban(iban);
});
