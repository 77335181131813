export default [["de", {
  "application": {
    "back_to_app": "Zurück zur Qonto App",
    "go_back": "Zurück",
    "security_disclaimer": "Dies ist ein sicherer Service. Alle Daten sind ausschließlich der Verwendung durch Qonto vorbehalten und können auf Anfrage jederzeit gelöscht werden.",
    "security_disclaimer_link": "Mehr erfahren",
    "title": "Kontowechsel"
  },
  "appointment": {
    "back_cta": "Zurück",
    "calendar_subtitle": "Vereinbaren Sie einen Termin mit Ihrem/r Qonto Berater/in, um den Kontowechsel vorzubereiten.",
    "calendar_title": "Treffen mit Ihrem/r Qonto Berater/in"
  },
  "bank_selection": {
    "consent_modal": {
      "checkbox_text": "Ich bin mit der Verwendung meiner persönlichen Daten durch Qonto einverstanden.",
      "primary_CTA": "Bestätigen",
      "secondary_CTA": "Abbrechen",
      "subtitle": "Aus rechtlichen Gründen müssen wir Ihre Zustimmung zu unseren Datenverwendungsrichtlinien einholen, bevor Sie Ihre Bank über den sicheren Dienst Bridge by Bankin' mit Qonto verbinden.",
      "title": "Geben Sie uns bitte Ihre Zustimmung"
    },
    "select_child_bank": {
      "back_CTA": "Zurück",
      "individual": "Privatkonto",
      "pro": "Geschäftskonto",
      "subtitle": "Zu der {bank_name} gehören mehrere Einheiten, achten Sie also darauf, dass Sie die richtige auswählen. Wenn Sie sich nicht sicher nicht, empfehlen wir Ihnen, Ihre Bank zu kontaktieren.",
      "title": "Wählen Sie Ihre Bankeinheit aus"
    },
    "select_parent_bank": {
      "cannot_find": "Ihre Bank ist nicht in der Liste aufgeführt?",
      "search_empty": {
        "CTA": "Kontaktieren Sie uns",
        "subtitle": "Geben Sie uns bitte die Bank an, die Ihnen fehlt, indem Sie uns über den Chat kontaktieren, der auf diesem Bildschirm angezeigt wird. Unser Team wird sein Bestes tun, um Sie bei Ihrem/Ihren Kontowechsel/n zu unterstützen.",
        "title": "Diese Bank ist (noch) nicht aufgeführt"
      },
      "search_placeholder": "Nach einer Bank suchen...",
      "title": "Wählen Sie Ihre Bank aus"
    }
  },
  "bankswitch": {
    "backend_error": {
      "toaster": "Es ist ein Fehler aufgetreten. Können Sie die Seite aktualisieren?"
    }
  },
  "btn": {
    "back": "Zurück"
  },
  "choose_organization": {
    "continue_button": "Weiter",
    "error_message": "Bitte wählen Sie ein Unternehmen, um fortzufahren.",
    "subtitle": "Wählen Sie ein Unternehmen, auf das Sie Ihr Konto übertragen möchten.",
    "title": "Wählen Sie ein Unternehmen aus"
  },
  "dashboard": {
    "CTA": {
      "new_action": "Eine Aktion erstellen"
    },
    "account_details": {
      "bic": "BIC",
      "disclaimer": "Bislang können Sie nur von einer Bank zu uns wechseln, eine Multi-Bank-Migration wird bald zur Verfügung stehen.",
      "iban": "IBAN",
      "no_iban": "Sie finden Ihre IBAN im Kundenbereich Ihrer Bank.",
      "no_iban_link": "Sehen Sie Ihre IBAN auf Qonto ein.",
      "notifications": {
        "bic_copy": "Ihr BIC wurde kopiert!",
        "iban_copy": "Ihre IBAN wurde kopiert!"
      }
    },
    "action": {
      "important": {
        "tag": "Wichtig"
      },
      "menu": {
        "CTA_delete": "Löschen",
        "CTA_edit": "Ändern",
        "CTA_flag_as_important": "Als wichtig markieren",
        "CTA_ignore": "Ignorieren",
        "CTA_mark_as_done": "Als bearbeitet markieren",
        "CTA_unflag_as_important": "Als unwichtig markieren",
        "CTA_unignore": "Wiederherstellen",
        "CTA_unmark_as_done": "Als unbearbeitet markieren"
      }
    },
    "card": {
      "action": {
        "ignored": "Ignorieren",
        "reactive": "Reaktivieren"
      },
      "category": {
        "cards": "Karten",
        "mandates": "Mandate",
        "others": "Sonstige",
        "transfers": "Überweisungen"
      },
      "last_operation": "Letzte:",
      "last_operation_detected": "Letzte erkannte Operation:",
      "operations": "{count, plural,=1 {Operation:} other {Operationen:}}"
    },
    "create_action": {
      "toaster": "Eine neue Aktion wurde erstellt."
    },
    "create_form": {
      "CTA_cancel": "Abbrechen",
      "CTA_create": "Erstellen",
      "category_field": {
        "placeholder": "Wählen Sie eine Kategorie",
        "title": "Kategorie"
      },
      "category_list": {
        "atm": "Geldautomat",
        "fees": "Gebühren",
        "finance": "Finanzen",
        "hotel_and_lodging": "Hotels & Unterkünfte",
        "insurance": "Versicherungen",
        "legal_and_accounting": "Rechtliches & Buchhaltung",
        "logistics": "Logistiken",
        "marketing": "Marketing",
        "office_rental": "Büromiete",
        "office_supply": "Bürobedarf",
        "online_service": "Online-Dienstleistungen",
        "other": "Sonstige",
        "pending": "Ausstehend",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Löhne & Gehälter",
        "sales": "Vertrieb",
        "tax": "Steuern",
        "transport": "Transporte",
        "utility": "Nebenkosten"
      },
      "note_field": {
        "placeholder": "Notiz hinzufügen",
        "title": "Zusätzliche Informationen"
      },
      "title": {
        "placeholder": "Neue Aufgabe"
      },
      "title_field": {
        "placeholder": "Geben Sie dieser Aufgabe einen Namen",
        "title": "Name"
      },
      "type_field": {
        "placeholder": "Wählen Sie eine Zahlungsart",
        "title": "Zahlungsart"
      }
    },
    "credit": {
      "no_actions": {
        "subtitle": "In diesem Bereich stehen keine Aktionen aus. Zögern Sie nicht nachzusehen, ob im Bereich Lastschriften Aktionen ausstehen.",
        "title": "Kein Stammkunde"
      },
      "sections": {
        "all": {
          "methods": "Alle Arten"
        },
        "cards": "Karten",
        "cards_tooltip": "Registrieren Sie Ihre neue Qonto Card bei diesen Zahlungspartnern.",
        "done": "Bearbeitet",
        "ignored": "Ignoriert",
        "others": "Sonstige",
        "others_tooltip": "Diese Aktionen müssen individuell bearbeitet werden. Bei Fragen hilft Ihnen ein/e Qonto Berater/in gern weiter.",
        "todo": "Zu bearbeiten",
        "transfers": "Überweisungen",
        "transfers_tooltip": "Fügen Sie diese Zahlungsempfänger zu Ihrem Qonto Konto hinzu."
      },
      "subtitle": "Senden Sie Ihren Kunden und Zahlungspartnern Ihre neue Bankverbindung, um erste Zahlungen auf Ihr Qonto Konto zu erhalten.",
      "title": "Stammkunden"
    },
    "debit": {
      "no_actions": {
        "subtitle": "In diesem Bereich stehen keine Aktionen aus. Sie können prüfen, ob im Bereich Gutschriften Aktionen ausstehen.",
        "title": "Kein Stammlieferant"
      },
      "sections": {
        "all": {
          "methods": "Alle Zahlungsarten"
        },
        "cards": "Karten",
        "cards_tooltip": "Registrieren Sie Ihre neue Qonto Card bei diesen Lieferanten.",
        "done": "Bearbeitet",
        "ignored": "Ignoriert",
        "mandates": "Mandate",
        "mandates_tooltip": "Kündigen Sie Ihre aktuellen Mandate und erstellen Sie neue Mandate mit Ihrer Qonto IBAN.",
        "others": "Sonstige",
        "others_tooltip": "Diese Aktionen müssen individuell bearbeitet werden. Bei Fragen hilft Ihnen ein/e Qonto Berater/in gern weiter.",
        "todo": "Zu bearbeiten",
        "transfers": "Überweisungen",
        "transfers_tooltip": "Fügen Sie diese Zahlungsempfänger zu Ihrem Qonto Konto hinzu."
      },
      "subtitle": "Geben Sie Ihre neue Bankverbindung an Ihre Lieferanten weiter, damit Ihre zukünftigen Zahlungen bearbeitet werden können.",
      "title": "Stammlieferanten"
    },
    "delete_action": {
      "modal": {
        "CTA_cancel": "Abbrechen",
        "CTA_delete": "Löschen",
        "body": "Denken Sie daran, dass das Löschen dieser Aktion endgültig ist.",
        "title": "Diese Aktion löschen?"
      },
      "toaster": "Diese Aktion wurde gelöscht."
    },
    "edit_action": {
      "toaster": "Diese Aktion wurde bearbeitet."
    },
    "edit_form": {
      "CTA_cancel": "Abbrechen",
      "CTA_save": "Änderungen speichern",
      "category_field": {
        "placeholder": "Wählen Sie eine Kategorie",
        "title": "Kategorie"
      },
      "category_list": {
        "atm": "Geldautomat",
        "fees": "Gebühren",
        "finance": "Finanzen",
        "hotel_and_lodging": "Hotels & Unterkünfte",
        "insurance": "Versicherungen",
        "legal_and_accounting": "Rechtliches & Buchhaltung",
        "logistics": "Logistiken",
        "marketing": "Marketing",
        "office_rental": "Büromiete",
        "office_supply": "Bürobedarf",
        "online_service": "Online-Dienstleistungen",
        "other": "Sonstige",
        "pending": "Ausstehend",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Löhne & Gehälter",
        "sales": "Vertrieb",
        "tax": "Steuern",
        "transport": "Transporte",
        "utility": "Nebenkosten"
      },
      "note_field": {
        "placeholder": "Notiz hinzufügen",
        "title": "Zusätzliche Informationen"
      },
      "title_field": {
        "placeholder": "Geben Sie dieser Aufgabe einen Namen",
        "title": "Name"
      },
      "type_field": {
        "placeholder": "Wählen Sie eine Zahlungsart",
        "title": "Zahlungsart"
      }
    },
    "operation_history": {
      "back_to_credits_cta": "Zurück zu Kunden",
      "back_to_debits_cta": "Zurück zu Lieferanten",
      "cancel_cta": "Als unbearbeitet markieren",
      "check_cta": "Als bearbeitet markieren",
      "ignore_cta": "Ignorieren",
      "reactive_cta": "Wiederherstellen",
      "subtitle": {
        "credit": "{count, plural,=1 {{count} Transaktion ist mit diesem Kunden verbunden} other {{count} Transaktionen sind mit diesem Kunden verbunden}}",
        "debit": "{count, plural,=1 {{count} Transaktion ist mit diesem Lieferanten verbunden} other {{count} Transaktionen sind mit diesem Lieferanten verbunden}}"
      },
      "table_field": {
        "amount": "Betrag",
        "date": "Datum",
        "label": "Transaktion"
      }
    },
    "overview": {
      "cards": {
        "dropdown": {
          "title": "Allen Konten"
        },
        "last_update": {
          "day": "Zuletzt aktualisiert vor {count} {count, plural, =1 {Tag} other {Tagen}}",
          "month": "Zuletzt aktualisiert vor {count} {count, plural, =1 {Monat} other {Monaten}}",
          "year": "Zuletzt aktualisiert vor {count} {count, plural, =1 {Jahr} other {Jahren}}"
        }
      },
      "faq": {
        "first_article": {
          "description": "Hier erfahren Sie, wie die Bank-Aggregation funktioniert und wie Sie Ihre bisherige Bank mit Qonto synchronisieren können.",
          "link": "https://help.qonto.com/de/articles/5020434",
          "title": "Wie funktioniert der Kontowechselservice?"
        },
        "second_article": {
          "description": "Hier finden Sie die Ratschläge unserer Experten für einen unkomplizierten Kontowechsel.",
          "link": "https://help.qonto.com/de/articles/5145573",
          "title": "Wie kann ich den Fortschritt meines Kontowechsels verfolgen?"
        },
        "title": "Alle wichtigen Informationen zum Kontowechsel"
      },
      "popin": {
        "end_migration_completed": {
          "cta": {
            "cancel": "Abbrechen",
            "end_transfer": "Bestätigen"
          },
          "title": "Bestätigen Sie, dass Ihr Kontowechsel abgeschlossen ist",
          "wording": "Ihr Kontowechsel von der {bank_name} zu Qonto für {company_name} ist bald abgeschlossen. Alle Daten, die sich auf Ihr bisheriges Konto beziehen, werden deshalb gelöscht."
        },
        "end_migration_not_completed": {
          "cta": {
            "cancel": "Abbrechen",
            "end_transfer": "Kontowechsel abschließen"
          },
          "title": "Schließen Sie Ihren Kontowechsel ab",
          "wording": "Sind Sie sicher? Damit brechen Sie Ihren Kontowechsel für {company_name} von der {bank_name} zu Qonto ab und löschen Ihre Übertragungsdaten. Wenn gewünscht, können Sie jederzeit einen neuen Wechsel starten."
        }
      },
      "stepper": {
        "end_migration": "Kontowechsel abschließen",
        "file_link": "Anleitung herunterladen",
        "step1": {
          "progress_bar": {
            "completed": "Abgeschlossen",
            "inprogress": "In Bearbeitung"
          },
          "subtitle1": {
            "description": "Um sicherzustellen, dass Ihre zukünftigen Zahlungen bearbeitet werden.",
            "title": "Lieferanten"
          },
          "subtitle2": {
            "description": "Um Geld auf Ihr Qonto Konto zu erhalten.",
            "title": "Kunden"
          },
          "title": "Geben Sie Ihre neue Bankverbindung an Ihre Lieferanten und Kunden weiter"
        },
        "step2": {
          "subtitle": "In unserem Leitfaden erfahren Sie mehr über die Schritte, die Sie ausführen müssen.",
          "title": "Bereiten Sie die Schließung Ihres bisherigen Kontos vor"
        },
        "step3": {
          "subtitle": "Bestätigen Sie, dass Ihr Kontowechsel abgeschlossen ist, sobald Sie die vorherigen Schritte durchgeführt haben.",
          "title": "Kontowechsel abschließen"
        },
        "step_link": {
          "completed": "Details anzeigen",
          "uncompleted": "{count} {count, plural,=1 {auszuführende Aktion} other {auszuführende Aktionen}}"
        },
        "title": "Fortschritt des Kontowechsels"
      },
      "subtitle": "Hier finden Sie alle wichtigen Informationen zu Ihrem Kontowechsel.",
      "title": "Fortschritt Ihres Kontowechsels"
    },
    "side": {
      "credit": "Kunden",
      "debit": "Lieferanten",
      "overview": "Übersicht",
      "settings": "Einstellungen",
      "settings_items": {
        "add_accounts": "Weiteres Konto hinzufügen",
        "back_to_app": "Zurück zur Qonto App",
        "ends_transfer_cta": "Kontowechsel abschließen",
        "support": "Support",
        "support_link": "https://help.qonto.com/de/",
        "switch_organization": "Ein anderes Unternehmen auswählen"
      }
    }
  },
  "dashboard_generation": {
    "waiting_screen": {
      "description": "Unser Team ist gerade dabei, alle Ihre wiederkehrenden Transaktionen zu analysieren und aufzulisten. Dies kann <b>4 bis 10 Minuten</b> dauern, aber keine Sorge, Sie müssen hier nicht darauf warten. Sie erhalten eine E-Mail, sobald Ihr Dashboard fertig erstellt ist.",
      "disclaimer": {
        "part_1": "Dies ist ein sicherer Service. Alle Daten sind ausschließlich der Verwendung durch Qonto vorbehalten und können auf Anfrage jederzeit gelöscht werden.",
        "part_2": "Mehr erfahren"
      },
      "title": "Ihr Dashboard wird gerade erstellt"
    }
  },
  "dynamic-values": {
    "dates": {
      "current-month": "%Monat%",
      "current-year": "%Jahr%",
      "last-month": "%letztenMonat%",
      "next-month": "%nächstenMonat%"
    }
  },
  "empty-state-discover": {
    "divider": "oder"
  },
  "ends_transfer": {
    "confirmation_modal": {
      "cancel_cta": "Abbrechen",
      "confirm_cta": "Schließen Sie die Übertragung ab",
      "subtitle": "Sind Sie sicher? Damit brechen Sie Ihren Kontowechsel für {company_name} von der {bank_name} zu Qonto ab und löschen Ihre Übertragungsdaten. Wenn gewünscht, können Sie jederzeit einen neuen Wechsel starten.",
      "title": "Beenden"
    }
  },
  "error_1010": {
    "disclaimer": {
      "body": "Ihre Daten sind nicht aktualisiert: Sie müssen den per SMS erhaltenen Bestätigungscode erneut eingeben",
      "link": "genau hier"
    }
  },
  "error_429": {
    "disclaimer": {
      "body_action1": "Ihre Daten sind nicht aktualisiert: Sie müssen den Allgemeinen Geschäftsbedingungen für Ihr {bank_name}-Konto zustimmen.",
      "body_action10": "Ihre Daten sind nicht aktualisiert: Sie müssen die starke Authentifizierung für Ihr {bank_name}-Konto reaktivieren.",
      "body_action11": "Ihre Daten sind nicht aktualisiert: Sie müssen Zugang zu Online-Diensten erhalten, indem Sie sich an Ihre Bank {bank_name} wenden.",
      "body_action12": "Ihre Daten sind nicht aktualisiert: Sie müssen den Kundenservice Ihrer Bank {bank_name} kontaktieren.",
      "body_action13": "Ihre Daten sind nicht aktualisiert: Sie müssen Ihre Zugangsdaten erneuern oder aktivieren, indem Sie sich an Ihre Bank {bank_name} wenden.",
      "body_action14": "Ihre Daten sind nicht aktualisiert: Sie müssen Ihr Passwort für Ihr {bank_name}-Konto ändern.",
      "body_action15": "Ihre Daten sind nicht aktualisiert: Sie müssen ein neues Passwort erstellen, indem Sie sich zum ersten Mal bei Ihrem {bank_name}-Konto anmelden.",
      "body_action2": "Ihre Daten sind nicht aktualisiert: Sie müssen einen Vertrag für Ihr {bank_name}-Konto unterzeichnen.",
      "body_action3": "Ihre Daten sind nicht aktualisiert: Sie müssen Ihr {bank_name}-Konto aktivieren oder Ihre Anmeldedaten eingeben.",
      "body_action4": "Ihre Daten sind nicht aktualisiert: Sie müssen Ihr {bank_name}-Konto aktivieren.",
      "body_action5": "Ihre Daten sind nicht aktualisiert: Sie müssen die Telefonnummer eingeben oder bestätigen, die mit Ihrem {bank_name}-Konto verknüpft ist.",
      "body_action6": "Ihre Daten sind nicht aktualisiert: Sie müssen eine Information oder eine Nachricht für Ihr {bank_name}-Konto bestätigen.",
      "body_action7": "Ihre Daten sind nicht aktualisiert: Sie müssen einige persönliche Daten für Ihr {bank_name}-Konto bestätigen oder vervollständigen.",
      "body_action8": "Ihre Daten sind nicht aktualisiert: Sie müssen Ihre Angaben für Ihr {bank_name}-Konto bestätigen und/oder ändern.",
      "body_action9": "Ihre Daten sind nicht aktualisiert: Sie müssen ein {bank_name}-Konto erstellen."
    }
  },
  "error_430": {
    "disclaimer": {
      "body": "Ihre Daten sind nicht aktualisiert: Sie müssen Ihr Passwort für Ihr {bank_name}-Konto ändern."
    }
  },
  "error_management_screen": {
    "generic_fallback_error": {
      "description": "Aufgrund eines Fehlers können wir Ihr Dashboard nicht automatisch erstellen. Vereinbaren Sie einen Anruf mit einem Qonto Berater, damit wir Ihnen helfen und Sie begleiten können.",
      "primary_cta": "Einen Anruf vereinbaren",
      "title": "Ihr Dashboard konnte nicht erstellt werden"
    }
  },
  "information_appointment": {
    "calendar_subtitle": "Buchen Sie einen Beratungstermin zur Vorbereitung Ihres Kontowechsels.",
    "calendar_title": "Treffen mit Ihrem/r Qonto Berater/in"
  },
  "labels": {
    "pagination_summary": "von"
  },
  "logout": {
    "btn": "Zurück zum Anmeldebildschirm",
    "modal": {
      "accept_btn": "Ja, behalte mich angemeldet!",
      "close_btn": "Melde mich ab",
      "content": "In 5 Minuten erreicht Ihre Sitzung das Limit von 30 Minuten und läuft ab. Um die Sicherheit Ihres Kontos zu gewährleisten, werden Sie automatisch ausgeloggt. Sie können Ihre Sitzung verlängern.",
      "title": "Sind Sie noch da?"
    },
    "subtitle": "Aus Sicherheitsgründen endet Ihre Qonto-Sitzung nach 30 Minuten. Sie wurden automatisch abgemeldet.",
    "title": "Sie wurden sicher abgemeldet."
  },
  "maintenance": {
    "cta": "Zurück zur Qonto App",
    "subtitle": "Anscheinend ist ein Problem aufgetreten. Keine Sorge, unser Team ist bereits dabei, es zu beheben. Falls Sie Hilfe benötigen, wenden Sie sich in der Zwischenzeit gern an Ihre/n Qonto Berater/in.",
    "title": "Ein Fehler ist aufgetreten"
  },
  "migration_status": {
    "appointment_successfully_made_redirection_toast": "Ihr Termin wurde bestätigt!",
    "end_migration": "Kontowechsel abschließen",
    "step_1_subtitle": "Um einen Plan für den Kontowechsel zu erstellen, der Ihren Bedürfnissen entspricht.",
    "step_1_title": "Ihre Transaktionen werden überprüft",
    "step_2_subtitle": "Um das individuelle Vorgehen für Ihren Kontowechsel zu besprechen.",
    "step_2_title": "Es ist Zeit für ein erstes Gespräch mit Ihrem/r Qonto Berater/in",
    "step_3_subtitle": "Sie erhalten bei jedem Schritt Aktualisierungen und Erinnerungen.",
    "step_3_title": "Ihr Kontowechsel ist in guten Händen",
    "step_4_subtitle": "Sie sind jetzt startklar! Ab sofort können Sie Ihr Geschäftskonto direkt in der Qonto App verwalten.",
    "step_4_title": "Der Kontowechsel wurde abgeschlossen!",
    "subtitle": "Der Kontowechsel hat begonnen. Hier können Sie den Fortschritt verfolgen.",
    "title": "Verfolgen Sie Ihren Kontowechsel"
  },
  "migration_succeeded": {
    "back_to_qonto": "Zu meinem Qonto Konto gehen",
    "end_migration": {
      "file_link": "Anleitung herunterladen",
      "title1": "Beenden Sie die Schließung",
      "title2": "Ihres bisherigen Kontos.",
      "wording": "Haben Sie Ihr bisheriges Konto bereits aufgelöst? Wenn nicht, erfahren Sie in unserem Leitfaden mehr über die Schritte, die Sie ausführen müssen."
    },
    "subtitle": "Sie können jetzt die Vorteile aller exklusiven Funktionen von Qonto nutzen. Bei Fragen helfen wir Ihnen gerne weiter.",
    "switch_another_bank": "Ein weiteres Konto auf Qonto übertragen",
    "title": "Herzlichen Glückwunsch, Ihr Kontowechsel ist abgeschlossen!"
  },
  "no_mobile": {
    "back_to_qonto": "Zurück zur Qonto App",
    "subtitle": "Bitte melden Sie sich auf Ihrem Computer an, um auf diese Funktion zuzugreifen.",
    "title": "Diese Funktion ist bislang nur auf dem Desktop verfügbar!"
  },
  "no_rights": {
    "subtitle": "Nur der Admin kann einen Kontowechsel durchführen.",
    "title": "Leider können Sie auf diesen Service nicht zugreifen"
  },
  "not_found": {
    "cta": "Zurück zur Qonto App",
    "subtitle": "Wir konnten die gesuchte Seite leider nicht finden. Dies liegt entweder daran, dass ein Fehler in der in der URL vorliegt oder die gesuchte Seite verschoben oder gelöscht wurde.",
    "title": "Diese Seite kann nicht gefunden werden"
  },
  "onboarding_funnel": {
    "OTP_modal": {
      "code": "Code",
      "error_text": "Ungültiger Code",
      "link": "Keinen Code erhalten? Einen Neuen senden.",
      "primary_CTA": "Abbrechen",
      "secondary_CTA": "Senden",
      "text": "Bitte geben Sie den temporären Bestätigungscode ein, der Ihnen grade per SMS zugeschickt wurde",
      "title": "Geben Sie Ihren Verifizierungscode ein"
    },
    "app_modal": {
      "link": "Brauchen Sie Hilfe?",
      "text": "Um fortzufahren, müssen Sie die Verbindung von der mobilen App Ihrer Bank aus bestätigen.",
      "title": "Nehmen Sie Ihr Smartphone zur Hand"
    },
    "consent": {
      "CTA_1": "Abbrechen",
      "CTA_2": "Bestätigen",
      "checkbox": {
        "first": "Wenn ich hier klicke, akzeptiere ich die Allgemeinen Geschäftsbedingungen für diesen Dienst und bestätige, dass ich die ",
        "link": "Datenschutzbestimmungen von Qonto",
        "second": " zur Kenntnis genommen habe."
      },
      "text": "Qonto bietet Ihnen die Möglichkeit, alle Ihre Bank- und Zahlungskonten, die Sie bei Drittinstituten eröffnet haben, mit Ihrem Qonto-Konto zu verbinden.<br>Qonto ist von der ACPR zugelassen, um Ihnen diesen Service der Kontenaggregation und -information anzubieten, und stützt sich dabei auf die Infrastrukturen von Bankin'.<br>Es kann jedoch nicht garantiert werden, dass wir auf alle Ihre Konten zugreifen können.<br><br>Ihre Bankdaten und Ihre persönlichen Daten werden gemäß den europäischen Vorschriften (Richtlinie über Zahlungsdienste und Datenschutz-Grundverordnung) geschützt.",
      "title": "Qonto respektiert den Schutz Ihrer Daten"
    },
    "credentials": {
      "4digitcode_title": "4-stelligen Code",
      "CTA": "Sich anmelden",
      "accesscode_title": "Zugangscode",
      "accountnumber_placeholder": "Geben Sie Ihre Kontonummer ein",
      "accountnumber_title": "Kontonummer",
      "answer_placeholder": "Geben Sie Ihre Antwort ein",
      "answer_title": "Antwort auf die Sicherheitsfrage",
      "birthdate_placeholder": "TT/MM/JJJJ",
      "birthdate_title": "Geburtsdatum",
      "checkbox": "Ich versichere hiermit eidesstattlich, dass ich über die erforderlichen Rechte und Berechtigungen verfüge, um auf die Kontoinformationen zuzugreifen.",
      "clientnumber_placeholder": "Geben Sie Ihre Kundennummer ein",
      "clientnumber_title": "Kundennummer",
      "code_placeholder": "Geben Sie Ihren Benutzercode ein",
      "code_title": "Benutzercode",
      "companynumber_placeholder": "Geben Sie ID-Nummer Ihres Unternehmens ein",
      "companynumber_title": "ID-Nummer des Unternehmens",
      "confidentialcode_title": "Vertraulicher Code",
      "email_placeholder": "jan.mueller@meinunternehmen.com",
      "email_title": "E-Mail-Adresse",
      "id_placeholder": "Geben Sie Ihre Kennnummer ein",
      "id_title": "Kennnummer",
      "password_placeholder": "Passwort eingeben",
      "password_title": "Passwort",
      "phonenumber_placeholder": "+49 12 345678901",
      "phonenumber_title": "Telefonnummer mit Landesvorwahl (+49...)",
      "servercode_title": "Servercode",
      "subscriptionnumber_placeholder": "Geben Sie Ihre Abonnentennummer ein",
      "subscriptionnumber_title": "Abonnentennummer",
      "text": "Bitte geben Sie dieselben Anmeldedaten ein, die Sie üblicherweise auf der Website der {bank_name} benutzen.<br/> Wir versichern Ihnen, dass Qonto diese Informationen nicht speichert.",
      "title": "Verbinden Sie sich mit der {bank_name}",
      "usernumber_placeholder": "Geben Sie Ihre Kennnummer ein",
      "usernumber_title": "Kennnummer"
    },
    "error_1005": {
      "primary_CTA": "Einen Anruf vereinbaren",
      "secondary_CTA": "Zurück zur Qonto App",
      "text": "Aufgrund eines Fehlers können wir uns nicht mit Ihrer Bank verbinden. Keine Sorge, ein Qonto-Berater hilft Ihnen dabei, das Problem zu lösen und begleitet Sie während des gesamten Kontowechsels.",
      "title": "Die Verbindung zur {bank_name} ist fehlgeschlagen"
    },
    "error_402": {
      "link": "Bankeinheit ausgewählt haben?",
      "text": "Die Anmeldung ist offenbar fehlgeschlagen. Können Sie Ihre Anmeldedaten überprüfen und sicherstellen, dass Sie die richtige "
    },
    "success": {
      "CTA": "Übertragen Sie Ihre Konten",
      "text": "Sie haben es fast geschafft und können jetzt die Konten auswählen, die Sie übertragen möchten.",
      "title": "Ihre Daten wurden erfolgreich abgerufen"
    },
    "synchronization": {
      "subtitle": "Unser Team ruft Ihre Kontoinformationen bei der {bank_name} ab. Dies kann einige Minuten dauern, bitte bleiben Sie auf dieser Seite.",
      "text": "Abruf Ihrer Kontoinformationen bei der {bank_name}…",
      "title": "Einen Moment bitte, während wir die Informationen von Ihrer Bank abrufen"
    }
  },
  "optional_cs": {
    "select_method": {
      "main_page": {
        "algorithm_bloc": {
          "argument1": "Lassen Sie uns das Dashboard für Ihren Kontowechsel erstellen",
          "argument2": "Erhalten Sie eine Benachrichtigung, sobald Ihr Dashboard fertig ist",
          "argument3": "Nutzen Sie Ihr Dashboard und teilen Sie Ihre neuen Bankdaten mit",
          "description": "Zeitersparnis und mehr Autonomie",
          "primary_cta": "Auswählen",
          "tag_recommended": "Empfohlen",
          "title": {
            "1": "Eine vollautomatische",
            "2": "Dashboards"
          }
        },
        "customer_service_bloc": {
          "argument1": "Planen Sie einen Anruf zu Ihrem Wunschtermin",
          "argument2": "Erstellen Sie mit Ihrem Berater Ihr Dashboard für den Kontowechsel",
          "argument3": "Nutzen Sie Ihr Dashboard und teilen Sie Ihre neuen Bankdaten mit",
          "description": "Bei jedem Schritt unterstützt werden",
          "primary_cta": "Auswählen",
          "title": {
            "1": "Ein Gespräch mit Ihrem",
            "2": "Qonto Berater"
          }
        },
        "title": "Wählen Sie die Art der Unterstützung, die Sie benötigen"
      }
    }
  },
  "pdf-preview": {
    "items-links": "{number, plural, =0 {} one {{language, select, fr {Lien :} de {Link:} es {Enlace:} it {Link:} other {Link:}}} other {{language, select, fr {Liens :} de {Links:} es {Enlaces:} it {Link:} other {Links:}}}}",
    "unit": {
      "ampere": "{number, plural, =0 {} one {{language, select, fr {ampère} de {Ampere} es {amperio} it {ampere} other {ampere}}} other {{language, select, fr {ampères} de {Ampere} es {amperios} it {ampere} other {amperes}}}}",
      "centimeter": "{number, plural, =0 {} one {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}} other {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}}}",
      "cubic_meter": "{number, plural, =0 {} one {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}} other {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}}}",
      "cubic_meter_per_hour": "{number, plural, =0 {} one {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}} other {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}}}",
      "day": "{number, plural, =0 {} one {{language, select, fr {jour} de {Tag} es {día} it {giornata} other {day}}} other {{language, select, fr {jours} de {Tage} es {días} it {giornate}  other {days}}}}",
      "gigajoule": "{number, plural, =0 {} one {{language, select, fr {gigajoule} de {Gigajoule} es {gigajulio} it {gigajoule} other {gigajoule}}} other {{language, select, fr {gigajoules} de {Gigajoule} es {gigajulios} it {gigajoule} other {gigajoules}}}}",
      "gigawatt": "{number, plural, =0 {} one {{language, select, fr {gigawatt} de {Gigawatt} es {gigavatio} it {gigawatt} other {gigawatt}}} other {{language, select, fr {gigawatts} de {Gigawatt} es {gigavatios} it {gigawatt} other {gigawatts}}}}",
      "gigawatt_hour": "{number, plural, =0 {} one {{language, select, fr {gigawattheure} de {Gigawattstunde} es {gigavatio-hora} it {gigawattora} other {gigawatt hour}}} other {{language, select, fr {gigawattheures} de {Gigawattstunden} es {gigavatios-hora} it {gigawattora} other {gigawatt hours}}}}",
      "gram": "{number, plural, =0 {} one {{language, select, fr {g} de {g} es {g} it {g} other {g}}} other {{language, select, fr {g} de {g} es {g} it {g} other {g}}}}",
      "half_year": "{number, plural, =0 {} one {{language, select, fr {semestre} de {halbes Jahr} es {semestre} it {semestre} other {half-year}}} other {{language, select, fr {semestres} de {halbe Jahre} es {semestres} it {semestri} other {half-years}}}}",
      "hour": "{number, plural, =0 {} one {{language, select, fr {heure} de {Stunde} es {hora} it {ora} other {hour}}} other {{language, select, fr {heures} de {Stunden} es {horas} it {ore} other {hours}}}}",
      "joule": "{number, plural, =0 {} one {{language, select, fr {joules} de {Joule} es {julio} it {joule} other {joule}}} other {{language, select, fr {joules} de {Joule} es {julios} it {joule} other {joules}}}}",
      "kilogram": "{number, plural, =0 {} one {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}} other {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}}}",
      "kilojoule": "{number, plural, =0 {} one {{language, select, fr {kilojoule} de {Kilojoule} es {kilojulio} it {kilojoule} other {kilojoule}}} other {{language, select, fr {kilojoules} de {Kilojoule} es {kilojulios} it {kilojoule} other {kilojoules}}}}",
      "kilovar": "{number, plural, =0 {} one {{language, select, fr {kilovar} de {Kilovar} es {kilovar} it {kilovar} other {kilovar}}} other {{language, select, fr {kilovars} de {Kilovar} es {kilovares} it {kilovar} other {kilovars}}}}",
      "kilovolt_ampere_reactive_hour": "{number, plural, =0 {} one {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}} other {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}}}",
      "kilowatt": "{number, plural, =0 {} one {{language, select, fr {kilowatt} de {Kilowatt} es {kilovatio} it {chilowatt} other {kilowatt}}} other {{language, select, fr {kilowatts} de {Kilowatt} es {kilovatios} it {chilowatt} other {kilowatts}}}}",
      "kilowatt_hour": "{number, plural, =0 {} one {{language, select, fr {kilowattheure} de {Kilowattstunde} es {kilovatio-hora} it {chilowattora} other {kilowatt hour}}} other {{language, select, fr {kilowattheures} de {Kilowattstunden} es {kilovatios-hora} it {chilowattora} other {kilowatt hours}}}}",
      "liter": "{number, plural, =0 {} one {{language, select, fr {L} de {l} es {l} it {L} other {L}}} other {{language, select, fr {to complete} de {l} es {l} it {L} other {L}}}}",
      "megajoule": "{number, plural, =0 {} one {{language, select, fr {mégajoule} de {Megajoule} es {megajulio} it {megajoule} other {megajoule}}} other {{language, select, fr {mégajoules} de {Megajoule} es {megajulios} it {megajoule} other {megajoules}}}}",
      "megawatt": "{number, plural, =0 {} one {{language, select, fr {mégawatt} de {Megawatt} es {megavatio} it {megawatt} other {megawatt}}} other {{language, select, fr {mégawatts} de {Megawatt} es {megavatios} it {megawatt} other {megawatts}}}}",
      "megawatt_hour": "{number, plural, =0 {} one {{language, select, fr {mégawattheure} de {Megawattstunde} es {megavatio-hora} it {megawattora} other {megawatt hour}}} other {{language, select, fr {mégawattheures} de {Megawattstunden} es {megavatios-hora} it {megawattora} other {megawatt hours}}}}",
      "meter": "{number, plural, =0 {} one {{language, select, fr {m} de {m} es {m} it {m} other {m}}} other {{language, select, fr {m} de {m} es {m} it {m} other {m}}}}",
      "milligram": "{number, plural, =0 {} one {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}} other {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}}}",
      "milliliter": "{number, plural, =0 {} one {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}} other {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}}}",
      "millimeter": "{number, plural, =0 {} one {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}} other {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}}}",
      "minute": "{number, plural, =0 {} one {{language, select, fr {minute} de {Minute} es {minuto} it {minuto} other {minute}}} other {{language, select, fr {minutes} de {Minuten} es {minutos} it {minuti} other {minutes}}}}",
      "month": "{number, plural, =0 {} one {{language, select, fr {mois} de {Monat} es {mes} it {mese} other {month}}} other {{language, select, fr {mois} de {Monate} es {meses} it {mesi} other {months}}}}",
      "number_of_articles": "{number, plural, =0 {} one {{language, select, fr {article} de {Artikel} es {artículo} it {pezzo} other {article}}} other {{language, select, fr {articles} de {Artikel} es {artículos} it {pezzi} other {articles}}}}",
      "pair": "{number, plural, =0 {} one {{language, select, fr {paire} de {Paar} es {par} it {paio} other {pair}}} other {{language, select, fr {paires} de {Paare} es {pares} it {paia} other {pairs}}}}",
      "quarter": "{number, plural, =0 {} one {{language, select, fr {trimestre} de {Viertel} es {trimestre} it {trimestre} other {quarter}}} other {{language, select, fr {trimestres} de {Viertel} es {trimestres} it {trimestri} other {quarters}}}}",
      "second": "{number, plural, =0 {} one {{language, select, fr {seconde} de {Sekunde} es {segundo} it {secondo} other {second}}} other {{language, select, fr {secondes} de {Sekunden} es {segundos} it {secondi} other {seconds}}}}",
      "set": "{number, plural, =0 {} one {{language, select, fr {ensemble} de {Set} es {juego} it {assortimento} other {set}}} other {{language, select, fr {ensembles} de {Sets} es {juegos} it {assortimenti} other {sets}}}}",
      "square_meter": "{number, plural, =0 {} one {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}} other {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}}}",
      "ton": "{number, plural, =0 {} one {{language, select, fr {t} de {t} es {t} it {t} other {T}}} other {{language, select, fr {t} de {t} es {t} it {t} other {T}}}}",
      "two_week": "{number, plural, =0 {} one {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two-week}}} other {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two weeks}}}}",
      "unit": "{number, plural, =0 {} one {{language, select, fr {unité} de {Einheit} es {unidad} it {unità} other {unit}}} other {{language, select, fr {unités} de {Einheiten} es {unidades} it {unità} other {units}}}}",
      "watt_hour": "{number, plural, =0 {} one {{language, select, fr {wattheure} de {Wattstunde} es {vatio-hora} it {wattora} other {watt hour}}} other {{language, select, fr {wattheures} de {Wattstunden} es {vatios-hora} it {wattora} other {watt hours}}}}",
      "week": "{number, plural, =0 {} one {{language, select, fr {semaine} de {Woche} es {semana} it {settimana} other {week}}} other {{language, select, fr {semaines} de {Wochen} es {semanas} it {settimane} other {weeks}}}}",
      "year": "{number, plural, =0 {} one {{language, select, fr {année} de {Jahr} es {año} it {anno} other {year}}} other {{language, select, fr {années} de {Jahre} es {años} it {anni} other {years}}}}"
    }
  },
  "product_tour": {
    "banner": {
      "CTA_primary": "Ihre Bank verbinden",
      "CTA_secondary": "Demo wiederholen"
    },
    "clients": {
      "action1": {
        "title": "Marketing-Agentur",
        "transactions": "Marketing-Agentur Zahlung"
      },
      "action2": {
        "title": "Bäckerei",
        "transactions": "Debitkarte Bäckerei"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "Qonto Airlines Zahlung"
      },
      "action4": {
        "title": "Arbeitsmedizin",
        "transactions": "Kartenzahlung Arbeitsmedizin"
      },
      "action5": {
        "title": "Hilfs- und Betriebsstoffe",
        "transactions": "Hilfs- und Betriebsstoffe Kartenzahlung"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Qonto Technology Zahlung"
      },
      "action7": {
        "title": "Gas & Strom",
        "transactions": "Gas & Strom monatliche Zahlung"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "Qonto Telecom Abonnement"
      }
    },
    "floating_banner": {
      "text": "Sind Sie bereit, Ihr Konto auf Qonto zu übertragen?"
    },
    "overview": {
      "card1": {
        "IBAN": "DE00 1111 2222 3333 4444 5555 000",
        "title": "Vorheriges Konto"
      },
      "card2": {
        "IBAN": "DE00 6666 7777 8888 9999 1111 000",
        "title": "Qonto-Hauptkonto"
      },
      "title": "Übersicht (Demo)"
    },
    "suppliers": {
      "action1": {
        "title": "Marketing-Agentur",
        "transactions": "Marketing-Agentur Zahlung"
      },
      "action2": {
        "title": "Bäckerei",
        "transactions": "Debitkarte Bäckerei"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "Qonto Airlines Zahlung"
      },
      "action4": {
        "title": "Arbeitsmedizin",
        "transactions": "Kartenzahlung Arbeitsmedizin"
      },
      "action5": {
        "title": "Hilfs- und Betriebsstoffe",
        "transactions": "Hilfs- und Betriebsstoffe Kartenzahlung"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Qonto Technology Zahlung"
      },
      "action7": {
        "title": "Gas & Strom",
        "transactions": "Gas & Strom monatliche Zahlung"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "Qonto Telecom Abonnement"
      }
    }
  },
  "redirect": {
    "continue_button": "Weiter",
    "error_message": "Um fortzufahren, müssen Sie mindestens ein Konto auswählen.",
    "subtitle": "Die Transaktionshistorie zu diesem Konto wird zur Überprüfung an Ihre/n Qonto Berater/in gesendet.",
    "title": "Wählen Sie die Konten aus, die Sie übertragen möchten"
  },
  "synchronization": {
    "error_page_1003": {
      "CTA": "Zurück zur Qonto App",
      "body": "Aufgrund eines Fehlers konnten wir uns nicht mit Ihrer Bank verbinden. Aber keine Sorge, unser Team arbeitet bereits daran und Sie erhalten eine E-Mail, sobald der Fehler behoben ist.",
      "title": "Wir konnten keine Verbindung zu Ihrer Bank herstellen"
    },
    "error_page_402": {
      "CTA_1": "Eine andere Bank auswählen",
      "CTA_2": "Benötigen Sie Hilfe?",
      "body": "Es sieht so aus, als hätten Sie Schwierigkeiten, Ihre Bank mit Qonto zu verbinden. Einige Banken haben mehrere Einheiten, daher sollten Sie überprüfen, ob Sie die richtige Bank ausgewählt haben, bevor Sie versuchen, sich erneut mit Qonto zu verbinden.",
      "title": "Sind Sie sicher, dass Sie die richtige Bank ausgewählt haben?"
    },
    "error_page_429": {
      "CTA_1": "Weiter",
      "CTA_2": "Zurück zur Qonto App",
      "body_action1": "Um fortzufahren, müssen Sie den Allgemeinen Geschäftsbedingungen für Ihr {bank_name}-Konto zustimmen. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action10": "Um fortzufahren, müssen Sie die starke Authentifizierung für Ihr {bank_name}-Konto reaktivieren. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action11": "Um fortzufahren, müssen Sie Zugang zu Online-Diensten erhalten, indem Sie sich an Ihre Bank {bank_name} wenden. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action12": "Um fortzufahren, müssen Sie den Kundenservice Ihrer Bank {bank_name} kontaktieren. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action13": "Um fortzufahren, müssen Sie Ihre Zugangsdaten erneuern oder aktivieren, indem Sie sich an Ihre Bank {bank_name} wenden. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action14": "Um fortzufahren, müssen Sie Ihr Passwort für Ihr {bank_name}-Konto ändern. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action15": "Um fortzufahren, müssen Sie ein neues Passwort erstellen, indem Sie sich zum ersten Mal bei Ihrem {bank_name}-Konto anmelden. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action2": "Um fortzufahren, müssen Sie einen Vertrag für Ihr {bank_name}-Konto unterzeichnen. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action3": "Um fortzufahren, müssen Sie Ihr {bank_name}-Konto aktivieren oder Ihre Anmeldedaten eingeben. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action4": "Um fortzufahren, müssen Sie Ihr {bank_name}-Konto aktivieren. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action5": "Um fortzufahren, müssen Sie die Telefonnummer eingeben oder bestätigen, die mit Ihrem {bank_name}-Konto verknüpft ist. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action6": "Um fortzufahren, müssen Sie eine Information oder eine Nachricht für Ihr {bank_name}-Konto bestätigen. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action7": "Um fortzufahren, müssen Sie einige persönliche Daten für Ihr {bank_name}-Konto bestätigen oder vervollständigen. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action8": "Um fortzufahren, müssen Sie Ihre Angaben für Ihr {bank_name}-Konto bestätigen und/oder ändern. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "body_action9": "Um fortzufahren, müssen Sie ein {bank_name}-Konto erstellen. Kehren Sie dann zu Ihrer Qonto-App zurück, um Ihren Kontowechsel fortzusetzen.",
      "checkbox": "Indem ich hier klicke, bestätige ich, dass ich die notwendigen Schritte bei meiner Bank unternommen habe.",
      "title": "Gehen Sie zum Kundenbereich Ihrer Bank",
      "toaster": "Es scheint, dass Sie noch eine Aktion für Ihre Bank ausführen müssen. Können Sie das überprüfen und es noch einmal versuchen?"
    }
  },
  "toast": {
    "default_error_message": "Ein Fehler ist aufgetreten. Könnten Sie es noch einmal versuchen?",
    "not_available_for_country": "Leider ist der Kontowechsel in Ihrem Land (noch) nicht verfügbar. Wir halten Sie auf dem Laufenden!"
  },
  "ui-kit": {
    "avatar-input": {
      "default-image-alt-text": "Standardbild",
      "uploaded-image-alt-text": "Zuletzt hochgeladenes Bild"
    },
    "btn": {
      "aria-label": {
        "clear": "Zurücksetzen",
        "download": "Herunterladen",
        "next-month": "Nächster Monat",
        "preview": "Anzeigen",
        "previous-month": "Vorheriger Monat"
      },
      "cancel": "Abbrechen",
      "close": "Schließen",
      "delete": "Löschen",
      "delete-aria-label": "Löschen"
    },
    "countries": {
      "AC": "Ascension-Insel",
      "AD": "Andorra",
      "AE": "Vereinigte Arabische Emirate",
      "AF": "Afghanistan",
      "AG": "Antigua und Barbuda\t",
      "AI": "Anguilla",
      "AL": "Albanien",
      "AM": "Armenien",
      "AN": "Niederländische Antillen",
      "AO": "Angola",
      "AQ": "Antarktis",
      "AR": "Argentinien",
      "AS": "Amerikanisch-Samoa",
      "AT": "Österreich",
      "AU": "Australien",
      "AW": "Aruba",
      "AX": "Aserbaidschan",
      "AZ": "Aserbaidschan",
      "BA": "Bosnien und Herzegovina\t",
      "BB": "Barbados",
      "BD": "Bangladesch",
      "BE": "Belgien",
      "BF": "Burkina Faso",
      "BG": "Bulgarien",
      "BH": "Bahrain",
      "BI": "Burundi",
      "BJ": "Benin",
      "BL": "St. Barthélemy",
      "BM": "Bermuda",
      "BN": "Brunei",
      "BO": "Bolivien",
      "BQ": "Karibische Niederlande",
      "BR": "Brasilien",
      "BS": "Bahamas",
      "BT": "Bhutan",
      "BV": "Bouvetinsel",
      "BW": "Botswana",
      "BY": "Weißrußland",
      "BZ": "Belize",
      "CA": "Kanada",
      "CC": "Kokosinseln (Keeling)",
      "CD": "Demokratische Republik Kongo",
      "CF": "Zentralafrikanische Republik\t",
      "CG": "Republik Kongo",
      "CH": "Schweiz",
      "CI": "Elfenbeinküste",
      "CK": "Cookinseln",
      "CL": "Chile",
      "CM": "Kamerun",
      "CN": "China",
      "CO": "Kolumbien",
      "CP": "Clipperton-Insel",
      "CR": "Costa Rica\t",
      "CU": "Kuba",
      "CV": "Kap Verde",
      "CW": "Curaçao",
      "CX": "Weihnachtsinsel",
      "CY": "Zypern",
      "CZ": "Tschechien",
      "DE": "Deutschland",
      "DG": "Diego Garcia",
      "DJ": "Dschibuti",
      "DK": "Dänemark",
      "DM": "Dominica",
      "DO": "Dominikanische Republik",
      "DZ": "Algerien",
      "EA": "Ceuta & Melilla",
      "EC": "Ecuador",
      "EE": "Estland",
      "EG": "Ägypten",
      "EH": "Westsahara",
      "ER": "Eritrea",
      "ES": "Spanien",
      "ET": "Äthiopien",
      "EU": "Europäische Union",
      "FI": "Finnland",
      "FJ": "Fidschi",
      "FK": "Falklandinseln",
      "FM": "Mikronesien",
      "FO": "Färöer-Inseln",
      "FR": "Frankreich",
      "GA": "Gabun",
      "GB": "Vereinigtes Königreich",
      "GD": "Grenada",
      "GE": "Georgien",
      "GF": "Französisch-Guayana\t",
      "GG": "Guernsey",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GL": "Grönland",
      "GM": "Gambia",
      "GN": "Guinea",
      "GP": "Guadeloupe",
      "GQ": "Äquatorialguinea",
      "GR": "Griechenland",
      "GS": "Südgeorgien und die Südlichen Sandwichinseln",
      "GT": "Guatemala",
      "GU": "Guam",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HK": "Hongkong",
      "HM": "Heard und Mcdonaldinseln\t",
      "HN": "Honduras",
      "HR": "Kroatien",
      "HT": "Haiti",
      "HU": "Ungarn",
      "IC": "Kanarische Inseln",
      "ID": "Indonesien",
      "IE": "Irland",
      "IL": "Israel",
      "IM": "Insel Man",
      "IN": "Indien",
      "IO": "Britisches Territorium im Indischen Ozean",
      "IQ": "Irak",
      "IR": "Iran",
      "IS": "Island",
      "IT": "Italien",
      "JE": "Jersey",
      "JM": "Jamaika",
      "JO": "Jordanien",
      "JP": "Japan",
      "KE": "Kenia",
      "KG": "Kirgistan",
      "KH": "Kambodscha",
      "KI": "Kiribati",
      "KM": "Komoren",
      "KN": "St. Kitts & Nevis",
      "KP": "Nordkorea",
      "KR": "Südkorea",
      "KW": "Kuwait",
      "KY": "Cayman-Inseln",
      "KZ": "Kasachstan",
      "LA": "Laos",
      "LB": "Libanon",
      "LC": "St. Lucia",
      "LI": "Liechtenstein",
      "LK": "Sri Lanka",
      "LR": "Liberia",
      "LS": "Lesotho",
      "LT": "Litauen",
      "LU": "Luxemburg",
      "LV": "Lettland",
      "LY": "Libyen",
      "MA": "Marokko",
      "MC": "Monaco",
      "MD": "Republik Moldau",
      "ME": "Montenegro",
      "MF": "St. Martin",
      "MG": "Madagaskar",
      "MH": "Marshall-Inseln",
      "MK": "Mazedonien",
      "ML": "Mali",
      "MM": "Myanmar (Burma)",
      "MN": "Mongolei",
      "MO": "Macau SAR China",
      "MP": "Nördliche Marianen",
      "MQ": "Martinique",
      "MR": "Mauretanien",
      "MS": "Montserrat",
      "MT": "Malta",
      "MU": "Mauritius",
      "MV": "Malediven",
      "MW": "Malawi",
      "MX": "Mexiko",
      "MY": "Malaysia",
      "MZ": "Mosambik",
      "NA": "Namibia",
      "NC": "Neu-Kaledonien",
      "NE": "Niger",
      "NF": "Norfolk-Insel",
      "NG": "Nigeria",
      "NI": "Nicaragua",
      "NL": "Niederlande (Holland)",
      "NO": "Norwegen",
      "NP": "Nepal",
      "NR": "Nauru",
      "NU": "Niue",
      "NZ": "Neuseeland",
      "OM": "Oman",
      "PA": "Panama",
      "PE": "Peru",
      "PF": "Französisch-Polynesien",
      "PG": "Papua-Neu-Guinea",
      "PH": "Philippinen",
      "PK": "Pakistan",
      "PL": "Polen",
      "PM": "St. Pierre & Miquelon",
      "PN": "Pitcairn Inseln",
      "PR": "Puerto Rico",
      "PS": "Palästinensisches Autonomiegebiet",
      "PT": "Portugal",
      "PW": "Palau",
      "PY": "Paraguay",
      "QA": "Katar",
      "QO": "Ozeanien",
      "RE": "Réunion",
      "RO": "Rumänien",
      "RS": "Serbien",
      "RU": "Russland",
      "RW": "Ruanda",
      "SA": "Saudi-Arabien\t",
      "SB": "Salomonen",
      "SC": "Seychellen",
      "SD": "Sudan",
      "SE": "Schweden",
      "SG": "Singapur",
      "SH": "St. Helena\t",
      "SI": "Slowenien",
      "SJ": "Svalbard und Jan Mayen",
      "SK": "Slowakei",
      "SL": "Sierra Leone",
      "SM": "San Marino",
      "SN": "Senegal",
      "SO": "Somalia",
      "SR": "Suriname",
      "SS": "Südsudan",
      "ST": "São Tomé und Príncipe",
      "SV": "El Salvador",
      "SX": "Saint-Martin",
      "SY": "Syrien",
      "SZ": "Swasiland",
      "TA": "Tristan Da Cunha",
      "TC": "Türken & Caicos-Inseln",
      "TD": "Tschad",
      "TF": "Französische Südliche Territorien",
      "TG": "Togo",
      "TH": "Thailand",
      "TJ": "Tadschikistan",
      "TK": "Tokelau",
      "TL": "Timor-Leste",
      "TM": "Turkmenistan",
      "TN": "Tunesien",
      "TO": "Tonga",
      "TR": "Turkei",
      "TT": "& Tobago Trinidad",
      "TV": "Tuvalu",
      "TW": "Taiwan",
      "TZ": "Tansania",
      "UA": "Ukraine",
      "UG": "Uganda",
      "UM": "Kleinere Inselbesitzungen der Vereinigten Staaten",
      "US": "USA",
      "UY": "Uruguay",
      "UZ": "Usbekistan",
      "VA": "Vatikanstadt",
      "VC": "St. Vincent & Grenadinen",
      "VE": "Venezuela",
      "VG": "Britische Jungferninseln",
      "VI": "Jungferninseln (US)",
      "VN": "Vietnam",
      "VU": "Vanuatu",
      "WF": "Wallis und Futuna",
      "WS": "Samoa",
      "XK": "Kosovo",
      "YE": "Jemen",
      "YT": "Mayotte",
      "ZA": "Südafrika",
      "ZM": "Sambia",
      "ZW": "Zimbabwe",
      "ZZ": "Unbekannte Region"
    },
    "country-select": {
      "label": "Land",
      "no_matches_message": "Keine Ergebnisse gefunden",
      "placeholder": "Land",
      "search_placeholder": "Tippe um zu suchen"
    },
    "disclaimer": {
      "alt-values": {
        "error": "Fehlermeldung",
        "info": "Informative Meldung",
        "warning": "Warnmeldung"
      }
    },
    "file-status": {
      "certified": "Beglaubigt",
      "not-certified": "Nicht beglaubigt"
    },
    "form-elements": {
      "optional": "(optional)"
    },
    "form-fields": {
      "date-field": {
        "error": {
          "invalid": "Dieses Datum ist ungültig. Können Sie es nochmal mit dem Format TT.MM.JJJJ versuchen?"
        },
        "placeholder": "TT.MM.JJJJ"
      },
      "date-picker": {
        "clear": {
          "cta": "Zurücksetzen"
        }
      }
    },
    "fullscreen": {
      "preview": {
        "delete-file": {
          "button-cancel": "Abbrechen",
          "button-confirm": "Löschen",
          "description": "Beachten Sie, dass das Löschen dieser Datei dauerhaft ist.",
          "title": "Diese Datei löschen?"
        }
      }
    },
    "gender-selector": {
      "female": "Weiblich",
      "label": "Geschlecht auf Identitätsnachweis",
      "male": "Männlich",
      "placeholder": "Geschlecht"
    },
    "icons": {
      "alt-values": {
        "error": "Fehlermeldung",
        "info": "Informative Meldung",
        "warning": "Warnmeldung"
      }
    },
    "kilobyte": "KB",
    "labels": {
      "choose_all": "Alle auswählen",
      "clear_filters": "Alle abwählen"
    },
    "megabyte": "MB",
    "password_creator": {
      "label": "Passwort",
      "placeholder": "Geben Sie hier Ihr Passwort ein",
      "set_pwd_rules": "Stellen Sie sicher, dass Ihr Passwort aus mindestens 9 Zeichen besteht. Nicht geeignet sind zum Beispiel Ihr Geburtsdatum, der Name Ihres Haustiers oder \"0000\".",
      "strength_password_strong": "Ihr Passwort ist < sicher genug! >",
      "strength_password_weak": "Ihr Passwort ist < noch zu einfach. >"
    },
    "pdf-preview": {
      "amount-discount": "{language, select, fr {Remise} de {Rabatt} es {Descuento} it {Sconto} other {Discount}}",
      "beneficiary-name": "{language, select, fr {Nom du bénéficiaire} it {Nome e cognome del beneficiario} de {Name des/r Begünstigten} es {Nombre del beneficiario} other {Beneficiary name}}",
      "bic": "BIC",
      "client-steuer-id": "{language, select, fr {Numéro fiscal client} de {Kunden-Steuer-ID} es {Codice fiscale cliente} it {NIF cliente} other {Client tax ID}}",
      "client-ust-udnr": "{language, select, fr {Numéro TVA client} de {Kunden-USt.-IDNr.} es {Número de IVA cliente} it {Partita IVA cliente} other {Client VAT number}}",
      "credit-note": {
        "credit-note-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "credit-note-number": "{language, select, fr {Numéro de l'avoir} it {Numero nota di credito} de {Stornorechnungsnummer} es {Número de factura rectificativa} other {Credit note number}}",
        "invoice-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "reason-credit-note": "{language, select, fr {Motif de l'avoir :} it {Motivo emissione nota di credito:} de {Grund für die Stornorechnung:} es {Motivo de la factura rectificativa:} other {Reason for credit note:}}",
        "related-invoice-number": "{language, select, fr {Numéro de facture associée} it {Numero fattura correlata} de {Zugehörige Rechnungsnummer} es {Número de factura asociada} other {Related invoice number}}",
        "title": "{language, select, fr {Avoir} it {Nota di credito} de {Stornorechnung} es {Factura rectificativa} other {Credit note}}"
      },
      "delivery-address": "{language, select, fr {Adresse de livraison} de {Lieferadresse} es {Dirección de envío} it {Indirizzo di consegna} other {Delivery address}}",
      "district-court": "{language, select, fr {Greffe du tribunal d'arrondissement :} de {Registergericht} es {Juzgado de registro de distrito:} it {Registro del tribunale distrettuale:} other {Registry district court:}}",
      "draft": {
        "invoice-number-draft-value": "--",
        "proforma-disclaimer": "{language, select, it {Il presente documento non costituisce fattura valida ai fini del DPR 633 del 26/10/1972 e successive modifiche. La fattura definitiva verrà emessa all’atto del pagamento del corrispettivo (articolo 6, comma 3, DPR 633/72).} other {This document does not constitute a valid invoice as outlined in the Decree of the President of the Republic (DPR) no. 633 of 26 October 1972 and subsequent amendments. The final invoice will be issued upon payment of the consideration (Article 6, paragraph 3, of the DPR 633/72).}}",
        "title": "{language, select, fr {Facture pro forma} de {Proformarechnung} es {Factura proforma} it {Fattura proforma} other {Pro forma invoice}}"
      },
      "due-date": "{language, select, fr {Date d'échéance} it {Data di scadenza} de {Fälligkeitsdatum} es {Fecha de vencimiento} other {Due date}}",
      "exemption": {
        "AT": {
          "S11.1a": "{language, select, fr {TVA non applicable, article 11 de l'UStG} de {MwSt. entfällt, Art. 11 des UStG} es {IVA no aplicable, art. 11 del UStG} it {IVA non applicabile, art. 11 del UStG} other {VAT not applicable, art. 11 of the UStG}}",
          "S6.1.13": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.16": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.19": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.27": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}"
        },
        "BE": {
          "S21": "{language, select, fr {TVA non applicable, article 21 du BTW-Wetboek} de {MwSt. entfällt, Art. 21 des BTW-Wetboek} es {IVA no aplicable, art. 21 del BTW-Wetboek} it {IVA non applicabile, art. 21 del BTW-Wetboek} other {VAT not applicable, art. 21 of the BTW-Wetboek}}",
          "S39": "{language, select, fr {TVA non applicable, article 39 du BTW-Wetboek} de {MwSt. entfällt, Art. 39 des BTW-Wetboek} es {IVA no aplicable, art. 39 del BTW-Wetboek} it {IVA non applicabile, art. 39 del BTW-Wetboek} other {VAT not applicable, art. 39 of the BTW-Wetboek}}",
          "S39bis": "{language, select, fr {TVA non applicable, article 39bis du BTW-Wetboek} de {MwSt. entfällt, Art. 39bis des BTW-Wetboek} es {IVA no aplicable, art. 39bis del BTW-Wetboek} it {IVA non applicabile, art. 39bis del BTW-Wetboek} other {VAT not applicable, art. 39bis of the BTW-Wetboek}}",
          "S44.1": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.11": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.2": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.4": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S56bis": "{language, select, fr {TVA non applicable, article 56bis du BTW-Wetboek} de {MwSt. entfällt, Art. 56bis des BTW-Wetboek} es {IVA no aplicable, art. 56bis del BTW-Wetboek} it {IVA non applicabile, art. 56bis del BTW-Wetboek} other {VAT not applicable, art. 56bis of the BTW-Wetboek}}"
        },
        "DE": {
          "S13B": "{language, select, fr {Steuerschuldnerschaft des Leistungsempfängers (obligation fiscale du bénéficiaire) (section 13b)} de {Steuerschuldnerschaft des Leistungsempfängers (Abschnitt 13b)} es {Steuerschuldnerschaft des Leistungsempfängers (responsabilidad fiscal del beneficiario) (Sección 13b)} it {Steuerschuldnerschaft des Leistungsempfängers (responsabilità fiscale del beneficiario) (Sezione 13b)} other {Steuerschuldnerschaft des Leistungsempfängers (Tax liability of the beneficiary) (Section 13b)}}",
          "S19.1": "{language, select, fr {Conformément à la section 19, paragraphe 1 de l'UStG, le montant de la facture n'inclut pas de taxe de vente} de {Gemäß Abschnitt 19 Abs. 1 UStG ist im Rechnungsbetrag keine Umsatzsteuer enthalten} es {Según el artículo 19, apartado 1 de la UStG, el importe de la factura no incluye el impuesto sobre las ventas} it {Ai sensi della sezione 19, paragrafo 1, della UStG, l'importo della fattura non comprende l'imposta sulle vendite} other {According to Section 19 Paragraph 1 UStG, the invoice amount does not include sales tax}}",
          "S4": "{language, select, fr {Conformément à la section 4 de l'UStG, aucune taxe sur la valeur ajoutée n'est facturée} de {Gemäß Abschnitt 4 UStG wird keine Mehrwertsteuer erhoben} es {Según el artículo 4 de la UStG, no se cobra ningún impuesto sobre el valor añadido} it {Ai sensi della sezione 4 della UStG, non viene addebitata alcuna imposta sul valore aggiunto} other {In accordance to § 4 UStG, no value added tax is charged}}",
          "S4.1A": "{language, select, fr {Livraison d'exportation exonérée d'impôt, conformément à la section 4 n° 1a de l'UStG} de {Steuerfreie Exportlieferung gemäß Abschnitt 4 Nr. 1a des UStG} es {Entrega para exportación exenta de impuestos según el artículo 4 núm. 1a de la UStG} it {Cessione dell'esportazione esente da imposte ai sensi della sezione. 4 n. 1a UStG} other {Tax-free export delivery in accordance with Section 4 No. 1a UStG}}",
          "S4.1B": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à la section 4 n° 1b de l'UStG} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Abschnitt 4 Nr. 1b des UStG} es {Entrega intracomunitaria exenta de impuestos según el artículo 4 núm. 1b de la UStG} it {Cessione intracomunitaria esente da imposte ai sensi della sezione 4 n. 1b UStG} other {Tax-free intra-community delivery in accordance with Section 4 No. 1b UStG.}}"
        },
        "ES": {
          "S122": "{language, select, fr {Opération exonérée d'impôt avec l'art. 122 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 122 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos en virtud del art. 122 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 122 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 122 of Law 37/1992, on VAT}}",
          "S20": "{language, select, fr {Opération exonérée d'impôt, conformément à l'art. 20 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 20 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por aplicación del art. 20 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 20 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 20 of Law 37/1992, on VAT}}",
          "S21": "{language, select, fr {Exportation exonérée d'impôt avec l'art. 21 de la Loi 37/1992 sur la TVA} de {Steuerfreier Export gemäß Art. 21 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por exportación en virtud del art. 21 de la Ley 37/1992, del IVA} it {Esportazione esente da imposta ai sensi dell'art. 21 della Legge 37/1992 sull'IVA} other {Tax-free export in accordance with art. 21 of Law 37/1992, on VAT}}",
          "S25": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à l'art. 25 de la Loi 37/1992 sur la TVA} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Art. 25 des Gesetzes 37/1992 über die MwSt.} es {Entrega intracomunitaria exenta de impuestos por aplicación del art. 25 de la Ley 37/1992, del IVA} it {Cessione intracomunitaria esente da imposta ai sensi dell'art. 25 della Legge 37/1992 sull'IVA} other {Tax-free intra-community delivery in accordance with art. 25 of Law 37/1992, on VAT}}",
          "S69": "{language, select, fr {Opération située à l'adresse du destinataire, conformément à l'art. 69 de la Loi 37/1992 sur la TVA} de {Ein auf der Anschrift des Empfängers oder der Empfängerin laufender Vorgang gemäß Art. 69 des Gesetzes 37/1992 über die MwSt.} es {Operación localizada en sede del destinatario en virtud del art. 69 de la Ley 37/1992, del IVA} it {Operazione situata all'indirizzo del destinatario ai sensi dell'art. 69 della Legge 37/1992 sull'IVA} other {Operation located at the address of the recipient in accordance with art. 69 of Law 37/1992, on VAT}}",
          "S84.1.2": "{language, select, fr {Reversement de l'assujetti - Art. 84.1.2º de la Loi 37/1992 sur la TVA} de {Umkehrung des Steuerpflichtigen – Art. 84.1.2º des Gesetzes 37/1992 über die MwSt.} es {Inversión de sujeto pasivo - Art. 84.1.2º de la Ley 37/1992, del IVA} it {Storno del soggetto passivo - Art. 84.1.2º della Legge 37/1992 sull'IVA} other {Reversal of taxable person - Art. 84.1.2º of Law 37/1992, on VAT}}"
        },
        "FR": {
          "S259": "{language, select, fr {TVA non applicable, art. 259-1 du CGI} de {MwSt. entfällt, Art. 259-1 des CGI} es {IVA no aplicable, art. 259-1 del CGI} it {IVA non applicabile, art. 259-1 del CGI} other {VAT not applicable, art. 259-1 of the CGI}}",
          "S261": "{language, select, fr {TVA non applicable, art. 261 du CGI} de {MwSt. entfällt, Art. 261 des CGI} es {IVA no aplicable, art. 261 del CGI} it {IVA non applicabile, art. 261 del CGI} other {VAT not applicable, art. 261 of the CGI}}",
          "S262": "{language, select, fr {TVA non applicable, art. 262 du CGI} de {MwSt. entfällt, Art. 262 des CGI} es {IVA no aplicable, art. 262 del CGI} it {IVA non applicabile, art. 262 del CGI} other {VAT not applicable, art. 262 of the CGI}}",
          "S262.1": "{language, select, fr {TVA non applicable, article 262 I du CGI} de {MwSt. entfällt, Art. 262 I des CGI} es {IVA no aplicable, art. 262 I del CGI} it {IVA non applicabile, art. 262 I del CGI} other {VAT not applicable, art. 262 I of the CGI}}",
          "S263": "{language, select, fr {TVA non applicable, art. 263 du CGI} de {MwSt. entfällt, Art. 263 des CGI} es {IVA no aplicable, art. 263 del CGI} it {IVA non applicabile, art. 263 del CGI} other {VAT not applicable, art. 263 of the CGI}}",
          "S283": "{language, select, fr {Autoliquidation, art. 283 du CGI} de {Steuerschuldnerschaft des Leistungsempfängers, Art. 283 des CGI} es {Autoliquidación, art. 283 del CGI} it {Autoliquidazione, art. 283 del CGI} other {Autoliquidation, art. 283 of the CGI}}",
          "S293B": "{language, select, fr {TVA non applicable, art. 293 B du CGI} de {MwSt. entfällt, Art. 263 B des CGI} es {IVA no aplicable, art. 293 B del CGI} it {IVA non applicabile, art. 293 B della CGI} other {VAT not applicable, art. 293 B of the CGI}}"
        },
        "NL": {
          "S11": "{language, select, fr {TVA non applicable, article 11 de l'OB 1968} de {MwSt. entfällt, Art. 11 des OB 1968} es {IVA no aplicable, art. 11 del OB 1968} it {IVA non applicabile, art. 11 del OB 1968} other {VAT not applicable, art. 11 of the OB 1968}}",
          "S12": "{language, select, fr {TVA non applicable, article 12 de l'OB 1968} de {MwSt. entfällt, Art. 12 des OB 1968} es {IVA no aplicable, art. 12 del OB 1968} it {IVA non applicabile, art. 12 del OB 1968} other {VAT not applicable, art. 12 of the OB 1968}}",
          "S25": "{language, select, fr {TVA non applicable, article 25 de l'OB 1968} de {MwSt. entfällt, Art. 25 des OB 1968} es {IVA no aplicable, art. 25 del OB 1968} it {IVA non applicabile, art. 25 del OB 1968} other {VAT not applicable, art. 25 of the OB 1968}}",
          "S9": "{language, select, fr {TVA non applicable, article 9 de l'OB 1968} de {MwSt. entfällt, Art. 9 des OB 1968} es {IVA no aplicable, art. 9 del OB 1968} it {IVA non applicabile, art. 9 del OB 1968} other {VAT not applicable, art. 9 of the OB 1968}}"
        },
        "PT": {
          "S53": "{language, select, fr {TVA non applicable, article 53 du Regime do IVA} de {MwSt. entfällt, Art. 53 des Regime do IVA} es {IVA no aplicable, art. 53 del Regime do IVA} it {IVA non applicabile, art. 53 del Regime do IVA} other {VAT not applicable, art. 53 of the Regime do IVA}}",
          "S9.1-5": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.27.28": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.29.30": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}"
        }
      },
      "footer-organization": "{language, select, fr {{orgaName} avec un capital de {amount}} de {mit einem Kapital von} es {{orgaName} con un capital de {amount}} it {{orgaName} con un capitale di {amount}} other {{orgaName} with a capital of {amount}}}",
      "iban": "IBAN",
      "intermediary-bic": {
        "label": "{language, select, fr {BIC de la banque intermédiaire} de {BIC unserer Korrespondenzbank} es {BIC del banco intermediario} it {BIC della banca corrispondente} other {Intermediary bank BIC}}",
        "value": "{language, select, fr {TRWIBEB3XXX} de {TRWIBEB3XXX} es {TRWIBEB3XXX} it {TRWIBEB3XXX} other {TRWIBEB3XXX}}"
      },
      "invoice-example": {
        "client-address-line1": "Lohmühlenstr. 65",
        "client-city": "Berlin",
        "client-countrycode": "DE",
        "client-delivery-address-line1": "Lohmühlenstr. 65",
        "client-delivery-city": "Berlin",
        "client-delivery-countrycode": "DE",
        "client-delivery-zipcode": "12435",
        "client-name": "Johanna Müller",
        "client-vat-number": "1234567890",
        "client-zipcode": "12435",
        "invoice-due-date": "15.01.2030",
        "invoice-issue-date": "01.01.2030",
        "invoice-number": "RE-2030-001",
        "invoice-payment-time": "Bitte begleichen Sie den Rechnungsbetrag innerhalb von 15 Tagen. (15.01.2030)",
        "invoice-performance-date": "01/01/2030",
        "invoice-total": "119",
        "item-description": "Hier werden die Details Ihrer zukünftigen, realen Elemente erscheinen.",
        "item-price": "100",
        "item-quantity": "2",
        "item-title": "Der Name meines ersten Elements",
        "item-total": "119",
        "item-total-without-vat": "100",
        "item-vat-amount": "0.19",
        "item-vat-percentual": "%",
        "item-vat-total": "19",
        "page-count": "1/1",
        "quote-validated-time": "Dieses Angebot ist 30 Tage gültig (15.01.2030)"
      },
      "invoice-number": "{language, select, fr {Numéro de facture} it {Numero della fattura} de {Rechnungsnummer} es {Número de factura} other {Invoice number}}",
      "invoice-payment-time": "{number, plural, =0 {{language, select, fr {Veuillez payer la facture à l'émission. ({dueDate})} de {Bitte bezahlen Sie den Betrag direkt nach Erhalt der Rechnung. ({dueDate})} es {Por favor, abona el importe de la factura tras la recepción. ({dueDate})} it {Per favore, paga l’importo della fattura alla sua ricezione. ({dueDate})} other {Please pay the invoice amount upon receipt. ({dueDate})}}} one {{language, select, fr {Veuillez payer la facture sous {days_amount} jour. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tag. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} día. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorno. ({dueDate})} other {Please pay the invoice amount within {days_amount} day. ({dueDate})}}} other {{language, select, fr {Veuillez payer la facture sous {days_amount} jours. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tagen. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} días. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorni. ({dueDate})} other {Please pay the invoice amount within {days_amount} days. ({dueDate})}}}}",
      "issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
      "items": "{language, select, fr {Description} it {Descrizione} de {Beschreibung} es {Descripción} other {Description}}",
      "leadership": "{language, select, fr {Direction de l'entreprise :} de {Geschäftsführung} es {Dirección de la empresa:} it {Direzione dell'impresa:} other {Company leadership:}}",
      "payment-condition": {
        "compensated_for_sales": "{language, select, fr {Sur les débits} de {Im zu zahlenden Betrag enthalten} es {Compensación por ventas} it {Inclusa nel prezzo di vendita} other {Compensated for sales}}",
        "exempt": "{language, select, fr {Exonération} de {Befreit} es {Exento} it {Esente} other {Exempt}}",
        "label": "{language, select, fr {Conditions de paiement de la TVA : } de {MwSt.-Zahlungsbedingung: } es {Condición de pago del IVA: } it {Opzioni per il pagamento dell'IVA: } other {VAT payment condition: }}",
        "on_receipts": "{language, select, fr {Sur les encaissements} de {Sofort} es {Tras la recepción} it {A vista} other {On receipt}}"
      },
      "payment-details": "{language, select, fr {Détails du paiement} it {Dettagli per il pagamento} de {Zahlungsdetails} es {Detalles del pago} other {Payment details}}",
      "pension-contribution": "{language, select, it {Contributo pensione/INPS (IVA escl.)} other {Pension/INPS contribution (excl. VAT)}}",
      "percentage-discount": "{language, select, fr {Remise ({discountPercentage} %)} de {Rabatt ({discountPercentage}%)} es {Descuento ({discountPercentage}%)} it {Sconto ({discountPercentage}%)} other {Discount ({discountPercentage}%)}}",
      "performance-date": "{language, select, fr {Date d'exécution} de {Leistungsdatum} es {Fecha de ejecución} it {Data prestazione} other {Performance date}}",
      "placerholder-stamp-duty": "{language, select, it {Imposta di bollo virtuale pagata ai sensi del decreto ministeriale 17.06.2014} other {Stamp duty paid virtually pursuant to Ministerial Decree 17.06.2014}}",
      "purchase-order": "{language, select, fr {Numéro de bon de commande} it {Numero d'ordine} de {Bestellnummer} es {Número de orden de compra} other {Purchase order number}}",
      "quantity": "{language, select, fr {Qté} it {Qtà} de {Menge} es {Cant.} other {Qty}}",
      "quote-expiry-date": "{language, select, fr {Date d'expiration} it {Data di scadenza} de {Ablaufdatum} es {Fecha de vencimiento} other {Expiry date}}",
      "quote-number": "{language, select, fr {Numéro de devis} it {Numero del preventivo} de {Angebotsnummer} es {Número de presupuesto} other {Quote number}}",
      "quote-related-number": "{language, select, fr {Numéro du devis associé } it {Numero di preventivo correlato } de {Zugehöriges Angebot } es {Número de presupuesto asociado } other {Related quote number }}",
      "quote-title": "{language, select, fr {Devis} de {Angebot} es {Presupuesto} it {Preventivo} other {Quote}}",
      "quote-validated-time": "{number, plural, =0 {} one {{language, select, fr {Ce devis est valable {days_amount} jour. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tag. ({expiry_date})} es {Este presupuesto es válido por {days_amount} día. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorno. ({expiry_date})} other {This quote is valid for {days_amount} day. ({expiry_date})}}} other {{language, select, fr {Ce devis est valable {days_amount} jours. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tage. ({expiry_date})} es {Este presupuesto es válido por {days_amount} días. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorni. ({expiry_date})} other {This quote is valid for {days_amount} days. ({expiry_date})}}}}",
      "reference": "{language, select, fr {Référence} it {Causale} de {Referenz} es {Referencia} other {Reference}}",
      "revenue-stamp": "{language, select, it {Marca da bollo} other {Revenue stamp}}",
      "steuer-id": "{language, select, fr {Steuer-ID} de {Steuer-ID} es {Steuer-ID} it {Steuer-ID} other {Steuer-ID}}",
      "steuernummer": "{language, select, fr {Steuernummer :} de {Steuernummer:} es {Steuernummer:} it {Steuernummer:} other {Steuernummer:}}",
      "title": "{language, select, fr {Facture} de {Rechnung} es {Factura} it {Fattura} other {Invoice}}",
      "total": "{language, select, fr {Total} it {Totale} de {Gesamt} es {Total} other {Total}}",
      "total-vat": "{language, select, fr  {Total TTC} it {Totale (IVA inclusa)}  de {Gesamt (inkl. MwSt.)} es {Total (IVA incluido)} other {Total incl. VAT}}",
      "total-vat-amount": "{language, select, fr {Montant total de la TVA} it {Importo totale IVA} de {MwSt.-Betrag insgesamt} es {Importe total del IVA} other {Total VAT amount}}",
      "total-without-vat": "{language, select, fr {Total HT} it {Totale (IVA escl.)} de {Gesamt (exkl. MwSt.)} es {Total (IVA no incluido)} other {Total excl. VAT}}",
      "transaction-type": {
        "goods": "{language, select, fr {Biens} de {Waren} es {Bienes} it {Beni} other {Goods}}",
        "goods_and_services": "{language, select, fr {Biens et services} de {Waren und Dienstleistungen} es {Bienes y servicios} it {Beni e servizi} other {Goods and services}}",
        "label": "{language, select, fr {Type de transaction : } de {Transaktionstyp: } es {Tipo de transacción: } it {Tipo di transazione: } other {Transaction type: }}",
        "services": "{language, select, fr {Services} de {Dienstleistungen} es {Servicios} it {Servizi} other {Services}}"
      },
      "unit-price": "{language, select, fr {Prix unitaire} it {Prezzo unitario} de {Preis pro Einheit} es {Precio unitario} other {Unit price}}",
      "ust-udnr": "{language, select, fr {USt-IDNr.} de {USt-IDNr.} es {USt-IDNr.} it {USt-IDNr.} other {USt-IDNr.}}",
      "vat": "{language, select, fr {TVA (%)} it {IVA (%)} de {MwSt. (%)} es {IVA (%)} other {VAT (%)}}",
      "vat-amount": "{language, select, fr {Montant de la TVA} it {Importo IVA} de {MwSt-Betrag} es {Importe del IVA} other {VAT amount}}",
      "vat-number": "{language, select, fr {Numéro de TVA :} de {USt.-IdNr.:} es {Número de IVA:} it {Partita IVA:} other {VAT number:}}",
      "withholding-tax": "{language, select, it {Ritenuta d'acconto} other {Withholding tax}}"
    },
    "spinner": {
      "label": "Lädt"
    },
    "stepper-header": {
      "back": "Zurück",
      "close": "Schließen"
    },
    "translation-with-args": {
      "body-1": "Text mit {var} drinnen.",
      "body-3": "Text mit {var3} drinnen und dann noch ein {var1} drinnen und dann noch ein {var2} drinnen."
    },
    "zxcvbn": {
      "password_common": "Dieses Passwort wird häufig verwendet und ist daher leicht zu erraten.",
      "password_date": "Datumsangaben sollten vermieden werden, da sie zu häufig verwendet werden.",
      "password_dictionary": "Dieses Passwort wird häufig verwendet und ist daher leicht zu erraten.",
      "password_repeat": "Wiederholungen von Wörtern oder Buchstaben sind nicht sicher genug.",
      "password_sequence": "Wiederholungen von Wörtern oder Buchstaben sind nicht sicher genug.",
      "password_spatial": "Tastenfolgen, wie z.B. \"qwertz\", sind nicht sicher genug."
    }
  },
  "welcome": {
    "gdpr_checkbox": "Ich bin mit der Verwendung meiner persönlichen Daten durch Qonto einverstanden",
    "gdpr_checkbox_error_message": "Um fortzufahren, müssen Sie unserer Datenschutzrichtlinie zustimmen.",
    "redirect_to_appointment_page": "Fragen Sie unsere Experten nach weiteren Informationen",
    "redirect_to_bridge": "Den Kontowechsel beginnen",
    "step_1_subtitle": "Mit dem sicheren Service Bridge by Bankin'.",
    "step_1_title": "Verbinden Sie Ihre Bank mit Qonto",
    "step_2_subtitle": "Ihre Daten werden sicher synchronisiert.",
    "step_2_title": "Wählen Sie das oder die zu übertragenden Konten",
    "step_3_subtitle": "Um all Ihre Fragen zu beantworten.",
    "step_3_title": "Ein/e Qonto Berater/in wird Sie Schritt für Schritt begleiten",
    "subtitle": "Und optimieren Sie Ihre Finanzverwaltung.",
    "title": "Übertragen Sie Ihre Konten ohne großen Aufwand"
  }
}], ["en", {
  "application": {
    "back_to_app": "Go back to Qonto app",
    "go_back": "Back",
    "security_disclaimer": "This is a secure service. All data is strictly reserved for Qonto usage and can be deleted upon request at any time.",
    "security_disclaimer_link": "Learn more",
    "title": "Account transfer"
  },
  "appointment": {
    "back_cta": "Back",
    "calendar_subtitle": "Book an appointment to prepare your account transfer with your Qonto agent.",
    "calendar_title": "Meet your Qonto agent"
  },
  "bank_selection": {
    "consent_modal": {
      "checkbox_text": "I agree to the use of my personal data by Qonto.",
      "primary_CTA": "Confirm",
      "secondary_CTA": "Cancel",
      "subtitle": "For legal reasons, we need to get your consent on our data use policy before connecting your bank to Qonto via the secure service Bridge by Bankin’.",
      "title": "Give us your consent"
    },
    "select_child_bank": {
      "back_CTA": "Back",
      "individual": "Personal account",
      "pro": "Business account",
      "subtitle": "{bank_name} has several entities so make sure to select the right one. If you’re not sure, we advise you to contact your bank.",
      "title": "Select your bank’s entity"
    },
    "select_parent_bank": {
      "cannot_find": "Your bank is not listed?",
      "search_empty": {
        "CTA": "Contact us",
        "subtitle": "Contact us using the chat displayed on this screen to let us know which bank is missing. We’ll do our best to help you transfer your account(s) anyway.",
        "title": "This bank isn’t supported (yet)"
      },
      "search_placeholder": "Search for a bank...",
      "title": "Select your bank"
    }
  },
  "bankswitch": {
    "backend_error": {
      "toaster": "An error occurred. Could you refresh the page?"
    }
  },
  "btn": {
    "back": "Back"
  },
  "choose_organization": {
    "continue_button": "Continue",
    "error_message": "To continue, you need to select a company.",
    "subtitle": "Select a company to transfer your account to.",
    "title": "Select a company"
  },
  "dashboard": {
    "CTA": {
      "new_action": "Create an action"
    },
    "account_details": {
      "bic": "BIC",
      "disclaimer": "You can switch only one bank now, multi-bank is coming soon.",
      "iban": "IBAN",
      "no_iban": "You'll find your IBAN on your bank's client portal.",
      "no_iban_link": "See your IBAN on Qonto.",
      "notifications": {
        "bic_copy": "Your BIC has been copied!",
        "iban_copy": "Your IBAN has been copied!"
      }
    },
    "action": {
      "important": {
        "tag": "Important"
      },
      "menu": {
        "CTA_delete": "Delete",
        "CTA_edit": "Edit",
        "CTA_flag_as_important": "Mark as important",
        "CTA_ignore": "Ignore",
        "CTA_mark_as_done": "Mark as done",
        "CTA_unflag_as_important": "Unmark as important",
        "CTA_unignore": "Move back",
        "CTA_unmark_as_done": "Unmark as done"
      }
    },
    "card": {
      "action": {
        "ignored": "Ignore",
        "reactive": "Move back"
      },
      "category": {
        "cards": "Cards",
        "mandates": "Mandates",
        "others": "Others",
        "transfers": "Transfers"
      },
      "last_operation": "Last:",
      "last_operation_detected": "Last operation detected:",
      "operations": "{count, plural,=1 {Operation:} other {Operations:}}"
    },
    "create_action": {
      "toaster": "A new action has been created."
    },
    "create_form": {
      "CTA_cancel": "Cancel",
      "CTA_create": "Create",
      "category_field": {
        "placeholder": "Select a category",
        "title": "Category"
      },
      "category_list": {
        "atm": "ATM",
        "fees": "Fees",
        "finance": "Finance",
        "hotel_and_lodging": "Hotels & Lodging",
        "insurance": "Insurance",
        "legal_and_accounting": "Legal & Accounting",
        "logistics": "Logistics",
        "marketing": "Marketing",
        "office_rental": "Office rental",
        "office_supply": "Office supplies",
        "online_service": "Online services",
        "other": "Other",
        "pending": "Pending",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Salaries",
        "sales": "Sales",
        "tax": "Taxes",
        "transport": "Transports",
        "utility": "Utilities"
      },
      "note_field": {
        "placeholder": "Add a note",
        "title": "Additional information"
      },
      "title": {
        "placeholder": "New task"
      },
      "title_field": {
        "placeholder": "Give a name to this task",
        "title": "Name"
      },
      "type_field": {
        "placeholder": "Select a payment method",
        "title": "Method"
      }
    },
    "credit": {
      "no_actions": {
        "subtitle": "We couldn’t find any credit transactions to transfer from this account. Don’t hesitate to contact us if you think this is a mistake.",
        "title": "No recurring client"
      },
      "sections": {
        "all": {
          "methods": "All methods"
        },
        "cards": "Cards",
        "cards_tooltip": "Register your new Qonto card with these payment partners.",
        "done": "Done",
        "ignored": "Ignored",
        "others": "Others",
        "others_tooltip": "These actions must be treated individually. Get in touch with your Qonto agent for help.",
        "todo": "To do",
        "transfers": "Transfers",
        "transfers_tooltip": "Add these beneficiaries to your Qonto account."
      },
      "subtitle": "Share your new account details with your customers and payment partners to start receiving money into your Qonto account.",
      "title": "Recurring clients"
    },
    "debit": {
      "no_actions": {
        "subtitle": "We couldn’t find any debit transactions to transfer from this account. Don’t hesitate to contact us if you think this is a mistake.",
        "title": "No recurring supplier"
      },
      "sections": {
        "all": {
          "methods": "All methods"
        },
        "cards": "Cards",
        "cards_tooltip": "Register your new Qonto card with these providers.",
        "done": "Done",
        "ignored": "Ignored",
        "mandates": "Mandates",
        "mandates_tooltip": "Cancel your current mandates and issue new ones with your Qonto IBAN.",
        "others": "Others",
        "others_tooltip": "These actions must be treated individually. Get in touch with your Qonto agent for help.",
        "todo": "To do",
        "transfers": "Transfers",
        "transfers_tooltip": "Add these beneficiaries to your Qonto account."
      },
      "subtitle": "Share your new bank details with your suppliers to ensure your future payments are processed.",
      "title": "Recurring suppliers"
    },
    "delete_action": {
      "modal": {
        "CTA_cancel": "Cancel",
        "CTA_delete": "Delete",
        "body": "Keep in mind that deleting this action is permanent.",
        "title": "Delete this action?"
      },
      "toaster": "This action has been deleted."
    },
    "edit_action": {
      "toaster": "This action has been edited."
    },
    "edit_form": {
      "CTA_cancel": "Cancel",
      "CTA_save": "Save changes",
      "category_field": {
        "placeholder": "Select a category",
        "title": "Category"
      },
      "category_list": {
        "atm": "ATM",
        "fees": "Fees",
        "finance": "Finance",
        "hotel_and_lodging": "Hotels & Lodging",
        "insurance": "Insurance",
        "legal_and_accounting": "Legal & Accounting",
        "logistics": "Logistics",
        "marketing": "Marketing",
        "office_rental": "Office rental",
        "office_supply": "Office supplies",
        "online_service": "Online services",
        "other": "Other",
        "pending": "Pending",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Salaries",
        "sales": "Sales",
        "tax": "Taxes",
        "transport": "Transports",
        "utility": "Utilities"
      },
      "note_field": {
        "placeholder": "Add a note",
        "title": "Additional information"
      },
      "title_field": {
        "placeholder": "Give a name to this task",
        "title": "Name"
      },
      "type_field": {
        "placeholder": "Select a payment method",
        "title": "Method"
      }
    },
    "operation_history": {
      "back_to_credits_cta": "Back to Clients",
      "back_to_debits_cta": "Back to Suppliers",
      "cancel_cta": "Unmark as done",
      "check_cta": "Mark as done",
      "ignore_cta": "Ignore",
      "reactive_cta": "Move back",
      "subtitle": {
        "credit": "{count, plural,=1 {{count} operation is related to this client} other {{count} operations are related to this client}}",
        "debit": "{count, plural,=1 {{count} operation is related to this supplier} other {{count} operations are related to this supplier}}"
      },
      "table_field": {
        "amount": "Amount",
        "date": "Date",
        "label": "Transaction"
      }
    },
    "overview": {
      "cards": {
        "dropdown": {
          "title": "All accounts"
        },
        "last_update": {
          "day": "Last updated {count} {count, plural, =1 {day ago} other {days ago}}",
          "month": "Last updated {count} {count, plural, =1 {month ago} other {months ago}}",
          "year": "Last updated {count} {count, plural, =1 {year ago} other {years ago}}"
        }
      },
      "faq": {
        "first_article": {
          "description": "Find out about bank aggregation and learn how to synchronize your former bank with Qonto.",
          "link": "https://help.qonto.com/en/articles/5020434",
          "title": "How does the account transfer service work?"
        },
        "second_article": {
          "description": "Read our experts’ tips and advice on how to change banks without the hassle.",
          "link": "https://help.qonto.com/en/articles/5145573",
          "title": "How to track your account transfer progress?"
        },
        "title": "Getting started with your account transfer"
      },
      "popin": {
        "end_migration_completed": {
          "cta": {
            "cancel": "Cancel",
            "end_transfer": "Confirm"
          },
          "title": "Confirm that your account transfer has been completed",
          "wording": "Your account transfer from {bank_name} to Qonto for {company_name} is about to end. Therefore, all your data related to your previous account will be erased."
        },
        "end_migration_not_completed": {
          "cta": {
            "cancel": "Cancel",
            "end_transfer": "End transfer"
          },
          "title": "End your account transfer",
          "wording": "Are you sure? This will stop your account transfer from {bank_name} to Qonto for {company_name}, and erase your transfer data. Just for the record, you can start a new transfer from scratch at any time."
        }
      },
      "stepper": {
        "end_migration": "End transfer",
        "file_link": "Download the guide book",
        "step1": {
          "progress_bar": {
            "completed": "Completed",
            "inprogress": "In progress"
          },
          "subtitle1": {
            "description": "To ensure your future payments are processed.",
            "title": "Suppliers"
          },
          "subtitle2": {
            "description": "To start receiving money into your Qonto account.",
            "title": "Clients"
          },
          "title": "Share your new bank details with your suppliers and clients"
        },
        "step2": {
          "subtitle": "Take a look at our guide to learn more about the steps you need to complete.",
          "title": "Prepare your previous account’s closure"
        },
        "step3": {
          "subtitle": "Confirm you're ready to end your transfer account once you've completed the previous steps.",
          "title": "End transfer"
        },
        "step_link": {
          "completed": "View details",
          "uncompleted": "{count} {count, plural,=1 {action to finish} other {actions to finish}}"
        },
        "title": "Transfer progress"
      },
      "subtitle": "In this dashboard, you'll find all the information relative to your account transfer.",
      "title": "Keep an eye on your account transfer"
    },
    "side": {
      "credit": "Clients",
      "debit": "Suppliers",
      "overview": "Overview",
      "settings": "Settings",
      "settings_items": {
        "add_accounts": "Add another account",
        "back_to_app": "Go to Qonto app",
        "ends_transfer_cta": "End the transfer",
        "support": "Support",
        "support_link": "https://help.qonto.com/en",
        "switch_organization": "Change company"
      }
    }
  },
  "dashboard_generation": {
    "waiting_screen": {
      "description": "Our team is currently analyzing and listing all your recurring transactions. This might take up <b>from 4 to 10 minutes</b> but no worries, there is no need for you to wait here. You’ll receive an email once your dashboard is ready.",
      "disclaimer": {
        "part_1": "This is a secure service. All data is strictly reserved for Qonto usage and can be deleted upon request at any time.",
        "part_2": "Learn more"
      },
      "title": "Your dashboard is being created"
    }
  },
  "dynamic-values": {
    "dates": {
      "current-month": "%month%",
      "current-year": "%year%",
      "last-month": "%lastmonth%",
      "next-month": "%nextmonth%"
    }
  },
  "empty-state-discover": {
    "divider": "or"
  },
  "ends_transfer": {
    "confirmation_modal": {
      "cancel_cta": "Cancel",
      "confirm_cta": "End the transfer",
      "subtitle": "Are you sure? This will stop your account transfer from {bank_name} to Qonto for {company_name}, and erase your transfer data. Just for the record, you can start a new transfer from scratch whenever you want.",
      "title": "End"
    }
  },
  "error_1010": {
    "disclaimer": {
      "body": "Your data is not updated: you need to re-enter the confirmation code received by SMS",
      "link": "right here"
    }
  },
  "error_429": {
    "disclaimer": {
      "body_action1": "Your data is not updated: you need to agree to the terms and conditions on your {bank_name} account.",
      "body_action10": "Your data is not updated: you need to reactivate the strong authentication on your {bank_name} account.",
      "body_action11": "Your data is not updated: you need to get access to online services by contacting your bank {bank_name}.",
      "body_action12": "Your data is not updated: you need to contact the customer service of your bank {bank_name}.",
      "body_action13": "Your data is not updated: you need to replace or activate your credentials by contacting your bank {bank_name}.",
      "body_action14": "Your data is not updated: you need to modify your password on your {bank_name} account.",
      "body_action15": "Your data is not updated: you need to create a new password by logging in for the first time on your {bank_name} account.",
      "body_action2": "Your data is not updated: you need to sign a contract on your {bank_name} account.",
      "body_action3": "Your data is not updated: you need to activate or enter your credentials on your {bank_name} account.",
      "body_action4": "Your data is not updated: you need to activate your {bank_name} account.",
      "body_action5": "Your data is not updated: you need to enter or confirm the phone number linked to your {bank_name} account.",
      "body_action6": "Your data is not updated: you need to validate an information or a message on your {bank_name} account.",
      "body_action7": "Your data is not updated: you need to confirm or complete some personal details on your {bank_name} account.",
      "body_action8": "Your data is not updated: you need to confirm and/or modify your details on your {bank_name} account.",
      "body_action9": "Your data is not updated: you need to create a {bank_name} account."
    }
  },
  "error_430": {
    "disclaimer": {
      "body": "Your data is not updated: you need to modify your password on your {bank_name} account."
    }
  },
  "error_management_screen": {
    "generic_fallback_error": {
      "description": "An error prevents us from creating your dashboard automatically. Schedule a call with a Qonto agent to get help and be accompanied.",
      "primary_cta": "Schedule a call",
      "title": "We couldn't create your dashboard"
    }
  },
  "information_appointment": {
    "calendar_subtitle": "Book an appointment to prepare your account transfer with a dedicated agent.",
    "calendar_title": "Meet your Qonto agent"
  },
  "labels": {
    "pagination_summary": "of"
  },
  "logout": {
    "btn": "Go back to login screen",
    "modal": {
      "accept_btn": "Yes, keep me in!",
      "close_btn": "Log me out",
      "content": "In 5 minutes, your session will reach its limit of 30 minutes and expire. To guarantee the security of your account, you will be automatically logged out. You can also choose to extend your session below.",
      "title": "Are you still here?"
    },
    "subtitle": "For security reasons, your Qonto sessions end after 30 minutes. You have been automatically logged out.",
    "title": "You have been securely logged out."
  },
  "maintenance": {
    "cta": "Go back to Qonto app",
    "subtitle": "It looks like there is a problem. No worries, our team is currently fixing it. In the meantime, get in touch with your Qonto agent if you need any help.",
    "title": "An error has occurred"
  },
  "migration_status": {
    "appointment_successfully_made_redirection_toast": "Your appointment has been scheduled!",
    "end_migration": "End the transfer",
    "step_1_subtitle": "To generate a custom account transfer plan.",
    "step_1_title": "Your transactions are being analyzed",
    "step_2_subtitle": "To discuss your personalized account transfer plan.",
    "step_2_title": "It's time for a first interview with your Qonto agent",
    "step_3_subtitle": "You'll receive updates and reminders every step of the way.",
    "step_3_title": "Your account transfer is in good hands",
    "step_4_subtitle": "You're all set! Start managing your professional account directly from your Qonto app.",
    "step_4_title": "Your account transfer is complete!",
    "subtitle": "Your account transfer has started. Follow its progression here.",
    "title": "Track your account transfer progress"
  },
  "migration_succeeded": {
    "back_to_qonto": "Go to my Qonto account",
    "end_migration": {
      "file_link": "Download the guide book",
      "title1": "Finalize your previous",
      "title2": "account’s closure",
      "wording": "Have you already closed your previous account? If not, take a look at our guide to learn more about the steps you need to complete."
    },
    "subtitle": "You can now take advantage of all Qonto's exclusive features. Don't hesitate to contact us if you need anything else.",
    "switch_another_bank": "Transfer another account to Qonto",
    "title": "Congratulations, your account transfer is complete!"
  },
  "no_mobile": {
    "back_to_qonto": "Go back to Qonto app",
    "subtitle": "Please log on to your desktop device to access this feature.",
    "title": "It’s only on desktop for now!"
  },
  "no_rights": {
    "subtitle": "Only an Admin can carry out an account transfer.",
    "title": "It seems you don't have the rights to access this service"
  },
  "not_found": {
    "cta": "Go back to Qonto app",
    "subtitle": "We couldn't find the page you were looking for. This is either because the page has been moved or deleted, or because there is an error in the URL.",
    "title": "This page cannot be found"
  },
  "onboarding_funnel": {
    "OTP_modal": {
      "code": "Code",
      "error_text": "Invalid code",
      "link": "Didn’t get your code? Send another one.",
      "primary_CTA": "Cancel",
      "secondary_CTA": "Submit",
      "text": "Please fill in the temporary verification code you just received by text.",
      "title": "Verification code"
    },
    "app_modal": {
      "link": "Do you need help?",
      "text": "To continue, you need to confirm the connection on your bank’s mobile app.",
      "title": "Grab your phone"
    },
    "consent": {
      "CTA_1": "Cancel",
      "CTA_2": "Confirm",
      "checkbox": {
        "first": "By clicking here, I accept the terms and conditions of this service and I confirm that I have read ",
        "link": "Qonto's data protection policy.",
        "second": ""
      },
      "text": "Qonto offers you the possibility to connect to your Qonto account, all of your bank accounts and payment institution accounts opened with third-party establishments.<br>Qonto is approved by the ACPR to provide you with this information service and account aggregation service and relies on the infrastructures of Bankin'.<br>However, we cannot guarantee being able to access all of your accounts.<br><br>Your bank details and your personal data are protected in conformity with European regulations (Directive on payment services and General personal data protection regulation).",
      "title": "Qonto cares about your data privacy"
    },
    "credentials": {
      "4digitcode_title": "4 digit code",
      "CTA": "Sign in",
      "accesscode_title": "Access code",
      "accountnumber_placeholder": "Enter your account number",
      "accountnumber_title": "Account number",
      "answer_placeholder": "Enter your answer",
      "answer_title": "Answer to the security question",
      "birthdate_placeholder": "DD/MM/YYYY",
      "birthdate_title": "Date of birth",
      "checkbox": "I certify on my honor that I am entitled and have the necessary authorizations to access the account information.",
      "clientnumber_placeholder": "Enter your client number",
      "clientnumber_title": "Client number",
      "code_placeholder": "Enter your user code",
      "code_title": "User code",
      "companynumber_placeholder": "Enter your company ID number",
      "companynumber_title": "Company ID number",
      "confidentialcode_title": "Confidential code",
      "email_placeholder": "jane.doe@mycompany.com",
      "email_title": "Email address",
      "id_placeholder": "Enter your ID number",
      "id_title": "ID number",
      "password_placeholder": "Enter your password",
      "password_title": "Password",
      "phonenumber_placeholder": "+44 123 456789",
      "phonenumber_title": "Phone number with country code (+44...)",
      "servercode_title": "Server code",
      "subscriptionnumber_placeholder": "Enter your subscriber number",
      "subscriptionnumber_title": "Subscriber number",
      "text": "Please enter the same login details that you usually use on {bank_name}’s website.<br/>Rest assured, Qonto does not store this information.",
      "title": "Connect to {bank_name}",
      "usernumber_placeholder": "Enter your ID number",
      "usernumber_title": "ID number"
    },
    "error_1005": {
      "primary_CTA": "Schedule a call",
      "secondary_CTA": "Go to Qonto app",
      "text": "An error prevents us from connecting to your bank. Don’t worry though, a Qonto agent is here to help you resolve the problem and assist you throughout the transfer.",
      "title": "The connection with {bank_name} has failed"
    },
    "error_402": {
      "link": "chosen the right bank entity?",
      "text": "It seems the connection has failed. Could you check your credentials and make sure you have "
    },
    "success": {
      "CTA": "Transfer your accounts",
      "text": "You’re almost there, you can now select the accounts you would like to transfer.",
      "title": "Your data has been successfully retrieved"
    },
    "synchronization": {
      "subtitle": "Our team is retrieving information from your {bank_name} account. Please leave this tab open as it can take a few minutes.",
      "text": "Retrieving information from your {bank_name} account...",
      "title": "Just a moment while we get information from your bank"
    }
  },
  "optional_cs": {
    "select_method": {
      "main_page": {
        "algorithm_bloc": {
          "argument1": "Let us take care of your account transfer dashboard creation",
          "argument2": "Get notified as soon as your dashboard is ready",
          "argument3": "Access your dashboard and start sharing your new bank details",
          "description": "To save time and be autonomous",
          "primary_cta": "Choose",
          "tag_recommended": "Recommended",
          "title": {
            "1": "A fully automated",
            "2": "dashboard setup"
          }
        },
        "customer_service_bloc": {
          "argument1": "Schedule a call at your convenience",
          "argument2": "Create your account transfer dashboard along with your agent",
          "argument3": "Access your dashboard and start sharing your new bank details",
          "description": "To be accompanied step-by-step",
          "primary_cta": "Choose",
          "title": {
            "1": "A call with a dedicated",
            "2": "Qonto agent"
          }
        },
        "title": "Choose the type of support you need"
      }
    }
  },
  "pdf-preview": {
    "items-links": "{number, plural, =0 {} one {{language, select, fr {Lien :} de {Link:} es {Enlace:} it {Link:} other {Link:}}} other {{language, select, fr {Liens :} de {Links:} es {Enlaces:} it {Link:} other {Links:}}}}",
    "unit": {
      "ampere": "{number, plural, =0 {} one {{language, select, fr {ampère} de {Ampere} es {amperio} it {ampere} other {ampere}}} other {{language, select, fr {ampères} de {Ampere} es {amperios} it {ampere} other {amperes}}}}",
      "centimeter": "{number, plural, =0 {} one {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}} other {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}}}",
      "cubic_meter": "{number, plural, =0 {} one {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}} other {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}}}",
      "cubic_meter_per_hour": "{number, plural, =0 {} one {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}} other {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}}}",
      "day": "{number, plural, =0 {} one {{language, select, fr {jour} de {Tag} es {día} it {giornata} other {day}}} other {{language, select, fr {jours} de {Tage} es {días} it {giornate}  other {days}}}}",
      "gigajoule": "{number, plural, =0 {} one {{language, select, fr {gigajoule} de {Gigajoule} es {gigajulio} it {gigajoule} other {gigajoule}}} other {{language, select, fr {gigajoules} de {Gigajoule} es {gigajulios} it {gigajoule} other {gigajoules}}}}",
      "gigawatt": "{number, plural, =0 {} one {{language, select, fr {gigawatt} de {Gigawatt} es {gigavatio} it {gigawatt} other {gigawatt}}} other {{language, select, fr {gigawatts} de {Gigawatt} es {gigavatios} it {gigawatt} other {gigawatts}}}}",
      "gigawatt_hour": "{number, plural, =0 {} one {{language, select, fr {gigawattheure} de {Gigawattstunde} es {gigavatio-hora} it {gigawattora} other {gigawatt hour}}} other {{language, select, fr {gigawattheures} de {Gigawattstunden} es {gigavatios-hora} it {gigawattora} other {gigawatt hours}}}}",
      "gram": "{number, plural, =0 {} one {{language, select, fr {g} de {g} es {g} it {g} other {g}}} other {{language, select, fr {g} de {g} es {g} it {g} other {g}}}}",
      "half_year": "{number, plural, =0 {} one {{language, select, fr {semestre} de {halbes Jahr} es {semestre} it {semestre} other {half-year}}} other {{language, select, fr {semestres} de {halbe Jahre} es {semestres} it {semestri} other {half-years}}}}",
      "hour": "{number, plural, =0 {} one {{language, select, fr {heure} de {Stunde} es {hora} it {ora} other {hour}}} other {{language, select, fr {heures} de {Stunden} es {horas} it {ore} other {hours}}}}",
      "joule": "{number, plural, =0 {} one {{language, select, fr {joules} de {Joule} es {julio} it {joule} other {joule}}} other {{language, select, fr {joules} de {Joule} es {julios} it {joule} other {joules}}}}",
      "kilogram": "{number, plural, =0 {} one {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}} other {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}}}",
      "kilojoule": "{number, plural, =0 {} one {{language, select, fr {kilojoule} de {Kilojoule} es {kilojulio} it {kilojoule} other {kilojoule}}} other {{language, select, fr {kilojoules} de {Kilojoule} es {kilojulios} it {kilojoule} other {kilojoules}}}}",
      "kilovar": "{number, plural, =0 {} one {{language, select, fr {kilovar} de {Kilovar} es {kilovar} it {kilovar} other {kilovar}}} other {{language, select, fr {kilovars} de {Kilovar} es {kilovares} it {kilovar} other {kilovars}}}}",
      "kilovolt_ampere_reactive_hour": "{number, plural, =0 {} one {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}} other {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}}}",
      "kilowatt": "{number, plural, =0 {} one {{language, select, fr {kilowatt} de {Kilowatt} es {kilovatio} it {chilowatt} other {kilowatt}}} other {{language, select, fr {kilowatts} de {Kilowatt} es {kilovatios} it {chilowatt} other {kilowatts}}}}",
      "kilowatt_hour": "{number, plural, =0 {} one {{language, select, fr {kilowattheure} de {Kilowattstunde} es {kilovatio-hora} it {chilowattora} other {kilowatt hour}}} other {{language, select, fr {kilowattheures} de {Kilowattstunden} es {kilovatios-hora} it {chilowattora} other {kilowatt hours}}}}",
      "liter": "{number, plural, =0 {} one {{language, select, fr {L} de {l} es {l} it {L} other {L}}} other {{language, select, fr {to complete} de {l} es {l} it {L} other {L}}}}",
      "megajoule": "{number, plural, =0 {} one {{language, select, fr {mégajoule} de {Megajoule} es {megajulio} it {megajoule} other {megajoule}}} other {{language, select, fr {mégajoules} de {Megajoule} es {megajulios} it {megajoule} other {megajoules}}}}",
      "megawatt": "{number, plural, =0 {} one {{language, select, fr {mégawatt} de {Megawatt} es {megavatio} it {megawatt} other {megawatt}}} other {{language, select, fr {mégawatts} de {Megawatt} es {megavatios} it {megawatt} other {megawatts}}}}",
      "megawatt_hour": "{number, plural, =0 {} one {{language, select, fr {mégawattheure} de {Megawattstunde} es {megavatio-hora} it {megawattora} other {megawatt hour}}} other {{language, select, fr {mégawattheures} de {Megawattstunden} es {megavatios-hora} it {megawattora} other {megawatt hours}}}}",
      "meter": "{number, plural, =0 {} one {{language, select, fr {m} de {m} es {m} it {m} other {m}}} other {{language, select, fr {m} de {m} es {m} it {m} other {m}}}}",
      "milligram": "{number, plural, =0 {} one {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}} other {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}}}",
      "milliliter": "{number, plural, =0 {} one {{language, select, fr {mL} de {ml} es {ml} it {ml} other {mL}}} other {{language, select, fr {mL} de {ml} es {ml} it {ml} other {mL}}}}",
      "millimeter": "{number, plural, =0 {} one {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}} other {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}}}",
      "minute": "{number, plural, =0 {} one {{language, select, fr {minute} de {Minute} es {minuto} it {minuto} other {minute}}} other {{language, select, fr {minutes} de {Minuten} es {minutos} it {minuti} other {minutes}}}}",
      "month": "{number, plural, =0 {} one {{language, select, fr {mois} de {Monat} es {mes} it {mese} other {month}}} other {{language, select, fr {mois} de {Monate} es {meses} it {mesi} other {months}}}}",
      "number_of_articles": "{number, plural, =0 {} one {{language, select, fr {article} de {Artikel} es {artículo} it {pezzo} other {article}}} other {{language, select, fr {articles} de {Artikel} es {artículos} it {pezzi} other {articles}}}}",
      "pair": "{number, plural, =0 {} one {{language, select, fr {paire} de {Paar} es {par} it {paio} other {pair}}} other {{language, select, fr {paires} de {Paare} es {pares} it {paia} other {pairs}}}}",
      "quarter": "{number, plural, =0 {} one {{language, select, fr {trimestre} de {Viertel} es {trimestre} it {trimestre} other {quarter}}} other {{language, select, fr {trimestres} de {Viertel} es {trimestres} it {trimestri} other {quarters}}}}",
      "second": "{number, plural, =0 {} one {{language, select, fr {seconde} de {Sekunde} es {segundo} it {secondo} other {second}}} other {{language, select, fr {secondes} de {Sekunden} es {segundos} it {secondi} other {seconds}}}}",
      "set": "{number, plural, =0 {} one {{language, select, fr {ensemble} de {Set} es {juego} it {assortimento} other {set}}} other {{language, select, fr {ensembles} de {Sets} es {juegos} it {assortimenti} other {sets}}}}",
      "square_meter": "{number, plural, =0 {} one {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}} other {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}}}",
      "ton": "{number, plural, =0 {} one {{language, select, fr {t} de {t} es {t} it {t} other {T}}} other {{language, select, fr {t} de {t} es {t} it {t} other {T}}}}",
      "two_week": "{number, plural, =0 {} one {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two-week}}} other {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two weeks}}}}",
      "unit": "{number, plural, =0 {} one {{language, select, fr {unité} de {Einheit} es {unidad} it {unità} other {unit}}} other {{language, select, fr {unités} de {Einheiten} es {unidades} it {unità} other {units}}}}",
      "watt_hour": "{number, plural, =0 {} one {{language, select, fr {wattheure} de {Wattstunde} es {vatio-hora} it {wattora} other {watt hour}}} other {{language, select, fr {wattheures} de {Wattstunden} es {vatios-hora} it {wattora} other {watt hours}}}}",
      "week": "{number, plural, =0 {} one {{language, select, fr {semaine} de {Woche} es {semana} it {settimana} other {week}}} other {{language, select, fr {semaines} de {Wochen} es {semanas} it {settimane} other {weeks}}}}",
      "year": "{number, plural, =0 {} one {{language, select, fr {année} de {Jahr} es {año} it {anno} other {year}}} other {{language, select, fr {années} de {Jahre} es {años} it {anni} other {years}}}}"
    }
  },
  "product_tour": {
    "banner": {
      "CTA_primary": "Connect your bank",
      "CTA_secondary": "Replay demo"
    },
    "clients": {
      "action1": {
        "title": "Marketing Agency",
        "transactions": "Payment Marketing Agency"
      },
      "action2": {
        "title": "Bakery",
        "transactions": "Card debit Bakery"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "Qonto Airlines payment"
      },
      "action4": {
        "title": "Occupational Medicine",
        "transactions": "Card payment Medicine"
      },
      "action5": {
        "title": "Supplies",
        "transactions": "Card payment Supplies"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Payment Qonto Technology"
      },
      "action7": {
        "title": "Gas & Electricity",
        "transactions": "Gas & Electricity monthly payment"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "Qonto Telecom subscription"
      }
    },
    "floating_banner": {
      "text": "Ready to transfer your account to Qonto?"
    },
    "overview": {
      "card1": {
        "IBAN": "FR00 1111 2222 3333 4444 5555 000",
        "title": "Previous account"
      },
      "card2": {
        "IBAN": "FR00 6666 7777 8888 9999 1111 000",
        "title": "Main Qonto account"
      },
      "title": "Overview (demo)"
    },
    "suppliers": {
      "action1": {
        "title": "Marketing Agency",
        "transactions": "Payment Marketing Agency"
      },
      "action2": {
        "title": "Bakery",
        "transactions": "Card debit Bakery"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "Qonto Airlines payment"
      },
      "action4": {
        "title": "Occupational Medicine",
        "transactions": "Card payment Medicine"
      },
      "action5": {
        "title": "Supplies",
        "transactions": "Card payment Supplies"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Payment Qonto Technology"
      },
      "action7": {
        "title": "Gas & Electricity",
        "transactions": "Gas & Electricity monthly payment"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "Qonto Telecom subscription"
      }
    }
  },
  "redirect": {
    "continue_button": "Continue",
    "error_message": "To continue, you need to select at least one account.",
    "subtitle": "The transaction history related to this account will be sent to your Qonto agent for review.",
    "title": "Select the accounts you wish to transfer"
  },
  "synchronization": {
    "error_page_1003": {
      "CTA": "Go back to Qonto app",
      "body": "An error prevents us from connecting to your bank. Don’t worry though, our team is already working on it and you’ll receive an email as soon as it’s fixed.",
      "title": "We couldn’t connect to your bank"
    },
    "error_page_402": {
      "CTA_1": "Select another bank",
      "CTA_2": "Need help?",
      "body": "It looks like you’re having trouble connecting your bank to Qonto. Some banks have several entities so you might want to double-check that you chose the right one before trying to connect to Qonto again.",
      "title": "Are you sure you’ve selected the right bank?"
    },
    "error_page_429": {
      "CTA_1": "Continue",
      "CTA_2": "Go back to Qonto app",
      "body_action1": "To continue, you need to agree to the terms and conditions on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action10": "To continue, you need to reactivate the strong authentication on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action11": "To continue, you need to get access to online services by contacting your bank {bank_name}. Then come back to your Qonto app to resume your account transfer.",
      "body_action12": "To continue, you need to contact the customer service of your bank {bank_name}. Then come back to your Qonto app to resume your account transfer.",
      "body_action13": "To continue, you need to replace or activate your credentials by contacting your bank {bank_name}. Then come back to your Qonto app to resume your account transfer.",
      "body_action14": "To continue, you need to modify your password on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action15": "To continue, you need to create a new password by logging in for the first time on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action2": "To continue, you need to sign a contract on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action3": "To continue, you need to activate or enter your credentials on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action4": "To continue, you need to activate your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action5": "To continue, you need to enter or confirm the phone number linked to your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action6": "To continue, you need to validate an information or a message on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action7": "To continue, you need to confirm or complete some personal details on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action8": "To continue, you need to confirm and/or modify your details on your {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "body_action9": "To continue, you need to create a {bank_name} account. Then come back to your Qonto app to resume your account transfer.",
      "checkbox": "By clicking here, I confirm I've done what is required by my bank.",
      "title": "Go to your bank's client portal",
      "toaster": "It looks like there is still an action required by your bank to undertake. Could you verify and try again?"
    }
  },
  "toast": {
    "default_error_message": "An error occurred. Could you try again?",
    "not_available_for_country": "Unfortunately, account transfer is not available (yet) in your country. Stay tuned!"
  },
  "ui-kit": {
    "avatar-input": {
      "default-image-alt-text": "Default image",
      "uploaded-image-alt-text": "Last image you uploaded"
    },
    "btn": {
      "aria-label": {
        "clear": "Clear",
        "download": "Download",
        "next-month": "Next month",
        "preview": "Show",
        "previous-month": "Previous month"
      },
      "cancel": "Cancel",
      "close": "Close",
      "delete": "Delete",
      "delete-aria-label": "Delete"
    },
    "countries": {
      "AC": "Ascension Island",
      "AD": "Andorra",
      "AE": "United Arab Emirates",
      "AF": "Afghanistan",
      "AG": "Antigua & Barbuda",
      "AI": "Anguilla",
      "AL": "Albania",
      "AM": "Armenia",
      "AN": "Netherlands Antilles",
      "AO": "Angola",
      "AQ": "Antarctica",
      "AR": "Argentina",
      "AS": "American Samoa",
      "AT": "Austria",
      "AU": "Australia",
      "AW": "Aruba",
      "AX": "Aland Islands",
      "AZ": "Azerbaijan",
      "BA": "Bosnia & Herzegovina",
      "BB": "Barbados",
      "BD": "Bangladesh",
      "BE": "Belgium",
      "BF": "Burkina Faso",
      "BG": "Bulgaria",
      "BH": "Bahrain",
      "BI": "Burundi",
      "BJ": "Benin",
      "BL": "St. Barthélemy",
      "BM": "Bermuda",
      "BN": "Brunei",
      "BO": "Bolivia",
      "BQ": "Caribbean Netherlands",
      "BR": "Brazil",
      "BS": "Bahamas",
      "BT": "Bhutan",
      "BV": "Bouvet Island",
      "BW": "Botswana",
      "BY": "Belarus",
      "BZ": "Belize",
      "CA": "Canada",
      "CC": "Cocos (Keeling) Islands",
      "CD": "Congo - Kinshasa",
      "CF": "Central African Republic",
      "CG": "Congo - Brazzaville",
      "CH": "Switzerland",
      "CI": "Côte d’Ivoire",
      "CK": "Cook Islands",
      "CL": "Chile",
      "CM": "Cameroon",
      "CN": "China",
      "CO": "Colombia",
      "CP": "Clipperton Island",
      "CR": "Costa Rica",
      "CU": "Cuba",
      "CV": "Cape Verde",
      "CW": "Curaçao",
      "CX": "Christmas Island",
      "CY": "Cyprus",
      "CZ": "Czech Republic",
      "DE": "Germany",
      "DG": "Diego Garcia",
      "DJ": "Djibouti",
      "DK": "Denmark",
      "DM": "Dominica",
      "DO": "Dominican Republic",
      "DZ": "Algeria",
      "EA": "Ceuta & Melilla",
      "EC": "Ecuador",
      "EE": "Estonia",
      "EG": "Egypt",
      "EH": "Western Sahara",
      "ER": "Eritrea",
      "ES": "Spain",
      "ET": "Ethiopia",
      "EU": "European Union",
      "FI": "Finland",
      "FJ": "Fiji",
      "FK": "Falkland Islands",
      "FM": "Micronesia",
      "FO": "Faroe Islands",
      "FR": "France",
      "GA": "Gabon",
      "GB": "United Kingdom",
      "GD": "Grenada",
      "GE": "Georgia",
      "GF": "French Guiana",
      "GG": "Guernsey",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GL": "Greenland",
      "GM": "Gambia",
      "GN": "Guinea",
      "GP": "Guadeloupe",
      "GQ": "Equatorial Guinea",
      "GR": "Greece",
      "GS": "South Georgia & South Sandwich Islands",
      "GT": "Guatemala",
      "GU": "Guam",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HK": "Hong Kong SAR China",
      "HM": "Heard & McDonald Islands",
      "HN": "Honduras",
      "HR": "Croatia",
      "HT": "Haiti",
      "HU": "Hungary",
      "IC": "Canary Islands",
      "ID": "Indonesia",
      "IE": "Ireland",
      "IL": "Israel",
      "IM": "Isle of Man",
      "IN": "India",
      "IO": "British Indian Ocean Territory",
      "IQ": "Iraq",
      "IR": "Iran",
      "IS": "Iceland",
      "IT": "Italy",
      "JE": "Jersey",
      "JM": "Jamaica",
      "JO": "Jordan",
      "JP": "Japan",
      "KE": "Kenya",
      "KG": "Kyrgyzstan",
      "KH": "Cambodia",
      "KI": "Kiribati",
      "KM": "Comoros",
      "KN": "St. Kitts & Nevis",
      "KP": "North Korea",
      "KR": "South Korea",
      "KW": "Kuwait",
      "KY": "Cayman Islands",
      "KZ": "Kazakhstan",
      "LA": "Laos",
      "LB": "Lebanon",
      "LC": "St. Lucia",
      "LI": "Liechtenstein",
      "LK": "Sri Lanka",
      "LR": "Liberia",
      "LS": "Lesotho",
      "LT": "Lithuania",
      "LU": "Luxembourg",
      "LV": "Latvia",
      "LY": "Libya",
      "MA": "Morocco",
      "MC": "Monaco",
      "MD": "Moldova",
      "ME": "Montenegro",
      "MF": "St. Martin",
      "MG": "Madagascar",
      "MH": "Marshall Islands",
      "MK": "Macedonia",
      "ML": "Mali",
      "MM": "Myanmar (Burma)",
      "MN": "Mongolia",
      "MO": "Macau SAR China",
      "MP": "Northern Mariana Islands",
      "MQ": "Martinique",
      "MR": "Mauritania",
      "MS": "Montserrat",
      "MT": "Malta",
      "MU": "Mauritius",
      "MV": "Maldives",
      "MW": "Malawi",
      "MX": "Mexico",
      "MY": "Malaysia",
      "MZ": "Mozambique",
      "NA": "Namibia",
      "NC": "New Caledonia",
      "NE": "Niger",
      "NF": "Norfolk Island",
      "NG": "Nigeria",
      "NI": "Nicaragua",
      "NL": "Netherlands",
      "NO": "Norway",
      "NP": "Nepal",
      "NR": "Nauru",
      "NU": "Niue",
      "NZ": "New Zealand",
      "OM": "Oman",
      "PA": "Panama",
      "PE": "Peru",
      "PF": "French Polynesia",
      "PG": "Papua New Guinea",
      "PH": "Philippines",
      "PK": "Pakistan",
      "PL": "Poland",
      "PM": "St. Pierre & Miquelon",
      "PN": "Pitcairn Islands",
      "PR": "Puerto Rico",
      "PS": "Palestinian Territories",
      "PT": "Portugal",
      "PW": "Palau",
      "PY": "Paraguay",
      "QA": "Qatar",
      "QO": "Outlying Oceania",
      "RE": "Réunion",
      "RO": "Romania",
      "RS": "Serbia",
      "RU": "Russia",
      "RW": "Rwanda",
      "SA": "Saudi Arabia",
      "SB": "Solomon Islands",
      "SC": "Seychelles",
      "SD": "Sudan",
      "SE": "Sweden",
      "SG": "Singapore",
      "SH": "St. Helena",
      "SI": "Slovenia",
      "SJ": "Svalbard & Jan Mayen",
      "SK": "Slovakia",
      "SL": "Sierra Leone",
      "SM": "San Marino",
      "SN": "Senegal",
      "SO": "Somalia",
      "SR": "Suriname",
      "SS": "South Sudan",
      "ST": "São Tomé & Príncipe",
      "SV": "El Salvador",
      "SX": "Saint Martin",
      "SY": "Syria",
      "SZ": "Swaziland",
      "TA": "Tristan da Cunha",
      "TC": "Turks & Caicos Islands",
      "TD": "Chad",
      "TF": "French Southern Territories",
      "TG": "Togo",
      "TH": "Thailand",
      "TJ": "Tajikistan",
      "TK": "Tokelau",
      "TL": "Timor-Leste",
      "TM": "Turkmenistan",
      "TN": "Tunisia",
      "TO": "Tonga",
      "TR": "Türkiye",
      "TT": "Trinidad & Tobago",
      "TV": "Tuvalu",
      "TW": "Taiwan",
      "TZ": "Tanzania",
      "UA": "Ukraine",
      "UG": "Uganda",
      "UM": "U.S. Outlying Islands",
      "US": "United States",
      "UY": "Uruguay",
      "UZ": "Uzbekistan",
      "VA": "Vatican City",
      "VC": "St. Vincent & Grenadines",
      "VE": "Venezuela",
      "VG": "British Virgin Islands",
      "VI": "U.S. Virgin Islands",
      "VN": "Vietnam",
      "VU": "Vanuatu",
      "WF": "Wallis & Futuna",
      "WS": "Samoa",
      "XK": "Kosovo",
      "YE": "Yemen",
      "YT": "Mayotte",
      "ZA": "South Africa",
      "ZM": "Zambia",
      "ZW": "Zimbabwe",
      "ZZ": "Unknown Region"
    },
    "country-select": {
      "label": "Country",
      "no_matches_message": "No results found",
      "placeholder": "Country",
      "search_placeholder": "Type to search"
    },
    "disclaimer": {
      "alt-values": {
        "error": "Error message",
        "info": "Informative message",
        "warning": "Warning message"
      }
    },
    "file-status": {
      "certified": "Certified",
      "not-certified": "Not certified"
    },
    "form-elements": {
      "optional": "(optional)"
    },
    "form-fields": {
      "date-field": {
        "error": {
          "invalid": "This date isn't valid. Can you try again, in DD-MM-YYYY format?"
        },
        "placeholder": "DD-MM-YYYY"
      },
      "date-picker": {
        "clear": {
          "cta": "Clear"
        }
      }
    },
    "fullscreen": {
      "preview": {
        "delete-file": {
          "button-cancel": "Cancel",
          "button-confirm": "Delete",
          "description": "Keep in mind that deleting this file is permanent.",
          "title": "Delete this file?"
        }
      }
    },
    "gender-selector": {
      "female": "Female",
      "label": "Sex on the proof of identity",
      "male": "Male",
      "placeholder": "Gender"
    },
    "icons": {
      "alt-values": {
        "error": "Error message",
        "info": "Informative message",
        "warning": "Warning message"
      }
    },
    "kilobyte": "KB",
    "labels": {
      "choose_all": "Select all",
      "clear_filters": "Deselect all"
    },
    "megabyte": "MB",
    "password_creator": {
      "label": "Password",
      "placeholder": "Enter your password here",
      "set_pwd_rules": "Make sure your password is at least 9 characters long. Avoid your date of birth, your pet's name or even \"0000\" for example.",
      "strength_password_strong": "Great! Your password is < secure enough. >",
      "strength_password_weak": "Your password is < not yet secure enough. >"
    },
    "pdf-preview": {
      "amount-discount": "{language, select, fr {Remise} de {Rabatt} es {Descuento} it {Sconto} other {Discount}}",
      "beneficiary-name": "{language, select, fr {Nom du bénéficiaire} it {Nome e cognome del beneficiario} de {Name des/r Begünstigten} es {Nombre del beneficiario} other {Beneficiary name}}",
      "bic": "BIC",
      "client-steuer-id": "{language, select, fr {Numéro fiscal client} de {Kunden-Steuer-ID} es {Codice fiscale cliente} it {NIF cliente} other {Client tax ID}}",
      "client-ust-udnr": "{language, select, fr {Numéro TVA client} de {Kunden-USt.-IDNr.} es {Número de IVA cliente} it {Partita IVA cliente} other {Client VAT number}}",
      "credit-note": {
        "credit-note-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "credit-note-number": "{language, select, fr {Numéro de l'avoir} it {Numero nota di credito} de {Stornorechnungsnummer} es {Número de factura rectificativa} other {Credit note number}}",
        "invoice-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "reason-credit-note": "{language, select, fr {Motif de l'avoir :} it {Motivo emissione nota di credito:} de {Grund für die Stornorechnung:} es {Motivo de la factura rectificativa:} other {Reason for credit note:}}",
        "related-invoice-number": "{language, select, fr {Numéro de facture associée} it {Numero fattura correlata} de {Zugehörige Rechnungsnummer} es {Número de factura asociada} other {Related invoice number}}",
        "title": "{language, select, fr {Avoir} it {Nota di credito} de {Stornorechnung} es {Factura rectificativa} other {Credit note}}"
      },
      "delivery-address": "{language, select, fr {Adresse de livraison} de {Lieferadresse} es {Dirección de envío} it {Indirizzo di consegna} other {Delivery address}}",
      "district-court": "{language, select, fr {Greffe du tribunal d'arrondissement :} de {Registergericht} es {Juzgado de registro de distrito:} it {Registro del tribunale distrettuale:} other {Registry district court:}}",
      "draft": {
        "invoice-number-draft-value": "--",
        "proforma-disclaimer": "{language, select, it {Il presente documento non costituisce fattura valida ai fini del DPR 633 del 26/10/1972 e successive modifiche. La fattura definitiva verrà emessa all’atto del pagamento del corrispettivo (articolo 6, comma 3, DPR 633/72).} other {This document does not constitute a valid invoice as outlined in the Decree of the President of the Republic (DPR) no. 633 of 26 October 1972 and subsequent amendments. The final invoice will be issued upon payment of the consideration (Article 6, paragraph 3, of the DPR 633/72).}}",
        "title": "{language, select, fr {Facture pro forma} de {Proformarechnung} es {Factura proforma} it {Fattura proforma} other {Pro forma invoice}}"
      },
      "due-date": "{language, select, fr {Date d'échéance} it {Data di scadenza} de {Fälligkeitsdatum} es {Fecha de vencimiento} other {Due date}}",
      "exemption": {
        "AT": {
          "S11.1a": "{language, select, fr {TVA non applicable, article 11 de l'UStG} de {MwSt. entfällt, Art. 11 des UStG} es {IVA no aplicable, art. 11 del UStG} it {IVA non applicabile, art. 11 del UStG} other {VAT not applicable, art. 11 of the UStG}}",
          "S6.1.13": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.16": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.19": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.27": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}"
        },
        "BE": {
          "S21": "{language, select, fr {TVA non applicable, article 21 du BTW-Wetboek} de {MwSt. entfällt, Art. 21 des BTW-Wetboek} es {IVA no aplicable, art. 21 del BTW-Wetboek} it {IVA non applicabile, art. 21 del BTW-Wetboek} other {VAT not applicable, art. 21 of the BTW-Wetboek}}",
          "S39": "{language, select, fr {TVA non applicable, article 39 du BTW-Wetboek} de {MwSt. entfällt, Art. 39 des BTW-Wetboek} es {IVA no aplicable, art. 39 del BTW-Wetboek} it {IVA non applicabile, art. 39 del BTW-Wetboek} other {VAT not applicable, art. 39 of the BTW-Wetboek}}",
          "S39bis": "{language, select, fr {TVA non applicable, article 39bis du BTW-Wetboek} de {MwSt. entfällt, Art. 39bis des BTW-Wetboek} es {IVA no aplicable, art. 39bis del BTW-Wetboek} it {IVA non applicabile, art. 39bis del BTW-Wetboek} other {VAT not applicable, art. 39bis of the BTW-Wetboek}}",
          "S44.1": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.11": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.2": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.4": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S56bis": "{language, select, fr {TVA non applicable, article 56bis du BTW-Wetboek} de {MwSt. entfällt, Art. 56bis des BTW-Wetboek} es {IVA no aplicable, art. 56bis del BTW-Wetboek} it {IVA non applicabile, art. 56bis del BTW-Wetboek} other {VAT not applicable, art. 56bis of the BTW-Wetboek}}"
        },
        "DE": {
          "S13B": "{language, select, fr {Steuerschuldnerschaft des Leistungsempfängers (obligation fiscale du bénéficiaire) (section 13b)} de {Steuerschuldnerschaft des Leistungsempfängers (Abschnitt 13b)} es {Steuerschuldnerschaft des Leistungsempfängers (responsabilidad fiscal del beneficiario) (Sección 13b)} it {Steuerschuldnerschaft des Leistungsempfängers (responsabilità fiscale del beneficiario) (Sezione 13b)} other {Steuerschuldnerschaft des Leistungsempfängers (Tax liability of the beneficiary) (Section 13b)}}",
          "S19.1": "{language, select, fr {Conformément à la section 19, paragraphe 1 de l'UStG, le montant de la facture n'inclut pas de taxe de vente} de {Gemäß Abschnitt 19 Abs. 1 UStG ist im Rechnungsbetrag keine Umsatzsteuer enthalten} es {Según el artículo 19, apartado 1 de la UStG, el importe de la factura no incluye el impuesto sobre las ventas} it {Ai sensi della sezione 19, paragrafo 1, della UStG, l'importo della fattura non comprende l'imposta sulle vendite} other {According to Section 19 Paragraph 1 UStG, the invoice amount does not include sales tax}}",
          "S4": "{language, select, fr {Conformément à la section 4 de l'UStG, aucune taxe sur la valeur ajoutée n'est facturée} de {Gemäß Abschnitt 4 UStG wird keine Mehrwertsteuer erhoben} es {Según el artículo 4 de la UStG, no se cobra ningún impuesto sobre el valor añadido} it {Ai sensi della sezione 4 della UStG, non viene addebitata alcuna imposta sul valore aggiunto} other {In accordance to § 4 UStG, no value added tax is charged}}",
          "S4.1A": "{language, select, fr {Livraison d'exportation exonérée d'impôt, conformément à la section 4 n° 1a de l'UStG} de {Steuerfreie Exportlieferung gemäß Abschnitt 4 Nr. 1a des UStG} es {Entrega para exportación exenta de impuestos según el artículo 4 núm. 1a de la UStG} it {Cessione dell'esportazione esente da imposte ai sensi della sezione. 4 n. 1a UStG} other {Tax-free export delivery in accordance with Section 4 No. 1a UStG}}",
          "S4.1B": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à la section 4 n° 1b de l'UStG} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Abschnitt 4 Nr. 1b des UStG} es {Entrega intracomunitaria exenta de impuestos según el artículo 4 núm. 1b de la UStG} it {Cessione intracomunitaria esente da imposte ai sensi della sezione 4 n. 1b UStG} other {Tax-free intra-community delivery in accordance with Section 4 No. 1b UStG.}}"
        },
        "ES": {
          "S122": "{language, select, fr {Opération exonérée d'impôt avec l'art. 122 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 122 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos en virtud del art. 122 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 122 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 122 of Law 37/1992, on VAT}}",
          "S20": "{language, select, fr {Opération exonérée d'impôt, conformément à l'art. 20 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 20 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por aplicación del art. 20 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 20 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 20 of Law 37/1992, on VAT}}",
          "S21": "{language, select, fr {Exportation exonérée d'impôt avec l'art. 21 de la Loi 37/1992 sur la TVA} de {Steuerfreier Export gemäß Art. 21 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por exportación en virtud del art. 21 de la Ley 37/1992, del IVA} it {Esportazione esente da imposta ai sensi dell'art. 21 della Legge 37/1992 sull'IVA} other {Tax-free export in accordance with art. 21 of Law 37/1992, on VAT}}",
          "S25": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à l'art. 25 de la Loi 37/1992 sur la TVA} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Art. 25 des Gesetzes 37/1992 über die MwSt.} es {Entrega intracomunitaria exenta de impuestos por aplicación del art. 25 de la Ley 37/1992, del IVA} it {Cessione intracomunitaria esente da imposta ai sensi dell'art. 25 della Legge 37/1992 sull'IVA} other {Tax-free intra-community delivery in accordance with art. 25 of Law 37/1992, on VAT}}",
          "S69": "{language, select, fr {Opération située à l'adresse du destinataire, conformément à l'art. 69 de la Loi 37/1992 sur la TVA} de {Ein auf der Anschrift des Empfängers oder der Empfängerin laufender Vorgang gemäß Art. 69 des Gesetzes 37/1992 über die MwSt.} es {Operación localizada en sede del destinatario en virtud del art. 69 de la Ley 37/1992, del IVA} it {Operazione situata all'indirizzo del destinatario ai sensi dell'art. 69 della Legge 37/1992 sull'IVA} other {Operation located at the address of the recipient in accordance with art. 69 of Law 37/1992, on VAT}}",
          "S84.1.2": "{language, select, fr {Reversement de l'assujetti - Art. 84.1.2º de la Loi 37/1992 sur la TVA} de {Umkehrung des Steuerpflichtigen – Art. 84.1.2º des Gesetzes 37/1992 über die MwSt.} es {Inversión de sujeto pasivo - Art. 84.1.2º de la Ley 37/1992, del IVA} it {Storno del soggetto passivo - Art. 84.1.2º della Legge 37/1992 sull'IVA} other {Reversal of taxable person - Art. 84.1.2º of Law 37/1992, on VAT}}"
        },
        "FR": {
          "S259": "{language, select, fr {TVA non applicable, art. 259-1 du CGI} de {MwSt. entfällt, Art. 259-1 des CGI} es {IVA no aplicable, art. 259-1 del CGI} it {IVA non applicabile, art. 259-1 del CGI} other {VAT not applicable, art. 259-1 of the CGI}}",
          "S261": "{language, select, fr {TVA non applicable, art. 261 du CGI} de {MwSt. entfällt, Art. 261 des CGI} es {IVA no aplicable, art. 261 del CGI} it {IVA non applicabile, art. 261 del CGI} other {VAT not applicable, art. 261 of the CGI}}",
          "S262": "{language, select, fr {TVA non applicable, art. 262 du CGI} de {MwSt. entfällt, Art. 262 des CGI} es {IVA no aplicable, art. 262 del CGI} it {IVA non applicabile, art. 262 del CGI} other {VAT not applicable, art. 262 of the CGI}}",
          "S262.1": "{language, select, fr {TVA non applicable, article 262 I du CGI} de {MwSt. entfällt, Art. 262 I des CGI} es {IVA no aplicable, art. 262 I del CGI} it {IVA non applicabile, art. 262 I del CGI} other {VAT not applicable, art. 262 I of the CGI}}",
          "S263": "{language, select, fr {TVA non applicable, art. 263 du CGI} de {MwSt. entfällt, Art. 263 des CGI} es {IVA no aplicable, art. 263 del CGI} it {IVA non applicabile, art. 263 del CGI} other {VAT not applicable, art. 263 of the CGI}}",
          "S283": "{language, select, fr {Autoliquidation, art. 283 du CGI} de {Steuerschuldnerschaft des Leistungsempfängers, Art. 283 des CGI} es {Autoliquidación, art. 283 del CGI} it {Autoliquidazione, art. 283 del CGI} other {Autoliquidation, art. 283 of the CGI}}",
          "S293B": "{language, select, fr {TVA non applicable, art. 293 B du CGI} de {MwSt. entfällt, Art. 263 B des CGI} es {IVA no aplicable, art. 293 B del CGI} it {IVA non applicabile, art. 293 B della CGI} other {VAT not applicable, art. 293 B of the CGI}}"
        },
        "NL": {
          "S11": "{language, select, fr {TVA non applicable, article 11 de l'OB 1968} de {MwSt. entfällt, Art. 11 des OB 1968} es {IVA no aplicable, art. 11 del OB 1968} it {IVA non applicabile, art. 11 del OB 1968} other {VAT not applicable, art. 11 of the OB 1968}}",
          "S12": "{language, select, fr {TVA non applicable, article 12 de l'OB 1968} de {MwSt. entfällt, Art. 12 des OB 1968} es {IVA no aplicable, art. 12 del OB 1968} it {IVA non applicabile, art. 12 del OB 1968} other {VAT not applicable, art. 12 of the OB 1968}}",
          "S25": "{language, select, fr {TVA non applicable, article 25 de l'OB 1968} de {MwSt. entfällt, Art. 25 des OB 1968} es {IVA no aplicable, art. 25 del OB 1968} it {IVA non applicabile, art. 25 del OB 1968} other {VAT not applicable, art. 25 of the OB 1968}}",
          "S9": "{language, select, fr {TVA non applicable, article 9 de l'OB 1968} de {MwSt. entfällt, Art. 9 des OB 1968} es {IVA no aplicable, art. 9 del OB 1968} it {IVA non applicabile, art. 9 del OB 1968} other {VAT not applicable, art. 9 of the OB 1968}}"
        },
        "PT": {
          "S53": "{language, select, fr {TVA non applicable, article 53 du Regime do IVA} de {MwSt. entfällt, Art. 53 des Regime do IVA} es {IVA no aplicable, art. 53 del Regime do IVA} it {IVA non applicabile, art. 53 del Regime do IVA} other {VAT not applicable, art. 53 of the Regime do IVA}}",
          "S9.1-5": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.27.28": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.29.30": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}"
        }
      },
      "footer-organization": "{language, select, fr {{orgaName} avec un capital de {amount}} de {mit einem Kapital von} es {{orgaName} con un capital de {amount}} it {{orgaName} con un capitale di {amount}} other {{orgaName} with a capital of {amount}}}",
      "iban": "IBAN",
      "intermediary-bic": {
        "label": "{language, select, fr {BIC de la banque intermédiaire} de {BIC unserer Korrespondenzbank} es {BIC del banco intermediario} it {BIC della banca corrispondente} other {Intermediary bank BIC}}",
        "value": "{language, select, fr {TRWIBEB3XXX} de {TRWIBEB3XXX} es {TRWIBEB3XXX} it {TRWIBEB3XXX} other {TRWIBEB3XXX}}"
      },
      "invoice-example": {
        "client-address-line1": "18 rue de Navarin",
        "client-city": "Paris",
        "client-countrycode": "DE",
        "client-delivery-address-line1": "18 rue de Navarin",
        "client-delivery-city": "Paris",
        "client-delivery-countrycode": "FR",
        "client-delivery-zipcode": "75009",
        "client-name": "Jane Doe",
        "client-vat-number": "123456789",
        "client-zipcode": "75009",
        "invoice-due-date": "15/01/2030",
        "invoice-issue-date": "01/01/2030",
        "invoice-number": "INV-2030-001",
        "invoice-payment-time": "Please pay the invoice amount within 15 days. (15/01/2030)",
        "invoice-performance-date": "01/01/2030",
        "invoice-total": "120",
        "item-description": "This is the place where the details of your future, real items will go.",
        "item-price": "100",
        "item-quantity": "2",
        "item-title": "My first item title",
        "item-total": "120",
        "item-total-without-vat": "100",
        "item-vat-amount": "0.2",
        "item-vat-percentual": "%",
        "item-vat-total": "20",
        "page-count": "1/1",
        "quote-validated-time": "This quote is valid for 30 days (15/01/2030)"
      },
      "invoice-number": "{language, select, fr {Numéro de facture} it {Numero della fattura} de {Rechnungsnummer} es {Número de factura} other {Invoice number}}",
      "invoice-payment-time": "{number, plural, =0 {{language, select, fr {Veuillez payer la facture à l'émission. ({dueDate})} de {Bitte bezahlen Sie den Betrag direkt nach Erhalt der Rechnung. ({dueDate})} es {Por favor, abona el importe de la factura tras la recepción. ({dueDate})} it {Per favore, paga l’importo della fattura alla sua ricezione. ({dueDate})} other {Please pay the invoice amount upon receipt. ({dueDate})}}} one {{language, select, fr {Veuillez payer la facture sous {days_amount} jour. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tag. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} día. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorno. ({dueDate})} other {Please pay the invoice amount within {days_amount} day. ({dueDate})}}} other {{language, select, fr {Veuillez payer la facture sous {days_amount} jours. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tagen. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} días. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorni. ({dueDate})} other {Please pay the invoice amount within {days_amount} days. ({dueDate})}}}}",
      "issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
      "items": "{language, select, fr {Description} it {Descrizione} de {Beschreibung} es {Descripción} other {Description}}",
      "leadership": "{language, select, fr {Direction de l'entreprise :} de {Geschäftsführung} es {Dirección de la empresa:} it {Direzione dell'impresa:} other {Company leadership:}}",
      "payment-condition": {
        "compensated_for_sales": "{language, select, fr {Sur les débits} de {Im zu zahlenden Betrag enthalten} es {Compensación por ventas} it {Inclusa nel prezzo di vendita} other {Compensated for sales}}",
        "exempt": "{language, select, fr {Exonération} de {Befreit} es {Exento} it {Esente} other {Exempt}}",
        "label": "{language, select, fr {Conditions de paiement de la TVA : } de {MwSt.-Zahlungsbedingung: } es {Condición de pago del IVA: } it {Opzioni per il pagamento dell'IVA: } other {VAT payment condition: }}",
        "on_receipts": "{language, select, fr {Sur les encaissements} de {Sofort} es {Tras la recepción} it {A vista} other {On receipt}}"
      },
      "payment-details": "{language, select, fr {Détails du paiement} it {Dettagli per il pagamento} de {Zahlungsdetails} es {Detalles del pago} other {Payment details}}",
      "pension-contribution": "{language, select, it {Contributo pensione/INPS (IVA escl.)} other {Pension/INPS contribution (excl. VAT)}}",
      "percentage-discount": "{language, select, fr {Remise ({discountPercentage} %)} de {Rabatt ({discountPercentage}%)} es {Descuento ({discountPercentage}%)} it {Sconto ({discountPercentage}%)} other {Discount ({discountPercentage}%)}}",
      "performance-date": "{language, select, fr {Date d'exécution} de {Leistungsdatum} es {Fecha de ejecución} it {Data prestazione} other {Performance date}}",
      "placerholder-stamp-duty": "{language, select, it {Imposta di bollo virtuale pagata ai sensi del decreto ministeriale 17.06.2014} other {Stamp duty paid virtually pursuant to Ministerial Decree 17.06.2014}}",
      "purchase-order": "{language, select, fr {Numéro de bon de commande} it {Numero d'ordine} de {Bestellnummer} es {Número de orden de compra} other {Purchase order number}}",
      "quantity": "{language, select, fr {Qté} it {Qtà} de {Menge} es {Cant.} other {Qty}}",
      "quote-expiry-date": "{language, select, fr {Date d'expiration} it {Data di scadenza} de {Ablaufdatum} es {Fecha de vencimiento} other {Expiry date}}",
      "quote-number": "{language, select, fr {Numéro de devis} it {Numero del preventivo} de {Angebotsnummer} es {Número de presupuesto} other {Quote number}}",
      "quote-related-number": "{language, select, fr {Numéro du devis associé } it {Numero di preventivo correlato } de {Zugehöriges Angebot } es {Número de presupuesto asociado } other {Related quote number }}",
      "quote-title": "{language, select, fr {Devis} de {Angebot} es {Presupuesto} it {Preventivo} other {Quote}}",
      "quote-validated-time": "{number, plural, =0 {} one {{language, select, fr {Ce devis est valable {days_amount} jour. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tag. ({expiry_date})} es {Este presupuesto es válido por {days_amount} día. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorno. ({expiry_date})} other {This quote is valid for {days_amount} day. ({expiry_date})}}} other {{language, select, fr {Ce devis est valable {days_amount} jours. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tage. ({expiry_date})} es {Este presupuesto es válido por {days_amount} días. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorni. ({expiry_date})} other {This quote is valid for {days_amount} days. ({expiry_date})}}}}",
      "reference": "{language, select, fr {Référence} it {Causale} de {Referenz} es {Referencia} other {Reference}}",
      "revenue-stamp": "{language, select, it {Marca da bollo} other {Revenue stamp}}",
      "steuer-id": "{language, select, fr {Steuer-ID} de {Steuer-ID} es {Steuer-ID} it {Steuer-ID} other {Steuer-ID}}",
      "steuernummer": "{language, select, fr {Steuernummer :} de {Steuernummer:} es {Steuernummer:} it {Steuernummer:} other {Steuernummer:}}",
      "title": "{language, select, fr {Facture} de {Rechnung} es {Factura} it {Fattura} other {Invoice}}",
      "total": "{language, select, fr {Total} it {Totale} de {Gesamt} es {Total} other {Total}}",
      "total-vat": "{language, select, fr  {Total TTC} it {Totale (IVA inclusa)}  de {Gesamt (inkl. MwSt.)} es {Total (IVA incluido)} other {Total incl. VAT}}",
      "total-vat-amount": "{language, select, fr {Montant total de la TVA} it {Importo totale IVA} de {MwSt.-Betrag insgesamt} es {Importe total del IVA} other {Total VAT amount}}",
      "total-without-vat": "{language, select, fr {Total HT} it {Totale (IVA escl.)} de {Gesamt (exkl. MwSt.)} es {Total (IVA no incluido)} other {Total excl. VAT}}",
      "transaction-type": {
        "goods": "{language, select, fr {Biens} de {Waren} es {Bienes} it {Beni} other {Goods}}",
        "goods_and_services": "{language, select, fr {Biens et services} de {Waren und Dienstleistungen} es {Bienes y servicios} it {Beni e servizi} other {Goods and services}}",
        "label": "{language, select, fr {Type de transaction : } de {Transaktionstyp: } es {Tipo de transacción: } it {Tipo di transazione: } other {Transaction type: }}",
        "services": "{language, select, fr {Services} de {Dienstleistungen} es {Servicios} it {Servizi} other {Services}}"
      },
      "unit-price": "{language, select, fr {Prix unitaire} it {Prezzo unitario} de {Preis pro Einheit} es {Precio unitario} other {Unit price}}",
      "ust-udnr": "{language, select, fr {USt-IDNr.} de {USt-IDNr.} es {USt-IDNr.} it {USt-IDNr.} other {USt-IDNr.}}",
      "vat": "{language, select, fr {TVA (%)} it {IVA (%)} de {MwSt. (%)} es {IVA (%)} other {VAT (%)}}",
      "vat-amount": "{language, select, fr {Montant de la TVA} it {Importo IVA} de {MwSt-Betrag} es {Importe del IVA} other {VAT amount}}",
      "vat-number": "{language, select, fr {Numéro de TVA :} de {USt.-IdNr.:} es {Número de IVA:} it {Partita IVA:} other {VAT number:}}",
      "withholding-tax": "{language, select, it {Ritenuta d'acconto} other {Withholding tax}}"
    },
    "spinner": {
      "label": "Loading"
    },
    "stepper-header": {
      "back": "Back",
      "close": "Close"
    },
    "translation-with-args": {
      "body-1": "Text with {var} inside.",
      "body-3": "Text with {var1} inside and then another {var2} inside and then another {var3} inside."
    },
    "zxcvbn": {
      "password_common": "This password is too common, and therefore easy to guess.",
      "password_date": "Dates should be avoided as they are used too often.",
      "password_dictionary": "This password is too common, and therefore easy to guess.",
      "password_repeat": "Repetitions of words or letters are not secure enough.",
      "password_sequence": "Logical sequences, such as \"1234\" for example, are easy to guess.",
      "password_spatial": "Keyboard sequences, such as \"qwerty\" for example, are not secure enough."
    }
  },
  "welcome": {
    "gdpr_checkbox": "I agree to the use of my personal data by Qonto",
    "gdpr_checkbox_error_message": "To continue, you must consent to our data use policy.",
    "redirect_to_appointment_page": "Ask one of our experts for more information",
    "redirect_to_bridge": "Start the transfer",
    "step_1_subtitle": "Using the secure service Bridge by Bankin'.",
    "step_1_title": "Connect your bank to Qonto",
    "step_2_subtitle": "Your data will be synchronized securely.",
    "step_2_title": "Choose which account(s) to transfer",
    "step_3_subtitle": "To answer all your questions.",
    "step_3_title": "A Qonto agent will guide you step by step",
    "subtitle": "And optimize your financial management.",
    "title": "Transfer your accounts without the hassle"
  }
}], ["es", {
  "application": {
    "back_to_app": "Volver a Qonto",
    "go_back": "Atrás",
    "security_disclaimer": "Este servicio es seguro. Todos los datos están estrictamente reservados a Qonto y pueden eliminarse en cualquier momento si el usuario lo solicita.",
    "security_disclaimer_link": "Más información",
    "title": "Migración de cuenta"
  },
  "appointment": {
    "back_cta": "Atrás",
    "calendar_subtitle": "Pide cita con un asesor de Qonto para preparar la migración de tu cuenta.",
    "calendar_title": "Conoce a tu asesor de Qonto"
  },
  "bank_selection": {
    "consent_modal": {
      "checkbox_text": "Autorizo a Qonto a hacer uso de mis datos personales.",
      "primary_CTA": "Confirmar",
      "secondary_CTA": "Cancelar",
      "subtitle": "Por razones jurídicas, debes aceptar nuestra política de utilización de datos antes de conectar tu banco a Qonto, a través del servicio seguro Bridge by Bankin’.",
      "title": "Danos tu consentimiento"
    },
    "select_child_bank": {
      "back_CTA": "Atrás",
      "individual": "Cuenta personal",
      "pro": "Cuenta profesional",
      "subtitle": "{bank_name} tiene varias entidades. Comprueba que seleccionas la correcta. Si tienes dudas, te aconsejamos que contactes con tu banco.",
      "title": "Selecciona tu entidad bancaria"
    },
    "select_parent_bank": {
      "cannot_find": "¿Tu banco no aparece en la lista?",
      "search_empty": {
        "CTA": "Contactarnos",
        "subtitle": "Indícanos el banco que falta a través de nuestro chat. Haremos lo posible para ayudarte a migrar tu(s) cuenta(s).",
        "title": "Este banco (aún) no está registrado"
      },
      "search_placeholder": "Buscar un banco...",
      "title": "Selecciona tu banco"
    }
  },
  "bankswitch": {
    "backend_error": {
      "toaster": "Se ha producido un error. ¿Puedes recargar la página?"
    }
  },
  "btn": {
    "back": "Atrás"
  },
  "choose_organization": {
    "continue_button": "Continuar",
    "error_message": "Selecciona una empresa para continuar.",
    "subtitle": "Selecciona una empresa para migrar tu cuenta.",
    "title": "Selecciona una empresa"
  },
  "dashboard": {
    "CTA": {
      "new_action": "Crear una acción"
    },
    "account_details": {
      "bic": "BIC",
      "disclaimer": "De momento, solo puedes migrar a un único banco, pero la multibanca llegará pronto.",
      "iban": "IBAN",
      "no_iban": "Encontrarás tu IBAN en el espacio para clientes de tu banco.",
      "no_iban_link": "Accede a tu IBAN en Qonto.",
      "notifications": {
        "bic_copy": "¡Tu BIC se ha copiado!",
        "iban_copy": "¡Tu IBAN se ha copiado!"
      }
    },
    "action": {
      "important": {
        "tag": "Importante"
      },
      "menu": {
        "CTA_delete": "Eliminar",
        "CTA_edit": "Editar",
        "CTA_flag_as_important": "Marcar como importante",
        "CTA_ignore": "Ignorar",
        "CTA_mark_as_done": "Marcar como realizado",
        "CTA_unflag_as_important": "Marcar como no importante",
        "CTA_unignore": "Restablecer",
        "CTA_unmark_as_done": "Marcar como no realizado"
      }
    },
    "card": {
      "action": {
        "ignored": "Ignorar",
        "reactive": "Reactivar"
      },
      "category": {
        "cards": "Tarjetas",
        "mandates": "Mandatos",
        "others": "Otros",
        "transfers": "Transferencias"
      },
      "last_operation": "Última:",
      "last_operation_detected": "Última operación detectada:",
      "operations": "{count, plural,=1 {Operación:} other {Operaciónes:}}"
    },
    "create_action": {
      "toaster": "Se ha creado una nueva acción."
    },
    "create_form": {
      "CTA_cancel": "Cancelar",
      "CTA_create": "Crear",
      "category_field": {
        "placeholder": "Selecciona una categoría",
        "title": "Categoría"
      },
      "category_list": {
        "atm": "Cajero automático",
        "fees": "Comisiones",
        "finance": "Finanzas",
        "hotel_and_lodging": "Hoteles y alojamiento",
        "insurance": "Seguro",
        "legal_and_accounting": "Legal y contable",
        "logistics": "Logística",
        "marketing": "Marketing",
        "office_rental": "Alquiler de oficina",
        "office_supply": "Suministros de oficina",
        "online_service": "Servicios online",
        "other": "Otro",
        "pending": "Pendiente",
        "restaurant_and_bar": "Bares y restaurantes",
        "salary": "Salarios",
        "sales": "Ventas",
        "tax": "Impuestos",
        "transport": "Transporte",
        "utility": "Luz/Agua/Gas"
      },
      "note_field": {
        "placeholder": "Añade una nota",
        "title": "Información adicional"
      },
      "title": {
        "placeholder": "Nueva tarea"
      },
      "title_field": {
        "placeholder": "Da un nombre a esta tarea",
        "title": "Nombre"
      },
      "type_field": {
        "placeholder": "Elige un medio de pago",
        "title": "Método"
      }
    },
    "credit": {
      "no_actions": {
        "subtitle": "No hay ninguna acción pendiente en esta sección. Puedes entrar en la sección Débitos para ver si hay alguna.",
        "title": "Ningún cliente recurrente"
      },
      "sections": {
        "all": {
          "methods": "Todos los métodos"
        },
        "cards": "Tarjetas",
        "cards_tooltip": "Guarda tu nueva tarjeta Qonto con estos acreedores y deudores.",
        "done": "Hecho",
        "ignored": "Ignorado",
        "others": "Otros",
        "others_tooltip": "Estas acciones tienen que tratarse de forma individual. Contacta con tu asesor de Qonto si necesitas ayuda.",
        "todo": "Pendiente",
        "transfers": "Transferencias",
        "transfers_tooltip": "Añade estos beneficiarios a tu cuenta Qonto."
      },
      "subtitle": "Comparte tus nuevos datos bancarios con tus clientes y colaboradores para recibir tus primeros pagos en la cuenta Qonto.",
      "title": "Clientes recurrentes"
    },
    "debit": {
      "no_actions": {
        "subtitle": "No hay ninguna acción pendiente en esta sección. Puedes entrar en la sección Débitos para ver si hay alguna.",
        "title": "Ningún proveedor recurrente"
      },
      "sections": {
        "all": {
          "methods": "Todos los métodos"
        },
        "cards": "Tarjetas",
        "cards_tooltip": "Guarda tu nueva tarjeta Qonto con estos proveedores.",
        "done": "Hecho",
        "ignored": "Ignorado",
        "mandates": "Mandatos",
        "mandates_tooltip": "Cancela tus mandatos actuales y emite otros nuevos con tu IBAN Qonto.",
        "others": "Otros",
        "others_tooltip": "Estas acciones tienen que tratarse de forma individual. Contacta con tu asesor de Qonto si necesitas ayuda.",
        "todo": "Pendiente",
        "transfers": "Transferencias",
        "transfers_tooltip": "Añade estos beneficiarios a tu cuenta Qonto."
      },
      "subtitle": "Comparte tus nuevos datos bancarios con tus proveedores para que tus próximos pagos se ejecuten con normalidad.",
      "title": "Proveedores recurrentes"
    },
    "delete_action": {
      "modal": {
        "CTA_cancel": "Cancelar",
        "CTA_delete": "Eliminar",
        "body": "Ten presente que la eliminación de esta acción es definitiva.",
        "title": "¿Eliminar esta acción?"
      },
      "toaster": "Esta acción ha sido eliminada."
    },
    "edit_action": {
      "toaster": "Esta acción ha sido modificada."
    },
    "edit_form": {
      "CTA_cancel": "Cancelar",
      "CTA_save": "Guardar los cambios",
      "category_field": {
        "placeholder": "Selecciona una categoría",
        "title": "Categoría"
      },
      "category_list": {
        "atm": "Cajero automático",
        "fees": "Comisiones",
        "finance": "Finanzas",
        "hotel_and_lodging": "Hoteles y alojamiento",
        "insurance": "Seguro",
        "legal_and_accounting": "Legal y contable",
        "logistics": "Logística",
        "marketing": "Marketing",
        "office_rental": "Alquiler de oficina",
        "office_supply": "Suministros de oficina",
        "online_service": "Servicios online",
        "other": "Otro",
        "pending": "Pendiente",
        "restaurant_and_bar": "Bares y restaurantes",
        "salary": "Salarios",
        "sales": "Ventas",
        "tax": "Impuestos",
        "transport": "Transporte",
        "utility": "Luz/Agua/Gas"
      },
      "note_field": {
        "placeholder": "Añade una nota",
        "title": "Información adicional"
      },
      "title_field": {
        "placeholder": "Da un nombre a esta tarea",
        "title": "Nombre"
      },
      "type_field": {
        "placeholder": "Elige un medio de pago",
        "title": "Método"
      }
    },
    "operation_history": {
      "back_to_credits_cta": "Volver a Clientes",
      "back_to_debits_cta": "Volver a Proveedores",
      "cancel_cta": "Marcar como no realizado",
      "check_cta": "Marcar como realizado",
      "ignore_cta": "Ignorar",
      "reactive_cta": "Restablecer",
      "subtitle": {
        "credit": "{count, plural,=1 {{count} operacion está asociada a este cliente} other {{count} operaciones están asociadas a este cliente}}",
        "debit": "{count, plural,=1 {{count} operacion está asociada a este proveedor} other {{count} operaciones están asociadas a este proveedor}}"
      },
      "table_field": {
        "amount": "Importe",
        "date": "Fecha",
        "label": "Transacción"
      }
    },
    "overview": {
      "cards": {
        "dropdown": {
          "title": "Todas las cuentas"
        },
        "last_update": {
          "day": "Actualizado hace {count} {count, plural, =1 {día} other {días}}",
          "month": "Actualizado hace {count} {count, plural, =1 {mes} other {meses}}",
          "year": "Actualizado hace {count} {count, plural, =1 {año} other {años}}"
        }
      },
      "faq": {
        "first_article": {
          "description": "Descubre la agregación bancaria y aprende a sincronizar tu antiguo banco con Qonto.",
          "link": "https://help.qonto.com/es/articles/5020434",
          "title": "¿Cómo funciona el servicio de migración de cuenta?"
        },
        "second_article": {
          "description": "Lee los consejos de nuestros expertos para cambiar de banco fácilmente.",
          "link": "https://help.qonto.com/es/articles/5145573",
          "title": "¿Cómo superviso la migración de mi cuenta?"
        },
        "title": "Empezar la migración de cuenta"
      },
      "popin": {
        "end_migration_completed": {
          "cta": {
            "cancel": "Cancelar",
            "end_transfer": "Confirmar"
          },
          "title": "Confirma que la transferencia de cuenta ha terminado",
          "wording": "Tu transferencia de cuenta del {bank_name} a Qonto para {company_name} va a terminar. Ten en cuenta que todos los datos relativos a tu antigua cuenta se suprimirán."
        },
        "end_migration_not_completed": {
          "cta": {
            "cancel": "Cancelar",
            "end_transfer": "Terminar la migración"
          },
          "title": "Terminar tu migración de cuenta",
          "wording": "¿Estás seguro? Se detendrá tu migración de cuenta de {bank_name} a Qonto para {company_name} y se borrarán tus datos. Si cambias de opinión, puedes comenzar una nueva migración de cuenta en cualquier momento."
        }
      },
      "stepper": {
        "end_migration": "Terminar la migración",
        "file_link": "Descargar la guía",
        "step1": {
          "progress_bar": {
            "completed": "Completado",
            "inprogress": "En proceso"
          },
          "subtitle1": {
            "description": "Para asegurar el procesamiento de tus próximos pagos.",
            "title": "Proveedores"
          },
          "subtitle2": {
            "description": "Para recibir dinero en tu cuenta Qonto.",
            "title": "Clientes"
          },
          "title": "Comparte tus nuevos datos bancarios con tus proveedores y clientes"
        },
        "step2": {
          "subtitle": "Consulta nuestra guía para más información sobre las etapas que debes completar.",
          "title": "Prepara el cierre de tu antigua cuenta"
        },
        "step3": {
          "subtitle": "Confirma que tu transferencia de cuenta ha terminado una vez completadas las etapas precedentes.",
          "title": "Terminar la migración"
        },
        "step_link": {
          "completed": "Ver detalles",
          "uncompleted": "{count} {count, plural,=1 {acción pendiente} other {acciones pendientes}}"
        },
        "title": "Estado de la migración"
      },
      "subtitle": "En este panel de control encontrarás toda la información relativa a la migración de tu cuenta.",
      "title": "Supervisa el avance de la migración de cuenta"
    },
    "side": {
      "credit": "Clientes",
      "debit": "Proveedores",
      "overview": "Panel de control",
      "settings": "Ajustes",
      "settings_items": {
        "add_accounts": "Añadir otra cuenta",
        "back_to_app": "Volver a Qonto",
        "ends_transfer_cta": "Terminar la migración",
        "support": "Ayuda",
        "support_link": "https://help.qonto.com/es/",
        "switch_organization": "Cambiar de empresa"
      }
    }
  },
  "dashboard_generation": {
    "waiting_screen": {
      "description": "Nuestro equipo está analizando y creando el listado de tus transacciones recurrentes. Esto puede durar <b>de 4 a 10 minutos</b>, pero no te preocupes, no tienes que esperar. Recibirás un correo electrónico cuando el panel de control esté listo.",
      "disclaimer": {
        "part_1": "Este servicio es seguro. Todos los datos están estrictamente reservados a Qonto y pueden eliminarse en cualquier momento si el usuario lo solicita.",
        "part_2": "Más información"
      },
      "title": "Tu panel de control ha sido creado"
    }
  },
  "dynamic-values": {
    "dates": {
      "current-month": "%mes%",
      "current-year": "%año%",
      "last-month": "%mesanterior%",
      "next-month": "%messiguiente%"
    }
  },
  "empty-state-discover": {
    "divider": "o"
  },
  "ends_transfer": {
    "confirmation_modal": {
      "cancel_cta": "Cancelar",
      "confirm_cta": "Termina la migración",
      "subtitle": "¿Estás seguro? Se detendrá tu migración de cuenta de {bank_name} a Qonto para {company_name} y se borrarán tus datos. Si cambias de opinión, puedes comenzar una nueva migración de cuenta en cualquier momento.",
      "title": "Terminar"
    }
  },
  "error_1010": {
    "disclaimer": {
      "body": "Tus datos no están actualizados: debes volver a indicar tu código de confirmación recibido por SMS",
      "link": "justo aquí"
    }
  },
  "error_429": {
    "disclaimer": {
      "body_action1": "Tus datos no están actualizados: debes aceptar las condiciones generales en tu cuenta {bank_name}.",
      "body_action10": "Tus datos no están actualizados: debes reactivar la autenticación segura en tu cuenta {bank_name}.",
      "body_action11": "Tus datos no están actualizados: debes obtener el acceso a los servicios en línea contactando con tu banco {bank_name}.",
      "body_action12": "Tus datos no están actualizados: debes contactar con el servicio de atención al cliente de tu banco {bank_name}.",
      "body_action13": "Tus datos no están actualizados: debes reemplazar o activar tus credenciales contactando con tu banco {bank_name}.",
      "body_action14": "Tus datos no están actualizados: debes modificar tu contraseña en tu cuenta {bank_name}.",
      "body_action15": "Tus datos no están actualizados: debes crear una nueva contraseña conectándote por primera vez a tu cuenta {bank_name}.",
      "body_action2": "Tus datos no están actualizados: debes firmar un contrato en tu cuenta {bank_name}.",
      "body_action3": "Tus datos no están actualizados: debes activar o indicar tus credenciales en tu cuenta {bank_name}.",
      "body_action4": "Tus datos no están actualizados: debes activar tu cuenta {bank_name}.",
      "body_action5": "Tus datos no están actualizados: debes indicar o confirmar el número de teléfono asociado a tu cuenta {bank_name}.",
      "body_action6": "Tus datos no están actualizados: debes validar la información o el mensaje de tu cuenta {bank_name}.",
      "body_action7": "Tus datos no están actualizados: debes confirmar o completar tus datos personales en tu cuenta {bank_name}.",
      "body_action8": "Tus datos no están actualizados: debes confirmar y/o modificar tus datos en tu cuenta {bank_name}.",
      "body_action9": "Tus datos no están actualizados: debes crear una cuenta {bank_name}."
    }
  },
  "error_430": {
    "disclaimer": {
      "body": "Tus datos no están actualizados: debes modificar tu contraseña en tu cuenta {bank_name}."
    }
  },
  "error_management_screen": {
    "generic_fallback_error": {
      "description": "Se ha producido un error en la creación automática del panel de control. Reserva una llamada con un asesor de Qonto para obtener ayuda.",
      "primary_cta": "Reservar una llamada",
      "title": "Tu panel de control no se ha podido crear"
    }
  },
  "information_appointment": {
    "calendar_subtitle": "Pide una cita para preparar la migración de tu cuenta con un asesor especializado.",
    "calendar_title": "Conoce a tu asesor de Qonto"
  },
  "labels": {
    "pagination_summary": "en"
  },
  "logout": {
    "btn": "Vuelve a la pantalla de inicio",
    "modal": {
      "accept_btn": "Seguir conectado",
      "close_btn": "Desconectarme",
      "content": "Por motivos de seguridad tu sesión expirará en 5 minutos y serás automáticamente desconectado. \nPuedes prolongar el tiempo de conexión clicando aquí abajo.",
      "title": "¿Sigues aquí?"
    },
    "subtitle": "Por seguridad, las sesiones expiran en 30 minutos. Te has desconectado automáticamente.",
    "title": "Te has desconectado."
  },
  "maintenance": {
    "cta": "Volver a Qonto",
    "subtitle": "Parece que hay un problema. No te preocupes, nuestro equipo hará todo lo posible para solucionarlo cuanto antes. Mientras tanto, contacta con tu asesor de Qonto si necesitas ayuda.",
    "title": "Ha habido un error"
  },
  "migration_status": {
    "appointment_successfully_made_redirection_toast": "Tu cita se ha confirmado.",
    "end_migration": "Terminar la migración",
    "step_1_subtitle": "Para generar un plan de migración personalizado.",
    "step_1_title": "Tus transacciones se están analizando",
    "step_2_subtitle": "Para hablar de tu plan de migración de cuenta personalizado.",
    "step_2_title": "Concierta una primera entrevista con tu asesor de Qonto",
    "step_3_subtitle": "Recibirás actualizaciones y recordatorios en cada etapa",
    "step_3_title": "Estamos gestionando la migración de tu cuenta",
    "step_4_subtitle": "¡Todo listo! Empieza a gestionar tu cuenta de empresa desde la app de Qonto.",
    "step_4_title": "¡La migración de tu cuenta se ha completado!",
    "subtitle": "La migración de tu cuenta se ha iniciado. Sigue de cerca su progresión aquí.",
    "title": "Supervisa el avance de la migración de cuenta"
  },
  "migration_succeeded": {
    "back_to_qonto": "Acceder a mi cuenta Qonto",
    "end_migration": {
      "file_link": "Descargar la guía",
      "title1": "Finalizar el cierre",
      "title2": "de tu antigua cuenta",
      "wording": "¿Has cerrado tu antigua cuenta? Consulta nuestra guía para más información sobre las etapas que debes completar."
    },
    "subtitle": "Ya puedes disfrutar de todas las funcionalidades exclusivas de Qonto. Si tienes cualquier duda, puedes ponerte en contacto con nosotros.",
    "switch_another_bank": "Migrar otra cuenta a Qonto",
    "title": "¡La migración de tu cuenta se ha completado!"
  },
  "no_mobile": {
    "back_to_qonto": "Volver a Qonto",
    "subtitle": "De momento, este servicio está disponible solo desde un ordenador.",
    "title": "Hazlo desde la aplicación web"
  },
  "no_rights": {
    "subtitle": "Solo los perfiles Admin pueden hacer una migración de cuenta.",
    "title": "No tienes acceso a este servicio"
  },
  "not_found": {
    "cta": "Volver a Qonto",
    "subtitle": "No hemos encontrado la página que estabas buscando. Esto puede ocurrir por un error en la URL introducida o porque hemos cambiado o eliminado la página.",
    "title": "No hemos encontrado la página"
  },
  "onboarding_funnel": {
    "OTP_modal": {
      "code": "Código",
      "error_text": "Código inválido",
      "link": "¿No has recibido el código? Enviar otra.",
      "primary_CTA": "Cancelar",
      "secondary_CTA": "Confirmar",
      "text": "Introduce el código de verificación temporal que has recibido por SMS.",
      "title": "Introduce tu código de verificación"
    },
    "app_modal": {
      "link": "¿Necesitas ayuda?",
      "text": "Para continuar, debes confirmar la conexión en la aplicación móvil de tu banco.",
      "title": "Coge tu teléfono"
    },
    "consent": {
      "CTA_1": "Cancelar",
      "CTA_2": "Confirmar",
      "checkbox": {
        "first": "Al hacer clic aquí, acepto los términos y condiciones de este servicio y confirmo que he leído ",
        "link": "la política de protección de datos de Qonto.",
        "second": ""
      },
      "text": "Qonto te permite conectarte a tu cuenta Qonto, a todas tus cuentas bancarias y a las entidades de pago abiertas en establecimientos de terceros.<br>La ACPR (Autoridad francesa de supervisión prudencial y de resolución) autoriza a Qonto a proporcionarte este servicio de información y agregación financiera y se apoya en las infraestructuras de Bankin'.<br>Sin embargo, no podemos garantizar el acceso a todas tus cuentas.<br><br>Tus datos bancarios y personales están protegidos conforme a la normativa europea (Directiva que regula los servicios de pago y la normativa general sobre la protección de datos personales).",
      "title": "La protección de tus datos es muy importante para Qonto"
    },
    "credentials": {
      "4digitcode_title": "Código de 4 dígitos",
      "CTA": "Iniciar sesión",
      "accesscode_title": "Código de acceso",
      "accountnumber_placeholder": "Introduce tu número de cuenta",
      "accountnumber_title": "Número de cuenta",
      "answer_placeholder": "Introduce tu respuesta",
      "answer_title": "Respuesta a la pregunta de seguridad",
      "birthdate_placeholder": "DD/MM/AAAA",
      "birthdate_title": "Fecha de nacimiento",
      "checkbox": "Declaro por mi honor que estoy autorizado y poseo las autorizaciones necesarias para acceder a la información contable.",
      "clientnumber_placeholder": "Introduce tu número de cliente",
      "clientnumber_title": "Número de cliente",
      "code_placeholder": "Introduce tu código de usuario",
      "code_title": "Código de usuario",
      "companynumber_placeholder": "Introduce el número de ID de tu empresa",
      "companynumber_title": "Número de ID de tu empresa",
      "confidentialcode_title": "Código confidencial",
      "email_placeholder": "sonia.ruiz@qonto.com",
      "email_title": "Correo electrónico",
      "id_placeholder": "Introduce tu número de ID",
      "id_title": "Número de ID",
      "password_placeholder": "Introduce tu contraseña",
      "password_title": "Contraseña",
      "phonenumber_placeholder": "+34 123 456 789",
      "phonenumber_title": "Número de teléfono con código de país (+34...)",
      "servercode_title": "Código del servidor",
      "subscriptionnumber_placeholder": "Introduce tu número de subscriptor",
      "subscriptionnumber_title": "Número de subscriptor",
      "text": "Introduce las mismas credenciales de conexión que utilizas generalmente en la página de {bank_name}.<br/>No te preocupes, ya que Qonto no guardará esta información.",
      "title": "Conéctate a {bank_name}",
      "usernumber_placeholder": "Introduce tu número de ID",
      "usernumber_title": "Número de ID"
    },
    "error_1005": {
      "primary_CTA": "Reservar una llamada",
      "secondary_CTA": "Volver a Qonto",
      "text": "Un error nos impide conectarnos a tu banco. No te preocupes, un asesor Qonto te ayudará a resolver el problema y te acompañará durante la migración.",
      "title": "La conexión con {bank_name} ha fallado"
    },
    "error_402": {
      "link": "elegido la entidad bancaria correcta.",
      "text": "Puede que haya habido un error de conexión. Comprueba tus credenciales y asegúrate de que has "
    },
    "success": {
      "CTA": "Transferir tus cuentas",
      "text": "Ya casi terminaste. Puedes seleccionar las cuentas que desees transferir.",
      "title": "Tus datos han sido recuperados correctamente"
    },
    "synchronization": {
      "subtitle": "Nuestro equipo está recuperando información de tu cuenta {bank_name}... La operación puede llevar unos minutos. No cierres la pestaña.",
      "text": "Recuperar información de tu cuenta {bank_name}...",
      "title": "Un instante, estamos recuperando información de tu banco"
    }
  },
  "optional_cs": {
    "select_method": {
      "main_page": {
        "algorithm_bloc": {
          "argument1": "Nos encargamos de la creación de tu panel",
          "argument2": "Te avisamos cuando tu panel esté listo",
          "argument3": "Accede a tu panel y comparte tus nuevos datos bancarios",
          "description": "Para ahorrar tiempo y tener autonomía",
          "primary_cta": "Elegir",
          "tag_recommended": "Recomendado",
          "title": {
            "1": "Una configuración del",
            "2": "panel automatizada"
          }
        },
        "customer_service_bloc": {
          "argument1": "Reserva una llamada cuando te convenga",
          "argument2": "Crea el panel de migración de cuenta con tu asesor",
          "argument3": "Accede a tu panel y comparte tus nuevos datos bancarios",
          "description": "Para acompañarte en todas las etapas",
          "primary_cta": "Elegir",
          "title": {
            "1": "Llamada con un asesor",
            "2": "Qonto"
          }
        },
        "title": "Elige el tipo de soporte que más te convenga"
      }
    }
  },
  "pdf-preview": {
    "items-links": "{number, plural, =0 {} one {{language, select, fr {Lien :} de {Link:} es {Enlace:} it {Link:} other {Link:}}} other {{language, select, fr {Liens :} de {Links:} es {Enlaces:} it {Link:} other {Links:}}}}",
    "unit": {
      "ampere": "{number, plural, =0 {} one {{language, select, fr {ampère} de {Ampere} es {amperio} it {ampere} other {ampere}}} other {{language, select, fr {ampères} de {Ampere} es {amperios} it {ampere} other {amperes}}}}",
      "centimeter": "{number, plural, =0 {} one {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}} other {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}}}",
      "cubic_meter": "{number, plural, =0 {} one {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}} other {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}}}",
      "cubic_meter_per_hour": "{number, plural, =0 {} one {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}} other {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}}}",
      "day": "{number, plural, =0 {} one {{language, select, fr {jour} de {Tag} es {día} it {giornata} other {day}}} other {{language, select, fr {jours} de {Tage} es {días} it {giornate}  other {days}}}}",
      "gigajoule": "{number, plural, =0 {} one {{language, select, fr {gigajoule} de {Gigajoule} es {gigajulio} it {gigajoule} other {gigajoule}}} other {{language, select, fr {gigajoules} de {Gigajoule} es {gigajulios} it {gigajoule} other {gigajoules}}}}",
      "gigawatt": "{number, plural, =0 {} one {{language, select, fr {gigawatt} de {Gigawatt} es {gigavatio} it {gigawatt} other {gigawatt}}} other {{language, select, fr {gigawatts} de {Gigawatt} es {gigavatios} it {gigawatt} other {gigawatts}}}}",
      "gigawatt_hour": "{number, plural, =0 {} one {{language, select, fr {gigawattheure} de {Gigawattstunde} es {gigavatio-hora} it {gigawattora} other {gigawatt hour}}} other {{language, select, fr {gigawattheures} de {Gigawattstunden} es {gigavatios-hora} it {gigawattora} other {gigawatt hours}}}}",
      "gram": "{number, plural, =0 {} one {{language, select, fr {g} de {g} es {g} it {g} other {g}}} other {{language, select, fr {g} de {g} es {g} it {g} other {g}}}}",
      "half_year": "{number, plural, =0 {} one {{language, select, fr {semestre} de {halbes Jahr} es {semestre} it {semestre} other {half-year}}} other {{language, select, fr {semestres} de {halbe Jahre} es {semestres} it {semestri} other {half-years}}}}",
      "hour": "{number, plural, =0 {} one {{language, select, fr {heure} de {Stunde} es {hora} it {ora} other {hour}}} other {{language, select, fr {heures} de {Stunden} es {horas} it {ore} other {hours}}}}",
      "joule": "{number, plural, =0 {} one {{language, select, fr {joules} de {Joule} es {julio} it {joule} other {joule}}} other {{language, select, fr {joules} de {Joule} es {julios} it {joule} other {joules}}}}",
      "kilogram": "{number, plural, =0 {} one {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}} other {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}}}",
      "kilojoule": "{number, plural, =0 {} one {{language, select, fr {kilojoule} de {Kilojoule} es {kilojulio} it {kilojoule} other {kilojoule}}} other {{language, select, fr {kilojoules} de {Kilojoule} es {kilojulios} it {kilojoule} other {kilojoules}}}}",
      "kilovar": "{number, plural, =0 {} one {{language, select, fr {kilovar} de {Kilovar} es {kilovar} it {kilovar} other {kilovar}}} other {{language, select, fr {kilovars} de {Kilovar} es {kilovares} it {kilovar} other {kilovars}}}}",
      "kilovolt_ampere_reactive_hour": "{number, plural, =0 {} one {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}} other {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}}}",
      "kilowatt": "{number, plural, =0 {} one {{language, select, fr {kilowatt} de {Kilowatt} es {kilovatio} it {chilowatt} other {kilowatt}}} other {{language, select, fr {kilowatts} de {Kilowatt} es {kilovatios} it {chilowatt} other {kilowatts}}}}",
      "kilowatt_hour": "{number, plural, =0 {} one {{language, select, fr {kilowattheure} de {Kilowattstunde} es {kilovatio-hora} it {chilowattora} other {kilowatt hour}}} other {{language, select, fr {kilowattheures} de {Kilowattstunden} es {kilovatios-hora} it {chilowattora} other {kilowatt hours}}}}",
      "liter": "{number, plural, =0 {} one {{language, select, fr {L} de {l} es {l} it {L} other {L}}} other {{language, select, fr {to complete} de {l} es {l} it {L} other {L}}}}",
      "megajoule": "{number, plural, =0 {} one {{language, select, fr {mégajoule} de {Megajoule} es {megajulio} it {megajoule} other {megajoule}}} other {{language, select, fr {mégajoules} de {Megajoule} es {megajulios} it {megajoule} other {megajoules}}}}",
      "megawatt": "{number, plural, =0 {} one {{language, select, fr {mégawatt} de {Megawatt} es {megavatio} it {megawatt} other {megawatt}}} other {{language, select, fr {mégawatts} de {Megawatt} es {megavatios} it {megawatt} other {megawatts}}}}",
      "megawatt_hour": "{number, plural, =0 {} one {{language, select, fr {mégawattheure} de {Megawattstunde} es {megavatio-hora} it {megawattora} other {megawatt hour}}} other {{language, select, fr {mégawattheures} de {Megawattstunden} es {megavatios-hora} it {megawattora} other {megawatt hours}}}}",
      "meter": "{number, plural, =0 {} one {{language, select, fr {m} de {m} es {m} it {m} other {m}}} other {{language, select, fr {m} de {m} es {m} it {m} other {m}}}}",
      "milligram": "{number, plural, =0 {} one {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}} other {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}}}",
      "milliliter": "{number, plural, =0 {} one {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}} other {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}}}",
      "millimeter": "{number, plural, =0 {} one {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}} other {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}}}",
      "minute": "{number, plural, =0 {} one {{language, select, fr {minute} de {Minute} es {minuto} it {minuto} other {minute}}} other {{language, select, fr {minutes} de {Minuten} es {minutos} it {minuti} other {minutes}}}}",
      "month": "{number, plural, =0 {} one {{language, select, fr {mois} de {Monat} es {mes} it {mese} other {month}}} other {{language, select, fr {mois} de {Monate} es {meses} it {mesi} other {months}}}}",
      "number_of_articles": "{number, plural, =0 {} one {{language, select, fr {article} de {Artikel} es {artículo} it {pezzo} other {article}}} other {{language, select, fr {articles} de {Artikel} es {artículos} it {pezzi} other {articles}}}}",
      "pair": "{number, plural, =0 {} one {{language, select, fr {paire} de {Paar} es {par} it {paio} other {pair}}} other {{language, select, fr {paires} de {Paare} es {pares} it {paia} other {pairs}}}}",
      "quarter": "{number, plural, =0 {} one {{language, select, fr {trimestre} de {Viertel} es {trimestre} it {trimestre} other {quarter}}} other {{language, select, fr {trimestres} de {Viertel} es {trimestres} it {trimestri} other {quarters}}}}",
      "second": "{number, plural, =0 {} one {{language, select, fr {seconde} de {Sekunde} es {segundo} it {secondo} other {second}}} other {{language, select, fr {secondes} de {Sekunden} es {segundos} it {secondi} other {seconds}}}}",
      "set": "{number, plural, =0 {} one {{language, select, fr {ensemble} de {Set} es {juego} it {assortimento} other {set}}} other {{language, select, fr {ensembles} de {Sets} es {juegos} it {assortimenti} other {sets}}}}",
      "square_meter": "{number, plural, =0 {} one {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}} other {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}}}",
      "ton": "{number, plural, =0 {} one {{language, select, fr {t} de {t} es {t} it {t} other {T}}} other {{language, select, fr {t} de {t} es {t} it {t} other {T}}}}",
      "two_week": "{number, plural, =0 {} one {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two-week}}} other {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two weeks}}}}",
      "unit": "{number, plural, =0 {} one {{language, select, fr {unité} de {Einheit} es {unidad} it {unità} other {unit}}} other {{language, select, fr {unités} de {Einheiten} es {unidades} it {unità} other {units}}}}",
      "watt_hour": "{number, plural, =0 {} one {{language, select, fr {wattheure} de {Wattstunde} es {vatio-hora} it {wattora} other {watt hour}}} other {{language, select, fr {wattheures} de {Wattstunden} es {vatios-hora} it {wattora} other {watt hours}}}}",
      "week": "{number, plural, =0 {} one {{language, select, fr {semaine} de {Woche} es {semana} it {settimana} other {week}}} other {{language, select, fr {semaines} de {Wochen} es {semanas} it {settimane} other {weeks}}}}",
      "year": "{number, plural, =0 {} one {{language, select, fr {année} de {Jahr} es {año} it {anno} other {year}}} other {{language, select, fr {années} de {Jahre} es {años} it {anni} other {years}}}}"
    }
  },
  "product_tour": {
    "banner": {
      "CTA_primary": "Conectar tu banco",
      "CTA_secondary": "Reproducir la demostración"
    },
    "clients": {
      "action1": {
        "title": "Agencia de marketing",
        "transactions": "Pago Agencia de marketing"
      },
      "action2": {
        "title": "Panadería",
        "transactions": "débito tarjeta panadería"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "pago Qonto Airlines"
      },
      "action4": {
        "title": "Medicina ocupacional",
        "transactions": "pago con tarjeta Medicina ocupacional"
      },
      "action5": {
        "title": "Suministro",
        "transactions": "Pago con tarjeta Suministro"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Pago Qonto Technology"
      },
      "action7": {
        "title": "Gas y electricidad",
        "transactions": "pago mensual Gas y electricidad"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "suscripción Qonto Telecom"
      }
    },
    "floating_banner": {
      "text": "¿Listo para migrar tu cuenta a Qonto?"
    },
    "overview": {
      "card1": {
        "IBAN": "ES00 1111 2222 3333 4444 5555 000",
        "title": "Cuenta anterior"
      },
      "card2": {
        "IBAN": "ES00 6666 7777 8888 9999 1111 000",
        "title": "Cuenta principal Qonto"
      },
      "title": "Panel de control (demostración)"
    },
    "suppliers": {
      "action1": {
        "title": "Agencia de marketing",
        "transactions": "Pago Agencia de marketing"
      },
      "action2": {
        "title": "Panadería",
        "transactions": "débito tarjeta panadería"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "pago Qonto Airlines"
      },
      "action4": {
        "title": "Medicina ocupacional",
        "transactions": "pago con tarjeta Medicina ocupacional"
      },
      "action5": {
        "title": "Suministro",
        "transactions": "Pago con tarjeta Suministro"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Pago Qonto Technology"
      },
      "action7": {
        "title": "Gas y electricidad",
        "transactions": "pago mensual Gas y electricidad"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "suscripción Qonto Telecom"
      }
    }
  },
  "redirect": {
    "continue_button": "Continuar",
    "error_message": "Para continuar, selecciona al menos una cuenta.",
    "subtitle": "El historial de transacciones asociado a esta cuenta se transferirá a tu asesor de Qonto para su revisión.",
    "title": "Selecciona las cuentas que quieres transferir"
  },
  "synchronization": {
    "error_page_1003": {
      "CTA": "Volver a Qonto",
      "body": "Un error nos impide conectarnos a tu banco. No te preocupes, nuestro equipo está trabajando en ello y recibirás un correo electrónico en cuanto se resuelva.",
      "title": "No hemos podido conectarnos a tu banco"
    },
    "error_page_402": {
      "CTA_1": "Seleccionar otro banco",
      "CTA_2": "¿Necesitas ayuda?",
      "body": "Parece que hay un problema para conectar tu banco a Qonto. Algunos bancos tienen varias entidades, por lo que debes comprobar que eliges la correcta antes de conectarte de nuevo a Qonto.",
      "title": "¿Has seleccionado el banco correcto?"
    },
    "error_page_429": {
      "CTA_1": "Continuar",
      "CTA_2": "Volver a Qonto",
      "body_action1": "Para continuar, debes aceptar las condiciones generales en tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action10": "Para continuar, debes reactivar la autenticación reforzada en tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action11": "Para continuar, debes obtener el acceso a los servicios en línea contactando con tu banco {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action12": "Para continuar, debes contactar con el servicio de atención al cliente de tu banco {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action13": "Para continuar, debes reemplazar o activar tus credenciales contactando con tu banco {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action14": "Para continuar, debes modificar tu contraseña en tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action15": "Para continuar, debes crear una nueva contraseña conectándote por primera vez a tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action2": "Para continuar, debes firmar un contrato en tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action3": "Para continuar, debes activar o indicar tus credenciales en tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action4": "Para continuar, debes activar tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action5": "Para continuar, debes indicar o confirmar el número de teléfono asociado a tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action6": "Para continuar, debes validar la información o el mensaje de tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action7": "Para continuar, debes confirmar o completar tus datos personales en tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action8": "Para continuar, debes confirmar y/o modificar tus datos en tu cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "body_action9": "Para continuar, debes crear una cuenta {bank_name}. Después, vuelve a la aplicación Qonto para reanudar tu migración de cuenta.",
      "checkbox": "Al hacer clic aquí, confirmo haber hecho lo necesario en mi banco.",
      "title": "Dirígete al espacio para clientes de tu banco",
      "toaster": "Parece que tu banco tiene que efectuar una acción todavía. ¿Puedes comprobarlo y volver a intentarlo?"
    }
  },
  "toast": {
    "default_error_message": "Se ha producido un error. ¿Puedes volverlo a intentar?",
    "not_available_for_country": "Lo sentimos, el servicio de migración de cuenta no está disponible (aún) en tu país. ¡Mantente atento a las novedades!"
  },
  "ui-kit": {
    "avatar-input": {
      "default-image-alt-text": "Imagen predeterminada",
      "uploaded-image-alt-text": "Última imagen que has subido"
    },
    "btn": {
      "aria-label": {
        "clear": "Quitar",
        "download": "Descargar",
        "next-month": "Mes siguiente",
        "preview": "Mostrar",
        "previous-month": "Mes anterior"
      },
      "cancel": "Cancelar",
      "close": "Cerrar",
      "delete": "Eliminar",
      "delete-aria-label": "Eliminar"
    },
    "countries": {
      "AC": "Isla Ascensión",
      "AD": "Andorra",
      "AE": "Emiratos Árabes Unidos",
      "AF": "Afganistán",
      "AG": "Antigua & Barbuda",
      "AI": "Anguila",
      "AL": "Albania",
      "AM": "Armenia",
      "AN": "Antillas Neerlandesas",
      "AO": "Angola",
      "AQ": "Antártida",
      "AR": "Argentina",
      "AS": "Samoa Americana",
      "AT": "Austria",
      "AU": "Australia",
      "AW": "Aruba",
      "AX": "Islas Åland",
      "AZ": "Azerbaiyán",
      "BA": "Bosnia y Herzegovina",
      "BB": "Barbados",
      "BD": "Bangladesh",
      "BE": "Bélgica",
      "BF": "Burkina Faso",
      "BG": "Bulgaria",
      "BH": "Bahrein",
      "BI": "Burundi",
      "BJ": "Benín",
      "BL": "San Bartolomé",
      "BM": "Bermudas",
      "BN": "Brunéi",
      "BO": "Bolivia",
      "BQ": "Caribe Neerlandés",
      "BR": "Brasil",
      "BS": "Bahamas",
      "BT": "Bután",
      "BV": "Isla Bouvet",
      "BW": "Botsuana",
      "BY": "Bielorrusia",
      "BZ": "Belice",
      "CA": "Canadá",
      "CC": "Islas Cocos",
      "CD": "Congo - Kinsasa",
      "CF": "República Centroafricana",
      "CG": "Congo - Brazzaville",
      "CH": "Suiza",
      "CI": "Costa de Marfil",
      "CK": "Islas Cook",
      "CL": "Chile",
      "CM": "Camerún",
      "CN": "China",
      "CO": "Colombia",
      "CP": "Isla Clipperton",
      "CR": "Costa Rica",
      "CU": "Cuba",
      "CV": "Cabo Verde",
      "CW": "Curazao",
      "CX": "Kiritimati",
      "CY": "Chipre",
      "CZ": "República Checa",
      "DE": "Alemania",
      "DG": "Isla Diego García",
      "DJ": "Yibuti",
      "DK": "Dinamarca",
      "DM": "Dominica",
      "DO": "República Dominicana",
      "DZ": "Argelia",
      "EA": "Ceuta y Melilla (España)",
      "EC": "Ecuador",
      "EE": "Estonia",
      "EG": "Egipto",
      "EH": "Sahara Occidental",
      "ER": "Eritrea",
      "ES": "España",
      "ET": "Etiopía",
      "EU": "Unión Europea",
      "FI": "Finlandia",
      "FJ": "Fiyi",
      "FK": "Islas Malvinas",
      "FM": "Micronesia",
      "FO": "Islas Feroe (Dinamarca)",
      "FR": "Francia",
      "GA": "Gabón",
      "GB": "Reino Unido",
      "GD": "Granada",
      "GE": "Georgia",
      "GF": "Guayana Francesa",
      "GG": "Bailía de Guernsey",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GL": "Groenlandia",
      "GM": "Gambia",
      "GN": "Guinea",
      "GP": "Guadalupe",
      "GQ": "Guinea Ecuatorial",
      "GR": "Grecia",
      "GS": "Islas Georgias del Sur y Sandwich del Sur",
      "GT": "Guatemala",
      "GU": "Guam",
      "GW": "Guinea-Bisáu",
      "GY": "Guyana",
      "HK": "Hong Kong",
      "HM": "Islas Heard y McDonald",
      "HN": "Honduras",
      "HR": "Croacia",
      "HT": "Haití",
      "HU": "Hungría",
      "IC": "Islas Canarias (España)",
      "ID": "Indonesia",
      "IE": "Irlanda",
      "IL": "Israel",
      "IM": "Isla de Man",
      "IN": "India",
      "IO": "Territorio Británico del Océano Índico",
      "IQ": "Irak",
      "IR": "Irán",
      "IS": "Islandia",
      "IT": "Italia",
      "JE": "Jersey",
      "JM": "Jamaica",
      "JO": "Jordania",
      "JP": "Japón",
      "KE": "Kenia",
      "KG": "Kirguistán",
      "KH": "Camboya",
      "KI": "Kiribati",
      "KM": "Comoras",
      "KN": "San Cristóbal y Nieves",
      "KP": "Corea del Norte",
      "KR": "Corea del Sur",
      "KW": "Kuwait",
      "KY": "Islas Caimán",
      "KZ": "Kazajistán",
      "LA": "Laos",
      "LB": "Líbano",
      "LC": "Santa Lucía",
      "LI": "Liechtenstein",
      "LK": "Sri Lanka",
      "LR": "Liberia",
      "LS": "Lesoto",
      "LT": "Lituania",
      "LU": "Luxemburgo",
      "LV": "Letonia",
      "LY": "Libia",
      "MA": "Marruecos",
      "MC": "Mónaco",
      "MD": "Moldavia",
      "ME": "Montenegro",
      "MF": "Isla de San Martín",
      "MG": "Madagascar",
      "MH": "Islas Marshall",
      "MK": "Macedonia",
      "ML": "Malí",
      "MM": "Birmania",
      "MN": "Mongolia",
      "MO": "Macau",
      "MP": "Islas Marianas del Norte",
      "MQ": "Martinica",
      "MR": "Mauritania",
      "MS": "Isla de Montserrat",
      "MT": "Malta",
      "MU": "Mauricio",
      "MV": "Maldivas",
      "MW": "Malaui",
      "MX": "México",
      "MY": "Malasia",
      "MZ": "Mozambique",
      "NA": "Namibia",
      "NC": "Nueva Caledonia",
      "NE": "Níger",
      "NF": "Isla Norfolk",
      "NG": "Nigeria",
      "NI": "Nicaragua",
      "NL": "Países Bajos",
      "NO": "Noruega",
      "NP": "Nepal",
      "NR": "Nauru",
      "NU": "Niue",
      "NZ": "Nueva Zelanda",
      "OM": "Omán",
      "PA": "Panamá",
      "PE": "Perú",
      "PF": "Polinesia Francesa",
      "PG": "Papúa Nueva Guinea",
      "PH": "Filipinas",
      "PK": "Pakistán",
      "PL": "Polonia",
      "PM": "San Pedro y Miquelón",
      "PN": "Islas Pitcairn",
      "PR": "Puerto Rico",
      "PS": "Territorios Palestinos",
      "PT": "Portugal",
      "PW": "Palaos",
      "PY": "Paraguay",
      "QA": "Catar",
      "QO": "Oceanía periférica",
      "RE": "Reunión",
      "RO": "Rumania",
      "RS": "Serbia",
      "RU": "Rusia",
      "RW": "Ruanda",
      "SA": "Arabia Saudita",
      "SB": "Islas Salomón",
      "SC": "Seychelles",
      "SD": "Sudán",
      "SE": "Suecia",
      "SG": "Singapore",
      "SH": "Isla Santa Elena",
      "SI": "Eslovenia",
      "SJ": "Svalbard y Jan Mayen",
      "SK": "Eslovaquia",
      "SL": "Sierra Leona",
      "SM": "San Marino",
      "SN": "Senegal",
      "SO": "Somalia",
      "SR": "Surinam",
      "SS": "Sudán del Sur",
      "ST": "Santo Tomé y Príncipe",
      "SV": "El Salvador",
      "SX": "Isla de San Martín",
      "SY": "Siria",
      "SZ": "Suazilandia",
      "TA": "Tristán de Acuña",
      "TC": "Islas Turcas y Caicos",
      "TD": "Chad",
      "TF": "Tierras Australes y Antárticas Francesas",
      "TG": "Togo",
      "TH": "Tailandia",
      "TJ": "Tayikistán",
      "TK": "Tokelau",
      "TL": "Timor Oriental",
      "TM": "Turkmenistán",
      "TN": "Túnez",
      "TO": "Tonga",
      "TR": "Turquía",
      "TT": "Trinidad y Tobago",
      "TV": "Tuvalu",
      "TW": "Taiwán",
      "TZ": "Tanzania",
      "UA": "Ucrania",
      "UG": "Uganda",
      "UM": "Islas Ultramarinas Menores (EUA)",
      "US": "Estados Unidos",
      "UY": "Uruguay",
      "UZ": "Uzbekistán",
      "VA": "Ciudad del Vaticano",
      "VC": "San Vicente y las Granadinas",
      "VE": "Venezuela",
      "VG": "Islas Vírgenes Británicas",
      "VI": "Islas Vírgenes (EUA)",
      "VN": "Vietnam",
      "VU": "Vanuatu",
      "WF": "Wallis y Futuna",
      "WS": "Samoa",
      "XK": "Kósovo",
      "YE": "Yemen",
      "YT": "Mayotte",
      "ZA": "Sudáfrica",
      "ZM": "Zambia",
      "ZW": "Zimbabue",
      "ZZ": "Región desconocida"
    },
    "country-select": {
      "label": "País",
      "no_matches_message": "No hemos encontrado resultados",
      "placeholder": "País",
      "search_placeholder": "Escribe para buscar"
    },
    "disclaimer": {
      "alt-values": {
        "error": "Mensaje de error",
        "info": "Mensaje informativo",
        "warning": "Mensaje de aviso"
      }
    },
    "file-status": {
      "certified": "Digitalizado",
      "not-certified": "Sin digitalizar"
    },
    "form-elements": {
      "optional": "(opcional)"
    },
    "form-fields": {
      "date-field": {
        "error": {
          "invalid": "Esta fecha no es válida. ¿Puedes intentarlo de nuevo con el formato DD-MM-AAAA?"
        },
        "placeholder": "DD-MM-AAAA"
      },
      "date-picker": {
        "clear": {
          "cta": "Quitar"
        }
      }
    },
    "fullscreen": {
      "preview": {
        "delete-file": {
          "button-cancel": "Cancelar",
          "button-confirm": "Eliminar",
          "description": "Ten en cuenta que la eliminación es definitiva.",
          "title": "¿Eliminamos el archivo?"
        }
      }
    },
    "gender-selector": {
      "female": "Femenino",
      "label": "Sexo en el documento de identidad",
      "male": "Masculino",
      "placeholder": "Género"
    },
    "icons": {
      "alt-values": {
        "error": "Mensaje de error",
        "info": "Mensaje informativo",
        "warning": "Mensaje de aviso"
      }
    },
    "kilobyte": "KB",
    "labels": {
      "choose_all": "Seleccionar todo",
      "clear_filters": "Deseleccionar todo"
    },
    "megabyte": "MB",
    "password_creator": {
      "label": "Contraseña",
      "placeholder": "Escribe aquí tu contraseña",
      "set_pwd_rules": "Tu contraseña tiene que tener como mínimo 9 caracteres. Intenta no poner tu fecha de nacimiento, el nombre de tu mascota o «0000», por ejemplo.",
      "strength_password_strong": "Perfecto, tu contraseña es < lo suficientemente segura. >",
      "strength_password_weak": "Tu contraseña < no es lo suficientemente segura. >"
    },
    "pdf-preview": {
      "amount-discount": "{language, select, fr {Remise} de {Rabatt} es {Descuento} it {Sconto} other {Discount}}",
      "beneficiary-name": "{language, select, fr {Nom du bénéficiaire} it {Nome e cognome del beneficiario} de {Name des/r Begünstigten} es {Nombre del beneficiario} other {Beneficiary name}}",
      "bic": "BIC",
      "client-steuer-id": "{language, select, fr {Numéro fiscal client} de {Kunden-Steuer-ID} es {Codice fiscale cliente} it {NIF cliente} other {Client tax ID}}",
      "client-ust-udnr": "{language, select, fr {Numéro TVA client} de {Kunden-USt.-IDNr.} es {Número de IVA cliente} it {Partita IVA cliente} other {Client VAT number}}",
      "credit-note": {
        "credit-note-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "credit-note-number": "{language, select, fr {Numéro de l'avoir} it {Numero nota di credito} de {Stornorechnungsnummer} es {Número de factura rectificativa} other {Credit note number}}",
        "invoice-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "reason-credit-note": "{language, select, fr {Motif de l'avoir :} it {Motivo emissione nota di credito:} de {Grund für die Stornorechnung:} es {Motivo de la factura rectificativa:} other {Reason for credit note:}}",
        "related-invoice-number": "{language, select, fr {Numéro de facture associée} it {Numero fattura correlata} de {Zugehörige Rechnungsnummer} es {Número de factura asociada} other {Related invoice number}}",
        "title": "{language, select, fr {Avoir} it {Nota di credito} de {Stornorechnung} es {Factura rectificativa} other {Credit note}}"
      },
      "delivery-address": "{language, select, fr {Adresse de livraison} de {Lieferadresse} es {Dirección de envío} it {Indirizzo di consegna} other {Delivery address}}",
      "district-court": "{language, select, fr {Greffe du tribunal d'arrondissement :} de {Registergericht} es {Juzgado de registro de distrito:} it {Registro del tribunale distrettuale:} other {Registry district court:}}",
      "draft": {
        "invoice-number-draft-value": "--",
        "proforma-disclaimer": "{language, select, it {Il presente documento non costituisce fattura valida ai fini del DPR 633 del 26/10/1972 e successive modifiche. La fattura definitiva verrà emessa all’atto del pagamento del corrispettivo (articolo 6, comma 3, DPR 633/72).} other {This document does not constitute a valid invoice as outlined in the Decree of the President of the Republic (DPR) no. 633 of 26 October 1972 and subsequent amendments. The final invoice will be issued upon payment of the consideration (Article 6, paragraph 3, of the DPR 633/72).}}",
        "title": "{language, select, fr {Facture pro forma} de {Proformarechnung} es {Factura proforma} it {Fattura proforma} other {Pro forma invoice}}"
      },
      "due-date": "{language, select, fr {Date d'échéance} it {Data di scadenza} de {Fälligkeitsdatum} es {Fecha de vencimiento} other {Due date}}",
      "exemption": {
        "AT": {
          "S11.1a": "{language, select, fr {TVA non applicable, article 11 de l'UStG} de {MwSt. entfällt, Art. 11 des UStG} es {IVA no aplicable, art. 11 del UStG} it {IVA non applicabile, art. 11 del UStG} other {VAT not applicable, art. 11 of the UStG}}",
          "S6.1.13": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.16": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.19": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.27": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}"
        },
        "BE": {
          "S21": "{language, select, fr {TVA non applicable, article 21 du BTW-Wetboek} de {MwSt. entfällt, Art. 21 des BTW-Wetboek} es {IVA no aplicable, art. 21 del BTW-Wetboek} it {IVA non applicabile, art. 21 del BTW-Wetboek} other {VAT not applicable, art. 21 of the BTW-Wetboek}}",
          "S39": "{language, select, fr {TVA non applicable, article 39 du BTW-Wetboek} de {MwSt. entfällt, Art. 39 des BTW-Wetboek} es {IVA no aplicable, art. 39 del BTW-Wetboek} it {IVA non applicabile, art. 39 del BTW-Wetboek} other {VAT not applicable, art. 39 of the BTW-Wetboek}}",
          "S39bis": "{language, select, fr {TVA non applicable, article 39bis du BTW-Wetboek} de {MwSt. entfällt, Art. 39bis des BTW-Wetboek} es {IVA no aplicable, art. 39bis del BTW-Wetboek} it {IVA non applicabile, art. 39bis del BTW-Wetboek} other {VAT not applicable, art. 39bis of the BTW-Wetboek}}",
          "S44.1": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.11": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.2": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.4": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S56bis": "{language, select, fr {TVA non applicable, article 56bis du BTW-Wetboek} de {MwSt. entfällt, Art. 56bis des BTW-Wetboek} es {IVA no aplicable, art. 56bis del BTW-Wetboek} it {IVA non applicabile, art. 56bis del BTW-Wetboek} other {VAT not applicable, art. 56bis of the BTW-Wetboek}}"
        },
        "DE": {
          "S13B": "{language, select, fr {Steuerschuldnerschaft des Leistungsempfängers (obligation fiscale du bénéficiaire) (section 13b)} de {Steuerschuldnerschaft des Leistungsempfängers (Abschnitt 13b)} es {Steuerschuldnerschaft des Leistungsempfängers (responsabilidad fiscal del beneficiario) (Sección 13b)} it {Steuerschuldnerschaft des Leistungsempfängers (responsabilità fiscale del beneficiario) (Sezione 13b)} other {Steuerschuldnerschaft des Leistungsempfängers (Tax liability of the beneficiary) (Section 13b)}}",
          "S19.1": "{language, select, fr {Conformément à la section 19, paragraphe 1 de l'UStG, le montant de la facture n'inclut pas de taxe de vente} de {Gemäß Abschnitt 19 Abs. 1 UStG ist im Rechnungsbetrag keine Umsatzsteuer enthalten} es {Según el artículo 19, apartado 1 de la UStG, el importe de la factura no incluye el impuesto sobre las ventas} it {Ai sensi della sezione 19, paragrafo 1, della UStG, l'importo della fattura non comprende l'imposta sulle vendite} other {According to Section 19 Paragraph 1 UStG, the invoice amount does not include sales tax}}",
          "S4": "{language, select, fr {Conformément à la section 4 de l'UStG, aucune taxe sur la valeur ajoutée n'est facturée} de {Gemäß Abschnitt 4 UStG wird keine Mehrwertsteuer erhoben} es {Según el artículo 4 de la UStG, no se cobra ningún impuesto sobre el valor añadido} it {Ai sensi della sezione 4 della UStG, non viene addebitata alcuna imposta sul valore aggiunto} other {In accordance to § 4 UStG, no value added tax is charged}}",
          "S4.1A": "{language, select, fr {Livraison d'exportation exonérée d'impôt, conformément à la section 4 n° 1a de l'UStG} de {Steuerfreie Exportlieferung gemäß Abschnitt 4 Nr. 1a des UStG} es {Entrega para exportación exenta de impuestos según el artículo 4 núm. 1a de la UStG} it {Cessione dell'esportazione esente da imposte ai sensi della sezione. 4 n. 1a UStG} other {Tax-free export delivery in accordance with Section 4 No. 1a UStG}}",
          "S4.1B": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à la section 4 n° 1b de l'UStG} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Abschnitt 4 Nr. 1b des UStG} es {Entrega intracomunitaria exenta de impuestos según el artículo 4 núm. 1b de la UStG} it {Cessione intracomunitaria esente da imposte ai sensi della sezione 4 n. 1b UStG} other {Tax-free intra-community delivery in accordance with Section 4 No. 1b UStG.}}"
        },
        "ES": {
          "S122": "{language, select, fr {Opération exonérée d'impôt avec l'art. 122 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 122 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos en virtud del art. 122 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 122 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 122 of Law 37/1992, on VAT}}",
          "S20": "{language, select, fr {Opération exonérée d'impôt, conformément à l'art. 20 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 20 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por aplicación del art. 20 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 20 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 20 of Law 37/1992, on VAT}}",
          "S21": "{language, select, fr {Exportation exonérée d'impôt avec l'art. 21 de la Loi 37/1992 sur la TVA} de {Steuerfreier Export gemäß Art. 21 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por exportación en virtud del art. 21 de la Ley 37/1992, del IVA} it {Esportazione esente da imposta ai sensi dell'art. 21 della Legge 37/1992 sull'IVA} other {Tax-free export in accordance with art. 21 of Law 37/1992, on VAT}}",
          "S25": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à l'art. 25 de la Loi 37/1992 sur la TVA} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Art. 25 des Gesetzes 37/1992 über die MwSt.} es {Entrega intracomunitaria exenta de impuestos por aplicación del art. 25 de la Ley 37/1992, del IVA} it {Cessione intracomunitaria esente da imposta ai sensi dell'art. 25 della Legge 37/1992 sull'IVA} other {Tax-free intra-community delivery in accordance with art. 25 of Law 37/1992, on VAT}}",
          "S69": "{language, select, fr {Opération située à l'adresse du destinataire, conformément à l'art. 69 de la Loi 37/1992 sur la TVA} de {Ein auf der Anschrift des Empfängers oder der Empfängerin laufender Vorgang gemäß Art. 69 des Gesetzes 37/1992 über die MwSt.} es {Operación localizada en sede del destinatario en virtud del art. 69 de la Ley 37/1992, del IVA} it {Operazione situata all'indirizzo del destinatario ai sensi dell'art. 69 della Legge 37/1992 sull'IVA} other {Operation located at the address of the recipient in accordance with art. 69 of Law 37/1992, on VAT}}",
          "S84.1.2": "{language, select, fr {Reversement de l'assujetti - Art. 84.1.2º de la Loi 37/1992 sur la TVA} de {Umkehrung des Steuerpflichtigen – Art. 84.1.2º des Gesetzes 37/1992 über die MwSt.} es {Inversión de sujeto pasivo - Art. 84.1.2º de la Ley 37/1992, del IVA} it {Storno del soggetto passivo - Art. 84.1.2º della Legge 37/1992 sull'IVA} other {Reversal of taxable person - Art. 84.1.2º of Law 37/1992, on VAT}}"
        },
        "FR": {
          "S259": "{language, select, fr {TVA non applicable, art. 259-1 du CGI} de {MwSt. entfällt, Art. 259-1 des CGI} es {IVA no aplicable, art. 259-1 del CGI} it {IVA non applicabile, art. 259-1 del CGI} other {VAT not applicable, art. 259-1 of the CGI}}",
          "S261": "{language, select, fr {TVA non applicable, art. 261 du CGI} de {MwSt. entfällt, Art. 261 des CGI} es {IVA no aplicable, art. 261 del CGI} it {IVA non applicabile, art. 261 del CGI} other {VAT not applicable, art. 261 of the CGI}}",
          "S262": "{language, select, fr {TVA non applicable, art. 262 du CGI} de {MwSt. entfällt, Art. 262 des CGI} es {IVA no aplicable, art. 262 del CGI} it {IVA non applicabile, art. 262 del CGI} other {VAT not applicable, art. 262 of the CGI}}",
          "S262.1": "{language, select, fr {TVA non applicable, article 262 I du CGI} de {MwSt. entfällt, Art. 262 I des CGI} es {IVA no aplicable, art. 262 I del CGI} it {IVA non applicabile, art. 262 I del CGI} other {VAT not applicable, art. 262 I of the CGI}}",
          "S263": "{language, select, fr {TVA non applicable, art. 263 du CGI} de {MwSt. entfällt, Art. 263 des CGI} es {IVA no aplicable, art. 263 del CGI} it {IVA non applicabile, art. 263 del CGI} other {VAT not applicable, art. 263 of the CGI}}",
          "S283": "{language, select, fr {Autoliquidation, art. 283 du CGI} de {Steuerschuldnerschaft des Leistungsempfängers, Art. 283 des CGI} es {Autoliquidación, art. 283 del CGI} it {Autoliquidazione, art. 283 del CGI} other {Autoliquidation, art. 283 of the CGI}}",
          "S293B": "{language, select, fr {TVA non applicable, art. 293 B du CGI} de {MwSt. entfällt, Art. 263 B des CGI} es {IVA no aplicable, art. 293 B del CGI} it {IVA non applicabile, art. 293 B della CGI} other {VAT not applicable, art. 293 B of the CGI}}"
        },
        "NL": {
          "S11": "{language, select, fr {TVA non applicable, article 11 de l'OB 1968} de {MwSt. entfällt, Art. 11 des OB 1968} es {IVA no aplicable, art. 11 del OB 1968} it {IVA non applicabile, art. 11 del OB 1968} other {VAT not applicable, art. 11 of the OB 1968}}",
          "S12": "{language, select, fr {TVA non applicable, article 12 de l'OB 1968} de {MwSt. entfällt, Art. 12 des OB 1968} es {IVA no aplicable, art. 12 del OB 1968} it {IVA non applicabile, art. 12 del OB 1968} other {VAT not applicable, art. 12 of the OB 1968}}",
          "S25": "{language, select, fr {TVA non applicable, article 25 de l'OB 1968} de {MwSt. entfällt, Art. 25 des OB 1968} es {IVA no aplicable, art. 25 del OB 1968} it {IVA non applicabile, art. 25 del OB 1968} other {VAT not applicable, art. 25 of the OB 1968}}",
          "S9": "{language, select, fr {TVA non applicable, article 9 de l'OB 1968} de {MwSt. entfällt, Art. 9 des OB 1968} es {IVA no aplicable, art. 9 del OB 1968} it {IVA non applicabile, art. 9 del OB 1968} other {VAT not applicable, art. 9 of the OB 1968}}"
        },
        "PT": {
          "S53": "{language, select, fr {TVA non applicable, article 53 du Regime do IVA} de {MwSt. entfällt, Art. 53 des Regime do IVA} es {IVA no aplicable, art. 53 del Regime do IVA} it {IVA non applicabile, art. 53 del Regime do IVA} other {VAT not applicable, art. 53 of the Regime do IVA}}",
          "S9.1-5": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.27.28": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.29.30": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}"
        }
      },
      "footer-organization": "{language, select, fr {{orgaName} avec un capital de {amount}} de {mit einem Kapital von} es {{orgaName} con un capital de {amount}} it {{orgaName} con un capitale di {amount}} other {{orgaName} with a capital of {amount}}}",
      "iban": "IBAN",
      "intermediary-bic": {
        "label": "{language, select, fr {BIC de la banque intermédiaire} de {BIC unserer Korrespondenzbank} es {BIC del banco intermediario} it {BIC della banca corrispondente} other {Intermediary bank BIC}}",
        "value": "{language, select, fr {TRWIBEB3XXX} de {TRWIBEB3XXX} es {TRWIBEB3XXX} it {TRWIBEB3XXX} other {TRWIBEB3XXX}}"
      },
      "invoice-example": {
        "client-address-line1": "Passeig de Gràcia 17",
        "client-city": "Barcelona",
        "client-countrycode": "DE",
        "client-delivery-address-line1": "Passeig de Gràcia 17",
        "client-delivery-city": "Barcelona",
        "client-delivery-countrycode": "ES",
        "client-delivery-zipcode": "08002",
        "client-name": "Sonia Ruiz",
        "client-vat-number": "12345678A",
        "client-zipcode": "08007",
        "invoice-due-date": "15-01-2030",
        "invoice-issue-date": "01-01-2030",
        "invoice-number": "F-2030-001",
        "invoice-payment-time": "Por favor, abona el importe de la factura en un plazo de 15 días. (15/01/2030)",
        "invoice-performance-date": "01/01/2030",
        "invoice-total": "121",
        "item-description": "Aquí aparecerán los detalles de tus futuros objetos reales.",
        "item-price": "100",
        "item-quantity": "2",
        "item-title": "El título de mi primer concepto",
        "item-total": "121",
        "item-total-without-vat": "100",
        "item-vat-amount": "0.21",
        "item-vat-percentual": "%",
        "item-vat-total": "21",
        "page-count": "1/1",
        "quote-validated-time": "Este presupuesto es válido durante 30 días (15/01/2030)"
      },
      "invoice-number": "{language, select, fr {Numéro de facture} it {Numero della fattura} de {Rechnungsnummer} es {Número de factura} other {Invoice number}}",
      "invoice-payment-time": "{number, plural, =0 {{language, select, fr {Veuillez payer la facture à l'émission. ({dueDate})} de {Bitte bezahlen Sie den Betrag direkt nach Erhalt der Rechnung. ({dueDate})} es {Por favor, abona el importe de la factura tras la recepción. ({dueDate})} it {Per favore, paga l’importo della fattura alla sua ricezione. ({dueDate})} other {Please pay the invoice amount upon receipt. ({dueDate})}}} one {{language, select, fr {Veuillez payer la facture sous {days_amount} jour. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tag. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} día. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorno. ({dueDate})} other {Please pay the invoice amount within {days_amount} day. ({dueDate})}}} other {{language, select, fr {Veuillez payer la facture sous {days_amount} jours. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tagen. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} días. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorni. ({dueDate})} other {Please pay the invoice amount within {days_amount} days. ({dueDate})}}}}",
      "issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
      "items": "{language, select, fr {Description} it {Descrizione} de {Beschreibung} es {Descripción} other {Description}}",
      "leadership": "{language, select, fr {Direction de l'entreprise :} de {Geschäftsführung} es {Dirección de la empresa:} it {Direzione dell'impresa:} other {Company leadership:}}",
      "payment-condition": {
        "compensated_for_sales": "{language, select, fr {Sur les débits} de {Im zu zahlenden Betrag enthalten} es {Compensación por ventas} it {Inclusa nel prezzo di vendita} other {Compensated for sales}}",
        "exempt": "{language, select, fr {Exonération} de {Befreit} es {Exento} it {Esente} other {Exempt}}",
        "label": "{language, select, fr {Conditions de paiement de la TVA : } de {MwSt.-Zahlungsbedingung: } es {Condición de pago del IVA: } it {Opzioni per il pagamento dell'IVA: } other {VAT payment condition: }}",
        "on_receipts": "{language, select, fr {Sur les encaissements} de {Sofort} es {Tras la recepción} it {A vista} other {On receipt}}"
      },
      "payment-details": "{language, select, fr {Détails du paiement} it {Dettagli per il pagamento} de {Zahlungsdetails} es {Detalles del pago} other {Payment details}}",
      "pension-contribution": "{language, select, it {Contributo pensione/INPS (IVA escl.)} other {Pension/INPS contribution (excl. VAT)}}",
      "percentage-discount": "{language, select, fr {Remise ({discountPercentage} %)} de {Rabatt ({discountPercentage}%)} es {Descuento ({discountPercentage}%)} it {Sconto ({discountPercentage}%)} other {Discount ({discountPercentage}%)}}",
      "performance-date": "{language, select, fr {Date d'exécution} de {Leistungsdatum} es {Fecha de ejecución} it {Data prestazione} other {Performance date}}",
      "placerholder-stamp-duty": "{language, select, it {Imposta di bollo virtuale pagata ai sensi del decreto ministeriale 17.06.2014} other {Stamp duty paid virtually pursuant to Ministerial Decree 17.06.2014}}",
      "purchase-order": "{language, select, fr {Numéro de bon de commande} it {Numero d'ordine} de {Bestellnummer} es {Número de orden de compra} other {Purchase order number}}",
      "quantity": "{language, select, fr {Qté} it {Qtà} de {Menge} es {Cant.} other {Qty}}",
      "quote-expiry-date": "{language, select, fr {Date d'expiration} it {Data di scadenza} de {Ablaufdatum} es {Fecha de vencimiento} other {Expiry date}}",
      "quote-number": "{language, select, fr {Numéro de devis} it {Numero del preventivo} de {Angebotsnummer} es {Número de presupuesto} other {Quote number}}",
      "quote-related-number": "{language, select, fr {Numéro du devis associé } it {Numero di preventivo correlato } de {Zugehöriges Angebot } es {Número de presupuesto asociado } other {Related quote number }}",
      "quote-title": "{language, select, fr {Devis} de {Angebot} es {Presupuesto} it {Preventivo} other {Quote}}",
      "quote-validated-time": "{number, plural, =0 {} one {{language, select, fr {Ce devis est valable {days_amount} jour. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tag. ({expiry_date})} es {Este presupuesto es válido por {days_amount} día. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorno. ({expiry_date})} other {This quote is valid for {days_amount} day. ({expiry_date})}}} other {{language, select, fr {Ce devis est valable {days_amount} jours. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tage. ({expiry_date})} es {Este presupuesto es válido por {days_amount} días. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorni. ({expiry_date})} other {This quote is valid for {days_amount} days. ({expiry_date})}}}}",
      "reference": "{language, select, fr {Référence} it {Causale} de {Referenz} es {Referencia} other {Reference}}",
      "revenue-stamp": "{language, select, it {Marca da bollo} other {Revenue stamp}}",
      "steuer-id": "{language, select, fr {Steuer-ID} de {Steuer-ID} es {Steuer-ID} it {Steuer-ID} other {Steuer-ID}}",
      "steuernummer": "{language, select, fr {Steuernummer :} de {Steuernummer:} es {Steuernummer:} it {Steuernummer:} other {Steuernummer:}}",
      "title": "{language, select, fr {Facture} de {Rechnung} es {Factura} it {Fattura} other {Invoice}}",
      "total": "{language, select, fr {Total} it {Totale} de {Gesamt} es {Total} other {Total}}",
      "total-vat": "{language, select, fr  {Total TTC} it {Totale (IVA inclusa)}  de {Gesamt (inkl. MwSt.)} es {Total (IVA incluido)} other {Total incl. VAT}}",
      "total-vat-amount": "{language, select, fr {Montant total de la TVA} it {Importo totale IVA} de {MwSt.-Betrag insgesamt} es {Importe total del IVA} other {Total VAT amount}}",
      "total-without-vat": "{language, select, fr {Total HT} it {Totale (IVA escl.)} de {Gesamt (exkl. MwSt.)} es {Total (IVA no incluido)} other {Total excl. VAT}}",
      "transaction-type": {
        "goods": "{language, select, fr {Biens} de {Waren} es {Bienes} it {Beni} other {Goods}}",
        "goods_and_services": "{language, select, fr {Biens et services} de {Waren und Dienstleistungen} es {Bienes y servicios} it {Beni e servizi} other {Goods and services}}",
        "label": "{language, select, fr {Type de transaction : } de {Transaktionstyp: } es {Tipo de transacción: } it {Tipo di transazione: } other {Transaction type: }}",
        "services": "{language, select, fr {Services} de {Dienstleistungen} es {Servicios} it {Servizi} other {Services}}"
      },
      "unit-price": "{language, select, fr {Prix unitaire} it {Prezzo unitario} de {Preis pro Einheit} es {Precio unitario} other {Unit price}}",
      "ust-udnr": "{language, select, fr {USt-IDNr.} de {USt-IDNr.} es {USt-IDNr.} it {USt-IDNr.} other {USt-IDNr.}}",
      "vat": "{language, select, fr {TVA (%)} it {IVA (%)} de {MwSt. (%)} es {IVA (%)} other {VAT (%)}}",
      "vat-amount": "{language, select, fr {Montant de la TVA} it {Importo IVA} de {MwSt-Betrag} es {Importe del IVA} other {VAT amount}}",
      "vat-number": "{language, select, fr {Numéro de TVA :} de {USt.-IdNr.:} es {Número de IVA:} it {Partita IVA:} other {VAT number:}}",
      "withholding-tax": "{language, select, it {Ritenuta d'acconto} other {Withholding tax}}"
    },
    "spinner": {
      "label": "Cargando"
    },
    "stepper-header": {
      "back": "Atrás",
      "close": "Cerrar"
    },
    "translation-with-args": {
      "body-1": "Texto con {var} dentro.",
      "body-3": "Texto con {var1} dentro y después otro {var2} dentro y después otro {var3} dentro."
    },
    "zxcvbn": {
      "password_common": "La contraseña es demasiado sencilla, y por lo tanto, fácil de descifrar.",
      "password_date": "Evita poner fechas. Son demasiado comunes.",
      "password_dictionary": "La contraseña es demasiado sencilla, y por lo tanto, fácil de descifrar.",
      "password_repeat": "Las repeticiones de palabras o de letras no son seguras.",
      "password_sequence": "Las repeticiones de palabras o de letras no son seguras.",
      "password_spatial": "Las repeticiones de palabras o de letras no son seguras."
    }
  },
  "welcome": {
    "gdpr_checkbox": "Autorizo a Qonto a hacer uso de mis datos personales",
    "gdpr_checkbox_error_message": "Para continuar, acepta nuestra política de uso de datos.",
    "redirect_to_appointment_page": "Solicita información a uno de nuestros expertos",
    "redirect_to_bridge": "Empezar la migración",
    "step_1_subtitle": "Mediante el servicio seguro de Bridge by Bankin'.",
    "step_1_title": "Conecta tu banco a Qonto",
    "step_2_subtitle": "Tus datos se sincronizarán de forma segura.",
    "step_2_title": "Selecciona la(s) cuenta(s) que quieres migrar",
    "step_3_subtitle": "Para responder a todas tus preguntas",
    "step_3_title": "Un asesor de Qonto te guiará a lo largo del proceso",
    "subtitle": "Y optimiza la gestión de tus finanzas",
    "title": "Migra tus cuentas fácilmente"
  }
}], ["fr", {
  "application": {
    "back_to_app": "Revenir à Qonto",
    "go_back": "Retour",
    "security_disclaimer": "Ce service est sécurisé. Toutes les données sont strictement réservées à l'usage de Qonto et peuvent être supprimées sur demande à tout moment.",
    "security_disclaimer_link": "En savoir plus",
    "title": "Transfert de compte"
  },
  "appointment": {
    "back_cta": "Retour",
    "calendar_subtitle": "Prenez rendez-vous avec votre conseiller Qonto pour préparer votre transfert de compte.",
    "calendar_title": "Rencontrez votre conseiller Qonto"
  },
  "bank_selection": {
    "consent_modal": {
      "checkbox_text": "Je consens à l'utilisation de mes données personnelles par Qonto.",
      "primary_CTA": "Confirmer",
      "secondary_CTA": "Annuler",
      "subtitle": "Pour des raisons légales, nous avons besoin que vous acceptiez notre politique d'utilisation des données avant de connecter votre banque à Qonto via le service sécurisé Bridge by Bankin'.",
      "title": "Acceptez cette condition"
    },
    "select_child_bank": {
      "back_CTA": "Retour",
      "individual": "Compte personnel",
      "pro": "Compte professionnel",
      "subtitle": "{bank_name} a plusieurs entités donc veillez bien à sélectionner celle qui vous correspond. Si vous avez le moindre doute, nous vous conseillons de contacter votre banque.",
      "title": "Sélectionnez votre entité bancaire"
    },
    "select_parent_bank": {
      "cannot_find": "Votre banque n'est pas dans la liste ?",
      "search_empty": {
        "CTA": "Nous contacter",
        "subtitle": "Indiquez-nous la banque qu'il vous manque en nous contactant via notre chat. Notre équipe fera de son mieux pour vous accompagner dans votre transfert de compte.",
        "title": "Cette banque n'est pas (encore) répertoriée"
      },
      "search_placeholder": "Rechercher une banque...",
      "title": "Sélectionnez votre banque"
    }
  },
  "bankswitch": {
    "backend_error": {
      "toaster": "Une erreur s’est produite. Pouvez-vous rafraîchir la page ?"
    }
  },
  "btn": {
    "back": "Retour"
  },
  "choose_organization": {
    "continue_button": "Continuer",
    "error_message": "Pour continuer, vous devez sélectionner une entreprise.",
    "subtitle": "Sélectionnez une entreprise vers laquelle transférer votre compte.",
    "title": "Sélectionnez une entreprise"
  },
  "dashboard": {
    "CTA": {
      "new_action": "Créer une action"
    },
    "account_details": {
      "bic": "BIC",
      "disclaimer": "Vous ne pouvez migrer qu'une seule banque pour le moment, le multi-banques arrive bientôt.",
      "iban": "IBAN",
      "no_iban": "Vous trouverez votre IBAN sur l'espace client de votre banque.",
      "no_iban_link": "Accédez à votre IBAN sur Qonto.",
      "notifications": {
        "bic_copy": "Votre BIC est copié !",
        "iban_copy": "Votre IBAN est copié !"
      }
    },
    "action": {
      "important": {
        "tag": "Important"
      },
      "menu": {
        "CTA_delete": "Supprimer",
        "CTA_edit": "Modifier",
        "CTA_flag_as_important": "Indiquer comme important",
        "CTA_ignore": "Ignorer",
        "CTA_mark_as_done": "Indiquer comme traité",
        "CTA_unflag_as_important": "Indiquer comme non important",
        "CTA_unignore": "Rétablir",
        "CTA_unmark_as_done": "Indiquer comme non traité"
      }
    },
    "card": {
      "action": {
        "ignored": "Ignorer",
        "reactive": "Rétablir"
      },
      "category": {
        "cards": "Cartes",
        "mandates": "Mandats",
        "others": "Autres",
        "transfers": "Virements"
      },
      "last_operation": "Dernière :",
      "last_operation_detected": "Dernière opération détectée :",
      "operations": "{count, plural,=1 {Opération :} other {Opérations :}}"
    },
    "create_action": {
      "toaster": "Une nouvelle action a été créée avec succès."
    },
    "create_form": {
      "CTA_cancel": "Annuler",
      "CTA_create": "Créer",
      "category_field": {
        "placeholder": "Sélectionnez une catégorie",
        "title": "Catégorie"
      },
      "category_list": {
        "atm": "Retraits",
        "fees": "Frais",
        "finance": "Finance",
        "hotel_and_lodging": "Hôtels & Logement",
        "insurance": "Assurance",
        "legal_and_accounting": "Juridique & Comptabilité",
        "logistics": "Logistique",
        "marketing": "Marketing",
        "office_rental": "Location de bureau",
        "office_supply": "Fournitures de bureau",
        "online_service": "Services en ligne",
        "other": "Autre",
        "pending": "En attente",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Salaires",
        "sales": "Ventes",
        "tax": "Impôts & Taxes",
        "transport": "Transport",
        "utility": "Utilitaire"
      },
      "note_field": {
        "placeholder": "Ajoutez une note",
        "title": "Informations complémentaires"
      },
      "title": {
        "placeholder": "Nouvelle tâche"
      },
      "title_field": {
        "placeholder": "Donnez un nom à cette tâche",
        "title": "Nom"
      },
      "type_field": {
        "placeholder": "Sélectionnez une méthode de paiement",
        "title": "Méthode"
      }
    },
    "credit": {
      "no_actions": {
        "subtitle": "Nous n'avons trouvé aucune transaction à transférer depuis ce compte. N'hésitez pas à nous contacter si vous pensez qu'il s’agit d’une erreur.",
        "title": "Aucun client récurrent"
      },
      "sections": {
        "all": {
          "methods": "Toutes les méthodes"
        },
        "cards": "Cartes",
        "cards_tooltip": "Enregistrez votre nouvelle carte Qonto auprès de ces partenaires de paiement.",
        "done": "Traité",
        "ignored": "Ignoré",
        "others": "Autres",
        "others_tooltip": "Ces actions doivent être traitées individuellement. Contactez votre conseiller Qonto pour obtenir de l’aide.",
        "todo": "À traiter",
        "transfers": "Virements",
        "transfers_tooltip": "Ajoutez ces bénéficiaires à votre compte Qonto."
      },
      "subtitle": "Envoyez vos nouvelles coordonnées bancaires à vos clients et partenaires pour recevoir vos premiers paiements sur votre compte Qonto.",
      "title": "Clients récurrents"
    },
    "debit": {
      "no_actions": {
        "subtitle": "Nous n'avons trouvé aucune transaction à transférer depuis ce compte. N'hésitez pas à nous contacter si vous pensez qu'il s’agit d’une erreur.",
        "title": "Aucun fournisseur récurrent"
      },
      "sections": {
        "all": {
          "methods": "Toutes les méthodes"
        },
        "cards": "Cartes",
        "cards_tooltip": "Enregistrez votre nouvelle carte Qonto auprès de ces fournisseurs.",
        "done": "Traité",
        "ignored": "Ignoré",
        "mandates": "Mandats",
        "mandates_tooltip": "Annulez vos mandats actuels et émettez-en de nouveaux avec votre IBAN Qonto.",
        "others": "Autres",
        "others_tooltip": "Ces actions doivent être traitées individuellement. Contactez votre conseiller Qonto pour obtenir de l’aide.",
        "todo": "À traiter",
        "transfers": "Virements",
        "transfers_tooltip": "Ajoutez ces bénéficiaires à votre compte Qonto."
      },
      "subtitle": "Partagez vos nouvelles coordonnées bancaires avec vos fournisseurs pour garantir le traitement de vos futurs paiements.",
      "title": "Fournisseurs récurrents"
    },
    "delete_action": {
      "modal": {
        "CTA_cancel": "Annuler",
        "CTA_delete": "Supprimer",
        "body": "Gardez en tête que la suppression de cette action est définitive.",
        "title": "Supprimer cette action ?"
      },
      "toaster": "Cette action a été supprimée avec succès."
    },
    "edit_action": {
      "toaster": "Cette action a été modifiée avec succès."
    },
    "edit_form": {
      "CTA_cancel": "Annuler",
      "CTA_save": "Enregistrer les modifications",
      "category_field": {
        "placeholder": "Sélectionnez une catégorie",
        "title": "Catégorie"
      },
      "category_list": {
        "atm": "Retraits",
        "fees": "Frais",
        "finance": "Finance",
        "hotel_and_lodging": "Hôtels & Logement",
        "insurance": "Assurance",
        "legal_and_accounting": "Juridique & Comptabilité",
        "logistics": "Logistique",
        "marketing": "Marketing",
        "office_rental": "Location de bureau",
        "office_supply": "Fournitures de bureau",
        "online_service": "Services en ligne",
        "other": "Autre",
        "pending": "En attente",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Salaires",
        "sales": "Ventes",
        "tax": "Impôts & Taxes",
        "transport": "Transports",
        "utility": "Utilitaire"
      },
      "note_field": {
        "placeholder": "Ajoutez une note",
        "title": "Informations complémentaires"
      },
      "title_field": {
        "placeholder": "Donnez un nom à cette tâche",
        "title": "Nom"
      },
      "type_field": {
        "placeholder": "Sélectionnez une méthode de paiement",
        "title": "Méthode"
      }
    },
    "operation_history": {
      "back_to_credits_cta": "Revenir à Clients",
      "back_to_debits_cta": "Revenir à Fournisseurs",
      "cancel_cta": "Indiquer comme non traité",
      "check_cta": "Indiquer comme traité",
      "ignore_cta": "Ignorer",
      "reactive_cta": "Rétablir",
      "subtitle": {
        "credit": "{count, plural,=1 {{count} opération est associée à ce client} other {{count} opérations sont associées à ce client}}",
        "debit": "{count, plural,=1 {{count} opération est associée à ce fournisseur} other {{count} opérations sont associées à ce fournisseur}}"
      },
      "table_field": {
        "amount": "Montant",
        "date": "Date",
        "label": "Transaction"
      }
    },
    "overview": {
      "cards": {
        "dropdown": {
          "title": "Tous les comptes"
        },
        "last_update": {
          "day": "Mis à jour il y a {count} {count, plural, =1 {jour} other {jours}}",
          "month": "Mis à jour il y a {count} mois",
          "year": "Mis à jour il y a {count} {count, plural, =1 {an} other {ans}}"
        }
      },
      "faq": {
        "first_article": {
          "description": "Découvrez l'agrégation bancaire et apprenez à synchroniser votre ancienne banque avec Qonto.",
          "link": "https://help.qonto.com/fr/articles/5020434",
          "title": "Comment fonctionne le service de transfert de compte ?"
        },
        "second_article": {
          "description": "Retrouvez les astuces et conseils de nos experts pour changer de banque en toute simplicité.",
          "link": "https://help.qonto.com/fr/articles/5145573",
          "title": "Comment suivre l'avancée de mon transfert de compte ?"
        },
        "title": "Tout savoir sur le transfert de compte"
      },
      "popin": {
        "end_migration_completed": {
          "cta": {
            "cancel": "Annuler",
            "end_transfer": "Confirmer"
          },
          "title": "Confirmez que votre transfert de compte est terminé",
          "wording": "Votre transfert de compte de {bank_name} à Qonto pour {company_name} est bientôt terminé. Toutes les données liées à votre ancien compte seront alors supprimées."
        },
        "end_migration_not_completed": {
          "cta": {
            "cancel": "Annuler",
            "end_transfer": "Terminer le transfert"
          },
          "title": "Terminez votre transfert de compte",
          "wording": "Êtes-vous sûr ? Votre transfert de compte de {bank_name} à Qonto pour {company_name} prendra fin et vos données seront supprimées. Vous changez d'avis ? (Re)démarrez un nouveau transfert à tout moment."
        }
      },
      "stepper": {
        "end_migration": "Terminer le transfert",
        "file_link": "Télécharger le guide",
        "step1": {
          "progress_bar": {
            "completed": "Terminé",
            "inprogress": "En cours"
          },
          "subtitle1": {
            "description": "Pour garantir le traitement de vos futurs paiements.",
            "title": "Fournisseurs"
          },
          "subtitle2": {
            "description": "Pour recevoir de l'argent sur votre compte Qonto.",
            "title": "Clients"
          },
          "title": "Partagez vos nouvelles coordonnées bancaires à vos fournisseurs et clients"
        },
        "step2": {
          "subtitle": "Jetez un œil à notre guide pour en savoir plus sur la marche à suivre.",
          "title": "Préparez la fermeture de votre ancien compte"
        },
        "step3": {
          "subtitle": "Confirmez que votre transfert de compte est terminé une fois les étapes précédentes complétées.",
          "title": "Terminer le transfert"
        },
        "step_link": {
          "completed": "Voir les détails",
          "uncompleted": "{count} {count, plural,=1 {action à réaliser} other {actions à réaliser}}"
        },
        "title": "Progression du transfert"
      },
      "subtitle": "Dans ce tableau de bord, vous trouverez toutes les informations relatives au transfert de votre compte.",
      "title": "Suivez l’avancée de votre transfert de compte"
    },
    "side": {
      "credit": "Clients",
      "debit": "Fournisseurs",
      "overview": "Suivi",
      "settings": "Réglages",
      "settings_items": {
        "add_accounts": "Ajouter un autre compte",
        "back_to_app": "Revenir à Qonto",
        "ends_transfer_cta": "Terminer le transfert",
        "support": "Aide",
        "support_link": "https://help.qonto.com/fr",
        "switch_organization": "Changer d'entreprise"
      }
    }
  },
  "dashboard_generation": {
    "waiting_screen": {
      "description": "Notre équipe se charge d'analyser et de lister vos transactions récurrentes. Cela peut prendre de <b>4 à 10 minutes</b> et vous recevrez un e-mail une fois votre tableau de bord prêt. Inutile donc de patienter ici.",
      "disclaimer": {
        "part_1": "Ce service est sécurisé. Toutes les données sont strictement réservées à l'usage de Qonto et peuvent être supprimées sur demande à tout moment.",
        "part_2": "En savoir plus"
      },
      "title": "Votre tableau de bord est en cours de création"
    }
  },
  "dynamic-values": {
    "dates": {
      "current-month": "%mois%",
      "current-year": "%année%",
      "last-month": "%moisdernier%",
      "next-month": "%moisprochain%"
    }
  },
  "empty-state-discover": {
    "divider": "ou"
  },
  "ends_transfer": {
    "confirmation_modal": {
      "cancel_cta": "Annuler",
      "confirm_cta": "Terminez le transfert",
      "subtitle": "Êtes-vous sûr ? Votre transfert de compte de {bank_name} à Qonto pour {company_name} prendra fin et vos données seront supprimées. Vous changez d'avis ? (Re)démarrez un nouveau transfert à tout moment.",
      "title": "Terminer"
    }
  },
  "error_1010": {
    "disclaimer": {
      "body": "Vos données ne sont pas à jour: vous devez ressaisir le code de confirmation reçu par SMS",
      "link": "juste ici"
    }
  },
  "error_429": {
    "disclaimer": {
      "body_action1": "Vos données ne sont pas à jour : vous devez accepter les conditions générales sur votre compte {bank_name}.",
      "body_action10": "Vos données ne sont pas à jour : vous devez réactiver l'authentification forte sur votre compte {bank_name}.",
      "body_action11": "Vos données ne sont pas à jour : vous devez obtenir l'accès aux services en ligne en contactant votre banque {bank_name}.",
      "body_action12": "Vos données ne sont pas à jour : vous devez contacter le service client de votre banque {bank_name}.",
      "body_action13": "Vos données ne sont pas à jour : vous devez renouveler ou activer vos identifiants en contactant votre banque {bank_name}.",
      "body_action14": "Vos données ne sont pas à jour : vous devez modifier votre mot de passe sur votre compte {bank_name}.",
      "body_action15": "Vos données ne sont pas à jour : vous devez créer un mot de passe en vous connectant une première fois sur votre compte {bank_name}.",
      "body_action2": "Vos données ne sont pas à jour : vous devez signer un contrat sur votre compte {bank_name}.",
      "body_action3": "Vos données ne sont pas à jour : vous devez activer ou renseigner vos identifiants sur votre compte {bank_name}.",
      "body_action4": "Vos données ne sont pas à jour : vous devez activer votre compte {bank_name}.",
      "body_action5": "Vos données ne sont pas à jour : vous devez renseigner ou confirmer le numéro de téléphone associé à votre compte {bank_name}.",
      "body_action6": "Vos données ne sont pas à jour : vous devez valider des informations ou un message sur votre compte {bank_name}.",
      "body_action7": "Vos données ne sont pas à jour : vous devez confirmer ou compléter des informations personnelles sur votre compte {bank_name}.",
      "body_action8": "Vos données ne sont pas à jour : vous devez confirmer et/ou modifier vos coordonnées sur votre compte {bank_name}.",
      "body_action9": "Vos données ne sont pas à jour : vous devez créer un compte {bank_name}."
    }
  },
  "error_430": {
    "disclaimer": {
      "body": "Vos données ne sont pas à jour : vous devez modifier votre mot de passe sur votre compte {bank_name}."
    }
  },
  "error_management_screen": {
    "generic_fallback_error": {
      "description": "Une erreur nous empêche de créer automatiquement votre tableau de bord. Planifiez un appel avec un conseiller Qonto pour obtenir de l'aide et être accompagné.",
      "primary_cta": "Planifier un appel",
      "title": "Votre tableau de bord n’a pas pu être créé"
    }
  },
  "information_appointment": {
    "calendar_subtitle": "Prenez rendez-vous pour préparer votre transfert de compte avec un conseiller dédié.",
    "calendar_title": "Rencontrez votre conseiller Qonto"
  },
  "labels": {
    "pagination_summary": "sur"
  },
  "logout": {
    "btn": "Retour à l'écran de connexion",
    "modal": {
      "accept_btn": "Continuer sur Qonto",
      "close_btn": "Me déconnecter",
      "content": "Dans 5 minutes, votre session atteindra sa limite de 30 minutes. Afin de garantir la sécurité de votre compte, vous serez automatiquement déconnecté(e). Vous pouvez choisir de prolonger votre session ci-dessous.",
      "title": "Êtes-vous toujours là ?"
    },
    "subtitle": "Pour des raisons de sécurité, vos sessions sur Qonto sont limitées à 30 minutes. Vous avez donc été automatiquement déconnecté.",
    "title": "Vous avez été déconnecté"
  },
  "maintenance": {
    "cta": "Revenir à Qonto",
    "subtitle": "Il semblerait qu'il y ait un problème. Rassurez-vous, notre équipe met tout en œuvre pour le résoudre au plus vite. En attendant, contactez votre conseiller Qonto si vous avez besoin d'aide.",
    "title": "Une erreur s'est produite"
  },
  "migration_status": {
    "appointment_successfully_made_redirection_toast": "Votre rendez-vous est confirmé !",
    "end_migration": "Clôturer le transfert",
    "step_1_subtitle": "Pour générer un plan de transfert de compte sur-mesure.",
    "step_1_title": "Vos transactions sont en cours d'analyse",
    "step_2_subtitle": "Pour discuter de votre plan de transfert de compte personnalisé.",
    "step_2_title": "C’est l’heure d’un premier entretien avec votre conseiller Qonto",
    "step_3_subtitle": "Vous recevrez des mises à jour et des rappels à chaque étape.",
    "step_3_title": "Votre transfert de compte est entre de bonnes mains",
    "step_4_subtitle": "C’est parti ! Pilotez dès maintenant votre compte pro depuis votre application Qonto.",
    "step_4_title": "Votre transfert de compte est terminé !",
    "subtitle": "Votre transfert de compte est en cours. Suivez sa progression ici.",
    "title": "Suivez l'avancée de votre transfert de compte"
  },
  "migration_succeeded": {
    "back_to_qonto": "Accéder à mon compte Qonto",
    "end_migration": {
      "file_link": "Télécharger le guide",
      "title1": "Finalisez la fermeture",
      "title2": "de votre ancien compte",
      "wording": "Vous n'avez pas encore fermé votre ancien compte ? Pensez à jeter un œil à notre guide pour en savoir plus sur la marche à suivre."
    },
    "subtitle": "Vous pouvez maintenant profiter de toutes les fonctionnalités exclusives Qonto. N'hésitez pas à nous contacter en cas de besoin.",
    "switch_another_bank": "Transférer un autre compte chez Qonto",
    "title": "Félicitations, votre transfert de compte est terminé !"
  },
  "no_mobile": {
    "back_to_qonto": "Revenir à Qonto",
    "subtitle": "Ce service est disponible exclusivement sur ordinateur pour l'instant.",
    "title": "Rendez-vous sur l'appli web !"
  },
  "no_rights": {
    "subtitle": "Seul un Admin peut effectuer un transfert de compte.",
    "title": "Vous n'avez malheureusement pas accès à ce service"
  },
  "not_found": {
    "cta": "Revenir à Qonto",
    "subtitle": "Nous avons bien cherché mais cette page reste introuvable. Elle a peut-être été déplacée ou supprimée, ou bien une erreur s'est glissée dans l'URL.",
    "title": "Cette page est introuvable"
  },
  "onboarding_funnel": {
    "OTP_modal": {
      "code": "Code",
      "error_text": "Code invalide",
      "link": "Vous n’avez rien reçu ? Recevoir un nouveau code.",
      "primary_CTA": "Annuler",
      "secondary_CTA": "Valider",
      "text": "Veuillez renseigner le code de vérification temporaire reçu par SMS.",
      "title": "Code de vérification"
    },
    "app_modal": {
      "link": "Besoin d'aide ?",
      "text": "Pour continuer, vous devez confirmer la connexion depuis l’application mobile de votre banque.",
      "title": "Rendez-vous sur votre téléphone"
    },
    "consent": {
      "CTA_1": "Annuler",
      "CTA_2": "Confirmer",
      "checkbox": {
        "first": "En cliquant ici, j’accepte les conditions générales du service et je confirme avoir pris connaissance de la ",
        "link": "politique de protection des données de Qonto.",
        "second": ""
      },
      "text": "Qonto vous propose de connecter l’ensemble de vos comptes bancaires et de paiement ouverts auprès d’établissements tiers à votre compte Qonto.<br>Qonto est agréé par l’ACPR pour vous fournir ce service d'agrégation et d’information sur les comptes et s’appuie sur les infrastructures de Bankin’.<br>L’accessibilité de certains de vos comptes ne peut cependant être garantie.<br><br>Vos données bancaires et vos données personnelles sont protégées conformément à la réglementation européenne (Directive sur les services de paiement et Règlement général sur la protection des données personnelles).",
      "title": "Qonto respecte vos données"
    },
    "credentials": {
      "4digitcode_title": "Code à 4 chiffres",
      "CTA": "Se connecter",
      "accesscode_title": "Code d'accès",
      "accountnumber_placeholder": "Saisissez votre numéro de compte",
      "accountnumber_title": "Numéro de compte",
      "answer_placeholder": "Saisissez votre réponse",
      "answer_title": "Réponse à la question de sécurité",
      "birthdate_placeholder": "JJ/MM/AAAA",
      "birthdate_title": "Date de naissance",
      "checkbox": "Je certifie sur l'honneur être en droit et disposer des autorisations nécessaires pour accéder aux informations du compte.",
      "clientnumber_placeholder": "Saisissez votre numéro client",
      "clientnumber_title": "Numéro client",
      "code_placeholder": "Saisissez votre code utilisateur",
      "code_title": "Code utilisateur",
      "companynumber_placeholder": "Saisissez votre numéro d'identifiant entreprise",
      "companynumber_title": "Numéro d'identifiant entreprise",
      "confidentialcode_title": "Code confidentiel",
      "email_placeholder": "jeanne.durant@monentreprise.com",
      "email_title": "Adresse e-mail",
      "id_placeholder": "Saisissez votre identifiant",
      "id_title": "Identifiant",
      "password_placeholder": "Saisissez votre mot de passe",
      "password_title": "Mot de passe",
      "phonenumber_placeholder": "+33 12 345 6789",
      "phonenumber_title": "Numéro de téléphone avec indicatif (+33...)",
      "servercode_title": "Code serveur",
      "subscriptionnumber_placeholder": "Saisissez votre numéro d'abonné",
      "subscriptionnumber_title": "Numéro d'abonné",
      "text": "Utilisez les mêmes identifiants que lors de votre connexion habituelle sur {bank_name}.<br/>Rassurez-vous, Qonto ne sauvegarde aucune de ces informations.",
      "title": "Connectez-vous à {bank_name}",
      "usernumber_placeholder": "Saisissez votre numéro d'usager",
      "usernumber_title": "Numéro d'usager"
    },
    "error_1005": {
      "primary_CTA": "Planifier un appel",
      "secondary_CTA": "Revenir à Qonto",
      "text": "Une erreur nous empêche de nous connecter à votre banque. Rassurez-vous, un agent Qonto est là pour résoudre le problème et vous accompagner tout au long du transfert.",
      "title": "La connexion avec {bank_name} a échoué"
    },
    "error_402": {
      "link": "choisi la bonne entité bancaire ?",
      "text": "Il semblerait que la connexion ait échoué. Pouvez-vous vérifier vos identifiants et vous assurer d’avoir "
    },
    "success": {
      "CTA": "Transférer vos comptes",
      "text": "C’est presque fini, il ne vous reste plus qu’à sélectionner les comptes que vous souhaitez transférer.",
      "title": "La récupération a bien été effectuée"
    },
    "synchronization": {
      "subtitle": "Notre équipe récupère les informations de votre compte {bank_name}. Cela peut prendre quelques minutes, veillez à rester sur cette page.",
      "text": "Récupération des informations de votre compte {bank_name}...",
      "title": "Un instant, nous consultons votre banque"
    }
  },
  "optional_cs": {
    "select_method": {
      "main_page": {
        "algorithm_bloc": {
          "argument1": "Déléguez la création de votre tableau de bord à notre équipe",
          "argument2": "Recevez une notification dès que votre tableau de bord est prêt",
          "argument3": "Accédez à votre tableau de bord et laissez-vous guider",
          "description": "Gagnez en temps et en autonomie",
          "primary_cta": "Choisir",
          "tag_recommended": "Recommandé",
          "title": {
            "1": "Un tableau de bord",
            "2": "créé automatiquement"
          }
        },
        "customer_service_bloc": {
          "argument1": "Planifiez un appel quand vous le souhaitez",
          "argument2": "Créez un tableau de bord avec l'aide de votre conseiller",
          "argument3": "Accédez à votre tableau de bord et laissez-vous guider",
          "description": "Soyez accompagné à chaque étape",
          "primary_cta": "Choisir",
          "title": {
            "1": "Un appel avec un",
            "2": "conseiller Qonto dédié"
          }
        },
        "title": "Choisissez le type d'accompagnement qu'il vous faut"
      }
    }
  },
  "pdf-preview": {
    "items-links": "{number, plural, =0 {} one {{language, select, fr {Lien :} de {Link:} es {Enlace:} it {Link:} other {Link:}}} other {{language, select, fr {Liens :} de {Links:} es {Enlaces:} it {Link:} other {Links:}}}}",
    "unit": {
      "ampere": "{number, plural, =0 {} one {{language, select, fr {ampère} de {Ampere} es {amperio} it {ampere} other {ampere}}} other {{language, select, fr {ampères} de {Ampere} es {amperios} it {ampere} other {amperes}}}}",
      "centimeter": "{number, plural, =0 {} one {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}} other {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}}}",
      "cubic_meter": "{number, plural, =0 {} one {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}} other {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}}}",
      "cubic_meter_per_hour": "{number, plural, =0 {} one {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}} other {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}}}",
      "day": "{number, plural, =0 {} one {{language, select, fr {jour} de {Tag} es {día} it {giornata} other {day}}} other {{language, select, fr {jours} de {Tage} es {días} it {giornate}  other {days}}}}",
      "gigajoule": "{number, plural, =0 {} one {{language, select, fr {gigajoule} de {Gigajoule} es {gigajulio} it {gigajoule} other {gigajoule}}} other {{language, select, fr {gigajoules} de {Gigajoule} es {gigajulios} it {gigajoule} other {gigajoules}}}}",
      "gigawatt": "{number, plural, =0 {} one {{language, select, fr {gigawatt} de {Gigawatt} es {gigavatio} it {gigawatt} other {gigawatt}}} other {{language, select, fr {gigawatts} de {Gigawatt} es {gigavatios} it {gigawatt} other {gigawatts}}}}",
      "gigawatt_hour": "{number, plural, =0 {} one {{language, select, fr {gigawattheure} de {Gigawattstunde} es {gigavatio-hora} it {gigawattora} other {gigawatt hour}}} other {{language, select, fr {gigawattheures} de {Gigawattstunden} es {gigavatios-hora} it {gigawattora} other {gigawatt hours}}}}",
      "gram": "{number, plural, =0 {} one {{language, select, fr {g} de {g} es {g} it {g} other {g}}} other {{language, select, fr {g} de {g} es {g} it {g} other {g}}}}",
      "half_year": "{number, plural, =0 {} one {{language, select, fr {semestre} de {halbes Jahr} es {semestre} it {semestre} other {half-year}}} other {{language, select, fr {semestres} de {halbe Jahre} es {semestres} it {semestri} other {half-years}}}}",
      "hour": "{number, plural, =0 {} one {{language, select, fr {heure} de {Stunde} es {hora} it {ora} other {hour}}} other {{language, select, fr {heures} de {Stunden} es {horas} it {ore} other {hours}}}}",
      "joule": "{number, plural, =0 {} one {{language, select, fr {joules} de {Joule} es {julio} it {joule} other {joule}}} other {{language, select, fr {joules} de {Joule} es {julios} it {joule} other {joules}}}}",
      "kilogram": "{number, plural, =0 {} one {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}} other {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}}}",
      "kilojoule": "{number, plural, =0 {} one {{language, select, fr {kilojoule} de {Kilojoule} es {kilojulio} it {kilojoule} other {kilojoule}}} other {{language, select, fr {kilojoules} de {Kilojoule} es {kilojulios} it {kilojoule} other {kilojoules}}}}",
      "kilovar": "{number, plural, =0 {} one {{language, select, fr {kilovar} de {Kilovar} es {kilovar} it {kilovar} other {kilovar}}} other {{language, select, fr {kilovars} de {Kilovar} es {kilovares} it {kilovar} other {kilovars}}}}",
      "kilovolt_ampere_reactive_hour": "{number, plural, =0 {} one {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}} other {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}}}",
      "kilowatt": "{number, plural, =0 {} one {{language, select, fr {kilowatt} de {Kilowatt} es {kilovatio} it {chilowatt} other {kilowatt}}} other {{language, select, fr {kilowatts} de {Kilowatt} es {kilovatios} it {chilowatt} other {kilowatts}}}}",
      "kilowatt_hour": "{number, plural, =0 {} one {{language, select, fr {kilowattheure} de {Kilowattstunde} es {kilovatio-hora} it {chilowattora} other {kilowatt hour}}} other {{language, select, fr {kilowattheures} de {Kilowattstunden} es {kilovatios-hora} it {chilowattora} other {kilowatt hours}}}}",
      "liter": "{number, plural, =0 {} one {{language, select, fr {L} de {l} es {l} it {L} other {L}}} other {{language, select, fr {to complete} de {l} es {l} it {L} other {L}}}}",
      "megajoule": "{number, plural, =0 {} one {{language, select, fr {mégajoule} de {Megajoule} es {megajulio} it {megajoule} other {megajoule}}} other {{language, select, fr {mégajoules} de {Megajoule} es {megajulios} it {megajoule} other {megajoules}}}}",
      "megawatt": "{number, plural, =0 {} one {{language, select, fr {mégawatt} de {Megawatt} es {megavatio} it {megawatt} other {megawatt}}} other {{language, select, fr {mégawatts} de {Megawatt} es {megavatios} it {megawatt} other {megawatts}}}}",
      "megawatt_hour": "{number, plural, =0 {} one {{language, select, fr {mégawattheure} de {Megawattstunde} es {megavatio-hora} it {megawattora} other {megawatt hour}}} other {{language, select, fr {mégawattheures} de {Megawattstunden} es {megavatios-hora} it {megawattora} other {megawatt hours}}}}",
      "meter": "{number, plural, =0 {} one {{language, select, fr {m} de {m} es {m} it {m} other {m}}} other {{language, select, fr {m} de {m} es {m} it {m} other {m}}}}",
      "milligram": "{number, plural, =0 {} one {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}} other {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}}}",
      "milliliter": "{number, plural, =0 {} one {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}} other {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}}}",
      "millimeter": "{number, plural, =0 {} one {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}} other {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}}}",
      "minute": "{number, plural, =0 {} one {{language, select, fr {minute} de {Minute} es {minuto} it {minuto} other {minute}}} other {{language, select, fr {minutes} de {Minuten} es {minutos} it {minuti} other {minutes}}}}",
      "month": "{number, plural, =0 {} one {{language, select, fr {mois} de {Monat} es {mes} it {mese} other {month}}} other {{language, select, fr {mois} de {Monate} es {meses} it {mesi} other {months}}}}",
      "number_of_articles": "{number, plural, =0 {} one {{language, select, fr {article} de {Artikel} es {artículo} it {pezzo} other {article}}} other {{language, select, fr {articles} de {Artikel} es {artículos} it {pezzi} other {articles}}}}",
      "pair": "{number, plural, =0 {} one {{language, select, fr {paire} de {Paar} es {par} it {paio} other {pair}}} other {{language, select, fr {paires} de {Paare} es {pares} it {paia} other {pairs}}}}",
      "quarter": "{number, plural, =0 {} one {{language, select, fr {trimestre} de {Viertel} es {trimestre} it {trimestre} other {quarter}}} other {{language, select, fr {trimestres} de {Viertel} es {trimestres} it {trimestri} other {quarters}}}}",
      "second": "{number, plural, =0 {} one {{language, select, fr {seconde} de {Sekunde} es {segundo} it {secondo} other {second}}} other {{language, select, fr {secondes} de {Sekunden} es {segundos} it {secondi} other {seconds}}}}",
      "set": "{number, plural, =0 {} one {{language, select, fr {ensemble} de {Set} es {juego} it {assortimento} other {set}}} other {{language, select, fr {ensembles} de {Sets} es {juegos} it {assortimenti} other {sets}}}}",
      "square_meter": "{number, plural, =0 {} one {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}} other {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}}}",
      "ton": "{number, plural, =0 {} one {{language, select, fr {t} de {t} es {t} it {t} other {T}}} other {{language, select, fr {t} de {t} es {t} it {t} other {T}}}}",
      "two_week": "{number, plural, =0 {} one {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two-week}}} other {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two weeks}}}}",
      "unit": "{number, plural, =0 {} one {{language, select, fr {unité} de {Einheit} es {unidad} it {unità} other {unit}}} other {{language, select, fr {unités} de {Einheiten} es {unidades} it {unità} other {units}}}}",
      "watt_hour": "{number, plural, =0 {} one {{language, select, fr {wattheure} de {Wattstunde} es {vatio-hora} it {wattora} other {watt hour}}} other {{language, select, fr {wattheures} de {Wattstunden} es {vatios-hora} it {wattora} other {watt hours}}}}",
      "week": "{number, plural, =0 {} one {{language, select, fr {semaine} de {Woche} es {semana} it {settimana} other {week}}} other {{language, select, fr {semaines} de {Wochen} es {semanas} it {settimane} other {weeks}}}}",
      "year": "{number, plural, =0 {} one {{language, select, fr {année} de {Jahr} es {año} it {anno} other {year}}} other {{language, select, fr {années} de {Jahre} es {años} it {anni} other {years}}}}"
    }
  },
  "product_tour": {
    "banner": {
      "CTA_primary": "Connecter votre banque",
      "CTA_secondary": "Rejouer la démo"
    },
    "clients": {
      "action1": {
        "title": "Agence Marketing",
        "transactions": "Paiement Agence Marketing"
      },
      "action2": {
        "title": "Boulangerie",
        "transactions": "Débit carte Boulangerie"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "paiement Qonto Airlines"
      },
      "action4": {
        "title": "Médecine du Travail",
        "transactions": "Paiement carte Médecine du Travail"
      },
      "action5": {
        "title": "Fournitures",
        "transactions": "Paiement par carte Fournitures"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Paiement Qonto Technology"
      },
      "action7": {
        "title": "Gaz & Électricité",
        "transactions": "Paiment mensuel Gaz & Électricité"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "abonnement Qonto Telecom"
      }
    },
    "floating_banner": {
      "text": "Prêt à transférer votre compte chez Qonto ?"
    },
    "overview": {
      "card1": {
        "IBAN": "FR00 1111 2222 3333 4444 5555 000",
        "title": "Ancien compte"
      },
      "card2": {
        "IBAN": "FR00 6666 7777 8888 9999 1111 000",
        "title": "Compte principal Qonto"
      },
      "title": "Suivi (démo)"
    },
    "suppliers": {
      "action1": {
        "title": "Agence Marketing",
        "transactions": "Paiement Agence Marketing"
      },
      "action2": {
        "title": "Boulangerie",
        "transactions": "Débit carte Boulangerie"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "paiement Qonto Airlines"
      },
      "action4": {
        "title": "Médecine du Travail",
        "transactions": "Paiement carte Médecine du Travail"
      },
      "action5": {
        "title": "Fournitures",
        "transactions": "Paiement par carte Fournitures"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Paiement Qonto Technology"
      },
      "action7": {
        "title": "Gaz & Électricité",
        "transactions": "Paiment mensuel Gaz & Électricité"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "abonnement Qonto Telecom"
      }
    }
  },
  "redirect": {
    "continue_button": "Continuer",
    "error_message": "Pour continuer, vous devez sélectionner au moins un compte.",
    "subtitle": "L'historique des transactions liées à ce compte sera transmis à votre conseiller Qonto pour analyse.",
    "title": "Sélectionnez les comptes que vous souhaitez transférer"
  },
  "synchronization": {
    "error_page_1003": {
      "CTA": "Revenir à Qonto",
      "body": "Une erreur nous empêche de nous connecter à votre banque. Rassurez-vous, notre équipe met tout en œuvre pour résoudre le problème au plus vite. Vous recevrez un e-mail dès que tout sera rentré dans l'ordre.",
      "title": "La connexion à votre banque a échoué"
    },
    "error_page_402": {
      "CTA_1": "Sélectionner une autre banque",
      "CTA_2": "Besoin d'aide ?",
      "body": "Il semblerait que vous ayez quelques difficultés à connecter votre banque à Qonto. Certaines banques ont plusieurs entités donc nous vous conseillons de vérifier que vous avez bien sélectionné celle qui vous correspond avant de réessayer de vous connecter à Qonto.",
      "title": "Êtes-vous sûr d'avoir sélectionné la bonne banque ?"
    },
    "error_page_429": {
      "CTA_1": "Continuer",
      "CTA_2": "Revenir à Qonto",
      "body_action1": "Pour continuer, vous devez accepter les conditions générales sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action10": "Pour continuer, vous devez réactiver l'authentification forte sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action11": "Pour continuer, vous devez obtenir l'accès aux services en ligne en contactant votre banque {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action12": "Pour continuer, vous devez contacter le service client de votre banque {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action13": "Pour continuer, vous devez renouveler ou activer vos identifiants en contactant votre banque {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action14": "Pour continuer, vous devez modifier votre mot de passe sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action15": "Pour continuer, vous devez créer un mot de passe en vous connectant une première fois sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action2": "Pour continuer, vous devez signer un contrat sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action3": "Pour continuer, vous devez activer ou renseigner vos identifiants sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action4": "Pour continuer, vous devez activer votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action5": "Pour continuer, vous devez renseigner ou confirmer le numéro de téléphone associé à votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action6": "Pour continuer, vous devez valider des informations ou un message sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action7": "Pour continuer, vous devez confirmer ou compléter des informations personnelles sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action8": "Pour continuer, vous devez confirmer et/ou modifier vos coordonnées sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action9": "Pour continuer, vous devez créer un compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "checkbox": "En cliquant ici, je confirme avoir fait le nécessaire auprès de ma banque.",
      "title": "Rendez-vous sur l'espace client de votre banque",
      "toaster": "Il semblerait qu'il y reste une action requise par votre banque à compléter. Pouvez-vous vérifier et réessayer ?"
    }
  },
  "toast": {
    "default_error_message": "Une erreur s'est produite. Pouvez-vous réessayer ?",
    "not_available_for_country": "Malheureusement, le transfert de compte n'est pas (encore) disponible dans votre pays. Restez connecté !"
  },
  "ui-kit": {
    "avatar-input": {
      "default-image-alt-text": "Image par défaut",
      "uploaded-image-alt-text": "Dernière image que vous avez téléchargée"
    },
    "btn": {
      "aria-label": {
        "clear": "Effacer",
        "download": "Télécharger",
        "next-month": "Mois suivant",
        "preview": "Afficher",
        "previous-month": "Mois précédent"
      },
      "cancel": "Annuler",
      "close": "Fermer",
      "delete": "Supprimer",
      "delete-aria-label": "Supprimer"
    },
    "countries": {
      "AC": "Île de l'Ascension",
      "AD": "Andorre",
      "AE": "Emirats Arabes Unis",
      "AF": "Afghanistan",
      "AG": "Antigua-et-Barbuda",
      "AI": "Anguilla",
      "AL": "Albanie",
      "AM": "Arménie",
      "AN": "Antilles Néerlandaises",
      "AO": "Angola",
      "AQ": "Antarctique",
      "AR": "Argentine",
      "AS": "Samoa américaines",
      "AT": "Autriche",
      "AU": "Australie",
      "AW": "Aruba",
      "AX": "Aland Islands",
      "AZ": "Azerbaïdjan",
      "BA": "Bosnie-Herzégovine",
      "BB": "Barbade",
      "BD": "Bangladesh",
      "BE": "Belgique",
      "BF": "Burkina Faso",
      "BG": "Bulgarie",
      "BH": "Bahreïn",
      "BI": "Burundi",
      "BJ": "Bénin",
      "BL": "Saint-Barthélemy",
      "BM": "Bermudes",
      "BN": "Brunei",
      "BO": "Bolivie",
      "BQ": "Pays-Bas caribéens",
      "BR": "Brésil",
      "BS": "Bahamas",
      "BT": "Bhoutan",
      "BV": "Île de Bouvet",
      "BW": "Botswana",
      "BY": "Biélorussie",
      "BZ": "Belize",
      "CA": "Canada",
      "CC": "Îles Cocos (Keeling)",
      "CD": "République Démocratique du Congo",
      "CF": "République centrafricaine",
      "CG": "Congo - Brazzaville",
      "CH": "Suisse",
      "CI": "Côte d’Ivoire",
      "CK": "Îles Cook",
      "CL": "Chili",
      "CM": "Cameroun",
      "CN": "Chine",
      "CO": "Colombie",
      "CP": "Île de Clipperton",
      "CR": "Costa Rica",
      "CU": "Cuba",
      "CV": "Cap-Vert",
      "CW": "Curaçao",
      "CX": "Île Christmas",
      "CY": "Chypre",
      "CZ": "République Tchèque",
      "DE": "Allemagne",
      "DG": "Diego Garcia",
      "DJ": "Djibouti",
      "DK": "Danemark",
      "DM": "Dominique",
      "DO": "République Dominicaine",
      "DZ": "Algérie",
      "EA": "Ceuta-et-Melilla",
      "EC": "Équateur",
      "EE": "Estonie",
      "EG": "Égypte",
      "EH": "Sahara occidental",
      "ER": "Érythrée",
      "ES": "Espagne",
      "ET": "Éthiopie",
      "EU": "Union européenne",
      "FI": "Finlande",
      "FJ": "Fiji",
      "FK": "Les îles Falkland",
      "FM": "Micronésie",
      "FO": "les îles Faroe",
      "FR": "France",
      "GA": "Gabon",
      "GB": "Royaume Uni",
      "GD": "Grenade",
      "GE": "Georgie",
      "GF": "Guyane française",
      "GG": "Guernesey",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GL": "Groenland",
      "GM": "Gambie",
      "GN": "Guinée",
      "GP": "Guadeloupe",
      "GQ": "Guinée Équatoriale",
      "GR": "Grèce",
      "GS": "Géorgie du Sud-et-les Îles Sandwich du Sud",
      "GT": "Guatemala",
      "GU": "Guam",
      "GW": "Guinée-Bissau",
      "GY": "Guyane",
      "HK": "Hong Kong",
      "HM": "Îles Heard-et-MacDonald",
      "HN": "Honduras",
      "HR": "Croatie",
      "HT": "Haiti",
      "HU": "Hongrie",
      "IC": "Îles Canaries",
      "ID": "Indonésie",
      "IE": "Irlande",
      "IL": "Israël",
      "IM": "Île de Man",
      "IN": "Inde",
      "IO": "Territoire britannique de l'océan Indien",
      "IQ": "Irak",
      "IR": "Iran",
      "IS": "Islande",
      "IT": "Italie",
      "JE": "Jersey",
      "JM": "Jamaïque",
      "JO": "Jordanie",
      "JP": "Japon",
      "KE": "Kenya",
      "KG": "Kirghizstan",
      "KH": "Cambodge",
      "KI": "Kiribati",
      "KM": "Comores",
      "KN": "Saint-Christophe-et-Niévès",
      "KP": "Corée du Nord",
      "KR": "Corée du Sud",
      "KW": "Koweït",
      "KY": "Îles Caïmans",
      "KZ": "Kazakhstan",
      "LA": "Laos",
      "LB": "Liban",
      "LC": "Sainte Lucie",
      "LI": "Liechtenstein",
      "LK": "Sri Lanka",
      "LR": "Libéria",
      "LS": "Lesotho",
      "LT": "Lituanie",
      "LU": "Luxembourg",
      "LV": "Lettonie",
      "LY": "Libye",
      "MA": "Maroc",
      "MC": "Monaco",
      "MD": "Moldavie",
      "ME": "Montenegro",
      "MF": "Saint Martin",
      "MG": "Madagascar",
      "MH": "Îles Marshall",
      "MK": "Macédonie",
      "ML": "Mali",
      "MM": "Myanmar (Birmanie)",
      "MN": "Mongolie",
      "MO": "Macau",
      "MP": "Îles Mariannes du Nord",
      "MQ": "Martinique",
      "MR": "Mauritanie",
      "MS": "Montserrat",
      "MT": "Malte",
      "MU": "Maurice",
      "MV": "Maldives",
      "MW": "Malawi",
      "MX": "Mexique",
      "MY": "Malaisie",
      "MZ": "Mozambique",
      "NA": "Namibie",
      "NC": "Nouvelle Calédonie",
      "NE": "Niger",
      "NF": "Île Norfolk",
      "NG": "Nigeria",
      "NI": "Nicaragua",
      "NL": "Pays Bas",
      "NO": "Norvège",
      "NP": "Népal",
      "NR": "Nauru",
      "NU": "Niue",
      "NZ": "Nouvelle Zélande",
      "OM": "Oman",
      "PA": "Panama",
      "PE": "Pérou",
      "PF": "Polynésie Française",
      "PG": "Papouasie Nouvelle Guinée",
      "PH": "Philippines",
      "PK": "Pakistan",
      "PL": "Pologne",
      "PM": "Saint Pierre-et-Miquelon",
      "PN": "Îles Pitcairn",
      "PR": "Porto Rico",
      "PS": "Territoires Palestiniens",
      "PT": "Portugal",
      "PW": "Palaos",
      "PY": "Paraguay",
      "QA": "Qatar",
      "QO": "Dépendances de l'Océanie",
      "RE": "Réunion",
      "RO": "Roumanie",
      "RS": "Serbie",
      "RU": "Russie",
      "RW": "Rwanda",
      "SA": "Arabie Saoudite",
      "SB": "Îles Salomon",
      "SC": "Seychelles",
      "SD": "Soudan",
      "SE": "Suède",
      "SG": "Singapour",
      "SH": "Saint Hélène",
      "SI": "Slovénie",
      "SJ": "Svalbard-et-Jan Mayen",
      "SK": "Slovaquie",
      "SL": "Sierra Leone",
      "SM": "Saint-Marin",
      "SN": "Sénégal",
      "SO": "Somalie",
      "SR": "Suriname",
      "SS": "Soudan du Sud",
      "ST": "Sao Tomé-et-Principe",
      "SV": "Salvador",
      "SX": "Saint Martin",
      "SY": "Syrie",
      "SZ": "Swaziland",
      "TA": "Tristan da Cunha",
      "TC": "Îles Turks-et-Caïcos",
      "TD": "Tchad",
      "TF": "Terres australes et antarctiques françaises",
      "TG": "Togo",
      "TH": "Thaïlande",
      "TJ": "Tajikistan",
      "TK": "Tokelau",
      "TL": "Timor oriental",
      "TM": "Turkménistan",
      "TN": "Tunisie",
      "TO": "Tonga",
      "TR": "Turquie",
      "TT": "Trinité-et-Tobago",
      "TV": "Tuvalu",
      "TW": "Taiwan",
      "TZ": "Tanzanie",
      "UA": "Ukraine",
      "UG": "Ouganda",
      "UM": "Îles mineures éloignées des États-Unis",
      "US": "États Unis",
      "UY": "Uruguay",
      "UZ": "Ouzbékistan",
      "VA": "État du Vatican",
      "VC": "Saint-Vincent-et-les-Grenadines",
      "VE": "Vénézuela",
      "VG": "Îles Vierges britanniques",
      "VI": "Îles Vierges des États-Unis",
      "VN": "Viêtnam",
      "VU": "Vanuatu",
      "WF": "Wallis-et-Futuna",
      "WS": "Samoa",
      "XK": "Kosovo",
      "YE": "Yemen",
      "YT": "Mayotte",
      "ZA": "Afrique du Sud",
      "ZM": "Zambie",
      "ZW": "Zimbabwe",
      "ZZ": "Autre"
    },
    "country-select": {
      "label": "Pays",
      "no_matches_message": "Aucun résultat",
      "placeholder": "Pays",
      "search_placeholder": "Rechercher..."
    },
    "disclaimer": {
      "alt-values": {
        "error": "Message d'erreur",
        "info": "Message d'information",
        "warning": "Message d'alerte"
      }
    },
    "file-status": {
      "certified": "Certifié",
      "not-certified": "Non certifié"
    },
    "form-elements": {
      "optional": "(optionnel)"
    },
    "form-fields": {
      "date-field": {
        "error": {
          "invalid": "Cette date n'est pas valide. Pouvez-vous réessayer, au format JJ-MM-AAAA ?"
        },
        "placeholder": "JJ-MM-AAAA"
      },
      "date-picker": {
        "clear": {
          "cta": "Effacer"
        }
      }
    },
    "fullscreen": {
      "preview": {
        "delete-file": {
          "button-cancel": "Annuler",
          "button-confirm": "Supprimer",
          "description": "Gardez en tête que la suppression du fichier est définitive.",
          "title": "Supprimer ce fichier ?"
        }
      }
    },
    "gender-selector": {
      "female": "Féminin",
      "label": "Sexe figurant sur la pièce d’identité",
      "male": "Masculin",
      "placeholder": "Genre"
    },
    "icons": {
      "alt-values": {
        "error": "Message d'erreur",
        "info": "Message d'information",
        "warning": "Message d'alerte"
      }
    },
    "kilobyte": "Ko",
    "labels": {
      "choose_all": "Tout sélectionner",
      "clear_filters": "Tout désélectionner"
    },
    "megabyte": "Mo",
    "password_creator": {
      "label": "Mot de passe",
      "placeholder": "Saisissez votre mot de passe ici",
      "set_pwd_rules": "Assurez-vous que votre mot de passe soit composé d'au moins 9 caractères. Évitez votre date de naissance, le nom de votre animal de compagnie ou « 0000 » par exemple.",
      "strength_password_strong": "Parfait, votre mot de passe est < suffisamment sécurisé. >",
      "strength_password_weak": "Votre mot de passe < n'est pas assez sécurisé. >"
    },
    "pdf-preview": {
      "amount-discount": "{language, select, fr {Remise} de {Rabatt} es {Descuento} it {Sconto} other {Discount}}",
      "beneficiary-name": "{language, select, fr {Nom du bénéficiaire} it {Nome e cognome del beneficiario} de {Name des/r Begünstigten} es {Nombre del beneficiario} other {Beneficiary name}}",
      "bic": "BIC",
      "client-steuer-id": "{language, select, fr {Numéro fiscal client} de {Kunden-Steuer-ID} es {Codice fiscale cliente} it {NIF cliente} other {Client tax ID}}",
      "client-ust-udnr": "{language, select, fr {Numéro TVA client} de {Kunden-USt.-IDNr.} es {Número de IVA cliente} it {Partita IVA cliente} other {Client VAT number}}",
      "credit-note": {
        "credit-note-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "credit-note-number": "{language, select, fr {Numéro de l'avoir} it {Numero nota di credito} de {Stornorechnungsnummer} es {Número de factura rectificativa} other {Credit note number}}",
        "invoice-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "reason-credit-note": "{language, select, fr {Motif de l'avoir :} it {Motivo emissione nota di credito:} de {Grund für die Stornorechnung:} es {Motivo de la factura rectificativa:} other {Reason for credit note:}}",
        "related-invoice-number": "{language, select, fr {Numéro de facture associée} it {Numero fattura correlata} de {Zugehörige Rechnungsnummer} es {Número de factura asociada} other {Related invoice number}}",
        "title": "{language, select, fr {Avoir} it {Nota di credito} de {Stornorechnung} es {Factura rectificativa} other {Credit note}}"
      },
      "delivery-address": "{language, select, fr {Adresse de livraison} de {Lieferadresse} es {Dirección de envío} it {Indirizzo di consegna} other {Delivery address}}",
      "district-court": "{language, select, fr {Greffe du tribunal d'arrondissement :} de {Registergericht} es {Juzgado de registro de distrito:} it {Registro del tribunale distrettuale:} other {Registry district court:}}",
      "draft": {
        "invoice-number-draft-value": "--",
        "proforma-disclaimer": "{language, select, it {Il presente documento non costituisce fattura valida ai fini del DPR 633 del 26/10/1972 e successive modifiche. La fattura definitiva verrà emessa all’atto del pagamento del corrispettivo (articolo 6, comma 3, DPR 633/72).} other {This document does not constitute a valid invoice as outlined in the Decree of the President of the Republic (DPR) no. 633 of 26 October 1972 and subsequent amendments. The final invoice will be issued upon payment of the consideration (Article 6, paragraph 3, of the DPR 633/72).}}",
        "title": "{language, select, fr {Facture pro forma} de {Proformarechnung} es {Factura proforma} it {Fattura proforma} other {Pro forma invoice}}"
      },
      "due-date": "{language, select, fr {Date d'échéance} it {Data di scadenza} de {Fälligkeitsdatum} es {Fecha de vencimiento} other {Due date}}",
      "exemption": {
        "AT": {
          "S11.1a": "{language, select, fr {TVA non applicable, article 11 de l'UStG} de {MwSt. entfällt, Art. 11 des UStG} es {IVA no aplicable, art. 11 del UStG} it {IVA non applicabile, art. 11 del UStG} other {VAT not applicable, art. 11 of the UStG}}",
          "S6.1.13": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.16": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.19": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.27": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}"
        },
        "BE": {
          "S21": "{language, select, fr {TVA non applicable, article 21 du BTW-Wetboek} de {MwSt. entfällt, Art. 21 des BTW-Wetboek} es {IVA no aplicable, art. 21 del BTW-Wetboek} it {IVA non applicabile, art. 21 del BTW-Wetboek} other {VAT not applicable, art. 21 of the BTW-Wetboek}}",
          "S39": "{language, select, fr {TVA non applicable, article 39 du BTW-Wetboek} de {MwSt. entfällt, Art. 39 des BTW-Wetboek} es {IVA no aplicable, art. 39 del BTW-Wetboek} it {IVA non applicabile, art. 39 del BTW-Wetboek} other {VAT not applicable, art. 39 of the BTW-Wetboek}}",
          "S39bis": "{language, select, fr {TVA non applicable, article 39bis du BTW-Wetboek} de {MwSt. entfällt, Art. 39bis des BTW-Wetboek} es {IVA no aplicable, art. 39bis del BTW-Wetboek} it {IVA non applicabile, art. 39bis del BTW-Wetboek} other {VAT not applicable, art. 39bis of the BTW-Wetboek}}",
          "S44.1": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.11": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.2": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.4": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S56bis": "{language, select, fr {TVA non applicable, article 56bis du BTW-Wetboek} de {MwSt. entfällt, Art. 56bis des BTW-Wetboek} es {IVA no aplicable, art. 56bis del BTW-Wetboek} it {IVA non applicabile, art. 56bis del BTW-Wetboek} other {VAT not applicable, art. 56bis of the BTW-Wetboek}}"
        },
        "DE": {
          "S13B": "{language, select, fr {Steuerschuldnerschaft des Leistungsempfängers (obligation fiscale du bénéficiaire) (section 13b)} de {Steuerschuldnerschaft des Leistungsempfängers (Abschnitt 13b)} es {Steuerschuldnerschaft des Leistungsempfängers (responsabilidad fiscal del beneficiario) (Sección 13b)} it {Steuerschuldnerschaft des Leistungsempfängers (responsabilità fiscale del beneficiario) (Sezione 13b)} other {Steuerschuldnerschaft des Leistungsempfängers (Tax liability of the beneficiary) (Section 13b)}}",
          "S19.1": "{language, select, fr {Conformément à la section 19, paragraphe 1 de l'UStG, le montant de la facture n'inclut pas de taxe de vente} de {Gemäß Abschnitt 19 Abs. 1 UStG ist im Rechnungsbetrag keine Umsatzsteuer enthalten} es {Según el artículo 19, apartado 1 de la UStG, el importe de la factura no incluye el impuesto sobre las ventas} it {Ai sensi della sezione 19, paragrafo 1, della UStG, l'importo della fattura non comprende l'imposta sulle vendite} other {According to Section 19 Paragraph 1 UStG, the invoice amount does not include sales tax}}",
          "S4": "{language, select, fr {Conformément à la section 4 de l'UStG, aucune taxe sur la valeur ajoutée n'est facturée} de {Gemäß Abschnitt 4 UStG wird keine Mehrwertsteuer erhoben} es {Según el artículo 4 de la UStG, no se cobra ningún impuesto sobre el valor añadido} it {Ai sensi della sezione 4 della UStG, non viene addebitata alcuna imposta sul valore aggiunto} other {In accordance to § 4 UStG, no value added tax is charged}}",
          "S4.1A": "{language, select, fr {Livraison d'exportation exonérée d'impôt, conformément à la section 4 n° 1a de l'UStG} de {Steuerfreie Exportlieferung gemäß Abschnitt 4 Nr. 1a des UStG} es {Entrega para exportación exenta de impuestos según el artículo 4 núm. 1a de la UStG} it {Cessione dell'esportazione esente da imposte ai sensi della sezione. 4 n. 1a UStG} other {Tax-free export delivery in accordance with Section 4 No. 1a UStG}}",
          "S4.1B": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à la section 4 n° 1b de l'UStG} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Abschnitt 4 Nr. 1b des UStG} es {Entrega intracomunitaria exenta de impuestos según el artículo 4 núm. 1b de la UStG} it {Cessione intracomunitaria esente da imposte ai sensi della sezione 4 n. 1b UStG} other {Tax-free intra-community delivery in accordance with Section 4 No. 1b UStG.}}"
        },
        "ES": {
          "S122": "{language, select, fr {Opération exonérée d'impôt avec l'art. 122 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 122 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos en virtud del art. 122 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 122 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 122 of Law 37/1992, on VAT}}",
          "S20": "{language, select, fr {Opération exonérée d'impôt, conformément à l'art. 20 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 20 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por aplicación del art. 20 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 20 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 20 of Law 37/1992, on VAT}}",
          "S21": "{language, select, fr {Exportation exonérée d'impôt avec l'art. 21 de la Loi 37/1992 sur la TVA} de {Steuerfreier Export gemäß Art. 21 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por exportación en virtud del art. 21 de la Ley 37/1992, del IVA} it {Esportazione esente da imposta ai sensi dell'art. 21 della Legge 37/1992 sull'IVA} other {Tax-free export in accordance with art. 21 of Law 37/1992, on VAT}}",
          "S25": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à l'art. 25 de la Loi 37/1992 sur la TVA} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Art. 25 des Gesetzes 37/1992 über die MwSt.} es {Entrega intracomunitaria exenta de impuestos por aplicación del art. 25 de la Ley 37/1992, del IVA} it {Cessione intracomunitaria esente da imposta ai sensi dell'art. 25 della Legge 37/1992 sull'IVA} other {Tax-free intra-community delivery in accordance with art. 25 of Law 37/1992, on VAT}}",
          "S69": "{language, select, fr {Opération située à l'adresse du destinataire, conformément à l'art. 69 de la Loi 37/1992 sur la TVA} de {Ein auf der Anschrift des Empfängers oder der Empfängerin laufender Vorgang gemäß Art. 69 des Gesetzes 37/1992 über die MwSt.} es {Operación localizada en sede del destinatario en virtud del art. 69 de la Ley 37/1992, del IVA} it {Operazione situata all'indirizzo del destinatario ai sensi dell'art. 69 della Legge 37/1992 sull'IVA} other {Operation located at the address of the recipient in accordance with art. 69 of Law 37/1992, on VAT}}",
          "S84.1.2": "{language, select, fr {Reversement de l'assujetti - Art. 84.1.2º de la Loi 37/1992 sur la TVA} de {Umkehrung des Steuerpflichtigen – Art. 84.1.2º des Gesetzes 37/1992 über die MwSt.} es {Inversión de sujeto pasivo - Art. 84.1.2º de la Ley 37/1992, del IVA} it {Storno del soggetto passivo - Art. 84.1.2º della Legge 37/1992 sull'IVA} other {Reversal of taxable person - Art. 84.1.2º of Law 37/1992, on VAT}}"
        },
        "FR": {
          "S259": "{language, select, fr {TVA non applicable, art. 259-1 du CGI} de {MwSt. entfällt, Art. 259-1 des CGI} es {IVA no aplicable, art. 259-1 del CGI} it {IVA non applicabile, art. 259-1 del CGI} other {VAT not applicable, art. 259-1 of the CGI}}",
          "S261": "{language, select, fr {TVA non applicable, art. 261 du CGI} de {MwSt. entfällt, Art. 261 des CGI} es {IVA no aplicable, art. 261 del CGI} it {IVA non applicabile, art. 261 del CGI} other {VAT not applicable, art. 261 of the CGI}}",
          "S262": "{language, select, fr {TVA non applicable, art. 262 du CGI} de {MwSt. entfällt, Art. 262 des CGI} es {IVA no aplicable, art. 262 del CGI} it {IVA non applicabile, art. 262 del CGI} other {VAT not applicable, art. 262 of the CGI}}",
          "S262.1": "{language, select, fr {TVA non applicable, article 262 I du CGI} de {MwSt. entfällt, Art. 262 I des CGI} es {IVA no aplicable, art. 262 I del CGI} it {IVA non applicabile, art. 262 I del CGI} other {VAT not applicable, art. 262 I of the CGI}}",
          "S263": "{language, select, fr {TVA non applicable, art. 263 du CGI} de {MwSt. entfällt, Art. 263 des CGI} es {IVA no aplicable, art. 263 del CGI} it {IVA non applicabile, art. 263 del CGI} other {VAT not applicable, art. 263 of the CGI}}",
          "S283": "{language, select, fr {Autoliquidation, art. 283 du CGI} de {Steuerschuldnerschaft des Leistungsempfängers, Art. 283 des CGI} es {Autoliquidación, art. 283 del CGI} it {Autoliquidazione, art. 283 del CGI} other {Autoliquidation, art. 283 of the CGI}}",
          "S293B": "{language, select, fr {TVA non applicable, art. 293 B du CGI} de {MwSt. entfällt, Art. 263 B des CGI} es {IVA no aplicable, art. 293 B del CGI} it {IVA non applicabile, art. 293 B della CGI} other {VAT not applicable, art. 293 B of the CGI}}"
        },
        "NL": {
          "S11": "{language, select, fr {TVA non applicable, article 11 de l'OB 1968} de {MwSt. entfällt, Art. 11 des OB 1968} es {IVA no aplicable, art. 11 del OB 1968} it {IVA non applicabile, art. 11 del OB 1968} other {VAT not applicable, art. 11 of the OB 1968}}",
          "S12": "{language, select, fr {TVA non applicable, article 12 de l'OB 1968} de {MwSt. entfällt, Art. 12 des OB 1968} es {IVA no aplicable, art. 12 del OB 1968} it {IVA non applicabile, art. 12 del OB 1968} other {VAT not applicable, art. 12 of the OB 1968}}",
          "S25": "{language, select, fr {TVA non applicable, article 25 de l'OB 1968} de {MwSt. entfällt, Art. 25 des OB 1968} es {IVA no aplicable, art. 25 del OB 1968} it {IVA non applicabile, art. 25 del OB 1968} other {VAT not applicable, art. 25 of the OB 1968}}",
          "S9": "{language, select, fr {TVA non applicable, article 9 de l'OB 1968} de {MwSt. entfällt, Art. 9 des OB 1968} es {IVA no aplicable, art. 9 del OB 1968} it {IVA non applicabile, art. 9 del OB 1968} other {VAT not applicable, art. 9 of the OB 1968}}"
        },
        "PT": {
          "S53": "{language, select, fr {TVA non applicable, article 53 du Regime do IVA} de {MwSt. entfällt, Art. 53 des Regime do IVA} es {IVA no aplicable, art. 53 del Regime do IVA} it {IVA non applicabile, art. 53 del Regime do IVA} other {VAT not applicable, art. 53 of the Regime do IVA}}",
          "S9.1-5": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.27.28": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.29.30": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}"
        }
      },
      "footer-organization": "{language, select, fr {{orgaName} avec un capital de {amount}} de {mit einem Kapital von} es {{orgaName} con un capital de {amount}} it {{orgaName} con un capitale di {amount}} other {{orgaName} with a capital of {amount}}}",
      "iban": "IBAN",
      "intermediary-bic": {
        "label": "{language, select, fr {BIC de la banque intermédiaire} de {BIC unserer Korrespondenzbank} es {BIC del banco intermediario} it {BIC della banca corrispondente} other {Intermediary bank BIC}}",
        "value": "{language, select, fr {TRWIBEB3XXX} de {TRWIBEB3XXX} es {TRWIBEB3XXX} it {TRWIBEB3XXX} other {TRWIBEB3XXX}}"
      },
      "invoice-example": {
        "client-address-line1": "18 rue de Navarin",
        "client-city": "Paris",
        "client-countrycode": "DE",
        "client-delivery-address-line1": "18 rue de Navarin",
        "client-delivery-city": "Paris",
        "client-delivery-countrycode": "FR",
        "client-delivery-zipcode": "75009",
        "client-name": "Jeanne Durant",
        "client-vat-number": "123456789",
        "client-zipcode": "75009",
        "invoice-due-date": "15/01/2030",
        "invoice-issue-date": "01/01/2030",
        "invoice-number": "F-2030-001",
        "invoice-payment-time": "Veuillez payer le montant de la facture sous 15 jours. (15/01/2030)",
        "invoice-performance-date": "01/01/2030",
        "invoice-total": "120",
        "item-description": "Les informations concernant vos futurs éléments réels apparaîtront ici.",
        "item-price": "100",
        "item-quantity": "2",
        "item-title": "Le nom de mon premier élément",
        "item-total": "120",
        "item-total-without-vat": "100",
        "item-vat-amount": "0.2",
        "item-vat-percentual": "%",
        "item-vat-total": "20",
        "page-count": "1/1",
        "quote-validated-time": "Ce devis est valable 30 jours (15/01/2030)"
      },
      "invoice-number": "{language, select, fr {Numéro de facture} it {Numero della fattura} de {Rechnungsnummer} es {Número de factura} other {Invoice number}}",
      "invoice-payment-time": "{number, plural, =0 {{language, select, fr {Veuillez payer la facture à l'émission. ({dueDate})} de {Bitte bezahlen Sie den Betrag direkt nach Erhalt der Rechnung. ({dueDate})} es {Por favor, abona el importe de la factura tras la recepción. ({dueDate})} it {Per favore, paga l’importo della fattura alla sua ricezione. ({dueDate})} other {Please pay the invoice amount upon receipt. ({dueDate})}}} one {{language, select, fr {Veuillez payer la facture sous {days_amount} jour. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tag. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} día. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorno. ({dueDate})} other {Please pay the invoice amount within {days_amount} day. ({dueDate})}}} other {{language, select, fr {Veuillez payer la facture sous {days_amount} jours. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tagen. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} días. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorni. ({dueDate})} other {Please pay the invoice amount within {days_amount} days. ({dueDate})}}}}",
      "issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
      "items": "{language, select, fr {Description} it {Descrizione} de {Beschreibung} es {Descripción} other {Description}}",
      "leadership": "{language, select, fr {Direction de l'entreprise :} de {Geschäftsführung} es {Dirección de la empresa:} it {Direzione dell'impresa:} other {Company leadership:}}",
      "payment-condition": {
        "compensated_for_sales": "{language, select, fr {Sur les débits} de {Im zu zahlenden Betrag enthalten} es {Compensación por ventas} it {Inclusa nel prezzo di vendita} other {Compensated for sales}}",
        "exempt": "{language, select, fr {Exonération} de {Befreit} es {Exento} it {Esente} other {Exempt}}",
        "label": "{language, select, fr {Conditions de paiement de la TVA : } de {MwSt.-Zahlungsbedingung: } es {Condición de pago del IVA: } it {Opzioni per il pagamento dell'IVA: } other {VAT payment condition: }}",
        "on_receipts": "{language, select, fr {Sur les encaissements} de {Sofort} es {Tras la recepción} it {A vista} other {On receipt}}"
      },
      "payment-details": "{language, select, fr {Détails du paiement} it {Dettagli per il pagamento} de {Zahlungsdetails} es {Detalles del pago} other {Payment details}}",
      "pension-contribution": "{language, select, it {Contributo pensione/INPS (IVA escl.)} other {Pension/INPS contribution (excl. VAT)}}",
      "percentage-discount": "{language, select, fr {Remise ({discountPercentage} %)} de {Rabatt ({discountPercentage}%)} es {Descuento ({discountPercentage}%)} it {Sconto ({discountPercentage}%)} other {Discount ({discountPercentage}%)}}",
      "performance-date": "{language, select, fr {Date d'exécution} de {Leistungsdatum} es {Fecha de ejecución} it {Data prestazione} other {Performance date}}",
      "placerholder-stamp-duty": "{language, select, it {Imposta di bollo virtuale pagata ai sensi del decreto ministeriale 17.06.2014} other {Stamp duty paid virtually pursuant to Ministerial Decree 17.06.2014}}",
      "purchase-order": "{language, select, fr {Numéro de bon de commande} it {Numero d'ordine} de {Bestellnummer} es {Número de orden de compra} other {Purchase order number}}",
      "quantity": "{language, select, fr {Qté} it {Qtà} de {Menge} es {Cant.} other {Qty}}",
      "quote-expiry-date": "{language, select, fr {Date d'expiration} it {Data di scadenza} de {Ablaufdatum} es {Fecha de vencimiento} other {Expiry date}}",
      "quote-number": "{language, select, fr {Numéro de devis} it {Numero del preventivo} de {Angebotsnummer} es {Número de presupuesto} other {Quote number}}",
      "quote-related-number": "{language, select, fr {Numéro du devis associé } it {Numero di preventivo correlato } de {Zugehöriges Angebot } es {Número de presupuesto asociado } other {Related quote number }}",
      "quote-title": "{language, select, fr {Devis} de {Angebot} es {Presupuesto} it {Preventivo} other {Quote}}",
      "quote-validated-time": "{number, plural, =0 {} one {{language, select, fr {Ce devis est valable {days_amount} jour. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tag. ({expiry_date})} es {Este presupuesto es válido por {days_amount} día. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorno. ({expiry_date})} other {This quote is valid for {days_amount} day. ({expiry_date})}}} other {{language, select, fr {Ce devis est valable {days_amount} jours. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tage. ({expiry_date})} es {Este presupuesto es válido por {days_amount} días. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorni. ({expiry_date})} other {This quote is valid for {days_amount} days. ({expiry_date})}}}}",
      "reference": "{language, select, fr {Référence} it {Causale} de {Referenz} es {Referencia} other {Reference}}",
      "revenue-stamp": "{language, select, it {Marca da bollo} other {Revenue stamp}}",
      "steuer-id": "{language, select, fr {Steuer-ID} de {Steuer-ID} es {Steuer-ID} it {Steuer-ID} other {Steuer-ID}}",
      "steuernummer": "{language, select, fr {Steuernummer :} de {Steuernummer:} es {Steuernummer:} it {Steuernummer:} other {Steuernummer:}}",
      "title": "{language, select, fr {Facture} de {Rechnung} es {Factura} it {Fattura} other {Invoice}}",
      "total": "{language, select, fr {Total} it {Totale} de {Gesamt} es {Total} other {Total}}",
      "total-vat": "{language, select, fr  {Total TTC} it {Totale (IVA inclusa)}  de {Gesamt (inkl. MwSt.)} es {Total (IVA incluido)} other {Total incl. VAT}}",
      "total-vat-amount": "{language, select, fr {Montant total de la TVA} it {Importo totale IVA} de {MwSt.-Betrag insgesamt} es {Importe total del IVA} other {Total VAT amount}}",
      "total-without-vat": "{language, select, fr {Total HT} it {Totale (IVA escl.)} de {Gesamt (exkl. MwSt.)} es {Total (IVA no incluido)} other {Total excl. VAT}}",
      "transaction-type": {
        "goods": "{language, select, fr {Biens} de {Waren} es {Bienes} it {Beni} other {Goods}}",
        "goods_and_services": "{language, select, fr {Biens et services} de {Waren und Dienstleistungen} es {Bienes y servicios} it {Beni e servizi} other {Goods and services}}",
        "label": "{language, select, fr {Type de transaction : } de {Transaktionstyp: } es {Tipo de transacción: } it {Tipo di transazione: } other {Transaction type: }}",
        "services": "{language, select, fr {Services} de {Dienstleistungen} es {Servicios} it {Servizi} other {Services}}"
      },
      "unit-price": "{language, select, fr {Prix unitaire} it {Prezzo unitario} de {Preis pro Einheit} es {Precio unitario} other {Unit price}}",
      "ust-udnr": "{language, select, fr {USt-IDNr.} de {USt-IDNr.} es {USt-IDNr.} it {USt-IDNr.} other {USt-IDNr.}}",
      "vat": "{language, select, fr {TVA (%)} it {IVA (%)} de {MwSt. (%)} es {IVA (%)} other {VAT (%)}}",
      "vat-amount": "{language, select, fr {Montant de la TVA} it {Importo IVA} de {MwSt-Betrag} es {Importe del IVA} other {VAT amount}}",
      "vat-number": "{language, select, fr {Numéro de TVA :} de {USt.-IdNr.:} es {Número de IVA:} it {Partita IVA:} other {VAT number:}}",
      "withholding-tax": "{language, select, it {Ritenuta d'acconto} other {Withholding tax}}"
    },
    "spinner": {
      "label": "Chargement"
    },
    "stepper-header": {
      "back": "Retour",
      "close": "Fermer"
    },
    "translation-with-args": {
      "body-1": "Texte contenant {var}.",
      "body-3": "Texte contenant {var1} puis une autre {var2} à l’intérieur puis une autre {var3} à l’intérieur."
    },
    "zxcvbn": {
      "password_common": "Ce mot de passe est trop commun, et donc facile à deviner.",
      "password_date": "Les dates sont à éviter, car elles sont trop souvent utilisées.",
      "password_dictionary": "Ce mot de passe est trop commun, et donc facile à deviner.",
      "password_repeat": "Les répétitions de mots ou de lettres ne sont pas assez sécurisées.",
      "password_sequence": "Les suites logiques, comme « 1234 » par exemple, sont faciles à deviner.",
      "password_spatial": "Les suites de clavier, comme « azerty » par exemple, ne sont pas assez sécurisées."
    }
  },
  "welcome": {
    "gdpr_checkbox": "Je consens à l'utilisation de mes données personnelles par Qonto",
    "gdpr_checkbox_error_message": "Pour continuer, vous devez accepter notre politique d'utilisation des données.",
    "redirect_to_appointment_page": "Demandez plus d'informations à l'un de nos experts",
    "redirect_to_bridge": "Démarrer le transfert",
    "step_1_subtitle": "Grâce au service sécurisé Bridge by Bankin'.",
    "step_1_title": "Connectez votre banque à Qonto",
    "step_2_subtitle": "Vos données seront synchronisées en toute sécurité.",
    "step_2_title": "Sélectionnez le(s) compte(s) à transférer",
    "step_3_subtitle": "Pour répondre à toutes vos questions.",
    "step_3_title": "Un conseiller Qonto vous accompagne de A à Z",
    "subtitle": "Et optimisez la gestion de vos finances.",
    "title": "Transférez vos comptes en toute simplicité"
  }
}], ["it", {
  "application": {
    "back_to_app": "Ritorna su Qonto",
    "go_back": "Indietro",
    "security_disclaimer": "Questo servizio è sicuro. Tutti i dati sono strettamente riservati all'uso di Qonto e possono essere cancellati su richiesta in qualsiasi momento.",
    "security_disclaimer_link": "Scopri di più",
    "title": "Trasferimento di conto"
  },
  "appointment": {
    "back_cta": "Indietro",
    "calendar_subtitle": "Prendi un appuntamento con il tuo consulente Qonto per preparare il trasferimento del conto.",
    "calendar_title": "Incontra il tuo consulente Qonto"
  },
  "bank_selection": {
    "consent_modal": {
      "checkbox_text": "Acconsento al trattamento dei miei dati personali da parte di Qonto.",
      "primary_CTA": "Conferma",
      "secondary_CTA": "Annulla",
      "subtitle": "Per ragioni legali, dobbiamo ottenere il tuo consenso sulla nostra politica di utilizzo dei dati prima di collegare la tua banca a Qonto tramite il servizio sicuro Bridge by Bankin'.",
      "title": "Accetta le condizioni"
    },
    "select_child_bank": {
      "back_CTA": "Indietro",
      "individual": "Conto personale",
      "pro": "Conto professionale",
      "subtitle": "{bank_name} ha diverse entità quindi assicurati di selezionare quella giusta. Se non sei sicuro, ti consigliamo di contattare la tua banca.",
      "title": "Seleziona l’entità della tua banca"
    },
    "select_parent_bank": {
      "cannot_find": "La tua banca non è nella lista?",
      "search_empty": {
        "CTA": "Contattaci",
        "subtitle": "Contattaci utilizzando la chat visualizzata in questa schermata per farci sapere quale banca manca. Faremo del nostro meglio per aiutarti a trasferire comunque i tuoi conti.",
        "title": "Questa banca non è (ancora) supportata"
      },
      "search_placeholder": "Cerca una banca...",
      "title": "Seleziona la tua banca"
    }
  },
  "bankswitch": {
    "backend_error": {
      "toaster": "Si è verificato un errore. Puoi aggiornare la pagina?"
    }
  },
  "btn": {
    "back": "Indietro"
  },
  "choose_organization": {
    "continue_button": "Continua",
    "error_message": "Per continuare, devi selezionare un'impresa.",
    "subtitle": "Seleziona un'impresa a cui trasferire il tuo conto.",
    "title": "Seleziona un'impresa"
  },
  "dashboard": {
    "CTA": {
      "new_action": "Crea un’azione"
    },
    "account_details": {
      "bic": "BIC",
      "disclaimer": "Puoi trasferire solo ad una banca per il momento, il multi-banking è in arrivo.",
      "iban": "IBAN",
      "no_iban": "Puoi trovare il tuo IBAN nell'area clienti della tua banca.",
      "no_iban_link": "Accedi al tuo IBAN su Qonto.",
      "notifications": {
        "bic_copy": "Il tuo BIC è stato copiato!",
        "iban_copy": "Il tuo IBAN è stato copiato!"
      }
    },
    "action": {
      "important": {
        "tag": "Importante"
      },
      "menu": {
        "CTA_delete": "Elimina",
        "CTA_edit": "Modifica",
        "CTA_flag_as_important": "Segna come importante",
        "CTA_ignore": "Ignora",
        "CTA_mark_as_done": "Segna come elaborato",
        "CTA_unflag_as_important": "Segna come non importante",
        "CTA_unignore": "Riattiva",
        "CTA_unmark_as_done": "Segna come non elaborato"
      }
    },
    "card": {
      "action": {
        "ignored": "Ignorare",
        "reactive": "Riattivare"
      },
      "category": {
        "cards": "Carte",
        "mandates": "Mandati",
        "others": "Altri",
        "transfers": "Bonifici"
      },
      "last_operation": "Ultima:",
      "last_operation_detected": "Ultima operazione rilevata:",
      "operations": "{count, plural,=1 {Operazione:} other {Operazioni:}}"
    },
    "create_action": {
      "toaster": "È stata creata una nuova azione."
    },
    "create_form": {
      "CTA_cancel": "Annulla",
      "CTA_create": "Crea",
      "category_field": {
        "placeholder": "Scegli una categoria",
        "title": "Categoria"
      },
      "category_list": {
        "atm": "Prelievi",
        "fees": "Commissioni",
        "finance": "Finanza",
        "hotel_and_lodging": "Hotel & alloggi",
        "insurance": "Assicurazione",
        "legal_and_accounting": "Spese legali & contabilità",
        "logistics": "Logistica",
        "marketing": "Marketing",
        "office_rental": "Affitto locali",
        "office_supply": "Cancelleria",
        "online_service": "Servizi online",
        "other": "Altro",
        "pending": "In attesa",
        "restaurant_and_bar": "Ristoranti & bar",
        "salary": "Salari",
        "sales": "Vendite",
        "tax": "Tasse & imposte",
        "transport": "Trasporti",
        "utility": "Utenze"
      },
      "note_field": {
        "placeholder": "Aggiungi una nota",
        "title": "Informazioni aggiuntive"
      },
      "title": {
        "placeholder": "Nuovo compito"
      },
      "title_field": {
        "placeholder": "Dai un nome a questo compito",
        "title": "Nome"
      },
      "type_field": {
        "placeholder": "Scegli un metodo di pagamento",
        "title": "Metodo"
      }
    },
    "credit": {
      "no_actions": {
        "subtitle": "Su questo conto non abbiamo trovato nessuna transazione da trasferire. Se pensi ci sia un errore, non esitare a contattarci.",
        "title": "Nessun cliente ricorrente"
      },
      "sections": {
        "all": {
          "methods": "Tutti i metodi"
        },
        "cards": "Carte",
        "cards_tooltip": "Registra la tua nuova carta Qonto con questi partner di pagamento.",
        "done": "Elaborato",
        "ignored": "Ignorato",
        "others": "Altri",
        "others_tooltip": "Queste azioni devono essere gestite individualmente. Contatta il tuo consulente Qonto per avere assistenza.",
        "todo": "Da elaborare",
        "transfers": "Bonifici",
        "transfers_tooltip": "Aggiungi questi beneficiari al tuo conto Qonto."
      },
      "subtitle": "Invia le tue nuove coordinate bancarie ai tuoi clienti e partner per ricevere i primi pagamenti sul tuo conto Qonto.",
      "title": "Clienti ricorrenti"
    },
    "debit": {
      "no_actions": {
        "subtitle": "Su questo conto non abbiamo trovato nessuna transazione da trasferire. Se pensi ci sia un errore, non esitare a contattarci.",
        "title": "Nessun fornitore ricorrente"
      },
      "sections": {
        "all": {
          "methods": "Tutti i metodi"
        },
        "cards": "Carte",
        "cards_tooltip": "Registra la tua nuova carta Qonto con questi fornitori.",
        "done": "Elaborato",
        "ignored": "Ignorato",
        "mandates": "Mandati",
        "mandates_tooltip": "Annulla i tuoi attuali mandati ed emettine di nuovi con il tuo IBAN Qonto.",
        "others": "Altri",
        "others_tooltip": "Queste azioni devono essere gestite individualmente. Contatta il tuo consulente Qonto per avere assistenza.",
        "todo": "Da elaborare",
        "transfers": "Bonifici",
        "transfers_tooltip": "Aggiungi questi beneficiari al tuo conto Qonto."
      },
      "subtitle": "Condividi le tue nuove coordinate bancarie con i tuoi fornitori per garantire l’elaborazione dei pagamenti futuri.",
      "title": "Fornitori ricorrenti"
    },
    "delete_action": {
      "modal": {
        "CTA_cancel": "Annulla",
        "CTA_delete": "Elimina",
        "body": "Ricorda che l’eliminazione di questa azione è permanente.",
        "title": "Elimina questa azione?"
      },
      "toaster": "Questa azione è stata eliminata."
    },
    "edit_action": {
      "toaster": "Questa azione è stata modificata."
    },
    "edit_form": {
      "CTA_cancel": "Annulla",
      "CTA_save": "Salva le modifiche",
      "category_field": {
        "placeholder": "Scegli una categoria",
        "title": "Categoria"
      },
      "category_list": {
        "atm": "Prelievi",
        "fees": "Commissioni",
        "finance": "Finanza",
        "hotel_and_lodging": "Hotel & alloggi",
        "insurance": "Assicurazione",
        "legal_and_accounting": "Spese legali & contabilità",
        "logistics": "Logistica",
        "marketing": "Marketing",
        "office_rental": "Affitto locali",
        "office_supply": "Cancelleria",
        "online_service": "Servizi online",
        "other": "Altro",
        "pending": "In attesa",
        "restaurant_and_bar": "Ristoranti & bar",
        "salary": "Salari",
        "sales": "Vendite",
        "tax": "Tasse",
        "transport": "Trasporti",
        "utility": "Utenze"
      },
      "note_field": {
        "placeholder": "Aggiungi una nota",
        "title": "Informazioni aggiuntive"
      },
      "title_field": {
        "placeholder": "Dai un nome a questo compito",
        "title": "Nome"
      },
      "type_field": {
        "placeholder": "Scegli un metodo di pagamento",
        "title": "Metodo"
      }
    },
    "operation_history": {
      "back_to_credits_cta": "Torna ai Clienti",
      "back_to_debits_cta": "Torna ai Fornitori",
      "cancel_cta": "Segna come non elaborato",
      "check_cta": "Segna come elaborato",
      "ignore_cta": "Ignorare",
      "reactive_cta": "Riattiva",
      "subtitle": {
        "credit": "{count, plural,=1 {{count} operazione è associate a questo cliente} other {{count} operazioni sono associate a questo cliente}}",
        "debit": "{count, plural,=1 {{count} operazione é associate a questo fornitore} other {{count} operazioni sono associate a questo fornitore}}"
      },
      "table_field": {
        "amount": "Importo",
        "date": "Data",
        "label": "Transazione"
      }
    },
    "overview": {
      "cards": {
        "dropdown": {
          "title": "Tutti i conti"
        },
        "last_update": {
          "day": "Ultimo aggiornamento {count} {count, plural, =1 {giorno fa} other {giorni fa}}",
          "month": "Ultimo aggiornamento {count} {count, plural, =1 {mese fa} other {mesi fa}}",
          "year": "Ultimo aggiornamento {count} {count, plural, =1 {anno fa} other {anni fa}}"
        }
      },
      "faq": {
        "first_article": {
          "description": "Scopri l'aggregazione bancaria e come sincronizzare la tua vecchia banca con Qonto.",
          "link": "https://help.qonto.com/it/articles/5020434",
          "title": "Come funziona il servizio di trasferimento del conto?"
        },
        "second_article": {
          "description": "Trova suggerimenti e consigli dei nostri esperti su come cambiare banca facilmente.",
          "link": "https://help.qonto.com/it/articles/5145573",
          "title": "Come posso seguire la progressione del trasferimento del mio conto?"
        },
        "title": "Informazioni sul trasferimento di conto"
      },
      "popin": {
        "end_migration_completed": {
          "cta": {
            "cancel": "Annulla",
            "end_transfer": "Conferma"
          },
          "title": "Conferma che il tuo trasferimento di conto è stato completato",
          "wording": "Il tuo trasferimento di conto da {bank_name} a Qonto per {company_name} sta per terminare. Pertanto, tutti i dati relativi al tuo vecchio conto saranno cancellati."
        },
        "end_migration_not_completed": {
          "cta": {
            "cancel": "Annulla",
            "end_transfer": "Termina il trasferimento"
          },
          "title": "Termina il trasferimento del tuo conto",
          "wording": "Sei sicuro? Il trasferimento del tuo conto da {bank_name} a Qonto per {company_name} terminerà e i tuoi dati saranno cancellati. Hai cambiato idea? Puoi (ri)avviare un nuovo trasferimento quando vuoi."
        }
      },
      "stepper": {
        "end_migration": "Termina il trasferimento",
        "file_link": "Scarica la guida",
        "step1": {
          "progress_bar": {
            "completed": "Completato",
            "inprogress": "In corso"
          },
          "subtitle1": {
            "description": "Per garantire l'elaborazione dei pagamenti futuri.",
            "title": "Fornitori"
          },
          "subtitle2": {
            "description": "Per iniziare a ricevere denaro sul tuo conto Qonto.",
            "title": "Clienti"
          },
          "title": "Condividi le tue nuove coordinate bancarie con i tuoi fornitori e clienti"
        },
        "step2": {
          "subtitle": "Leggi la nostra guida per saperne di più sui passi da completare.",
          "title": "Prepara la chiusura del tuo vecchio conto"
        },
        "step3": {
          "subtitle": "Conferma che sei pronto a terminare il trasferimento del conto dopo aver completato i passi precedenti.",
          "title": "Termina il trasferimento"
        },
        "step_link": {
          "completed": "Visualizza i dettagli",
          "uncompleted": "{count} {count, plural,=1 {azione da effettuare} other {azioni da effettuare}}"
        },
        "title": "Progressione del trasferimento"
      },
      "subtitle": "In questa dashboard troverai tutte le informazioni relative al trasferimento del tuo conto.",
      "title": "Segui il progresso del tuo trasferimento di conto"
    },
    "side": {
      "credit": "Clienti",
      "debit": "Fornitori",
      "overview": "Panoramica",
      "settings": "Impostazioni",
      "settings_items": {
        "add_accounts": "Aggiungi un altro conto",
        "back_to_app": "Ritorna su Qonto",
        "ends_transfer_cta": "Termina il trasferimento di conto",
        "support": "Aiuto",
        "support_link": "https://help.qonto.com/it/",
        "switch_organization": "Cambia impresa"
      }
    }
  },
  "dashboard_generation": {
    "waiting_screen": {
      "description": "Il nostro team sta analizzando ed elencando tutte le tue transazioni ricorrenti. Ci possono volere <b>da 4 a 10 minuti</b> ma niente paura, non c'è bisogno che tu aspetti qui. Riceverai una email quando il dashboard sarà pronto.",
      "disclaimer": {
        "part_1": "Questo servizio è sicuro. Tutti i dati sono strettamente riservati all'uso di Qonto e possono essere cancellati su richiesta in qualsiasi momento.",
        "part_2": "Scopri di più"
      },
      "title": "Il tuo dashboard è stato creato"
    }
  },
  "dynamic-values": {
    "dates": {
      "current-month": "%mese%",
      "current-year": "%anno%",
      "last-month": "%mesescorso%",
      "next-month": "%meseprossimo%"
    }
  },
  "empty-state-discover": {
    "divider": "o"
  },
  "ends_transfer": {
    "confirmation_modal": {
      "cancel_cta": "Annulla",
      "confirm_cta": "Termina il trasferimento di conto",
      "subtitle": "Sei sicuro? Il trasferimento del tuo conto da {bank_name} a Qonto per {company_name} terminerà e i tuoi dati saranno cancellati. Hai cambiato idea? Puoi (ri)avviare un nuovo trasferimento quando vuoi.",
      "title": "Termina"
    }
  },
  "error_1010": {
    "disclaimer": {
      "body": "I tuoi dati non sono aggiornati: devi reinserire il codice di conferma ricevuto via SMS",
      "link": "qui"
    }
  },
  "error_429": {
    "disclaimer": {
      "body_action1": "I tuoi dati non sono aggiornati: devi accettare i termini e le condizioni sul tuo conto {bank_name}.",
      "body_action10": "I tuoi dati non sono aggiornati: devi riattivare l'autenticazione forte sul tuo conto {bank_name}.",
      "body_action11": "I tuoi dati non sono aggiornati: devi ottenere l'accesso ai servizi online contattando la tua banca {bank_name}.",
      "body_action12": "I tuoi dati non sono aggiornati: devi contattare il servizio clienti della tua banca {bank_name}.",
      "body_action13": "I tuoi dati non sono aggiornati: devi sostituire o attivare le tue credenziali contattando la tua banca {bank_name}.",
      "body_action14": "I tuoi dati non sono aggiornati: devi modificare la tua password sul tuo conto {bank_name}.",
      "body_action15": "I tuoi dati non sono aggiornati: devi creare una nuova password accedendo per la prima volta al tuo conto {bank_name}.",
      "body_action2": "I tuoi dati non sono aggiornati: devi firmare un contratto sul tuo conto {bank_name}.",
      "body_action3": "I tuoi dati non sono aggiornati: devi attivare o inserire le tue credenziali sul tuo conto {bank_name}.",
      "body_action4": "I tuoi dati non sono aggiornati: devi attivare il tuo conto {bank_name}.",
      "body_action5": "I tuoi dati non sono aggiornati: devi inserire o confermare il numero di telefono collegato al tuo conto {bank_name}.",
      "body_action6": "I tuoi dati non sono aggiornati: devi convalidare un'informazione o un messaggio sul tuo conto {bank_name}.",
      "body_action7": "I tuoi dati non sono aggiornati: devi confermare o completare alcuni dati personali sul tuo conto {bank_name}.",
      "body_action8": "Per continuare, devi confermare e/o modificare i tuoi dati sul tuo conto {bank_name}.",
      "body_action9": "I tuoi dati non sono aggiornati: devi creare un conto {bank_name}."
    }
  },
  "error_430": {
    "disclaimer": {
      "body": "I tuoi dati non sono aggiornati: devi modificare la tua password sul tuo conto {bank_name}."
    }
  },
  "error_management_screen": {
    "generic_fallback_error": {
      "description": "Un errore ci impedisce di creare automaticamente il tuo dashboard. Programma una chiamata con un consulente Qonto per ottenere assistenza.",
      "primary_cta": "Programma una chiamata",
      "title": "Non abbiamo potuto creare il tuo dashboard"
    }
  },
  "information_appointment": {
    "calendar_subtitle": "Prendi un appuntamento per preparare il trasferimento del tuo conto con un consulente dedicato.",
    "calendar_title": "Incontra il tuo consulente Qonto"
  },
  "labels": {
    "pagination_summary": "di"
  },
  "logout": {
    "btn": "Torna alla pagina di accesso",
    "modal": {
      "accept_btn": "Continua su Qonto",
      "close_btn": "Esci",
      "content": "Tra 5 minuti, raggiungerai il limite massimo di connessione di 30 minuti. Dopodiché, ti disconnetteremo automaticamente per motivi di sicurezza. Se vuoi continuare a utilizzare Qonto, puoi prolungare la tua sessione qui sotto.",
      "title": "Sei ancora qui?"
    },
    "subtitle": "La tua sessione su Qonto ha superato il limite di 30 minuti. Per motivi di sicurezza ti abbiamo disconnesso automaticamente.",
    "title": "Disconnessione effettuata."
  },
  "maintenance": {
    "cta": "Ritorna su Qonto",
    "subtitle": "Sembra che ci sia un problema. Non preoccuparti: il nostro team sta lavorando per risolvere il problema il prima possibile. Nel frattempo, contatta il tuo consulente Qonto se hai bisogno di aiuto.",
    "title": "Si è verificato un errore"
  },
  "migration_status": {
    "appointment_successfully_made_redirection_toast": "Il tuo appuntamento è confermato!",
    "end_migration": "Termina il cambio di conto",
    "step_1_subtitle": "Per generare un piano personalizzato di trasferimento del conto.",
    "step_1_title": "Le tue transazioni vengono analizzate",
    "step_2_subtitle": "Per discutere del tuo piano personalizzato di trasferimento del conto.",
    "step_2_title": "È il momento di un primo incontro con il tuo consulente Qonto",
    "step_3_subtitle": "Riceverai aggiornamenti e promemoria ad ogni fase.",
    "step_3_title": "Il trasferimento del tuo conto è in buone mani",
    "step_4_subtitle": "Pronti! Ora puoi controllare il tuo conto professionale dall’app Qonto.",
    "step_4_title": "Il trasferimento del tuo conto è terminato!",
    "subtitle": "Il trasferimento del tuo conto è in corso. Seguine la progressione qui.",
    "title": "Segui il progresso del tuo trasferimento di conto"
  },
  "migration_succeeded": {
    "back_to_qonto": "Accedi al mio conto Qonto",
    "end_migration": {
      "file_link": "Scarica la guida",
      "title1": "Termina la chiusura",
      "title2": "del tuo vecchio conto",
      "wording": "Hai già chiuso il tuo vecchio conto? Se non lo hai fatto, leggi la nostra guida per saperne di più sui passi da completare."
    },
    "subtitle": "Ora puoi approfittare di tutte le funzionalità esclusive di Qonto. Contattaci se hai bisogno di aiuto.",
    "switch_another_bank": "Trasferisci un altro conto su Qonto",
    "title": "Complimenti, il trasferimento del tuo conto è terminato!"
  },
  "no_mobile": {
    "back_to_qonto": "Ritorna su Qonto",
    "subtitle": "Per il momento questo servizio è disponibile solo su computer.",
    "title": "Vai alla web app!"
  },
  "no_rights": {
    "subtitle": "Solo un Admin può trasferire un conto.",
    "title": "Purtroppo non hai accesso a questo servizio"
  },
  "not_found": {
    "cta": "Ritorna su Qonto",
    "subtitle": "Non abbiamo trovato la pagina che stavi cercando. Forse c'è un errore nell'URL che hai inserito, oppure la pagina che cercavi è stata spostata o rimossa.",
    "title": "Non è possibile trovare questa pagina"
  },
  "onboarding_funnel": {
    "OTP_modal": {
      "code": "Codice",
      "error_text": "Codice non valido",
      "link": "Non hai ricevuto il tuo codice? Inviane un altro.",
      "primary_CTA": "Annulla",
      "secondary_CTA": "Conferma",
      "text": "Inserisci il codice di verifica temporaneo che hai appena ricevuto via SMS.",
      "title": "Inserisci il tuo codice di verifica"
    },
    "app_modal": {
      "link": "Hai bisogno di un supporto?",
      "text": "Per continuare, conferma la connessione sull'app mobile della tua banca.",
      "title": "Prendi il tuo telefono"
    },
    "consent": {
      "CTA_1": "Annulla",
      "CTA_2": "Conferma",
      "checkbox": {
        "first": "Cliccando qui, accetto i termini e le condizioni di questo servizio e confermo di aver ",
        "link": "letto l'informativa sulla protezione dei dati di Qonto.",
        "second": ""
      },
      "text": "Qonto ti offre la possibilità di collegare al tuo conto Qonto tutti i tuoi conti bancari e i conti di istituti di pagamento aperti presso istituti terzi.<br>Qonto è autorizzato dall'ACPR (Autorità di controllo prudenziale e di risoluzione) a fornirti questo servizio di informazione e di aggregazione dei conti e si basa sulle infrastrutture di Bankin'.<br>Tuttavia, non possiamo garantirti la possibilità di accedere a tutti i tuoi conti.<br><br>Le tue coordinate bancarie e i tuoi dati personali sono protetti in conformità alle normative europee (Direttiva sui servizi di pagamento e Regolamento generale sulla protezione dei dati personali).",
      "title": "Qonto ha a cuore la privacy dei tuoi dati"
    },
    "credentials": {
      "4digitcode_title": "Codice a 4 cifre",
      "CTA": "Accedi",
      "accesscode_title": "Codice di accesso",
      "accountnumber_placeholder": "Inserisci il tuo numero di conto",
      "accountnumber_title": "Numero di conto",
      "answer_placeholder": "Inserisci la tua risposta",
      "answer_title": "Risposta alla domanda di sicurezza",
      "birthdate_placeholder": "GG/MM/AAAA",
      "birthdate_title": "Data di nascita",
      "checkbox": "Dichiaro sul mio onore di avere il diritto e le autorizzazioni necessarie per accedere alle informazioni sul conto.",
      "clientnumber_placeholder": "Inserisci il tuo numero cliente",
      "clientnumber_title": "Numero cliente",
      "code_placeholder": "Inserisci il tuo codice utente",
      "code_title": "Codice utente",
      "companynumber_placeholder": "Inserisci il tuo numero ID aziendale",
      "companynumber_title": "Numero ID aziendale",
      "confidentialcode_title": "Codice riservato",
      "email_placeholder": "maria.rossi@qonto.com",
      "email_title": "Indirizzo e-mail",
      "id_placeholder": "Inserisci il tuo numero ID",
      "id_title": "Numero ID",
      "password_placeholder": "Inserisci la tua password",
      "password_title": "Password",
      "phonenumber_placeholder": "+39 12 345 6789",
      "phonenumber_title": "Numero di telefono con prefisso del paese (+39...)",
      "servercode_title": "Codice server",
      "subscriptionnumber_placeholder": "Inserisci il tuo numero d'iscrizione",
      "subscriptionnumber_title": "Numero d'iscrizione",
      "text": "Inserisci gli stessi dati di accesso che usi di solito sul sito web di {bank_name}.<br/>Niente paura, Qonto non memorizza queste informazioni.",
      "title": "Connettiti a {bank_name}",
      "usernumber_placeholder": "Inserisci il tuo numero ID",
      "usernumber_title": "Numero ID"
    },
    "error_1005": {
      "primary_CTA": "Programma una chiamata",
      "secondary_CTA": "Ritorna su Qonto",
      "text": "Un errore ci impedisce di connetterci alla tua banca. Niente paura, un agente Qonto è lì per risolvere il problema e guidarti attraverso il trasferimento.",
      "title": "L’acceso con {bank_name} è fallito"
    },
    "error_402": {
      "link": "scelto l'entità bancaria corretta?",
      "text": "Sembra che la connessione sia fallita. Puoi controllare i tuoi dati identificativi e assicurarti di aver "
    },
    "success": {
      "CTA": "Trasferisci i tuoi conti",
      "text": "Ci siamo quasi, ora puoi selezionare i conti che vuoi trasferire.",
      "title": "I tuoi dati sono stati recuperati con successo"
    },
    "synchronization": {
      "subtitle": "Il nostro team sta recuperando informazioni dal tuo conto {bank_name}. Possono volerci alcuni minuti, lascia questa scheda aperta.",
      "text": "Recupero delle informazioni dal tuo conto {bank_name}...",
      "title": "Attendi un attimo mentre otteniamo informazioni dalla tua banca"
    }
  },
  "optional_cs": {
    "select_method": {
      "main_page": {
        "algorithm_bloc": {
          "argument1": "Ci occupiamo noi della creazione del tuo dashboard",
          "argument2": "Ricevi una notifica non quando il tuo dashboard è pronto",
          "argument3": "Accedi al tuo dashboard e condividi le tue nuove coordinate bancarie",
          "description": "Per risparmiare tempo ed essere autonomo",
          "primary_cta": "Scegli",
          "tag_recommended": "Consigliato",
          "title": {
            "1": "Un dashboard creato",
            "2": "automaticamente"
          }
        },
        "customer_service_bloc": {
          "argument1": "Programma una chiamata quando lo desideri",
          "argument2": "Crea il tuo dashboard insieme al tuo consulente",
          "argument3": "Accedi al tuo dashboard e condividi le tue nuove coordinate bancarie",
          "description": "Per essere accompagnato passo dopo passo",
          "primary_cta": "Scegli",
          "title": {
            "1": "Una chiamata con un",
            "2": "consulente Qonto"
          }
        },
        "title": "Scegli il tipo di supporto di cui hai bisogno"
      }
    }
  },
  "pdf-preview": {
    "items-links": "{number, plural, =0 {} one {{language, select, fr {Lien :} de {Link:} es {Enlace:} it {Link:} other {Link:}}} other {{language, select, fr {Liens :} de {Links:} es {Enlaces:} it {Link:} other {Links:}}}}",
    "unit": {
      "ampere": "{number, plural, =0 {} one {{language, select, fr {ampère} de {Ampere} es {amperio} it {ampere} other {ampere}}} other {{language, select, fr {ampères} de {Ampere} es {amperios} it {ampere} other {amperes}}}}",
      "centimeter": "{number, plural, =0 {} one {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}} other {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}}}",
      "cubic_meter": "{number, plural, =0 {} one {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}} other {{language, select, fr {m³} de {m³} es {m³} it {m³} other {m³}}}}",
      "cubic_meter_per_hour": "{number, plural, =0 {} one {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}} other {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}}}",
      "day": "{number, plural, =0 {} one {{language, select, fr {jour} de {Tag} es {día} it {giornata} other {day}}} other {{language, select, fr {jours} de {Tage} es {días} it {giornate}  other {days}}}}",
      "gigajoule": "{number, plural, =0 {} one {{language, select, fr {gigajoule} de {Gigajoule} es {gigajulio} it {gigajoule} other {gigajoule}}} other {{language, select, fr {gigajoules} de {Gigajoule} es {gigajulios} it {gigajoule} other {gigajoules}}}}",
      "gigawatt": "{number, plural, =0 {} one {{language, select, fr {gigawatt} de {Gigawatt} es {gigavatio} it {gigawatt} other {gigawatt}}} other {{language, select, fr {gigawatts} de {Gigawatt} es {gigavatios} it {gigawatt} other {gigawatts}}}}",
      "gigawatt_hour": "{number, plural, =0 {} one {{language, select, fr {gigawattheure} de {Gigawattstunde} es {gigavatio-hora} it {gigawattora} other {gigawatt hour}}} other {{language, select, fr {gigawattheures} de {Gigawattstunden} es {gigavatios-hora} it {gigawattora} other {gigawatt hours}}}}",
      "gram": "{number, plural, =0 {} one {{language, select, fr {g} de {g} es {g} it {g} other {g}}} other {{language, select, fr {g} de {g} es {g} it {g} other {g}}}}",
      "half_year": "{number, plural, =0 {} one {{language, select, fr {semestre} de {halbes Jahr} es {semestre} it {semestre} other {half-year}}} other {{language, select, fr {semestres} de {halbe Jahre} es {semestres} it {semestri} other {half-years}}}}",
      "hour": "{number, plural, =0 {} one {{language, select, fr {heure} de {Stunde} es {hora} it {ora} other {hour}}} other {{language, select, fr {heures} de {Stunden} es {horas} it {ore} other {hours}}}}",
      "joule": "{number, plural, =0 {} one {{language, select, fr {joules} de {Joule} es {julio} it {joule} other {joule}}} other {{language, select, fr {joules} de {Joule} es {julios} it {joule} other {joules}}}}",
      "kilogram": "{number, plural, =0 {} one {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}} other {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}}}",
      "kilojoule": "{number, plural, =0 {} one {{language, select, fr {kilojoule} de {Kilojoule} es {kilojulio} it {kilojoule} other {kilojoule}}} other {{language, select, fr {kilojoules} de {Kilojoule} es {kilojulios} it {kilojoule} other {kilojoules}}}}",
      "kilovar": "{number, plural, =0 {} one {{language, select, fr {kilovar} de {Kilovar} es {kilovar} it {kilovar} other {kilovar}}} other {{language, select, fr {kilovars} de {Kilovar} es {kilovares} it {kilovar} other {kilovars}}}}",
      "kilovolt_ampere_reactive_hour": "{number, plural, =0 {} one {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}} other {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}}}",
      "kilowatt": "{number, plural, =0 {} one {{language, select, fr {kilowatt} de {Kilowatt} es {kilovatio} it {chilowatt} other {kilowatt}}} other {{language, select, fr {kilowatts} de {Kilowatt} es {kilovatios} it {chilowatt} other {kilowatts}}}}",
      "kilowatt_hour": "{number, plural, =0 {} one {{language, select, fr {kilowattheure} de {Kilowattstunde} es {kilovatio-hora} it {chilowattora} other {kilowatt hour}}} other {{language, select, fr {kilowattheures} de {Kilowattstunden} es {kilovatios-hora} it {chilowattora} other {kilowatt hours}}}}",
      "liter": "{number, plural, =0 {} one {{language, select, fr {L} de {l} es {l} it {L} other {L}}} other {{language, select, fr {to complete} de {l} es {l} it {L} other {L}}}}",
      "megajoule": "{number, plural, =0 {} one {{language, select, fr {mégajoule} de {Megajoule} es {megajulio} it {megajoule} other {megajoule}}} other {{language, select, fr {mégajoules} de {Megajoule} es {megajulios} it {megajoule} other {megajoules}}}}",
      "megawatt": "{number, plural, =0 {} one {{language, select, fr {mégawatt} de {Megawatt} es {megavatio} it {megawatt} other {megawatt}}} other {{language, select, fr {mégawatts} de {Megawatt} es {megavatios} it {megawatt} other {megawatts}}}}",
      "megawatt_hour": "{number, plural, =0 {} one {{language, select, fr {mégawattheure} de {Megawattstunde} es {megavatio-hora} it {megawattora} other {megawatt hour}}} other {{language, select, fr {mégawattheures} de {Megawattstunden} es {megavatios-hora} it {megawattora} other {megawatt hours}}}}",
      "meter": "{number, plural, =0 {} one {{language, select, fr {m} de {m} es {m} it {m} other {m}}} other {{language, select, fr {m} de {m} es {m} it {m} other {m}}}}",
      "milligram": "{number, plural, =0 {} one {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}} other {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}}}",
      "milliliter": "{number, plural, =0 {} one {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}} other {{language, select, fr {ml} de {ml} es {ml} it {ml} other {mL}}}}",
      "millimeter": "{number, plural, =0 {} one {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}} other {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}}}",
      "minute": "{number, plural, =0 {} one {{language, select, fr {minute} de {Minute} es {minuto} it {minuto} other {minute}}} other {{language, select, fr {minutes} de {Minuten} es {minutos} it {minuti} other {minutes}}}}",
      "month": "{number, plural, =0 {} one {{language, select, fr {mois} de {Monat} es {mes} it {mese} other {month}}} other {{language, select, fr {mois} de {Monate} es {meses} it {mesi} other {months}}}}",
      "number_of_articles": "{number, plural, =0 {} one {{language, select, fr {article} de {Artikel} es {artículo} it {pezzo} other {article}}} other {{language, select, fr {articles} de {Artikel} es {artículos} it {pezzi} other {articles}}}}",
      "pair": "{number, plural, =0 {} one {{language, select, fr {paire} de {Paar} es {par} it {paio} other {pair}}} other {{language, select, fr {paires} de {Paare} es {pares} it {paia} other {pairs}}}}",
      "quarter": "{number, plural, =0 {} one {{language, select, fr {trimestre} de {Viertel} es {trimestre} it {trimestre} other {quarter}}} other {{language, select, fr {trimestres} de {Viertel} es {trimestres} it {trimestri} other {quarters}}}}",
      "second": "{number, plural, =0 {} one {{language, select, fr {seconde} de {Sekunde} es {segundo} it {secondo} other {second}}} other {{language, select, fr {secondes} de {Sekunden} es {segundos} it {secondi} other {seconds}}}}",
      "set": "{number, plural, =0 {} one {{language, select, fr {ensemble} de {Set} es {juego} it {assortimento} other {set}}} other {{language, select, fr {ensembles} de {Sets} es {juegos} it {assortimenti} other {sets}}}}",
      "square_meter": "{number, plural, =0 {} one {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}} other {{language, select, fr {m²} de {m²} es {m²} it {m²} other {m²}}}}",
      "ton": "{number, plural, =0 {} one {{language, select, fr {t} de {t} es {t} it {t} other {T}}} other {{language, select, fr {t} de {t} es {t} it {t} other {T}}}}",
      "two_week": "{number, plural, =0 {} one {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two-week}}} other {{language, select, fr {deux semaines} de {zwei Wochen} es {dos semanas} it {due settimane} other {two weeks}}}}",
      "unit": "{number, plural, =0 {} one {{language, select, fr {unité} de {Einheit} es {unidad} it {unità} other {unit}}} other {{language, select, fr {unités} de {Einheiten} es {unidades} it {unità} other {units}}}}",
      "watt_hour": "{number, plural, =0 {} one {{language, select, fr {wattheure} de {Wattstunde} es {vatio-hora} it {wattora} other {watt hour}}} other {{language, select, fr {wattheures} de {Wattstunden} es {vatios-hora} it {wattora} other {watt hours}}}}",
      "week": "{number, plural, =0 {} one {{language, select, fr {semaine} de {Woche} es {semana} it {settimana} other {week}}} other {{language, select, fr {semaines} de {Wochen} es {semanas} it {settimane} other {weeks}}}}",
      "year": "{number, plural, =0 {} one {{language, select, fr {année} de {Jahr} es {año} it {anno} other {year}}} other {{language, select, fr {années} de {Jahre} es {años} it {anni} other {years}}}}"
    }
  },
  "product_tour": {
    "banner": {
      "CTA_primary": "Connetti la tua banca",
      "CTA_secondary": "Rivedi la demo"
    },
    "clients": {
      "action1": {
        "title": "Agenzia di marketing",
        "transactions": "Pagamento Agenzia di marketing"
      },
      "action2": {
        "title": "Panetteria",
        "transactions": "Debito carte panetteria"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "pagamento Qonto Airlines"
      },
      "action4": {
        "title": "Medicina del lavoro",
        "transactions": "pagamento con carta Medicina del lavoro"
      },
      "action5": {
        "title": "Forniture",
        "transactions": "Pagamento con carta Forniture"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Pagamento Qonto Technology"
      },
      "action7": {
        "title": "Gas e Elettricità",
        "transactions": "pagamento mensile Gas e Elettricità"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "abbonamento Qonto Telecom"
      }
    },
    "floating_banner": {
      "text": "Pronto a trasferire il tuo conto da Qonto?"
    },
    "overview": {
      "card1": {
        "IBAN": "IT00 1111 2222 3333 4444 5555 000",
        "title": "Vecchio conto"
      },
      "card2": {
        "IBAN": "IT00 6666 7777 8888 9999 1111 000",
        "title": "Conto principale Qonto"
      },
      "title": "Panoramica (demo)"
    },
    "suppliers": {
      "action1": {
        "title": "Agenzia di marketing",
        "transactions": "Pagamento Agenzia di marketing"
      },
      "action2": {
        "title": "Panetteria",
        "transactions": "Debito carte panetteria"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "pagamento Qonto Airlines"
      },
      "action4": {
        "title": "Medicina del lavoro",
        "transactions": "pagamento con carta Medicina del lavoro"
      },
      "action5": {
        "title": "Forniture",
        "transactions": "Pagamento con carta Forniture"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Pagamento Qonto Technology"
      },
      "action7": {
        "title": "Gas e Elettricità",
        "transactions": "pagamento mensile Gas e Elettricità"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "abbonamento Qonto Telecom"
      }
    }
  },
  "redirect": {
    "continue_button": "Continua",
    "error_message": "Per continuare, devi selezionare almeno un conto.",
    "subtitle": "La cronologia delle transazioni di questo conto sarà trasmessa al tuo consulente Qonto per l'analisi.",
    "title": "Seleziona i conti che vuoi trasferire"
  },
  "synchronization": {
    "error_page_1003": {
      "CTA": "Ritorna su Qonto",
      "body": "Un errore ci impedisce di connetterci alla tua banca. Niente paura, il nostro team ci sta già lavorando e riceverai un'email non appena sarà risolto.",
      "title": "Impossibile connetterci alla tua banca"
    },
    "error_page_402": {
      "CTA_1": "Seleziona un'altra banca",
      "CTA_2": "Hai bisogno di aiuto?",
      "body": "Sembra che tu abbia problemi a connettere la tua banca a Qonto. Alcune banche hanno diverse entità quindi controlla di aver scelto quella giusta prima di riprovare a connetterti a Qonto.",
      "title": "Sei sicuro di aver selezionato la banca giusta?"
    },
    "error_page_429": {
      "CTA_1": "Continua",
      "CTA_2": "Ritorna su Qonto",
      "body_action1": "Per continuare, devi accettare i termini e le condizioni sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action10": "Per continuare, devi riattivare l'autenticazione forte sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action11": "Per continuare, devi ottenere l'accesso ai servizi online contattando la tua banca {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action12": "Per continuare, devi contattare il servizio clienti della tua banca {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action13": "Per continuare, devi sostituire o attivare le tue credenziali contattando la tua banca {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action14": "Per continuare, devi modificare la tua password sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action15": "Per continuare, devi creare una nuova password accedendo per la prima volta al tuo conto {bank_name}}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action2": "Per continuare, devi firmare un contratto sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action3": "Per continuare, devi attivare o inserire le tue credenziali sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action4": "Per continuare, devi attivare il tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action5": "Per continuare, devi inserire o confermare il numero di telefono collegato al tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action6": "Per continuare, devi convalidare un'informazione o un messaggio sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action7": "Per continuare, devi confermare o completare alcuni dati personali sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action8": "Per continuare, devi confermare e/o modificare i tuoi dati sul tuo conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "body_action9": "Per continuare, devi creare un conto {bank_name}. Poi torna alla tua app Qonto per riprendere il trasferimento del conto.",
      "checkbox": "Cliccando qui, confermo di aver fatto quanto richiesto dalla mia banca.",
      "title": "Vai all'area clienti della tua banca",
      "toaster": "Sembra che ci sia ancora un'azione da intraprendere da parte della tua banca. Puoi verificare e riprovare?"
    }
  },
  "toast": {
    "default_error_message": "Si è verificato un errore. Potresti riprovare?",
    "not_available_for_country": "Purtroppo il trasferimento del conto non è (ancora) disponibile nel tuo paese. Resta connesso!"
  },
  "ui-kit": {
    "avatar-input": {
      "default-image-alt-text": "Immagine predefinita",
      "uploaded-image-alt-text": "Ultima immagine che hai caricato"
    },
    "btn": {
      "aria-label": {
        "clear": "Rimuovi data",
        "download": "Scarica",
        "next-month": "Mese successivo",
        "preview": "Mostra",
        "previous-month": "Mese precedente"
      },
      "cancel": "Annulla",
      "close": "Chiudi",
      "delete": "Elimina",
      "delete-aria-label": "Elimina"
    },
    "countries": {
      "AC": "Isola di Ascensione",
      "AD": "Andorra",
      "AE": "Emirati Arabi Uniti",
      "AF": "Afghanistan",
      "AG": "Antigua & Barbuda",
      "AI": "Anguilla",
      "AL": "Albania",
      "AM": "Armenia",
      "AN": "Antille olandesi",
      "AO": "Angola",
      "AQ": "Antartide",
      "AR": "Argentina",
      "AS": "Samoa americane",
      "AT": "Austria",
      "AU": "Australia",
      "AW": "Aruba",
      "AX": "Isole Aland",
      "AZ": "Azerbaigian",
      "BA": "Bosnia ed Erzegovina",
      "BB": "Barbados",
      "BD": "Bangladesh",
      "BE": "Belgio",
      "BF": "Burkina Faso",
      "BG": "Bulgaria",
      "BH": "Bahrein",
      "BI": "Burundi",
      "BJ": "Benin",
      "BL": "St. Barthélemy",
      "BM": "Bermuda",
      "BN": "Brunei",
      "BO": "Bolivia",
      "BQ": "Isole BES",
      "BR": "Brasile",
      "BS": "Bahamas",
      "BT": "Bhutan",
      "BV": "Isola Bouvet",
      "BW": "Botswana",
      "BY": "Bielorussia",
      "BZ": "Belize",
      "CA": "Canada",
      "CC": "Isole Cocos (Keeling)",
      "CD": "Repubblica Democratica del Congo - Kinshasa",
      "CF": "Repubblica Centrafricana",
      "CG": "Congo - Brazzaville",
      "CH": "Svizzera",
      "CI": "Costa d'Avorio",
      "CK": "Isole Cook",
      "CL": "Cile",
      "CM": "Camerun",
      "CN": "Cina",
      "CO": "Colombia",
      "CP": "Isola di Clipperton",
      "CR": "Costa Rica",
      "CU": "Cuba",
      "CV": "Capo Verde",
      "CW": "Curaçao",
      "CX": "Isola di Natale",
      "CY": "Cipro",
      "CZ": "Repubblica Ceca",
      "DE": "Germania",
      "DG": "Diego Garcia",
      "DJ": "Gibuti",
      "DK": "Danimarca",
      "DM": "Dominica",
      "DO": "Repubblica Dominicana",
      "DZ": "Algeria",
      "EA": "Ceuta & Melilla",
      "EC": "Ecuador",
      "EE": "Estonia",
      "EG": "Egitto",
      "EH": "Sahara occidentale",
      "ER": "Eritrea",
      "ES": "Spagna",
      "ET": "Etiopia",
      "EU": "Unione europea",
      "FI": "Finlandia",
      "FJ": "Fiji",
      "FK": "Isole Falkland",
      "FM": "Micronesia",
      "FO": "Isole Faroe",
      "FR": "Francia",
      "GA": "Gabon",
      "GB": "Regno Unito",
      "GD": "Grenada",
      "GE": "Georgia",
      "GF": "Guiana francese",
      "GG": "Guernsey",
      "GH": "Ghana",
      "GI": "Gibilterra",
      "GL": "Groenlandia",
      "GM": "Gambia",
      "GN": "Guinea",
      "GP": "Guadalupa",
      "GQ": "Guinea Equatoriale",
      "GR": "Grecia",
      "GS": "Georgia del sud e Isole Sandwich del sud",
      "GT": "Guatemala",
      "GU": "Guam",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HK": "Hong Kong",
      "HM": "Isole Heard e McDonald",
      "HN": "Honduras",
      "HR": "Croazia",
      "HT": "Haiti",
      "HU": "Ungheria",
      "IC": "Isole Canarie",
      "ID": "Indonesia",
      "IE": "Irlanda",
      "IL": "Israele",
      "IM": "Isola di Man",
      "IN": "India",
      "IO": "Territorio britannico dell'oceano indiano",
      "IQ": "Iraq",
      "IR": "Iran",
      "IS": "Islanda",
      "IT": "Italia",
      "JE": "Baliato di Jersey",
      "JM": "Giamaica",
      "JO": "Giordania",
      "JP": "Giappone",
      "KE": "Kenya",
      "KG": "Kirghizistan",
      "KH": "Cambogia",
      "KI": "Kiribati",
      "KM": "Comore",
      "KN": "St. Kitts & Nevis",
      "KP": "Corea del Nord",
      "KR": "Corea del sud",
      "KW": "Kuwait",
      "KY": "Isole Cayman",
      "KZ": "Kazakistan",
      "LA": "Laos",
      "LB": "Libano",
      "LC": "St. Lucia",
      "LI": "Liechtenstein",
      "LK": "Sri Lanka",
      "LR": "Liberia",
      "LS": "Lesotho",
      "LT": "Lituania",
      "LU": "Lussemburgo",
      "LV": "Lettonia",
      "LY": "Libia",
      "MA": "Marocco",
      "MC": "Monaco",
      "MD": "Moldavia",
      "ME": "Montenegro",
      "MF": "St. Martin",
      "MG": "Madagascar",
      "MH": "Isole Marshall",
      "MK": "Macedonia",
      "ML": "Mali",
      "MM": "Myanmar (Birmania)",
      "MN": "Mongolia",
      "MO": "Macao",
      "MP": "Isole Marianne settentrionali",
      "MQ": "Martinica",
      "MR": "Mauritania",
      "MS": "Montserrat",
      "MT": "Malta",
      "MU": "Mauritius",
      "MV": "Maldive",
      "MW": "Malawi",
      "MX": "Messico",
      "MY": "Malesia",
      "MZ": "Mozambico",
      "NA": "Namibia",
      "NC": "Nuova Caledonia",
      "NE": "Niger",
      "NF": "Isola Norfolk",
      "NG": "Nigeria",
      "NI": "Nicaragua",
      "NL": "Paesi Bassi",
      "NO": "Norvegia",
      "NP": "Nepal",
      "NR": "Nauru",
      "NU": "Niue",
      "NZ": "Nuova Zelanda",
      "OM": "Oman",
      "PA": "Panama",
      "PE": "Perù",
      "PF": "Polinesia francese",
      "PG": "Papua Nuova Guinea",
      "PH": "Filippine",
      "PK": "Pakistan",
      "PL": "Polonia",
      "PM": "St. Pierre & Miquelon",
      "PN": "Isole Pitcairn",
      "PR": "Portorico",
      "PS": "Territori palestinesi",
      "PT": "Portogallo",
      "PW": "Palau",
      "PY": "Paraguay",
      "QA": "Qatar",
      "QO": "Oceania periferica",
      "RE": "Riunione",
      "RO": "Romania",
      "RS": "Serbia",
      "RU": "Russia",
      "RW": "Ruanda",
      "SA": "Arabia Saudita",
      "SB": "Isole Salomone",
      "SC": "Seychelles",
      "SD": "Sudan",
      "SE": "Svezia",
      "SG": "Singapore",
      "SH": "Sant'Elena",
      "SI": "Slovenia",
      "SJ": "Svalbard e Jan Mayen",
      "SK": "Slovacchia",
      "SL": "Sierra Leone",
      "SM": "San Marino",
      "SN": "Senegal",
      "SO": "Somalia",
      "SR": "Suriname",
      "SS": "Sudan del Sud",
      "ST": "São Tomé e Príncipe",
      "SV": "El Salvador",
      "SX": "Saint Martin",
      "SY": "Siria",
      "SZ": "Swaziland",
      "TA": "Tristan da Cunha",
      "TC": "Isole Turks e Caicos",
      "TD": "Ciad",
      "TF": "Territori australi francesi",
      "TG": "Togo",
      "TH": "Thailandia",
      "TJ": "Tagikistan",
      "TK": "Tokelau",
      "TL": "Timor Est",
      "TM": "Turkmenistan",
      "TN": "Tunisia",
      "TO": "Tonga",
      "TR": "Turchia",
      "TT": "Trinidad e Tobago",
      "TV": "Tuvalu",
      "TW": "Taiwan",
      "TZ": "Tanzania",
      "UA": "Ucraina",
      "UG": "Uganda",
      "UM": "Isole minori esterne degli Stati Uniti",
      "US": "Stati Uniti",
      "UY": "Uruguay",
      "UZ": "Uzbekistan",
      "VA": "Città del Vaticano",
      "VC": "St. Vincent e Grenadines",
      "VE": "Venezuela",
      "VG": "Isole Vergini britanniche",
      "VI": "Isole Vergini americane",
      "VN": "Vietnam",
      "VU": "Vanuatu",
      "WF": "Wallis e Futuna",
      "WS": "Samoa",
      "XK": "Kosovo",
      "YE": "Yemen",
      "YT": "Mayotte",
      "ZA": "Sud Africa",
      "ZM": "Zambia",
      "ZW": "Zimbabwe",
      "ZZ": "Regione sconosciuta"
    },
    "country-select": {
      "label": "Paese",
      "no_matches_message": "Nessun risultato",
      "placeholder": "Paese",
      "search_placeholder": "Digita per eseguire la ricerca"
    },
    "disclaimer": {
      "alt-values": {
        "error": "Messaggio di errore",
        "info": "Messaggio informativo",
        "warning": "Messaggio di avviso"
      }
    },
    "file-status": {
      "certified": "Certificato",
      "not-certified": "Non certificato"
    },
    "form-elements": {
      "optional": "(opzionale)"
    },
    "form-fields": {
      "date-field": {
        "error": {
          "invalid": "Questa data non è valida. Puoi provare di nuovo utilizzando il formato GG/MM/AAAA?"
        },
        "placeholder": "GG/MM/AAAA"
      },
      "date-picker": {
        "clear": {
          "cta": "Rimuovi data"
        }
      }
    },
    "fullscreen": {
      "preview": {
        "delete-file": {
          "button-cancel": "Annulla",
          "button-confirm": "Elimina",
          "description": "Tieni presente che eliminare questo file è irreversibile.",
          "title": "Eliminare questo file?"
        }
      }
    },
    "gender-selector": {
      "female": "Donna",
      "label": "Sesso su documento d’identità",
      "male": "Uomo",
      "placeholder": "Genere"
    },
    "icons": {
      "alt-values": {
        "error": "Messaggio di errore",
        "info": "Messaggio informativo",
        "warning": "Messaggio di avviso"
      }
    },
    "kilobyte": "KB",
    "labels": {
      "choose_all": "Seleziona tutto",
      "clear_filters": "Deseleziona tutto"
    },
    "megabyte": "MB",
    "password_creator": {
      "label": "Password",
      "placeholder": "Inserisci qui la tua password",
      "set_pwd_rules": "Assicurati che la tua password sia composta da almeno 9 caratteri. Ti suggeriamo di non scegliere, per esempio, la tua data di nascita, il nome del tuo animale domestico o \"0000\".",
      "strength_password_strong": "La tua password è < molto sicura. >",
      "strength_password_weak": "La tua password è < ancora poco sicura. >"
    },
    "pdf-preview": {
      "amount-discount": "{language, select, fr {Remise} de {Rabatt} es {Descuento} it {Sconto} other {Discount}}",
      "beneficiary-name": "{language, select, fr {Nom du bénéficiaire} it {Nome e cognome del beneficiario} de {Name des/r Begünstigten} es {Nombre del beneficiario} other {Beneficiary name}}",
      "bic": "BIC",
      "client-steuer-id": "{language, select, fr {Numéro fiscal client} de {Kunden-Steuer-ID} es {Codice fiscale cliente} it {NIF cliente} other {Client tax ID}}",
      "client-ust-udnr": "{language, select, fr {Numéro TVA client} de {Kunden-USt.-IDNr.} es {Número de IVA cliente} it {Partita IVA cliente} other {Client VAT number}}",
      "credit-note": {
        "credit-note-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "credit-note-number": "{language, select, fr {Numéro de l'avoir} it {Numero nota di credito} de {Stornorechnungsnummer} es {Número de factura rectificativa} other {Credit note number}}",
        "invoice-issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
        "reason-credit-note": "{language, select, fr {Motif de l'avoir :} it {Motivo emissione nota di credito:} de {Grund für die Stornorechnung:} es {Motivo de la factura rectificativa:} other {Reason for credit note:}}",
        "related-invoice-number": "{language, select, fr {Numéro de facture associée} it {Numero fattura correlata} de {Zugehörige Rechnungsnummer} es {Número de factura asociada} other {Related invoice number}}",
        "title": "{language, select, fr {Avoir} it {Nota di credito} de {Stornorechnung} es {Factura rectificativa} other {Credit note}}"
      },
      "delivery-address": "{language, select, fr {Adresse de livraison} de {Lieferadresse} es {Dirección de envío} it {Indirizzo di consegna} other {Delivery address}}",
      "district-court": "{language, select, fr {Greffe du tribunal d'arrondissement :} de {Registergericht} es {Juzgado de registro de distrito:} it {Registro del tribunale distrettuale:} other {Registry district court:}}",
      "draft": {
        "invoice-number-draft-value": "--",
        "proforma-disclaimer": "{language, select, it {Il presente documento non costituisce fattura valida ai fini del DPR 633 del 26/10/1972 e successive modifiche. La fattura definitiva verrà emessa all’atto del pagamento del corrispettivo (articolo 6, comma 3, DPR 633/72).} other {This document does not constitute a valid invoice as outlined in the Decree of the President of the Republic (DPR) no. 633 of 26 October 1972 and subsequent amendments. The final invoice will be issued upon payment of the consideration (Article 6, paragraph 3, of the DPR 633/72).}}",
        "title": "{language, select, fr {Facture pro forma} de {Proformarechnung} es {Factura proforma} it {Fattura proforma} other {Pro forma invoice}}"
      },
      "due-date": "{language, select, fr {Date d'échéance} it {Data di scadenza} de {Fälligkeitsdatum} es {Fecha de vencimiento} other {Due date}}",
      "exemption": {
        "AT": {
          "S11.1a": "{language, select, fr {TVA non applicable, article 11 de l'UStG} de {MwSt. entfällt, Art. 11 des UStG} es {IVA no aplicable, art. 11 del UStG} it {IVA non applicabile, art. 11 del UStG} other {VAT not applicable, art. 11 of the UStG}}",
          "S6.1.13": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.16": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.19": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.27": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}"
        },
        "BE": {
          "S21": "{language, select, fr {TVA non applicable, article 21 du BTW-Wetboek} de {MwSt. entfällt, Art. 21 des BTW-Wetboek} es {IVA no aplicable, art. 21 del BTW-Wetboek} it {IVA non applicabile, art. 21 del BTW-Wetboek} other {VAT not applicable, art. 21 of the BTW-Wetboek}}",
          "S39": "{language, select, fr {TVA non applicable, article 39 du BTW-Wetboek} de {MwSt. entfällt, Art. 39 des BTW-Wetboek} es {IVA no aplicable, art. 39 del BTW-Wetboek} it {IVA non applicabile, art. 39 del BTW-Wetboek} other {VAT not applicable, art. 39 of the BTW-Wetboek}}",
          "S39bis": "{language, select, fr {TVA non applicable, article 39bis du BTW-Wetboek} de {MwSt. entfällt, Art. 39bis des BTW-Wetboek} es {IVA no aplicable, art. 39bis del BTW-Wetboek} it {IVA non applicabile, art. 39bis del BTW-Wetboek} other {VAT not applicable, art. 39bis of the BTW-Wetboek}}",
          "S44.1": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.11": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.2": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.4": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S56bis": "{language, select, fr {TVA non applicable, article 56bis du BTW-Wetboek} de {MwSt. entfällt, Art. 56bis des BTW-Wetboek} es {IVA no aplicable, art. 56bis del BTW-Wetboek} it {IVA non applicabile, art. 56bis del BTW-Wetboek} other {VAT not applicable, art. 56bis of the BTW-Wetboek}}"
        },
        "DE": {
          "S13B": "{language, select, fr {Steuerschuldnerschaft des Leistungsempfängers (obligation fiscale du bénéficiaire) (section 13b)} de {Steuerschuldnerschaft des Leistungsempfängers (Abschnitt 13b)} es {Steuerschuldnerschaft des Leistungsempfängers (responsabilidad fiscal del beneficiario) (Sección 13b)} it {Steuerschuldnerschaft des Leistungsempfängers (responsabilità fiscale del beneficiario) (Sezione 13b)} other {Steuerschuldnerschaft des Leistungsempfängers (Tax liability of the beneficiary) (Section 13b)}}",
          "S19.1": "{language, select, fr {Conformément à la section 19, paragraphe 1 de l'UStG, le montant de la facture n'inclut pas de taxe de vente} de {Gemäß Abschnitt 19 Abs. 1 UStG ist im Rechnungsbetrag keine Umsatzsteuer enthalten} es {Según el artículo 19, apartado 1 de la UStG, el importe de la factura no incluye el impuesto sobre las ventas} it {Ai sensi della sezione 19, paragrafo 1, della UStG, l'importo della fattura non comprende l'imposta sulle vendite} other {According to Section 19 Paragraph 1 UStG, the invoice amount does not include sales tax}}",
          "S4": "{language, select, fr {Conformément à la section 4 de l'UStG, aucune taxe sur la valeur ajoutée n'est facturée} de {Gemäß Abschnitt 4 UStG wird keine Mehrwertsteuer erhoben} es {Según el artículo 4 de la UStG, no se cobra ningún impuesto sobre el valor añadido} it {Ai sensi della sezione 4 della UStG, non viene addebitata alcuna imposta sul valore aggiunto} other {In accordance to § 4 UStG, no value added tax is charged}}",
          "S4.1A": "{language, select, fr {Livraison d'exportation exonérée d'impôt, conformément à la section 4 n° 1a de l'UStG} de {Steuerfreie Exportlieferung gemäß Abschnitt 4 Nr. 1a des UStG} es {Entrega para exportación exenta de impuestos según el artículo 4 núm. 1a de la UStG} it {Cessione dell'esportazione esente da imposte ai sensi della sezione. 4 n. 1a UStG} other {Tax-free export delivery in accordance with Section 4 No. 1a UStG}}",
          "S4.1B": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à la section 4 n° 1b de l'UStG} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Abschnitt 4 Nr. 1b des UStG} es {Entrega intracomunitaria exenta de impuestos según el artículo 4 núm. 1b de la UStG} it {Cessione intracomunitaria esente da imposte ai sensi della sezione 4 n. 1b UStG} other {Tax-free intra-community delivery in accordance with Section 4 No. 1b UStG.}}"
        },
        "ES": {
          "S122": "{language, select, fr {Opération exonérée d'impôt avec l'art. 122 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 122 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos en virtud del art. 122 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 122 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 122 of Law 37/1992, on VAT}}",
          "S20": "{language, select, fr {Opération exonérée d'impôt, conformément à l'art. 20 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 20 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por aplicación del art. 20 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 20 della Legge 37/1992 sull'IVA} other {Tax-free operation in accordance with art. 20 of Law 37/1992, on VAT}}",
          "S21": "{language, select, fr {Exportation exonérée d'impôt avec l'art. 21 de la Loi 37/1992 sur la TVA} de {Steuerfreier Export gemäß Art. 21 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por exportación en virtud del art. 21 de la Ley 37/1992, del IVA} it {Esportazione esente da imposta ai sensi dell'art. 21 della Legge 37/1992 sull'IVA} other {Tax-free export in accordance with art. 21 of Law 37/1992, on VAT}}",
          "S25": "{language, select, fr {Livraison intracommunautaire exonérée d'impôt, conformément à l'art. 25 de la Loi 37/1992 sur la TVA} de {Steuerfreie innergemeinschaftliche Lieferung gemäß Art. 25 des Gesetzes 37/1992 über die MwSt.} es {Entrega intracomunitaria exenta de impuestos por aplicación del art. 25 de la Ley 37/1992, del IVA} it {Cessione intracomunitaria esente da imposta ai sensi dell'art. 25 della Legge 37/1992 sull'IVA} other {Tax-free intra-community delivery in accordance with art. 25 of Law 37/1992, on VAT}}",
          "S69": "{language, select, fr {Opération située à l'adresse du destinataire, conformément à l'art. 69 de la Loi 37/1992 sur la TVA} de {Ein auf der Anschrift des Empfängers oder der Empfängerin laufender Vorgang gemäß Art. 69 des Gesetzes 37/1992 über die MwSt.} es {Operación localizada en sede del destinatario en virtud del art. 69 de la Ley 37/1992, del IVA} it {Operazione situata all'indirizzo del destinatario ai sensi dell'art. 69 della Legge 37/1992 sull'IVA} other {Operation located at the address of the recipient in accordance with art. 69 of Law 37/1992, on VAT}}",
          "S84.1.2": "{language, select, fr {Reversement de l'assujetti - Art. 84.1.2º de la Loi 37/1992 sur la TVA} de {Umkehrung des Steuerpflichtigen – Art. 84.1.2º des Gesetzes 37/1992 über die MwSt.} es {Inversión de sujeto pasivo - Art. 84.1.2º de la Ley 37/1992, del IVA} it {Storno del soggetto passivo - Art. 84.1.2º della Legge 37/1992 sull'IVA} other {Reversal of taxable person - Art. 84.1.2º of Law 37/1992, on VAT}}"
        },
        "FR": {
          "S259": "{language, select, fr {TVA non applicable, art. 259-1 du CGI} de {MwSt. entfällt, Art. 259-1 des CGI} es {IVA no aplicable, art. 259-1 del CGI} it {IVA non applicabile, art. 259-1 del CGI} other {VAT not applicable, art. 259-1 of the CGI}}",
          "S261": "{language, select, fr {TVA non applicable, art. 261 du CGI} de {MwSt. entfällt, Art. 261 des CGI} es {IVA no aplicable, art. 261 del CGI} it {IVA non applicabile, art. 261 del CGI} other {VAT not applicable, art. 261 of the CGI}}",
          "S262": "{language, select, fr {TVA non applicable, art. 262 du CGI} de {MwSt. entfällt, Art. 262 des CGI} es {IVA no aplicable, art. 262 del CGI} it {IVA non applicabile, art. 262 del CGI} other {VAT not applicable, art. 262 of the CGI}}",
          "S262.1": "{language, select, fr {TVA non applicable, article 262 I du CGI} de {MwSt. entfällt, Art. 262 I des CGI} es {IVA no aplicable, art. 262 I del CGI} it {IVA non applicabile, art. 262 I del CGI} other {VAT not applicable, art. 262 I of the CGI}}",
          "S263": "{language, select, fr {TVA non applicable, art. 263 du CGI} de {MwSt. entfällt, Art. 263 des CGI} es {IVA no aplicable, art. 263 del CGI} it {IVA non applicabile, art. 263 del CGI} other {VAT not applicable, art. 263 of the CGI}}",
          "S283": "{language, select, fr {Autoliquidation, art. 283 du CGI} de {Steuerschuldnerschaft des Leistungsempfängers, Art. 283 des CGI} es {Autoliquidación, art. 283 del CGI} it {Autoliquidazione, art. 283 del CGI} other {Autoliquidation, art. 283 of the CGI}}",
          "S293B": "{language, select, fr {TVA non applicable, art. 293 B du CGI} de {MwSt. entfällt, Art. 263 B des CGI} es {IVA no aplicable, art. 293 B del CGI} it {IVA non applicabile, art. 293 B della CGI} other {VAT not applicable, art. 293 B of the CGI}}"
        },
        "NL": {
          "S11": "{language, select, fr {TVA non applicable, article 11 de l'OB 1968} de {MwSt. entfällt, Art. 11 des OB 1968} es {IVA no aplicable, art. 11 del OB 1968} it {IVA non applicabile, art. 11 del OB 1968} other {VAT not applicable, art. 11 of the OB 1968}}",
          "S12": "{language, select, fr {TVA non applicable, article 12 de l'OB 1968} de {MwSt. entfällt, Art. 12 des OB 1968} es {IVA no aplicable, art. 12 del OB 1968} it {IVA non applicabile, art. 12 del OB 1968} other {VAT not applicable, art. 12 of the OB 1968}}",
          "S25": "{language, select, fr {TVA non applicable, article 25 de l'OB 1968} de {MwSt. entfällt, Art. 25 des OB 1968} es {IVA no aplicable, art. 25 del OB 1968} it {IVA non applicabile, art. 25 del OB 1968} other {VAT not applicable, art. 25 of the OB 1968}}",
          "S9": "{language, select, fr {TVA non applicable, article 9 de l'OB 1968} de {MwSt. entfällt, Art. 9 des OB 1968} es {IVA no aplicable, art. 9 del OB 1968} it {IVA non applicabile, art. 9 del OB 1968} other {VAT not applicable, art. 9 of the OB 1968}}"
        },
        "PT": {
          "S53": "{language, select, fr {TVA non applicable, article 53 du Regime do IVA} de {MwSt. entfällt, Art. 53 des Regime do IVA} es {IVA no aplicable, art. 53 del Regime do IVA} it {IVA non applicabile, art. 53 del Regime do IVA} other {VAT not applicable, art. 53 of the Regime do IVA}}",
          "S9.1-5": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.27.28": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.29.30": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}"
        }
      },
      "footer-organization": "{language, select, fr {{orgaName} avec un capital de {amount}} de {mit einem Kapital von} es {{orgaName} con un capital de {amount}} it {{orgaName} con un capitale di {amount}} other {{orgaName} with a capital of {amount}}}",
      "iban": "IBAN",
      "intermediary-bic": {
        "label": "{language, select, fr {BIC de la banque intermédiaire} de {BIC unserer Korrespondenzbank} es {BIC del banco intermediario} it {BIC della banca corrispondente} other {Intermediary bank BIC}}",
        "value": "{language, select, fr {TRWIBEB3XXX} de {TRWIBEB3XXX} es {TRWIBEB3XXX} it {TRWIBEB3XXX} other {TRWIBEB3XXX}}"
      },
      "invoice-example": {
        "client-address-line1": "Via Giuseppe Mazzini 9",
        "client-city": "Milano",
        "client-countrycode": "DE",
        "client-delivery-address-line1": "Via Giuseppe Mazzini 9",
        "client-delivery-city": "Milano",
        "client-delivery-countrycode": "IT",
        "client-delivery-zipcode": "20122",
        "client-name": "Maria Rossi",
        "client-vat-number": "RSSMRA85A41F205G",
        "client-zipcode": "20123",
        "invoice-due-date": "15/01/2030",
        "invoice-issue-date": "01/01/2030",
        "invoice-number": "F-2030-001",
        "invoice-payment-time": "Si prega di pagare l'importo della fattura entro 15 giorni. (15/01/2030)",
        "invoice-performance-date": "01/01/2030",
        "invoice-total": "122",
        "item-description": "Le informazioni sui tuoi futuri articoli reali compariranno qui.",
        "item-price": "100",
        "item-quantity": "2",
        "item-title": "Il titolo del mio primo articolo",
        "item-total": "120",
        "item-total-without-vat": "100",
        "item-vat-amount": "0.2",
        "item-vat-percentual": "%",
        "item-vat-total": "22",
        "page-count": "1/1",
        "quote-validated-time": "Questo preventivo è valido per 30 giorni (15/01/2030)"
      },
      "invoice-number": "{language, select, fr {Numéro de facture} it {Numero della fattura} de {Rechnungsnummer} es {Número de factura} other {Invoice number}}",
      "invoice-payment-time": "{number, plural, =0 {{language, select, fr {Veuillez payer la facture à l'émission. ({dueDate})} de {Bitte bezahlen Sie den Betrag direkt nach Erhalt der Rechnung. ({dueDate})} es {Por favor, abona el importe de la factura tras la recepción. ({dueDate})} it {Per favore, paga l’importo della fattura alla sua ricezione. ({dueDate})} other {Please pay the invoice amount upon receipt. ({dueDate})}}} one {{language, select, fr {Veuillez payer la facture sous {days_amount} jour. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tag. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} día. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorno. ({dueDate})} other {Please pay the invoice amount within {days_amount} day. ({dueDate})}}} other {{language, select, fr {Veuillez payer la facture sous {days_amount} jours. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tagen. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} días. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorni. ({dueDate})} other {Please pay the invoice amount within {days_amount} days. ({dueDate})}}}}",
      "issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Issue date}}",
      "items": "{language, select, fr {Description} it {Descrizione} de {Beschreibung} es {Descripción} other {Description}}",
      "leadership": "{language, select, fr {Direction de l'entreprise :} de {Geschäftsführung} es {Dirección de la empresa:} it {Direzione dell'impresa:} other {Company leadership:}}",
      "payment-condition": {
        "compensated_for_sales": "{language, select, fr {Sur les débits} de {Im zu zahlenden Betrag enthalten} es {Compensación por ventas} it {Inclusa nel prezzo di vendita} other {Compensated for sales}}",
        "exempt": "{language, select, fr {Exonération} de {Befreit} es {Exento} it {Esente} other {Exempt}}",
        "label": "{language, select, fr {Conditions de paiement de la TVA : } de {MwSt.-Zahlungsbedingung: } es {Condición de pago del IVA: } it {Opzioni per il pagamento dell'IVA: } other {VAT payment condition: }}",
        "on_receipts": "{language, select, fr {Sur les encaissements} de {Sofort} es {Tras la recepción} it {A vista} other {On receipt}}"
      },
      "payment-details": "{language, select, fr {Détails du paiement} it {Dettagli per il pagamento} de {Zahlungsdetails} es {Detalles del pago} other {Payment details}}",
      "pension-contribution": "{language, select, it {Contributo pensione/INPS (IVA escl.)} other {Pension/INPS contribution (excl. VAT)}}",
      "percentage-discount": "{language, select, fr {Remise ({discountPercentage} %)} de {Rabatt ({discountPercentage}%)} es {Descuento ({discountPercentage}%)} it {Sconto ({discountPercentage}%)} other {Discount ({discountPercentage}%)}}",
      "performance-date": "{language, select, fr {Date d'exécution} de {Leistungsdatum} es {Fecha de ejecución} it {Data prestazione} other {Performance date}}",
      "placerholder-stamp-duty": "{language, select, it {Imposta di bollo virtuale pagata ai sensi del decreto ministeriale 17.06.2014} other {Stamp duty paid virtually pursuant to Ministerial Decree 17.06.2014}}",
      "purchase-order": "{language, select, fr {Numéro de bon de commande} it {Numero d'ordine} de {Bestellnummer} es {Número de orden de compra} other {Purchase order number}}",
      "quantity": "{language, select, fr {Qté} it {Qtà} de {Menge} es {Cant.} other {Qty}}",
      "quote-expiry-date": "{language, select, fr {Date d'expiration} it {Data di scadenza} de {Ablaufdatum} es {Fecha de vencimiento} other {Expiry date}}",
      "quote-number": "{language, select, fr {Numéro de devis} it {Numero del preventivo} de {Angebotsnummer} es {Número de presupuesto} other {Quote number}}",
      "quote-related-number": "{language, select, fr {Numéro du devis associé } it {Numero di preventivo correlato } de {Zugehöriges Angebot } es {Número de presupuesto asociado } other {Related quote number }}",
      "quote-title": "{language, select, fr {Devis} de {Angebot} es {Presupuesto} it {Preventivo} other {Quote}}",
      "quote-validated-time": "{number, plural, =0 {} one {{language, select, fr {Ce devis est valable {days_amount} jour. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tag. ({expiry_date})} es {Este presupuesto es válido por {days_amount} día. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorno. ({expiry_date})} other {This quote is valid for {days_amount} day. ({expiry_date})}}} other {{language, select, fr {Ce devis est valable {days_amount} jours. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tage. ({expiry_date})} es {Este presupuesto es válido por {days_amount} días. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorni. ({expiry_date})} other {This quote is valid for {days_amount} days. ({expiry_date})}}}}",
      "reference": "{language, select, fr {Référence} it {Causale} de {Referenz} es {Referencia} other {Reference}}",
      "revenue-stamp": "{language, select, it {Marca da bollo} other {Revenue stamp}}",
      "steuer-id": "{language, select, fr {Steuer-ID} de {Steuer-ID} es {Steuer-ID} it {Steuer-ID} other {Steuer-ID}}",
      "steuernummer": "{language, select, fr {Steuernummer :} de {Steuernummer:} es {Steuernummer:} it {Steuernummer:} other {Steuernummer:}}",
      "title": "{language, select, fr {Facture} de {Rechnung} es {Factura} it {Fattura} other {Invoice}}",
      "total": "{language, select, fr {Total} it {Totale} de {Gesamt} es {Total} other {Total}}",
      "total-vat": "{language, select, fr  {Total TTC} it {Totale (IVA inclusa)}  de {Gesamt (inkl. MwSt.)} es {Total (IVA incluido)} other {Total incl. VAT}}",
      "total-vat-amount": "{language, select, fr {Montant total de la TVA} it {Importo totale IVA} de {MwSt.-Betrag insgesamt} es {Importe total del IVA} other {Total VAT amount}}",
      "total-without-vat": "{language, select, fr {Total HT} it {Totale (IVA escl.)} de {Gesamt (exkl. MwSt.)} es {Total (IVA no incluido)} other {Total excl. VAT}}",
      "transaction-type": {
        "goods": "{language, select, fr {Biens} de {Waren} es {Bienes} it {Beni} other {Goods}}",
        "goods_and_services": "{language, select, fr {Biens et services} de {Waren und Dienstleistungen} es {Bienes y servicios} it {Beni e servizi} other {Goods and services}}",
        "label": "{language, select, fr {Type de transaction : } de {Transaktionstyp: } es {Tipo de transacción: } it {Tipo di transazione: } other {Transaction type: }}",
        "services": "{language, select, fr {Services} de {Dienstleistungen} es {Servicios} it {Servizi} other {Services}}"
      },
      "unit-price": "{language, select, fr {Prix unitaire} it {Prezzo unitario} de {Preis pro Einheit} es {Precio unitario} other {Unit price}}",
      "ust-udnr": "{language, select, fr {USt-IDNr.} de {USt-IDNr.} es {USt-IDNr.} it {USt-IDNr.} other {USt-IDNr.}}",
      "vat": "{language, select, fr {TVA (%)} it {IVA (%)} de {MwSt. (%)} es {IVA (%)} other {VAT (%)}}",
      "vat-amount": "{language, select, fr {Montant de la TVA} it {Importo IVA} de {MwSt-Betrag} es {Importe del IVA} other {VAT amount}}",
      "vat-number": "{language, select, fr {Numéro de TVA :} de {USt.-IdNr.:} es {Número de IVA:} it {Partita IVA:} other {VAT number:}}",
      "withholding-tax": "{language, select, it {Ritenuta d'acconto} other {Withholding tax}}"
    },
    "spinner": {
      "label": "Caricando"
    },
    "stepper-header": {
      "back": "Indietro",
      "close": "Chiudi"
    },
    "translation-with-args": {
      "body-1": "Testo con {var} all'interno.",
      "body-3": "Testo con {var2} all'interno e poi ancora {var3} all'interno e poi ancora {var1} all'interno."
    },
    "zxcvbn": {
      "password_common": "Questa password è troppo comune, quindi poco sicura.",
      "password_date": "Ti sconsigliamo di scegliere una data.",
      "password_dictionary": "Questa password è troppo comune, quindi poco sicura.",
      "password_repeat": "Non è consigliabile scegliere la ripetizione di parole o lettere.",
      "password_sequence": "Le sequenze logiche o numeriche, come ad esempio \"1234\", sono facili da indovinare.",
      "password_spatial": "Le sequenze della tastiera come \"qwerty\" non sono abbastanza sicure."
    }
  },
  "welcome": {
    "gdpr_checkbox": "Acconsento al trattamento dei miei dati personali da parte di Qonto",
    "gdpr_checkbox_error_message": "Per continuare, devi accettare la nostra politica di utilizzo dei dati.",
    "redirect_to_appointment_page": "Per maggiori informazioni chiedi a uno dei nostri esperti",
    "redirect_to_bridge": "Inizia il trasferimento",
    "step_1_subtitle": "Grazie al servizio sicuro Bridge by Bankin'.",
    "step_1_title": "Collega la tua banca a Qonto",
    "step_2_subtitle": "I tuoi dati saranno sincronizzati in tutta sicurezza.",
    "step_2_title": "Seleziona il conto o i conti che vuoi trasferire",
    "step_3_subtitle": "Per rispondere a tutte le tue domande.",
    "step_3_title": "Un consulente Qonto ti assiste dalla A alla Z",
    "subtitle": "E ottimizza la gestione delle tue finanze.",
    "title": "Trasferisci i tuoi conti senza problemi"
  }
}], ["pt", {
  "application": {
    "back_to_app": "Revenir à Qonto",
    "go_back": "Retour",
    "security_disclaimer": "Ce service est sécurisé. Toutes les données sont strictement réservées à l'usage de Qonto et peuvent être supprimées sur demande à tout moment.",
    "security_disclaimer_link": "En savoir plus",
    "title": "Transfert de compte"
  },
  "appointment": {
    "back_cta": "Retour",
    "calendar_subtitle": "Prenez rendez-vous avec votre conseiller Qonto pour préparer votre transfert de compte.",
    "calendar_title": "Rencontrez votre conseiller Qonto"
  },
  "bank_selection": {
    "consent_modal": {
      "checkbox_text": "Je consens à l'utilisation de mes données personnelles par Qonto.",
      "primary_CTA": "Confirmer",
      "secondary_CTA": "Annuler",
      "subtitle": "Pour des raisons légales, nous avons besoin que vous acceptiez notre politique d'utilisation des données avant de connecter votre banque à Qonto via le service sécurisé Bridge by Bankin'.",
      "title": "Acceptez cette condition"
    },
    "select_child_bank": {
      "back_CTA": "Retour",
      "individual": "Compte personnel",
      "pro": "Compte professionnel",
      "subtitle": "{bank_name} a plusieurs entités donc veillez bien à sélectionner celle qui vous correspond. Si vous avez le moindre doute, nous vous conseillons de contacter votre banque.",
      "title": "Sélectionnez votre entité bancaire"
    },
    "select_parent_bank": {
      "cannot_find": "Votre banque n'est pas dans la liste ?",
      "search_empty": {
        "CTA": "Nous contacter",
        "subtitle": "Indiquez-nous la banque qu'il vous manque en nous contactant via notre chat. Notre équipe fera de son mieux pour vous accompagner dans votre transfert de compte.",
        "title": "Cette banque n'est pas (encore) répertoriée"
      },
      "search_placeholder": "Rechercher une banque...",
      "title": "Sélectionnez votre banque"
    }
  },
  "bankswitch": {
    "backend_error": {
      "toaster": "Une erreur s’est produite. Pouvez-vous rafraîchir la page ?"
    }
  },
  "btn": {
    "back": "Retour"
  },
  "choose_organization": {
    "continue_button": "Continuer",
    "error_message": "Pour continuer, vous devez sélectionner une entreprise.",
    "subtitle": "Sélectionnez une entreprise vers laquelle transférer votre compte.",
    "title": "Sélectionnez une entreprise"
  },
  "dashboard": {
    "CTA": {
      "new_action": "Créer une action"
    },
    "account_details": {
      "bic": "BIC",
      "disclaimer": "Vous ne pouvez migrer qu'une seule banque pour le moment, le multi-banques arrive bientôt.",
      "iban": "IBAN",
      "no_iban": "Vous trouverez votre IBAN sur l'espace client de votre banque.",
      "no_iban_link": "Accédez à votre IBAN sur Qonto.",
      "notifications": {
        "bic_copy": "Votre BIC est copié !",
        "iban_copy": "Votre IBAN est copié !"
      }
    },
    "action": {
      "important": {
        "tag": "Important"
      },
      "menu": {
        "CTA_delete": "Supprimer",
        "CTA_edit": "Modifier",
        "CTA_flag_as_important": "Indiquer comme important",
        "CTA_ignore": "Ignorer",
        "CTA_mark_as_done": "Indiquer comme traité",
        "CTA_unflag_as_important": "Indiquer comme non important",
        "CTA_unignore": "Rétablir",
        "CTA_unmark_as_done": "Indiquer comme non traité"
      }
    },
    "card": {
      "action": {
        "ignored": "Ignorer",
        "reactive": "Rétablir"
      },
      "category": {
        "cards": "Cartes",
        "mandates": "Mandats",
        "others": "Autres",
        "transfers": "Virements"
      },
      "last_operation": "Dernière :",
      "last_operation_detected": "Dernière opération détectée :",
      "operations": "{count, plural,=1 {Opération :} other {Opérations :}}"
    },
    "create_action": {
      "toaster": "Une nouvelle action a été créée avec succès."
    },
    "create_form": {
      "CTA_cancel": "Annuler",
      "CTA_create": "Créer",
      "category_field": {
        "placeholder": "Sélectionnez une catégorie",
        "title": "Catégorie"
      },
      "category_list": {
        "atm": "Retraits",
        "fees": "Frais",
        "finance": "Finance",
        "hotel_and_lodging": "Hôtels & Logement",
        "insurance": "Assurance",
        "legal_and_accounting": "Juridique & Comptabilité",
        "logistics": "Logistique",
        "marketing": "Marketing",
        "office_rental": "Location de bureau",
        "office_supply": "Fournitures de bureau",
        "online_service": "Services en ligne",
        "other": "Autre",
        "pending": "En attente",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Salaires",
        "sales": "Ventes",
        "tax": "Impôts & Taxes",
        "transport": "Transport",
        "utility": "Utilitaire"
      },
      "note_field": {
        "placeholder": "Ajoutez une note",
        "title": "Informations complémentaires"
      },
      "title": {
        "placeholder": "Nouvelle tâche"
      },
      "title_field": {
        "placeholder": "Donnez un nom à cette tâche",
        "title": "Nom"
      },
      "type_field": {
        "placeholder": "Sélectionnez une méthode de paiement",
        "title": "Méthode"
      }
    },
    "credit": {
      "no_actions": {
        "subtitle": "Nous n'avons trouvé aucune transaction à transférer depuis ce compte. N'hésitez pas à nous contacter si vous pensez qu'il s’agit d’une erreur.",
        "title": "Aucun client récurrent"
      },
      "sections": {
        "all": {
          "methods": "Toutes les méthodes"
        },
        "cards": "Cartes",
        "cards_tooltip": "Enregistrez votre nouvelle carte Qonto auprès de ces partenaires de paiement.",
        "done": "Traité",
        "ignored": "Ignoré",
        "others": "Autres",
        "others_tooltip": "Ces actions doivent être traitées individuellement. Contactez votre conseiller Qonto pour obtenir de l’aide.",
        "todo": "À traiter",
        "transfers": "Virements",
        "transfers_tooltip": "Ajoutez ces bénéficiaires à votre compte Qonto."
      },
      "subtitle": "Envoyez vos nouvelles coordonnées bancaires à vos clients et partenaires pour recevoir vos premiers paiements sur votre compte Qonto.",
      "title": "Clients récurrents"
    },
    "debit": {
      "no_actions": {
        "subtitle": "Nous n'avons trouvé aucune transaction à transférer depuis ce compte. N'hésitez pas à nous contacter si vous pensez qu'il s’agit d’une erreur.",
        "title": "Aucun fournisseur récurrent"
      },
      "sections": {
        "all": {
          "methods": "Toutes les méthodes"
        },
        "cards": "Cartes",
        "cards_tooltip": "Enregistrez votre nouvelle carte Qonto auprès de ces fournisseurs.",
        "done": "Traité",
        "ignored": "Ignoré",
        "mandates": "Mandats",
        "mandates_tooltip": "Annulez vos mandats actuels et émettez-en de nouveaux avec votre IBAN Qonto.",
        "others": "Autres",
        "others_tooltip": "Ces actions doivent être traitées individuellement. Contactez votre conseiller Qonto pour obtenir de l’aide.",
        "todo": "À traiter",
        "transfers": "Virements",
        "transfers_tooltip": "Ajoutez ces bénéficiaires à votre compte Qonto."
      },
      "subtitle": "Partagez vos nouvelles coordonnées bancaires avec vos fournisseurs pour garantir le traitement de vos futurs paiements.",
      "title": "Fournisseurs récurrents"
    },
    "delete_action": {
      "modal": {
        "CTA_cancel": "Annuler",
        "CTA_delete": "Supprimer",
        "body": "Gardez en tête que la suppression de cette action est définitive.",
        "title": "Supprimer cette action ?"
      },
      "toaster": "Cette action a été supprimée avec succès."
    },
    "edit_action": {
      "toaster": "Cette action a été modifiée avec succès."
    },
    "edit_form": {
      "CTA_cancel": "Annuler",
      "CTA_save": "Enregistrer les modifications",
      "category_field": {
        "placeholder": "Sélectionnez une catégorie",
        "title": "Catégorie"
      },
      "category_list": {
        "atm": "Retraits",
        "fees": "Frais",
        "finance": "Finance",
        "hotel_and_lodging": "Hôtels & Logement",
        "insurance": "Assurance",
        "legal_and_accounting": "Juridique & Comptabilité",
        "logistics": "Logistique",
        "marketing": "Marketing",
        "office_rental": "Location de bureau",
        "office_supply": "Fournitures de bureau",
        "online_service": "Services en ligne",
        "other": "Autre",
        "pending": "En attente",
        "restaurant_and_bar": "Restaurants & Bars",
        "salary": "Salaires",
        "sales": "Ventes",
        "tax": "Impôts & Taxes",
        "transport": "Transports",
        "utility": "Utilitaire"
      },
      "note_field": {
        "placeholder": "Ajoutez une note",
        "title": "Informations complémentaires"
      },
      "title_field": {
        "placeholder": "Donnez un nom à cette tâche",
        "title": "Nom"
      },
      "type_field": {
        "placeholder": "Sélectionnez une méthode de paiement",
        "title": "Méthode"
      }
    },
    "operation_history": {
      "back_to_credits_cta": "Revenir à Clients",
      "back_to_debits_cta": "Revenir à Fournisseurs",
      "cancel_cta": "Indiquer comme non traité",
      "check_cta": "Indiquer comme traité",
      "ignore_cta": "Ignorer",
      "reactive_cta": "Rétablir",
      "subtitle": {
        "credit": "{count, plural,=1 {{count} opération est associée à ce client} other {{count} opérations sont associées à ce client}}",
        "debit": "{count, plural,=1 {{count} opération est associée à ce fournisseur} other {{count} opérations sont associées à ce fournisseur}}"
      },
      "table_field": {
        "amount": "Montant",
        "date": "Date",
        "label": "Transaction"
      }
    },
    "overview": {
      "cards": {
        "dropdown": {
          "title": "Tous les comptes"
        },
        "last_update": {
          "day": "Mis à jour il y a {count} {count, plural, =1 {jour} other {jours}}",
          "month": "Mis à jour il y a {count} mois",
          "year": "Mis à jour il y a {count} {count, plural, =1 {an} other {ans}}"
        }
      },
      "faq": {
        "first_article": {
          "description": "Découvrez l'agrégation bancaire et apprenez à synchroniser votre ancienne banque avec Qonto.",
          "link": "https://help.qonto.com/fr/articles/5020434",
          "title": "Comment fonctionne le service de transfert de compte ?"
        },
        "second_article": {
          "description": "Retrouvez les astuces et conseils de nos experts pour changer de banque en toute simplicité.",
          "link": "https://help.qonto.com/fr/articles/5145573",
          "title": "Comment suivre l'avancée de mon transfert de compte ?"
        },
        "title": "Tout savoir sur le transfert de compte"
      },
      "popin": {
        "end_migration_completed": {
          "cta": {
            "cancel": "Annuler",
            "end_transfer": "Confirmer"
          },
          "title": "Confirmez que votre transfert de compte est terminé",
          "wording": "Votre transfert de compte de {bank_name} à Qonto pour {company_name} est bientôt terminé. Toutes les données liées à votre ancien compte seront alors supprimées."
        },
        "end_migration_not_completed": {
          "cta": {
            "cancel": "Annuler",
            "end_transfer": "Terminer le transfert"
          },
          "title": "Terminez votre transfert de compte",
          "wording": "Êtes-vous sûr ? Votre transfert de compte de {bank_name} à Qonto pour {company_name} prendra fin et vos données seront supprimées. Vous changez d'avis ? (Re)démarrez un nouveau transfert à tout moment."
        }
      },
      "stepper": {
        "end_migration": "Terminer le transfert",
        "file_link": "Télécharger le guide",
        "step1": {
          "progress_bar": {
            "completed": "Terminé",
            "inprogress": "En cours"
          },
          "subtitle1": {
            "description": "Pour garantir le traitement de vos futurs paiements.",
            "title": "Fournisseurs"
          },
          "subtitle2": {
            "description": "Pour recevoir de l'argent sur votre compte Qonto.",
            "title": "Clients"
          },
          "title": "Partagez vos nouvelles coordonnées bancaires à vos fournisseurs et clients"
        },
        "step2": {
          "subtitle": "Jetez un œil à notre guide pour en savoir plus sur la marche à suivre.",
          "title": "Préparez la fermeture de votre ancien compte"
        },
        "step3": {
          "subtitle": "Confirmez que votre transfert de compte est terminé une fois les étapes précédentes complétées.",
          "title": "Terminer le transfert"
        },
        "step_link": {
          "completed": "Voir les détails",
          "uncompleted": "{count} {count, plural,=1 {action à réaliser} other {actions à réaliser}}"
        },
        "title": "Progression du transfert"
      },
      "subtitle": "Dans ce tableau de bord, vous trouverez toutes les informations relatives au transfert de votre compte.",
      "title": "Suivez l’avancée de votre transfert de compte"
    },
    "side": {
      "credit": "Clients",
      "debit": "Fournisseurs",
      "overview": "Suivi",
      "settings": "Réglages",
      "settings_items": {
        "add_accounts": "Ajouter un autre compte",
        "back_to_app": "Revenir à Qonto",
        "ends_transfer_cta": "Terminer le transfert",
        "support": "Aide",
        "support_link": "https://help.qonto.com/fr",
        "switch_organization": "Changer d'entreprise"
      }
    }
  },
  "dashboard_generation": {
    "waiting_screen": {
      "description": "Notre équipe se charge d'analyser et de lister vos transactions récurrentes. Cela peut prendre de <b>4 à 10 minutes</b> et vous recevrez un e-mail une fois votre tableau de bord prêt. Inutile donc de patienter ici.",
      "disclaimer": {
        "part_1": "Ce service est sécurisé. Toutes les données sont strictement réservées à l'usage de Qonto et peuvent être supprimées sur demande à tout moment.",
        "part_2": "En savoir plus"
      },
      "title": "Votre tableau de bord est en cours de création"
    }
  },
  "dynamic-values": {
    "dates": {
      "current-month": "%month%",
      "current-year": "%year%",
      "last-month": "%lastmonth%",
      "next-month": "%nextmonth%"
    }
  },
  "empty-state-discover": {
    "divider": "ou"
  },
  "ends_transfer": {
    "confirmation_modal": {
      "cancel_cta": "Annuler",
      "confirm_cta": "Terminez le transfert",
      "subtitle": "Êtes-vous sûr ? Votre transfert de compte de {bank_name} à Qonto pour {company_name} prendra fin et vos données seront supprimées. Vous changez d'avis ? (Re)démarrez un nouveau transfert à tout moment.",
      "title": "Terminer"
    }
  },
  "error_1010": {
    "disclaimer": {
      "body": "Vos données ne sont pas à jour: vous devez ressaisir le code de confirmation reçu par SMS",
      "link": "juste ici"
    }
  },
  "error_429": {
    "disclaimer": {
      "body_action1": "Vos données ne sont pas à jour : vous devez accepter les conditions générales sur votre compte {bank_name}.",
      "body_action10": "Vos données ne sont pas à jour : vous devez réactiver l'authentification forte sur votre compte {bank_name}.",
      "body_action11": "Vos données ne sont pas à jour : vous devez obtenir l'accès aux services en ligne en contactant votre banque {bank_name}.",
      "body_action12": "Vos données ne sont pas à jour : vous devez contacter le service client de votre banque {bank_name}.",
      "body_action13": "Vos données ne sont pas à jour : vous devez renouveler ou activer vos identifiants en contactant votre banque {bank_name}.",
      "body_action14": "Vos données ne sont pas à jour : vous devez modifier votre mot de passe sur votre compte {bank_name}.",
      "body_action15": "Vos données ne sont pas à jour : vous devez créer un mot de passe en vous connectant une première fois sur votre compte {bank_name}.",
      "body_action2": "Vos données ne sont pas à jour : vous devez signer un contrat sur votre compte {bank_name}.",
      "body_action3": "Vos données ne sont pas à jour : vous devez activer ou renseigner vos identifiants sur votre compte {bank_name}.",
      "body_action4": "Vos données ne sont pas à jour : vous devez activer votre compte {bank_name}.",
      "body_action5": "Vos données ne sont pas à jour : vous devez renseigner ou confirmer le numéro de téléphone associé à votre compte {bank_name}.",
      "body_action6": "Vos données ne sont pas à jour : vous devez valider des informations ou un message sur votre compte {bank_name}.",
      "body_action7": "Vos données ne sont pas à jour : vous devez confirmer ou compléter des informations personnelles sur votre compte {bank_name}.",
      "body_action8": "Vos données ne sont pas à jour : vous devez confirmer et/ou modifier vos coordonnées sur votre compte {bank_name}.",
      "body_action9": "Vos données ne sont pas à jour : vous devez créer un compte {bank_name}."
    }
  },
  "error_430": {
    "disclaimer": {
      "body": "Vos données ne sont pas à jour : vous devez modifier votre mot de passe sur votre compte {bank_name}."
    }
  },
  "error_management_screen": {
    "generic_fallback_error": {
      "description": "Une erreur nous empêche de créer automatiquement votre tableau de bord. Planifiez un appel avec un conseiller Qonto pour obtenir de l'aide et être accompagné.",
      "primary_cta": "Planifier un appel",
      "title": "Votre tableau de bord n’a pas pu être créé"
    }
  },
  "information_appointment": {
    "calendar_subtitle": "Prenez rendez-vous pour préparer votre transfert de compte avec un conseiller dédié.",
    "calendar_title": "Rencontrez votre conseiller Qonto"
  },
  "labels": {
    "pagination_summary": "sur"
  },
  "logout": {
    "btn": "Retour à l'écran de connexion",
    "modal": {
      "accept_btn": "Continuer sur Qonto",
      "close_btn": "Me déconnecter",
      "content": "Dans 5 minutes, votre session atteindra sa limite de 30 minutes. Afin de garantir la sécurité de votre compte, vous serez automatiquement déconnecté(e). Vous pouvez choisir de prolonger votre session ci-dessous.",
      "title": "Êtes-vous toujours là ?"
    },
    "subtitle": "Pour des raisons de sécurité, vos sessions sur Qonto sont limitées à 30 minutes. Vous avez donc été automatiquement déconnecté.",
    "title": "Vous avez été déconnecté"
  },
  "maintenance": {
    "cta": "Revenir à Qonto",
    "subtitle": "Il semblerait qu'il y ait un problème. Rassurez-vous, notre équipe met tout en œuvre pour le résoudre au plus vite. En attendant, contactez votre conseiller Qonto si vous avez besoin d'aide.",
    "title": "Une erreur s'est produite"
  },
  "migration_status": {
    "appointment_successfully_made_redirection_toast": "Votre rendez-vous est confirmé !",
    "end_migration": "Clôturer le transfert",
    "step_1_subtitle": "Pour générer un plan de transfert de compte sur-mesure.",
    "step_1_title": "Vos transactions sont en cours d'analyse",
    "step_2_subtitle": "Pour discuter de votre plan de transfert de compte personnalisé.",
    "step_2_title": "C’est l’heure d’un premier entretien avec votre conseiller Qonto",
    "step_3_subtitle": "Vous recevrez des mises à jour et des rappels à chaque étape.",
    "step_3_title": "Votre transfert de compte est entre de bonnes mains",
    "step_4_subtitle": "C’est parti ! Pilotez dès maintenant votre compte pro depuis votre application Qonto.",
    "step_4_title": "Votre transfert de compte est terminé !",
    "subtitle": "Votre transfert de compte est en cours. Suivez sa progression ici.",
    "title": "Suivez l'avancée de votre transfert de compte"
  },
  "migration_succeeded": {
    "back_to_qonto": "Accéder à mon compte Qonto",
    "end_migration": {
      "file_link": "Télécharger le guide",
      "title1": "Finalisez la fermeture",
      "title2": "de votre ancien compte",
      "wording": "Vous n'avez pas encore fermé votre ancien compte ? Pensez à jeter un œil à notre guide pour en savoir plus sur la marche à suivre."
    },
    "subtitle": "Vous pouvez maintenant profiter de toutes les fonctionnalités exclusives Qonto. N'hésitez pas à nous contacter en cas de besoin.",
    "switch_another_bank": "Transférer un autre compte chez Qonto",
    "title": "Félicitations, votre transfert de compte est terminé !"
  },
  "no_mobile": {
    "back_to_qonto": "Revenir à Qonto",
    "subtitle": "Ce service est disponible exclusivement sur ordinateur pour l'instant.",
    "title": "Rendez-vous sur l'appli web !"
  },
  "no_rights": {
    "subtitle": "Seul un Admin peut effectuer un transfert de compte.",
    "title": "Vous n'avez malheureusement pas accès à ce service"
  },
  "not_found": {
    "cta": "Revenir à Qonto",
    "subtitle": "Nous avons bien cherché mais cette page reste introuvable. Elle a peut-être été déplacée ou supprimée, ou bien une erreur s'est glissée dans l'URL.",
    "title": "Cette page est introuvable"
  },
  "onboarding_funnel": {
    "OTP_modal": {
      "code": "Code",
      "error_text": "Code invalide",
      "link": "Vous n’avez rien reçu ? Recevoir un nouveau code.",
      "primary_CTA": "Annuler",
      "secondary_CTA": "Valider",
      "text": "Veuillez renseigner le code de vérification temporaire reçu par SMS.",
      "title": "Code de vérification"
    },
    "app_modal": {
      "link": "Besoin d'aide ?",
      "text": "Pour continuer, vous devez confirmer la connexion depuis l’application mobile de votre banque.",
      "title": "Rendez-vous sur votre téléphone"
    },
    "consent": {
      "CTA_1": "Annuler",
      "CTA_2": "Confirmer",
      "checkbox": {
        "first": "En cliquant ici, j’accepte les conditions générales du service et je confirme avoir pris connaissance de la ",
        "link": "politique de protection des données de Qonto.",
        "second": ""
      },
      "text": "Qonto vous propose de connecter l’ensemble de vos comptes bancaires et de paiement ouverts auprès d’établissements tiers à votre compte Qonto.<br>Qonto est agréé par l’ACPR pour vous fournir ce service d'agrégation et d’information sur les comptes et s’appuie sur les infrastructures de Bankin’.<br>L’accessibilité de certains de vos comptes ne peut cependant être garantie.<br><br>Vos données bancaires et vos données personnelles sont protégées conformément à la réglementation européenne (Directive sur les services de paiement et Règlement général sur la protection des données personnelles).",
      "title": "Qonto respecte vos données"
    },
    "credentials": {
      "4digitcode_title": "Code à 4 chiffres",
      "CTA": "Se connecter",
      "accesscode_title": "Code d'accès",
      "accountnumber_placeholder": "Saisissez votre numéro de compte",
      "accountnumber_title": "Numéro de compte",
      "answer_placeholder": "Saisissez votre réponse",
      "answer_title": "Réponse à la question de sécurité",
      "birthdate_placeholder": "JJ/MM/AAAA",
      "birthdate_title": "Date de naissance",
      "checkbox": "Je certifie sur l'honneur être en droit et disposer des autorisations nécessaires pour accéder aux informations du compte.",
      "clientnumber_placeholder": "Saisissez votre numéro client",
      "clientnumber_title": "Numéro client",
      "code_placeholder": "Saisissez votre code utilisateur",
      "code_title": "Code utilisateur",
      "companynumber_placeholder": "Saisissez votre numéro d'identifiant entreprise",
      "companynumber_title": "Numéro d'identifiant entreprise",
      "confidentialcode_title": "Code confidentiel",
      "email_placeholder": "jeanne.durant@monentreprise.com",
      "email_title": "Adresse e-mail",
      "id_placeholder": "Saisissez votre identifiant",
      "id_title": "Identifiant",
      "password_placeholder": "Saisissez votre mot de passe",
      "password_title": "Mot de passe",
      "phonenumber_placeholder": "+33 12 345 6789",
      "phonenumber_title": "Numéro de téléphone avec indicatif (+33...)",
      "servercode_title": "Code serveur",
      "subscriptionnumber_placeholder": "Saisissez votre numéro d'abonné",
      "subscriptionnumber_title": "Numéro d'abonné",
      "text": "Utilisez les mêmes identifiants que lors de votre connexion habituelle sur {bank_name}.<br/>Rassurez-vous, Qonto ne sauvegarde aucune de ces informations.",
      "title": "Connectez-vous à {bank_name}",
      "usernumber_placeholder": "Saisissez votre numéro d'usager",
      "usernumber_title": "Numéro d'usager"
    },
    "error_1005": {
      "primary_CTA": "Planifier un appel",
      "secondary_CTA": "Revenir à Qonto",
      "text": "Une erreur nous empêche de nous connecter à votre banque. Rassurez-vous, un agent Qonto est là pour résoudre le problème et vous accompagner tout au long du transfert.",
      "title": "La connexion avec {bank_name} a échoué"
    },
    "error_402": {
      "link": "choisi la bonne entité bancaire ?",
      "text": "Il semblerait que la connexion ait échoué. Pouvez-vous vérifier vos identifiants et vous assurer d’avoir "
    },
    "success": {
      "CTA": "Transférer vos comptes",
      "text": "C’est presque fini, il ne vous reste plus qu’à sélectionner les comptes que vous souhaitez transférer.",
      "title": "La récupération a bien été effectuée"
    },
    "synchronization": {
      "subtitle": "Notre équipe récupère les informations de votre compte {bank_name}. Cela peut prendre quelques minutes, veillez à rester sur cette page.",
      "text": "Récupération des informations de votre compte {bank_name}...",
      "title": "Un instant, nous consultons votre banque"
    }
  },
  "optional_cs": {
    "select_method": {
      "main_page": {
        "algorithm_bloc": {
          "argument1": "Déléguez la création de votre tableau de bord à notre équipe",
          "argument2": "Recevez une notification dès que votre tableau de bord est prêt",
          "argument3": "Accédez à votre tableau de bord et laissez-vous guider",
          "description": "Gagnez en temps et en autonomie",
          "primary_cta": "Choisir",
          "tag_recommended": "Recommandé",
          "title": {
            "1": "Un tableau de bord",
            "2": "créé automatiquement"
          }
        },
        "customer_service_bloc": {
          "argument1": "Planifiez un appel quand vous le souhaitez",
          "argument2": "Créez un tableau de bord avec l'aide de votre conseiller",
          "argument3": "Accédez à votre tableau de bord et laissez-vous guider",
          "description": "Soyez accompagné à chaque étape",
          "primary_cta": "Choisir",
          "title": {
            "1": "Un appel avec un",
            "2": "conseiller Qonto dédié"
          }
        },
        "title": "Choisissez le type d'accompagnement qu'il vous faut"
      }
    }
  },
  "pdf-preview": {
    "items-links": "{number, plural, =0 {} one {{language, select, fr {Lien :} de {Link:} es {Enlace:} it {Link:} other {Link:}}} other {{language, select, fr {Liens :} de {Links:} es {Enlaces:} it {Link:} other {Links:}}}}",
    "unit": {
      "ampere": "{number, plural, one {{language, select, fr {ampère} de {Ampere} es {amperio} it {ampere} other {ampere}}} other {{language, select, fr {ampères} de {Ampere} es {amperios} it {ampere} other {amperes}}}}",
      "centimeter": "{number, plural, one {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}} other {{language, select, fr {cm} de {cm} es {cm} it {cm} other {cm}}}}",
      "cubic_meter": "{number, plural, one {{language, select, pt {m³} other {m³}}} other {{language, select, pt {m³} other {m³}}}}",
      "cubic_meter_per_hour": "{number, plural, one {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}} other {{language, select, fr {m³/h} de {m³/h} es {m³/h} it {m³/h} other {m³/h}}}}",
      "day": "{number, plural, one {{language, select, fr {jour} de {Tag} es {día} it {giornata} other {dia}}} other {{language, select, fr {jours} de {Tage} es {días} it {giornate}  other {dias}}}}",
      "gigajoule": "{number, plural, one {{language, select, fr {gigajoule} de {Gigajoule} es {gigajulio} it {gigajoule} other {gigajoule}}} other {{language, select, fr {gigajoules} de {Gigajoule} es {gigajulios} it {gigajoule} other {gigajoules}}}}",
      "gigawatt": "{number, plural, one {{language, select, fr {gigawatt} de {Gigawatt} es {gigavatio} it {gigawatt} pt {gigawatt} other {gigawatt}}} other {{language, select, fr {gigawatts} de {Gigawatt} es {gigavatios} it {gigawatt} pt {gigawatts} other {gigawatts}}}}",
      "gigawatt_hour": "{number, plural, one {{language, select, pt {gigawatt hora} other {gigawatt hour}}} other {{language, select, pt {gigawatt horas} other {gigawatt hours}}}}",
      "gram": "{number, plural, one {{language, select, fr {g} de {g} es {g} it {g} other {g}}} other {{language, select, fr {g} de {g} es {g} it {g} other {g}}}}",
      "half_year": "{number, plural, one {{language, select, fr {semestre} de {meio ano} es {semestre} it {semestre} other {meio ano}}} other {{language, select, fr {semestres} de {meios anos} es {semestres} it {semestri} other {meios anos}}}}",
      "hour": "{number, plural, one {{language, select, fr {heure} de {Stunde} es {hora} it {ora} other {hora}}} other {{language, select, fr {heures} de {Stunden} es {horas} it {ore} other {horas}}}}",
      "joule": "{number, plural, one {{language, select, fr {joules} de {Joule} es {julio} it {joule} other {joule}}} other {{language, select, fr {joules} de {Joule} es {julios} it {joule} other {joules}}}}",
      "kilogram": "{number, plural, one {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}} other {{language, select, fr {kg} de {kg} es {kg} it {kg} other {kg}}}}",
      "kilojoule": "{number, plural, one {{language, select, fr {kilojoule} de {Kilojoule} es {kilojulio} it {kilojoule} other {kilojoule}}} other {{language, select, fr {kilojoules} de {Kilojoule} es {kilojulios} it {kilojoule} other {kilojoules}}}}",
      "kilovar": "{number, plural, one {{language, select, fr {kilovar} de {Kilovar} es {kilovar} it {kilovar} other {kilovar}}} other {{language, select, fr {kilovars} de {Kilovar} es {kilovares} it {kilovar} other {kilovars}}}}",
      "kilovolt_ampere_reactive_hour": "{number, plural, one {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}} other {{language, select, fr {kVArh} de {kvarh} es {kVArh} it {kVArh} other {kVArh}}}}",
      "kilowatt": "{number, plural, one {{language, select, fr {quilowatt} de {Kilowatt} es {quilovatio} it {chilowatt} other {quilowatt}}} other {{language, select, fr {quilowatts} de {Kilowatt} es {quilovatios} it {chilowatt} other {quilowatts}}}}",
      "kilowatt_hour": "{number, plural, one {{language, select, fr {quilowatt-hora} de {quilowatt-hora} es {quilowatt-hora} it {quilowatt-hora} other {quilowatt-hora}}} other {{language, select, fr {quilowatts-hora} de {quilowatts-hora} es {quilowatts-hora} it {quilowatts-hora} other {quilowatts-hora}}}}",
      "liter": "{number, plural, one {{language, select, fr {L} de {l} es {l} it {L} other {L}}} other {{language, select, fr {para completar} de {l} es {l} it {L} other {L}}}}",
      "megajoule": "{number, plural, one {{language, select, fr {mégajoule} de {Megajoule} es {megajulio} it {megajoule} other {megajoule}}} other {{language, select, fr {mégajoules} de {Megajoule} es {megajulios} it {megajoule} other {megajoule}}}}",
      "megawatt": "{number, plural, one {{language, select, fr {mégawatt} de {Megawatt} es {megavatio} it {megawatt} other {megawatt}}} other {{language, select, fr {mégawatts} de {Megawatt} es {megavatios} it {megawatt} other {megawatts}}}}",
      "megawatt_hour": "{number, plural, one {{language, select, fr {mégawattheure} de {Megawattstunde} es {megavatio-hora} it {megawattora} other {megawatt hora}}} other {{language, select, fr {mégawattheure} de {Megawattstunde} es {megavatio-hora} it {megawattora} other {megawatts-hora}}}}",
      "meter": "{number, plural, one {{language, select, fr {m} de {m} es {m} it {m} other {m}}} other {{language, select, fr {m} de {m} es {m} it {m} other {m}}}}",
      "milligram": "{number, plural, one {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}} other {{language, select, fr {mg} de {mg} es {mg} it {mg} other {mg}}}}",
      "milliliter": "{number, plural, one {{language, select, fr {mL} de {ml} es {ml} it {ml} other {mL}}} other {{language, select, fr {mL} de {ml} es {ml} it {ml} other {mL}}}}",
      "millimeter": "{number, plural, one {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}} other {{language, select, fr {mm} de {mm} es {mm} it {mm} other {mm}}}}",
      "minute": "{number, plural, one {{language, select, fr {minuto} de {Minute} es {minuto} it {minuto} other {minuto}}} other {{language, select, fr {minutos} de {Minuten} es {minutos} it {minuti} other {minutos}}}}",
      "month": "{number, plural, one {{language, select, fr {mês} de {Monat} es {mes} it {mese} other {mês}}} other {{language, select, fr {meses} de {Monate} es {meses} it {mesi} other {meses}}}}",
      "number_of_articles": "{number, plural, one {{language, select, fr {article} de {Artikel} es {artículo} it {pezzo} other {artigo}}} other {{language, select, fr {articles} de {Artikel} es {artículos} it {pezzi} other {artigos}}}}",
      "pair": "{number, plural, one {{language, select, fr {par} de {Paar} es {par} it {paio} other {par}}} other {{language, select, fr {pares} de {Paare} es {pares} it {paia} other {pares}}}}",
      "quarter": "{number, plural, one {{language, select, fr {trimestre} de {trimestre} es {trimestre} it {trimestre} other {trimestre}}} other {{language, select, fr {trimestres} de {trimestres} es {trimestres} it {trimestri} other {trimestres}}}}",
      "second": "{number, plural, one {{language, select, fr {segundo} de {Sekunde} es {segundo} it {segundo} other {segundo}}} other {{language, select, fr {segundos} de {Sekunden} es {segundos} it {secondi} other {segundos}}}}",
      "set": "{number, plural, one {{language, select, fr {conjunto} de {conjunto} es {juego} it {assortimento} other {conjunto}}} other {{language, select, fr {conjuntos} de {conjuntos} es {juegos} it {assortimenti} other {conjuntos}}}}",
      "square_meter": "{number, plural, one {{language, select, pt {m²} other {m²}}} other {{language, select, pt {m²} other {m²}}}}",
      "ton": "{number, plural, one {{language, select, fr {t} de {t} es {t} it {t} other {T}}} other {{language, select, fr {t} de {t} es {t} it {t} other {T}}}}",
      "two_week": "{number, plural, one {{language, select, fr {duas semanas} de {zwei Wochen} es {dos semanas} it {due settimane} other {duas semanas}}} other {{language, select, fr {duas semanas} de {zwei Wochen} es {dos semanas} it {due settimane} other {duas semanas}}}}",
      "unit": "{number, plural, one {{language, select, fr {unité} de {Einheit} es {unidad} it {unità} other {unidade}}} other {{language, select, fr {unités} de {Einheiten} es {unidades} it {unità} other {unidades}}}}",
      "watt_hour": "{number, plural, one {{language, select, fr {wattheure} de {Wattstunde} es {vatio-hora} it {wattora} other {watt hora}}} other {{language, select, fr {wattheures} de {Wattstunden} es {vatios-hora} it {wattora} other {watt horas}}}}",
      "week": "{number, plural, one {{language, select, fr {semana} de {semana} es {semana} it {settimana} other {semana}}} other {{language, select, fr {semanas} de {semanas} es {semanas} it {settimane} other {semanas}}}}",
      "year": "{number, plural, one {{language, select, fr {année} de {Jahr} es {año} it {anno} other {ano}}} other {{language, select, fr {années} de {Jahre} es {años} it {anni} other {anos}}}}"
    }
  },
  "product_tour": {
    "banner": {
      "CTA_primary": "Connecter votre banque",
      "CTA_secondary": "Rejouer la démo"
    },
    "clients": {
      "action1": {
        "title": "Agence Marketing",
        "transactions": "Paiement Agence Marketing"
      },
      "action2": {
        "title": "Boulangerie",
        "transactions": "Débit carte Boulangerie"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "paiement Qonto Airlines"
      },
      "action4": {
        "title": "Médecine du Travail",
        "transactions": "Paiement carte Médecine du Travail"
      },
      "action5": {
        "title": "Fournitures",
        "transactions": "Paiement par carte Fournitures"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Paiement Qonto Technology"
      },
      "action7": {
        "title": "Gaz & Électricité",
        "transactions": "Paiment mensuel Gaz & Électricité"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "abonnement Qonto Telecom"
      }
    },
    "floating_banner": {
      "text": "Prêt à transférer votre compte chez Qonto ?"
    },
    "overview": {
      "card1": {
        "IBAN": "FR00 1111 2222 3333 4444 5555 000",
        "title": "Ancien compte"
      },
      "card2": {
        "IBAN": "FR00 6666 7777 8888 9999 1111 000",
        "title": "Compte principal Qonto"
      },
      "title": "Suivi (démo)"
    },
    "suppliers": {
      "action1": {
        "title": "Agence Marketing",
        "transactions": "Paiement Agence Marketing"
      },
      "action2": {
        "title": "Boulangerie",
        "transactions": "Débit carte Boulangerie"
      },
      "action3": {
        "title": "Qonto Airlines",
        "transactions": "paiement Qonto Airlines"
      },
      "action4": {
        "title": "Médecine du Travail",
        "transactions": "Paiement carte Médecine du Travail"
      },
      "action5": {
        "title": "Fournitures",
        "transactions": "Paiement par carte Fournitures"
      },
      "action6": {
        "title": "Qonto Technology",
        "transactions": "Paiement Qonto Technology"
      },
      "action7": {
        "title": "Gaz & Électricité",
        "transactions": "Paiment mensuel Gaz & Électricité"
      },
      "action8": {
        "title": "Qonto Telecom",
        "transactions": "abonnement Qonto Telecom"
      }
    }
  },
  "redirect": {
    "continue_button": "Continuer",
    "error_message": "Pour continuer, vous devez sélectionner au moins un compte.",
    "subtitle": "L'historique des transactions liées à ce compte sera transmis à votre conseiller Qonto pour analyse.",
    "title": "Sélectionnez les comptes que vous souhaitez transférer"
  },
  "synchronization": {
    "error_page_1003": {
      "CTA": "Revenir à Qonto",
      "body": "Une erreur nous empêche de nous connecter à votre banque. Rassurez-vous, notre équipe met tout en œuvre pour résoudre le problème au plus vite. Vous recevrez un e-mail dès que tout sera rentré dans l'ordre.",
      "title": "La connexion à votre banque a échoué"
    },
    "error_page_402": {
      "CTA_1": "Sélectionner une autre banque",
      "CTA_2": "Besoin d'aide ?",
      "body": "Il semblerait que vous ayez quelques difficultés à connecter votre banque à Qonto. Certaines banques ont plusieurs entités donc nous vous conseillons de vérifier que vous avez bien sélectionné celle qui vous correspond avant de réessayer de vous connecter à Qonto.",
      "title": "Êtes-vous sûr d'avoir sélectionné la bonne banque ?"
    },
    "error_page_429": {
      "CTA_1": "Continuer",
      "CTA_2": "Revenir à Qonto",
      "body_action1": "Pour continuer, vous devez accepter les conditions générales sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action10": "Pour continuer, vous devez réactiver l'authentification forte sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action11": "Pour continuer, vous devez obtenir l'accès aux services en ligne en contactant votre banque {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action12": "Pour continuer, vous devez contacter le service client de votre banque {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action13": "Pour continuer, vous devez renouveler ou activer vos identifiants en contactant votre banque {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action14": "Pour continuer, vous devez modifier votre mot de passe sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action15": "Pour continuer, vous devez créer un mot de passe en vous connectant une première fois sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action2": "Pour continuer, vous devez signer un contrat sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action3": "Pour continuer, vous devez activer ou renseigner vos identifiants sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action4": "Pour continuer, vous devez activer votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action5": "Pour continuer, vous devez renseigner ou confirmer le numéro de téléphone associé à votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action6": "Pour continuer, vous devez valider des informations ou un message sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action7": "Pour continuer, vous devez confirmer ou compléter des informations personnelles sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action8": "Pour continuer, vous devez confirmer et/ou modifier vos coordonnées sur votre compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "body_action9": "Pour continuer, vous devez créer un compte {bank_name}. Reprenez ensuite votre transfert de compte depuis votre application Qonto.",
      "checkbox": "En cliquant ici, je confirme avoir fait le nécessaire auprès de ma banque.",
      "title": "Rendez-vous sur l'espace client de votre banque",
      "toaster": "Il semblerait qu'il y reste une action requise par votre banque à compléter. Pouvez-vous vérifier et réessayer ?"
    }
  },
  "toast": {
    "default_error_message": "Une erreur s'est produite. Pouvez-vous réessayer ?",
    "not_available_for_country": "Malheureusement, le transfert de compte n'est pas (encore) disponible dans votre pays. Restez connecté !"
  },
  "ui-kit": {
    "avatar-input": {
      "default-image-alt-text": "Imagem padrão",
      "uploaded-image-alt-text": "Última imagem que você enviou"
    },
    "btn": {
      "aria-label": {
        "clear": "Limpar",
        "download": "Baixar",
        "next-month": "Próximo mês",
        "preview": "Mostrar",
        "previous-month": "Mês anterior"
      },
      "cancel": "Cancelar",
      "close": "Fechar",
      "delete": "Excluir",
      "delete-aria-label": "Excluir"
    },
    "countries": {
      "AC": "Ilha da Ascensão",
      "AD": "Andorra",
      "AE": "Emirados Árabes Unidos",
      "AF": "Afeganistão",
      "AG": "Antígua & Barbuda",
      "AI": "Anguilla",
      "AL": "Albânia",
      "AM": "Armênia",
      "AN": "Antilhas Holandesas",
      "AO": "Angola",
      "AQ": "Antártica",
      "AR": "Argentina",
      "AS": "Samoa Americana",
      "AT": "Áustria",
      "AU": "Austrália",
      "AW": "Aruba",
      "AX": "Ilhas Åland",
      "AZ": "Azerbaijão",
      "BA": "Bósnia & Herzegovina",
      "BB": "Barbados",
      "BD": "Bangladesh",
      "BE": "Bélgica",
      "BF": "Burkina Faso",
      "BG": "Bulgária",
      "BH": "Bahrein",
      "BI": "Burundi",
      "BJ": "Benin",
      "BL": "São Bartolomeu",
      "BM": "Bermudas",
      "BN": "Brunei",
      "BO": "Bolívia",
      "BQ": "Antilhas Holandesas do Caribe",
      "BR": "Brasil",
      "BS": "Bahamas",
      "BT": "Butão",
      "BV": "Ilha Bouvet",
      "BW": "Botsuana",
      "BY": "Bielorrússia",
      "BZ": "Belize",
      "CA": "Canadá",
      "CC": "Ilhas Cocos (Keeling)",
      "CD": "Congo - Kinshasa",
      "CF": "República Centro-Africana",
      "CG": "Congo - Brazzaville",
      "CH": "Suíça",
      "CI": "Côte d’Ivoire",
      "CK": "Ilhas Cook",
      "CL": "Chile",
      "CM": "Camarões",
      "CN": "China",
      "CO": "Colômbia",
      "CP": "Ilha Clipperton",
      "CR": "Costa Rica",
      "CU": "Cuba",
      "CV": "Cabo Verde",
      "CW": "Curaçao",
      "CX": "Ilha do Natal",
      "CY": "Chipre",
      "CZ": "República Tcheca",
      "DE": "Alemanha",
      "DG": "Diego Garcia",
      "DJ": "Djibouti",
      "DK": "Dinamarca",
      "DM": "Dominica",
      "DO": "República Dominicana",
      "DZ": "Argélia",
      "EA": "Ceuta & Melilla",
      "EC": "Equador",
      "EE": "Estônia",
      "EG": "Egito",
      "EH": "Saara Ocidental",
      "ER": "Eritreia",
      "ES": "Espanha",
      "ET": "Etiópia",
      "EU": "União Europeia",
      "FI": "Finlândia",
      "FJ": "Fiji",
      "FK": "Ilhas Falkland",
      "FM": "Micronésia",
      "FO": "Ilhas Faroé",
      "FR": "França",
      "GA": "Gabão",
      "GB": "Reino Unido",
      "GD": "Granada",
      "GE": "Geórgia",
      "GF": "Guiana Francesa",
      "GG": "Guernsey",
      "GH": "Gana",
      "GI": "Gibraltar",
      "GL": "Groenlândia",
      "GM": "Gâmbia",
      "GN": "Guiné",
      "GP": "Guadalupe",
      "GQ": "Guiné Equatorial",
      "GR": "Grécia",
      "GS": "Geórgia do Sul e Ilhas Sandwich do Sul",
      "GT": "Guatemala",
      "GU": "Guam",
      "GW": "Guiné-Bissau",
      "GY": "Guiana",
      "HK": "Região Administrativa Especial de Hong Kong da China",
      "HM": "Ilhas Heard e McDonald",
      "HN": "Honduras",
      "HR": "Croácia",
      "HT": "Haiti",
      "HU": "Hungria",
      "IC": "Ilhas Canárias",
      "ID": "Indonésia",
      "IE": "Irlanda",
      "IL": "Israel",
      "IM": "Ilha de Man",
      "IN": "Índia",
      "IO": "Território Britânico do Oceano Índico",
      "IQ": "Iraque",
      "IR": "Irã",
      "IS": "Islândia",
      "IT": "Itália",
      "JE": "Jersey",
      "JM": "Jamaica",
      "JO": "Jordan",
      "JP": "Japão",
      "KE": "Quênia",
      "KG": "Quirguistão",
      "KH": "Camboja",
      "KI": "Kiribati",
      "KM": "Comores",
      "KN": "St. Kitts & Nevis",
      "KP": "Coreia do Norte",
      "KR": "Coreia do Sul",
      "KW": "Kuwait",
      "KY": "Ilhas Cayman",
      "KZ": "Cazaquistão",
      "LA": "Laos",
      "LB": "Líbano",
      "LC": "Santa Lúcia",
      "LI": "Liechtenstein",
      "LK": "Sri Lanka",
      "LR": "Libéria",
      "LS": "Lesoto",
      "LT": "Lituânia",
      "LU": "Luxemburgo",
      "LV": "Letônia",
      "LY": "Líbia",
      "MA": "Marrocos",
      "MC": "Mônaco",
      "MD": "Moldávia",
      "ME": "Montenegro",
      "MF": "São Martinho",
      "MG": "Madagascar",
      "MH": "Ilhas Marshall",
      "MK": "Macedônia",
      "ML": "Mali",
      "MM": "Mianmar (Birmânia)",
      "MN": "Mongólia",
      "MO": "Macau RAE China",
      "MP": "Ilhas Marianas do Norte",
      "MQ": "Martinica",
      "MR": "Mauritânia",
      "MS": "Montserrat",
      "MT": "Malta",
      "MU": "Maurício",
      "MV": "Maldivas",
      "MW": "Malawi",
      "MX": "México",
      "MY": "Malásia",
      "MZ": "Moçambique",
      "NA": "Namíbia",
      "NC": "Nova Caledônia",
      "NE": "Níger",
      "NF": "Ilha Norfolk",
      "NG": "Nigéria",
      "NI": "Nicarágua",
      "NL": "Países Baixos",
      "NO": "Noruega",
      "NP": "Nepal",
      "NR": "Nauru",
      "NU": "Niue",
      "NZ": "Nova Zelândia",
      "OM": "Omã",
      "PA": "Panamá",
      "PE": "Peru",
      "PF": "Polinésia Francesa",
      "PG": "Papua Nova Guiné",
      "PH": "Filipinas",
      "PK": "Paquistão",
      "PL": "Polônia",
      "PM": "St. Pierre & Miquelon",
      "PN": "Ilhas Pitcairn",
      "PR": "Porto Rico",
      "PS": "Territórios Palestinos",
      "PT": "Portugal",
      "PW": "Palau",
      "PY": "Paraguai",
      "QA": "Catar",
      "QO": "Oceania Remota",
      "RE": "Réunion",
      "RO": "Romênia",
      "RS": "Sérvia",
      "RU": "Rússia",
      "RW": "Ruanda",
      "SA": "Arábia Saudita",
      "SB": "Ilhas Salomão",
      "SC": "Seicheles",
      "SD": "Sudão",
      "SE": "Suécia",
      "SG": "Cingapura",
      "SH": "St. Helena",
      "SI": "Eslovênia",
      "SJ": "Svalbard & Jan Mayen",
      "SK": "Eslováquia",
      "SL": "Serra Leoa",
      "SM": "San Marino",
      "SN": "Senegal",
      "SO": "Somália",
      "SR": "Suriname",
      "SS": "Sudão do Sul",
      "ST": "São Tomé & Príncipe",
      "SV": "El Salvador",
      "SX": "São Martinho",
      "SY": "Síria",
      "SZ": "Essuatíni",
      "TA": "Tristan da Cunha",
      "TC": "Ilhas Turks & Caicos",
      "TD": "Chade",
      "TF": "Territórios Franceses do Sul",
      "TG": "Togo",
      "TH": "Tailândia",
      "TJ": "Tajiquistão",
      "TK": "Tokelau",
      "TL": "Timor-Leste",
      "TM": "Turcomenistão",
      "TN": "Tunísia",
      "TO": "Tonga",
      "TR": "Turquia",
      "TT": "Trinidad & Tobago",
      "TV": "Tuvalu",
      "TW": "Taiwan",
      "TZ": "Tanzânia",
      "UA": "Ucrânia",
      "UG": "Uganda",
      "UM": "Ilhas Menores Distantes dos EUA",
      "US": "Estados Unidos",
      "UY": "Uruguai",
      "UZ": "Uzbequistão",
      "VA": "Cidade do Vaticano",
      "VC": "São Vicente & Granadinas",
      "VE": "Venezuela",
      "VG": "Ilhas Virgens Britânicas",
      "VI": "Ilhas Virgens Americanas",
      "VN": "Vietnã",
      "VU": "Vanuatu",
      "WF": "Wallis & Futuna",
      "WS": "Samoa",
      "XK": "Kosovo",
      "YE": "Iêmen",
      "YT": "Mayotte",
      "ZA": "África do Sul",
      "ZM": "Zâmbia",
      "ZW": "Zimbábue",
      "ZZ": "Região desconhecida"
    },
    "country-select": {
      "label": "País",
      "no_matches_message": "Nenhum resultado encontrado",
      "placeholder": "País",
      "search_placeholder": "Digite para pesquisar"
    },
    "disclaimer": {
      "alt-values": {
        "error": "Mensagem de erro",
        "info": "Mensagem informativa",
        "warning": "Mensagem de aviso"
      }
    },
    "file-status": {
      "certified": "Certificado",
      "not-certified": "Não certificado"
    },
    "form-elements": {
      "optional": "(opcional)"
    },
    "form-fields": {
      "date-field": {
        "error": {
          "invalid": "Esta data não é válida. Pode tentar novamente, no formato DD-MM-AAAA?"
        },
        "placeholder": "DD-MM-AAAA"
      },
      "date-picker": {
        "clear": {
          "cta": "Limpar"
        }
      }
    },
    "fullscreen": {
      "preview": {
        "delete-file": {
          "button-cancel": "Cancelar",
          "button-confirm": "Excluir",
          "description": "Lembre-se de que excluir este arquivo é permanente.",
          "title": "Excluir este arquivo?"
        }
      }
    },
    "gender-selector": {
      "female": "Feminino",
      "label": "Sexo no documento de identificação",
      "male": "Masculino",
      "placeholder": "Gênero"
    },
    "icons": {
      "alt-values": {
        "error": "Mensagem de erro",
        "info": "Mensagem informativa",
        "warning": "Mensagem de aviso"
      }
    },
    "kilobyte": "KB",
    "labels": {
      "choose_all": "Selecionar tudo",
      "clear_filters": "Desmarcar tudo"
    },
    "megabyte": "MB",
    "password_creator": {
      "label": "Palavra-passe",
      "placeholder": "Insira a sua palavra-passe aqui",
      "set_pwd_rules": "Certifique-se de que a sua palavra-passe tem pelo menos 9 caracteres. Evite a sua data de nascimento, o nome do seu animal de estimação ou até mesmo \"0000\", por exemplo.",
      "strength_password_strong": "Ótimo! Sua palavra-passe é < segura o suficiente. >",
      "strength_password_weak": "A sua palavra-passe ainda não é suficientemente segura."
    },
    "pdf-preview": {
      "amount-discount": "{language, select, fr {Remise} de {Rabatt} es {Descuento} it {Sconto} other {Desconto}}",
      "beneficiary-name": "{language, select, fr {Nome do beneficiário} it {Nome e sobrenome do beneficiário} de {Nome do/a beneficiário/a} es {Nome do beneficiário} other {Nome do beneficiário}}",
      "bic": "BIC",
      "client-steuer-id": "{language, select, fr {Número fiscal do cliente} de {ID fiscal do cliente} es {Código fiscal do cliente} it {NIF do cliente} other {ID fiscal do cliente}}",
      "client-ust-udnr": "{language, select, fr {Número de IVA do cliente} de {Número de IVA do cliente} es {Número de IVA do cliente} it {Número de IVA do cliente} other {Número de IVA do cliente}}",
      "credit-note": {
        "credit-note-issue-date": "{language, select, fr {Data de emissão} it {Data de emissão} de {Data de emissão} es {Data de emissão} other {Data de emissão}}",
        "credit-note-number": "{language, select, fr {Numéro de l'avoir} it {Numero nota di credito} de {Stornorechnungsnummer} es {Número de factura rectificativa} other {Número da nota de crédito}}",
        "invoice-issue-date": "{language, select, fr {Data de emissão} it {Data de emissão} de {Data de emissão} es {Data de emissão} other {Data de emissão}}",
        "reason-credit-note": "{language, select, fr {Motif de l'avoir :} it {Motivo emissione nota di credito:} de {Grund für die Stornorechnung:} es {Motivo de la factura rectificativa:} other {Motivo para a nota de crédito:}}",
        "related-invoice-number": "{language, select, fr {Numéro de facture associée} it {Numero fattura correlata} de {Zugehörige Rechnungsnummer} es {Número de factura asociada} other {Número de fatura relacionado}}",
        "title": "{language, select, fr {Avoir} it {Nota di credito} de {Stornorechnung} es {Factura rectificativa} other {Nota de crédito}}"
      },
      "delivery-address": "{language, select, fr {Adresse de livraison} de {Lieferadresse} es {Dirección de envío} it {Indirizzo di consegna} other {Endereço de entrega}}",
      "district-court": "{language, select, fr {Greffe du tribunal d'arrondissement :} de {Registergericht} es {Juzgado de registro de distrito:} it {Registro del tribunale distrettuale:} other {Registro do tribunal distrital:}}",
      "draft": {
        "invoice-number-draft-value": "--",
        "proforma-disclaimer": "{language, select, it {Il presente documento non costituisce fattura valida ai fini del DPR 633 del 26/10/1972 e successive modifiche. La fattura definitiva verrà emessa all’atto del pagamento del corrispettivo (articolo 6, comma 3, DPR 633/72).} other {Este documento não constitui uma fatura válida conforme descrito no Decreto do Presidente da República (DPR) nº 633 de 26 de outubro de 1972 e alterações subsequentes. A fatura final será emitida após o pagamento da contraprestação (Artigo 6, parágrafo 3, do DPR 633/72).}}",
        "title": "{language, select, fr {Fatura proforma} de {Proformarechnung} es {Fatura proforma} it {Fatura proforma} other {Fatura proforma}}"
      },
      "due-date": "{language, select, fr {Date d'échéance} it {Data di scadenza} de {Fälligkeitsdatum} es {Fecha de vencimiento} other {Data de vencimento}}",
      "exemption": {
        "AT": {
          "S11.1a": "{language, select, fr {TVA non applicable, article 11 de l'UStG} de {MwSt. entfällt, Art. 11 des UStG} es {IVA no aplicable, art. 11 del UStG} it {IVA non applicabile, art. 11 del UStG} other {VAT not applicable, art. 11 of the UStG}}",
          "S6.1.13": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.16": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.19": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}",
          "S6.1.27": "{language, select, fr {TVA non applicable, article 6 de l'UStG} de {MwSt. entfällt, Art. 6 des UStG} es {IVA no aplicable, art. 6 del UStG} it {IVA non applicabile, art. 6 del UStG} other {VAT not applicable, art. 6 of the UStG}}"
        },
        "BE": {
          "S21": "{language, select, fr {TVA non applicable, article 21 du BTW-Wetboek} de {MwSt. entfällt, Art. 21 des BTW-Wetboek} es {IVA no aplicable, art. 21 del BTW-Wetboek} it {IVA non applicabile, art. 21 del BTW-Wetboek} other {VAT not applicable, art. 21 of the BTW-Wetboek}}",
          "S39": "{language, select, fr {TVA non applicable, article 39 du BTW-Wetboek} de {MwSt. entfällt, Art. 39 des BTW-Wetboek} es {IVA no aplicable, art. 39 del BTW-Wetboek} it {IVA non applicabile, art. 39 del BTW-Wetboek} other {VAT not applicable, art. 39 of the BTW-Wetboek}}",
          "S39bis": "{language, select, fr {TVA non applicable, article 39bis du BTW-Wetboek} de {MwSt. entfällt, Art. 39bis des BTW-Wetboek} es {IVA no aplicable, art. 39bis del BTW-Wetboek} it {IVA non applicabile, art. 39bis del BTW-Wetboek} other {VAT not applicable, art. 39bis of the BTW-Wetboek}}",
          "S44.1": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.11": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.2": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S44.3.4": "{language, select, fr {TVA non applicable, article 44 du BTW-Wetboek} de {MwSt. entfällt, Art. 44 des BTW-Wetboek} es {IVA no aplicable, art. 44 del BTW-Wetboek} it {IVA non applicabile, art. 44 del BTW-Wetboek} other {VAT not applicable, art. 44 of the BTW-Wetboek}}",
          "S56bis": "{language, select, fr {TVA non applicable, article 56bis du BTW-Wetboek} de {MwSt. entfällt, Art. 56bis des BTW-Wetboek} es {IVA no aplicable, art. 56bis del BTW-Wetboek} it {IVA non applicabile, art. 56bis del BTW-Wetboek} other {VAT not applicable, art. 56bis of the BTW-Wetboek}}"
        },
        "DE": {
          "S13B": "{language, select, fr {Steuerschuldnerschaft des Leistungsempfängers (obligation fiscale du bénéficiaire) (secção 13b)} de {Steuerschuldnerschaft des Leistungsempfängers (Abschnitt 13b)} es {Steuerschuldnerschaft des Leistungsempfängers (responsabilidad fiscal del beneficiario) (Sección 13b)} it {Steuerschuldnerschaft des Leistungsempfängers (responsabilità fiscale del beneficiario) (Sezione 13b)} other {Steuerschuldnerschaft des Leistungsempfängers (Responsabilidade fiscal do beneficiário) (Secção 13b)}}",
          "S19.1": "{language, select, fr {Conformément à la secção 19, paragraphe 1 de l'UStG, le montant de la facture n'inclut pas de taxe de vente} de {Gemäß Abschnitt 19 Abs. 1 UStG ist im Rechnungsbetrag keine Umsatzsteuer enthalten} es {Según el artículo 19, apartado 1 de la UStG, el importe de la factura no incluye el impuesto sobre las ventas} it {Ai sensi della secção 19, paragrafo 1, della UStG, l'importo della fattura non comprende l'imposta sulle vendite} other {De acordo com a Secção 19 Parágrafo 1 UStG, o valor da fatura não inclui imposto sobre vendas}}",
          "S4": "{language, select, fr {De acordo com a secção 4 da UStG, nenhum imposto sobre o valor acrescentado é cobrado} de {De acordo com a secção 4 da UStG, nenhum imposto sobre o valor acrescentado é cobrado} es {De acordo com a secção 4 da UStG, nenhum imposto sobre o valor acrescentado é cobrado} it {De acordo com a secção 4 da UStG, nenhum imposto sobre o valor acrescentado é cobrado} other {De acordo com a secção 4 da UStG, nenhum imposto sobre o valor acrescentado é cobrado}}",
          "S4.1A": "{language, select, fr {Entrega de ficheiro exportado isenta de impostos, de acordo com a secção 4 nº 1a do UStG} de {Entrega de ficheiro exportado isenta de impostos, de acordo com a secção 4 nº 1a do UStG} es {Entrega de ficheiro exportado isenta de impostos, de acordo com a secção 4 nº 1a do UStG} it {Entrega de ficheiro exportado isenta de impostos, de acordo com a secção 4 nº 1a do UStG} other {Entrega de ficheiro exportado isenta de impostos, de acordo com a secção 4 nº 1a do UStG}}",
          "S4.1B": "{language, select, fr {Entrega intracomunitária isenta de impostos, de acordo com a secção 4 nº 1b do UStG} de {Entrega intracomunitária isenta de impostos de acordo com a secção 4 Nr. 1b do UStG} es {Entrega intracomunitária isenta de impostos de acordo com a secção 4 nº 1b do UStG} it {Entrega intracomunitária isenta de impostos de acordo com a secção 4 n. 1b UStG} other {Entrega intracomunitária isenta de impostos de acordo com a secção 4 Nº 1b UStG.}}"
        },
        "ES": {
          "S122": "{language, select, fr {Opération exonérée d'impôt avec l'art. 122 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 122 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos en virtud del art. 122 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 122 della Legge 37/1992 sull'IVA} other {Operação isenta de impostos de acordo com o art. 122 da Lei 37/1992, sobre o IVA}}",
          "S20": "{language, select, fr {Opération exonérée d'impôt, conformément à l'art. 20 de la Loi 37/1992 sur la TVA} de {Steuerfreier Vorgang gemäß Art. 20 des Gesetzes 37/1992 über die MwSt.} es {Operación exenta de impuestos por aplicación del art. 20 de la Ley 37/1992, del IVA} it {Operazione esente da imposte ai sensi dell'art. 20 della Legge 37/1992 sull'IVA} other {Operação isenta de impostos de acordo com o art. 20 da Lei 37/1992, sobre o IVA}}",
          "S21": "{language, select, fr {Exportação isenta de impostos de acordo com o art. 21 da Lei 37/1992, sobre o IVA} de {Exportação isenta de impostos de acordo com o art. 21 da Lei 37/1992, sobre o IVA} es {Exportação isenta de impostos de acordo com o art. 21 da Lei 37/1992, sobre o IVA} it {Exportação isenta de impostos de acordo com o art. 21 da Lei 37/1992, sobre o IVA} other {Exportação isenta de impostos de acordo com o art. 21 da Lei 37/1992, sobre o IVA}}",
          "S25": "{language, select, fr {Entrega intracomunitária isenta de impostos, de acordo com o art. 25 da Lei 37/1992 sobre o IVA} de {Entrega intracomunitária isenta de impostos de acordo com o art. 25 da Lei 37/1992 sobre o IVA} es {Entrega intracomunitária isenta de impostos de acordo com o art. 25 da Lei 37/1992, do IVA} it {Entrega intracomunitária isenta de impostos de acordo com o art. 25 da Lei 37/1992 sobre o IVA} other {Entrega intracomunitária isenta de impostos de acordo com o art. 25 da Lei 37/1992, sobre o IVA}}",
          "S69": "{language, select, fr {Operação localizada no endereço do destinatário, de acordo com o art. 69 da Lei 37/1992 sobre o IVA} de {Operação localizada no endereço do destinatário, de acordo com o art. 69 da Lei 37/1992 sobre o IVA} es {Operação localizada no endereço do destinatário, de acordo com o art. 69 da Lei 37/1992 sobre o IVA} it {Operação localizada no endereço do destinatário, de acordo com o art. 69 da Lei 37/1992 sobre o IVA} other {Operação localizada no endereço do destinatário, de acordo com o art. 69 da Lei 37/1992 sobre o IVA}}",
          "S84.1.2": "{language, select, fr {Reversão do sujeito passivo - Art. 84.1.2º da Lei 37/1992, sobre o IVA} de {Inversão do sujeito passivo - Art. 84.1.2º da Lei 37/1992 sobre o IVA} es {Inversión del sujeto pasivo - Art. 84.1.2º de la Ley 37/1992, del IVA} it {Storno del soggetto passivo - Art. 84.1.2º della Legge 37/1992 sull'IVA} other {Reversão do sujeito passivo - Art. 84.1.2º da Lei 37/1992, sobre o IVA}}"
        },
        "FR": {
          "S259": "{language, select, fr {IVA não aplicável, art. 259-1 do CGI} de {IVA não aplicável, art. 259-1 do CGI} es {IVA não aplicável, art. 259-1 do CGI} it {IVA não aplicável, art. 259-1 do CGI} other {IVA não aplicável, art. 259-1 do CGI}}",
          "S261": "{language, select, fr {IVA não aplicável, art. 261 do CGI} de {IVA não aplicável, art. 261 do CGI} es {IVA não aplicável, art. 261 do CGI} it {IVA não aplicável, art. 261 do CGI} other {IVA não aplicável, art. 261 do CGI}}",
          "S262": "{language, select, fr {TVA non applicable, art. 262 du CGI} de {MwSt. entfällt, Art. 262 des CGI} es {IVA no aplicable, art. 262 del CGI} it {IVA non applicabile, art. 262 del CGI} other {IVA não aplicável, art. 262 do CGI}}",
          "S262.1": "{language, select, fr {IVA não aplicável, art. 262 I do CGI} de {IVA não aplicável, art. 262 I do CGI} es {IVA não aplicável, art. 262 I do CGI} it {IVA não aplicável, art. 262 I do CGI} other {IVA não aplicável, art. 262 I do CGI}}",
          "S263": "{language, select, fr {IVA não aplicável, art. 263 do CGI} de {IVA não aplicável, art. 263 do CGI} es {IVA não aplicável, art. 263 do CGI} it {IVA não aplicável, art. 263 do CGI} other {IVA não aplicável, art. 263 do CGI}}",
          "S283": "{language, select, fr {Autoliquidação, art. 283 do CGI} de {Responsabilidade fiscal do beneficiário, Art. 283 do CGI} es {Autoliquidación, art. 283 del CGI} it {Autoliquidazione, art. 283 del CGI} other {Autoliquidação, art. 283 do CGI}}",
          "S293B": "{language, select, fr {IVA não aplicável, art. 293 B do CGI} de {IVA não aplicável, art. 293 B do CGI} es {IVA não aplicável, art. 293 B do CGI} it {IVA não aplicável, art. 293 B do CGI} other {IVA não aplicável, art. 293 B do CGI}}"
        },
        "NL": {
          "S11": "{language, select, fr {TVA non applicable, article 11 de l'OB 1968} de {MwSt. entfällt, Art. 11 des OB 1968} es {IVA no aplicable, art. 11 del OB 1968} it {IVA non applicabile, art. 11 del OB 1968} other {VAT not applicable, art. 11 of the OB 1968}}",
          "S12": "{language, select, fr {TVA non applicable, article 12 de l'OB 1968} de {MwSt. entfällt, Art. 12 des OB 1968} es {IVA no aplicable, art. 12 del OB 1968} it {IVA non applicabile, art. 12 del OB 1968} other {VAT not applicable, art. 12 of the OB 1968}}",
          "S25": "{language, select, fr {TVA non applicable, article 25 de l'OB 1968} de {MwSt. entfällt, Art. 25 des OB 1968} es {IVA no aplicable, art. 25 del OB 1968} it {IVA non applicabile, art. 25 del OB 1968} other {VAT not applicable, art. 25 of the OB 1968}}",
          "S9": "{language, select, fr {TVA non applicable, article 9 de l'OB 1968} de {MwSt. entfällt, Art. 9 des OB 1968} es {IVA no aplicable, art. 9 del OB 1968} it {IVA non applicabile, art. 9 del OB 1968} other {VAT not applicable, art. 9 of the OB 1968}}"
        },
        "PT": {
          "S53": "{language, select, fr {TVA non applicable, article 53 du Regime do IVA} de {MwSt. entfällt, Art. 53 des Regime do IVA} es {IVA no aplicable, art. 53 del Regime do IVA} it {IVA non applicabile, art. 53 del Regime do IVA} other {VAT not applicable, art. 53 of the Regime do IVA}}",
          "S9.1-5": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.27.28": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}",
          "S9.29.30": "{language, select, fr {TVA non applicable, article 9 du Regime do IVA} de {MwSt. entfällt, Art. 9 des Regime do IVA} es {IVA no aplicable, art. 9 del Regime do IVA} it {IVA non applicabile, art. 9 del Regime do IVA} other {VAT not applicable, art. 9 of the Regime do IVA}}"
        }
      },
      "footer-organization": "{language, select, fr {{orgaName} com um capital de {amount}} de {mit einem Kapital von} es {{orgaName} com um capital de {amount}} it {{orgaName} com um capital de {amount}} other {{orgaName} com um capital de {amount}}}",
      "iban": "IBAN",
      "intermediary-bic": {
        "label": "{language, select, fr {BIC do banco intermediário} de {BIC do nosso banco correspondente} es {BIC do banco intermediário} it {BIC do banco correspondente} other {BIC do banco intermediário}}",
        "value": "{language, select, fr {TRWIBEB3XXX} de {TRWIBEB3XXX} es {TRWIBEB3XXX} it {TRWIBEB3XXX} other {TRWIBEB3XXX}}"
      },
      "invoice-example": {
        "client-address-line1": "18 rue de Navarin",
        "client-city": "Paris",
        "client-countrycode": "DE",
        "client-delivery-address-line1": "18 rue de Navarin",
        "client-delivery-city": "Paris",
        "client-delivery-countrycode": "FR",
        "client-delivery-zipcode": "75009",
        "client-name": "Jane Doe",
        "client-vat-number": "123456789",
        "client-zipcode": "75009",
        "invoice-due-date": "15/01/2030",
        "invoice-issue-date": "01/01/2030",
        "invoice-number": "INV-2030-001",
        "invoice-payment-time": "Por favor, pague o valor da fatura dentro de 15 dias. (15/01/2030)",
        "invoice-performance-date": "01/01/2030",
        "invoice-total": "120",
        "item-description": "Este é o lugar onde os detalhes do seu futuro, verdadeiros artigos irão.",
        "item-price": "100",
        "item-quantity": "2",
        "item-title": "O título do meu primeiro artigo",
        "item-total": "120",
        "item-total-without-vat": "100",
        "item-vat-amount": "0.2",
        "item-vat-percentual": "%",
        "item-vat-total": "20",
        "page-count": "1/1",
        "quote-validated-time": "Este orçamento é válido por 30 dias (15/01/2030)"
      },
      "invoice-number": "{language, select, fr {Número da fatura} it {Numero da fatura} de {Número da fatura} es {Número da fatura} other {Número da fatura}}",
      "invoice-payment-time": "{number, plural, =0 {{language, select, fr {Veuillez payer la facture à l'émission. ({dueDate})} de {Bitte bezahlen Sie den Betrag direkt nach Erhalt der Rechnung. ({dueDate})} es {Por favor, abona el importe de la factura tras la recepción. ({dueDate})} it {Per favore, paga l’importo della fattura alla sua ricezione. ({dueDate})} other {Please pay the invoice amount upon receipt. ({dueDate})}}} one {{language, select, fr {Veuillez payer la facture sous {days_amount} jour. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tag. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} día. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorno. ({dueDate})} other {Please pay the invoice amount within {days_amount} day. ({dueDate})}}} other {{language, select, fr {Veuillez payer la facture sous {days_amount} jours. ({dueDate})} de {Der Rechnungsbetrag ist fällig in {days_amount} Tagen. ({dueDate})} es {Por favor, abona el importe de la factura en un plazo de {days_amount} días. ({dueDate})} it {Per favore, paga l'importo della fattura entro {days_amount} giorni. ({dueDate})} other {Please pay the invoice amount within {days_amount} days. ({dueDate})}}}}",
      "issue-date": "{language, select, fr {Date d'émission} it {Data di emissione} de {Ausstellungsdatum} es {Fecha de emisión} other {Data de emissão}}",
      "items": "{language, select, fr {Descrição} it {Descrição} de {Descrição} es {Descrição} other {Descrição}}",
      "leadership": "{language, select, fr {Direção da empresa:} de {Unternehmensführung} es {Dirección de la empresa:} it {Direzione dell'impresa:} other {Liderança da empresa:}}",
      "payment-condition": {
        "compensated_for_sales": "{language, select, fr {Sobre os débitos} de {Incluído no valor a ser pago} es {Compensação por vendas} it {Incluído no preço de venda} other {Compensado pelas vendas}}",
        "exempt": "{language, select, fr {Isenção} de {Isento} es {Isento} it {Isento} other {Isento}}",
        "label": "{language, select, fr {Condições de pagamento do IVA: } de {Condições de pagamento do IVA: } es {Condições de pagamento do IVA: } it {Condições de pagamento do IVA: } other {Condições de pagamento do IVA: }}",
        "on_receipts": "{language, select, fr {Sur les encaissements} de {Sofort} es {Tras la recepción} it {A vista} other {Na recepção}}"
      },
      "payment-details": "{language, select, fr {Détails du paiement} it {Dettagli per il pagamento} de {Zahlungsdetails} es {Detalles del pago} other {Detalhes do pagamento}}",
      "pension-contribution": "{language, select, it {Contributo pensione/INPS (IVA escl.)} other {Contribuição para a pensão/INPS (excl. IVA)}}",
      "percentage-discount": "{language, select, fr {Remise ({discountPercentage} %)} de {Desconto ({discountPercentage}%)} es {Descuento ({discountPercentage}%)} it {Sconto ({discountPercentage}%)} other {Discount ({discountPercentage}%)}}",
      "performance-date": "{language, select, fr {Data de execução} de {Leistungsdatum} es {Fecha de ejecución} it {Data prestazione} other {Data de execução}}",
      "placerholder-stamp-duty": "{language, select, it {Imposta di bollo virtuale pagata ai sensi del decreto ministeriale 17.06.2014} other {Imposto de selo pago virtualmente de acordo com o Decreto Ministerial 17.06.2014}}",
      "purchase-order": "{language, select, fr {Número do pedido de compra} it {Numero d'ordine} de {Bestellnummer} es {Número de orden de compra} other {Número do pedido de compra}}",
      "quantity": "{language, select, fr {Qté} it {Qtà} de {Menge} es {Cant.} other {Qtd}}",
      "quote-expiry-date": "{language, select, fr {Date d'expiration} it {Data di scadenza} de {Ablaufdatum} es {Fecha de vencimiento} other {Data de validade}}",
      "quote-number": "{language, select, fr {Número do orçamento} it {Numero del preventivo} de {Angebotsnummer} es {Número de presupuesto} other {Número do orçamento}}",
      "quote-related-number": "{language, select, fr {Número do orçamento associado } it {Numero di preventivo correlato } de {Zugehöriges Angebot } es {Número de presupuesto asociado } other {Número do orçamento relacionado }}",
      "quote-title": "{language, select, fr {Devis} de {Angebot} es {Presupuesto} it {Preventivo} other {Orçamento}}",
      "quote-validated-time": "{number, plural, one {{language, select, fr {Ce devis est valable {days_amount} jour. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tag. ({expiry_date})} es {Este presupuesto es válido por {days_amount} día. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorno. ({expiry_date})} other {Este orçamento é válido por {days_amount} dia. ({expiry_date})}}} other {{language, select, fr {Ce devis est valable {days_amount} jours. ({expiry_date})} de {Dieses Angebot gilt {days_amount} Tage. ({expiry_date})} es {Este presupuesto es válido por {days_amount} días. ({expiry_date})} it {Questo preventivo è valido per {days_amount} giorni. ({expiry_date})} other {Este orçamento é válido por {days_amount} dias. ({expiry_date})}}}}",
      "reference": "{language, select, fr {Référence} it {Causale} de {Referenz} es {Referencia} other {Referência}}",
      "revenue-stamp": "{language, select, it {Selo fiscal} other {Revenue stamp}}",
      "steuer-id": "{language, select, fr {Identificação Fiscal} de {Identificação Fiscal} es {Identificação Fiscal} it {Identificação Fiscal} other {Identificação Fiscal}}",
      "steuernummer": "{language, select, fr {Número de imposto :} de {Número de imposto:} es {Número de imposto:} it {Número de imposto:} other {Número de imposto:}}",
      "title": "{language, select, fr {Fatura} de {Fatura} es {Fatura} it {Fatura} other {Fatura}}",
      "total": "{language, select, fr {Total} it {Total} de {Total} es {Total} other {Total}}",
      "total-vat": "{language, select, fr  {Total TTC} it {Totale (IVA inclusa)}  de {Gesamt (inkl. MwSt.)} es {Total (IVA incluido)} other {Total incl. IVA}}",
      "total-vat-amount": "{language, select, fr {Montant total de la TVA} it {Importo totale IVA} de {MwSt.-Betrag insgesamt} es {Importe total del IVA} other {Montante total de IVA}}",
      "total-without-vat": "{language, select, fr {Total HT} it {Totale (IVA escl.)} de {Gesamt (exkl. MwSt.)} es {Total (IVA no incluido)} other {Total excl. IVA}}",
      "transaction-type": {
        "goods": "{language, select, fr {Bens} de {Waren} es {Bienes} it {Beni} other {Bens}}",
        "goods_and_services": "{language, select, fr {Bens e serviços} de {Waren und Dienstleistungen} es {Bienes y servicios} it {Beni e servizi} other {Bens e serviços}}",
        "label": "{language, select, fr {Tipo de transação: } de {Transaktionstyp: } es {Tipo de transacción: } it {Tipo di transazione: } other {Tipo de transação: }}",
        "services": "{language, select, fr {Serviços} de {Dienstleistungen} es {Servicios} it {Servizi} other {Serviços}}"
      },
      "unit-price": "{language, select, fr {Prix unitaire} it {Prezzo unitario} de {Preis pro Einheit} es {Precio unitario} other {Preço unitário}}",
      "ust-udnr": "{language, select, fr {NIF} de {NIF} es {NIF} it {NIF} other {NIF}}",
      "vat": "{language, select, fr {TVA (%)} it {IVA (%)} de {MwSt. (%)} es {IVA (%)} other {IVA (%)}}",
      "vat-amount": "{language, select, fr {Montant de la TVA} it {Importo IVA} de {MwSt-Betrag} es {Importe del IVA} other {montante de IVA}}",
      "vat-number": "{language, select, fr {Número de IVA :} de {USt.-IdNr.:} es {Número de IVA:} it {Partita IVA:} other {Número de IVA:}}",
      "withholding-tax": "{language, select, it {Ritenuta d'acconto} other {Imposto retido na fonte}}"
    },
    "spinner": {
      "label": "Carregando"
    },
    "stepper-header": {
      "back": "Voltar",
      "close": "Fechar"
    },
    "translation-with-args": {
      "body-1": "Texto com {var} dentro.",
      "body-3": "Texto com {var1} dentro e depois outro {var2} dentro e então outro {var3} dentro."
    },
    "zxcvbn": {
      "password_common": "Esta palavra-passe é muito comum, e portanto fácil de adivinhar.",
      "password_date": "As datas devem ser evitadas, pois são usadas com muita frequência.",
      "password_dictionary": "Esta palavra-passe é muito comum, e portanto fácil de adivinhar.",
      "password_repeat": "Repetições de palavras ou letras não são seguras o suficiente.",
      "password_sequence": "Sequências lógicas, como \"1234\" por exemplo, são fáceis de adivinhar.",
      "password_spatial": "Sequências de teclado, como \"qwerty\" por exemplo, não são seguras o suficiente."
    }
  },
  "welcome": {
    "gdpr_checkbox": "Je consens à l'utilisation de mes données personnelles par Qonto",
    "gdpr_checkbox_error_message": "Pour continuer, vous devez accepter notre politique d'utilisation des données.",
    "redirect_to_appointment_page": "Demandez plus d'informations à l'un de nos experts",
    "redirect_to_bridge": "Démarrer le transfert",
    "step_1_subtitle": "Grâce au service sécurisé Bridge by Bankin'.",
    "step_1_title": "Connectez votre banque à Qonto",
    "step_2_subtitle": "Vos données seront synchronisées en toute sécurité.",
    "step_2_title": "Sélectionnez le(s) compte(s) à transférer",
    "step_3_subtitle": "Pour répondre à toutes vos questions.",
    "step_3_title": "Un conseiller Qonto vous accompagne de A à Z",
    "subtitle": "Et optimisez la gestion de vos finances.",
    "title": "Transférez vos comptes en toute simplicité"
  }
}]];