export default {
  "row-item-no-bottom": "_row-item-no-bottom_kcndj2",
  "row-item-padding": "_row-item-padding_kcndj2",
  "row-link-label": "_row-link-label_kcndj2",
  "row-links": "_row-links_kcndj2",
  "row-discount": "_row-discount_kcndj2",
  "line-break": "_line-break_kcndj2",
  "five-columns-table": "_five-columns-table_kcndj2",
  "eu-table": "_eu-table_kcndj2",
  "de-table": "_de-table_kcndj2",
  "row-item-discount": "_row-item-discount_kcndj2"
};