export default {
  "fullscreen-preview": "_fullscreen-preview_1pgv97",
  "with-sidebar": "_with-sidebar_1pgv97",
  "header": "_header_1pgv97",
  "title-wrapper": "_title-wrapper_1pgv97",
  "title": "_title_1pgv97",
  "subtitle": "_subtitle_1pgv97",
  "main": "_main_1pgv97",
  "image-preview": "_image-preview_1pgv97",
  "pdf-preview": "_pdf-preview_1pgv97",
  "sidebar": "_sidebar_1pgv97"
};