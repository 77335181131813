import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import ParentBankModel from 'qonto-switching-bank-js/models/parent-bank';
import Router from 'qonto-switching-bank-js/router';
import SelectBankRoute from 'qonto-switching-bank-js/routes/onboarding/select-bank/route';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import ParentBankService from 'qonto-switching-bank-js/services/parent-bank';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class SelectBankIndexController extends Controller {
  @service('parentBank') declare parentBankService: ParentBankService;
  @service declare router: Router;
  @service declare modals: typeof ModalsService;
  @service declare segment: SegmentService;

  declare model: ModelFrom<SelectBankRoute>;

  @tracked searchQuery = '';

  get parsedBanks() {
    if (!this.searchQuery) {
      return this.parentBankService.parse(this.model);
    } else {
      return new Map([['', this.parentBankService.search(this.model, this.searchQuery)]]);
    }
  }

  get noBankFound(): boolean {
    return this.searchQuery !== '' && this.parsedBanks.get('')?.length === 0;
  }

  @action handleSelection(bank: ParentBankModel) {
    this.segment.track('bankswitch_bank_selected');
    if (bank.child_bank_ids.length > 1) {
      this.router.transitionTo('onboarding.select-bank.child', bank.id);
    } else {
      this.router.transitionTo(
        'onboarding.select-bank.child.credentials',
        bank.id,
        bank.child_bank_ids[0]
      );
    }
  }

  @action searchBanks(search: string) {
    this.searchQuery = search;
  }
}
