import { buildTask as _buildTask } from "ember-concurrency/-private/async-arrow-runtime";
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
export default class PopupDestructiveComponent extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "confirmTask", _buildTask(() => ({
      context: this,
      generator: function* () {
        let {
          confirmTask,
          ...data
        } = this.args.data;
        if (confirmTask) {
          yield confirmTask.linked().perform(this.args.close, data);
        } else {
          this.args.close('confirm');
        }
      }
    }), null, "confirmTask", null));
    _defineProperty(this, "cancelTask", _buildTask(() => ({
      context: this,
      generator: function* () {
        let {
          cancelTask,
          ...data
        } = this.args.data;
        if (cancelTask) {
          yield cancelTask.linked().perform(this.args.close, data);
        } else {
          this.args.close('cancel');
        }
      }
    }), null, "cancelTask", null));
    _defineProperty(this, "closeTask", _buildTask(() => ({
      context: this,
      generator: function* () {
        if (this.confirmTask.isIdle && this.cancelTask.isIdle) {
          let {
            closeTask,
            ...data
          } = this.args.data;
          if (closeTask) {
            yield closeTask.linked().perform(this.args.close, data);
          } else {
            this.args.close('close');
          }
        }
      }
    }), null, "closeTask", null));
  }
  get isDisabled() {
    return this.confirmTask.isRunning || this.cancelTask.isRunning || this.closeTask.isRunning;
  }
}