import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import ToastFlashMessagesService from 'app/services/toast-flash-messages';
import { BadgeHighlight } from 'design-system-kit';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import ActionModel from 'qonto-switching-bank-js/models/action';
import MigrationModel from 'qonto-switching-bank-js/models/migration';
import ActionService from 'qonto-switching-bank-js/services/action';
import FixturesService from 'qonto-switching-bank-js/services/fixtures';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import SegmentService from 'qonto-switching-bank-js/services/segment';

interface ActionCardArgs {
  action: ActionModel;
  migration: MigrationModel;
  showActionForm: (action: ActionModel) => void;
  shouldDisplayDemoData: boolean;
}

export default class ActionCardComponent extends Component<ActionCardArgs> {
  badgeHighlight = BadgeHighlight;

  @service('action') declare actionService: ActionService;
  @service declare router: RouterService;
  @service declare modals: typeof ModalsService;
  @service declare intl: IntlService;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service declare fixtures: FixturesService;
  @service declare segment: SegmentService;

  get lastTransactionDate() {
    return this.args.action.last_transaction?.date
      ? new Date(this.args.action.last_transaction.date).toLocaleDateString()
      : '';
  }

  get hasNoTransactions() {
    return this.args.action.transactions_count <= 0;
  }

  get isDone() {
    return this.args.action.status === 'finished';
  }
  get isIgnored() {
    return this.args.action.status === 'ignored';
  }
  get isImportant() {
    return this.args.action.is_important;
  }
  get statusClass() {
    if (this.isIgnored) {
      return 'is-ignored';
    } else if (this.isDone) {
      return 'is-done';
    } else {
      return '';
    }
  }
  get methodName() {
    return `dashboard.card.category.${this.args.action.methodName}`;
  }
  get toggleIgnoredIcon() {
    return this.isIgnored ? 'icon-eye' : 'icon-eye-hidden';
  }

  @action showAction() {
    if (this.hasNoTransactions) {
      return;
    }

    this.router.transitionTo('dashboard.type.action', this.args.action.id, {
      queryParams: { page: '1' },
    });
  }

  @action editAction() {
    this.args.showActionForm(this.args.action);
  }

  @task handleDeleteActionTask = taskFor(function* (this: ActionCardComponent, close: () => void) {
    try {
      if (this.args.shouldDisplayDemoData) {
        this.fixtures.deleteAction(this.args.action);
      } else {
        this.segment.track('bankswitch_action_deleted');
        this.args.action.destroyRecord();
      }
      yield close();

      this.toastFlashMessages.toastInfo(this.intl.t('dashboard.delete_action.toaster'));
    } catch {
      this.toastFlashMessages.defaultToastError();
    }
  });

  @action deleteAction() {
    this.modals.open('confirmation-modal', {
      title: this.intl.t('dashboard.delete_action.modal.title'),
      body: this.intl.t('dashboard.delete_action.modal.body'),
      cancel: this.intl.t('dashboard.delete_action.modal.CTA_cancel'),
      confirm: this.intl.t('dashboard.delete_action.modal.CTA_delete'),
      confirmTask: this.handleDeleteActionTask,
      confirmColor: 'red',
    });
  }

  @action async toggleFinished() {
    await this.actionService.editActionStatus(
      this.args.action,
      this.isDone ? 'created' : 'finished',
      this.args.shouldDisplayDemoData
    );
  }

  @action async toggleIgnored() {
    await this.actionService.editActionStatus(
      this.args.action,
      this.isIgnored ? 'created' : 'ignored',
      this.args.shouldDisplayDemoData
    );
  }

  @action async toggleImportant() {
    await this.actionService.toggleImportant(this.args.action, this.args.shouldDisplayDemoData);
  }

  @action stopEventPropagation(event: Event) {
    event.stopImmediatePropagation();
  }
}
