var _class;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
/* globals ProgressBar */
import { action } from '@ember/object';
import Component from '@glimmer/component';
let QProgressBar = (_class = class QProgressBar extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "_previousProgress", null);
    _defineProperty(this, "_progressBar", null);
  }
  get formattedProgress() {
    let p = this.args.progress || 0;
    return `${Math.floor(p * 100)}%`;
  }
  createProgressBar(element) {
    this._progressBar = new ProgressBar.Line(element, {
      strokeWidth: 0.25,
      duration: 100,
      color: '#7d70d1'
    });
    this._progressBar.set(this.args.progress);
  }
  update() {
    let progress = this.args.progress;
    let from = this._previousProgress || 0;
    this._previousProgress = progress;
    this._progressBar.animate(progress, {
      from
    }, () => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      if (progress === 1) {
        this._previousProgress = 0;
      }
    });
  }
}, (_applyDecoratedDescriptor(_class.prototype, "createProgressBar", [action], Object.getOwnPropertyDescriptor(_class.prototype, "createProgressBar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class);
export { QProgressBar as default };