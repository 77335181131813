import ArrayProxy from '@ember/array/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { LegacyNetworkHandler } from '@ember-data/legacy-compat';
import RequestManager from '@ember-data/request';
import Fetch from '@ember-data/request/fetch';
import BaseStore, { CacheHandler } from '@ember-data/store';
import Namespace from '@ember/application/namespace';
import Ember from 'ember';
import VERSION from 'ember-data/version';
export { Errors, ManyArray, PromiseManyArray } from '@ember-data/model/-private';
export { Snapshot, SnapshotRecordArray } from '@ember-data/legacy-compat/-private';
export { RecordArrayManager, coerceId, normalizeModelName } from '@ember-data/store/-private';
const DS = Namespace.create({
  VERSION: VERSION,
  name: 'DS'
});
if (Ember.libraries) {
  Ember.libraries.registerCoreLibrary('Ember Data', VERSION);
}

// public
class Store extends BaseStore {
  constructor(args) {
    super(args);
    this.requestManager = new RequestManager();
    this.requestManager.use([LegacyNetworkHandler, Fetch]);
    this.requestManager.useCache(CacheHandler);
  }
}
const PromiseArray = ArrayProxy.extend(PromiseProxyMixin);
const PromiseObject = ObjectProxy.extend(PromiseProxyMixin);
export { DS, PromiseArray, PromiseObject, Store };