function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

import Ember from 'ember';
import memoize from 'fast-memoize';
import { htmlSafe, isHTMLSafe } from '@ember/template';
import IntlMessageFormat from 'intl-messageformat';
import parse from '../utils/parse';
const {
  Handlebars: {
    // @ts-expect-error Upstream types are incomplete.
    Utils: {
      escapeExpression
    }
  }
} = Ember;
function escapeOptions(object) {
  if (typeof object !== 'object') {
    return;
  }
  const escapedOpts = {};
  Object.keys(object).forEach(key => {
    const val = object[key];
    if (isHTMLSafe(val)) {
      // If the option is an instance of Ember SafeString,
      // we don't want to pass it into the formatter, since the
      // formatter won't know what to do with it. Instead, we cast
      // the SafeString to a regular string using `toHTML`.
      // Since it was already marked as safe we should *not* escape it.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      escapedOpts[key] = val.toHTML();
    } else if (typeof val === 'string') {
      escapedOpts[key] = escapeExpression(val);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      escapedOpts[key] = val; // copy as-is
    }
  });
  return escapedOpts;
}

/**
 * @private
 * @hide
 */
export default class FormatMessage {
  constructor(config) {
    _defineProperty(this, "config", void 0);
    _defineProperty(this, "readFormatConfig", void 0);
    _defineProperty(this, "createNativeFormatter", memoize((ast, locales, formatConfig) => {
      return new IntlMessageFormat(ast, locales, formatConfig, {
        ignoreTag: true
      });
    }));
    this.config = config;

    // NOTE: a fn since we lazily grab the formatter from the config
    // as it can change at runtime by calling intl.set('formats', {...});
    this.readFormatConfig = config.readFormatConfig;
  }

  // ! Function overloads are not passed through generic types for reasons that
  // evade my knowledge. ¯\_(ツ)_/¯
  // For this reason these types need to be manually copied over to the
  // `IntlService#formatMessage`.

  format(locale, maybeAst, options) {
    let ast = maybeAst;
    if (typeof maybeAst === 'string') {
      // maybe memoize?  it's not a typical hot path since we
      // parse when translations are pushed to ember-intl.
      // This is only used if inlining a translation i.e.,
      // {{format-message "Hi {name}"}}
      ast = parse(maybeAst);
    }
    const isHTMLSafe = options && options.htmlSafe;
    const formatterInstance = this.createNativeFormatter(ast, locale, this.readFormatConfig());
    const escapedOptions = isHTMLSafe ? escapeOptions(options) : options;
    const result = formatterInstance.format(escapedOptions);
    return isHTMLSafe ? htmlSafe(result) : result;
  }
}
_defineProperty(FormatMessage, "type", 'message');