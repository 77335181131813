import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { didCancel, task } from 'ember-concurrency';

import ENV from 'qonto-switching-bank-js/config/environment';
import { membershipNamespace, qontoApiBaseURL } from 'qonto-switching-bank-js/constants/hosts';
import { ExternalScriptError, loadScript } from 'qonto-switching-bank-js/utils/load-script';

export default class RefinerService extends Service {
  @service store;
  @service network;

  @tracked isLoaded = false;

  _refiner() {
    window._refinerQueue.push(arguments);
  }

  setupRefiner = projectId => {
    window._refinerQueue = window._refinerQueue || [];
    this._refiner('setProject', projectId);
  };

  identifyUser = (membership, migration, organization) => {
    this._refiner('identifyUser', {
      id: membership.id,
      name: membership.full_name,
      first_name: membership.first_name,
      role: membership.role,
      pathway: migration.pathway,
      account: {
        id: organization.id,
        name: organization.legal_name,
        country: organization.legal_country,
      },
    });
  };

  load = (migration, organization, user) => {
    if (!this.isLoaded) {
      try {
        return this.loadRefinerTask.perform(migration, organization, user);
      } catch (error) {
        if (!(didCancel(error) || error instanceof ExternalScriptError)) {
          throw error;
        }
      }
    }
  };

  @(task(function* (migration, organization, user) {
    yield loadScript('https://js.refiner.io/2021-01-26-qonto/client.js');

    let baseURL = qontoApiBaseURL + '/' + membershipNamespace;
    let response = yield this.network.get(`/memberships?organization_id=${organization.id}`, {
      baseURL,
    });
    let memberships = response.data.memberships;
    let membership = memberships.find(membership => membership.user_id === user.id);

    this.setupRefiner(ENV.refinerProjectId);
    this.identifyUser(membership, migration, organization);
    this.isLoaded = true;
  }).drop())
  loadRefinerTask;
}
