import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { ProgressBar } from 'design-system-kit';

import MigrationModel from 'qonto-switching-bank-js/models/migration';
import GuideBookDownloadService from 'qonto-switching-bank-js/services/guide-book-download';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';

type OverviewStepsComponentArgs = {
  migration: MigrationModel;
  shouldDisplayDemoData: boolean;
};

export default class OverviewStepsComponent extends Component<OverviewStepsComponentArgs> {
  @service declare network: NetworkService;
  @service declare store: Store;
  @service('migration') declare migrationService: MigrationService;
  @service('guide-book-download') declare guideBook: GuideBookDownloadService;
  @service declare segment: SegmentService;

  progressBar = ProgressBar;

  get actionStats() {
    let stats = this.args.migration.action_stats;

    return {
      ...stats,
      done_credit_count: stats.relevant_credit_count - stats.created_credit_count,
      done_debit_count: stats.relevant_debit_count - stats.created_debit_count,
    };
  }

  get steps() {
    return [
      {
        position: 1,
        title: 'dashboard.overview.stepper.step1.title',
        cards: [
          {
            productTourTarget: 'overview-debit-card',
            title: 'dashboard.overview.stepper.step1.subtitle1.title',
            subtitle: 'dashboard.overview.stepper.step1.subtitle1.description',
            progress: {
              current: this.actionStats.done_debit_count,
              max: this.actionStats.relevant_debit_count,
            },
            link: {
              count: this.actionStats.created_debit_count,
              route: 'dashboard.type',
              model: 'debit',
            },
          },
          {
            title: 'dashboard.overview.stepper.step1.subtitle2.title',
            subtitle: 'dashboard.overview.stepper.step1.subtitle2.description',
            progress: {
              current: this.actionStats.done_credit_count,
              max: this.actionStats.relevant_credit_count,
            },
            link: {
              count: this.actionStats.created_credit_count,
              route: 'dashboard.type',
              model: 'credit',
            },
          },
        ],
      },
      {
        position: 2,
        title: 'dashboard.overview.stepper.step2.title',
        subtitle: 'dashboard.overview.stepper.step2.subtitle',
        action: {
          icon: 'icon_download_outlined',
          label: 'dashboard.overview.stepper.file_link',
          click: this.downloadFile,
        },
      },
      {
        position: 3,
        title: 'dashboard.overview.stepper.step3.title',
        subtitle: 'dashboard.overview.stepper.step3.subtitle',
        action: this.args.shouldDisplayDemoData
          ? null
          : {
              icon: 'icon-account-transfer',
              label: 'dashboard.overview.stepper.end_migration',
              click: this.endMigration,
            },
      },
    ];
  }

  @action async downloadFile() {
    this.guideBook.downloadFile(this.args.shouldDisplayDemoData);
  }

  @action endMigration() {
    this.migrationService.endMigration(this.args.migration);
  }
}
