import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/browser';
import { taskFor } from 'ember-concurrency-ts';

import EntryPointRouteService from 'qonto-switching-bank-js/services/entry-point-route';
import FunnelService from 'qonto-switching-bank-js/services/funnel';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import {
  CredentialsBody,
  CredentialsInput,
  CredentialsInputLabelsList,
  CredentialsInputLabelsPWDDefault,
  CredentialsInputLabelsUSERDefault,
  PROVIDER_ITEM_REFRESH_STATUS,
  ProviderItemRefreshInfo,
  ProviderItemRequestBody,
} from 'qonto-switching-bank-js/types/credentials';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

import SelectBankChildCredentialsRoute from './route';

export default class SelectBankChildCredentialsController extends Controller {
  @service declare network: NetworkService;
  @service declare funnel: FunnelService;
  @service declare modals: typeof ModalsService;
  @service declare segment: SegmentService;
  @service declare router: RouterService;
  @service declare entryPointRoute: EntryPointRouteService;

  declare model: ModelFrom<SelectBankChildCredentialsRoute>;

  @tracked USER = '';
  @tracked PWD = '';
  @tracked PWD2 = '';
  @tracked checked = false;
  @tracked isLoading = false;
  @tracked hasInvalidCreds = false;

  get isBackButtonToParentBank() {
    return Number(this.model.childBanks.length) <= 1;
  }

  get credentialsForm(): CredentialsInput[] {
    if (this.model.childBank.form) {
      return this.model.childBank.form.map(input => {
        let inputLabels = CredentialsInputLabelsList.find(
          labels => labels.bridgeLabel.toLowerCase() === input.label.toLowerCase()
        );

        if (!inputLabels) {
          if (input.type === 'USER') {
            inputLabels = CredentialsInputLabelsUSERDefault;
          } else {
            inputLabels = CredentialsInputLabelsPWDDefault;
          }

          Sentry.captureMessage(`Child bank form input label not found : ${input.label}`);
        }

        return { ...input, ...inputLabels };
      });
    } else {
      return [];
    }
  }

  get isDisabled(): boolean {
    let formIsFilled = this.credentialsForm.every(input => this[input.type]);

    return this.isLoading || !formIsFilled || !this.checked;
  }

  get providerItemRefreshInfoPoller() {
    return taskFor(this.funnel.pollProviderItemRefreshInfoTask);
  }

  @action toggleCheckbox() {
    this.checked = !this.checked;
  }

  @action update(input: CredentialsInput, value: string) {
    this[input.type] = input.isNum === '1' ? value.replace(/[^/0-9]/g, '') : value;
  }

  handleProviderItemRefreshInfo = async (providerItemRefreshInfo: ProviderItemRefreshInfo) => {
    let { status, status_details } = providerItemRefreshInfo;

    switch (status) {
      case PROVIDER_ITEM_REFRESH_STATUS.RETRIEVING_DATA:
        this.router.transitionTo('onboarding.synchronization-in-progress');
        break;
      case PROVIDER_ITEM_REFRESH_STATUS.FINISHED:
        this.router.transitionTo('onboarding.synchronization-success');
        break;
      case PROVIDER_ITEM_REFRESH_STATUS.INVALID_CREDS:
        this.hasInvalidCreds = true;
        break;
      case PROVIDER_ITEM_REFRESH_STATUS.FINISHED_ERROR:
        if (status_details === 'could_not_refresh') {
          this.router.transitionTo('errors.could-not-refresh');
        } else if (status_details === 'needs_manual_refresh') {
          this.hasInvalidCreds = true;
        } else if (status_details) {
          this.router.transitionTo(this.entryPointRoute.getMigrationErrorRoute(status_details));
        }
        break;
    }
  };

  @action async submit(event: Event) {
    this.segment.track('bankswitch_credentials_completed');
    event.preventDefault();
    this.isLoading = true;
    this.hasInvalidCreds = false;

    try {
      let bank_id = this.model.childBank.provider_bank_id;
      let credentials = this.credentialsForm.reduce<CredentialsBody>(
        (body, input) => {
          return { ...body, [input.type]: this[input.type] };
        },
        { USER: '' }
      );
      let providerItemRequestBody: ProviderItemRequestBody = { bank_id, credentials };
      let response = await this.network
        .post('/provider/items', providerItemRequestBody)
        .catch(error => {
          if (error?.response?.status === 422) {
            this.hasInvalidCreds = true;
          }
          throw error;
        });
      let migrationId = response.data.migration_id;

      let providerItemRefreshInfo = await this.providerItemRefreshInfoPoller.perform({
        statusesToAwait: [
          PROVIDER_ITEM_REFRESH_STATUS.INFO_REQUIRED,
          PROVIDER_ITEM_REFRESH_STATUS.RETRIEVING_DATA,
        ],
      });

      if (providerItemRefreshInfo.status === PROVIDER_ITEM_REFRESH_STATUS.INFO_REQUIRED) {
        let modalConfig = {
          focusTrapOptions: {
            clickOutsideDeactivates: false,
            allowOutsideClick: false,
          },
        };
        if (providerItemRefreshInfo.mfa?.type === 'SMS') {
          this.modals.open(
            'credentials-modal-otp',
            { migrationId, providerItemRequestBody },
            modalConfig
          );
        } else if (providerItemRefreshInfo.mfa?.type === 'APP_TO_APP') {
          let modal = this.modals.open('credentials-modal-app-to-app', {}, modalConfig);

          providerItemRefreshInfo = await this.providerItemRefreshInfoPoller.perform({
            statusesToAwait: [PROVIDER_ITEM_REFRESH_STATUS.RETRIEVING_DATA],
            maxFetchCount: 100,
          });
          modal.close();

          this.handleProviderItemRefreshInfo(providerItemRefreshInfo);
        }
      } else {
        this.handleProviderItemRefreshInfo(providerItemRefreshInfo);
      }
    } catch (error) {
      if (error?.response?.config && error?.response?.status) {
        let { method, url } = error.response.config;
        let status = error.response.status;
        Sentry.captureMessage(`${status} error from ${method.toUpperCase()} ${url}`);
      }
    } finally {
      this.isLoading = false;
    }
  }
}
