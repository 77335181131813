import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SelectBankRoute extends Route {
  @service declare store: Store;

  async model() {
    return await this.store.findAll('parent-bank');
  }
}
