import Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';

import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';

export default class GuideBookDownloadService extends Service {
  @service declare network: NetworkService;
  @service declare store: Store;
  @service declare segment: SegmentService;

  async downloadFile(shouldDisplayDemoData?: boolean) {
    let redirect_url = '';
    let a = document.createElement('a');

    if (shouldDisplayDemoData) {
      redirect_url =
        'https://qonto-assets.s3.eu-central-1.amazonaws.com/documents/QONTO-BANK-SWITCH-TEMPLATE-LETTER-FR.pdf';
    } else {
      let migration = await this.store.queryRecord('migration', {});

      if (migration) {
        let response = await this.network.get(`/migrations/${migration.id}/template-letter`);
        this.segment.track('bankswitch_pdf_downloaded');

        redirect_url = response.data.redirect_url;
      }
    }

    a.href = redirect_url;
    a.target = '_blank';
    a.click();
  }
}

declare module '@ember/service' {
  interface Registry {
    'guide-book-download': GuideBookDownloadService;
  }
}
