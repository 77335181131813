/* eslint-disable @typescript-eslint/no-unused-vars */
import Store from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';

export default class DashboardTypeRoute extends Route {
  @service fixtures;
  @service store;

  async model({ transaction_type }) {
    let { migration, shouldDisplayDemoData } = this.modelFor('dashboard');
    let migration_id = migration.id;
    let actions;

    if (shouldDisplayDemoData) {
      actions = this.fixtures.actions[transaction_type];
    } else {
      actions = await this.store.query('action', { transaction_type, migration_id });
    }

    return { transaction_type, migration, actions, shouldDisplayDemoData };
  }

  @action error() {
    Sentry.captureMessage("find all actions issue: the actions couldn't be picked by the store");

    return true;
  }
}
