export default {
  "cockpit-tile": "_cockpit-tile_g8qbj0",
  "cockpit-tile--clickable": "_cockpit-tile--clickable_g8qbj0",
  "title": "_title_g8qbj0",
  "content": "_content_g8qbj0",
  "value-loading": "_value-loading_g8qbj0",
  "value": "_value_g8qbj0",
  "subtitle-loading": "_subtitle-loading_g8qbj0",
  "subtitle-wrapper": "_subtitle-wrapper_g8qbj0",
  "subtitle": "_subtitle_g8qbj0",
  "subtitle-tooltip": "_subtitle-tooltip_g8qbj0",
  "subtitle-tooltip-svg": "_subtitle-tooltip-svg_g8qbj0"
};