import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import ChildBankModel from 'qonto-switching-bank-js/models/child-bank';
import SelectBankChildRoute from 'qonto-switching-bank-js/routes/onboarding/select-bank/child/route';
import EntryPointRouteService from 'qonto-switching-bank-js/services/entry-point-route';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

type Transition = ReturnType<RouterService['transitionTo']>;

export default class SelectBankChildCredentialsRoute extends Route {
  @service declare network: NetworkService;
  @service('migration') declare migrationService: MigrationService;
  @service declare entryPointRoute: EntryPointRouteService;
  @service declare segment: SegmentService;
  @service declare modals: typeof ModalsService;

  beforeModel() {
    this.segment.track('bankswitch_child_bank_selected');
  }

  async model(params: { child_bank_id: string }) {
    let child_bank_id = params?.child_bank_id;

    let { parentBank, childBanks } = this.modelFor(
      'onboarding.select-bank.child'
    ) as ModelFrom<SelectBankChildRoute>;

    let childBank = childBanks.find(bank => bank.id === child_bank_id) as ChildBankModel;
    let childBankInfo = await this.network.get(`/provider/bank/${childBank.provider_bank_id}/info`);

    childBank?.setProperties(childBankInfo.data);

    return { parentBank, childBank, childBanks };
  }

  async afterModel(model: ModelFrom<SelectBankChildCredentialsRoute>, transition: Transition) {
    if (model.childBank.authentication_type === 'WEBVIEW') {
      transition.abort();
      let bridgeFunnelUrl = await this.entryPointRoute.getNewFunnelUrl(model.childBank.id);
      window.location.replace(bridgeFunnelUrl);
    }
  }
}
