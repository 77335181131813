var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
export function captureVariablesValuesFromTranslation(rawTranslation, translation) {
  // Special characters in copy might interfere with Regex
  let escapedRawTranslation = rawTranslation.replace(/[.*+?^$()|[\]\\]/g, '\\$&');
  let captureVariableValuesRe = new RegExp(escapedRawTranslation.replaceAll(/({\w+})/g, '(.*)'));
  let variableValues = translation.match(captureVariableValuesRe);
  variableValues.shift(); // discard first irrelevant match

  return variableValues;
}
export function tokenizeTranslationWithInjectedValues(rawTranslation, variableValues) {
  if (!variableValues.length) return [];
  return rawTranslation.split(/({\w+})/g).map(token => {
    let containsVar = /^{\w+}$/.test(token);
    if (containsVar) {
      let varName = token.slice(1, -1);
      return {
        variable: {
          name: varName,
          value: variableValues.shift()
        }
      };
    } else {
      return {
        text: token
      };
    }
  });
}
let StringVariables = (_class = class StringVariables extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
  }
  get tokens() {
    let {
      text: translation,
      key
    } = this.args;
    let rawTranslation = this.intl.lookup(key);
    let variableValues = captureVariablesValuesFromTranslation(rawTranslation, translation);
    return tokenizeTranslationWithInjectedValues(rawTranslation, variableValues);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { StringVariables as default };