import { M as Model } from "./has-many-d45aa09e";
export { E as Errors, L as LEGACY_SUPPORT, R as ManyArray, P as PromiseBelongsTo, c as PromiseManyArray, a as attr, b as belongsTo, h as hasMany } from "./has-many-d45aa09e";
import { getOwner } from '@ember/application';

/*
    In case someone defined a relationship to a mixin, for example:
    ```
      import Model, { belongsTo, hasMany } from '@ember-data/model';
      import Mixin from '@ember/object/mixin';

      class CommentModel extends Model {
        @belongsTo('commentable', { polymorphic: true }) owner;
      }

      let Commentable = Mixin.create({
        @hasMany('comment') comments;
      });
    ```
    we want to look up a Commentable class which has all the necessary
    relationship meta data. Thus, we look up the mixin and create a mock
    Model, so we can access the relationship CPs of the mixin (`comments`)
    in this case
  */
function modelForMixin(store, normalizedModelName) {
  let owner = getOwner(store);
  let MaybeMixin = owner.factoryFor(`mixin:${normalizedModelName}`);
  let mixin = MaybeMixin && MaybeMixin.class;
  if (mixin) {
    let ModelForMixin = Model.extend(mixin);
    ModelForMixin.__isMixin = true;
    ModelForMixin.__mixin = mixin;
    //Cache the class as a model
    owner.register('model:' + normalizedModelName, ModelForMixin);
  }
  return owner.factoryFor(`model:${normalizedModelName}`);
}

/**
  @module @ember-data/model
*/

/**
  @method diffArray
  @internal
  @param {Array} oldArray the old array
  @param {Array} newArray the new array
  @return {hash} {
      firstChangeIndex: <integer>,  // null if no change
      addedCount: <integer>,        // 0 if no change
      removedCount: <integer>       // 0 if no change
    }
*/
function diffArray(oldArray, newArray) {
  const oldLength = oldArray.length;
  const newLength = newArray.length;
  const shortestLength = Math.min(oldLength, newLength);
  let firstChangeIndex = null; // null signifies no changes

  // find the first change
  for (let i = 0; i < shortestLength; i++) {
    // compare each item in the array
    if (oldArray[i] !== newArray[i]) {
      firstChangeIndex = i;
      break;
    }
  }
  if (firstChangeIndex === null && newLength !== oldLength) {
    // no change found in the overlapping block
    // and array lengths differ,
    // so change starts at end of overlap
    firstChangeIndex = shortestLength;
  }
  let addedCount = 0;
  let removedCount = 0;
  if (firstChangeIndex !== null) {
    // we found a change, find the end of the change
    let unchangedEndBlockLength = shortestLength - firstChangeIndex;
    // walk back from the end of both arrays until we find a change
    for (let i = 1; i <= shortestLength; i++) {
      // compare each item in the array
      if (oldArray[oldLength - i] !== newArray[newLength - i]) {
        unchangedEndBlockLength = i - 1;
        break;
      }
    }
    addedCount = newLength - unchangedEndBlockLength - firstChangeIndex;
    removedCount = oldLength - unchangedEndBlockLength - firstChangeIndex;
  }
  return {
    firstChangeIndex,
    addedCount,
    removedCount
  };
}
export { Model, modelForMixin as _modelForMixin, diffArray };