import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import ActionModel from 'qonto-switching-bank-js/models/action';
import MigrationModel from 'qonto-switching-bank-js/models/migration';
import ActionService from 'qonto-switching-bank-js/services/action';

interface ActionHeaderDetailsArgs {
  action: ActionModel;
  migration: MigrationModel;
  shouldDisplayDemoData: boolean;
}

export default class ActionHeaderDetailsComponent extends Component<ActionHeaderDetailsArgs> {
  @service('action') declare actionService: ActionService;

  get iconName() {
    return this.args.action.category || 'other_expense';
  }

  get status() {
    return this.args.action.status;
  }

  @action async toggleFinished() {
    await this.actionService.editActionStatus(
      this.args.action,
      this.status === 'finished' ? 'created' : 'finished',
      this.args.shouldDisplayDemoData
    );
  }

  @action async toggleIgnored() {
    await this.actionService.editActionStatus(
      this.args.action,
      this.status === 'ignored' ? 'created' : 'ignored',
      this.args.shouldDisplayDemoData
    );
  }
}
