import type Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class NotSupportedRoute extends Route {
  @service declare store: Store;

  async model() {
    let migration = await this.store.queryRecord('migration', {});

    return { migration };
  }
}
