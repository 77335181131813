import { Category, TransactionMethod, TransactionType } from 'qonto-switching-bank-js/types/action';

export interface CategoryOption {
  value: Category;
  label: string;
}

export interface TypeOption {
  value: TransactionMethod;
  label: string;
}

export const formLabels = (isCreate: boolean) => {
  let startKey = `dashboard.${isCreate ? 'create_form' : 'edit_form'}`;

  return {
    cta: {
      cancel: `${startKey}.CTA_cancel`,
      confirm: `${startKey}.${isCreate ? 'CTA_create' : 'CTA_save'}`,
    },
    field: {
      title: `${startKey}.title_field.title`,
      category: `${startKey}.category_field.title`,
      type: `${startKey}.type_field.title`,
      note: `${startKey}.note_field.title`,
    },
    placeholder: {
      title: `${startKey}.title_field.placeholder`,
      category: `${startKey}.category_field.placeholder`,
      type: `${startKey}.type_field.placeholder`,
      note: `${startKey}.note_field.placeholder`,
    },
  };
};

export const actionCategories = (isCreate: boolean) => {
  let startKey = `dashboard.${isCreate ? 'create_form' : 'edit_form'}.category_list`;

  let result: CategoryOption[] = [
    { value: 'atm', label: `${startKey}.atm` },
    { value: 'fees', label: `${startKey}.fees` },
    { value: 'finance', label: `${startKey}.finance` },
    { value: 'hotel_and_lodging', label: `${startKey}.hotel_and_lodging` },
    { value: 'insurance', label: `${startKey}.insurance` },
    { value: 'legal_and_accounting', label: `${startKey}.legal_and_accounting` },
    { value: 'logistics', label: `${startKey}.logistics` },
    { value: 'marketing', label: `${startKey}.marketing` },
    { value: 'office_rental', label: `${startKey}.office_rental` },
    { value: 'office_supply', label: `${startKey}.office_supply` },
    { value: 'online_service', label: `${startKey}.online_service` },
    { value: 'other', label: `${startKey}.other` },
    { value: 'restaurant_and_bar', label: `${startKey}.restaurant_and_bar` },
    { value: 'salary', label: `${startKey}.salary` },
    { value: 'sales', label: `${startKey}.sales` },
    { value: 'tax', label: `${startKey}.tax` },
    { value: 'transport', label: `${startKey}.transport` },
    { value: 'utility', label: `${startKey}.utility` },
  ];
  if (!isCreate) {
    result.push({ value: 'pending', label: `${startKey}.pending` });
  }
  return result;
};

export const actionTypes = (transactionType: TransactionType) => {
  let result: TypeOption[] = [
    { value: 'transfer', label: 'dashboard.card.category.transfers' },
    { value: 'card', label: 'dashboard.card.category.cards' },
    { value: 'other', label: 'dashboard.card.category.others' },
  ];
  if (transactionType === 'debit') {
    result.push({ value: 'b2b', label: 'dashboard.card.category.mandates' });
  }
  return result;
};
