import { inject as service } from '@ember/service';

import ApplicationAdapter from 'qonto-switching-bank-js/adapters/application';

export default class ActionAdapter extends ApplicationAdapter {
  @service network;

  async query(_store, _type, query) {
    let { migration_id, transaction_type } = query;

    let response = await this.network.get(`/migrations/${migration_id}/actions`, {
      params: { transaction_type },
    });

    return { action: response.data };
  }

  async createRecord(_store, _type, snapshot) {
    let { migration_id } = snapshot.record;

    let response = await this.network.post(`/migrations/${migration_id}/actions`, {
      action_data: snapshot.attributes(),
    });

    return { action: response.data };
  }

  async updateRecord(_store, _type, snapshot) {
    let { migration_id } = snapshot.record;

    let response = await this.network.patch(`/migrations/${migration_id}/actions/${snapshot.id}`, {
      action_data: this.getDirtyAttributes(snapshot.record),
    });

    return { action: response.data };
  }

  async deleteRecord(_store, _type, snapshot) {
    let { migration_id } = snapshot.record;

    let response = await this.network.delete(`/migrations/${migration_id}/actions/${snapshot.id}`);

    return { action: response.data };
  }
}
