import Component from '@glimmer/component';

interface MigrationStepperArgs {
  currentStep: number;
}

export default class MigrationStepperComponent extends Component<MigrationStepperArgs> {
  get steps() {
    let { currentStep } = this.args;

    return Array.from({ length: 4 }, (_v: unknown, index: number) => {
      let position = index + 1;

      if (position < currentStep) {
        return { position, status: 'done' };
      } else if (position === currentStep) {
        return { position, status: 'doing' };
      } else {
        return { position, status: 'todo' };
      }
    });
  }
}
