import { assert } from '@ember/debug';
import { htmlSafe, isHTMLSafe } from '@ember/template';
import Component from '@glimmer/component';
const ALLOWED_TYPES = ['info', 'success', 'error'];
export default class Toast extends Component {
  constructor() {
    super(...arguments);
    let {
      type
    } = this.args.content;
    assert('<Toast /> must have a @type', type);
    assert('<Toast /> can only have those types: info, success or error', ALLOWED_TYPES.includes(type));
  }
  get iconName() {
    switch (this.args.content.type) {
      case 'info':
        return 'icon_info_rounded_filled';
      case 'success':
        return 'icon_checkmark_rounded_filled';
      case 'error':
        return 'icon_error_filled';
    }
  }
  get message() {
    let {
      message
    } = this.args.content;
    return isHTMLSafe(message) ? message : htmlSafe(message);
  }
}