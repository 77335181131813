import Service, { inject as service } from '@ember/service';

import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import * as Sentry from '@sentry/browser';
import IntlService from 'ember-intl/services/intl';

import ENV from 'qonto-switching-bank-js/config/environment';
import CookiesService from 'qonto-switching-bank-js/types/cookies-service';

const { defaultLocale: DEFAULT_LOCALE, supportedLocales: SUPPORTED_LOCALES } = ENV;

async function polyfillGetCanonicalLocales() {
  if (shouldPolyfillGetCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
}

async function polyfillLocale() {
  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
}

async function polyfillPluralRules(locale: string) {
  if (shouldPolyfillPluralRules(locale)) {
    await import('@formatjs/intl-pluralrules/polyfill-force');
    await import(`@formatjs/intl-pluralrules/locale-data/${locale}`);
  }
}

export default class LocaleManagerService extends Service {
  @service declare cookies: CookiesService;
  @service declare intl: IntlService;
  @service zendeskWidget;

  locale?: string = undefined;

  getBrowserLocale() {
    let navigatorLanguage = navigator.language;

    return navigatorLanguage.toLowerCase().split(/[-_]/)[0];
  }

  isLocaleSupported(locale: string) {
    return SUPPORTED_LOCALES.includes(locale);
  }

  getLocale(userLocale?: string) {
    let cookieLocale = this.cookies.read('_qonto-locale');
    let browserLocale = this.getBrowserLocale();

    if (userLocale && this.isLocaleSupported(userLocale)) {
      return userLocale;
    } else if (this.isLocaleSupported(cookieLocale)) {
      return cookieLocale;
    } else if (this.isLocaleSupported(browserLocale)) {
      return browserLocale;
    } else {
      return DEFAULT_LOCALE;
    }
  }

  async setLocale(userLocale?: string) {
    let locale = this.getLocale(userLocale);

    if (locale !== this.locale) {
      try {
        await polyfillGetCanonicalLocales();
        await polyfillLocale();
        await polyfillPluralRules(locale);
      } catch {
        Sentry.captureMessage(`Intl polyfill failed with ${locale} locale`);
      }

      this.intl.setLocale(locale);
      this.zendeskWidget.localeChange(locale);
      this.locale = locale;
    }
  }
}
