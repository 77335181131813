import { assert } from '@ember/debug';
import Component from '@glimmer/component';
const ALLOWED_COLORS = ['primary-a', 'primary-b', 'danger'];
const ALLOWED_SIZES = ['small', 'medium', 'large'];
export default class BadgeStatusComponent extends Component {
  get color() {
    let color = this.args.color ?? 'primary-b';
    assert('<Spinner /> can only have those colors: primary-a, primary-b, danger', ALLOWED_COLORS.includes(color));
    return color;
  }
  get size() {
    let size = this.args.size ?? 'medium';
    assert('<Spinner /> can only have those sizes: small, medium, large', ALLOWED_SIZES.includes(size));
    return size;
  }
}