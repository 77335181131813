import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardTypeActionRoute extends Route {
  @service store;

  queryParams = {
    page: {
      refreshModel: true,
    },
  };

  async model(params) {
    let { actions, migration, shouldDisplayDemoData } = this.modelFor('dashboard.type');
    let { action_id, page } = params;
    let action = actions.find(action => action.id === action_id);
    let transactions;

    if (shouldDisplayDemoData) {
      transactions = action.transactions;
    } else {
      transactions = await this.store.query('transaction', {
        filter: { action_id, page },
      });
    }

    return { action, migration, transactions, shouldDisplayDemoData };
  }
}
