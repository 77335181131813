function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { DEBUG } from '@glimmer/env';
import { ARGS_SET } from './component';
/**
 * This factory function returns a component manager class with common behavior
 * that can be extend to add Glimmer.js- or Ember.js-specific functionality. As
 * these environments converge, the need for two component manager
 * implementations (and thus this factory) should go away.
 */
export default function BaseComponentManager(setOwner, getOwner, capabilities) {
  return class {
    static create(attrs) {
      let owner = getOwner(attrs);
      return new this(owner);
    }
    constructor(owner) {
      _defineProperty(this, "capabilities", capabilities);
      setOwner(this, owner);
    }
    createComponent(ComponentClass, args) {
      if (DEBUG) {
        ARGS_SET.set(args.named, true);
      }
      return new ComponentClass(getOwner(this), args.named);
    }
    getContext(component) {
      return component;
    }
  };
}