import { buildTask as _buildTask } from "ember-concurrency/-private/async-arrow-runtime";
var _dec, _class, _descriptor, _descriptor2, _descriptor3;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable @qonto/requires-task-function-syntax, ember/no-array-prototype-extensions, ember/no-classic-components, unicorn/no-instanceof-array */
import { A } from '@ember/array';
import Component from '@ember/component';
import { action } from '@ember/object';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { all, dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import { getFileExtension } from '../utils/format-file-size';
import LocalFile from '../utils/local-file';

/**
  A file input component. It is used to compose higher level upload file components. For instance: `q-avatar-input` and `x-dropzone`.

  ⚠ this is a deprecated component ⚠. Please use instead `x-upload-file-beta` whenever you can. At some point, we'll remove `x-file-upload` and rename the so-called beta.

  ```hbs
    {{#x-upload-file
      filesLimit=1
      maxSize=maxSize
      multiple=multiple
      extensions=extensions
      files=localFiles
      addFile=(action "handleAddFile")
      triggerErrorFile=(action (mut readFileError))
    }}
      {{x-button kind="secondary" title='Here upload your files'}}
    {{/x-upload-file}}
  ```

  @class XUploadFile
  @public
*/
let XUploadFile = (_dec = reads('files.firstObject'), (_class = class XUploadFile extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "tagName", '');
    _initializerDefineProperty(this, "intl", _descriptor, this);
    _initializerDefineProperty(this, "strings", _descriptor2, this);
    _initializerDefineProperty(this, "file", _descriptor3, this);
    _defineProperty(this, "input", void 0);
    _defineProperty(this, "onDropTask", _buildTask(() => ({
      context: this,
      generator: function* (files) {
        if (!this.disabled) {
          return yield this.readTask.perform(files);
        }
      }
    }), null, "onDropTask", "drop"));
  }
  get fileType() {
    let contentType = this.file?.fileContentType;
    if (contentType && contentType.includes('image')) {
      return 'icon_picture';
    }
    return 'icon_invoices';
  }
  get accept() {
    let {
      extensions
    } = this;
    if (!extensions) return null;
    return extensions.split(',').map(ext => `.${ext}`).join(',');
  }
  init() {
    super.init(...arguments);

    /**
      Allowed extensions. Unallowed extensions will be greyed in the browser file picker.
      This is a (pretty non-standard) comma-delimited string. For instance: 'jpg,jpeg,png'
      @argument extensions
      @type String?
    */
    this.extensions = this.extensions || null;

    /**
      Maximum number of files that may be added by the user.
      It is required as it takes by default 0.
      @argument maxSize
      @type Number
    */
    this.filesLimit = this.filesLimit || 0;

    /**
      File max size. An integer.
      It is required as it takes by default 0.
      @argument maxSize
      @type Number
    */
    this.maxSize = this.maxSize || 0;

    /**
      File minimum size. An integer.
      Default value is 0.
      @argument minSize
      @type Number
    */
    this.minSize = this.minSize || 0;

    /**
      Unlocks browser's limit to more than 1 file to pick.
      This has to be set manually along with `this.filesLimit` option.
      Defaults to `true`.
      @argument multiple
      @type Boolean?
    */
    this.multiple = this.multiple || true;

    /**
      Files attached to file input.
      Defaults to empty EmberArray.
      @argument files
      @type EmberArray?
    */
    this.files = this.files || A();

    /**
      If implemented, bubbles the uploaded file the parent component.
      @argument addFile
      @type Function?
    */
    this.addFile = this.addFile || (file => this.files.pushObject(file));

    /**
      If implemented, bubbles error to parent component.
      @argument triggerErrorFile
      @type Function?
    */
    this.triggerErrorFile = this.triggerErrorFile || (err => err);
  }
  onClick(event) {
    let {
      input
    } = this;
    if (event.target !== input && !this.disabled) {
      input.click();
    }
  }
  onKeyPress(event) {
    let {
      input
    } = this;
    if (event.code === 'Space' && event.target !== input && !this.disabled) {
      input.click();
    }
  }
  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }
  onDrop(event) {
    event.preventDefault();
    return this.onDropTask.perform(event.dataTransfer.files);
  }
  setInputElement(element) {
    this.input = element;
  }
  handleInputChange(event) {
    let input = event.target;
    // in testing mode, `uploadFile()` helper is available
    let localFiles = this.input.files;
    if (localFiles) {
      // eslint-disable-next-line ember/no-runloop
      run(() => {
        // eslint-disable-next-line ember-concurrency/no-perform-without-catch
        this.readTask.perform(localFiles);
        input.value = null;
      });
    }
  }
  _hasUnallowedFileExtension(fileName) {
    let {
      extensions
    } = this;
    if (extensions) {
      let extension = getFileExtension(fileName);
      if (typeof extensions === 'string') {
        extensions = extensions.split(',');
      }
      extensions.map(ext => ext && ext.toLowerCase());
      return !extensions.includes(extension);
    }
    return false;
  }
  *readTask(data) {
    this.triggerErrorFile(null);
    try {
      yield this._readTask.perform(data);
      return this.afterMultipleUploads?.();
    } catch (error) {
      let {
        extension,
        message
      } = error;
      if (message) {
        let maxSize = this.strings.formatFileSize(this.maxSize);
        let allowedExtensions = this.extensions || 'pdf,jpg,png';
        let msg = this.strings.t(error.message, {
          params: {
            maxSize,
            extension: extension?.toUpperCase(),
            extensions: new Intl.ListFormat(this.intl.locale, {
              type: 'disjunction'
            }).format(allowedExtensions.split(',').map(extension => extension.toUpperCase())),
            filesLimit: this.filesLimit
          }
        });
        this.triggerErrorFile(msg);
      } else {
        throw error;
      }
    }
  }
  *_readTask(data) {
    let localLength = this.files?.length || 0;
    if (data.length + localLength > this.filesLimit) {
      throw new Error('errors.files_limit');
    }
    if (data instanceof FileList) {
      let copy = Array.from(data);
      return all(copy.map(data => this._readTask.perform(data)));
    }
    if (data instanceof Array) {
      return all(data.map(data => this._readTask.perform(data)));
    }
    if (this.files?.length === this.filesLimit) {
      throw new Error('errors.files_limit');
    }
    if (this._hasUnallowedFileExtension(data.name)) {
      let error = new Error('errors.wrong_file_extension');
      error.extension = getFileExtension(data.name);
      throw error;
    }
    if (data.size > this.maxSize) {
      throw new Error('errors.file_too_big');
    }
    if (data.size < this.minSize) {
      throw new Error('errors.file_too_small');
    }
    let localFile = yield LocalFile.read(data);

    // Quick fix to avoid conflicts on the backend if the filename equal `large.png`
    // See: https://gitlab.qonto.co/qonto/qonto-auth/-/blob/master/app/uploaders/avatar_uploader.rb#L30
    if (localFile.fileContentType.includes('image')) {
      localFile.fileName = `image-${localFile.fileName}`;
    }
    this.addFile(localFile);
    return localFile;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "strings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "file", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "onClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyPress", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyPress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragOver", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onDragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDrop", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onDrop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInputElement", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setInputElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInputChange", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInputChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "readTask", [task, waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "readTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_readTask", [task, waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "_readTask"), _class.prototype)), _class));
export { XUploadFile as default };