import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import ToastFlashMessagesService from 'app/services/toast-flash-messages';
import { LottiePlayer } from 'design-system-kit';
import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import Router from 'qonto-switching-bank-js/router';
import NeedsHumanActionRoute from 'qonto-switching-bank-js/routes/errors/needs-human-action/route';
import EntryPointRouteService from 'qonto-switching-bank-js/services/entry-point-route';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

export default class NeedsHumanActionController extends Controller {
  lottiePlayer = LottiePlayer;
  @service declare entryPointRoute: EntryPointRouteService;
  @service('migration') declare migrationService: MigrationService;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service declare intl: IntlService;
  @service declare router: Router;

  declare model: ModelFrom<NeedsHumanActionRoute>;

  @tracked checked = false;

  @action toggleCheckbox() {
    this.checked = !this.checked;
  }

  get isDisabled() {
    return !this.checked || this.refreshMigrationTask.isRunning;
  }

  @task refreshMigrationTask = taskFor(function* (
    this: NeedsHumanActionController
  ): TaskGenerator<void> {
    let migration = yield this.migrationService.refreshMigration(this.model.migration.id);

    if (migration.hasError) {
      this.toastFlashMessages.toastInfo(
        this.intl.t(migration.extendedProviderConnectionStatusWordings.toaster, {
          bank_name: migration.bank_name,
        })
      );

      this.model.migration = migration;
    } else {
      let entryPointRoute: string = yield this.entryPointRoute.getEntryPoint(migration);
      this.router.transitionTo(entryPointRoute);
    }
  });
}
