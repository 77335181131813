export default {
  "pricing-card": "_pricing-card_d4m7y7",
  "is-active": "_is-active_d4m7y7",
  "is-selected": "_is-selected_d4m7y7",
  "card-badge": "_card-badge_d4m7y7",
  "card-title": "_card-title_d4m7y7",
  "card-description": "_card-description_d4m7y7",
  "card-price": "_card-price_d4m7y7",
  "card-price-strikethrough": "_card-price-strikethrough_d4m7y7",
  "card-price-heading": "_card-price-heading_d4m7y7",
  "card-price-billing": "_card-price-billing_d4m7y7",
  "card-recurrence": "_card-recurrence_d4m7y7",
  "card-additional-price-description": "_card-additional-price-description_d4m7y7",
  "card-link": "_card-link_d4m7y7",
  "card-billing": "_card-billing_d4m7y7",
  "card-price-description": "_card-price-description_d4m7y7",
  "card-price-description-strikethrough": "_card-price-description-strikethrough_d4m7y7",
  "card-feature-list": "_card-feature-list_d4m7y7",
  "card-feature-check": "_card-feature-check_d4m7y7",
  "card-feature-item": "_card-feature-item_d4m7y7",
  "card-feature-badge": "_card-feature-badge_d4m7y7",
  "feature-badge": "_feature-badge_d4m7y7",
  "custom-price": "_custom-price_d4m7y7"
};