/**
 * All credits go to DockYard
 *
 * See the source file here: https://github.com/DockYard/ember-composable-helpers/blob/master/addon/helpers/optional.js
 */

import { helper } from '@ember/component/helper';
const identity = i => i;
export function optional([maybeFn]) {
  if (typeof maybeFn === 'function') {
    return maybeFn;
  }
  return identity;
}
export default helper(optional);