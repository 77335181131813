import Component from '@glimmer/component';
export default class AddressSearchInpuField extends Component {
  get hasError() {
    // for backwards compatibility, when providing an errorMessage
    if (this.args.errorMessage) {
      return true;
    }
    let {
      message,
      messageLevel
    } = this.args;
    return Boolean(message) && messageLevel === 'error';
  }
}