import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import Router from 'qonto-switching-bank-js/router';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

import SelectAccountsRoute from './route';

export default class SelectAccountsController extends Controller {
  @service declare network: NetworkService;
  @service declare router: Router;
  @service declare segment: SegmentService;

  declare model: ModelFrom<SelectAccountsRoute>;

  @tracked selectedAccounts: { [id: string]: boolean } = {};

  get bankLogoUrl() {
    return this.model.accounts[0]?.bank_logo_url;
  }

  get isDisabled() {
    return (
      !Object.values(this.selectedAccounts).includes(true) ||
      this.updateSelectedAccountsTask.isRunning
    );
  }

  @action toggleAccounts(id: string) {
    if (this.selectedAccounts[id] === undefined) {
      this.selectedAccounts = { ...this.selectedAccounts, [id]: true };
    } else {
      this.selectedAccounts = { ...this.selectedAccounts, [id]: !this.selectedAccounts[id] };
    }
  }

  @task updateSelectedAccountsTask = taskFor(function* (
    this: SelectAccountsController
  ): TaskGenerator<void> {
    yield this.network.put('/accounts', { account: { ids: Object.keys(this.selectedAccounts) } });

    this.segment.track('bankswitch_selectaccounts_completed');
    this.router.transitionTo('onboarding.choose-dashboard-access');
  });
}
