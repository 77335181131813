import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import KeyboardFocusService from 'qonto-switching-bank-js/services/keyboard-focus';

export default class TabPageItem extends Component {
  @service declare keyboardFocus: KeyboardFocusService;

  @tracked focused = false;

  @action setFocused(focused: boolean) {
    this.focused = focused;
  }
}
