var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable ember/no-array-prototype-extensions, ember/no-computed-properties-in-native-classes */
import { action, computed } from '@ember/object';
import { and, bool, not, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
let QFile = (_dec = computed('args.file'), _dec2 = bool('file.errors.firstObject'), _dec3 = not('hasError'), _dec4 = bool('file.watcher.progress'), _dec5 = not('isDeleting'), _dec6 = and('hasProgress', 'hasNoError'), _dec7 = not('file.watcher'), _dec8 = or('isSuccessfullyUploaded', 'hasError'), _dec9 = and('deletable', 'isUploaded', 'isNotDeleting'), _dec10 = computed('file.fileSize'), (_class = class QFile extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "strings", _descriptor, this);
    _defineProperty(this, "_isDeleting", false);
    _initializerDefineProperty(this, "hasError", _descriptor2, this);
    _initializerDefineProperty(this, "hasNoError", _descriptor3, this);
    _initializerDefineProperty(this, "hasProgress", _descriptor4, this);
    _initializerDefineProperty(this, "isNotDeleting", _descriptor5, this);
    _initializerDefineProperty(this, "isUploading", _descriptor6, this);
    _initializerDefineProperty(this, "isSuccessfullyUploaded", _descriptor7, this);
    _initializerDefineProperty(this, "isUploaded", _descriptor8, this);
    _initializerDefineProperty(this, "isDeletable", _descriptor9, this);
  }
  get deletable() {
    return this.args.deletable !== false;
  }
  get showFileIcon() {
    return this.args.showFileIcon !== false;
  }
  get showFileSize() {
    return this.args.showFileSize !== false;
  }
  get file() {
    return this.args.file || null;
  }
  get fileSizeFormatted() {
    let size = this.file?.fileSize;
    if (size) {
      return this.strings.formatFileSize(size);
    }
    return null;
  }
  handleCancel() {
    this.args.cancel?.(this.file);
  }
  handlePreview() {
    this.args.preview?.(this.file);
  }
  handleDelete() {
    if (!this.args.delete) return;
    let promise = this.args.delete(this.file);
    this._isDeleting = true;
    if (!promise) {
      this._isDeleting = false;
      return;
    }
    return promise.catch(() => {
      this._isDeleting = false;
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "strings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "file", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "file"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasError", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasNoError", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hasProgress", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isNotDeleting", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isUploading", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isSuccessfullyUploaded", [_dec7], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isUploaded", [_dec8], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isDeletable", [_dec9], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "fileSizeFormatted", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "fileSizeFormatted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCancel", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePreview", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDelete", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDelete"), _class.prototype)), _class));
export { QFile as default };