import Model, { attr } from '@ember-data/model';
import { get } from '@ember/object';

import {
  extendedProviderConnectionStatusBody,
  extendedProviderConnectionStatusToaster,
} from 'qonto-switching-bank-js/constants/migration';
import {
  Account,
  ActionStats,
  KindOfMigration,
  MigrationStatus,
  Pathway,
  ProviderConnectionStatus,
  ProviderConnectionStatusDetails,
} from 'qonto-switching-bank-js/types/migration';

export default class MigrationModel extends Model {
  @attr('string') declare bank_id: string;
  @attr('string') declare bank_name?: string;
  @attr('string') declare organization_id: string;
  @attr('string') declare status: MigrationStatus;
  @attr('string') declare bank_logo_url: string;
  @attr('string') declare provider_connection_status: ProviderConnectionStatus;
  @attr('string') declare provider_connection_status_details: ProviderConnectionStatusDetails;
  @attr('string') declare extended_provider_connection_status?: string;
  @attr('date') declare template_letter_last_fetched_at?: Date;
  @attr('string') declare pathway: Pathway;
  @attr('date') declare updated_at: Date;
  @attr('string') declare kind: KindOfMigration;
  @attr declare action_stats: ActionStats;
  @attr declare selected_accounts: Account[];
  @attr declare isFixture?: boolean;

  get ibans() {
    return (this.selected_accounts || []).map(account => account.iban).filter(iban => iban);
  }

  get areNoActionsLeft() {
    return (
      get(this.action_stats, 'created_credit_count') === 0 &&
      get(this.action_stats, 'created_debit_count') === 0
    );
  }

  get hasError() {
    return this.provider_connection_status === 'error';
  }

  get extendedProviderConnectionStatusWordings(): { body: string; toaster: string } {
    let errorStatus = this.provider_connection_status_details;
    let extendedStatus = this.extended_provider_connection_status;

    if (errorStatus && extendedStatus) {
      return {
        body: extendedProviderConnectionStatusBody(errorStatus, extendedStatus) || '',
        toaster: extendedProviderConnectionStatusToaster(errorStatus, extendedStatus) || '',
      };
    } else {
      return { body: '', toaster: '' };
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    migration: MigrationModel;
  }
}
