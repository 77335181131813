import Service from '@ember/service';

import * as Sentry from '@sentry/browser';
import axios from 'axios';

import ENV from 'qonto-switching-bank-js/config/environment';
import { apiBaseURL } from 'qonto-switching-bank-js/constants/hosts';

export default class NetworkService extends Service {
  init() {
    super.init(...arguments);
  }

  client() {
    return this.axios;
  }

  get(url, config) {
    return this.getAxios().get(url, config);
  }

  delete(url, config) {
    return this.getAxios().delete(url, config);
  }

  head(url, config) {
    return this.getAxios().head(url, config);
  }

  options(url, config) {
    return this.getAxios().options(url, config);
  }

  post(url, data, config) {
    return this.getAxios().post(url, data, config);
  }

  put(url, data, config) {
    return this.getAxios().put(url, data, config);
  }

  patch(url, data, config) {
    return this.getAxios().patch(url, data, config);
  }

  getHeaders(organizationId) {
    let headers = {
      'X-Qonto-Organization-Id': organizationId,
    };

    if (ENV.isStaging) {
      headers['X-Qonto-Staging-Token'] = ENV.stagingAccessToken;
    }

    return headers;
  }

  getAxios() {
    let urlParams = new URLSearchParams(window.location.search);
    let organizationId = urlParams.get('organizationId') || localStorage.getItem('organizationId');

    let axiosInstance = axios.create({
      baseURL: apiBaseURL,
      headers: this.getHeaders(organizationId),
      withCredentials: true,
    });

    axiosInstance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.response && error.response.status === 500) {
          Sentry.captureMessage('Internal server error 500 when querying the backend', {
            level: 'error',
            extra: {
              error,
            },
          });
          return Promise.reject(error);
        }
        if (error.response && error.response.status === 403) {
          Sentry.captureMessage(
            'Permission issue: The user received a 403 when querying the backend'
          );
          return Promise.reject(error);
        }
        if (
          error.response &&
          error.response.status === 401 &&
          (error.response.data.message || error.response.data.errors) ===
            'Authenticable::InvalidRoleError'
        ) {
          if (
            window.location.pathname === '/tests' || // We do not want to run the `else` on tests
            window.location.pathname === '/error/unauthorized' ||
            window.location.pathname === '/choose-organization' // those two pages already handle unauthorized redirection
          ) {
            return Promise.reject(error);
          } else {
            window.location.pathname = '/error/unauthorized';
            return Promise.reject(error);
          }
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }
}
