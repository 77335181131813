export default {
  "wrapper": "_wrapper_1hs460",
  "checkbox": "_checkbox_1hs460",
  "checkbox-error-label": "_checkbox-error-label_1hs460",
  "disabled": "_disabled_1hs460",
  "label": "_label_1hs460",
  "error": "_error_1hs460",
  "label-wrapper": "_label-wrapper_1hs460",
  "error-label": "_error-label_1hs460",
  "icon": "_icon_1hs460"
};