var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
let QFileMultipart = (_class = class QFileMultipart extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "strings", _descriptor, this);
  }
  get isUploading() {
    return this.args.file.state === 'uploading';
  }
  get fileIcon() {
    let {
      status,
      isProcessing
    } = this.args.file;
    return isProcessing || status ? 'image-receipt' : 'image-placeholder';
  }
  get fileSizeFormatted() {
    let {
      fileSize
    } = this.args.file;
    return fileSize ? this.strings.formatFileSize(fileSize) : null;
  }
  get progress() {
    return this.args.file.progress / 100 || 0;
  }
  get progressStatusFormatted() {
    let progress = this.args.file.progress || 0;
    return `${Math.floor(progress * 100)}%`;
  }
  handleCancel() {
    this.args.cancel?.();
  }
  handleDelete() {
    this.args.delete?.();
  }
  handlePreview() {
    if (!this.isUploading) {
      this.args.preview?.(this.args.file);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "strings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "handleCancel", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDelete", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePreview", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePreview"), _class.prototype)), _class);
export { QFileMultipart as default };