import Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';
import IntlService from 'ember-intl/services/intl';

import MigrationModel from 'qonto-switching-bank-js/models/migration';
import Router from 'qonto-switching-bank-js/router';
import EntryPointRouteService from 'qonto-switching-bank-js/services/entry-point-route';
import FixturesService from 'qonto-switching-bank-js/services/fixtures';
import ModalsService from 'qonto-switching-bank-js/services/modal';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';

import ToastFlashMessagesService from './toast-flash-messages';

export default class MigrationService extends Service {
  @service declare store: Store;
  @service declare modals: typeof ModalsService;
  @service declare network: NetworkService;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare segment: SegmentService;
  @service declare fixtures: FixturesService;
  @service declare entryPointRoute: EntryPointRouteService;

  async refreshMigration(migrationId: string) {
    try {
      await this.network.post(`/migrations/${migrationId}/refresh`);

      return await this.store.queryRecord('migration', {});
    } catch (error) {
      this.toastFlashMessages.defaultToastError();

      return Promise.reject(error);
    }
  }

  async endMigration(migration: MigrationModel) {
    let organizationId = localStorage.getItem('organizationId') as string;
    let organization = await this.store.peekRecord('organization', organizationId);
    let endMigrationWordings = migration.areNoActionsLeft
      ? 'dashboard.overview.popin.end_migration_completed'
      : 'dashboard.overview.popin.end_migration_not_completed';

    let modal = await this.modals.open('confirmation-modal', {
      title: this.intl.t(`${endMigrationWordings}.title`),
      body: this.intl.t(`${endMigrationWordings}.wording`, {
        htmlSafe: true,
        bank_name: migration.bank_name,
        company_name: organization.name,
      }),
      cancel: this.intl.t(`${endMigrationWordings}.cta.cancel`),
      confirm: this.intl.t(`${endMigrationWordings}.cta.end_transfer`),
      confirmColor: migration.areNoActionsLeft ? '' : 'red',
    });

    if (modal === 'confirm') {
      migration.status = 'finished';

      migration
        .save()
        .then(() => {
          this.segment.track(
            migration.areNoActionsLeft
              ? 'bankswitch_overview_migration_completed'
              : 'bankswitch_overview_migration_early_completed',
            { actions_stats: migration.action_stats }
          );
          this.entryPointRoute.getEntryPoint(migration).then(entryPointRoute => {
            this.router.transitionTo(entryPointRoute);
          });
        })
        .catch(error => {
          Sentry.captureMessage(
            `Migration update when ending migration failed | ${error?.message}`
          );
          this.toastFlashMessages.defaultToastError();
        });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    migration: MigrationService;
  }
}
