var _class;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import { action } from '@ember/object';
import Component from '@glimmer/component';
let FilterSelectTrigger = (_class = class FilterSelectTrigger extends Component {
  clear(e) {
    e.stopPropagation();
    this.args.select.actions.select(null);
    if (e.type === 'touchstart') {
      return false;
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "clear", [action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class);
export { FilterSelectTrigger as default };