import ENV from 'qonto-switching-bank-js/config/environment';

const runningOnSubdomain = window.location.href.includes('.staging.qonto.co');
const isAuthenticationShareable = ENV.environment === 'development' && runningOnSubdomain;

let cookieDomain = null;
if (ENV.isStaging) {
  cookieDomain = '.qonto.co';
} else if (ENV.environment === 'production') {
  cookieDomain = '.qonto.com';
} else if (isAuthenticationShareable) {
  cookieDomain = '.staging.qonto.co';
}

export default cookieDomain;
