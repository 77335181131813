import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

type NoteTextAreaArgs = {
  maxLength: number;
  update: (text: string) => void;
  value: string | undefined;
};

export default class NoteTextAreaComponent extends Component<NoteTextAreaArgs> {
  get actualLength() {
    return this.args.value?.length || 0;
  }

  @action handleInput(event: HTMLElementEvent<HTMLTextAreaElement>) {
    let value = event.target.value;
    let maxLength = this.args.maxLength;

    if (isEmpty(maxLength) || value.length <= maxLength) {
      this.args.update(value);
    }
  }
}
