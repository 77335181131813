import { assert } from '@ember/debug';
import Component from '@glimmer/component';
export default class WarningBanner extends Component {
  constructor() {
    super(...arguments);
    let {
      description,
      headingLevel,
      title
    } = this.args;
    assert('<WarningBanner/> must have a @description', description);
    assert('<WarningBanner/> must have a @title', title);
    assert('<WarningBanner/> must have a @headingLevel', headingLevel);
  }
}