var _class;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
/* eslint-disable @qonto/requires-task-function-syntax */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
let FullscreenCenterComponent = (_class = class FullscreenCenterComponent extends Component {
  *confirmTask(event) {
    // it might be a form submission triggering this, we need to prevent default behaviour
    event?.preventDefault();
    let {
      confirmTask,
      ...data
    } = this.args.data;
    if (confirmTask) {
      yield confirmTask.linked().perform(this.args.close, data);
    } else {
      this.args.close('confirm');
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "confirmTask", [task], Object.getOwnPropertyDescriptor(_class.prototype, "confirmTask"), _class.prototype)), _class);
export { FullscreenCenterComponent as default };