var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { classify } from '@ember/string';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import { next, cancel } from '@ember/runloop';
import { action, computed } from '@ember/object';

/**
 * ARGS
 *
 * flash: FlashObject
 * messageStyle?: 'bootstrap' | 'foundation'
 * messageStylePrefix?: string
 */
let FlashMessage = (_dec = computed('args.flash.exiting'), _dec2 = computed('messageStyle'), _dec3 = computed('args.flash.type', 'messageStylePrefix'), _dec4 = computed('args.flash.type'), _dec5 = computed('args.flash.{showProgress,timeout}'), (_class = class FlashMessage extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "active", _descriptor, this);
    _initializerDefineProperty(this, "pendingSet", _descriptor2, this);
    _initializerDefineProperty(this, "_mouseEnterHandler", _descriptor3, this);
    _initializerDefineProperty(this, "_mouseLeaveHandler", _descriptor4, this);
  }
  get messageStyle() {
    return this.args.messageStyle ?? 'bootstrap';
  }
  get showProgress() {
    return this.args.flash.showProgress;
  }
  get notExiting() {
    return !this.exiting;
  }
  get showProgressBar() {
    return this.showProgress && this.notExiting;
  }
  get exiting() {
    return this.args.flash.exiting;
  }
  get messageStylePrefix() {
    return this.args.messageStylePrefix ?? this._defaultMessageStylePrefix;
  }
  get _defaultMessageStylePrefix() {
    const isFoundation = this.messageStyle === 'foundation';
    return isFoundation ? 'alert-box ' : 'alert alert-';
  }
  get alertType() {
    const flashType = this.args.flash.type || '';
    const prefix = this.messageStylePrefix;
    return `${prefix}${flashType}`;
  }
  get flashType() {
    return classify(this.args.flash.type || '');
  }
  get progressDuration() {
    if (!this.args.flash?.showProgress) {
      return false;
    }
    const duration = this.args.flash?.timeout || 0;
    return htmlSafe(`transition-duration: ${duration}ms`);
  }
  _mouseEnter() {
    if (isPresent(this.args.flash)) {
      this.args.flash.preventExit();
    }
  }
  _mouseLeave() {
    if (isPresent(this.args.flash) && !this.args.flash.exiting) {
      this.args.flash.allowExit();
    }
  }
  _destroyFlashMessage() {
    if (this.args.flash) {
      this.args.flash.destroyMessage();
    }
  }
  onClick() {
    const destroyOnClick = this.args.flash?.destroyOnClick ?? true;
    if (destroyOnClick) {
      this._destroyFlashMessage();
    }
  }
  onClose() {
    this._destroyFlashMessage();
  }
  onDidInsert(element) {
    const pendingSet = next(this, () => {
      this.active = true;
    });
    this.pendingSet = pendingSet;
    this._mouseEnterHandler = this._mouseEnter;
    this._mouseLeaveHandler = this._mouseLeave;
    element.addEventListener('mouseenter', this._mouseEnterHandler);
    element.addEventListener('mouseleave', this._mouseLeaveHandler);
  }
  onWillDestroy(element) {
    element.removeEventListener('mouseenter', this._mouseEnterHandler);
    element.removeEventListener('mouseleave', this._mouseLeaveHandler);
    cancel(this.pendingSet);
    this._destroyFlashMessage();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pendingSet", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_mouseEnterHandler", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_mouseLeaveHandler", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "exiting", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "exiting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_defaultMessageStylePrefix", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_defaultMessageStylePrefix"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alertType", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "alertType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flashType", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "flashType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "progressDuration", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "progressDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_mouseEnter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_mouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_mouseLeave", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_mouseLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWillDestroy", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onWillDestroy"), _class.prototype)), _class));
export { FlashMessage as default };