import { DEFAULT_PREFERRED_COUNTRY_CODES } from '@qonto/ui-kit/constants/countries';
export function makeSortByPreferredCountries(preferredCountries = DEFAULT_PREFERRED_COUNTRY_CODES) {
  return function sortByPreferredCountries(a, b) {
    let aIndex = preferredCountries.indexOf(a.code);
    let bIndex = preferredCountries.indexOf(b.code);
    if (aIndex === -1 && bIndex !== -1) {
      return 1;
    }
    if (aIndex !== -1 && bIndex === -1) {
      return -1;
    }
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    return a.name - b.name;
  };
}