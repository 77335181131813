function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Component from '@ember/component';
import templateLayout from '../../templates/components/power-calendar/nav';
export default class extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "layout", templateLayout);
    _defineProperty(this, "tagName", '');
    _defineProperty(this, "unit", 'month');
    _defineProperty(this, "format", 'MMMM YYYY');
  }
}