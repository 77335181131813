export default {
  "selector": "_selector_vc10lf",
  "selector--classic": "_selector--classic_vc10lf",
  "selector--add": "_selector--add_vc10lf",
  "selector--has-error": "_selector--has-error_vc10lf",
  "selector--disabled": "_selector--disabled_vc10lf",
  "icon": "_icon_vc10lf _icon_1ju4e7",
  "header": "_header_vc10lf _header_1ju4e7",
  "title": "_title_vc10lf _title_1ju4e7",
  "subtitle": "_subtitle_vc10lf _subtitle_1ju4e7",
  "content": "_content_vc10lf ml-24",
  "action-icon": "_action-icon_vc10lf"
};