import Store from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import ParentBankModel from 'qonto-switching-bank-js/models/parent-bank';
import Router from 'qonto-switching-bank-js/router';
import { ModelFrom } from 'qonto-switching-bank-js/utils/model-from';

import SelectBankRoute from '../route';

export default class SelectBankChildRoute extends Route {
  @service declare router: Router;
  @service declare store: Store;

  async model(params: { parent_bank_id: string }) {
    let parent_bank_id = params?.parent_bank_id;
    let childBanks = await this.store.query('child-bank', { parent_bank_id });
    let parentBanks = this.modelFor('onboarding.select-bank') as ModelFrom<SelectBankRoute>;

    return {
      parentBank: parentBanks.find(bank => bank.id === parent_bank_id) as ParentBankModel,
      childBanks,
    };
  }

  @action error(error: { response: { status: number } }) {
    if (error?.response?.status === 404) {
      this.router.transitionTo('errors.maintenance');
    }

    return true;
  }
}
