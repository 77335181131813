function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

import memoize from 'fast-memoize';
import Formatter from './-base';
const DATE_TIME_OPTIONS = ['localeMatcher', 'formatMatcher', 'timeZone', 'hour12', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName', 'hourCycle'];

/**
 * @private
 * @hide
 */
export default class FormatDateTime extends Formatter {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "createNativeFormatter", memoize((locales, options) => {
      return new Intl.DateTimeFormat(locales, options);
    }));
  }
  get options() {
    return DATE_TIME_OPTIONS;
  }
  format(locale, value, formatOptions) {
    const formatterOptions = this.readOptions(formatOptions);
    this.validateFormatterOptions(locale, formatterOptions);
    const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
    return formatterInstance.format(new Date(value));
  }
}
_defineProperty(FormatDateTime, "type", void 0);