var _class;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import { action } from '@ember/object';
import Component from '@glimmer/component';
let SelectorBase = (_class = class SelectorBase extends Component {
  get localClass() {
    let hasError = this.args.hasError ? 'selector--has-error' : '';
    let disabled = this.args.disabled ? 'selector--disabled' : '';
    return `selector selector--${this.type} ${hasError} ${disabled}`;
  }
  get linkIcon() {
    if (this.args.linkIcon) {
      return this.args.linkIcon;
    }
    if (this.args.type === 'add') {
      return 'icon_plus_outlined';
    }
    return 'icon_chevron_right_outlined';
  }
  get type() {
    return this.args.type || 'classic';
  }
  onClick() {
    this.args.onClick?.();
  }
  onFocus() {
    this.args.onFocus?.(...arguments);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "onClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocus", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocus"), _class.prototype)), _class);
export { SelectorBase as default };