import { assert } from '@ember/debug';
import Component from '@glimmer/component';
export default class UploaderFileComponent extends Component {
  constructor() {
    super(...arguments);
    let fileName = this.args.file?.filename;
    assert('You must pass @file with a valid filename', fileName);
  }
  get asLink() {
    return this.args.asLink ?? true;
  }
}