import Model, { attr } from '@ember-data/model';
import { inject } from '@ember/service';

import IntlService from 'ember-intl/services/intl';

export default class AccountModel extends Model {
  @inject declare intl: IntlService;
  @attr('string') declare bank_logo_url: URL;
  @attr('string') declare name: string;
  @attr('date') declare selected_at: Date;
  @attr('string') declare balance: number;

  get formattedBalance(): string {
    return new Intl.NumberFormat(this.intl.primaryLocale, {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR',
    }).format(this.balance);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
