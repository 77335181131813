import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class TransactionModel extends Model {
  @service intl;
  @attr('string') label;
  @attr('string') date;
  @attr('string') amount;

  get formattedAmount() {
    return (
      new Intl.NumberFormat(this.intl.primaryLocale, { minimumFractionDigits: 2 }).format(
        this.amount
      ) + ' EUR'
    );
  }

  get formattedDate() {
    return new Date(this.date).toLocaleDateString(this.intl.primaryLocale);
  }
}
