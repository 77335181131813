import { next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

import ENV from 'qonto-switching-bank-js/config/environment';

/**
 * Segment manager
 *
 * @class Segment Manager
 * @module qonto/services/segment
 * @extends Ember.Service
 */
export default class Segment extends Service {
  @service metrics;
  @service('migration') migrationService;
  @service store;

  /**
   * Identify the user for segment
   *
   * @public
   * @method identify
   *
   * @param  {Object} params
   * @returns void
   */
  identify(params) {
    if (ENV.environment === 'test') {
      return;
    }
    next(() => {
      this.metrics.identify('Segment', params);
    });
  }

  /**
   * Send a custom event to Segment
   *
   * @public
   * @method track
   *
   * @param  {String} eventName Name of the event sent to segment
   * @param  {Object} [params={}]
   *
   * @returns void
   */
  // TODO : Need to be reworked after DDAU is finished.
  track(eventName, params = {}) {
    if (ENV.environment !== 'test') {
      let segmentEvent = {};
      let organizationId = localStorage.getItem('organizationId');
      let migrations = this.store.peekAll('migration');

      if (organizationId) {
        let organization = this.store.peekRecord('organization', organizationId);

        segmentEvent.organization_id = organizationId;
        segmentEvent.price_plan = organization?.original_price_plan_code;
      }

      if (migrations.length > 0) {
        let migration = migrations.find(migration => !migration.isNew);

        segmentEvent.migration_id = migration?.id;
        segmentEvent.pathway = migration?.pathway;
        segmentEvent.origin_bank = migration?.bank_name;
      }

      next(this.metrics, 'trackEvent', 'Segment', { event: eventName, ...segmentEvent, ...params });
    }
  }

  /**
   * Attach a user to a group
   *
   * @public
   * @method group
   * @param  {Object} params
   *
   * @returns void
   */
  group(params) {
    if (ENV.environment === 'test') {
      return;
    }

    let canUseDOM = Boolean(
      typeof window !== 'undefined' && window.document && window.document.createElement
    );
    let { groupId } = params;
    delete params.groupId;

    next(() => {
      if (canUseDOM && window.analytics) {
        window.analytics.group(groupId, params);
      }
    });
  }

  /**
   * Reset
   *
   * @public
   * @method reset
   * @returns void
   */
  reset() {
    if (ENV.environment === 'test') {
      return;
    }

    let canUseDOM = Boolean(
      typeof window !== 'undefined' && window.document && window.document.createElement
    );

    next(() => {
      if (canUseDOM && window.analytics) {
        window.analytics.reset();
      }
    });
  }
}
