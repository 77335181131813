/* eslint-disable ember/no-runloop */
// import { registerDestructor } from '@ember/destroyable';
import { assert } from '@ember/debug';
import { scheduleOnce } from '@ember/runloop';
import { modifier } from 'ember-modifier';
import { Aligner } from './equalize-height/aligner';
let aligner = new Aligner();

/**
 * Usage:
 *
 * Specify a unique `key` to define elements that's heigh need to be equalized across the document.
 * Use the `disabled` named arg to disable the behavior temorarily.
 *
 * ```hbs
 * <div {{equalize-height key="foo"}}>...</div>
 * ...
 * <div {{equalize-height key="foo"}}>...</div>
 * ```
 *
 * Limitations:
 *
 * - Children of an equalizer must be block level elements. ResizeObserver won't be triggered on
 *   inline elements, and non-Element nodes (such as Text) are ignored as it would throw.
 * - All children must be static - addition or removal of direct descendants are not supported.
 */
export default modifier(function (element, pos, {
  key,
  disabled
}) {
  if (disabled) {
    aligner.untrack(key, element);
    return;
  }
  assert('You must provide the `key=` argument for {{equalize-height}}', Boolean(key));
  aligner.track(key, element);
  return () => {
    aligner.untrack(key, element);

    // Don't schedule when there are no elements left for the same key
    if (aligner.hasElementsForKey(key)) {
      scheduleOnce('afterRender', aligner, aligner.run);
    }
  };
}, {
  eager: false
});