export default function formatBytes(intl, size) {
  let divider, unit;
  if (size < 1e6) {
    divider = 1e3;
    unit = intl.t('ui-kit.kilobyte');
  } else {
    divider = 1e6;
    unit = intl.t('ui-kit.megabyte');
  }
  return Math.floor(size / divider * 100) / 100 + ' ' + unit;
}