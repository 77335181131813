import Service from '@ember/service';
import { isEmpty } from '@ember/utils';
import { Ember } from 'ember';

import ChildBankModel from 'qonto-switching-bank-js/models/child-bank';
import ParentBankModel from 'qonto-switching-bank-js/models/parent-bank';

export default class ParentBankService extends Service {
  parse(parentBanks: Ember.Array<ParentBankModel>) {
    let highlightedBanks = parentBanks
      .filter(parent => parent.display_order)
      .sortBy('display_order');
    let alphabeticMap = parentBanks
      .filter(parent => !parent.display_order)
      .reduce((acc, parentBank) => {
        let group = parentBank.name[0].toUpperCase();
        if (!acc.get(group)) {
          acc.set(group, [parentBank]);
        } else {
          acc.get(group).push(parentBank);
        }
        return acc;
      }, new Map());
    alphabeticMap.forEach((value, key) => {
      alphabeticMap.set(key, value.sortBy('name'));
    });
    if (!isEmpty(highlightedBanks)) {
      alphabeticMap.set('', highlightedBanks);
    }
    alphabeticMap = new Map([...alphabeticMap].sort());
    return alphabeticMap;
  }

  search(banks: Ember.Array<ChildBankModel | ParentBankModel>, searchQuery: string) {
    return banks
      .filter((bank: ChildBankModel | ParentBankModel) =>
        this.toUpperCaseWithoutAccents(bank.name).includes(
          this.toUpperCaseWithoutAccents(searchQuery)
        )
      )
      .sortBy('name');
  }

  toUpperCaseWithoutAccents(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();
  }
}

declare module '@ember/service' {
  interface Registry {
    parentBank: ParentBankService;
  }
}
