var _class2, _descriptor, _descriptor2;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable ember/no-classic-components, ember/no-runloop, ember/require-tagless-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { getFileExtension } from '../utils/format-file-size';
let _class = (_class2 = class _class2 extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
    _initializerDefineProperty(this, "strings", _descriptor2, this);
    _defineProperty(this, "name", guidFor(this));
  }
  get accept() {
    let extensions = this.extensions;
    if (!extensions) return null;
    return extensions.split(',').map(ext => `.${ext}`).join(',');
  }
  init() {
    super.init(...arguments);
    this.extensions = this.extensions || null;
    this.filesLimit = this.filesLimit || 0;
    this.disabled = this.disabled || false;
    this.maxSize = this.maxSize || null;
    this.minSize = this.minSize || 0;
    this.files = this.files || null;
    this.addFile = this.addFile || (() => {});
    this.triggerErrorFile = this.triggerErrorFile || (err => err);
  }
  handleFileAdd(file) {
    next(() => {
      this._read(file).then(() => {
        this.addFile(file);
      }).catch(error => {
        file.queue.remove(file);
        if (error) {
          let maxSize = this.strings.formatFileSize(this.maxSize);
          let {
            extension
          } = error;
          let allowedExtensions = this.extensions || 'pdf,jpg,png';
          let message = this.strings.t(error.message, {
            params: {
              maxSize,
              extension: extension?.toUpperCase(),
              extensions: new Intl.ListFormat(this.intl.locale, {
                type: 'disjunction'
              }).format(allowedExtensions.split(',').map(extension => extension.toUpperCase())),
              filesLimit: this.filesLimit
            }
          });
          this.triggerErrorFile(message);
        }
      });
    });
  }
  handleOpenFileBrowser() {
    if (!this.disabled) {
      let input = this.element.querySelector(`#${this.elementId}-file-upload`);
      if (input) {
        input.click();
      }
    }
  }
  handleKeypressOpenFileBrowser(event) {
    if (!this.disabled) {
      let input = this.element.querySelector(`#${this.elementId}-file-upload`);
      if (event.code === 'Space') {
        event.preventDefault();
        input.click();
      }
    }
  }
  async _read(data) {
    let localLength = this.files?.length || 0;
    if (data.queue.files.length + localLength > this.filesLimit) {
      throw new Error('errors.files_limit');
    }
    if (data instanceof FileList) {
      let copy = Array.from(data);
      return await Promise.all(copy.map(data => this._read(data)));
    }
    if (this.files?.length === this.filesLimit) {
      throw new Error('errors.files_limit');
    }
    if (this._hasUnallowedFileExtension(data.name)) {
      let error = new Error('errors.wrong_file_extension');
      error.extension = getFileExtension(data.name);
      throw error;
    }
    if (data.size > this.maxSize) {
      throw new Error('errors.file_too_big');
    }
    if (data.size < this.minSize) {
      throw new Error('errors.file_too_small');
    }
  }
  _hasUnallowedFileExtension(fileName) {
    let extensions = this.extensions;
    if (extensions) {
      let extension = getFileExtension(fileName);
      if (typeof extensions === 'string') {
        extensions = extensions.split(',');
      }
      extensions.map(ext => ext && ext.toLowerCase());
      return !extensions.includes(extension);
    }
    return false;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "strings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "handleFileAdd", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFileAdd"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleOpenFileBrowser", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleOpenFileBrowser"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeypressOpenFileBrowser", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeypressOpenFileBrowser"), _class2.prototype)), _class2);
export { _class as default };