var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-classic-components */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { match, readOnly } from '@ember/object/computed';

/**
 *
 * @class FilePreviewSlider
 * @extends Component
 */
let FilePreviewSlider = (_dec = match('selectedFile.fileContentType', /^image/), _dec2 = match('selectedFile.fileContentType', /pdf/), _dec3 = readOnly('selectedFile.fileUrl'), _dec4 = computed('files.[]', 'selectedFileIndex'), _dec5 = computed('files.[]', 'selectedFileIndex'), _dec6 = computed('files.[]', 'selectedFileIndex'), _dec7 = computed('files.[]'), (_class = class FilePreviewSlider extends Component {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "tagName", '');
    _initializerDefineProperty(this, "isImage", _descriptor, this);
    _initializerDefineProperty(this, "isPdf", _descriptor2, this);
    _initializerDefineProperty(this, "selectedFileUrl", _descriptor3, this);
  }
  init() {
    super.init(...arguments);
    /**
     * @property selectedFileIndex
     * @default 0
     * @type number
     */
    this.selectedFileIndex = this.selectedFileIndex || 0;

    /**
     * an array of FileObjects { fileName, fileSize, fileUrl, fileContentType }
     *
     * @property files
     * @default []
     * @type array
     */

    this.files = this.files || [];
  }
  get selectedFile() {
    return this.files[this.selectedFileIndex];
  }
  get isFirstFileSelected() {
    return this.selectedFileIndex === 0;
  }
  get isLastFileSelected() {
    return this.selectedFileIndex === this.files.length - 1;
  }
  get hasMultipleFiles() {
    return this.files.length > 1;
  }
  handleNext() {
    if (this.isLastFileSelected) return;
    this.incrementProperty('selectedFileIndex');
  }
  handlePrev() {
    if (this.isFirstFileSelected) return;
    this.decrementProperty('selectedFileIndex');
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isImage", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPdf", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedFileUrl", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "selectedFile", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectedFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFirstFileSelected", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isFirstFileSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLastFileSelected", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isLastFileSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasMultipleFiles", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "hasMultipleFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleNext", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePrev", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePrev"), _class.prototype)), _class));
export { FilePreviewSlider as default };