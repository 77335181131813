import Controller from '@ember/controller';

import { isDevelopingApp, macroCondition } from '@embroider/macros';

import ENV from 'qonto-switching-bank-js/config/environment';

export default class ApplicationController extends Controller {
  queryParams = ['organizationId'];

  organizationId = null;

  constructor() {
    super(...arguments);
    if (macroCondition(isDevelopingApp())) {
      document.title = 'Qonto - dev';
    } else {
      // eslint-disable-next-line no-console
      console.log('app version:', ENV.qontoAppVersion);
    }
  }
}
