var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
let QToggleBox = (_class = class QToggleBox extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "_isOpened", _descriptor, this);
    _initializerDefineProperty(this, "_expandedHeight", _descriptor2, this);
  }
  get isOpened() {
    return 'isOpened' in this.args ? this.args.isOpened : this.args.forceOpened || this._isOpened;
  }
  get isToggleEnabled() {
    return !this.args.forceOpened && !this.args.forceClosed;
  }
  get sectionContentStyle() {
    return htmlSafe(`height: ${this.isOpened ? this._expandedHeight : 0}px;`);
  }
  get hideBottomBorder() {
    return this.args.hideBottomBorder;
  }
  _computeExpandedHeight(element) {
    /**
     * The `target` property is used for `ember-on-resize-modifier` compatibility.
     */
    this._expandedHeight = element.target?.offsetHeight || element.offsetHeight;
  }
  _handleToggle() {
    if (this.isToggleEnabled) {
      if (this.args.toggleOpen) {
        this.args.toggleOpen();
      } else {
        this._isOpened = !this._isOpened;
      }
    }
  }
  _handleToggleKeyDown(e) {
    e.stopPropagation();
    if (this.isToggleEnabled && (e.key === ' ' || e.key === 'Enter')) {
      this._isOpened = !this._isOpened;
    }
  }
  _onToggleStateUpdate() {
    if (this.args.forceOpened) {
      this._isOpened = true;
    } else if (this.args.forceClosed) {
      this._isOpened = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_isOpened", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_expandedHeight", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "_computeExpandedHeight", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_computeExpandedHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_handleToggle", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_handleToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_handleToggleKeyDown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_handleToggleKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onToggleStateUpdate", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_onToggleStateUpdate"), _class.prototype)), _class);
export { QToggleBox as default };