import RESTAdapter from '@ember-data/adapter/rest';
import { inject as service } from '@ember/service';

import { apiBaseURL } from 'qonto-switching-bank-js/constants/hosts';

export default class ChildBankAdapter extends RESTAdapter {
  @service network;

  host = apiBaseURL;

  query(store, type, options) {
    let parentBankId = options.parent_bank_id;

    return this.network.get(`/banks/${parentBankId}/children`).then(response => ({
      'child-banks': response.data,
    }));
  }
}
