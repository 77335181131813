var _class;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import { action } from '@ember/object';
import Component from '@glimmer/component';
let TabsNavItemComponent = (_class = class TabsNavItemComponent extends Component {
  handleClick() {
    this._changeTab();
  }
  handleKeyDown(e) {
    let {
      nextElementSibling,
      previousElementSibling,
      parentElement
    } = document.activeElement;
    let {
      firstElementChild,
      lastElementChild
    } = parentElement;
    let dir = e.key === 'ArrowLeft' ? 'left' : e.key === 'ArrowRight' ? 'right' : null;
    if (dir === 'right') {
      if (nextElementSibling) {
        nextElementSibling.focus();
      } else {
        firstElementChild.focus();
      }
    } else if (dir === 'left') {
      if (previousElementSibling) {
        previousElementSibling.focus();
      } else {
        lastElementChild.focus();
      }
    }
    if (e.key === ' ' || e.key === 'Enter') {
      this._changeTab();
    }
  }
  _changeTab() {
    let {
      changeTab,
      navId
    } = this.args;
    changeTab(navId);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "handleClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype)), _class);
export { TabsNavItemComponent as default };