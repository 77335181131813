import Store from '@ember-data/store';
import ArrayProxy from '@ember/array/proxy';
import Service, { inject as service } from '@ember/service';

import * as dayjs from 'dayjs';

import ActionModel from 'qonto-switching-bank-js/models/action';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { ActionsByMethod, Status } from 'qonto-switching-bank-js/types/action';

import ToastFlashMessagesService from './toast-flash-messages';

export default class ActionService extends Service {
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service('migration') declare migrationService: MigrationService;
  @service declare segment: SegmentService;
  @service declare store: Store;

  sortByLastTransactionDate(firstAction: ActionModel, secondAction: ActionModel) {
    let firstDate = firstAction.last_transaction.date;
    let secondDate = secondAction.last_transaction.date;

    if (firstDate === null && secondDate === null) {
      return 0;
    }
    if (firstDate === null) {
      return 1;
    }
    if (secondDate === null) {
      return -1;
    }

    return dayjs(firstDate).isAfter(dayjs(secondDate)) ? -1 : 1;
  }

  parseByMethod(actions: ArrayProxy<ActionModel>) {
    if (!actions.length) {
      return null;
    }
    return actions
      .slice()
      .sort(this.sortByLastTransactionDate)
      .reduce(
        (parsedActions: ActionsByMethod, action) => {
          parsedActions.all = [...parsedActions.all, action];
          switch (action.transaction_method) {
            case 'core':
            case 'b2b':
              parsedActions.mandates = [...parsedActions.mandates, action];
              break;
            case 'transfer':
              parsedActions.transfers = [...parsedActions.transfers, action];
              break;
            case 'card':
              parsedActions.cards = [...parsedActions.cards, action];
              break;
            default:
              parsedActions.others = [...parsedActions.others, action];
              break;
          }
          return parsedActions;
        },
        { all: [], transfers: [], mandates: [], cards: [], others: [] }
      );
  }

  async editActionStatus(
    action: ActionModel,
    newStatus: Status,
    isDemoFixture: boolean
  ): Promise<void> {
    try {
      let oldStatus = action.status;

      action.status = newStatus;

      if (newStatus === 'created') {
        localStorage.removeItem('autoEndMigrationModalSeen');
      } else {
        action.is_important = false;
      }

      if (!isDemoFixture) {
        await action.save();

        this.segment.track(this.segmentLabel(oldStatus, newStatus), {
          action_completed: action.transaction_type,
        });
      }
    } catch {
      action.rollbackAttributes();
      this.toastFlashMessages.defaultToastError();
    }
  }

  async toggleImportant(action: ActionModel, isDemoFixture: boolean): Promise<void> {
    try {
      action.is_important = !action.is_important;

      if (!isDemoFixture) {
        await action.save();

        this.segment.track(
          action.is_important
            ? 'bankswitch_action_marked_important'
            : 'bankswitch_action_unmarked_important'
        );
      }
    } catch {
      action.rollbackAttributes();
      this.toastFlashMessages.defaultToastError();
    }
  }

  segmentLabel(oldStatus: Status, newStatus: Status) {
    switch (newStatus) {
      case 'finished':
        return 'bankswitch_action_checked';
      case 'ignored':
        return 'bankswitch_action_ignored';
      case 'created':
        if (oldStatus === 'finished') {
          return 'bankswitch_action_unchecked';
        } else {
          return 'bankswitch_action_unignored';
        }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    action: ActionService;
  }
}
