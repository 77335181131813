import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import ActionModel from 'qonto-switching-bank-js/models/action';
import { ActionsByMethod } from 'qonto-switching-bank-js/types/action';

interface DashboardActionsArgs {
  actions: ActionsByMethod;
  activeTab: TabType;
  page: string;
  shouldDisplayDemoData: boolean;
}

interface ParsedActions {
  done: ActionModel[];
  todo: ActionModel[];
  ignored: ActionModel[];
}

export type TabType = 'all' | 'mandates' | 'transfers' | 'cards' | 'others';

export default class DashboardActionsComponent extends Component<DashboardActionsArgs> {
  @service declare intl: IntlService;

  get tabs() {
    let actions = this.args.actions;
    let tabs: { type: TabType; title_key: string }[] = [
      { type: 'all', title_key: `dashboard.${this.args.page}.sections.all.methods` },
      { type: 'cards', title_key: `dashboard.${this.args.page}.sections.cards` },
      { type: 'transfers', title_key: `dashboard.${this.args.page}.sections.transfers` },
      { type: 'mandates', title_key: `dashboard.${this.args.page}.sections.mandates` },
      { type: 'others', title_key: `dashboard.${this.args.page}.sections.others` },
    ];

    return tabs
      .filter(tab => !isEmpty(actions[tab.type]))
      .map(tab => {
        let doneActions = actions[tab.type].filter(
          (action: ActionModel) => action.status === 'finished'
        ).length;
        let activeActions = actions[tab.type].filter(
          (action: ActionModel) => action.status !== 'ignored'
        ).length;

        return {
          ...tab,
          title: `
            ${this.intl.t(tab.title_key)} (${doneActions}/${activeActions})
          `,
        };
      });
  }

  get sections() {
    return [
      {
        label_key: `dashboard.${this.args.page}.sections.todo`,
        actions: this.currentActions.todo,
        productTourTarget: 'todo',
      },
      {
        label_key: `dashboard.${this.args.page}.sections.done`,
        actions: this.currentActions.done,
        productTourTarget: 'done',
      },
      {
        label_key: `dashboard.${this.args.page}.sections.ignored`,
        actions: this.currentActions.ignored,
        productTourTarget: 'ignored',
      },
    ];
  }

  get currentActions() {
    return this.args.actions[this.args.activeTab].slice().reduce(
      (parsedActions: ParsedActions, action: ActionModel) => {
        switch (action.status) {
          case 'created':
            parsedActions.todo = [...parsedActions.todo, action];
            break;
          case 'finished':
            parsedActions.done = [...parsedActions.done, action];
            break;
          case 'ignored':
            parsedActions.ignored = [...parsedActions.ignored, action];
            break;
          default:
            parsedActions.todo = [...parsedActions.todo, action];
            break;
        }
        return parsedActions;
      },
      { todo: [], done: [], ignored: [] }
    );
  }
}
