import Component from '@glimmer/component';
const DEFAULT_LOCALE = 'EN';
const DRAFT_STATUS = 'draft';
const INVOICE_MODAL = 'invoice-modal';
const INVOICE_FORM = 'invoice-form';
export default class ItemsTable extends Component {
  get shouldDisplayStampDutyDisclaimer() {
    return this.args.document.stampDutyAmount && this.args.document.stampDutyAmount !== '0.00';
  }
  get isDraftTitleDisplayed() {
    let {
      status
    } = this.args.document;
    let {
      origin
    } = this.args;
    return status === DRAFT_STATUS && !(origin === INVOICE_MODAL || origin === INVOICE_FORM);
  }
  get shouldDisplayDraftDisclaimer() {
    return this.args.document.organization?.legalCountry?.toUpperCase() === 'IT' && this.isDraftTitleDisplayed;
  }
  get totalsTableMarginBottomClass() {
    if (this.shouldDisplayStampDutyDisclaimer) return 'mb-8';
    if (this.shouldDisplayDraftDisclaimer) return 'mb-40';
    return 'mb-16';
  }
  get uppercaseLocale() {
    return this.args.document.organization?.legalCountry?.toUpperCase() ?? DEFAULT_LOCALE;
  }
  get withholdingTaxAmount() {
    let withholdingTaxAmount = Math.abs(this.args.document.withholdingTaxAmount);
    if (this.args.isCreditNote) {
      return withholdingTaxAmount;
    }
    return -withholdingTaxAmount;
  }
  get currency() {
    return this.args.document.currency || 'EUR';
  }
}