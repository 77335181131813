export const CENTS_MULTIPLIER = {
  BHD: 1000,
  BIF: 1,
  CLF: 10000,
  CLP: 1,
  CVE: 1,
  DJF: 1,
  GNF: 1,
  IQD: 1000,
  ISK: 1,
  JOD: 1000,
  JPY: 1,
  KMF: 1,
  KRW: 1,
  KWD: 1000,
  LYD: 1000,
  MGA: 10,
  MRO: 10,
  PYG: 1,
  RWF: 1,
  UGX: 1,
  UYI: 1,
  VND: 1,
  VUV: 1,
  XAF: 1
};