var _class;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
const options = {
  type: 'image/png',
  width: 160,
  height: 160,
  quality: 0.5,
  margin: 0,
  color: {
    dark: '#000000FF',
    light: '#00000000'
  }
};
let QrCodeComponent = (_class = class QrCodeComponent extends Component {
  async importQrCodePackage() {
    return await import('qrcode');
  }
  *generateQRcodeTask(element) {
    let {
      url
    } = this.args;
    if (!url) {
      return;
    }
    let QRCode = yield this.importQrCodePackage();
    if (QRCode) {
      let src = yield QRCode.toDataURL(url, options);
      element.src = src;
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "generateQRcodeTask", [task, waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "generateQRcodeTask"), _class.prototype)), _class);
export { QrCodeComponent as default };