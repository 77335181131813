var _class, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { DEFAULT_COUNTRY_CODES, DEFAULT_PREFERRED_COUNTRY_CODES } from '@qonto/ui-kit/constants/countries';
import { makeSortByPreferredCountries } from '@qonto/ui-kit/utils/sorters';

/**
 * @typedef {string} CountryCode
 * @typedef {{ code: CountryCode; name: string }} CountryOption
 * @typedef {import('ember-intl/services/intl').default} IntlService
 */

/**
 * @param {IntlService} intl
 * @returns {(code: CountryCode) => CountryOption}
 */
const countryCodeToOption = intl => code => ({
  code,
  name: intl.t(`ui-kit.countries.${code}`)
});

/**
 * Component args
 *
 * @typedef {Object} Args
 * @property {CountryCode[]} [blacklistedCountries] - If provided, will remove those countries from the options list
 * @property {CountryCode[]} [countries] - Collection of country codes, controls which countries are displayed in the list of options
 * @property {boolean} [disabled] - Sets the disabled attribute
 * @property {string} [error] - Error message displayed in the field.
 * @property {string} [label] - Label of the field
 * @property {string} [placeholder] - Text to display in the trigger of the component while no option is selected
 * @property {CountryCode[]} [preferredCountries] - Preferred countries are countries that will appear on top of the list
 * @property {boolean} [readonly] - If set to true, displays a readonly version of the component
 * @property {boolean} [renderInPlace] - When truthy, the list of options will be rendered in place instead of being attached to the root of the body and positioned with javascript. Enabling this option also adds a wrapper div around the trigger and the content with class .ember-power-select.
 * @property {boolean} [required] - Controls the required attribute, prevent parent form submission when value is not provided
 * @property {(code: CountryCode) => void} onUpdate - The function to be invoked when the user selects or unselects a country
 * @property {CountryCode} value - The selected country code
 * @property {"auto" | "above" | "below"} [verticalPosition] - The strategy to position the list of options. By default is "auto", meaning that will position the list depending on the space available around the trigger. The other possible values are "above" and "below".
 */

/**
 * @extends {Component<Args>}
 */
let CountrySelect = (_class = class CountrySelect extends Component {
  constructor(...args) {
    super(...args);
    /**
     * @type {IntlService}
     */
    _initializerDefineProperty(this, "intl", _descriptor, this);
  }
  get selectedCountry() {
    let code = this.args.value;
    if (code) {
      let format = countryCodeToOption(this.intl);
      return format(code);
    }
  }
  get blacklistedCountries() {
    return this.args.blacklistedCountries ?? [];
  }
  get fieldLabel() {
    return this.args.label ?? this.intl.t('ui-kit.country-select.label');
  }
  get preferredCountries() {
    return this.args.preferredCountries ?? DEFAULT_PREFERRED_COUNTRY_CODES;
  }
  get options() {
    let countryCodes = this.args.countries ?? DEFAULT_COUNTRY_CODES;
    return countryCodes.filter(countryCode => !this.blacklistedCountries.includes(countryCode)).map(countryCodeToOption(this.intl)).sort(makeSortByPreferredCountries(this.preferredCountries));
  }
  get placeholder() {
    return this.args.placeholder ?? this.intl.t('ui-kit.country-select.placeholder');
  }
  handleUpdate(countryOption) {
    this.args.onUpdate(countryOption?.code);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "handleUpdate", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdate"), _class.prototype)), _class);
export { CountrySelect as default };