import { assert } from '@ember/debug';
import { htmlSafe, isHTMLSafe } from '@ember/template';
import Component from '@glimmer/component';
const ALLOWED_TYPES = ['info', 'warning', 'error'];
export default class TopBanner extends Component {
  constructor() {
    super(...arguments);
    let {
      close
    } = this.args;
    let {
      type
    } = this.args.content;
    assert('<TopBanner /> must have a @type', type);
    assert('<TopBanner /> must have a @close function', close);
    assert('<TopBanner /> can only have those types: info, warning or error', ALLOWED_TYPES.includes(type));
  }
  get iconName() {
    switch (this.args.content.type) {
      case 'info':
      case 'warning':
        return 'icon_info_rounded_outlined';
      case 'error':
        return 'icon_warning_sign_outlined';
    }
  }
  get message() {
    let {
      message
    } = this.args.content;
    return isHTMLSafe(message) ? message : htmlSafe(message);
  }
}