import Store from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/browser';
import ToastFlashMessagesService from 'app/services/toast-flash-messages';

import Router from 'qonto-switching-bank-js/router';
import CalendlyService from 'qonto-switching-bank-js/services/calendly';
import MigrationService from 'qonto-switching-bank-js/services/migration';
import NetworkService from 'qonto-switching-bank-js/services/network';
import SegmentService from 'qonto-switching-bank-js/services/segment';
import { CalendlyWindow } from 'qonto-switching-bank-js/types/calendly';

import { AppointmentCategory } from './types';

export default class AppointmentRoute extends Route {
  @service declare calendly: CalendlyService;
  @service declare network: NetworkService;
  @service declare router: Router;
  @service declare segment: SegmentService;
  @service declare toastFlashMessages: ToastFlashMessagesService;
  @service('migration') declare migrationService: MigrationService;
  @service declare store: Store;

  handleCalendlyEvent = async (event: Event) => {
    if (this.calendly.isCalendlyEvent(event) && event.data.event === 'calendly.event_scheduled') {
      let migration = await this.store.queryRecord('migration', {});
      let status = migration?.status;

      // We update the migration status to call_planned only if the status is synchronized or empty_dashboard
      if (migration && (status === 'synchronized' || status === 'empty_dashboard')) {
        this.segment.track('bankswitch_calendly_scheduled');

        migration.status = 'call_planned';

        migration
          .save()
          .then(() => {
            this.router.transitionTo('onboarding.migration-status');
          })
          .catch(error => {
            Sentry.captureMessage(
              `Migration update after call scheduled failed | ${error?.message}`
            );
            this.toastFlashMessages.defaultToastError();
          });
      }
    }
  };

  async model(params: { category: AppointmentCategory }) {
    let organizationId = localStorage.getItem('organizationId') || '';
    let { full_name, email } = await this.store.queryRecord('user', {});
    let { legal_country, name } = await this.store.findRecord('organization', organizationId);
    let calendlyUrl = '';
    let calendlyWindow = window as CalendlyWindow;

    switch (params.category) {
      case 'information':
        calendlyUrl = this.calendly.getCalendlyInformationAppointmentUrl(legal_country);
        break;
      case 'help':
        calendlyUrl = this.calendly.getCalendlyHelpAppointmentUrl(legal_country);
        break;
      default:
        calendlyUrl = this.calendly.getCalendlyMigrationAppointmentUrl(legal_country);
        break;
    }
    calendlyWindow.calendly_url = calendlyUrl;
    calendlyWindow.calendly_prefill_name = full_name;
    calendlyWindow.calendly_prefill_email = email;
    calendlyWindow.calendly_prefill_organization = name;

    window.addEventListener('message', this.handleCalendlyEvent);
  }

  @action
  willTransition() {
    window.removeEventListener('message', this.handleCalendlyEvent);
  }
}
