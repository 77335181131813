var _class2;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import Service from '@ember/service';
import { computed } from '@ember/object';
import { getDefaultLocale } from 'ember-power-calendar-utils';
let _class = (_class2 = class _class2 extends Service {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "date", null);
  }
  // CPs
  get locale() {
    return getDefaultLocale();
  }
  set locale(value) {
    return value;
  }

  // Methods
  getDate() {
    return this.date || new Date();
  }
}, (_applyDecoratedDescriptor(_class2.prototype, "locale", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "locale"), _class2.prototype)), _class2);
export { _class as default };