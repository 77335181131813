import RESTAdapter from '@ember-data/adapter/rest';
import { inject as service } from '@ember/service';

import { apiBaseURL } from 'qonto-switching-bank-js/constants/hosts';

export default class ApplicationAdapter extends RESTAdapter {
  @service network;

  host = apiBaseURL;

  getDirtyAttributes = record => {
    /**
     * changedAttributes() return an object with each property representing dirty attributes from the record.
     * The value of each property of the object is an array that contain previous value and new value [prev, new].
     */
    let changedAttributes = record.changedAttributes();

    for (let property in changedAttributes) {
      changedAttributes[property] = changedAttributes[property].lastObject;
    }

    return changedAttributes;
  };

  findAll(store, type) {
    return this.network.get(`/${type.modelName}s`).then(response => {
      let data = {};
      data[`${type.modelName}`] = response.data;
      return data;
    });
  }

  findRecord(store, type, id) {
    return this.network.get(`/${type.modelName}s/${id}`).then(response => {
      let data = {};
      data[`${type.modelName}`] = response.data;
      return data;
    });
  }
}
