export default {
  "table": "_table_139o3o",
  "label-column": "_label-column_139o3o",
  "date-column": "_date-column_139o3o",
  "amount-column": "_amount-column_139o3o",
  "table-header": "_table-header_139o3o",
  "header-item": "_header-item_139o3o",
  "item-row": "_item-row_139o3o",
  "cell": "_cell_139o3o"
};
