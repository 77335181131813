import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class SearchInputComponent extends Component {
  @tracked isFocused = false;

  @action
  onChange({ target }) {
    this.args.task(target.value);
  }

  @action
  setFocus(val) {
    this.isFocused = val;
  }
}
