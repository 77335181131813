var _dec, _dec2, _dec3, _class2, _descriptor;
function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { computed, action, getProperties } from '@ember/object';
import CalendarComponent from './power-calendar';
import fallbackIfUndefined from '../utils/computed-fallback-if-undefined';
import { normalizeDate, normalizeRangeActionValue, diff, isAfter, isBefore, normalizeDuration } from 'ember-power-calendar-utils';
import { assert } from '@ember/debug';
import ownProp from 'ember-power-calendar/-private/utils/own-prop';
import PowerCalendarRangeComponent from './power-calendar-range/days';
let _class = (_dec = fallbackIfUndefined(false), _dec2 = computed('center'), _dec3 = computed('_publicAPI', 'minRange', 'maxRange'), (_class2 = class _class2 extends CalendarComponent {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "proximitySelection", _descriptor, this);
    _defineProperty(this, "daysComponent", PowerCalendarRangeComponent);
    _defineProperty(this, "_calendarType", 'range');
  }
  // CPs
  get minRange() {
    return 86400000;
  }
  set minRange(v) {
    if (typeof v === 'number') {
      return v * 86400000;
    }
    return normalizeDuration(v === undefined ? 86400000 : v);
  }
  get maxRange() {
    return null;
  }
  set maxRange(v) {
    if (typeof v === 'number') {
      return v * 86400000;
    }
    return normalizeDuration(v === undefined ? 86400000 : v);
  }
  get selected() {
    return {
      start: undefined,
      end: undefined
    };
  }
  set selected(v) {
    if (v === undefined || v === null) {
      v = {};
    }
    return {
      start: normalizeDate(v.start),
      end: normalizeDate(v.end)
    };
  }
  get currentCenter() {
    let center = this.center;
    if (!center) {
      center = this.selected.start || this.powerCalendarService.getDate();
    }
    return normalizeDate(center);
  }
  get publicAPI() {
    let rangeOnlyAPI = this.getProperties('minRange', 'maxRange');
    return Object.assign(rangeOnlyAPI, this._publicAPI);
  }

  // Actions
  select({
    date
  }, calendar, e) {
    assert('date must be either a Date, or a Range', date && (ownProp(date, 'start') || ownProp(date, 'end') || date instanceof Date));
    let range;
    if (ownProp(date, 'start') && ownProp(date, 'end')) {
      range = {
        date
      };
    } else {
      range = this._buildRange({
        date
      });
    }
    let {
      start,
      end
    } = range.date;
    if (start && end) {
      let {
        minRange,
        maxRange
      } = this.publicAPI;
      let diffInMs = Math.abs(diff(end, start));
      if (diffInMs < minRange || maxRange && diffInMs > maxRange) {
        return;
      }
    }
    if (this.onSelect) {
      this.onSelect(range, calendar, e);
    }
  }

  // Methods
  _buildRange(day) {
    let selected = this.publicAPI.selected || {
      start: null,
      end: null
    };
    let {
      start,
      end
    } = getProperties(selected, 'start', 'end');
    if (this.proximitySelection) {
      return this._buildRangeByProximity(day, start, end);
    }
    return this._buildDefaultRange(day, start, end);
  }
  _buildRangeByProximity(day, start, end) {
    if (start && end) {
      let changeStart = Math.abs(diff(day.date, end)) > Math.abs(diff(day.date, start));
      return normalizeRangeActionValue({
        date: {
          start: changeStart ? day.date : start,
          end: changeStart ? end : day.date
        }
      });
    }
    if (isBefore(day.date, start)) {
      return normalizeRangeActionValue({
        date: {
          start: day.date,
          end: null
        }
      });
    }
    return this._buildDefaultRange(day, start, end);
  }
  _buildDefaultRange(day, start, end) {
    if (start && !end) {
      if (isAfter(start, day.date)) {
        return normalizeRangeActionValue({
          date: {
            start: day.date,
            end: start
          }
        });
      }
      return normalizeRangeActionValue({
        date: {
          start: start,
          end: day.date
        }
      });
    }
    return normalizeRangeActionValue({
      date: {
        start: day.date,
        end: null
      }
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "proximitySelection", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "minRange", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "minRange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "maxRange", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "maxRange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selected", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentCenter", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "currentCenter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "publicAPI", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "publicAPI"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype)), _class2));
export { _class as default };