var _class;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
import { action } from '@ember/object';
import Component from '@glimmer/component';

/**
 * Actual text field in the component. Given to Ember Power Select,
 * which in turn is expected to pass itself as a property into this,
 * making itself available as `this.select`.
 *
 * This should not be used directly by application code.
 *
 * @private
 * @class FormElements::AddressSearchInput::Input
 */
let AddressSearchInputInput = (_class = class AddressSearchInputInput extends Component {
  constructor(...args) {
    super(...args);
    /**
     * Placeholder text. Not called `placeholder` as Ember Power Select
     * already sets a property with this name.
     *
     * @property customPlaceholder
     * @type string
     */
    _defineProperty(this, "customPlaceholder", this.args.customPlaceholder ?? null);
    /**
     * Whether to set the `required` attribute on the input field.
     *
     * @property required
     * @type boolean
     */
    _defineProperty(this, "required", this.args.required ?? false);
    /**
     * Whether to set the `disabled` attribute on the input field.
     *
     * @property required
     * @type boolean
     */
    _defineProperty(this, "disabled", this.args.disabled ?? false);
    /**
     * Whether to set the `readonly` attribute on the input field.
     *
     * @property readonly
     * @type boolean
     */
    _defineProperty(this, "readonly", this.args.readonly ?? false);
    /**
     * Instance of the parent Ember Power Select component that
     * renders this component.
     *
     * @property select
     * @type EmberPowerSelect
     */
    _defineProperty(this, "select", this.args.select ?? null);
  }
  onFocusCustom() {
    this.select.actions.open();
  }
}, (_applyDecoratedDescriptor(_class.prototype, "onFocusCustom", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusCustom"), _class.prototype)), _class);
export { AddressSearchInputInput as default };